import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LoadingIndicator, PrivateLayout, RequestSubmitMessage, NotificationToastMessage } from '../components';
import { rxModulesMetadata, useListRequest } from '../hooks';
import { AnyType, PhoneNumber, ServiceOptionType } from '../interfaces';
import {
  AppRootState,
  navigateToCases,
  navigateToHome,
  navigateToServiceRequest,
  navigateToServiceRequestSelected,
  useTenancyDrugListSelector,
  tenancyFeatureSelector
} from '../redux';
import {
  GATEWAY_API_URL,
  STANDARD_PORTAL_APPLICATION_NAME,
  useEnrollment,
  ENROLLMENT_MODULE_TRIGGER_RULE
} from '../api';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import memoizeOne from 'memoize-one';
import { getToken } from '../components/enrollment-module';
import { clientApplicationNameSelector } from '../utils';
import { Drug } from '../api/portal-config.generated';

const renderSwitch = (param: string) => {
  switch (param) {
    case 'BV Complete -PA Required':
      return ServiceOptionType.MedicalElectronicPriorAuthorization;
    case 'Pre-determination Option: Medical':
      return ServiceOptionType.MedicalElectronicPriorAuthorization;
    case 'Product Covered - PA Required':
      return ServiceOptionType.PharmacyElectronicPriorAuthorization;
    case 'Product Covered with restrictions - PA Required':
      return ServiceOptionType.PharmacyElectronicPriorAuthorization;
    default:
      return '';
  }
};

export const getEnrollmentModuleAPIEndpoint = (enrollmentId: string | null, practiceId: string | null): string => {
  return `${GATEWAY_API_URL}/enrollment/${enrollmentId}/${practiceId}`?.replace(/([^:]\/)\/+/g, '$1');
};

export const getAPIEndpointForEnrollmentModulePDF = (
  enrollmentId: string | null,
  practiceId: string | null
): string => {
  return `${GATEWAY_API_URL}/enrollment/pdf/${enrollmentId}/${practiceId}`?.replace(/([^:]\/)\/+/g, '$1');
};

const getOkToLeaveMessageValue = (phoneNumbers: Array<PhoneNumber>, phoneType: string) => {
  if (phoneNumbers?.length == 0) return false;
  return phoneNumbers?.filter((p) => p.phoneType.toLowerCase() === phoneType.toLowerCase())[0]?.okToLeaveMessage;
};

const getIndividualInsurance = (insuranceList: Array<AnyObject>, type: string) => {
  const selectedInsurance = insuranceList?.filter((i) => i.medicalInsuranceType.toLowerCase() === type.toLowerCase());
  return selectedInsurance?.length > 0 ? selectedInsurance[0] : {};
};

const getPatientInsurancePayload = (insuranceData: AnyObject) => {
  const length = insuranceData?.length;
  if (length < 1) return [];
  const primaryInsurance = getIndividualInsurance(insuranceData?.medicalInsurances, 'primary');
  const secondaryInsurance = getIndividualInsurance(insuranceData?.medicalInsurances, 'secondary');
  const tertiaryInsurance = getIndividualInsurance(insuranceData?.medicalInsurances, 'tertiary');
  return [
    {
      patientInsuranceId: parseInt(sessionStorage.getItem('primaryInsId') ?? '0'),
      insuranceType: 'PRIMARY',
      medicalCardHolderId: primaryInsurance?.cardHolderId || null,
      medicalGroup: primaryInsurance?.groupNumber || null,
      medicalPhoneNumber: primaryInsurance?.phoneNumber?.phoneNumber || null,
      medicalPlanName: primaryInsurance?.planName || null,
      pharmacyBINNumber: insuranceData?.pharmacyInsurance?.bin || null,
      pharmacyCardholderID: insuranceData?.pharmacyInsurance?.cardHolderId || null,
      pharmacyGroup: insuranceData?.pharmacyInsurance?.groupNumber || null,
      pharmacyPCN: insuranceData?.pharmacyInsurance?.pcn || null,
      pharmacyPhoneNumber: insuranceData?.pharmacyInsurance?.phoneNumber?.phoneNumber || null,
      pharmacyPlanName: insuranceData?.pharmacyInsurance?.insuranceName || null,
      policyHolderName: insuranceData?.pharmacyInsurance?.policyHolderName || null,
      subscriberRelationshipToCardholder: primaryInsurance?.cardHolderRelationship || null,
      cardHolderName: primaryInsurance?.cardHolderName || null,
      cardholderDOB: primaryInsurance?.cardholderDOB || null,
      employer: primaryInsurance?.employer || null,
      planType: primaryInsurance?.planType || null,
      PayerId: parseInt(primaryInsurance?.PayerId ?? '0')
    },
    {
      patientInsuranceId: parseInt(sessionStorage.getItem('secondaryInsId') ?? '0'),
      insuranceType: 'SECONDARY',
      medicalCardHolderId: secondaryInsurance?.cardHolderId || null,
      medicalGroup: secondaryInsurance?.groupNumber || null,
      medicalPhoneNumber: secondaryInsurance?.phoneNumber?.phoneNumber || null,
      medicalPlanName: secondaryInsurance?.planName || null,
      pharmacyBINNumber: insuranceData?.pharmacyInsurance?.bin || null,
      pharmacyCardholderID: insuranceData?.pharmacyInsurance?.cardHolderId || null,
      pharmacyGroup: insuranceData?.pharmacyInsurance?.groupNumber || null,
      pharmacyPCN: insuranceData?.pharmacyInsurance?.pcn || null,
      pharmacyBIN: insuranceData?.pharmacyInsurance?.bin || null,
      pharmacyPhoneNumber: insuranceData?.pharmacyInsurance?.phoneNumber?.phoneNumber || null,
      pharmacyPlanName: insuranceData?.pharmacyInsurance?.insuranceName || null,
      subscriberRelationshipToCardholder: secondaryInsurance?.cardHolderRelationship || null,
      cardHolderName: secondaryInsurance?.cardHolderName || null,
      cardholderDOB: secondaryInsurance?.cardholderDOB || null,
      employer: secondaryInsurance?.employer || null,
      planType: secondaryInsurance?.planType || null,
      PayerId: parseInt(secondaryInsurance?.PayerId ?? '0')
    },
    {
      patientInsuranceId: parseInt(sessionStorage.getItem('tertiaryInsId') ?? '0'),
      insuranceType: 'TERTIARY',
      medicalCardHolderId: tertiaryInsurance?.cardHolderId || null,
      medicalGroup: tertiaryInsurance?.groupNumber || null,
      medicalPhoneNumber: tertiaryInsurance?.phoneNumber?.phoneNumber || null,
      medicalPlanName: tertiaryInsurance?.planName || null,
      pharmacyBINNumber: insuranceData?.pharmacyInsurance?.bin || null,
      pharmacyCardholderID: insuranceData?.pharmacyInsurance?.cardHolderId || null,
      pharmacyGroup: insuranceData?.pharmacyInsurance?.groupNumber || null,
      pharmacyPCN: insuranceData?.pharmacyInsurance?.pcn || null,
      pharmacyPhoneNumber: insuranceData?.pharmacyInsurance?.phoneNumber?.phoneNumber || null,
      pharmacyPlanName: insuranceData?.pharmacyInsurance?.planName || null,
      subscriberRelationshipToCardholder: tertiaryInsurance?.cardHolderRelationship || null,
      cardHolderName: tertiaryInsurance?.cardHolderName || null,
      employer: tertiaryInsurance?.employer || null,
      planType: tertiaryInsurance?.planType || null,
      PayerId: parseInt(tertiaryInsurance?.PayerId ?? '0')
    }
  ];
};

const getAdditionalPhysiciansPayload = (additionalPhysiciansData: Array<AnyObject>) => {
  return additionalPhysiciansData?.map((pd: AnyObject) => {
    return {
      firstName: pd?.person?.firstName,
      lastName: pd?.person?.lastName,
      middleName: pd?.person?.middleName,
      npi: pd?.npi,
      taxId: pd?.taxId,
      dea: pd?.deaNumber,
      ptan: pd?.ptanNumber,
      stateLicenseNumber: pd?.stateLicenseNumber ?? '',
      licenseState: pd?.licensingState,
      specialty: pd?.specialty,
      physicianType: pd?.physicianType,
      medicareId: pd?.medicaidMedicareProviderNumber
    };
  });
};
const getManualAttestation = (doc: Array<AnyObject>) => {
  if (doc?.length < 1) return {};
  const attestation = doc.find((d) => d?.entityType === 'Enrollment.PatientConsent.Attestation');
  if (attestation) {
    return {
      fileName: attestation.fileName,
      fileData: attestation.fileContent
    };
  } else {
    return {};
  }
};
export const prepareEnollmentPayload = (
  data: AnyObject,
  caseId: number | null,
  caseRecordType: string | null,
  drugId: number,
  document?: string | null,
  isUsingEnrollmentModule?: boolean,
  isTamTracker?: boolean
) => {
  const doc = document
    ? {
        fileName: 'enrollmentdocumentfromModule',
        fileData: document
      }
    : {};
  const locationId = sessionStorage.getItem('locationId');
  const getPhoneNumber = (obj: any, phoneType: string) => {
    const phoneObj = obj?.find((p: AnyObject) => p.phoneType.toLowerCase() === phoneType);
    return phoneObj && phoneObj?.phoneNumber ? phoneObj.phoneNumber : '';
  };
  return {
    userName: data?.sourceSystem?.sourceSystemUser?.userName,
    enrollmentType: 'Patient Enrollment',
    caseRecordType: caseRecordType,
    supportServiceData: data?.serviceSpecific?.supportServiceData,
    patient: {
      patientId: parseInt(data?.patient?.externalId),
      firstName: data?.patient?.person?.firstName,
      middleName: data?.patient?.person?.middleName,
      lastName: data?.patient?.person?.lastName,
      last4SSN: data?.patient?.person?.last4SSN,
      dob: data?.patient?.dateOfBirth,
      gender: data?.patient?.person?.gender,
      preferredLanguage: data?.patient?.preferredLanguages ? data?.patient?.preferredLanguages[0]?.language : '',
      otherLanguage: data?.patient?.preferredLanguages
        ? data?.patient?.preferredLanguages[1]
          ? data?.patient?.preferredLanguages[1]?.language
          : ''
        : '',
      email: data?.patient?.email,
      patientPhoneNumbers: data?.patient?.phoneNumbers,
      okToLeaveMessageMobile: getOkToLeaveMessageValue(data?.patient?.phoneNumbers, 'home') || false,
      okToLeaveMessageHome: getOkToLeaveMessageValue(data?.patient?.phoneNumbers, 'mobile') || false,
      address1: data?.patient?.addresses?.[0]?.address1,
      address2: data?.patient?.addresses?.[0]?.address2,
      zip: data?.patient?.addresses?.[0]?.zip,
      city: data?.patient?.addresses?.[0]?.city,
      state: data?.patient?.addresses?.[0]?.state,
      hasCaregiver: data?.patient?.hasCaregiver,
      caregiver: {
        firstName: data?.patient?.caregiver?.person?.firstName,
        middleName: data?.patient?.caregiver?.person?.middleName,
        lastName: data?.patient?.caregiver?.person?.lastName,
        email: data?.patient?.caregiver?.email,
        homePhoneNumber: getPhoneNumber(data?.patient?.caregiver?.phoneNumbers, 'home'),
        mobilePhoneNumber: getPhoneNumber(data?.patient?.caregiver?.phoneNumbers, 'mobile'),
        relationship: data?.patient?.caregiver?.relationship,
        isLegallyAuthorizedRepresentative: data?.patient?.caregiver?.isLegallyAuthorizedRepresentative
      }
    },
    insurance: {
      hasPatientInsurance: data?.insurance?.hasInsurance,
      medicareBeneficiaryId: data?.insurance?.medicareId,
      patientInsurances: data?.insurance?.hasInsurance ? getPatientInsurancePayload(data?.insurance) : []
    },
    physicians: {
      lastName: data?.prescriber?.physician?.person?.lastName,
      firstName: data?.prescriber?.physician?.person?.firstName,
      middleName: data?.prescriber?.physician?.person?.middleName,
      npi: data?.prescriber?.physician?.npi,
      taxId: data?.prescriber?.physician?.taxId,
      stateLicenseNumber: data?.prescriber?.physician?.stateLicenseNumber,
      licenseState: data?.prescriber?.physician?.licensingState ?? '',
      medicareId: data?.prescriber?.physician?.medicaidMedicareProviderNumber,
      providerType: data?.prescriber?.physician?.physicianType,
      physicianId: parseInt(sessionStorage.getItem('physicianId') ?? '0'),
      hin: data?.prescriber?.physician?.hin,
      organization: {
        organizationId: parseInt(data?.practice?.externalId),
        name: data?.practice?.name,
        npi: data?.practice?.npi,
        taxId: data?.practice?.taxId,
        dea: data?.practice?.deaNumber,
        email: data?.practice?.email,
        role: data?.practice?.role,
        type: data?.practice?.type,
        organizationAddress: {
          organizationAddressId: parseInt(locationId ?? '0'),
          address1: data?.practice?.location?.address?.address1,
          address2: data?.practice?.location?.address?.address2,
          zip: data?.practice?.location?.address?.zip,
          city: data?.practice?.location?.address?.city,
          state: data?.practice?.location?.address?.state,
          phoneNumber: getPhoneNumber(data?.practice?.location?.phoneNumbers, 'work'),
          faxNumber: getPhoneNumber(data?.practice?.location?.phoneNumbers, 'fax')
        }
      },
      officeContact: {
        lastName: data?.practice?.officeContact?.person?.lastName,
        firstName: data?.practice?.officeContact?.person?.firstName,
        phoneNumber: getPhoneNumber(data?.practice?.officeContact?.phoneNumbers, 'work'),
        fax: getPhoneNumber(data?.practice?.officeContact?.phoneNumbers, 'fax'),
        eMail: data?.practice?.officeContact?.email
      },
      ptan: data?.prescriber?.physician?.ptanNumber,
      dea: data?.prescriber?.physician?.deaNumber,
      email: data?.prescriber?.physician?.email
    },
    additionalPhysicians: getAdditionalPhysiciansPayload(data?.additionalPhysicians),
    diagnosis: {
      diagnosisCode: data?.clinical?.primaryDiagnosisCode?.split('-')?.[0] || null,
      diagnosisCodesSecondary: data?.clinical?.secondaryDiagnosisCode?.split('-')?.[0] || null,
      diagnosisCodesTertiary: data?.clinical?.tertiaryDiagnosisCode?.split('-')?.[0] || null
    },
    administrationInformation: data?.administeringInformation
      ? typeof data?.administeringInformation === 'object'
        ? String(JSON.stringify(data?.administeringInformation))?.slice(0, 98)
        : String(data?.administeringInformation)
      : null,
    patientAttestation: {
      attestationType: data?.patientConsent?.attestationType,
      manualAttestationDocument: data?.documents ? getManualAttestation(data?.documents) : {},
      electronicAttestation: {
        patientEmail: data?.patient?.email,
        patientMobileNumber: getPhoneNumber(data?.patient?.phoneNumbers, 'home')
      }
    },
    prescriberAttestation: data?.prescriber?.attestation,
    drugId: drugId,
    caseRecordId: caseId ?? 0,
    enrollmentSignatures: [
      {
        entityType: 'Prescription',
        signaturePurpose: 'PrescriptionAllowed',
        signatureType: data?.enrollmentSignatures?.filter((p: AnyObject) => p.signatureType.toLowerCase() === 'write')
          ? 'Image'
          : data?.enrollmentSignatures?.filter((p: AnyObject) => p.signatureType.toLowerCase() === 'type')
          ? 'Text'
          : '',
        signatureDateTime: data?.enrollmentSignatures?.[0]?.signatureDateTime,
        signatureImage: data?.enrollmentSignatures?.[0]?.signatureImage
      }
    ],
    patientEnrollmentGuid: sessionStorage.getItem('enrollmentModuleEnrollmentId'),
    isEnrollmentUsingModule: isUsingEnrollmentModule,
    isTamTracker: isTamTracker,
    document: doc
  };
};

export const callModuleEnrollmentApi = async (
  accessToken: string | null,
  enrollmentId: string | null,
  practiceId: string | null
) => {
  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);

  const requestOptions = {
    method: 'GET',
    headers
  };
  return fetch(getEnrollmentModuleAPIEndpoint(enrollmentId, practiceId), requestOptions);
};

export const callModuleEnrollmentPdfApi = async (
  accessToken: string | null,
  enrollmentId: string | null,
  practiceId: string | null
) => {
  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);

  const requestOptions = {
    method: 'GET',
    headers
  };
  return fetch(getAPIEndpointForEnrollmentModulePDF(enrollmentId, practiceId), requestOptions);
};

export const RequestSubmitView = () => {
  const dispatch = useDispatch();
  const [loadingEnrollment, setLoadingEnrollment] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>('');
  const { mutate: enroll } = useEnrollment({});
  let status = '';
  let patientId = '';
  const { hash: service, state } = useLocation<AnyObject>();
  const metadata = (rxModulesMetadata as AnyType)[service.replace('#', '')];
  const [enrollmentData, setEnrollmentData] = useState<AnyType>({});

  const sessionDrug = sessionStorage.getItem('drugList');
  const drugList: Drug[] = sessionDrug ? JSON.parse(sessionDrug) : useTenancyDrugListSelector();

  const applicationName = useSelector(clientApplicationNameSelector);
  const autoServiceOption: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceOption'));

  const isUsingEnrollmentModule = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );
  const isTamTracker = useSelector((state: AppRootState) => state?.app?.entities?.features?.tamTracker?.data);
  const callEnrollmentAPI = async (payload: AnyObject) => {
    try {
      const result = await enroll(payload);
      if (result?.errors && result?.errors?.length < 1) {
        sessionStorage.setItem('enrollmentModuleEnrollmentId', '');
        sessionStorage.setItem('enrollmentModulePracticeId', '');
        sessionStorage.setItem('caseId', '');
        sessionStorage.setItem('drugList', '');
        sessionStorage.setItem('locationId', '');
        sessionStorage.setItem('patientIdEnrollment', '');
        sessionStorage.setItem('caseIdEnrollment', '');
      }
    } catch (e) {
      throw e?.message;
    }
  };

  const getDrugId = (jsonDrug: any) => {
    if (!jsonDrug) return null;
    const selectedDrug = drugList.find((element: Drug) => element?.JCode1 === jsonDrug?.jCode ?? 0);
    return selectedDrug
      ? selectedDrug?.DrugId
      : drugList.find((element: Drug) => element?.DrugNDC === jsonDrug?.ndc ?? 0)?.DrugId;
  };

  const loadAccessToken = useCallback(async () => {
    const token = await getToken(ENROLLMENT_MODULE_TRIGGER_RULE);
    const result = await token.text();

    try {
      const json = JSON.parse(result);
      setAccessToken(json.authToken);
    } catch (e) {
      setLoadingEnrollment(false);
      throw e?.message;
    }
  }, []);
  useEffect(() => {
    if (accessToken && loadingEnrollment && !state?.enrollmentJson?.enrollmentId) {
      loadPayloadData(accessToken);
    }
  }, [accessToken]);

  const loadPayloadData = useCallback(async (accessToken: string) => {
    if (!accessToken) return;

    const response = await callModuleEnrollmentApi(
      accessToken,
      sessionStorage.getItem('enrollmentModuleEnrollmentId'),
      sessionStorage.getItem('enrollmentModulePracticeId')
    );
    const result = await response.text();

    try {
      const json = JSON.parse(result);
      const drugId = getDrugId(json?.drug);
      setEnrollmentData(json);
      callEnrollmentAPI(
        prepareEnollmentPayload(json, 0, null, Number(drugId) ?? 0, json?.enrollmentDocument, isUsingEnrollmentModule)
      );
    } catch (e) {
      throw e?.message;
    } finally {
      setLoadingEnrollment(false);
    }
  }, []);

  if (metadata) {
    const routeData = useLocation<AnyType>();
    const physicianNpi = routeData.state?.physicianNpiValue;
    patientId = routeData.state?.patientIdValue;
    const { data } = useListRequest({
      endDate: moment().add(1, 'day').utc().toString(),
      externalPatientId: patientId,
      physicianNpi: physicianNpi || '',
      startDate: moment().subtract(2, 'days').utc().toString(),
      abbreviation: metadata.abbreviation || ''
    });

    status = data?.[0]?.Status;
  }
  useEffect(() => {
    if (
      sessionStorage.getItem('enrollmentModuleEnrollmentId') &&
      sessionStorage.getItem('enrollmentModulePracticeId') &&
      isUsingEnrollmentModule
    ) {
      setLoadingEnrollment(true);
      loadAccessToken();
    }
  }, []);

  if (loadingEnrollment) {
    return (
      <div className='text-center p-5'>
        <LoadingIndicator />
        <p>Please wait as we process your Enrollment</p>
      </div>
    );
  }

  return (
    <PrivateLayout pageTitle='submission successful'>
      <Col className='mb-3'>
        <RequestSubmitMessage
          enrollmentData={
            Object.keys(state?.enrollmentJson ?? {}).length > 0
              ? state?.enrollmentJson
              : Object.keys(enrollmentData).length === 0
              ? state
              : enrollmentData
          }
        />
      </Col>

      <Row>
        {renderSwitch(status) === ServiceOptionType.MedicalElectronicPriorAuthorization && (
          <Col lg={3}>
            <Button
              variant='secondary'
              size='sm'
              className='px-4'
              onClick={() =>
                dispatch(
                  navigateToServiceRequestSelected(ServiceOptionType.MedicalElectronicPriorAuthorization, patientId)
                )
              }>
              START MEDICAL PRIOR AUTHORIZATION
            </Button>
          </Col>
        )}
        {renderSwitch(status) === ServiceOptionType.PharmacyElectronicPriorAuthorization && (
          <Col lg={3}>
            <Button
              variant='secondary'
              size='sm'
              className='px-4'
              onClick={() =>
                dispatch(
                  navigateToServiceRequestSelected(ServiceOptionType.PharmacyElectronicPriorAuthorization, patientId)
                )
              }>
              START PRIOR AUTHORIZATION
            </Button>
          </Col>
        )}
        {!autoServiceOption?.enabled && (
          <Col lg={3}>
            <Button
              variant='secondary'
              size='sm'
              className='px-4'
              onClick={() => {
                if (applicationName === 'PhyzProviderPortal') {
                  dispatch(navigateToCases());
                } else {
                  dispatch(navigateToServiceRequest());
                }
              }}>
              START NEW REQUEST
            </Button>
          </Col>
        )}
        {!autoServiceOption?.enabled && (
          <Col xs={3}>
            <Button variant='outline-secondary' size='sm' className='px-5' onClick={() => dispatch(navigateToHome())}>
              CLOSE
            </Button>
          </Col>
        )}
      </Row>
    </PrivateLayout>
  );
};
