import React from 'react';

import FormRenderer, { FormRendererProps } from '@data-driven-forms/react-form-renderer/dist/cjs/form-renderer';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';
import { useSelector } from 'react-redux';
import { CustomThemes } from '../../utils/custom-themes';
import { AnyType } from '../../interfaces';
import { ResourceContent } from '..';
import { rxAdmincomponentMapper } from '../../components/DynamicForms/HcpFormEditRxFields';
import { HcpRequestError } from '../../components/HcpRequestStateUI';
import { HcpFormTemplate } from '../../components/DynamicForms/HcpFormTemplate';
import { DBFormSchema } from '../../utils';
import { validatorMapper } from '../../utils';
import { tenancyFeatureSelector } from '../../redux';
import { Col, Row } from 'react-bootstrap';

interface HcpFormRendererProps extends Partial<FormRendererProps> {
  contentKey?: string;
  noControls?: boolean;
  noErrorMessage?: boolean;
  schemaMethod?: (dbSchema: DBFormSchema) => Schema;
}

export const HcpRXEditDoseRender = React.memo((props: HcpFormRendererProps) => {
  CustomThemes();
  const defaultSchema = { title: '', fields: [] };
  const { contentKey, noControls, noErrorMessage, schemaMethod, ...formRendererProps } = props;
  const formSchema: AnyType = useSelector<AnyType>(tenancyFeatureSelector(contentKey ?? ''));

  const renderDefaultTemplate = (templateProps: FormTemplateRenderProps) => (
    <HcpFormTemplate {...templateProps} noControls={noControls} />
  );

  // Protect against data load issues so we don't show the error before there is truly an error
  if (formSchema === undefined && !formRendererProps.schema) return null;

  if (!formSchema?.fields && !formRendererProps.schema) {
    return <HcpRequestError errorTitle='Form Error' errorText='There was an error loading the form.' />;
  }

  return (
    <>
      <FormRenderer
        {...(formRendererProps as FormRendererProps)}
        FormTemplate={formRendererProps?.FormTemplate ?? renderDefaultTemplate}
        schema={formRendererProps?.schema ?? schemaMethod?.(formSchema?.fields ?? defaultSchema) ?? defaultSchema}
        componentMapper={rxAdmincomponentMapper}
        validatorMapper={validatorMapper}
      />
    </>
  );
});

HcpRXEditDoseRender.displayName = 'HcpRXEditDoseRender.';
