import React, { Props } from 'react';
import { AuthLayoutHeader, CheckboxField, PrivateLayout, PublicLayout } from '..';
import { useSelectTenancyResources } from '../../hooks/use-tenancy';
import { AnyType } from '../../interfaces';
import { rawCleanHtmlProps } from '../../utils';

interface ResourceContentProps extends Props<JSX.Element> {
  contentKey: string | string[];
  defaultContent?: AnyType;
  sourceDataKey?: string;
  render?: (data: AnyType) => JSX.Element;
}

export const ResourceContent = ({
  contentKey,
  defaultContent = {},
  sourceDataKey,
  // Render function child only needed for non-`content` properties (single-key only):
  render = (data = {}) => <span {...rawCleanHtmlProps(`${data?.content ?? data?.title ?? ''}`)} />
}: ResourceContentProps) => {
  const keyArray = Array.isArray(contentKey) ? contentKey : [contentKey];

  const data = keyArray.map((contentKey) => {
    const result = useSelectTenancyResources(contentKey) || defaultContent;
    return sourceDataKey ? result[sourceDataKey] : result;
  });

  const newProps = (data.length === 1 ? data[0] : data) ?? null;

  return render(newProps);
};

export const withResourceContent = (Wrapper: AnyType, targetProp: string, sourceDataKey = 'content') => {
  const WithResourceContent = ({ contentKey, ...props }: AnyType) => {
    return (
      <ResourceContent
        contentKey={contentKey}
        sourceDataKey={sourceDataKey}
        render={(content) => <Wrapper {...props} {...{ [targetProp]: content }} />}
      />
    );
  };

  WithResourceContent.displayName = `WithResourceContent(${Wrapper?.displayName || Wrapper?.name || 'Component'})`;

  return WithResourceContent;
};

// Consider moving these into their respective implementations' files if we end up making a lot of them:
export const ResourceAuthLayoutHeader = withResourceContent(AuthLayoutHeader, 'title');
ResourceAuthLayoutHeader.displayName = 'ResourceAuthLayoutHeader';

export const ResourceCheckbox = withResourceContent(CheckboxField, 'label');
ResourceCheckbox.displayName = 'ResourceCheckbox';

export const ResourceImage = withResourceContent((props: AnyType) => <img {...props} />, 'src', 'url');
ResourceImage.displayName = 'ResourceImage';

export const ResourcePrivateLayout = withResourceContent((props: AnyType) => <PrivateLayout {...props} />, 'pageTitle');
ResourcePrivateLayout.displayName = 'ResourcePrivateLayout';

export const ResourcePublicLayout = withResourceContent((props: AnyType) => <PublicLayout {...props} />, 'pageTitle');
ResourcePublicLayout.displayName = 'ResourcePublicLayout';
