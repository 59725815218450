import { eiSendIFrameMessage } from '@caremetx/enrollment-integration';
import { FormApi, FORM_ERROR } from 'final-form';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Alert } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { object, string } from 'yup';
import {
  FileUploadField,
  MessageSubjectsSelect,
  RequestsTypeaheadField,
  TextAreaField,
  TextField,
  SelfServiceRequestSelect
} from '.';
import { API_URL, ComposeMessageRequest, DocumentModel, useComposeMessage } from '../api';
import { PatientRequestViewModelFragment, ServiceRequestViewFragment } from '../graphql';
import { FilePreview, GeneralObject, AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import {
  clientApplicationNameSelector,
  getFilePayload,
  localDateTableFormatAnother,
  validateFormValues
} from '../utils';
import { PatientSearchField } from './PatientSearch';

const messageSchema = object({
  message: string().max(5000).required().label('Message'),
  patientId: string().required().label('Patient Name'), // ID is internal - name is user-facing.
  serviceRequestId: string().required().label('Request ID'),
  subject: string().max(100).required().label('Subject'),
  drug: string().max(100).required().label('Product')
});

const giSrMessageSchema = object({
  message: string().max(5000).required().label('Message'),
  drug: string().max(100).required().label('Product'),
  patientId: string().required().label('Patient Name'), // ID is internal - name is user-facing.
  subject: string().max(100).required().label('Subject'),
  selfServiceRequest: string().required().label('Self-Service Request')
});

export interface MessageComposeProps {
  isShown: boolean;
  onSent?: (message: ComposeMessageRequest) => void | null;
  onHide?: () => void | null;
}

export const MessageCompose = (props: MessageComposeProps) => {
  const { search: queryString } = useLocation();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const queryParams = new URLSearchParams(queryString);
  const patientId = queryParams.get('patient') ?? '';
  const requestId = queryParams.get('request') ?? '';
  const applicationName = useSelector(clientApplicationNameSelector);
  const [requestIdOptions, setRequestIdOptions] = useState<any[]>([]);
  const [isGISrNeeded, setIsGISrNeeded] = useState<boolean | null>(false);
  const [eServicesOptions, setEServicesOptions] = useState<any[]>([]);
  const secureMessageClosedServiceEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['secure-message.closed-service-request'] as AnyType)?.data
        ?.enabled
  );

  /**
   * true = request is complete
   * false = request is not complete
   * null = a request has not loaded to determine the status
   */
  const [isRequestComplete, setIsRequestComplete] = React.useState<boolean | null>(null);
  const [isHiding, setIsHiding] = React.useState<boolean>(false);

  useEffect(() => {
    if (isGISrNeeded) {
      setIsRequestComplete(!isGISrNeeded);
    }
  }, [isGISrNeeded]);

  const { mutate: sendMessage } = useComposeMessage({
    base: API_URL
  });

  const onSend = useCallback(
    async (values: GeneralObject, form: FormApi) => {
      const files = (values.files as Array<FilePreview>)?.map<DocumentModel | undefined>((f) =>
        getFilePayload(f)
      ) as DocumentModel[];

      const payload: ComposeMessageRequest = {
        applicationName: applicationName,
        files,
        messageText: String(values.message).trim(),
        patientId: Number(values.patientId),
        serviceRequestId: values.serviceRequestId !== '' ? Number(values.serviceRequestId) : null,
        subject: String(values.subject).trim(),
        userName: currentUser?.Login,
        transactionId:
          values?.selfServiceRequest && !values?.serviceRequestId
            ? String(values?.selfServiceRequest)?.split(',')?.[0]
            : null,
        transactionType:
          values?.selfServiceRequest && !values?.serviceRequestId
            ? String(values?.selfServiceRequest)?.split(',')?.[1]
            : null,
        transactionDate:
          values?.selfServiceRequest && !values?.serviceRequestId
            ? String(values?.selfServiceRequest)?.split(',')?.[2]
            : null,
        sRDrugName: values?.drug ? String(values?.drug) : null,
        physicianNPI: values?.prescriberNPI ? String(values?.prescriberNPI) : null,
        providerId: values?.providerId ? Number(values?.providerId) : null
      };

      const result = await sendMessage(payload);
      if (result.errors && result.errors.length > 0) {
        return { [FORM_ERROR]: result.errors.map((e) => e.description).join(' ') };
      }

      props.onSent && props.onSent(payload);
      props.onHide && props.onHide();
      setTimeout(form.reset, 0);
    },
    [props.onSent, currentUser]
  );

  const onHide = useCallback(() => {
    setIsRequestComplete(null);
    setIsHiding(true);
    setTimeout(() => {
      props.onHide && props.onHide();
    }, 0);
  }, [props.onHide]);

  const onPatientSelect = (patient: PatientRequestViewModelFragment, form: FormApi) => {
    setIsGISrNeeded(false);
    form.change('patientId', patient?.patientId);
    form.change('providerId', patient?.physicianId);

    // Clear the service request id unless the patient is the default request id. This covers scenarios where compose
    // is rendered by way of url parameters and there selected request loads before the selected patient.
    String(patient?.patientId) !== patientId && form.change('serviceRequestId', '');
  };

  const onEserviceSelect = (form: FormApi, eServiceId?: any) => {
    if (!eServiceId) return;
    const eService = eServicesOptions?.filter((eService: any) => eService.requestID === eServiceId);
    if (eService?.length > 0) {
      form.change('prescriberNPI', eService?.[0]?.physicianNPI);
      form.change('drug', eService?.[0]?.sRDrugName ?? '');

      const requestStartDate = localDateTableFormatAnother(eService?.[0]?.requestStartDate, 'MM-DD-YYYY h:mm:ss');
      const requestTypeAndDate = `${eService?.[0]?.requestType} ${requestStartDate}`;
      form.change('selfServiceRequestDateAndType', requestTypeAndDate ?? '');
    }
  };

  const onRequestSelect = (form: FormApi, request?: any) => {
    if (isHiding) {
      setIsHiding(false);
      return;
    }

    if (request?.isEserviceTransaction) return;
    // eslint-disable-next-line no-console
    console.log('DS: ', request);
    form.change('serviceRequestId', request?.id ?? '');
    form.change('drug', request?.sRDrugName ?? '');
    setIsRequestComplete(secureMessageClosedServiceEnabled ? false : request?.requestStatus === 'C');
  };
  // eslint-disable-next-line no-console
  console.log('DS:RENDER', isRequestComplete, patientId, requestId);

  return (
    <Modal show={props.isShown} size='lg' onHide={onHide}>
      <FinalForm
        onSubmit={onSend}
        validate={validateFormValues(isGISrNeeded ? giSrMessageSchema : messageSchema)}
        initialValues={messageSchema.default()}
        render={({ form, handleSubmit, submitting, submitError, hasValidationErrors, values }) => (
          <Form onSubmit={handleSubmit} noValidate id='HCP-Message'>
            <Modal.Header className='bg-primary text-light'>
              <Modal.Title as={'h6'}>Send a Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label className='text-right' column sm={3}>
                      PATIENT NAME
                    </Form.Label>
                    <Col>
                      <PatientSearchField
                        defaultPatientId={patientId}
                        fieldName='patientId'
                        placeholder='Patient Last Name or DOB'
                        onPatientSelect={(patient: PatientRequestViewModelFragment) => onPatientSelect(patient, form)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label className='text-right' column sm={3}>
                      REQUEST ID
                    </Form.Label>
                    <Col>
                      <RequestsTypeaheadField
                        defaultRequestId={requestId}
                        fieldName='serviceRequestId'
                        patientId={values.patientId}
                        placeholder='Request ID'
                        onRequestSelect={(request?: ServiceRequestViewFragment) => onRequestSelect(form, request)}
                        requestIdOptions={requestIdOptions}
                        setRequestIdOptions={setRequestIdOptions}
                        setEServicesOptions={setEServicesOptions}
                        setIsGISrNeeded={setIsGISrNeeded}
                        isGISrNeeded={isGISrNeeded}
                      />
                    </Col>
                  </Form.Group>
                  {isRequestComplete === true && (
                    <Alert variant='info'>
                      A Secure Message cannot be created on closed Request. Please contact the Service Center if you
                      have any questions regarding a specific Request.{' '}
                    </Alert>
                  )}
                  {isRequestComplete === false && (
                    <>
                      {isGISrNeeded && eServicesOptions.length ? (
                        <>
                          <Alert variant='info'>
                            There is no open service request, please use self service request from below
                          </Alert>
                          <Form.Group as={Row}>
                            <Form.Label className='text-right' column sm={3}>
                              SELF-SERVICE REQUEST
                            </Form.Label>
                            <Col>
                              <SelfServiceRequestSelect
                                name='selfServiceRequest'
                                options={eServicesOptions}
                                onEserviceSelect={(eService?: any) => onEserviceSelect(form, eService)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label className='text-right' column sm={3}>
                              SELF-SERVICE REQUEST DETAIL
                            </Form.Label>
                            <Col>
                              <TextField className='text-muted' name='selfServiceRequestDateAndType' readOnly />
                            </Col>
                          </Form.Group>
                        </>
                      ) : null}
                      <Form.Group as={Row}>
                        <Form.Label className='text-right' column sm={3}>
                          PRODUCT
                        </Form.Label>
                        <Col>
                          <TextField className='text-muted' name='drug' readOnly />
                          {false && <TextField className='text-muted' name='prescriberNPI' />}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label className='text-right' column sm={3}>
                          SUBJECT
                        </Form.Label>
                        <Col>
                          <MessageSubjectsSelect name='subject' isGISrNeeded={isGISrNeeded} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label className='text-right' column sm={3}>
                          FROM
                        </Form.Label>
                        <Col>
                          <Form.Control className='text-muted' plaintext readOnly value={currentUser?.Login} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label className='text-right' column sm={3}>
                          ATTACHMENTS
                        </Form.Label>
                        <Col>
                          <FileUploadField name='files' dropzoneOptions={{ maxFiles: 5 }} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label className='text-right' column sm={3}>
                          MESSAGE
                        </Form.Label>
                        <Col>
                          <TextAreaField
                            name='message'
                            placeholder='Type Your Message here'
                            maxLength={5000}
                            rows={5}
                            className='w-100'
                          />
                        </Col>
                      </Form.Group>
                      {submitError && (
                        <Form.Group>
                          <span className='text-danger'>{submitError}</span>
                        </Form.Group>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
              <Button
                type='reset'
                onClick={onHide}
                variant='transparent'
                className='text-danger mr-3'
                data-testid='cmx__modal-cancel-button'>
                CANCEL
              </Button>
              <Button
                type='submit'
                variant='secondary'
                id='Message-Submit'
                disabled={submitting || hasValidationErrors}
                data-testid='cmx__modal-send-button'>
                SEND
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};
