import { default as React, PropsWithRef, useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeaheadProps } from 'react-bootstrap-typeahead';
import { Field, FieldRenderProps } from 'react-final-form';
import { connect, useSelector } from 'react-redux';
import { LoadingIndicator } from '.';
import {
  PatientRequestViewModelFragment,
  PatientSearchViewModelFragment,
  usePatientInfoLazyQuery,
  usePatientRequestInfoLazyQuery,
  usePatientSearchInfoLazyQuery
} from '../graphql';
import { AnyType } from '../interfaces';
import { defaultDateFormat } from '../utils';
import { displayError } from './FormFields';
import { CustomThemes } from '../utils/custom-themes';
import { SearchInputWithGql, SearchInputWithGqlDisplay } from './SearchInputWithGql';
import { AppRootState } from '../redux';

interface PatientSearchProps {
  asyncTypeaheadProps?: Partial<AsyncTypeaheadProps<AnyType> & PropsWithRef<AnyType>>;
  display?: SearchInputWithGqlDisplay;
  defaultPatientId?: string;
  placeholder?: string;
  onPatientSelect: (patient: PatientRequestViewModelFragment) => void;
  isDisabled?: any;
}

const renderLabelWithSearchGraphQLApi = (option: PatientSearchViewModelFragment): string => {
  return `${option.firstName} ${option.lastName} | ${defaultDateFormat(option.dob)}`;
};

const renderLabel = (option: PatientRequestViewModelFragment): string => {
  return `${option.firstName} ${option.lastName} | ${defaultDateFormat(option.dob)}`;
};

export const PatientSearch = ({
  display = SearchInputWithGqlDisplay.underlined,
  defaultPatientId: patientId = '',
  ...props
}: PatientSearchProps) => {
  const isSearchApiEnabled = useSelector(
    (state: AppRootState) => (state.app.entities.features?.['patient-search-info-v2']?.data as any)?.enabled ?? false
  );

  const [execPatientQuery, patientResult] = usePatientInfoLazyQuery({ variables: { patientId } });
  CustomThemes();
  const loadDefaultPatient = useCallback(() => {
    if (!patientId) return;

    execPatientQuery({
      variables: {
        patientId
      }
    });
  }, [patientId]);

  useEffect(loadDefaultPatient, [loadDefaultPatient]);

  useEffect(() => {
    const result = patientResult?.data?.result;
    if (!result) return;

    const p: PatientRequestViewModelFragment = {
      actionAlertCount: 0,
      authorizationReceived: result.authorizationReceived ?? false,
      dob: result.dob,
      firstName: result.firstName,
      gender: result.gender,
      lastName: result.lastName,
      location: null,
      middleName: result.middleName,
      nextStep: null,
      patientId: result.id,
      physicianFirstName: null,
      physicianLastName: null,
      physicianId: result.physicians?.length ? result.physicians[0]?.physicianId : null,
      updateAlertCount: 0
    };

    props.onPatientSelect(p);
  }, [patientId, patientResult]);

  if (patientId && !patientResult?.data?.result) return <LoadingIndicator />;

  return (
    <SearchInputWithGql
      asyncTypeaheadProps={{
        ...props.asyncTypeaheadProps,
        defaultSelected: patientResult?.data?.result
          ? [patientResult?.data?.result]
          : props.asyncTypeaheadProps?.defaultSelected
      }}
      display={display}
      labelRenderer={isSearchApiEnabled ? renderLabelWithSearchGraphQLApi : renderLabel}
      queryHook={isSearchApiEnabled ? usePatientSearchInfoLazyQuery : usePatientRequestInfoLazyQuery}
      onItemSelect={props.onPatientSelect}
      placeholder={props.placeholder}
      isDisabled={props?.isDisabled}
    />
  );
};

interface PatientSearchFieldProps extends PatientSearchProps {
  fieldName?: string;
  label?: string;
}

export const PatientSearchField = ({ fieldName, label, ...props }: PatientSearchFieldProps) => {
  return (
    <Field name={fieldName ?? 'patient'}>
      {(fieldRenderProps: FieldRenderProps<AnyType>) => (
        <>
          {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
          <PatientSearch
            {...props}
            asyncTypeaheadProps={{
              ...props.asyncTypeaheadProps,
              isInvalid: fieldRenderProps.meta.touched && fieldRenderProps.meta.invalid,
              isValid: fieldRenderProps.meta.touched && fieldRenderProps.meta.valid,
              onBlur: () => fieldRenderProps?.input?.onBlur(),
              inputProps: {
                ...props.asyncTypeaheadProps?.inputProps,
                'data-testid': fieldRenderProps.input.name
              }
            }}
          />
          {displayError(fieldRenderProps)}
        </>
      )}
    </Field>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ConnectedPatientSearch = connect(mapStateToProps, mapDispatchToProps)(PatientSearch);
