import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { TriangleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { PrivateLayout, ResourceContent } from '../components';
import { rxModulesMetadata, useListRequest } from '../hooks';
import { AnyType, ServiceOptionType } from '../interfaces';
import { navigateToHome, navigateToRxModule, navigateToServiceRequest } from '../redux';
import { rawSafeHtmlProps } from '../utils';
import { CustomThemes } from '../utils/custom-themes';

const MEDICAL_BENEFIT_SERVICES_MESSAGE_KEY = 'service-request.auto-medepa-message';

export const RequestServiceIntermediator = () => {
  CustomThemes();
  const { hash, state } = useLocation<AnyType>();
  if (isEmpty(hash) || isEmpty(hash?.replace('#', ''))) {
    return <Redirect to='/requests/start' />;
  }
  switch (hash?.replace('#', '')) {
    case ServiceOptionType.MedicalElectronicPriorAuthorization:
      return <MedicalEPAProcessingPage state={state} />;
    default:
      return <Redirect to='/requests/start' />;
  }
};

const MedicalEPAProcessingPage = ({ state }: AnyType) => {
  const { data, loading = true } = useListRequest({
    endDate: moment().add(1, 'day').utc().toString(),
    externalPatientId: state?.patientId,
    physicianNpi: state?.enrollmentPayload?.physicians?.npi || '',
    startDate: moment().subtract(2, 'days').utc().toString(),
    abbreviation: rxModulesMetadata[ServiceOptionType.MedicalElectronicPriorAuthorization]?.abbreviation
  });
  const dispatch = useDispatch();
  const policies = state?.eBVResult?.policies;

  const areAllPaExecuted = () => {
    return !(policies.filter((p: AnyType) => p.paExecutedStatus === false).length > 0);
  };

  const getAutoPATransactionId = (policyType: string) => {
    if (!(state?.ePAResult?.length > 0)) {
      return null;
    }
    const selectedPA = state?.ePAResult?.filter((epa: AnyObject) => epa.policyType === policyType);
    return selectedPA?.length > 0 ? selectedPA[0]?.transactionId : null;
  };

  const getEPAStatus = (policyType: string) => {
    const transactionID = getAutoPATransactionId(policyType);
    const filteredEPAList: AnyObject = data?.filter((epa: AnyObject) => epa?.TransactionID === transactionID);
    return filteredEPAList?.length > 0 ? filteredEPAList[0]?.Status : 'PA Status Not Found';
  };

  const onPAStart = (index: number) => {
    policies[index] = {
      ...policies[index],
      paExecutedStatus: true
    };
    const routerState = {
      ...state,
      selectedPolicyType: policies[index].policyType,
      loadingService: ServiceOptionType.MedicalElectronicPriorAuthorization,
      allPAExecuted: areAllPaExecuted()
    };
    dispatch(
      navigateToRxModule(routerState?.patientId, ServiceOptionType.MedicalElectronicPriorAuthorization, routerState)
    );
  };

  if (loading) {
    return (
      <div className='loading-wrapper paList-screen'>
        <p className='loading-text'>
          Preparing Payer Data <br /> Please wait
        </p>
        <div>
          <div className='snippet' data-title='.dot-floating'>
            <div className='stage'>
              <div className='dot-floating' />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <PrivateLayout pageTitle={`Medical Benefit Serivces - ${state?.eBVResult?.details?.patientName}`}>
      <div className='cmx__medepa'>
        <Container fluid className='cmx__medepa__message'>
          <ResourceContent
            contentKey={MEDICAL_BENEFIT_SERVICES_MESSAGE_KEY}
            sourceDataKey='content'
            render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
          />
        </Container>
        <Container fluid className='cmx__medepa__list'>
          <Row>
            <Col lg={8}>
              <div className='cmx__medepa__list__heading'>PAYER</div>
              <div className='cmx__medepa__list__data'>
                {policies?.map((policy: AnyType, index: number) =>
                  policy?.paStatus?.toUpperCase() === 'YES' ? (
                    <Row key={`${policies?.policyType}_${index}`} className='mb-2'>
                      <Col xs={8}>
                        {policy?.payerName}
                        {policy?.paExecutedStatus}
                      </Col>
                      <Col xs={4}>
                        <p className={!policy?.paExecutedStatus ? 'd-none' : 'text-right'}>
                          <b>{getEPAStatus(policy.policyType)}</b>
                        </p>
                        <a
                          className={policy?.paExecutedStatus ? 'd-none btn-start' : 'btn-start'}
                          type='button'
                          onClick={() => onPAStart(index)}>
                          <TriangleFill tabIndex={0} className='btn-start__icon pointer fs-5' /> START PA
                        </a>
                      </Col>
                    </Row>
                  ) : null
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Row>
          <Col lg={3}>
            <Button variant='secondary' size='sm' className='px-4' onClick={() => dispatch(navigateToServiceRequest())}>
              START NEW REQUEST
            </Button>
          </Col>

          <Col xs={3}>
            <Button variant='outline-secondary' size='sm' className='px-5' onClick={() => dispatch(navigateToHome())}>
              CLOSE
            </Button>
          </Col>
        </Row>
      </div>
    </PrivateLayout>
  );
};
