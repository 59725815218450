import React from 'react';

import { SettingsChangePasswordForm, SettingsUserInfo, PrivateLayout } from '../components';
import { NotificationSettings } from '../components/NotificationSettings';
import { AnyType } from '../interfaces';
import { useSelector } from 'react-redux';
import { tenancyFeatureSelector } from '../redux/selectors';

export const SettingsView = () => {
  const enableNotification: AnyType = useSelector<AnyType>(tenancyFeatureSelector('enable-notification'));

  return (
    <PrivateLayout pageTitle='my account'>
      <div className='w-75'>
        <SettingsUserInfo />
      </div>
      <div className='mt-4'>
        <SettingsChangePasswordForm />
      </div>
      {enableNotification?.enabled && (
        <>
          <hr />
          <div className='w-75'>
            <NotificationSettings />
          </div>
        </>
      )}
    </PrivateLayout>
  );
};
