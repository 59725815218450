import React, { useEffect, useState } from 'react';
import { Badge, Col, Dropdown, Row } from 'react-bootstrap';
import {
  ArchiveFill,
  ArrowClockwise,
  BootstrapReboot,
  BoxArrowInRight,
  Check2All,
  CreditCardFill,
  EnvelopeFill,
  ExclamationCircleFill,
  File,
  FolderFill,
  InfoCircleFill,
  List,
  PencilSquare,
  PeopleFill,
  Sliders,
  XCircleFill
} from 'react-bootstrap-icons';

import { useHistory, useLocation } from 'react-router-dom';
import { CasesFilterTypes, extractCaseUrl, setToArray } from '../utils';
import { CaseMileStone, GRAPHQL_DEFAULT_CACHE, useCaseDetailsQuery, useCaseRecordsQuery } from '../graphql';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux/reducers';

interface CasessFiltersProps {
  onFilterChange: (filter: Set<string>) => void;
  patientId?: string;
}

export const CasesFilter = (props: CasessFiltersProps) => {
  const history = useHistory();
  const [caseId, setCaseId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [milestones, setMilestones] = useState<any>([]);
  const extractedUrl = extractCaseUrl(useLocation());
  const [pills, setPills] = useState<Set<string>>(new Set(extractedUrl?.filters));

  const queryVariables = {
    organizationId: useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId) || '',
    programId: useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '',
    userName: useSelector((state: AppRootState) => state.auth.session?.user?.Login) || '',
    patientId: null
  };
  const caseResult = useCaseRecordsQuery({ variables: { ...queryVariables }, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  const { data, refetch } = useCaseDetailsQuery({
    variables: { caseRecordId: caseId }
  });

  useEffect(() => {
    if (caseResult?.data?.result && caseResult?.data?.result.length > 0) {
      const tempCase = caseResult?.data?.result[0];
      setCaseId(tempCase?.caseRecordId.toString() || '');
    }
  }, [caseResult]);

  useEffect(() => {
    if (data) {
      setMilestones(data?.result?.mileStones);
    } else {
      refetch();
    }
  }, [caseId, data]);

  const onFilterChange = (newFilters: Set<string>) => {
    const newUrlFitlers: Set<string> = new Set(newFilters);
    history?.push(`${extractedUrl?.hashValue}?filters=${setToArray(newUrlFitlers)?.join(',')}`);
    props.onFilterChange(newFilters);
  };

  useEffect(() => {
    if (!pills || pills?.size <= 0) return;
    props.onFilterChange(pills);
  }, [props.onFilterChange, pills]);

  const renderFilterOptions = (e: string | null) => {
    if (e) {
      if (CasesFilterTypes.all === e) {
        setPills(new Set([CasesFilterTypes.all]));
        onFilterChange(new Set([CasesFilterTypes.all]));
      } else {
        const newSelectedFilters: Set<string> = new Set(pills);
        newSelectedFilters?.delete(CasesFilterTypes.all);
        newSelectedFilters?.add(e);
        setPills(newSelectedFilters);
        onFilterChange(newSelectedFilters);
      }
    }
  };

  const disMiss = (selectedFilter: string) => {
    const newSelectedFilters: Set<string> = new Set(pills);
    newSelectedFilters?.delete(selectedFilter);
    setPills(newSelectedFilters);
    onFilterChange(newSelectedFilters);
  };

  const disMissAll = () => {
    const removeAllFilters: Set<string> = new Set();
    setPills(removeAllFilters);
    onFilterChange(removeAllFilters);
  };

  const displayFilter = (mileStoneCode: string) => {
    return milestones.find((m: CaseMileStone) => m?.mileStoneCode?.toLowerCase() === mileStoneCode.toLowerCase());
  };

  return (
    <>
      <Row className='mb-3 align-items-center'>
        <Col xs={8} style={{ minHeight: '83px' }}>
          <p style={{ minHeight: '44px' }}>
            <i>
              To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
              criteria.
            </i>
          </p>
          {pills?.size > 0 &&
            setToArray(pills)?.map((pill, index) => (
              <Badge variant='primary' key={`${pill},${index}`} style={{ marginRight: '5px' }}>
                {pill} <XCircleFill onClick={() => disMiss(pill)} />
              </Badge>
            ))}
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minHeight: '83px' }}>
          <Dropdown
            style={{ float: 'left', border: 'none', color: 'primary', minHeight: '44px', marginBottom: '1rem' }}
            onSelect={renderFilterOptions}>
            <Dropdown.Toggle className='text-primary border-0' variant='link' id='dropdown-basic'>
              <Sliders /> FILTERS
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={CasesFilterTypes.needAction}>
                <ExclamationCircleFill /> Action Needed
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.updated}>
                <InfoCircleFill /> Updated
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.messageReceived}>
                <EnvelopeFill /> Message Received
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.prescriptionIntake}>
                <BoxArrowInRight /> Prescription Intake
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.enrollment}>
                <BoxArrowInRight /> Patient Enrollment
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.benefitInvestigation}>
                <Check2All /> Benefit Investigation
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.priorAuthorization}>
                <PencilSquare /> Prior Authorization
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.appeal}>
                <ArrowClockwise /> Appeal
              </Dropdown.Item>
              {displayFilter('bridge') && (
                <Dropdown.Item eventKey={CasesFilterTypes.bridge}>
                  <ArchiveFill /> Bridge
                </Dropdown.Item>
              )}
              {displayFilter('copay') && (
                <Dropdown.Item eventKey={CasesFilterTypes.copay}>
                  <CreditCardFill /> Copay
                </Dropdown.Item>
              )}
              {displayFilter('pap') && (
                <Dropdown.Item eventKey={CasesFilterTypes.patientAssistantProgram}>
                  <PeopleFill /> Patient Assistance Program
                </Dropdown.Item>
              )}
              <Dropdown.Item eventKey={CasesFilterTypes.sentToPharmacy}>
                <BootstrapReboot /> Sent to Pharmacy
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.closed}>
                <FolderFill /> Include Closed Cases
              </Dropdown.Item>
              <Dropdown.Item eventKey={CasesFilterTypes.all}>
                <File /> All Cases
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <TypeaheadField name='Filter' /> */}

          {pills?.size > 0 && (
            <Badge variant='secondary' style={{ cursor: 'default', marginTop: '4px' }} onClick={() => disMissAll()}>
              Clear All
            </Badge>
          )}
        </Col>
      </Row>
    </>
  );
};
