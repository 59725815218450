import React, { Props, useEffect, useState } from 'react';
import { RadioField } from './FormFields';

import { ResourceContent } from './multi-tenant';
import { AnyType, ServiceOption } from '../interfaces';
import { useSelectTenancyResources } from '../hooks';
import {
  ALL_SERVICE_REQUEST_KEY,
  AppRootState,
  tenancyDrugServicesSelector,
  useTenancyDrugListSelector
} from '../redux';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Drug } from '../api/portal-config.generated';
import { useParams } from 'react-router-dom';

export interface AllServicesOptionProps extends Props<JSX.Element> {
  drug: Drug;
  isEnrollmentModuleView?: boolean;
  getServiceValue: (arg0: any) => unknown;
}

export const AllServicesOption = (props: AllServicesOptionProps) => {
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  const { caseDrugName } = useParams<{ caseDrugName: string }>();
  const drug = newDrugList?.filter((drug: any) => drug?.DrugBrandName === caseDrugName);

  const services =
    useSelector(tenancyDrugServicesSelector(props?.drug?.DrugId || drug?.[0]?.DrugId)) ??
    useSelector((state: AppRootState) => (state.app?.entities?.features as AnyType) ?? {});
  const result = useSelectTenancyResources(ALL_SERVICE_REQUEST_KEY) || {};

  if (!result.options || !(result.options.length > 0) || !(Object.keys(services).length !== 0)) {
    return null;
  }

  const patientRequestText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['requests.new.service.text' ?? ''] as AnyType)?.data
  );

  // Map and display first option.
  const serviceOption: ServiceOption = {
    featureKey: `service-request.${result.options[0].value}`,
    label: result.options[0].label,
    description: result.options[0].description,
    value: result.options[0].value,
    disabled: !result.options[0].enabled
  };

  return (
    services[serviceOption.featureKey as string]?.data?.enabled && (
      <>
        {!props.isEnrollmentModuleView && (
          <h3 className='fs-4 font-weight-bold text-primary mt-3 mb-4'>
            <ResourceContent contentKey={ALL_SERVICE_REQUEST_KEY} />
          </h3>
        )}
        {props.isEnrollmentModuleView && (
          <h3 className='fs-4 font-weight-bold text-primary mt-3 mb-4 text-uppercase'>{patientRequestText.content}</h3>
        )}
        <Form.Group as={Col} controlId='service' className='mb-0'>
          <RadioField
            id='full-support-option'
            name='service'
            data-testid={`cmx__request__${serviceOption.value}__services`}
            value={serviceOption.value}
            label={<p className='mb-0 fs-4'>{serviceOption.label}</p>}
            disabled={serviceOption.disabled}
            onClick={() => props.getServiceValue(services[serviceOption.featureKey as string]?.data)}
            description={
              !props.isEnrollmentModuleView && <p className='fs-3 font-italic'>{serviceOption.description}</p>
            }
          />
        </Form.Group>
        {!props.isEnrollmentModuleView && <hr className='border-top border-dark mb-4' />}
      </>
    )
  );
};
