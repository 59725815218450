import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import { PublicLayout, ResourceContent } from '../components';
import { navigateToLogin, navigateToRegistration } from '../redux';
import { rawSafeHtmlProps } from '../utils';
import { clientApplicationNameSelector } from '../utils';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import { AnyType } from '../interfaces';

export const RegistrationWelcomeView = () => {
  const dispatch = useDispatch();
  const applicationName = useSelector(clientApplicationNameSelector);

  const email_field_registration_welcome_page = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.features as AnyType) ?? {})['email_field_for_registration_welcome_page'] as AnyType
  );

  return (
    <PublicLayout className='view-container w-55'>
      <Row className='view-container w-55'>
        <div className='bg-light-gray border border-bottom w-100'>
          <Container>
            <div className='d-flex py-3 w-55'>
              <h3 className='font-weight-bold'>
                <ResourceContent
                  contentKey='registration.title'
                  sourceDataKey='content'
                  render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                />
              </h3>
            </div>
          </Container>
        </div>
      </Row>
      <Row className='py-4'>
        <Col lg={8}>
          <ResourceContent
            contentKey='registration.body'
            sourceDataKey='content'
            render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
          />
        </Col>

        <Col sm={5} lg={4}>
          <div className='py-4 px-4 bg-light-gray'>
            <ResourceContent
              contentKey='registration.submitted.assistance_notice'
              sourceDataKey='content'
              render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
            />
            <table className='mb-4'>
              <tbody>
                <tr>
                  <th>Phone:</th>
                  <td className='pl-4'>
                    <ResourceContent
                      contentKey='registration.submitted.assistance_notice'
                      sourceDataKey='phone'
                      render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                    />
                  </td>
                </tr>
                <tr>
                  <th className='pt-2'>Fax:</th>
                  <td className='pl-4 pt-2'>
                    <ResourceContent
                      contentKey='registration.submitted.assistance_notice'
                      sourceDataKey='fax'
                      render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                    />
                  </td>
                </tr>

                {email_field_registration_welcome_page?.data?.enabled == true ? (
                  <tr>
                    <th className='pt-2'>Email:</th>
                    <td className='pl-4 pt-2'>
                      <ResourceContent
                        contentKey='registration.submitted.assistance_notice'
                        sourceDataKey='email'
                        render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                      />
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </table>
            <p className='mb-0'>
              <ResourceContent
                contentKey='registration.submitted.assistance_notice'
                sourceDataKey='hours'
                render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
              />
            </p>
          </div>
        </Col>
      </Row>
      <Row className='pb-3'>
        <Col sm={6}>
          <div className='text-right'>
            <Button
              variant='link'
              className='text-danger'
              data-testid='cmx__registration-welcome__cancel'
              onClick={() => dispatch(navigateToLogin())}>
              CANCEL
            </Button>
          </div>
        </Col>
        <Col>
          <div className='text-right'>
            <Button
              variant='secondary'
              data-testid='cmx__registration-welcome__registration'
              onClick={() => dispatch(navigateToRegistration())}>
              REGISTER
            </Button>
          </div>
        </Col>
      </Row>
    </PublicLayout>
  );
};
