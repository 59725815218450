import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MessageCompose, MessagesSearch, MessagesTable, MessagesTableComponent, PrivateLayout } from '../components';
import { MessagesFilters } from '../components/MessagesFilters';
import { AppRootState, getUnreadMessageCount } from '../redux';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { AnyType } from '../interfaces';

export const MessagesView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hash } = useLocation();
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [searchText, setSearchText] = useState<string>('');
  const [isComposing, setIsComposing] = useState<boolean>(hash.includes('compose'));
  const messagesTableRef = useRef<MessagesTableComponent>(null);
  const secureMessageEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['secure-message.manage'] as AnyType)?.data?.enabled
  );

  const renderSubtoolbarChildren = useCallback(
    () => (
      <Button className='text-nowrap' variant='secondary' size='sm' onClick={() => setIsComposing((v) => !v)}>
        COMPOSE MESSAGE
      </Button>
    ),
    []
  );

  const onComposeComplete = useCallback(() => {
    messagesTableRef?.current?.loadData();
  }, [messagesTableRef?.current]);

  const onComposeHide = useCallback(() => {
    setIsComposing(false);
    // Clear the URL params, if any exist
    history.push('#');
  }, [setIsComposing]);

  useEffect(() => {
    if (secureMessageEnabled) {
      dispatch(getUnreadMessageCount());
    }
  }, [dispatch]);

  return (
    <FeatureGuard contentKey='secure-message.manage'>
      <PrivateLayout pageTitle='messages' subtoolbarChildren={renderSubtoolbarChildren()}>
        <MessageCompose isShown={isComposing} onHide={onComposeHide} onSent={onComposeComplete} />
        <Row className='mb-3 align-items-center'>
          <Col>
            <MessagesFilters onFilterChange={setSelectedFilter} />
          </Col>
          <Col xs={2}>
            <MessagesSearch onSearchChange={setSearchText} />
          </Col>
        </Row>
        {/* Do not render the table until we have a filter selected */}
        {selectedFilter && (
          <MessagesTable ref={messagesTableRef} filteredStatus={selectedFilter} searchText={searchText} />
        )}
      </PrivateLayout>
    </FeatureGuard>
  );
};
