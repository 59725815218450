import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import { EHIPAA_VERIFY_URL } from '../../api';
import CopyIcon from '../../images/copy_icon.png';
import { CONSENT_MODAL_LABEL, CONSENT_STATUS_ON_FILE_ENABLED } from '../../utils';
import ConsentStatusC from './ConsentStatus';
import ErrorMessage from './ErrorMessage';
import { ConsentContext } from './context';
import { ConsentStatusEnum } from './types';

export default function OnsiteConsent() {
  const { verificationCode, consentStatus, checkConsentStatus, showCheckStatus, sendInvite } = useContext(
    ConsentContext
  );
  const [copied, setCopied] = useState({ verificationCode: false, verificationCodeUrl: false });

  const getVerifyCodeButtonLabel = useMemo(() => {
    switch (consentStatus) {
      case ConsentStatusEnum.PENDING:
        return CONSENT_MODAL_LABEL.GET_VERIFICATION_CODE;
      case ConsentStatusEnum.INVITE_INPROGRESS:
        return CONSENT_MODAL_LABEL.GENERATING_CODE;
      case ConsentStatusEnum.INVITESENT:
      case ConsentStatusEnum.CHECK_INPROGRESS:
        return CONSENT_MODAL_LABEL.NEW_VERIFICATION_CODE;
      default:
        return CONSENT_MODAL_LABEL.GET_VERIFICATION_CODE;
    }
  }, [consentStatus]);

  const isCheckInProgress = useMemo(() => consentStatus === ConsentStatusEnum.CHECK_INPROGRESS, [consentStatus]);

  const isConsentSuccess = useMemo(() => consentStatus === ConsentStatusEnum.SUCCESS, [consentStatus]);

  const isInviteSent = useMemo(() => consentStatus === ConsentStatusEnum.INVITESENT, [consentStatus]);

  const isSendDisabled = useMemo(() => consentStatus === ConsentStatusEnum.INVITE_INPROGRESS, [consentStatus]);

  const resetCopyState = useCallback(() => {
    setCopied({ verificationCode: false, verificationCodeUrl: false });
  }, []);

  const copyText = useCallback((text: string, param: string) => {
    navigator.clipboard.writeText(text);
    setCopied((prevCopied) => {
      return { ...prevCopied, [param]: true };
    });
    setTimeout(() => {
      resetCopyState();
    }, 2000);
  }, []);

  const actionsJustifyPos = useMemo(() => (isConsentSuccess ? 'end' : 'between'), [isConsentSuccess]);

  return (
    <>
      {(isInviteSent || isCheckInProgress) && verificationCode && (
        <div className='d-flex justify-content-between align-items-center cmx__request_consent-modal__div-verification-info'>
          <div className='d-flex flex-column'>
            <label className='cmx__request_consent-modal__label-verification-code'>Verification Code:</label>
            <div className='d-flex align-items-center'>
              <span className='cmx__request_consent-modal__span-verification-code'>{verificationCode}</span>
              {copied.verificationCode ? (
                <Check className='cmx__request_consent_modal__copied-icon' height={25} width={30} color='#789a3e' />
              ) : (
                <img
                  src={CopyIcon}
                  onClick={() => {
                    copyText(verificationCode, 'verificationCode');
                  }}
                  className='ml-1 cmx__request_consent_modal__copy-icon'
                  height={25}
                  width={25}
                />
              )}
            </div>
          </div>
          <div className='d-flex align-items-center cmx__request_consent-modal__div-verification-url-wrap'>
            <div className='d-flex flex-column cmx__request_consent-modal__div-verification-url'>
              <label className='cmx__request_consent-modal__label-verification-code'>Verification Code URL:</label>
              <a href={EHIPAA_VERIFY_URL} target='default' className='cmx__request_consent-modal__a-verification-url'>
                {EHIPAA_VERIFY_URL}
              </a>
            </div>
            {copied.verificationCodeUrl ? (
              <Check className='cmx__request_consent_modal__copied-icon' size={25} color='#789a3e' />
            ) : (
              <img
                src={CopyIcon}
                onClick={() => {
                  copyText(EHIPAA_VERIFY_URL, 'verificationCodeUrl');
                }}
                className='ml-1 cmx__request_consent_modal__copy-icon'
                height={25}
                width={25}
              />
            )}
          </div>
        </div>
      )}
      <ErrorMessage />
      <div
        className={`d-flex justify-content-${actionsJustifyPos} ${isConsentSuccess ? 'align-items-center' : ''} ${
          isConsentSuccess ? '' : 'mt-3'
        } cmx_consent_status`}>
        {!isConsentSuccess && (
          <>
            <Button
              size='sm'
              disabled={consentStatus === ConsentStatusEnum.INVITE_INPROGRESS}
              className='px-3 py-1 mr-3 button_font-12 cmx_consent_status__button-invite'
              variant='outline-secondary'
              onClick={() => {
                sendInvite();
              }}>
              {getVerifyCodeButtonLabel}
            </Button>
            {showCheckStatus && false && (
              <Button
                size='sm'
                disabled={isSendDisabled || isCheckInProgress}
                className='px-3 py-1 button_font-12 cmx_consent_status__button-invite'
                variant='outline-secondary'
                onClick={checkConsentStatus}>
                {isCheckInProgress ? CONSENT_MODAL_LABEL.CHECKING_STATUS : CONSENT_MODAL_LABEL.CHECK_STATUS}
              </Button>
            )}
          </>
        )}
        {CONSENT_STATUS_ON_FILE_ENABLED && <ConsentStatusC />}
      </div>
    </>
  );
}
