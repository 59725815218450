/* eslint @typescript-eslint/ban-ts-comment: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import { DropzoneOptions, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { AnyType } from '../../interfaces';
import { LoadingIndicator } from '../LoadingIndicator';
import { FilePreview } from './types';
import './FileUpload.scss';

export interface FileUploadProps {
  buttonAlign?: 'left' | 'right';
  disabled?: boolean;
  dropzoneOptions: DropzoneOptions;
  onChange?: (files: FilePreview[]) => void;
  onLoadEnd?: (files: FilePreview[]) => void;
  value?: FilePreview[];
  isLoading?: boolean;
  label?: string;
  showHelperText?: boolean;
  showGenericFileName?: boolean;
  showDocumentDate?: boolean;
}

export const FileUpload = (props: FileUploadProps) => {
  const [files, setFiles] = useState<FilePreview[]>([]);
  const { buttonAlign = 'left' } = props;
  const showHelperText = props.showHelperText ?? true;
  const showGenericFileName = props.showGenericFileName ?? false;
  const showDocumentDate = props.showDocumentDate ?? false;
  const maxSize = props.dropzoneOptions?.maxSize ?? 1000000;
  const maxSizeMB = maxSize / 1000000;
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [hideErrorOnRemoveFile, setHideErrorOnRemoveFile] = useState(false);
  useEffect(() => {
    if (props.value) {
      setFiles(props.value);
    }
  }, [props.value]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const formattedFiles = acceptedFiles.map((acceptedFile: File) => {
        const preview = URL.createObjectURL(acceptedFile);
        const sizeInMB = (acceptedFile.size / (1024 * 1024)).toFixed(2); // Size in MB with two decimal places
        let filePayload: Partial<FilePreview> = {
          ...acceptedFile,
          preview,
          date: new Date().toLocaleDateString(),
          size: Number(sizeInMB) // Convert to number
        };
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFile);
        reader.onloadend = () => {
          const bytes = reader?.result;
          filePayload = Object.assign(filePayload, { bytes });
          if (props.onLoadEnd) props.onLoadEnd(accumulateFiles);
        };
        return filePayload;
      });

      const accumulateFiles = [...formattedFiles, ...files].splice(0, props.dropzoneOptions?.maxFiles ?? 1);

      setFiles(accumulateFiles);
      setHideErrorOnRemoveFile(false);
      if (props.onChange) props.onChange(accumulateFiles);
    },
    [props]
  );

  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    onDrop: props.dropzoneOptions?.onDrop ?? onDrop,
    noClick: props.dropzoneOptions?.noClick ?? true,
    noKeyboard: props.dropzoneOptions?.noKeyboard ?? true,
    accept: props.dropzoneOptions?.accept ?? '.pdf',
    multiple: (props.dropzoneOptions?.maxFiles ?? 1) > 1,
    maxFiles: props.dropzoneOptions?.maxFiles ?? 1,
    maxSize: props.dropzoneOptions?.maxSize ?? 1000000 - totalFileSize * 1024 * 1024, // kb to mb
    ...props.dropzoneOptions
  });

  const removeFile = (file: FilePreview) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    props.onChange && props.onChange(newFiles);
    props.onLoadEnd && props.onLoadEnd(newFiles);
    setHideErrorOnRemoveFile(true);
  };

  const fileErrors = fileRejections.reduce(
    (acc: AnyType, rejection: FileRejection) => {
      if (rejection.errors.find((e: FileError) => e.code === 'file-invalid-type')) {
        return { ...acc, invalid: [...acc.invalid, rejection] };
      }
      if (rejection.errors.find((e: FileError) => e.code === 'file-too-large')) {
        return { ...acc, tooLarge: [...acc.tooLarge, rejection] };
      }
      if (rejection.errors.find((e: FileError) => e.code === 'too-many-files')) {
        return { ...acc, tooMany: [...acc.tooMany, rejection] };
      }
    },
    {
      invalid: [],
      tooLarge: [],
      tooMany: []
    }
  );

  const errors = fileRejections.length > 0 && (
    <ul className='text-danger fs-2 pl-3'>
      {fileErrors.invalid.length > 0 && <li>Please upload a pdf document.</li>}
      {fileErrors.tooLarge.length > 0 &&
        (showGenericFileName ? (
          <li>Please upload a pdf document less than {maxSizeMB} MB.</li>
        ) : totalFileSize > 0 ? (
          <li>
            Uploaded files size is {totalFileSize} MB. This upload is limited to a total cumulated file size of{' '}
            {maxSizeMB} MB.
          </li>
        ) : (
          <li>This upload is limited to a total cumulated file size of {maxSizeMB} MB.</li>
        ))}
      {fileErrors.tooMany.length > 0 && <li>Please upload 5 documents or less.</li>}
    </ul>
  );

  useEffect(() => {
    let totalSize = 0;
    if (files) {
      files.map((file) => {
        totalSize += file?.size;
      });
    }
    setTotalFileSize(Number(totalSize.toFixed(2)));
  }, [files]);

  const fileBadges = files.map((file: FilePreview, i: number) => (
    <div key={i}>
      <Badge pill variant='primary' className='px-2 py-1 mb-1'>
        <span className='text-truncate'>{showGenericFileName ? 'File uploaded on ' + file.date : file?.path}</span>
        <XCircleFill className='pointer ml-2' color='white' onClick={removeFile(file)} />
      </Badge>
      {!showGenericFileName && <label className='ml-3'>File size: {file?.size} MB</label>}
    </div>
  ));

  return (
    <div
      {...getRootProps()}
      className={`d-flex align-items-center cmx__file_upload ${
        buttonAlign === 'left' ? 'flex-row' : 'flex-row-reverse'
      }`}
      style={{ gap: 10 }}>
      {props?.dropzoneOptions?.maxFiles ? (
        <div>
          <div className='drop-upload__wrapper'>
            <div className='drop-upload__space d-flex flex-column align-items-center px-3 py-2'>
              <p className='consent-text-font cmx__file_upload__p-drag-drop'>Drag-and-drop your file in this box or</p>
              {/* @ts-ignore */}
              <input {...getInputProps()} />
              <Button
                className='mt-n2 mb-2 button_font-12 px-2 py-1 cmx__file_upload__button-upload-file'
                variant='outline-secondary'
                disabled={props.disabled}
                onClick={open}>
                {props.isLoading && <LoadingIndicator />}
                {props.label ?? 'UPLOAD A FILE'}
              </Button>
            </div>
            <div>{!showDocumentDate && fileBadges}</div>
          </div>
          {showHelperText && (
            <p className='drop-upload__help-text'>
              <small>
                Accepted format is: PDF <br />
                You may upload up to ({props?.dropzoneOptions?.maxFiles}) documents.
              </small>
            </p>
          )}
          {!hideErrorOnRemoveFile && errors}
        </div>
      ) : (
        <div>
          {/* @ts-ignore */}
          <input {...getInputProps()} />
          <Button className='mb-2 mr-2' variant='outline-secondary' disabled={props.disabled} onClick={open}>
            {props.isLoading && <LoadingIndicator />}
            {props.label ?? 'UPLOAD A FILE'}
          </Button>
          {errors}
        </div>
      )}
    </div>
  );
};
