import { ICellRendererParams } from 'ag-grid-community';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Image, Nav, OverlayTrigger, Spinner, Tooltip, Modal } from 'react-bootstrap';
import {
  ArrowLeft,
  ArrowRight,
  CheckSquare,
  ExclamationCircleFill,
  InfoCircleFill,
  Square
} from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { X } from 'react-bootstrap-icons';
import { AnyObject } from 'react-final-form';
import { FormOptions, FormTemplateRenderProps } from '@data-driven-forms/react-form-renderer';
import { FORM_ERROR } from 'final-form';
import {
  DEFAULT_DATE_FORMAT_WITH_TIME,
  FIELD_NAMES,
  defaultDateFormatWithAge,
  getEditAdminDoseSchema,
  getviewAdminDoseSchema,
  localDateFormat
} from '../../utils';
import { useSelector } from 'react-redux';
import { ActionsCellEditor, ActionsCellRenderer } from '.';
import { ACTION_NEEDED, INCOMING_MESSAGE, nextStepParser, OUTGOING_MESSAGE, RECENTLY_UPDATED } from '..';
import { useGetDocument, useForgotPassword, useSetRole, EditRxAdminDose, useEditRxAdminDose } from '../../api';
import { HcpModal } from '../../components/HcpModal';
import { ServiceRequestViewFragment, usePatientInfoQuery, usePhysiciansInfoQuery } from '../../graphql';
import { useBinaryObjectUrl } from '../../hooks';
import { AnyType } from '../../interfaces';
import { AppRootState, AuthUser, useTenancyDrugListSelector } from '../../redux';
import { getFileExtension } from '../helpers';
import { clientApplicationNameSelector } from '../tenancy-utils';
import * as renderers from './renderers';
import { HcpRXEditDoseRender } from '../../components/DynamicForms/HcpRXEditDoseRender';
import { HcpFormTemplate } from '../../components/DynamicForms/HcpFormTemplate';
import { HcpButton } from '../../components/HcpButton';
import { isRxAdminEnabled } from '../../redux/helpers';
export interface Drug {
  DrugId?: number;
  DrugBrandName?: string;
  DrugGenericName?: string;
  DrugLabelName?: string;
  Manufacturer?: string;
  DrugDescription?: string;
  DrugNDC?: string;
  JCode1?: string;
  Jcode2?: string;
  Quantity?: string;
  DaysSupply?: string;
}

// -----===[Framework Components]===-----

const NextStepCellRenderer = (params: ICellRendererParams) => {
  const { data } = params;
  // NOTE: Fetching isRxAdminEnabled flag from redux store to tackle below issue
  // NOTE: 'NextStepCellRenderer' not created within 1000ms
  // https://github.com/ag-grid/ag-grid/issues/3222#issuecomment-548742369
  if (data.rxAdminAlert && isRxAdminEnabled()) {
    return (
      <span>
        {nextStepParser(data)} Dose ({data.rxAdminDose}) <br />
        {data.rxAdminStatus}
      </span>
    );
  }
  return nextStepParser(data);
};

const ServiceRequestAlertCellRenderer = (params: ICellRendererParams) => {
  const serviceRequest: ServiceRequestViewFragment = params.data;
  const displayActionAlert = serviceRequest.actionAlert;
  const displayUpdateAlert = serviceRequest.updateAlert;

  return (
    <div>
      {displayActionAlert && (
        <OverlayTrigger placement='top' overlay={<Tooltip id='action-needed'>{ACTION_NEEDED}</Tooltip>}>
          <ExclamationCircleFill size={15} className={`${displayUpdateAlert ? 'mr-2' : ''} text-danger`.trim()} />
        </OverlayTrigger>
      )}
      {displayUpdateAlert && (
        <OverlayTrigger placement='top' overlay={<Tooltip id='recently-updated'>{RECENTLY_UPDATED}</Tooltip>}>
          <InfoCircleFill className='text-info' size={15} />
        </OverlayTrigger>
      )}
    </div>
  );
};

const AlertCellRenderer = (params: ICellRendererParams) => {
  const displayActionAlert = params.data.actionAlertCount >= 1;
  const displayUpdateAlert = params.data.updateAlertCount >= 1;
  const rxAdminActionAlert = params.data.rxAdminAlert;
  return (
    <div>
      {(displayActionAlert || (rxAdminActionAlert && isRxAdminEnabled())) && (
        <OverlayTrigger placement='top' overlay={<Tooltip id='action-needed'>{ACTION_NEEDED}</Tooltip>}>
          <ExclamationCircleFill size={15} className={`${displayUpdateAlert ? 'mr-2' : ''} text-danger`.trim()} />
        </OverlayTrigger>
      )}
      {displayUpdateAlert && (
        <OverlayTrigger placement='top' overlay={<Tooltip id='recently-updated'>{RECENTLY_UPDATED}</Tooltip>}>
          <InfoCircleFill className='text-info' size={15} />
        </OverlayTrigger>
      )}
    </div>
  );
};

const MessageCellRenderer = (params: ICellRendererParams) => {
  const isOutgoing = params.data.lastMessageSource === 'HCP';

  return (
    <div>
      {isOutgoing ? (
        <OverlayTrigger placement='top' overlay={<Tooltip id='outgoing-message'>{OUTGOING_MESSAGE}</Tooltip>}>
          <ArrowLeft size='20' />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement='top' overlay={<Tooltip id='incoming-message'>{INCOMING_MESSAGE}</Tooltip>}>
          <ArrowRight size='20' color='black' />
        </OverlayTrigger>
      )}
    </div>
  );
};

const IsAdminRenderer = (params: ICellRendererParams) => {
  const isAdmin = params.data.isAdmin;
  const StatusIcon = isAdmin ? CheckSquare : Square;
  const currentUser: AuthUser | undefined = useSelector((state: AppRootState) => state.auth.session?.user);
  const isCurrentUser = currentUser?.email === params.data.email;
  const { mutate: setUserRole, loading } = useSetRole({});
  const applicationName = useSelector(clientApplicationNameSelector);

  const onClick = useCallback(async () => {
    if (isCurrentUser) return;

    if (
      window.confirm(
        `Are you sure you want to convert ${params.data.firstName} ${params.data.lastName} ${
          isAdmin ? 'from' : 'to'
        } Admin?`
      )
    ) {
      const newValue = !isAdmin;
      const result = await setUserRole({
        applicationName: applicationName,
        userName: params.data.userName,
        isAdmin: newValue
      });

      if (result.errors && result.errors.length > 0) {
        alert(result.errors.map((e) => e.description).join(' '));
        return;
      }

      params.setValue(newValue);

      return;
    }
  }, [isCurrentUser, isAdmin]);

  return (
    <div onClick={onClick}>
      <StatusIcon className='mr-2 text-info' size={18} /> {loading && <Spinner animation='border' size='sm' />}
    </div>
  );
};

const ViewDocumentRenderer = (params: ICellRendererParams) => {
  const fileExtension = getFileExtension(params.data.fileName);
  const fileIsPdf = fileExtension === 'pdf';
  const [modalShow, setModalShow] = useState(false);
  const [imageData, setImageData] = useState<string | undefined>(undefined);
  const [documentErrors, setDocumentErrors] = useState('');
  const documentId = Number(params.data.id ?? params.data.documentId);
  const organizationId = Number(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId));
  const { data, refetch: getDocument } = useGetDocument({ lazy: true });

  const onClick = useCallback(async () => {
    try {
      await getDocument({ queryParams: { documentId } });
    } catch (e) {
      setDocumentErrors(e.message);
    }
  }, [organizationId, documentId]);

  useEffect(() => {
    if (!data) return;
    if (data?.errors && data.errors.length > 0) {
      const errors = data.errors.map((e: AnyType) => e.description).join(' ');
      setDocumentErrors(errors);
      setModalShow(true);
      return;
    }

    setImageData(String(data?.document?.fileData));
    setModalShow(true);
  }, [data]);

  const dataIfFileIsPdf = useBinaryObjectUrl(fileIsPdf ? imageData : null, 'application/pdf');

  const imageOrPdf = (
    <>
      {!fileIsPdf ? (
        <Image src={`data:image/${fileExtension};base64,${imageData}`} />
      ) : (
        <object
          aria-label={`Document ${params.data.fileName}`}
          style={{ width: '100%', height: '600px' }}
          type='application/pdf'
          name='test'
          data={dataIfFileIsPdf}
        />
      )}
    </>
  );

  return (
    <div>
      <Nav.Item onClick={onClick}>
        <Nav.Link className='p-0'>VIEW</Nav.Link>
      </Nav.Item>
      <HcpModal
        enableCancel
        modalProps={{ show: modalShow, onHide: () => setModalShow(false) }}
        header={params.data.fileName ?? 'No file name'}
        body={documentErrors ? <p className='text-danger'>{documentErrors}</p> : imageOrPdf}
      />
    </div>
  );
};

const EditUserRenderer = (params: ICellRendererParams) => {
  return <div className='font-weight-normal fs-2 text-left text-secondary'>EDIT</div>;
};

const ResetPasswordRenderer = (params: ICellRendererParams) => {
  const { data } = params;
  const applicationName = String(useSelector(clientApplicationNameSelector));
  const currentUser: AuthUser | undefined = useSelector((state: AppRootState) => state.auth.session?.user);
  const isCurrentUser = currentUser?.email === data.email;
  const result = useForgotPassword({ lazy: true });

  const onClick = useCallback(() => {
    if (isCurrentUser) return;
    const confirmationMessage = `Are you sure you want to reset ${data.firstName} ${data.lastName}'s password?`;
    const confirmation = window.confirm(confirmationMessage);
    if (confirmation) {
      result.refetch({
        queryParams: {
          userName: data.userName,
          applicationName: applicationName
        }
      });
    }

    if (result.data?.errors && result.data.errors.length > 0) {
      const errors = result.data.errors?.map((e) => e.description).join(' ');
      alert(errors);
    }
  }, [applicationName, data, currentUser]);

  // NOTE: must return an empty string, otherwise, error
  // NOTE: 'ResetPasswordRenderer' not created within 1000ms
  // https://github.com/ag-grid/ag-grid/issues/3222#issuecomment-548742369
  if (isCurrentUser) return '';

  return (
    <div className='font-weight-normal fs-2 text-left text-secondary' onClick={onClick}>
      RESET
    </div>
  );
};

export const RxAdminActionRenderer = (params: ICellRendererParams) => {
  const { id } = useParams();
  const { rxAdminDoseFields } = FIELD_NAMES;
  const status = params?.data?.status == 'Completed' ? false : params?.data?.status == 'Canceled' ? false : true;
  const { mutate: EditRxAdminDose } = useEditRxAdminDose({});

  const drugList: Drug[] = useTenancyDrugListSelector();
  const [modalShow, setModalShow] = useState<boolean>(false);

  const onClick = () => {
    setModalShow(true);
  };

  const onHide = () => {
    setModalShow(false);
  };
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId) || '';

  const { data: patientResult } = usePatientInfoQuery({ variables: { patientId: id ?? '' } });
  const { data } = usePhysiciansInfoQuery({ variables: { organizationId } });
  const prescriber = data?.result?.find((p: AnyType) => p.id == patientResult?.result?.physicians?.[0]?.physicianId);

  const rxDoseData = useMemo(() => {
    return {
      drugName: drugList?.[0]?.DrugBrandName?.toUpperCase(),
      dose: params?.data?.dose ?? '',
      adminDate: params?.data?.date ?? '',
      status: params?.data?.status ?? '',
      notes: params?.data?.adminNotes ?? '',
      patientId: id,
      prescriber: `${params?.data?.prescriber} | ${params?.data?.npi}`,
      prescriberId: params?.data?.prescriberId
    };
  }, [drugList, patientResult?.result, params?.data, prescriber, id]);

  const defaultPayload = useMemo(() => buildRxAdminPayload(patientResult?.result, rxDoseData, status), [
    patientResult?.result,
    rxDoseData,
    params?.data?.status
  ]);
  const onSubmit = async (values: AnyObject) => {
    try {
      const payload: EditRxAdminDose = {
        id: params?.data?.id,
        adminDate: values[rxAdminDoseFields.adminDate]?.toISOString(),
        adminStatus: values[rxAdminDoseFields.status],
        adminNotes: values[rxAdminDoseFields.notes],
        physician: {
          id: parseInt(values[rxAdminDoseFields.prescriberId])
        }
      };
      const result = await EditRxAdminDose(payload);
      if (result?.errors?.length === 0) {
        setModalShow(false);
        params.colDef?.headerComponentParams?.invalidateTable?.();
        params?.colDef?.headerComponentParams?.invalidateAddRxAdmin();
      }
      if (result.errors && result.errors.length > 0) {
        const errors = result.errors?.map((e) => e.description).join(' ');
        // Return the form error to display
        return { [FORM_ERROR]: errors };
      }
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  return (
    <div>
      <Nav.Item>
        <Nav.Link className='p-0' onClick={onClick}>
          {status === false ? 'VIEW' : 'EDIT'}
        </Nav.Link>
      </Nav.Item>

      <Modal show={modalShow} size='lg' className='w-90' onHide={onHide}>
        <Modal.Header className='bg-primary text-light'>
          <Modal.Title as={'h6'}>{status === false ? 'VIEW' : 'EDIT'} DOSE</Modal.Title>
          <X className='pointer' size={24} onClick={onHide} />
        </Modal.Header>
        <Modal.Body>
          {modalShow && (
            <HcpRXEditDoseRender
              contentKey='form.rxAdmin.dose'
              initialValues={defaultPayload}
              onSubmit={onSubmit}
              noControls
              noErrorMessage
              FormTemplate={status ? EditRxAdminDoseView : undefined}
              onCancel={() => setModalShow(false)}
              schemaMethod={status == false ? getviewAdminDoseSchema : getEditAdminDoseSchema}
            />
          )}
        </Modal.Body>
        {!status && (
          <Modal.Footer className='justify-content-between'>
            <HcpButton
              type='reset'
              onClick={onHide}
              variant='transparent'
              className='text-danger mr-3'
              data-testid='cmx__modal-cancel-button'>
              CANCEL
            </HcpButton>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

const buildRxAdminPayload = (data?: AnyType | null, rxdoseData?: AnyType, status?: boolean) => {
  return {
    patient_name: data?.firstName + ' ' + data?.lastName + ' | ' + defaultDateFormatWithAge(data?.dob),
    product_name: rxdoseData?.drugName ?? '',
    rx_dose: rxdoseData?.dose ?? '',
    rx_administration_date: status
      ? new Date(rxdoseData?.adminDate?.split('T')?.join(' ')?.concat('Z UTC'))
      : localDateFormat(
          new Date(rxdoseData?.adminDate?.split('T')?.join(' ')?.concat('Z UTC')),
          DEFAULT_DATE_FORMAT_WITH_TIME
        ),
    status: rxdoseData?.status ?? '',
    notes: rxdoseData?.notes ?? '',
    prescriber_name: rxdoseData?.prescriber ?? '',
    prescriber_id: rxdoseData?.prescriberId,
    prescriberView_name: rxdoseData?.prescriber ?? ''
  };
};

const EditRxAdminDoseView = (props: FormTemplateRenderProps, loading?: boolean) => {
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, submitSucceeded, hasValidationErrors, submitError, values } = formProps.getState();
        return (
          <>
            <Modal.Footer className='d-flex justify-content-between'>
              <HcpButton
                variant='transparent'
                data-testid='cmx__cancel-button'
                className='text-danger mt-3 mt-md-0'
                onClick={formProps.onCancel}>
                CANCEL
              </HcpButton>
              <HcpButton
                className='mb-0 mb-lg-0 mr-lg-2'
                disabled={submitting || hasValidationErrors}
                type='submit'
                variant='secondary'
                data-testid='cmx__save_changes-button'
                loading={submitting}>
                SAVE CHANGES
              </HcpButton>
            </Modal.Footer>
            {submitError && <p className='text-danger'>{submitError}</p>}
          </>
        );
      }}
    />
  );
};
const RxAdminHover = (params: ICellRendererParams) => {
  return (
    <div>
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id='action-needed'>
            {params?.data?.rxAdminStatus}
            <br />
            (Dose: {params?.data?.rxAdminDose})
          </Tooltip>
        }>
        <div className='status'>
          <span className={`${params?.data?.rxAdminStatus}`}>{localDateFormat(params?.data?.rxAdminDate)}</span>
        </div>
      </OverlayTrigger>
    </div>
  );
};
export const hcpFrameworkComponents = {
  ...renderers,
  RxAdminHover,
  AlertCellRenderer,
  ActionsCellRenderer,
  ActionsCellEditor,
  IsAdminRenderer,
  NextStepCellRenderer,
  MessageCellRenderer,
  ServiceRequestAlertCellRenderer,
  ViewDocumentRenderer,
  ResetPasswordRenderer,
  EditUserRenderer,
  RxAdminActionRenderer
};
