import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SERVICES_GATEWAY_URL, RX_APPLICATION_NAME, RX_URL } from '../api';
import { AppRootState, authOrgDetailsSelector } from '../redux';
import { standardPortalConfig } from '../utils';
import { useRxModuleAPI } from './use-ebv';

export interface SummaryPageRequestOpts {
  transactionId: string;
  transactionType: string;
}

export const useSummaryRequest = ({ transactionId, transactionType }: SummaryPageRequestOpts) => {
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const portalApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  const phyzApplicationName = 'PhyzConnect';
  const initURL = transactionType === TransactionType.MED_EBV ? SERVICES_GATEWAY_URL + '/module' : RX_URL;
  const headers =
    transactionType === TransactionType.MED_EBV
      ? {}
      : {
          'Access-Control-Allow-Origin': 'true',
          mode: 'no-cors',
          withCredentials: true
        };
  const withCredentials = transactionType === TransactionType.MED_EBV ? false : true;
  const request = useRxModuleAPI({
    headers: headers,
    lazy: true,
    method: 'POST',
    url: `${initURL}/api/${getSummeryAPiType(transactionType)}/Summary`,
    withCredentials: withCredentials,
    data: JSON.stringify({
      ClientApplicationName: standardPortalConfig.isStandardPortalUrl
        ? standardPortalConfig.applicationName
        : portalApplicationName === 'PhyzProviderPortal'
        ? phyzApplicationName
        : portalApplicationName ?? RX_APPLICATION_NAME,
      ExternalAccountID: organizationId,
      TransactionId: transactionId
    })
  });

  useEffect(() => {
    if (transactionId && transactionType && organizationId) {
      request?.fetchData();
    }
  }, [transactionId, transactionType, organizationId]);

  return request;
};

export enum TransactionType {
  MED_EBV = 'Benefit Verification - Medical eBV',
  MED_PA = 'Prior Authorization - Medical ePA',
  RX_EBV = 'Benefit Verification - Pharmacy',
  RX_PA = 'Prior Authorization - Pharmacy ePA'
}

const getSummeryAPiType = (transactionType: string) => {
  switch (transactionType) {
    case TransactionType.MED_EBV:
      return 'MedeBV';
    case TransactionType.MED_PA:
      return 'MedPA';
    case TransactionType.RX_EBV:
      return 'RxeBV';
    case TransactionType.RX_PA:
      return 'RxePA';
  }
};

export const ServiceRequestType = [
  TransactionType.MED_EBV.valueOf(),
  TransactionType.MED_PA.valueOf(),
  TransactionType.RX_EBV.valueOf(),
  TransactionType.RX_PA.valueOf()
];
