/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps } from 'restful-react';
export interface PortalFeature {
  portalFeatureId?: number;
  key?: string;
  data?: string;
  isCustom?: boolean;
}

export interface Error {
  code?: string;
  description?: string;
}

export interface OktaInfo {
  authurl?: string;
  clientId?: string;
}

export interface PortalFeatures {
  portalFeatures?: PortalFeature[];
  errors?: Error[];
}

export interface PortalClientDetails {
  clientId?: number;
  programId?: number;
  portalType?: string;
  name?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  fax?: string;
  domainURL?: string;
  headerLogo?: string;
  clientLogo?: string;
  applicationName?: string;
  cssOverrideFile?: string;
  oktaInfo?: OktaInfo;
  errors?: Error[];
}

export interface PortalResource {
  portalResourceId?: number;
  key?: string;
  data?: string;
  isCustom?: boolean;
}

export interface PortalResources {
  portalResources?: PortalResource[];
  errors?: Error[];
}

export interface PortalMenu {
  portalMenuId?: string;
  key?: string;
  data?: string;
  isCustom?: boolean;
}

export interface PortalMenus {
  portalMenus?: PortalMenu[];
  errors?: Error[];
}

export interface ServiceEntity {
  entityId?: string;
  description?: string;
  isActive?: string;
  sequence?: string;
  key?: string;
  data?: string;
  isEnabled?: string;
  isCustom?: string;
}

export interface Service {
  serviceId?: string;
  name?: string;
  isActive?: string;
  entities?: ServiceEntity[];
}

export interface ServiceResponse {
  service?: Service;
  errors?: Error[];
}

export interface Drug {
  DrugId?: number;
  DrugBrandName?: string;
  DrugGenericName?: string;
  DrugLabelName?: string;
  Manufacturer?: string;
  DrugDescription?: string;
  DrugNDC?: string;
  JCode1?: string;
  Jcode2?: string;
  Quantity?: string;
  DaysSupply?: string;
}

export interface Drugs {
  ClientDrugs?: Drug[];
  errors?: Error[];
}

export interface GetPortalConfigurationHcpQueryParams {
  url?: string;
}

export type GetPortalConfigurationHcpProps = Omit<
  GetProps<PortalClientDetails, unknown, GetPortalConfigurationHcpQueryParams, void>,
  'path'
>;

/**
 * Get the cofiguration information for the client
 */
export const GetPortalConfigurationHcp = (props: GetPortalConfigurationHcpProps) => (
  <Get<PortalClientDetails, unknown, GetPortalConfigurationHcpQueryParams, void>
    path={`/configuration/portal/hcp`}
    {...props}
  />
);

export type UseGetPortalConfigurationHcpProps = Omit<
  UseGetProps<PortalClientDetails, unknown, GetPortalConfigurationHcpQueryParams, void>,
  'path'
>;

/**
 * Get the cofiguration information for the client
 */
export const useGetPortalConfigurationHcp = (props: UseGetPortalConfigurationHcpProps) =>
  useGet<PortalClientDetails, unknown, GetPortalConfigurationHcpQueryParams, void>(`/configuration/portal/hcp`, props);

export interface GetConfigurationPortalHcpIdFeaturesPathParams {
  id: string;
}

export type GetConfigurationPortalHcpIdFeaturesProps = Omit<
  GetProps<PortalFeatures, unknown, void, GetConfigurationPortalHcpIdFeaturesPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdFeaturesPathParams;

/**
 * Featrures
 */
export const GetConfigurationPortalHcpIdFeatures = ({ id, ...props }: GetConfigurationPortalHcpIdFeaturesProps) => (
  <Get<PortalFeatures, unknown, void, GetConfigurationPortalHcpIdFeaturesPathParams>
    path={`/configuration/portal/hcp/${id}/features`}
    {...props}
  />
);

export type UseGetConfigurationPortalHcpIdFeaturesProps = Omit<
  UseGetProps<PortalFeatures, unknown, void, GetConfigurationPortalHcpIdFeaturesPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdFeaturesPathParams;

/**
 * Featrures
 */
export const useGetConfigurationPortalHcpIdFeatures = ({ id, ...props }: UseGetConfigurationPortalHcpIdFeaturesProps) =>
  useGet<PortalFeatures, unknown, void, GetConfigurationPortalHcpIdFeaturesPathParams>(
    (paramsInPath: GetConfigurationPortalHcpIdFeaturesPathParams) =>
      `/configuration/portal/hcp/${paramsInPath.id}/features`,
    { pathParams: { id }, ...props }
  );

export interface GetConfigurationPortalHcpIdResourcesQueryParams {
  group?: string;
}

export interface GetConfigurationPortalHcpIdResourcesPathParams {
  id: string;
}

export type GetConfigurationPortalHcpIdResourcesProps = Omit<
  GetProps<
    PortalResources,
    unknown,
    GetConfigurationPortalHcpIdResourcesQueryParams,
    GetConfigurationPortalHcpIdResourcesPathParams
  >,
  'path'
> &
  GetConfigurationPortalHcpIdResourcesPathParams;

/**
 * Get Resources
 */
export const GetConfigurationPortalHcpIdResources = ({ id, ...props }: GetConfigurationPortalHcpIdResourcesProps) => (
  <Get<
    PortalResources,
    unknown,
    GetConfigurationPortalHcpIdResourcesQueryParams,
    GetConfigurationPortalHcpIdResourcesPathParams
  >
    path={`/configuration/portal/hcp/${id}/resources`}
    {...props}
  />
);

export type UseGetConfigurationPortalHcpIdResourcesProps = Omit<
  UseGetProps<
    PortalResources,
    unknown,
    GetConfigurationPortalHcpIdResourcesQueryParams,
    GetConfigurationPortalHcpIdResourcesPathParams
  >,
  'path'
> &
  GetConfigurationPortalHcpIdResourcesPathParams;

/**
 * Get Resources
 */
export const useGetConfigurationPortalHcpIdResources = ({
  id,
  ...props
}: UseGetConfigurationPortalHcpIdResourcesProps) =>
  useGet<
    PortalResources,
    unknown,
    GetConfigurationPortalHcpIdResourcesQueryParams,
    GetConfigurationPortalHcpIdResourcesPathParams
  >(
    (paramsInPath: GetConfigurationPortalHcpIdResourcesPathParams) =>
      `/configuration/portal/hcp/${paramsInPath.id}/resources`,
    { pathParams: { id }, ...props }
  );

export interface GetConfigurationPortalHcpIdMenusPathParams {
  id: string;
}

export type GetConfigurationPortalHcpIdMenusProps = Omit<
  GetProps<PortalMenus, unknown, void, GetConfigurationPortalHcpIdMenusPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdMenusPathParams;

/**
 * Get Menus
 */
export const GetConfigurationPortalHcpIdMenus = ({ id, ...props }: GetConfigurationPortalHcpIdMenusProps) => (
  <Get<PortalMenus, unknown, void, GetConfigurationPortalHcpIdMenusPathParams>
    path={`/configuration/portal/hcp/${id}/menus`}
    {...props}
  />
);

export type UseGetConfigurationPortalHcpIdMenusProps = Omit<
  UseGetProps<PortalMenus, unknown, void, GetConfigurationPortalHcpIdMenusPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdMenusPathParams;

/**
 * Get Menus
 */
export const useGetConfigurationPortalHcpIdMenus = ({ id, ...props }: UseGetConfigurationPortalHcpIdMenusProps) =>
  useGet<PortalMenus, unknown, void, GetConfigurationPortalHcpIdMenusPathParams>(
    (paramsInPath: GetConfigurationPortalHcpIdMenusPathParams) => `/configuration/portal/hcp/${paramsInPath.id}/menus`,
    { pathParams: { id }, ...props }
  );

export interface GetConfigurationPortalHcpIdServicesNamePathParams {
  id: string;
  name: string;
}

export type GetConfigurationPortalHcpIdServicesNameProps = Omit<
  GetProps<ServiceResponse, unknown, void, GetConfigurationPortalHcpIdServicesNamePathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdServicesNamePathParams;

/**
 * Get Service Details
 */
export const GetConfigurationPortalHcpIdServicesName = ({
  id,
  name,
  ...props
}: GetConfigurationPortalHcpIdServicesNameProps) => (
  <Get<ServiceResponse, unknown, void, GetConfigurationPortalHcpIdServicesNamePathParams>
    path={`/configuration/portal/hcp/${id}/services/${name}`}
    {...props}
  />
);

export type UseGetConfigurationPortalHcpIdServicesNameProps = Omit<
  UseGetProps<ServiceResponse, unknown, void, GetConfigurationPortalHcpIdServicesNamePathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdServicesNamePathParams;

/**
 * Get Service Details
 */
export const useGetConfigurationPortalHcpIdServicesName = ({
  id,
  name,
  ...props
}: UseGetConfigurationPortalHcpIdServicesNameProps) =>
  useGet<ServiceResponse, unknown, void, GetConfigurationPortalHcpIdServicesNamePathParams>(
    (paramsInPath: GetConfigurationPortalHcpIdServicesNamePathParams) =>
      `/configuration/portal/hcp/${paramsInPath.id}/services/${paramsInPath.name}`,
    { pathParams: { id, name }, ...props }
  );

export interface GetConfigurationPortalHcpIdDrugsPathParams {
  id: string;
}

export type GetConfigurationPortalHcpIdDrugsProps = Omit<
  GetProps<Drug, unknown, void, GetConfigurationPortalHcpIdDrugsPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdDrugsPathParams;

/**
 * Your GET endpoint
 *
 * Get Drugs associated with the ClientId
 */
export const GetConfigurationPortalHcpIdDrugs = ({ id, ...props }: GetConfigurationPortalHcpIdDrugsProps) => (
  <Get<Drug, unknown, void, GetConfigurationPortalHcpIdDrugsPathParams>
    path={`/configuration/portal/hcp/${id}/drugs`}
    {...props}
  />
);

export type UseGetConfigurationPortalHcpIdDrugsProps = Omit<
  UseGetProps<Drug, unknown, void, GetConfigurationPortalHcpIdDrugsPathParams>,
  'path'
> &
  GetConfigurationPortalHcpIdDrugsPathParams;

/**
 * Your GET endpoint
 *
 * Get Drugs associated with the ClientId
 */
export const useGetConfigurationPortalHcpIdDrugs = ({ id, ...props }: UseGetConfigurationPortalHcpIdDrugsProps) =>
  useGet<Drug, unknown, void, GetConfigurationPortalHcpIdDrugsPathParams>(
    (paramsInPath: GetConfigurationPortalHcpIdDrugsPathParams) => `/configuration/portal/hcp/${paramsInPath.id}/drugs`,
    { pathParams: { id }, ...props }
  );
