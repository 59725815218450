import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { Envelope, ExclamationCircleFill } from 'react-bootstrap-icons';
import { AnyObject } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ManualBI, ManualPA } from '../components';

import { CaseDetailMainSectionSummaryTab, DocumentsTable, HcpRequestStateUI } from '../components';
import {
  CaseTransaction,
  CaseMileStone,
  GRAPHQL_DEFAULT_CACHE,
  useCaseDetailsLazyQuery,
  useCaseDetailsQuery,
  useCaseRecordDocumentsQuery,
  useCaseRecordsQuery
} from '../graphql';
import { AppRootState, authOrgDetailsSelector, getUnreadMessageCount, MessagesActionEnum } from '../redux';
import { extractCaseUrl, ROUTE_PATHS, UNREAD_MESSAGE } from '../utils';
import { BvSummaryModule } from '../views/BvSummaryDetails';
import './CaseDetailMainSectionTabs.scss';
import { CaseMessagesSection } from './CaseMessageSection';
import { EnrollmentModuleResult } from './EnrollmentModuleResult';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { dispatch } from 'rxjs/internal-compatibility';
import { PrescriptionDetails } from '../views/PrescriptionDetails';
import { BITabCollapsableSection } from './CaseDetailBITabCollapsableSection';
import { PATabCollapsableSection } from './CaseDetailPATabCollapsableSection';
import { AppealTabCollapsableSection } from './CaseDetailAppealTabCollapsableSection';

interface CaseDetailMainSectionTabsParams {
  caseId: string;
  transactionId: string;
}

interface CaseDetailMainSectionTabsParamsInterface {
  updateContent: number;
}

interface DocumentProps {
  __typename?: string | null;
  caseRecordId?: number | null;
  caseTransactionId?: number | null;
  created: string;
  createdBy?: string | null;
  documentId?: number | null;
  documentType?: string | null;
  entityId?: number | null;
  entityName?: string | null;
  fileName?: string | null;
  fileStorageId?: number | null;
  formComplete?: string | null;
  lastUpdated?: string | null;
  product?: string | null;
  programId?: number | null;
  signatureRequired?: boolean | null;
  source?: string | null;
  status?: string | null;
  totalPages?: number | null;
}

const summaryEventKey = '#caseDetail';
const messagesEventKeyTab = '#caseDetailMessages';
const documentsEventKeyTab = '#caseDetailDocuments';
const piEventKey = '#patientIntake';
const biEventKey = '#bi';
const paEventKey = '#pa';
const appealEventKey = '#appeal';
const enrollmentKeyTab = '#enrollment';
const availableTabs = [
  summaryEventKey,
  piEventKey,
  messagesEventKeyTab,
  biEventKey,
  documentsEventKeyTab,
  paEventKey,
  enrollmentKeyTab,
  appealEventKey
];

export const CaseDetailMainSectionTabs = (props: CaseDetailMainSectionTabsParamsInterface) => {
  const [updateContent, setUpdateContent] = useState(0);
  useEffect(() => {
    setUpdateContent(updateContent + 1);
  }, [props.updateContent]);
  const history = useHistory();
  const { caseId } = useParams<CaseDetailMainSectionTabsParams>();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const organizationId = useSelector(authOrgDetailsSelector)?.organizationId;
  const caseRecordDocumentsResult = useCaseRecordDocumentsQuery({ variables: { caseRecordId: caseId } });
  const caseRequestDocuments = caseRecordDocumentsResult?.data?.caseRecordDocuments;

  const caseResult = useCaseDetailsQuery({ variables: { caseRecordId: caseId } });
  const caseData = caseResult.data?.result;
  const transactions = caseData?.transactions ?? [];
  const allCaseMilestones = caseData?.mileStones?.flat<CaseMileStone[]>() ?? [];
  const openTransactions =
    transactions?.filter((element: any) => element?.caseTransactionState === 'False')?.flat<CaseTransaction[]>() ?? [];

  const prescriptionIntakes = (caseData?.prescriptionIntakes ?? [])[0];
  const patientEnrollment = (caseData?.patientEnrollments ?? [])[0];
  const orgDetail = useSelector((state: AppRootState) => state.auth?.session?.orgDetails);
  const practiceId = orgDetail && orgDetail?.organizationId ? String(orgDetail?.organizationId) : '';
  const transactionsLength = transactions.length;
  const onTabSelect = (eventKey: string | null) => {
    if (!eventKey) return;
    if (messagesEventKeyTab === eventKey) {
      setUpdateContent(updateContent + 1);
    }

    history.push(eventKey);
  };

  const PiActive = caseData?.prescriptionIntakes ?? [];
  const BiActive = (caseData?.benefitInvestigations ?? [])[0];
  const PaActive = (caseData?.priorAuthorizations ?? [])[0];
  const AppealActive = (caseData?.appeals ?? [])[0];

  const generateCaseDocumentType = () => {
    const documentArray: DocumentProps[] = [];
    caseRequestDocuments?.map((d) => {
      documentArray.push({
        __typename: d?.__typename ?? null,
        caseRecordId: d?.caseRecordId ?? null,
        caseTransactionId: d?.caseTransactionId ?? null,
        created: d?.created ?? new Date().toLocaleString() + '',
        createdBy: 'HCP Portal',
        documentId: d?.documentId ?? null,
        documentType: d?.documentType ?? null,
        entityId: d?.entityId ?? null,
        entityName: d?.entityName ?? null,
        fileName: d?.fileName ?? null,
        fileStorageId: d?.fileStorageId ?? null,
        formComplete: d?.formComplete ?? null,
        lastUpdated: d?.lastUpdated ?? null,
        product: null, //not called in CaseRecordDocumentsQuery
        programId: null,
        signatureRequired: d?.signatureRequired ?? null,
        source: d?.source ?? null,
        status: null,
        totalPages: null
      });
    });
    return documentArray;
  };

  const documents = generateCaseDocumentType()
    .sort((a, b) => a.created.localeCompare(b.created))
    ?.reverse();
  const hashValue: string | undefined = extractCaseUrl(useLocation())?.hashValue;
  const defaultActiveKey = hashValue ? getHashValue(hashValue) : summaryEventKey;
  const transactionType = 'Prior Authorization - Pharmacy ePA';
  const biTransactionType = 'Benefit Verification - Pharmacy';
  const [execQuery, result] = useCaseDetailsLazyQuery();

  useEffect(() => {
    if (!caseId || !currentUser) return;

    execQuery({
      variables: {
        caseRecordId: caseId
      }
    });
  }, [execQuery, currentUser, caseId]);

  const caseDetails = result?.data?.result;
  const tansactionId = getTransactionId(defaultActiveKey, caseDetails ?? []);
  const dispatch = useDispatch();
  const getUnreadCaseMessageCount = useCallback(() => dispatch(getUnreadMessageCount(caseId)), []);
  useEffect(() => {
    getUnreadCaseMessageCount();
  }, [props.updateContent]);

  return (
    <div className='cmx__case-detail-main-section-tabs m-4'>
      <div className='d-none'>
        {patientEnrollment?.patientEnrollmentGuid?.toString() && (
          <EnrollmentModuleResult
            enrollmentId={patientEnrollment?.patientEnrollmentGuid?.toString() || ''}
            practiceId={practiceId || ''}
            updateContent={updateContent}
          />
        )}
      </div>
      <HcpRequestStateUI
        errorUIEnabled
        isError={result.error}
        isLoading={result.loading}
        loadingUIEnabled
        reload={result.refetch}
        reloadingEnabled>
        <>
          <Tabs defaultActiveKey={defaultActiveKey} mountOnEnter onSelect={onTabSelect}>
            <Tab eventKey={summaryEventKey} title='SUMMARY'>
              <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                <CaseDetailMainSectionSummaryTab />
              </div>
            </Tab>
            {PiActive && (
              <Tab eventKey={piEventKey} title='PRESCRIPTION INTAKE'>
                <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                  <PrescriptionDetails
                    updateContent={updateContent}
                    prescriptionID={prescriptionIntakes?.prescriptionId?.toString() || ''}
                  />
                </div>
              </Tab>
            )}
            <Tab eventKey={messagesEventKeyTab} title={<MessageTabContent />}>
              <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                <CaseMessagesSection updateContent={updateContent} />
              </div>
            </Tab>
            <Tab eventKey={documentsEventKeyTab} title='DOCUMENTS'>
              <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                <DocumentsTable
                  documents={documents}
                  caseDetail={openTransactions}
                  caseMileStones={allCaseMilestones}
                  gridColumnFeatureKey='case-detail-page.documents.table'
                />
              </div>
            </Tab>
            {BiActive && (
              <Tab eventKey={biEventKey} title='BENEFIT INVESTIGATION'>
                <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                  <BITabCollapsableSection
                    benefitInvestigations={caseDetails?.benefitInvestigations}
                    orgnizationId={organizationId}
                  />
                  {/* NOTE : CAREMETXPD-7655 : Commented out */}
                  {/* {tansactionId && organizationId && BiActive.eBVTransactionId && (
                    <BvSummaryModule transactionId={tansactionId} transactionType={biTransactionType} />
                  )} */}

                  {/*
                  {BiActive?.eBVTransactionId == null && <ManualBI data={caseData?.benefitInvestigations} />}
                  */}
                  {!tansactionId && !organizationId && <p>Please select a valid Benefit Investigation</p>}
                </div>
              </Tab>
            )}
            {PaActive && (
              <Tab eventKey={paEventKey} title='PRIOR AUTHORIZATION'>
                <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                  <PATabCollapsableSection pas={caseDetails?.priorAuthorizations} orgnizationId={organizationId} />
                  {/* NOTE : CAREMETXPD-7655 : Commented out */}
                  {/* {tansactionId && organizationId && PaActive.ePATransactionID && (
                    <BvSummaryModule transactionId={tansactionId} transactionType={transactionType} />
                  )} */}

                  {/*
                  {PaActive?.ePATransactionID == null && <ManualPA data={caseData?.priorAuthorizations} />}
                  */}

                  {!tansactionId && !organizationId && <p>Please select a valid Prior Authorization</p>}
                </div>
              </Tab>
            )}
            {patientEnrollment?.patientEnrollmentGuid && (
              <Tab eventKey={enrollmentKeyTab} title='PATIENT ENROLLMENT'>
                <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                  <EnrollmentModuleResult
                    enrollmentId={patientEnrollment?.patientEnrollmentGuid?.toString() || ''}
                    practiceId={practiceId || ''}
                    updateContent={updateContent}
                  />
                </div>
              </Tab>
            )}
            {AppealActive && (
              <Tab eventKey={appealEventKey} title='APPEAL'>
                <div className='cmx__case-detail-main-section-tabs__tab-content card p-4'>
                  <AppealTabCollapsableSection appeals={caseDetails?.appeals} orgnizationId={organizationId} />
                  {!tansactionId && !organizationId && <p>Please select a valid Appeal</p>}
                </div>
              </Tab>
            )}
          </Tabs>
        </>
      </HcpRequestStateUI>
    </div>
  );
};

const MessageTabContent = () => {
  const { unreadMessagesCount } = useSelector((state: AppRootState) => state.messages);

  return (
    <div className='cmtx__message-tab'>
      <span className='cmtx__message-tab__text'>MESSAGES</span>
      <OverlayTrigger placement='top' overlay={<Tooltip id='2'>{UNREAD_MESSAGE}</Tooltip>}>
        <Badge pill variant='warning' className='message-badge'>
          {unreadMessagesCount}
        </Badge>
      </OverlayTrigger>
    </div>
  );
};

export const getTransactionId = (hash: string, caseDetails: AnyObject) => {
  switch (hash) {
    case '#bi':
      if (caseDetails?.benefitInvestigations?.[0]?.bIType === 'Pharmacy') {
        return caseDetails?.benefitInvestigations?.[0]?.eBVTransactionId ?? null;
      } else {
        return caseDetails?.benefitInvestigations?.[1]?.eBVTransactionId ?? null;
      }
    case '#bi-Medical':
      if (caseDetails?.benefitInvestigations?.[0]?.bIType === 'Medical') {
        return caseDetails?.benefitInvestigations?.[0]?.eBVTransactionId ?? null;
      } else {
        return caseDetails?.benefitInvestigations?.[1]?.eBVTransactionId ?? null;
      }
    case '#bi-Pharmacy':
      if (caseDetails?.benefitInvestigations?.[0]?.bIType === 'Pharmacy') {
        return caseDetails?.benefitInvestigations?.[0]?.eBVTransactionId ?? null;
      } else {
        return caseDetails?.benefitInvestigations?.[1]?.eBVTransactionId ?? null;
      }
    case '#pa':
      if (caseDetails?.priorAuthorizations?.[0]?.pAType === 'Pharmacy PA') {
        return caseDetails?.priorAuthorizations?.[0]?.ePATransactionID ?? null;
      } else {
        return caseDetails?.priorAuthorizations?.[1]?.ePATransactionID ?? null;
      }
    case '#pa-Medical PA':
      if (caseDetails?.priorAuthorizations?.[0]?.pAType === 'Medical PA') {
        return caseDetails?.priorAuthorizations?.[0]?.ePATransactionID ?? null;
      } else {
        return caseDetails?.priorAuthorizations?.[1]?.ePATransactionID ?? null;
      }
    case '#pa-Pharmacy PA':
      if (caseDetails?.priorAuthorizations?.[0]?.pAType === 'Pharmacy PA') {
        return caseDetails?.priorAuthorizations?.[0]?.ePATransactionID ?? null;
      } else {
        return caseDetails?.priorAuthorizations?.[1]?.ePATransactionID ?? null;
      }
    case '#appeal':
      if (caseDetails?.benefitInvestigations?.[0]?.bIType === 'Pharmacy') {
        return caseDetails?.benefitInvestigations?.[0]?.eBVTransactionId ?? null;
      } else {
        return caseDetails?.benefitInvestigations?.[1]?.eBVTransactionId ?? null;
      }
    default:
      return null;
  }
};

export const getHashValue = (hash: string) => {
  switch (hash) {
    case '#patientIntake':
      return '#patientIntake';
    case '#bi':
      return '#bi';
    case '#bi-Medical':
      return '#bi';
    case '#bi-Pharmacy':
      return '#bi';
    case '#pa':
      return '#pa';
    case '#pa-Medical PA':
      return '#pa';
    case '#pa-Pharmacy PA':
      return '#pa';
    case '#appeal':
      return '#appeal';
    default:
      return hash;
  }
};
