import React from 'react';
import { useParams } from 'react-router-dom';

import { CaseDetailMainSection, CaseDetailSidebar, PrivateLayout } from '../components';
import { usePatientInfoQuery } from '../graphql';
import { ROUTE_PATHS } from '../utils';
import './CaseDetail.scss';

interface CaseDetailViewParams {
  patientId: string;
  caseId: string;
}

export const CaseDetailView = () => {
  const { patientId, caseId } = useParams<CaseDetailViewParams>();

  const patientResult = usePatientInfoQuery({ variables: { patientId: patientId } });
  const patient = patientResult.data?.result;
  const patientName = [
    patient?.firstName?.trim() ?? '',
    patient?.middleName?.trim() ?? '',
    patient?.lastName?.trim() ?? ''
  ]
    .join(' ')
    .trim();

  return (
    <PrivateLayout
      pageTitle='case details'
      breadcrumbs={[
        { label: 'PATIENTS', href: ROUTE_PATHS.patients },
        { label: patientName, href: ROUTE_PATHS.patients + '/' + patient?.id },
        { label: 'CASES', href: ROUTE_PATHS.cases },
        { label: 'CASE #: ' + caseId, href: ROUTE_PATHS.patients + '/' + patient?.id + '/cases/' + caseId }
      ]}
      disableViewContainer={true}>
      <div className='cmx__case-detail-view-container h-100'>
        <CaseDetailSidebar />
        <CaseDetailMainSection />
      </div>
    </PrivateLayout>
  );
};
