import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { TextField } from '.';
import { PatientRequestViewModelFragment } from '../graphql';
import { defaultDateFormat, defaultDateFormatWithAge } from '../utils';

interface SelectedPatientProps {
  patient?: PatientRequestViewModelFragment;
}

export const SelectedPatient = ({ patient }: SelectedPatientProps) => {
  if (!patient) return null;

  return (
    <div className='bg-light-gray p-4 mb-4'>
      <p className='section-text'>SELECTED PATIENT</p>
      <Form.Row>
        <Form.Group sm={4} as={Col} controlId='patient_first_name'>
          <TextField
            disabled
            label='FIRST NAME'
            name='patient_first_name'
            defaultValue={patient.firstName}
            plaintext
            readOnly
            data-testid='cmx__selected-patient-first-name'
          />
        </Form.Group>
        <Form.Group sm={4} as={Col} controlId='patient_middle_name'>
          <TextField
            disabled
            label='MIDDLE NAME'
            name='patient_middle_name'
            defaultValue={patient.middleName ?? 'N/A'}
            plaintext
            readOnly
            data-testid='cmx__selected-patient-middle-name'
          />
        </Form.Group>
        <Form.Group sm={4} as={Col} controlId='patient_last_name'>
          <TextField
            disabled
            label='LAST NAME'
            name='patient_last_name'
            defaultValue={patient.lastName}
            plaintext
            readOnly
            data-testid='cmx__selected-patient-last-name'
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group sm={4} as={Col} controlId='dob'>
          <TextField
            disabled
            label='DATE OF BIRTH (AGE)'
            name='dob'
            defaultValue={defaultDateFormat(patient.dob)}
            format={(value: string) => defaultDateFormatWithAge(value)}
            plaintext
            readOnly
            data-testid='cmx__selected-date-of-birth'
          />
        </Form.Group>

        <Form.Group sm={4} as={Col} controlId='gender'>
          <TextField
            disabled
            label='GENDER'
            name='gender'
            defaultValue={patient.gender}
            plaintext
            readOnly
            data-testid='cmx__selected-patient-gender'
          />
        </Form.Group>

        {patient.gender === 'O' && (
          <Form.Group sm={4} as={Col} controlId='gender'>
            <TextField
              disabled
              label='OTHER GENDER'
              name='other_gender_patient'
              defaultValue={patient.genderOther}
              plaintext
              readOnly
              data-testid='cmx__selected-patient-gender-other'
            />
          </Form.Group>
        )}
      </Form.Row>
    </div>
  );
};
