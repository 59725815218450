import { useSelector } from 'react-redux';
import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

import { FIELD_NAMES, fieldSelectSection, REGENERON_PROVIDER_PORTAL_NAME, StepNames, titleSection } from '../..';
import {
  DBFormSchema,
  fieldDateSection,
  fieldRadioSection,
  fieldTextSection,
  headerSection,
  spacerSection,
  fieldDrugDescriptionSection,
  fieldQuantityUnitOfMeasureSection,
  fieldPlainTexBoldUnderlined,
  fieldTextLabel,
  plainTextSection
} from '../field-helpers';
import { signatureSection } from '../fields/signature-fields';

const { prescriptionFields, organizationFields } = FIELD_NAMES;

export const prescriptionTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const prescriptionTab = schema?.prescription_tab;
  if (!prescriptionTab || prescriptionTab?.hideTab) return null;
  return {
    title: 'Prescription',
    name: StepNames.PRESCRIPTION,
    nextStep: prescriptionTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.prescription_header?.label, schema?.prescription_header),
      plainTextSection(
        prescriptionFields.prescriptionMandatoryNotice,
        schema?.[prescriptionFields.prescriptionMandatoryNotice],
        {
          sizes: { md: 12 }
        }
      ),
      fieldTextLabel(prescriptionFields.fixedDose, schema?.[prescriptionFields.fixedDose], {
        sizes: { md: 12 }
      }),
      fieldPlainTexBoldUnderlined(
        prescriptionFields.recommendedStartingDose,
        schema?.[prescriptionFields.recommendedStartingDose],
        {
          sizes: { md: 12 }
        }
      ),
      fieldTextLabel(prescriptionFields.takeTablet, schema?.[prescriptionFields.takeTablet], {
        sizes: { md: 12 }
      }),
      fieldTextSection(prescriptionFields.sigAlternate, schema?.[prescriptionFields.sigAlternate], {
        sizes: { md: 12 }
      }),
      fieldDrugDescriptionSection(prescriptionFields.ndc, schema?.[prescriptionFields.ndc], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_0_1', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.tabletsPerCycleAnother, schema?.[prescriptionFields.tabletsPerCycleAnother], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.numberOfRefillsAnother, schema?.[prescriptionFields.numberOfRefillsAnother], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.height, schema?.[prescriptionFields.height], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.weight, schema?.[prescriptionFields.weight], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.bsa, schema?.[prescriptionFields.bsa], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_0_5', { sizes: { sm: 12 } }),
      titleSection(schema?.prescription_tablets_label?.label, schema?.prescription_tablets_label),
      spacerSection('prescription_0_7', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.initalDose, schema?.[prescriptionFields.initalDose], {
        sizes: { md: 12 }
      }),
      spacerSection('prescription_0_8', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.secondDose, schema?.[prescriptionFields.secondDose], {
        sizes: { md: 12 }
      }),
      spacerSection('prescription_0_9', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.sigAlternateLonsurf, schema?.[prescriptionFields.sigAlternateLonsurf], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_10', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.mgCycle15, schema?.[prescriptionFields.mgCycle15], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.mg20, schema?.[prescriptionFields.mg20], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.refillLonsurf, schema?.[prescriptionFields.refillLonsurf], {
        type: 'number',
        sizes: { md: 4 }
      }),
      spacerSection('prescription_0_2', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.milligrams15, schema?.[prescriptionFields.milligrams15], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.milligrams20, schema?.[prescriptionFields.milligrams20], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.fixedDoseTablet, schema?.[prescriptionFields.fixedDoseTablet], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_0_3', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.tabletsPerCycle, schema?.[prescriptionFields.tabletsPerCycle], {
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.qty, schema?.[prescriptionFields.qty], {
        type: 'number',
        sizes: { md: 4 }
      }),
      fieldQuantityUnitOfMeasureSection(prescriptionFields.uom, schema?.[prescriptionFields.uom], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_1', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.daysSupply, schema?.[prescriptionFields.daysSupply], {
        type: 'number',
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.numberOfRefills, schema?.[prescriptionFields.numberOfRefills], {
        type: 'number',
        sizes: { md: 4 }
      }),
      fieldTextSection(prescriptionFields.sig, schema?.[prescriptionFields.sig], {
        sizes: { md: 4 }
      }),
      fieldDateSection(prescriptionFields.effectiveDate, schema?.[prescriptionFields.effectiveDate], {
        sizes: { md: 4 }
      }),
      spacerSection('prescription_2', { sizes: { sm: 12 } }),
      fieldRadioSection(prescriptionFields.allowSubstitutions, schema?.[prescriptionFields.allowSubstitutions], {
        sizes: { md: 4 },
        options: [
          {
            value: 'y',
            label: 'Yes'
          },
          {
            value: 'n',
            label: 'No'
          }
        ]
      }),
      fieldTextSection(
        prescriptionFields.numberOfPeriodontalSites,
        schema?.[prescriptionFields.numberOfPeriodontalSites],
        {
          type: 'number',
          sizes: { md: 4 },
          condition: {
            when: prescriptionFields.qty,
            is: (value: number) => {
              if (value > 60) return true;
              return false;
            }
          }
        }
      ),
      spacerSection('prescription_1', { sizes: { sm: 12 } }),
      fieldTextSection(prescriptionFields.directionForUse, schema?.[prescriptionFields.directionForUse], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name',
            max: 210
          }
        ],
        condition: {
          not: {
            when: organizationFields.portalApplicationName,
            is: REGENERON_PROVIDER_PORTAL_NAME
          }
        }
      }),
      fieldSelectSection(prescriptionFields.directionForUse, schema?.[prescriptionFields.directionForUse], {
        sizes: { md: 4 },
        options: schema?.prescription_direction_for_use_options,
        condition: {
          when: organizationFields.portalApplicationName,
          is: REGENERON_PROVIDER_PORTAL_NAME
        }
      }),
      fieldTextSection(prescriptionFields.additionalNotes, schema?.[prescriptionFields.additionalNotes], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name',
            max: 210
          }
        ]
      }),
      spacerSection('prescription_3', { sizes: { sm: 12 } }),
      signatureSection(
        schema,
        prescriptionFields.signingAttestation,
        prescriptionFields.signature,
        prescriptionFields.signatureDate,
        prescriptionFields.physicianSignatureType
      ),
      spacerSection('prescription_4', { sizes: { sm: 12 } })
    ].filter(Boolean)
  };
};
