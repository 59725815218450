import React, { useCallback } from 'react';
import { HcpFormRenderer } from '.';
import { getVendorDetailsSchema } from '../utils/dynamic-forms/fields/vendor-details-fields';

interface VendorDetailsFormProps {
  copayCardDetails?: any | null;
}

export const VendorDetailsForm = ({ copayCardDetails }: VendorDetailsFormProps) => {
  const payload = useCallback(() => buildVendorDetails(copayCardDetails), [copayCardDetails]);
  return (
    <HcpFormRenderer
      contentKey='form.vendor-details'
      initialValues={payload()}
      noControls
      noErrorMessage
      schemaMethod={getVendorDetailsSchema}
    />
  );
};

const buildVendorDetails = (copayCardDetails?: any | null) => {
  return {
    vendor_name: copayCardDetails?.payeeName,
    vendor_address: copayCardDetails?.payeeAddress,
    tax_id: copayCardDetails?.taxID,
    total_amount_approved: copayCardDetails?.totalApprovedAmount ? `$ ${copayCardDetails?.totalApprovedAmount}` : '',
    total_amount_requested: copayCardDetails?.totalCopayRequestedAmount
      ? `$ ${copayCardDetails?.totalCopayRequestedAmount}`
      : '',
    total_amount_due: copayCardDetails?.totalDueAmount ? `$ ${copayCardDetails?.totalDueAmount}` : ''
  };
};
