import React from 'react';

import { RequestAddPatient, PrivateLayout } from '../components';
import { ROUTE_PATHS } from '../utils';

export const AddPatientView = () => {
  return (
    <PrivateLayout
      pageTitle='add a patient'
      breadcrumbs={[{ label: '<< back to patient list', href: ROUTE_PATHS.patients }]}>
      <RequestAddPatient />
    </PrivateLayout>
  );
};
