import React, { createRef, useEffect, useState } from 'react';
import { PhysicianFragment, usePhysiciansInfoLazyQuery, usePhysiciansInfoQuery } from '../graphql';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { SearchInputWithGql, SearchInputWithGqlDisplay } from './SearchInputWithGql';
import { FIELD_NAMES } from '../utils';
import { AnyObject } from 'final-form';
import { AddPrescriberView } from '../views';
import { useForm } from 'react-final-form';
import { AnyType } from '../interfaces';

const { prescriberFields } = FIELD_NAMES;

export const AddSearchPrescriber = (props: AnyObject) => {
  const form = useForm();
  const ref = createRef<HTMLDivElement>();
  const [prescriberId, setPrescriberId] = useState<any>(null);
  const organizationId = props?.organizationId || '';
  const { data, loading, refetch } = usePhysiciansInfoQuery({ variables: { organizationId } });
  const [defaultPrescriber, setDefaultPrescriber] = useState<any>(null);
  const [prescriberAdded, setPriscriberAdded] = useState(false);

  useEffect(() => {
    if (organizationId && prescriberId) {
      refetch();
    }
  }, [prescriberId]);

  useEffect(() => {
    if (!prescriberAdded) {
      setPrescriberId(defaultPrescriber?.physicianId);
    }
  }, [defaultPrescriber]);

  useEffect(() => {
    if (!prescriberAdded) {
      setDefaultPrescriber(props?.defaultPrescriber);
    }
  }, [props.defaultPrescriber, data]);

  useEffect(() => {
    if (prescriberId && data) {
      props.onPrescriberSelect(data?.result?.find((p: AnyType) => prescriberId?.toString() === p?.id));

      if (prescriberAdded) {
        const prescriber = data?.result?.find((p: AnyType) => prescriberId?.toString() === p?.id);
        setDefaultPrescriber(prescriber);
      }
    }
  }, [data, prescriberId]);

  const toggleAddInitialValue = !!(
    form.getState().values['adding_prescriber'] || form.getState().values[prescriberFields.id]
  );
  const [toggleAdd, setToggleAdd] = useState<boolean>(toggleAddInitialValue);

  const toggleReadFields = () => {
    if (form.getState().values['adding_prescriber'] === false) {
      form.change(prescriberFields.id, '');
      form.change(prescriberFields.npi, '');
      form.change(prescriberFields.firstName, '');
      form.change(prescriberFields.middleName, '');
      form.change(prescriberFields.lastName, '');
      form.change(prescriberFields.taxId, '');
      form.change(prescriberFields.stateLicenseNumber, '');
      form.change(prescriberFields.licensingState, '');
      form.change(prescriberFields.ptan, '');
      form.change(prescriberFields.dea, '');
      form.change(prescriberFields.email, '');
    }
  };

  const onPrescriberSelect = (prescriber: PhysicianFragment | null) => {
    form.batch(() => {
      form.change('adding_prescriber', false);
      setToggleAdd(false);
      form.change(prescriberFields.id, prescriber?.id);
      form.change(prescriberFields.firstName, prescriber?.firstName);
      form.change(prescriberFields.middleName, prescriber?.middleName);
      form.change(prescriberFields.lastName, prescriber?.lastName);
      form.change(prescriberFields.npi, prescriber?.npi || null);
      form.change(prescriberFields.taxId, prescriber?.details?.taxId);
      form.change(prescriberFields.stateLicenseNumber, prescriber?.stateLicenses?.stateLicenseNumber);
      form.change(prescriberFields.licensingState, prescriber?.stateLicenses?.stateIssuer);
      form.change(prescriberFields.ptan, prescriber?.details?.pTAN);
      form.change(prescriberFields.dea, prescriber?.details?.dEA);
      form.change(prescriberFields.email, prescriber?.email);
    });
    props.onPrescriberSelect(prescriber);
  };
  const onToggle = () => {
    setToggleAdd((prevToggle: boolean) => {
      form.change('adding_prescriber', !prevToggle);
      toggleReadFields();
      if (form.getState().values['adding_prescriber']) {
        setPrescriberId(null);
      }
      return !prevToggle;
    });
  };
  const renderLabel = (option: PhysicianFragment): string => {
    return `${option.firstName} ${option.lastName} | ${option.npi}`;
  };

  const addedNewPrescriber = (prescriberId: number | null | undefined) => {
    setPriscriberAdded(true);
    setPrescriberId(prescriberId);
    onToggle();
  };

  const getAddedPrescriber = () => {
    const prescriber: any =
      data?.result?.filter(
        (p: AnyType) => (prescriberId?.toString() || defaultPrescriber?.physicianId || defaultPrescriber?.id) == p?.id
      ) || [];
    if (!props?.prescriber) {
      props.setPrescriber(prescriber?.[0]);
    }
    return prescriber;
  };

  return (
    <>
      <Form.Group {...props.sizes}>
        <p className='fs-4 font-weight-bold text-primary mt-3 mb-4 text-uppercase'>Who is patient&apos;s prescriber?</p>
        <Row>
          <Col className='mb-3'>
            <Row>
              <Col className='flex-nowrap w-75'>
                {prescriberId &&
                  data?.result &&
                  !loading &&
                  data?.result.filter((p: AnyType) => prescriberId?.toString() === p?.id).length > 0 && (
                    <SearchInputWithGql
                      asyncTypeaheadProps={{
                        ...props.asyncTypeaheadProps,
                        ref,
                        defaultSelected: data?.result ? getAddedPrescriber() : []
                      }}
                      display={SearchInputWithGqlDisplay.outlined}
                      labelRenderer={renderLabel}
                      queryHook={usePhysiciansInfoLazyQuery}
                      onItemSelect={onPrescriberSelect}
                      placeholder='Search Prescriber'
                      isDisabled={props?.isDisabled}
                    />
                  )}
                {!prescriberId && (
                  <SearchInputWithGql
                    asyncTypeaheadProps={{
                      ref
                    }}
                    display={SearchInputWithGqlDisplay.outlined}
                    labelRenderer={renderLabel}
                    queryHook={usePhysiciansInfoLazyQuery}
                    onItemSelect={onPrescriberSelect}
                    placeholder='Search Prescriber'
                  />
                )}
              </Col>
              <Col>
                <Button
                  variant='outline-secondary'
                  className='ml-2 text-nowrap'
                  onClick={onToggle}
                  disabled={props?.isDisabled}>
                  {toggleAdd ? 'CANCEL' : 'ADD A PRESCRIBER'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>

      {form.getState().values['adding_prescriber'] && (
        <>
          <AddPrescriberView
            isStartRequestPage={true}
            patientId={props.patientId}
            addedNewPrescriber={addedNewPrescriber}
          />
        </>
      )}
    </>
  );
};
