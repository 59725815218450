import React from 'react';

import { Col, ProgressBar, Row } from 'react-bootstrap';
import Field from '@data-driven-forms/react-form-renderer/dist/cjs/field';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';
import { AnyObject } from 'react-final-form';

interface FormWizardProgressProps {
  currentStep: Schema & AnyObject;
  fields?: Field[];
}

export const FormWizardProgress = ({ currentStep, fields }: FormWizardProgressProps) => {
  const currentIndex = fields?.findIndex((field) => field?.name === currentStep?.name) ?? 0;
  const progress = (currentIndex * 100) / ((fields ?? []).length - 1);
  return (
    <Row>
      <Col lg={{ offset: 2, span: 10 }} className='px-0'>
        <ProgressBar now={progress} className='rounded-0' />
      </Col>
    </Row>
  );
};
