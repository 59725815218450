import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { boolean, object } from 'yup';
import {
  CheckboxField,
  ContentForRequiredScroll,
  FormWizardPageChangeContext,
  PublicLayout,
  ResourceContent
} from '../components';
import { AnyType, Breadcrumb } from '../interfaces';
import { FORM_WIZARD_VALUES, htmlProps, rawSafeHtmlProps, ROUTE_PATHS } from '../utils';
import { tenancyFeatureSelector } from '../redux/selectors';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';

export const termsAndConditionsSchema = object({
  acknowledge_terms: boolean().oneOf([true], 'Must Accept Terms and Conditions').default(undefined).required()
});

const REGISTRATION_FORM_PAGE_NAMES = FORM_WIZARD_VALUES?.pages?.registration?.names;

export const TermsAndConditionsView = () => {
  const [isBottom, setBottom] = useState(false);
  const termsAndConditionsSchema: AnyType = useSelector<AnyType>(tenancyFeatureSelector('form.termsAndConditions'));
  const termsAndConditionsFields = termsAndConditionsSchema?.fields;
  const termsAndConditions = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['terms-use.body' ?? ''] as AnyType)?.data
  );
  const domainURL = String(useSelector((state: AppRootState) => state.app?.config?.domainURL ?? ''));

  const modifidedTermsAndConditions = termsAndConditions
    ? termsAndConditions
        .replace(`https://standardhcpportal.caremetx.com`, `https://${domainURL}`)
        .replace(`https://standardhcpportal.caremetx.com/privacy`, `https://${domainURL}/privacy`)
    : '';

  const onScrollToBottom = () => {
    if (isBottom) return;

    setBottom(true);
  };

  const changeFormWizardPage = useContext(FormWizardPageChangeContext);

  const breadcrumbs: Breadcrumb[] = [
    {
      href: ROUTE_PATHS.registrationWelcome,
      label: 'WELCOME',
      testId: 'welcome'
    },
    {
      onClick: () => changeFormWizardPage(REGISTRATION_FORM_PAGE_NAMES.organization),
      label: 'ORGANIZATION INFORMATION',
      testId: 'organization'
    },
    {
      onClick: () => changeFormWizardPage(REGISTRATION_FORM_PAGE_NAMES.prescriber),
      label: 'PRESCRIBER',
      testId: 'prescriber'
    }
  ];

  return (
    <PublicLayout className='view-container' breadcrumbs={breadcrumbs} pageTitle='Terms and Conditions'>
      <p className='mt-3'>
        <ResourceContent contentKey='registration.terms.body' />
      </p>

      <ContentForRequiredScroll
        onScrollToBottom={onScrollToBottom}
        className='border border-gray rounded py-3 pl-3 pr-5 fs-2'
        style={{ maxWidth: '1500px', maxHeight: '300px' }}
        data-testid='cmx__terms-content'>
        <p className='mb-0'>
          <span {...htmlProps(modifidedTermsAndConditions)} />
        </p>
      </ContentForRequiredScroll>

      <p className='required-text mt-2'>
        <ResourceContent contentKey='registration.terms.scroll-notice' />
      </p>

      <Form.Row className='align-items-center ml-1 py-2'>
        <Form.Group controlId='acknowledge_terms'>
          <CheckboxField
            contentKey='registration.terms.agree-to-terms'
            inline='true'
            allowNull={false}
            name='acknowledge_terms'
            type='checkbox'
            disabled={!isBottom}
            label={termsAndConditionsFields?.acknowledgement_checkbox?.label}
            data-testid='cmx__terms-checkbox'
          />
        </Form.Group>
      </Form.Row>
    </PublicLayout>
  );
};
