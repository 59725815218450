import React, { useEffect, useState } from 'react';
import { PrivateLayout } from '../components';
import { RxAdmin } from './RxAdmin';
import flags from '../hooks/flags';
import { getAuthorizationHeader } from '../utils';
import { Redirect } from 'react-router';

export const RXAdminHome = () => {
  const show_injection_tracker = flags.useShowInjectionTracker();
  const [flagTimeout, setFlagTimeout] = useState(false);
  const bearerToken = getAuthorizationHeader();
  useEffect(() => {
    let timeoutId: any;
    if (!show_injection_tracker) {
      timeoutId = setTimeout(() => {
        setFlagTimeout(true);
      }, 3_000);
    } else {
      timeoutId && clearTimeout(timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [show_injection_tracker]);
  if (!bearerToken) {
    // redirect to home
    return <Redirect to='/' />;
  }
  if (show_injection_tracker) {
    // render rx admin import
    return (
      <>
        <PrivateLayout pageTitle='RX Admin'>
          <RxAdmin />
        </PrivateLayout>
      </>
    );
  }
  if (flagTimeout) {
    // redirect to home
    return <Redirect to='/' />;
  }

  return <p>Loading ....</p>;
};
