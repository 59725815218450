import React, { FC, useContext } from 'react';
import { Accordion, AccordionContext, Card, Col, Form, Row } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { dateFormatter } from '../utils';

export type BITabProps = {
  benefitInvestigations?: AnyType[] | AnyType;
  orgnizationId?: number;
};

export const BITabCollapsableSection = (biProps: BITabProps) => {
  const biFields = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['case-details.bi'] as AnyType)?.data?.fields || {}
  );

  type BIListType = { bIType: string; bICreatedDate: string; benefitInvestigations: AnyType[] };
  const bIList: AnyType[] = biProps?.benefitInvestigations || [];

  interface Props {
    children: React.ReactNode;
    eventKey: string;
  }

  const ContextAwareToggle: FC<Props> = (props: Props) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        className='cmx__case-detail-main-section-summary-tab__card-header'
        eventKey={props.eventKey}>
        <div>{props.children}</div>
        <hr />
        {isCurrentEventKey && <ChevronUp />}
        {!isCurrentEventKey && <ChevronDown />}
      </Accordion.Toggle>
    );
  };

  const FieldHeaderCol = ({ fieldName }: { fieldName: string }) => (
    <Col className={biFields?.[fieldName]?.hideField ? 'd-none' : 'md-'}>
      <Form.Label className='headerColor'>{biFields?.[fieldName]?.label}</Form.Label>
    </Col>
  );

  const FieldValueCol = ({ bi, fieldName, isDate }: { bi: AnyType; fieldName: string; isDate?: boolean }) => (
    <Col className={biFields?.[fieldName]?.hideField ? 'd-none' : ''}>
      <p className='text-gray'>{isDate ? dateFormatter(bi?.[fieldName]) : bi?.[fieldName]}</p>
    </Col>
  );

  const IndividualBICollapsableSection = ({ bIType, bICreatedDate, benefitInvestigations }: BIListType) => (
    <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
      <Card className='cmx__case-detail-main-section-summary-tab__card'>
        <ContextAwareToggle eventKey='4'>
          <strong>{`BENEFIT INVESTIGATION - ${bIType} - ${dateFormatter(bICreatedDate)}`.toUpperCase()}</strong>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey='4'>
          <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
            <Row>
              <FieldHeaderCol fieldName='therapyOrDrug' />
              <FieldHeaderCol fieldName='bIType' />
              <FieldHeaderCol fieldName='insuranceRepName' />
              <FieldHeaderCol fieldName='dateYearPlan' />
            </Row>
            {benefitInvestigations &&
              benefitInvestigations?.length > 0 &&
              benefitInvestigations?.map((bi: AnyType, index: number) => (
                <Row key={'bi-tab-collapsable-sec-data-first-row-' + index}>
                  <FieldValueCol bi={bi} fieldName='therapyOrDrug' />
                  <FieldValueCol bi={bi} fieldName='bIType' />
                  <FieldValueCol bi={bi} fieldName='insuranceRepName' />
                  {'Medical'.toLocaleLowerCase() === bi?.bIType?.toLocaleLowerCase() ? (
                    <FieldValueCol bi={bi} fieldName='dateYearPlan' />
                  ) : (
                    <Col />
                  )}
                </Row>
              ))}
            <br />
            <Row>
              <FieldHeaderCol fieldName='planEffectiveDate' />
              <FieldHeaderCol fieldName='planEndDate' />
              <FieldHeaderCol fieldName='coInsurancePercent' />
              <FieldHeaderCol fieldName='deductible' />
            </Row>
            {benefitInvestigations &&
              benefitInvestigations?.length > 0 &&
              benefitInvestigations?.map((bi: AnyType, index: number) => (
                <Row key={'bi-tab-collapsable-sec-data-second-row-' + index}>
                  <FieldValueCol bi={bi} fieldName='planEffectiveDate' isDate={true} />
                  <FieldValueCol bi={bi} fieldName='planEndDate' isDate={true} />
                  <FieldValueCol bi={bi} fieldName='coInsurancePercent' />
                  <FieldValueCol bi={bi} fieldName='deductible' />
                </Row>
              ))}
            <br />
            <Row>
              <FieldHeaderCol fieldName='deductibleAmountMet' />
              <FieldHeaderCol fieldName='outOfPocketMax' />
              <FieldHeaderCol fieldName='oopAmountMet' />
              <FieldHeaderCol fieldName='additionalComments' />
            </Row>
            {benefitInvestigations &&
              benefitInvestigations?.length > 0 &&
              benefitInvestigations?.map((bi: AnyType, index: number) => (
                <Row key={'bi-tab-collapsable-sec-data-third-row-' + index}>
                  <FieldValueCol bi={bi} fieldName='deductibleAmountMet' />
                  <FieldValueCol bi={bi} fieldName='outOfPocketMax' />
                  <FieldValueCol bi={bi} fieldName='oopAmountMet' />
                  <FieldValueCol bi={bi} fieldName='additionalComments' />
                </Row>
              ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  return (
    <div>
      {bIList?.map((bi: AnyType, index: number) => (
        <IndividualBICollapsableSection
          key={bi?.bIType + index}
          bIType={bi?.bIType}
          bICreatedDate={bi.bICreatedDate}
          benefitInvestigations={[bi]}
        />
      ))}
    </div>
  );
};
