import React from 'react';

import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

import { AnyType } from '../interfaces';

export enum PrescriberSearchInputIconLocation {
  prepend,
  append
}

interface UserSearchProps {
  onSearch?: (value: string | undefined) => void;
}

export const PrescriberSearchInput = (props: UserSearchProps) => {
  const onSearch = (e: AnyType) => {
    e.preventDefault();
    const value = e.target.value;
    props.onSearch && props.onSearch(value);
  };

  return (
    <InputGroup>
      <FormControl
        placeholder=''
        onChange={onSearch}
        aria-label='Patient'
        aria-describedby='basic-addon1'
        className='border-right-0'
      />
      <InputGroup.Prepend>
        <Button variant='transparent' className='border border-left-0 rounded-right px-3'>
          <Search className='text-gray' />
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  );
};
