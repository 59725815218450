import { FORM_ERROR } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Table } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { object } from 'yup';
import {
  ChannelDetails,
  NotificationPreference,
  Preference,
  useGetUserNotificationPreferences,
  useGetUsers,
  UserNotificationPreference,
  UserNotificationTypePreferences,
  useSaveUserNotificationPreferences
} from '../api';
import { AnyType, GeneralObject } from '../interfaces';
import { AppRootState } from '../redux/reducers';
import { clientApplicationNameSelector, validations } from '../utils';
import { EmailField, SwitchFieldCustom, PhoneField } from './FormFields';
import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

function getPreferenceFieldName(
  channelDetails: ChannelDetails | undefined,
  notificationTypeId: number | undefined
): string {
  return `preference${notificationTypeId}${channelDetails?.notificationChannelId}`;
}

function getChannelDetails(
  channelDetails: Array<ChannelDetails> | null | undefined,
  channelName: string | null | undefined
): ChannelDetails | undefined {
  if (!channelDetails || !channelName || channelDetails.length < 1) return;
  return channelDetails?.find((c) => c?.channelName?.toLowerCase() === channelName?.toLowerCase());
}

export const schema = object({
  user_email: validations.emailValidation().required()
});

export const NotificationSettings = () => {
  const currentUser = useSelector((state: AppRootState) => state?.auth?.session?.user);
  const { mutate: saveUserPreference } = useSaveUserNotificationPreferences({});
  const applicationName = useSelector(clientApplicationNameSelector);
  const userPreferences = useGetUserNotificationPreferences({}).data;
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const result = useGetUsers({ lazy: true });
  const [defaultNumber, setDefaultNumber] = useState<string>('');

  const loadData = useCallback(() => {
    if (!organizationId) return;

    result.refetch({});
  }, [organizationId]);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    if (result?.data?.errors?.length === 0) {
      const user = result?.data?.users?.find((u) => u.userName?.toLowerCase() === currentUser?.Login?.toLowerCase());
      setDefaultNumber(user?.cellNumber || '');
    }
  }, [result]);

  const getPreferences = (
    preference: Array<NotificationPreference> | null | undefined,
    values: GeneralObject
  ): Array<Preference> | undefined => {
    if (!preference) return;

    const preferenceArray: Array<AnyObject> = [];
    preference?.map((preference: NotificationPreference) => {
      preference?.channelDetails?.map((cd) => {
        const fieldName = getPreferenceFieldName(
          getChannelDetails(preference?.channelDetails, cd?.channelName),
          preference?.notificationTypeId
        );
        preferenceArray?.push({
          ...cd,
          optedOut: (!values[fieldName] ?? false) as boolean,
          notificationName: preference?.notificationName,
          notificationTypeId: preference?.notificationTypeId
        });
      });
    });
    return preferenceArray;
  };

  const savePreference = async (values: GeneralObject) => {
    const payload: UserNotificationPreference = {
      notificationEmail: String(values.user_email),
      notificationSMS: String(values.user_phone),
      userName: currentUser?.Login,
      applicationName: applicationName,
      preferences: getPreferences(userPreferences?.userNotificationTypePreferences?.preferences, values)
    };

    const result = await saveUserPreference(payload);
    if (result.errors && result.errors.length > 0) {
      return { [FORM_ERROR]: result.errors.map((e) => e.description).join(' ') };
    }

    window.location.reload();
  };

  const getSpecificPreferenceFieldNames = (field: string) => {
    const nameArray: Array<string> = [];
    userPreferences?.userNotificationTypePreferences?.preferences?.map((preference: NotificationPreference) => {
      preference?.channelDetails?.map((cd) => {
        if (cd.channelName?.toLowerCase() === field.toLowerCase()) {
          const fieldName = getPreferenceFieldName(
            getChannelDetails(preference?.channelDetails, field),
            preference?.notificationTypeId
          );
          nameArray.push(fieldName);
        }
      });
    });
    return nameArray;
  };

  const isSMSPreferenceSelected = (data: AnyObject) => {
    let smsSelected = false;
    const smsFieldNames = getSpecificPreferenceFieldNames('sms');
    smsFieldNames.forEach((name) => {
      smsSelected = smsSelected || data[name];
    });
    return smsSelected;
  };

  return (
    <>
      <div id='notification_section' className='mt-4'>
        <div>
          <p className='text-uppercase section-text mb-2'>Notifications</p>
          <p>
            Choose when you&#39;d like to receive notifications and which email address or phone number you&#39;d like
            them sent to
          </p>
        </div>
        <FinalForm
          onSubmit={savePreference}
          validate={(data) => {
            const dynamicSchema = object({
              user_email: validations.emailValidation().required(),
              user_phone: isSMSPreferenceSelected(data)
                ? validations.phoneValidation('All fields are required unless otherwise noted')
                : validations.phoneValidation('')
            });
            try {
              const values = dynamicSchema.validateSync(data, {
                abortEarly: false
              });
              return {};
            } catch (errors) {
              const e = errors.inner.reduce(
                (allErrors: AnyType, currentError: AnyType) => ({
                  ...allErrors,
                  [currentError.path]: currentError.message
                }),
                {}
              );

              return e;
            }
          }}
          render={({ handleSubmit, submitting, submitError, hasValidationErrors, hasSubmitErrors }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Table className='notification__options-list'>
                <thead>
                  <tr>
                    <th>Service/Activity</th>
                    <th>Email</th>
                    <th>SMS/Text</th>
                  </tr>
                </thead>
                <tbody>
                  {userPreferences &&
                    userPreferences?.userNotificationTypePreferences?.preferences?.map(
                      (preference: NotificationPreference) => (
                        <tr key={preference.notificationTypeId}>
                          <td>
                            <p className='mb-0'>{preference.notificationName}</p>
                          </td>
                          <td>
                            <SwitchFieldCustom
                              name={getPreferenceFieldName(
                                getChannelDetails(preference?.channelDetails, 'email'),
                                preference?.notificationTypeId
                              )}
                              defaultChecked={
                                getChannelDetails(preference?.channelDetails, 'email')?.optedOut
                                  ? false
                                  : !getChannelDetails(preference?.channelDetails, 'email')?.optedOut
                              }
                            />
                          </td>
                          <td>
                            <SwitchFieldCustom
                              name={getPreferenceFieldName(
                                getChannelDetails(preference?.channelDetails, 'sms'),
                                preference?.notificationTypeId
                              )}
                              defaultChecked={
                                getChannelDetails(preference?.channelDetails, 'sms')?.optedOut
                                  ? false
                                  : !getChannelDetails(preference?.channelDetails, 'sms')?.optedOut
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
              <Form.Row>
                <Form.Group className='col-6' controlId='user_email'>
                  <p className='mb-2 notification__label'>
                    This is the email address we&apos;ll send you notification to:
                  </p>
                  <EmailField
                    name='user_email'
                    defaultValue={
                      userPreferences?.userNotificationTypePreferences?.notificationEmail
                        ? userPreferences?.userNotificationTypePreferences?.notificationEmail
                        : currentUser?.email
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group controlId='user_phone' className='notification__no-label col-6'>
                  <p className='mb-2 notification__label'>
                    This is the Cell Number we&apos;ll send you a notification to:
                  </p>
                  <PhoneField
                    name='user_phone'
                    placeholder='(###) ###-####'
                    defaultValue={
                      userPreferences?.userNotificationTypePreferences?.notificationSMS
                        ? userPreferences?.userNotificationTypePreferences?.notificationSMS
                        : defaultNumber
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Button type='submit' variant='secondary text-uppercase' disabled={submitting || hasValidationErrors}>
                SAVE MY PREFERENCES
              </Button>
            </Form>
          )}
        />
      </div>
    </>
  );
};
