import React, { createRef, useCallback } from 'react';

import { Button, Col, Form } from 'react-bootstrap';
import { useField, useForm, useFormState } from 'react-final-form';

import {
  SearchInputWithGql,
  PrescribersInformationFields,
  SearchInputWithGqlDisplay,
  SelectedPrescribersForm,
  displayErrorText
} from '.';
import { PhysicianFragment, usePhysiciansInfoLazyQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { FIELD_NAMES } from '../utils';

const { prescriberFields } = FIELD_NAMES;

interface PrescriberSearchProps {
  onPrescriberSelect?: (patient: PhysicianFragment | null) => void;
}

const renderLabel = (option: PhysicianFragment): string => {
  return `${option.firstName} ${option.lastName} | ${option.npi}`;
};

export const PrescriberSearch = (props: PrescriberSearchProps) => {
  const { values } = useFormState();
  const isFormShowing = values.new_prescriber;
  const form = useForm();
  const prescriberIdField = useField(prescriberFields.id);
  const selectedPrescriber = !!values[prescriberFields.id];
  const searchRef = createRef<HTMLDivElement>();

  const setFormAndToggle = useCallback(() => {
    form.change('new_prescriber', !isFormShowing);
    form.change(prescriberFields.isNewPrescriber, !isFormShowing);
    onPrescriberSelect(null);
  }, [form, isFormShowing]);

  const onPrescriberSelect = (prescriber: PhysicianFragment | null) => {
    props.onPrescriberSelect && props.onPrescriberSelect(prescriber);

    form.batch(() => {
      (searchRef.current as AnyType)?.clear();
      form.change(prescriberFields.id, prescriber?.id);
      form.change(prescriberFields.firstName, prescriber?.firstName);
      form.change(prescriberFields.middleName, prescriber?.middleName);
      form.change(prescriberFields.lastName, prescriber?.lastName);
      form.change(
        prescriberFields.npi,
        // A server-sent blank string can cause a NaN-related error during validation conversion, so we default to null:
        prescriber?.npi || null
      );
      form.change(prescriberFields.taxId, prescriber?.details?.taxId);
      form.change(prescriberFields.stateLicenseNumber, prescriber?.stateLicenses?.stateLicenseNumber);
      form.change(prescriberFields.licensingState, prescriber?.stateLicenses?.stateIssuer);
    });
  };

  return (
    <>
      <Form.Row>
        <Form.Label>SELECT OR ADD A PRESCRIBER</Form.Label>
      </Form.Row>

      <Form.Row>
        <Col xs={5} className='mb-3'>
          <SearchInputWithGql
            asyncTypeaheadProps={{ ref: searchRef, inputProps: { disabled: isFormShowing } }}
            display={SearchInputWithGqlDisplay.outlined}
            labelRenderer={renderLabel}
            queryHook={usePhysiciansInfoLazyQuery}
            onItemSelect={onPrescriberSelect}
          />
          {displayErrorText(prescriberIdField)}
        </Col>
        <Col>
          <Button variant='outline-secondary' onClick={setFormAndToggle}>
            {isFormShowing ? 'CLEAR' : 'ADD A PRESCRIBER'}
          </Button>
        </Col>
      </Form.Row>

      <Form.Row>
        <p className='section-text'>PRESCRIBER INFORMATION</p>
      </Form.Row>

      {!isFormShowing && !selectedPrescriber && '---'}
      {!isFormShowing && selectedPrescriber && <SelectedPrescribersForm />}
      {isFormShowing && <PrescribersInformationFields />}
    </>
  );
};
