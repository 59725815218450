import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Label from 'react-bootstrap/FormLabel';
import { connect, useSelector } from 'react-redux';
import { AppRootState } from '../redux';

export const SettingsUserInfo = () => {
  const currentUser = useSelector((state: AppRootState) => state?.auth?.session?.user);

  return (
    <Row className='d-flex justify-content-between'>
      <Col lg={3} sm={4}>
        <Label>NAME</Label>
        <p className='font-weight-bold text-black-50'>{currentUser?.name}</p>
      </Col>
      <Col lg={3} sm={4}>
        <Label>USERNAME</Label>
        <p className='font-weight-bold text-black-50'>{currentUser?.Login}</p>
      </Col>
      <Col lg={3} sm={4}>
        <Label>EMAIL ADDRESS</Label>
        <p className='font-weight-bold text-black-50'>{currentUser?.email}</p>
      </Col>
    </Row>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ConnectedSettingsUserInfo = connect(mapStateToProps, mapDispatchToProps)(SettingsUserInfo);
