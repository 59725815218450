import React from 'react';

import { Col, Form } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { useForm } from 'react-final-form';

import { OrganizationNpiFragment } from '../graphql';
import flags from '../hooks/flags';
import { dataDrivenFormFieldSection } from '../utils/dynamic-forms';
import { RegistrationFormProps } from '../views';
import { NpiSearchOrganizationField } from './';
import { PhoneField, SelectField, TaxIdField, TextField, ZipField } from './FormFields';

export const OrganizationInfoForm = (props: RegistrationFormProps) => {
  const form = useForm();
  const states = flags.useStates();
  const onNpiSelect = (npi: OrganizationNpiFragment) => {
    form.change('organization_npi', Number(npi?.npi) || null);
    npi?.organizationName && form.change('organization_name', npi?.organizationName ?? '');
    npi?.address1 && form.change('address_1', npi?.address1 ?? '');
    npi?.address2 && form.change('address_2', npi?.address2 ?? '');
    npi?.city && form.change('city', npi?.city ?? '');
    npi?.state && form.change('state', npi?.state ?? '');
    npi?.zipCode && form.change('zip', (npi?.zipCode ?? '').substring(0, 5));
    npi?.taxId && form.change('tax_id', npi?.taxId ?? '');
    npi?.phoneNumber && form.change('organization_phone', npi?.phoneNumber ?? '');
    npi?.faxNumber && form.change('organization_fax', npi?.faxNumber ?? '');
  };

  if (props.isLoading) {
    return <> </>;
  }

  const formFields = props.formFields;

  return (
    <div>
      <Form.Row>
        <p className='section-text pb-2'>{formFields?.organization_info_header?.label}</p>
        <span>
          <InfoCircleFill className='ml-4 text-black-50' />
        </span>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={6}
          className={'hideField' in formFields?.organization_npi ? 'd-none' : ''}
          controlId='organization_npi'>
          <NpiSearchOrganizationField
            {...dataDrivenFormFieldSection('organization_npi', formFields?.organization_npi)}
            onNpiSelect={onNpiSelect}
          />
        </Form.Group>

        <Form.Group
          as={Col}
          xs={12}
          sm={6}
          controlId='organization_name'
          className={'hideField' in formFields?.organization_name ? 'd-none' : ''}>
          <TextField
            {...dataDrivenFormFieldSection('organization_name', formFields?.organization_name)}
            maxLength={formFields?.organization_name?.max}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId='address_1' className={'hideField' in formFields?.address_1 ? 'd-none' : ''}>
          <TextField
            {...dataDrivenFormFieldSection('address_1', formFields?.address_1)}
            maxLength={formFields?.address_1?.max}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId='address_2' className={'hideField' in formFields?.address_2 ? 'd-none' : ''}>
          <TextField {...dataDrivenFormFieldSection('address_2', formFields?.address_2)} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={4}
          controlId='city'
          className={'hideField' in formFields?.city ? 'd-none' : ''}>
          <TextField {...dataDrivenFormFieldSection('city', formFields?.city)} />
        </Form.Group>

        <Form.Group
          as={Col}
          xs={12}
          sm={4}
          controlId='state'
          className={'hideField' in formFields?.state ? 'd-none' : ''}>
          <SelectField options={states} {...dataDrivenFormFieldSection('state', formFields?.state)} />
        </Form.Group>

        <Form.Group as={Col} xs={12} sm={4} controlId='zip' className={'hideField' in formFields?.zip ? 'd-none' : ''}>
          <ZipField {...dataDrivenFormFieldSection('zip', formFields?.zip)} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={4}
          controlId='tax_id'
          className={'hideField' in formFields?.tax_id ? 'd-none' : ''}>
          <TaxIdField {...dataDrivenFormFieldSection('tax_id', formFields?.tax_id)} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={4}
          controlId='organization_phone'
          className={'hideField' in formFields?.organization_phone ? 'd-none' : ''}>
          <PhoneField {...dataDrivenFormFieldSection('organization_phone', formFields?.organization_phone)} />
        </Form.Group>

        <Form.Group
          as={Col}
          xs={12}
          sm={4}
          controlId='organization_fax'
          className={'hideField' in formFields?.organization_fax ? 'd-none' : ''}>
          <PhoneField {...dataDrivenFormFieldSection('organization_fax', formFields?.organization_fax)} />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
