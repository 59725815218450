import { AnyObject } from 'final-form';

import { FIELD_NAMES } from '..';
import { PatientRequest } from '../../api/add-patient.generated';

const { patientFields, caregiverFields } = FIELD_NAMES;
export const getAddPatientPayload = (values: AnyObject): PatientRequest => {
  return {
    FirstName: values[patientFields.firstName],
    MiddleName: values[patientFields.middleName],
    LastName: values[patientFields.lastName],
    DOB: values[patientFields.dob],
    Gender: values[patientFields.gender],
    GenderOther: values[patientFields.gender] === 'O' ? values[patientFields.otherGender] : null,
    PreferredLanguage: values[patientFields.preferredLanguage],
    Email: values[patientFields.email],
    PreferredLanguageOther: values[patientFields.otherLanguage] ?? null,
    Addresses: [
      {
        Address1: values[patientFields.address1],
        Address2: values[patientFields.address2],
        ZIP: values[patientFields.zip],
        City: values[patientFields.city],
        State: values[patientFields.state]
      }
    ],
    Phones: [
      values[patientFields.homeNumber] && {
        Type: values['isJanssen'] == true ? 'Day Phone' : 'home',
        PhoneNumber: values[patientFields.homeNumber].replace(/[^\d]/g, ''),
        OKtoLeaveMsg: values[patientFields.messageOption]?.includes('home') ? 'yes' : 'no'
      },
      values[patientFields.mobileNumber] && {
        Type: 'mobile',
        PhoneNumber: values[patientFields.mobileNumber].replace(/[^\d]/g, ''),
        OKtoLeaveMsg: values[patientFields.messageOption]?.includes('mobile') ? 'yes' : 'no'
      }
    ].filter((x) => !!x),
    Contacts: [
      values[patientFields.hasCaregiver]?.length && {
        PatientContactType: 'caregiver',
        FirstName: values[caregiverFields.firstName],
        MiddleName: values[caregiverFields.middleName],
        LastName: values[caregiverFields.lastName],
        Email: values[caregiverFields.email],
        LegallyAuthRep: values[caregiverFields.isAuthorizedRepForPatient]?.[0],
        HomePhoneNumber: values[caregiverFields.homePhone]?.replace(/[^\d]/g, ''),
        MobilePhoneNumber: values[caregiverFields.mobilePhone]?.replace(/[^\d]/g, ''),
        Relationship: values[caregiverFields.patientRelationship]
      }
    ].filter((x) => !!x)
  };
};
