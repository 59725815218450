import React from 'react';
import { Col } from 'react-bootstrap';

import './CaseDetailSidebar.scss';

import { CaseDetailSidebarNav, CaseDetailSidebarPatientInfo } from '../components';

export const CaseDetailSidebar = () => {
  return (
    <Col xs={4} md={2} lg={3} className='cmx__case-detail-sidebar d-flex flex-column overflow-hidden'>
      <CaseDetailSidebarPatientInfo />
      <CaseDetailSidebarNav />
    </Col>
  );
};
