import { AnyObject, FormApi, FORM_ERROR } from 'final-form';
import React, { Children, PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { AnyType, GeneralObject } from '../../interfaces';
import { scrollToTop } from '../../utils';
import { confirmWithUserAndPrepForBackNavigation } from './form-wizard-utils';
import { FormWizardContent } from './FormWizardContent';

interface FormWizardProps extends PropsWithChildren<unknown> {
  actionsClassName?: string;
  initialValues?: GeneralObject;
  onBack?: () => unknown;
  onCancel?: () => unknown;
  onSubmit: (values: GeneralObject) => void;
  onNext?: (currentPage: number, values: GeneralObject) => void;
  onPrevious?: (currentPage: number) => void;
  submitText?: string;
  isLastPage?: boolean;
}

export const FormWizard = (props: FormWizardProps) => {
  const [currentPage, setCurrentPage] = useState(0);

  const activePage = useCallback((): ReactElement<FormWizardPageProps> => {
    const children = props.children;
    return Children.toArray(children)[currentPage] as ReactElement;
  }, [currentPage]);

  const isLastPage = useCallback((): boolean => {
    if (props.isLastPage) return currentPage === Children.count(props.children) - 2;
    return currentPage === Children.count(props.children) - 1;
  }, [currentPage, props.children]);

  const validate = useCallback(
    (values: GeneralObject): AnyObject => {
      const { props: pageProps } = activePage();
      return pageProps.validate ? pageProps.validate(values) : {};
    },
    [activePage]
  );

  const onFormNext = useCallback(
    async (values: GeneralObject) => {
      return new Promise((resolve, reject) => {
        try {
          setCurrentPage((cp) => {
            const newPage = Math.min(cp + 1, Children.toArray(props.children)?.length - 1);
            // next tick this so the re-render happens after this components re-render
            setTimeout(() => props.onNext && props.onNext(newPage, values), 0);
            validate(values);
            scrollToTop();
            resolve(true);
            return newPage;
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    [props.children, props.onNext, validate]
  );

  const onFormPrevious = useCallback(
    (form: FormApi | undefined) => {
      if (!form) return;

      const navigationConfirmed = confirmWithUserAndPrepForBackNavigation(form);
      if (!navigationConfirmed) return;

      setCurrentPage((cp) => {
        const newPage = Math.max(cp - 1, 0);
        // next tick this so the re-render happens after this components re-render
        setTimeout(() => props.onPrevious && props.onPrevious(newPage), 0);
        scrollToTop();
        return newPage;
      });
    },
    [props.onPrevious]
  );

  const onFormSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (values: GeneralObject, _form: FormApi, _callback?: (errors: AnyObject) => void) => {
      const errors = await validate(values);
      if (errors && Object.keys(errors).length !== 0) return { validation: false };

      if (isLastPage()) {
        return props.onSubmit(values);
      }

      const { props: pageProps } = activePage();
      const nextErrors = pageProps.onFormNext && (await pageProps.onFormNext(values));

      if (nextErrors && Object.keys(nextErrors).length !== 0)
        return {
          [FORM_ERROR]: (
            <ul>
              {Object.keys(nextErrors).map((key: string) => (
                <li key={key}>{nextErrors[key]}</li>
              ))}
            </ul>
          )
        };

      return await onFormNext(values);
    },
    [activePage, isLastPage, props.onSubmit, onFormNext]
  );

  const getPageNumberByName = useCallback(
    (pageName: string | undefined): number => {
      if (pageName === undefined) return 0;

      const matchedPageIndex = Children.toArray(props.children).findIndex(
        (page: AnyType) => page?.props?.name === pageName
      );

      return Math.max(0, matchedPageIndex);
    },
    [props.children]
  );

  useEffect(scrollToTop, [currentPage]);

  return (
    <FinalForm initialValues={props?.initialValues} onSubmit={onFormSubmit} validate={validate}>
      {(formRenderProps: FormRenderProps) => {
        return (
          <FormWizardContent
            {...formRenderProps}
            actionsClassName={props.actionsClassName}
            getPageNumberByName={getPageNumberByName}
            isBackVisible={currentPage > 0 || !!props.onBack}
            isLastPage={isLastPage()}
            onBackClick={() =>
              currentPage > 0 ? onFormPrevious(formRenderProps.form) : props.onBack && props.onBack()
            }
            onCancelClick={props.onCancel}
            onPageChange={setCurrentPage}
            submitText={props.submitText}>
            {activePage()}
          </FormWizardContent>
        );
      }}
    </FinalForm>
  );
};

interface FormWizardPageProps {
  children: JSX.Element;
  name: string;
  onFormNext?: AnyType;
  validate: AnyType;
}

FormWizard.Page = ({ children }: FormWizardPageProps) => children;
