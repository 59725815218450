import { AnyType } from '../../interfaces';

export enum TransactionActionsEnum {
  CACHE_EBV_TRANSACTION = '[App] Cache Ebv Transaction'
}

export const ebvTransaction = (payload: AnyType) => ({
  type: TransactionActionsEnum.CACHE_EBV_TRANSACTION,
  payload
});
