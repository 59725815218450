import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import { useSelector } from 'react-redux';

import { StepNames } from '.';
import { AnyType } from '../../../interfaces';
import { AppRootState } from '../../../redux';
import { FIELD_NAMES } from '../../field-names';
import {
  DBFormSchema,
  fieldAttestationTypes,
  fieldTextSection,
  fieldUploadSection,
  plainItalicTextSection,
  plainItalicTextSectionWithRawHtml,
  spacerSection
} from '../field-helpers';

const { patientAttestationFields, patientFields } = FIELD_NAMES;

export const patientAttestationTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const patientAttestationTab = schema?.patient_attestation_tab;

  if (!patientAttestationTab || patientAttestationTab?.hideTab) return null;

  return {
    title: patientAttestationTab?.label ?? 'Patient Attestation & Signature',
    name: StepNames.PATIENT_ATTESTATION,
    nextStep: patientAttestationTab?.nextStep ?? nextStep,
    fields: [
      fieldAttestationTypes(
        patientAttestationFields.attestationType,
        schema?.[patientAttestationFields.attestationType],
        {
          sizes: { md: 4 },
          options: [
            {
              value: 'manual',
              label: 'Manual Attestation',
              description:
                '<p className="fs-3 font-italic"> Upload the patient consent document.PDF. If you don&apos;t have one, click HERE to download a blank form.</p>'
            }
          ]
        }
      ),
      spacerSection('patient_attestation_0', { sizes: { sm: 8 } }),
      ManualAttestaionTextSection(),
      spacerSection('patient_attestation_0', { sizes: { sm: 3 } }),
      fieldUploadSection(
        patientAttestationFields.attestationDocument,
        schema?.[patientAttestationFields.attestationDocument],
        {
          sizes: { md: 4 },
          condition: {
            when: patientAttestationFields.attestationType,
            is: 'manual'
          }
        }
      ),
      spacerSection('patient_attestation_1', { sizes: { sm: 12 } }),
      fieldAttestationTypes(
        patientAttestationFields.attestationType,
        schema?.[patientAttestationFields.attestationType],
        {
          sizes: { md: 4 },
          options: [
            {
              value: 'electronic',
              label: 'Electronic Attestation',
              description:
                '<p className="fs-3 font-italic"> Upload the patient consent document.PDF. If you don&apos;t have one, click HERE to download a blank form.</p>'
            }
          ]
        }
      ),
      spacerSection('patient_attestation_2', { sizes: { sm: 8 } }),
      plainItalicTextSection('electronic-attestation-text', {
        sizes: { md: 5 },
        content:
          '<p>Invite the patient to provide consent electronically via email. Your patient will receive an email from eHIPAA.com.</p>'
      }),
      spacerSection('patient_attestation_3', { sizes: { sm: 7 } }),
      fieldTextSection(patientFields.email, schema?.[patientAttestationFields.patientEmailAttestation], {
        sizes: { md: 4 },
        className: 'ml-5',
        readOnly: true
      }),
      fieldTextSection(patientFields.email, schema?.[patientAttestationFields.patientEmailEditableAttestation], {
        sizes: { md: 4 },
        className: 'ml-5'
      }),
      fieldTextSection(patientFields.mobileNumber, schema?.[patientAttestationFields.patientmobileNumber], {
        sizes: { md: 4 },
        type: 'tel',
        condition: {
          when: patientAttestationFields.attestationType,
          is: 'electronic'
        }
      }),
      spacerSection('patient_attestation_3', { sizes: { sm: 12 } }),
      fieldAttestationTypes(
        patientAttestationFields.attestationType,
        schema?.[patientAttestationFields.attestationType],
        {
          sizes: { md: 4 },
          options: [
            {
              value: 'request-assistance',
              label: 'Request Assistance'
            }
          ]
        }
      ),
      spacerSection('patient_attestation_2', { sizes: { sm: 8 } }),
      plainItalicTextSection('request-assistance-text', {
        sizes: { md: 5 },
        content: '<p>Please contact the patient directly to collect their authorization</p>'
      }),
      spacerSection('patient_attestation_4', { sizes: { sm: 12 } })
    ].filter(Boolean)
  };
};

const ManualAttestaionTextSection = () => {
  const manualAttestationPDF = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['manual-attestation.patient-consent-form' ?? ''] as AnyType)
        ?.data
  );
  const manualAttestationPDFLink =
    manualAttestationPDF?.type === 'pdf' &&
    manualAttestationPDF?.filename?.length > 0 &&
    manualAttestationPDF?.content?.length > 0
      ? `<a href="${manualAttestationPDF?.content}" download="${manualAttestationPDF?.filename}" target="_blank">HERE</a>`
      : manualAttestationPDF?.type === 'link' &&
        manualAttestationPDF?.filename?.length > 0 &&
        manualAttestationPDF?.content?.length > 0
      ? `<a href="${manualAttestationPDF?.content}" target="_blank">HERE</a>`
      : 'HERE';

  return plainItalicTextSectionWithRawHtml('manual-attestation-text', {
    sizes: { md: 5 },
    content: `<p> Upload a PDF of the completed patient consent form. If you don&apos;t have one, click ${manualAttestationPDFLink} to download a blank form. </p>`
  });
};
