import { difference } from 'lodash';

import { FIELD_NAMES } from '.';
import { FormFieldExclusions } from '../components';
import { AnyType, ServiceOptionType } from '../interfaces';

const {
  copayFields,
  facilityFields,
  organizationFields,
  prescriberFields,
  patientFields,
  insuranceFields,
  prescriberAttestationFields,
  patientAttestationFields,
  clinicalFields
} = FIELD_NAMES;

/**
 * Default exclusions
 */

export const DEFAULT_PATIENT_FIELD_EXCLUSIONS = [
  patientFields.address1,
  patientFields.address2,
  patientFields.city,
  patientFields.email,
  patientFields.homeNumber,
  patientFields.messageOption,
  patientFields.mobileNumber,
  patientFields.otherLanguage,
  patientFields.preferredLanguage,
  patientFields.state,
  patientFields.zip
];
export const PATIENT_PERSONAL_CONTACT_INFO_FIELD_EXCLUSIONS = [
  patientFields.preferredLanguage,
  patientFields.otherLanguage,
  patientFields.email,
  patientFields.homeNumber,
  patientFields.mobileNumber,
  patientFields.messageOption,
  patientFields.address1,
  patientFields.address2,
  patientFields.zip,
  patientFields.city,
  patientFields.state
];

export const ORGANIZATION_OFFICE_CONTACT_FIELD_EXCLUSION = [
  organizationFields.officeContactFirstName,
  organizationFields.officeContactLastName,
  organizationFields.officeContactPhone
];
export const DEFAULT_CLINICAL_FIELD_EXCLUSIONS = [
  clinicalFields.qty,
  clinicalFields.daysSupply,
  clinicalFields.ndc,
  clinicalFields.diagnosed,
  clinicalFields.primaryDiagnosed,
  clinicalFields.secondaryDiagnosed,
  clinicalFields.tertiaryDiagnosed
];
export const CLINICAL_OTHER_FIELD_EXCLUSIONS = [clinicalFields.otherDiagnosisCode];

export const DEFAULT_INSURANCE_FIELD_EXCLUSIONS = [
  patientFields.hasInsurance,
  patientFields.insurances,
  patientFields.otherInsurance,
  insuranceFields.primaryPlanName,
  insuranceFields.primaryPlanCardholderId,
  insuranceFields.primaryPlanGroupNumber,
  insuranceFields.primaryPlanPhoneNumber,
  insuranceFields.secondaryPlanName,
  insuranceFields.secondaryPlanCardholderId,
  insuranceFields.secondaryPlanGroupNumber,
  insuranceFields.secondaryPlanPhoneNumber,
  insuranceFields.pharmacyName,
  insuranceFields.pharmacyCardholderId,
  insuranceFields.pharmacyRxGroupNumber,
  insuranceFields.pharmacyRxBinIin,
  insuranceFields.pharmacyRxPcn,
  insuranceFields.pharmacyPhoneNumber,
  insuranceFields.otherPlanName,
  insuranceFields.otherPlanCardholderId,
  insuranceFields.otherPlanGroupNumber,
  insuranceFields.otherPlanPhoneNumber
];

/**
 * Named field sets to help with set manipulation
 */

export const COPAY_FIELDSET = Object.values(copayFields);
export const FACILITY_FIELDSET = Object.values(facilityFields);
export const ORGANIZATION_FIELDSET = Object.values(organizationFields);

export const PRESCRIBER_LOCATION_FIELDSET = [
  prescriberFields.locationAddress1,
  prescriberFields.locationAddress2,
  prescriberFields.locationAddressCity,
  prescriberFields.locationAddressState,
  prescriberFields.locationAddressZip,
  prescriberFields.locationName,
  prescriberFields.locationFaxNumber,
  prescriberFields.locationPhone1,
  prescriberFields.locationPhone1Type,
  prescriberFields.locationPhone2,
  prescriberFields.locationPhone2Type,
  prescriberFields.locationId
];

/**
 * Map Exclusions
 */

export const SERVICE_REQUEST_PATIENT_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.CopayEnrollment]: DEFAULT_PATIENT_FIELD_EXCLUSIONS,
  [ServiceOptionType.CopayClaim]: DEFAULT_PATIENT_FIELD_EXCLUSIONS,
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: PATIENT_PERSONAL_CONTACT_INFO_FIELD_EXCLUSIONS,
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: PATIENT_PERSONAL_CONTACT_INFO_FIELD_EXCLUSIONS,
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: PATIENT_PERSONAL_CONTACT_INFO_FIELD_EXCLUSIONS,
  [ServiceOptionType.MedicalElectronicBenefitVerification]: PATIENT_PERSONAL_CONTACT_INFO_FIELD_EXCLUSIONS,
  [ServiceOptionType.PatientResources]: difference(DEFAULT_PATIENT_FIELD_EXCLUSIONS, [patientFields.email])
};

export const SERVICE_REQUEST_INSURANCE_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.CopayClaim]: DEFAULT_INSURANCE_FIELD_EXCLUSIONS
};

export const SERVICE_REQUEST_PRESCRIBER_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.CopayClaim]: difference(ORGANIZATION_FIELDSET, [
    organizationFields.name,
    organizationFields.npi,
    organizationFields.taxId
  ]),
  [ServiceOptionType.PatientResources]: [...ORGANIZATION_FIELDSET, ...PRESCRIBER_LOCATION_FIELDSET],
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: ORGANIZATION_OFFICE_CONTACT_FIELD_EXCLUSION,
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: ORGANIZATION_OFFICE_CONTACT_FIELD_EXCLUSION,
  [ServiceOptionType.MedicalElectronicBenefitVerification]: ORGANIZATION_OFFICE_CONTACT_FIELD_EXCLUSION,
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: ORGANIZATION_OFFICE_CONTACT_FIELD_EXCLUSION
};
export const SERVICE_REQUEST_CLINICAL_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.All]: DEFAULT_CLINICAL_FIELD_EXCLUSIONS,
  [ServiceOptionType.CopayClaim]: DEFAULT_CLINICAL_FIELD_EXCLUSIONS,
  [ServiceOptionType.CopayEnrollment]: DEFAULT_CLINICAL_FIELD_EXCLUSIONS,
  [ServiceOptionType.InsuranceReview]: DEFAULT_CLINICAL_FIELD_EXCLUSIONS,
  [ServiceOptionType.PatientResources]: DEFAULT_CLINICAL_FIELD_EXCLUSIONS
};

export const SERVICE_REQUEST_CLINICAL_OTHER_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: CLINICAL_OTHER_FIELD_EXCLUSIONS,
  [ServiceOptionType.MedicalElectronicBenefitVerification]: CLINICAL_OTHER_FIELD_EXCLUSIONS,
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: CLINICAL_OTHER_FIELD_EXCLUSIONS,
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: CLINICAL_OTHER_FIELD_EXCLUSIONS
};

export const ALL_OTHER_FIELDSET = [...COPAY_FIELDSET, ...FACILITY_FIELDSET];
export const DEFAULT_OTHER_FIELDSET = [...FACILITY_FIELDSET];
export const ADDITIONAL_INFORMATION_FIELDSET = difference(COPAY_FIELDSET, []);
export const SERVICE_REQUEST_OTHER_TAB_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.All]: difference([...ALL_OTHER_FIELDSET, copayFields.emailId], DEFAULT_OTHER_FIELDSET),
  [ServiceOptionType.InsuranceReview]: difference(ALL_OTHER_FIELDSET, DEFAULT_OTHER_FIELDSET),
  [ServiceOptionType.CopayClaim]: [...DEFAULT_OTHER_FIELDSET, copayFields.cardId, copayFields.groupNumber]
};

export const PRESCRIBER_ATTESTATION_FIELD_EXCLUSIONS: FormFieldExclusions = {
  [ServiceOptionType.All]: [
    ...(SERVICE_REQUEST_OTHER_TAB_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.All],
    prescriberAttestationFields.signature
  ],
  [ServiceOptionType.CopayClaim]: [
    ...DEFAULT_PATIENT_FIELD_EXCLUSIONS,
    ...DEFAULT_INSURANCE_FIELD_EXCLUSIONS,
    ...(SERVICE_REQUEST_PRESCRIBER_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.CopayClaim],
    ...(SERVICE_REQUEST_OTHER_TAB_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.CopayClaim],
    prescriberAttestationFields.prescriberSigningAttestation
  ],
  [ServiceOptionType.InsuranceReview]: [
    ...(SERVICE_REQUEST_OTHER_TAB_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.InsuranceReview],
    prescriberAttestationFields.signature
  ],
  [ServiceOptionType.PatientResources]: [
    ...(SERVICE_REQUEST_PRESCRIBER_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.PatientResources],
    ...(SERVICE_REQUEST_PATIENT_FIELD_EXCLUSIONS as AnyType)[ServiceOptionType.PatientResources],
    prescriberAttestationFields.signature
  ],
  [ServiceOptionType.CopayEnrollment]: [
    ...DEFAULT_PATIENT_FIELD_EXCLUSIONS,
    ...DEFAULT_INSURANCE_FIELD_EXCLUSIONS,
    ...DEFAULT_OTHER_FIELDSET,
    ...ADDITIONAL_INFORMATION_FIELDSET,
    copayFields.additionalDocumentsUpload,
    insuranceFields.cardUpload,
    prescriberAttestationFields.signature
  ],
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: [
    ...DEFAULT_INSURANCE_FIELD_EXCLUSIONS,
    ...DEFAULT_OTHER_FIELDSET,
    ...ADDITIONAL_INFORMATION_FIELDSET,
    copayFields.additionalDocumentsUpload,
    insuranceFields.cardUpload,
    facilityFields.administrationInfo,
    patientAttestationFields.attestationType
  ],
  [ServiceOptionType.MedicalElectronicBenefitVerification]: [
    ...DEFAULT_PATIENT_FIELD_EXCLUSIONS,
    ...DEFAULT_OTHER_FIELDSET,
    ...ADDITIONAL_INFORMATION_FIELDSET,
    copayFields.additionalDocumentsUpload,
    insuranceFields.cardUpload,
    facilityFields.administrationInfo,
    patientAttestationFields.attestationType
  ],
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: [
    ...DEFAULT_PATIENT_FIELD_EXCLUSIONS,
    ...DEFAULT_OTHER_FIELDSET,
    ...ADDITIONAL_INFORMATION_FIELDSET,
    copayFields.additionalDocumentsUpload,
    insuranceFields.cardUpload,
    facilityFields.administrationInfo,
    patientAttestationFields.attestationType
  ],
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: [
    ...DEFAULT_PATIENT_FIELD_EXCLUSIONS,
    ...DEFAULT_INSURANCE_FIELD_EXCLUSIONS,
    ...DEFAULT_OTHER_FIELDSET,
    ...ADDITIONAL_INFORMATION_FIELDSET,
    copayFields.additionalDocumentsUpload,
    insuranceFields.cardUpload,
    facilityFields.administrationInfo,
    patientAttestationFields.attestationType
  ]
};
