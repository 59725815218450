import { RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedHcpGridWithGQL, PrescriberSearchInput } from '.';
import { usePhysiciansInfoQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState, navigateToPrescriberNew } from '../redux';
import { featureGridColumnsSelector, getFeatureGridColumnProps, isEmptyObject, rawHtmlProps } from '../utils';
import { FeatureGuard } from './multi-tenant/FeatureGuard';

const columnSelector = featureGridColumnsSelector('prescribers.table');

export const PrescribersTable = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>();
  const verbiageText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['account-administration-verbiage'] as AnyType)?.data || {}
  );

  const filterPrescribers = (node: RowNode, value: string | undefined) => {
    const name = `${node.data?.firstName} ${node.data?.lastName}`.toLowerCase();
    const npi = node.data?.npi?.toLowerCase();
    return name.includes(value ?? '') || npi.includes(value ?? '');
  };

  const columns = useSelector(columnSelector);

  if (!columns) return null;

  return (
    <>
      <div className='d-flex justify-content-between mb-3'>
        <div className='w-25'>
          <PrescriberSearchInput onSearch={setSearchValue} />
        </div>
        <FeatureGuard contentKey={'practice-management.prescribers.manage'}>
          <Button
            variant='secondary'
            data-testid='navigateToPrescriberNewBtn'
            onClick={() => dispatch(navigateToPrescriberNew())}>
            ADD A PRESCRIBER
          </Button>
        </FeatureGuard>
      </div>
      <ConnectedHcpGridWithGQL
        queryHook={usePhysiciansInfoQuery}
        isFilterActive={!!searchValue}
        filterComparator={filterPrescribers}
        filterValue={searchValue}
        pagination>
        <AgGridColumn field='firstName' {...getFeatureGridColumnProps(columns.firstName)} />
        <AgGridColumn field='lastName' {...getFeatureGridColumnProps(columns.lastName)} />
        <AgGridColumn field='npi' filter='agNumberColumnFilter' {...getFeatureGridColumnProps(columns.npi)} />
      </ConnectedHcpGridWithGQL>
      {!isEmptyObject(verbiageText) && (
        <span className='text-primary ag-theme-alpine' {...rawHtmlProps(verbiageText)} />
      )}
    </>
  );
};
