import React, { Props, useState } from 'react';

import { AnyObject, FORM_ERROR } from 'final-form';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';

import { AppRootState, navigateToPracticePrescribers } from '../redux';
import { Physician, useCreatePhysician } from '../api';
import { HcpButton, HcpFormRenderer, HcpFormTemplate, PrivateLayout } from '../components';
import { ROUTE_PATHS, getAddPrescriberSchema, FIELD_NAMES } from '../utils';
import { AnyType } from '../interfaces';

const { prescriberFields } = FIELD_NAMES;

export interface AddPrescriberViewProps extends Props<JSX.Element> {
  isStartRequestPage?: boolean;
  patientId?: string;
  addedNewPrescriber: (prescriberId: number | null | undefined) => void;
}
export const AddPrescriberView = (props: AddPrescriberViewProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppRootState) => state.auth.session?.user);
  const [formKey, setFormKey] = useState(new Date().toISOString());
  const { mutate: createPhysician } = useCreatePhysician({});

  const onSubmit = async (values: AnyObject) => {
    try {
      const payload: Physician = {
        firstName: values[prescriberFields.firstName],
        lastName: values[prescriberFields.lastName],
        licenseState: values[prescriberFields.licensingState],
        middleName: values[prescriberFields.middleName],
        npi: values[prescriberFields.npi] && values[prescriberFields.npi]?.toString(),
        stateLicenseNumber: values[prescriberFields.stateLicenseNumber],
        taxId: values[prescriberFields.taxId],
        ptan: values[prescriberFields.ptan],
        dea: values[prescriberFields.dea],
        email: values[prescriberFields.email],
        hin: values[prescriberFields.hin]
      };

      const result = await createPhysician(payload);

      if (result.errors && result.errors.length > 0) {
        const errors = result.errors?.map((e) => e.description).join(' ');

        // Return the form error to display
        return { [FORM_ERROR]: errors };
      }

      // Submission worked so let's redirect, if desired based on the button used
      if (values.redirect === true) {
        if (props.isStartRequestPage) {
          props.addedNewPrescriber(result?.physicianId);
          return;
        }
        dispatch(navigateToPracticePrescribers());
        return;
      }

      // Low-tech way to reset the form until form.restart is available;
      // see https://github.com/final-form/react-final-form/issues/840
      setFormKey(new Date().toISOString());
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  const backNavigationText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['back.navigation.text' ?? ''] as AnyType)?.data
  );

  if (props.isStartRequestPage) {
    return (
      <HcpFormRenderer
        contentKey='form.prescriber'
        FormTemplate={(props) => <AddPrescriberFormTemplate {...props} isStartRequestPage={true} />}
        key={formKey}
        onCancel={() => dispatch(navigateToPracticePrescribers())}
        onSubmit={onSubmit}
        schemaMethod={getAddPrescriberSchema}
      />
    );
  }

  return (
    <PrivateLayout
      pageTitle='add a prescriber'
      breadcrumbs={[{ label: backNavigationText?.content, href: `${ROUTE_PATHS.practice}#prescribers` }]}>
      <HcpFormRenderer
        contentKey='form.prescriber'
        FormTemplate={AddPrescriberFormTemplate}
        key={formKey}
        onCancel={() => dispatch(navigateToPracticePrescribers())}
        onSubmit={onSubmit}
        schemaMethod={getAddPrescriberSchema}
      />
    </PrivateLayout>
  );
};

export interface AddPrescriberFormTemplateProps extends FormTemplateRenderProps {
  isStartRequestPage?: boolean;
}

export const AddPrescriberFormTemplate = (props: AddPrescriberFormTemplateProps) => {
  const submitForm = (formProps: AnyObject) => {
    formProps.change('redirect', true);
    formProps.submit();
  };
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, hasValidationErrors, submitError, values } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row>
              {!props.isStartRequestPage && (
                <Col md={7} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                  <HcpButton
                    className='mb-2 mb-lg-0 mr-lg-2'
                    disabled={submitting || hasValidationErrors}
                    onClick={() => formProps.change('redirect', true)}
                    type='submit'
                    variant='secondary'
                    data-testid='cmx__add-prescriber-button'
                    loading={submitting && values.redirect}>
                    {submitting && values.redirect ? 'SUBMITTING...' : 'ADD PRESCRIBER AND CLOSE'}
                  </HcpButton>
                  <HcpButton
                    disabled={submitting || hasValidationErrors}
                    className='mt-2 mt-lg-0 ml-lg-2'
                    onClick={() => formProps.change('redirect', false)}
                    type='submit'
                    variant='outline-secondary'
                    loading={submitting && !values.redirect}>
                    {submitting && !values.redirect ? 'SUBMITTING...' : 'SAVE PRESCRIBER AND ADD ANOTHER'}
                  </HcpButton>
                </Col>
              )}

              {props.isStartRequestPage && (
                <Col md={7} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                  <HcpButton
                    className='mb-2 mb-lg-0 mr-lg-2'
                    disabled={submitting || hasValidationErrors}
                    onClick={() => submitForm(formProps)}
                    data-testid='cmx__add-prescriber-button'
                    loading={submitting && values.redirect}>
                    {submitting && values.redirect ? 'SUBMITTING...' : 'ADD PRESCRIBER AND SELECT'}
                  </HcpButton>
                </Col>
              )}

              <Col md={5} className='d-flex justify-content-md-end'>
                <HcpButton variant='transparent' className='text-danger mt-3 mt-md-0' onClick={formProps.onCancel}>
                  CANCEL
                </HcpButton>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
