import { MEDICSCAN_API_URL, MEDICSCAN_USERNAME, MEDICSCAN_PASSWORD } from '../api';
import { RequestHookOpts } from './use-ebv';
import { useFetch } from './use-fetch';

export const useMediscanCreateDocument = ({ lazy }: RequestHookOpts) => {
  return useFetch({
    lazy,
    method: 'POST',
    url: `${MEDICSCAN_API_URL}/AssureIDService/Document/Instance`,
    headers: {
      mode: 'no-cors',
      Authorization: `Basic ${btoa(`${MEDICSCAN_USERNAME}:${MEDICSCAN_PASSWORD}`)}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      AuthenticationSensitivity: 0,
      ClassificationMode: 0,
      Device: {
        HasContactlessChipReader: false,
        HasMagneticStripeReader: false,
        SerialNumber: 'xxx',
        Type: { Manufacturer: 'xxx', Model: 'xxx', SensorType: 0 }
      },
      ImageCroppingExpectedSize: 0,
      ImageCroppingMode: 0,
      ManualDocumentType: null,
      ProcessMode: 1,
      SubscriptionId: '1363fddd-c411-4a82-b71b-e9c237c4d933'
    })
  });
};

export const useMediscanPostFrontWhite = ({ lazy, instanceId }: RequestHookOpts) => {
  return useFetch({
    lazy,
    method: 'POST',
    url: `${MEDICSCAN_API_URL}/assureidservice/Document/${instanceId}/Image?side=0&light=0&metrics=true`,
    headers: {
      mode: 'no-cors',
      Authorization: `Basic ${btoa(`${MEDICSCAN_USERNAME}:${MEDICSCAN_PASSWORD}`)}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    redirect: 'follow'
  });
};

export const useMediscanGetFrontWhite = ({ lazy, instanceId }: RequestHookOpts) => {
  return useFetch({
    lazy,
    method: 'GET',
    url: `${MEDICSCAN_API_URL}/assureidservice/Document/${instanceId}/Image/Metrics?side=0&light=0`,
    headers: {
      mode: 'no-cors',
      Authorization: `Basic ${btoa(`${MEDICSCAN_USERNAME}:${MEDICSCAN_PASSWORD}`)}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    redirect: 'follow'
  });
};

export const useMediscanReturnData = ({ lazy, instanceId }: RequestHookOpts) => {
  return useFetch({
    lazy,
    method: 'GET',
    url: `https://preview.medicscan.acuant.net/api/v1/MedicalCard?subscriptionId=1363fddd-c411-4a82-b71b-e9c237c4d933&instanceId=${instanceId}`,
    headers: {
      mode: 'no-cors',
      Authorization: `Basic ${btoa(`${MEDICSCAN_USERNAME}:${MEDICSCAN_PASSWORD}`)}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    redirect: 'follow'
  });
};
