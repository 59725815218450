import { AnyObject } from 'final-form';
import { Reducer, ReducerAction, ReducerState } from 'react';
import { createSchema, handleNext, jumpToStep } from '.';

export enum WIZARD_ACTIONS {
  FINISH_LOADING = 'finish-loading',
  HANDLE_NEXT = 'handle-next',
  HANDLE_PREV_STEPS = 'handle-previous-steps',
  HANDLE_JUMP_TO_STEP = 'handle-jump-to-step'
}

export const wizardReducer = (
  state: ReducerState<Reducer<AnyObject, AnyObject>>,
  { type, payload }: ReducerAction<Reducer<AnyObject, AnyObject>>
) => {
  switch (type) {
    case WIZARD_ACTIONS.FINISH_LOADING:
      return {
        ...state,
        loading: false,
        navSchema: createSchema({
          fields: payload.fields,
          formOptions: payload.formOptions
        })
      };
    case WIZARD_ACTIONS.HANDLE_NEXT:
      return handleNext(state, payload.nextStep, payload.formOptions, payload.fields);
    case WIZARD_ACTIONS.HANDLE_PREV_STEPS:
      return {
        ...state,
        prevSteps: state.prevSteps.slice(0, state.activeStepIndex),
        maxStepIndex: state.activeStepIndex,
        navSchema: createSchema({
          fields: payload.fields,
          formOptions: payload.formOptions
        })
      };
    case WIZARD_ACTIONS.HANDLE_JUMP_TO_STEP:
      return jumpToStep(
        state,
        payload.index,
        payload.valid,
        payload.fields,
        payload.crossroads,
        payload.formOptions
      ) as AnyObject;
    default:
      return state;
  }
};
