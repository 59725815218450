import React, { PropsWithChildren, useContext, useEffect } from 'react';

import { AnyObject } from 'final-form';
import { AsyncTypeahead, AsyncTypeaheadProps, Typeahead, TypeaheadProps } from 'react-bootstrap-typeahead';
import { Field, FieldProps, FieldRenderProps, useForm } from 'react-final-form';
import { Form } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { some } from 'lodash';
import NumberFormat from 'react-number-format';
import { AnyType, ServiceOptionType } from '../interfaces';
import { HcpMedicscanUpload } from '../components/HcpMedicscanUpload';
import { HcpUpload } from '../components/HcpUpload';
import { defaultDateFormatWithAge } from '../utils/formatters';
import { CustomThemes } from '../utils/custom-themes';

export const displayRxError = (props: FieldRenderProps<string[]>, requireTouch = true) => {
  if (!(props.meta.error && ((requireTouch && props.meta.touched) || !requireTouch))) return null;

  return (
    <Form.Control.Feedback type='invalid' tooltip={false}>
      <div dangerouslySetInnerHTML={{ __html: props.meta.error }} />
    </Form.Control.Feedback>
  );
};

export const displayRxErrorText = (props: FieldRenderProps<string[]>, requireTouch = true) => {
  if (!(props.meta.error && ((requireTouch && (props.meta.touched || props.meta.dirty)) || !requireTouch))) return null;
  return <p className='fs-2 text-danger'>{props.meta.error}</p>;
};
