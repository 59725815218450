import { API_URL } from '../../api';
import { getNpiValidationMessage, getProgramIdFromStore } from '../../redux/helpers';

export const prescriberNpiValidation = async (npiValue: number) => {
  const programId = getProgramIdFromStore();
  if (npiValue?.toString()?.length === 10) {
    const url = `${API_URL}/api/register/IsNpiExists`;
    const queryParams = `physicianNpi=${npiValue}&programId=${programId}&Physicians=true`;
    const response = await fetch(`${url}?${queryParams}`);
    const json = await response?.json();
    if (!json?.isSuccess) {
      return Promise.reject(getNpiValidationMessage());
    }
  }
  return Promise.resolve();
};
