import { RowNode, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useState } from 'react';
import { Envelope, ExclamationCircleFill, InfoCircleFill, Eye, FileText } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

import { CasesFilter, ConnectedHcpGridWithGQL } from '.';
import { useCaseRecordsQuery } from '../graphql';
import {
  casefilterGridComparator,
  CasesFilterTypes,
  dateColumnDefinition,
  patientNameValueFormatter,
  providerColumnDefinition,
  ROUTE_PATHS,
  caseStatusValueFormatter,
  ACTION_NEEDED,
  RECENTLY_UPDATED,
  UNREAD_MESSAGE,
  drugDescription,
  ActionColumnActions,
  actionsColumnDefinition,
  ActionsCellRenderer,
  ActionsCellEditor
} from '../utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface CasesTableProps {
  enableExternalFilters?: boolean;
  filteredStatus?: string;
  defaultFilters?: string[];
}

export const CasesTable = (props: CasesTableProps) => {
  const [filters, setFilters] = useState<Set<string>>(new Set(props?.defaultFilters));
  const history = useHistory();

  const redirectToCase = (params: any, tabName: string) => {
    history.push(`${ROUTE_PATHS.patients}/${params.data.patientId}/${params.data.caseRecordId}#${tabName}`);
  };

  const filterProps =
    props?.enableExternalFilters || props?.defaultFilters
      ? {
          isFilterActive: !filters?.has(CasesFilterTypes.all),
          filterComparator: casefilterGridComparator,
          filterValue: filters
        }
      : {};

  return (
    <>
      {props.enableExternalFilters && <CasesFilter onFilterChange={(filter: Set<string>) => setFilters(filter)} />}
      {((props.enableExternalFilters && filters) || !props.enableExternalFilters) && (
        <ConnectedHcpGridWithGQL
          {...filterProps}
          queryHook={useCaseRecordsQuery}
          pagination
          className='grid-wrapper'
          frameworkComponents={{
            alertCellRenderer,
            ActionsCellRenderer,
            ActionsCellEditor
          }}>
          <AgGridColumn headerName='' cellRenderer='alertCellRenderer' maxWidth={75} />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            field='patientLastName'
            headerName='PATIENT'
            valueFormatter={patientNameValueFormatter}
            valueGetter={patientNameValueFormatter}
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            {...providerColumnDefinition('PROVIDER')}
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            {...drugDescription('caseDrugDescription', 'DRUG')}
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            field='caseStatus'
            headerName='STATUS'
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            field='caseSubStatus'
            headerName='SUB-STATUS'
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            field='caseClosed'
            headerName='CASE STATUS'
            valueFormatter={caseStatusValueFormatter}
            valueGetter={caseStatusValueFormatter}
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            field='caseRecordId'
            headerName='CASE ID'
          />
          <AgGridColumn
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
            {...dateColumnDefinition('caseReceivedDate')}
            headerName='RECEIVED'
          />
          <AgGridColumn
            {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
              {
                href: `${ROUTE_PATHS.patients}/${node.data.patientId}/${node.data.caseRecordId}#${'caseDetail'}`,
                label: 'View Case',
                icon: Eye
              },
              {
                href: `${ROUTE_PATHS.serviceRequest}/case/${node.data.caseRecordId}/patient/${node.data.patientId}/${node.data.caseDrugName}`,
                label: 'Start New Request',
                icon: FileText
              }
            ])}
          />
        </ConnectedHcpGridWithGQL>
      )}
    </>
  );
};

const ExclamationCircle = (props: any) => {
  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id='action-needed'>{ACTION_NEEDED}</Tooltip>}>
      <ExclamationCircleFill className='text-danger' {...props.iconProps} />
    </OverlayTrigger>
  );
};

const InfoCircle = (props: any) => {
  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id='recently-updated'>{RECENTLY_UPDATED}</Tooltip>}>
      <InfoCircleFill className='text-info' {...props.iconProps} />
    </OverlayTrigger>
  );
};

const EnvelopeIcon = (props: any) => {
  return (
    <OverlayTrigger placement='top' overlay={<Tooltip id='unread-message'>{UNREAD_MESSAGE}</Tooltip>}>
      <Envelope {...props.iconProps} color='grey' />
    </OverlayTrigger>
  );
};

export const alertCellRenderer = (node: RowNode) => {
  const iconProps = { fontSize: 15 };
  const history = useHistory();
  return (
    <div>
      {node.data.needAction && (
        <span
          onClick={() => {
            history.push(`${ROUTE_PATHS.patients}/${node.data.patientId}/${node.data.caseRecordId}#caseDetail`);
          }}>
          <ExclamationCircle iconProps={iconProps} />
        </span>
      )}
      {node.data.isUpdatedRecently && (
        <span
          onClick={() => {
            history.push(`${ROUTE_PATHS.patients}/${node.data.patientId}/${node.data.caseRecordId}#caseDetail`);
          }}>
          <InfoCircle style={{ marginLeft: '2px' }} iconProps={iconProps} />
        </span>
      )}
      {node.data.hasUnReadMessage && (
        <span
          className='pointer'
          onClick={() => {
            history.push(`${ROUTE_PATHS.patients}/${node.data.patientId}/${node.data.caseRecordId}#caseDetailMessages`);
          }}>
          <EnvelopeIcon style={{ marginLeft: '2px' }} iconProps={iconProps} />
        </span>
      )}
    </div>
  );
};
