import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Icon, ThreeDotsVertical } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { FeatureGuard } from '../../components/multi-tenant/FeatureGuard';
import { AnyType } from '../../interfaces';
import { ServiceRequestType } from '../../hooks/use-bv-summary';

export interface ActionColumnActions {
  featureKey?: string;
  href: string;
  icon: Icon;
  label: string;
  testId?: string;
  data?: any;
}

export const ActionsCellRenderer = () => {
  return (
    <div className='text-center text-primary' data-testid='cmx__hcp-grid-actions-button'>
      <ThreeDotsVertical id='cmx__hcp-grid-svg-icon' />
    </div>
  );
};

export const ActionsCellEditor = forwardRef((props: AnyType, ref) => {
  const [show, setShow] = useState(true);
  const history = useHistory();

  const handleClick = (event: any) => {
    const eventTargetValue = event.target?.offsetParent;
    const svgElementValue = event.srcElement?.parentElement?.id || event.srcElement?.id;
    let className = false;
    if ((event.target && eventTargetValue) || svgElementValue) {
      className =
        (typeof eventTargetValue?.className === 'string' &&
          eventTargetValue?.className.includes('ag-cell-popup-editing')) ||
        svgElementValue === 'cmx__hcp-grid-svg-icon';
    }
    if (show || className) {
      setShow(!show);
    }
  };

  // This will run in all render and handle clicks
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return '';
      },
      isPopup() {
        return true;
      }
    };
  });

  const onClick = (a: AnyType) => {
    history.push(a.href);
  };

  const { values } = props;
  const gridItems = values && values(props.node);
  const items = gridItems?.filter((item: ActionColumnActions) => !ServiceRequestType.includes(item?.data?.requestType));
  return (
    <Dropdown drop='left' show={show}>
      <Dropdown.Toggle className='sr-only'>&nbsp;</Dropdown.Toggle>

      <Dropdown.Menu className='px-0' align='left'>
        {items?.map((a: AnyType) => (
          <FeatureGuard key={a?.label} contentKey={a?.featureKey}>
            <Dropdown.Item className='text-muted' data-testid={a.testId} onClick={() => onClick(a)}>
              <a.icon className='mr-2' /> {a?.label}
            </Dropdown.Item>
          </FeatureGuard>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});

ActionsCellEditor.displayName = 'ActionsCellEditor';
