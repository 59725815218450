import { LazyQueryHookOptions, QueryTuple } from '@apollo/client';
import { AnyObject } from 'final-form';
import React, { PropsWithRef, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { AsyncTypeahead, AsyncTypeaheadProps, TypeaheadLabelKey } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';
import { GRAPHQL_DEFAULT_CACHE } from '../graphql/apollo';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux/reducers/types';
import { debounce } from 'lodash';

export enum SearchInputWithRXGqlIconLocation {
  prepend,
  append,
  none
}

export enum SearchInputWithRXGqlDisplay {
  outlined = 'outlined',
  underlined = 'underlined'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SearchInputRXWithGqlProps {
  asyncRxTypeaheadProps?: Partial<AsyncTypeaheadProps<AnyType> & PropsWithRef<AnyType>>;
  rXdisplay?: SearchInputWithRXGqlDisplay;
  labelRenderer: TypeaheadLabelKey<AnyType>; // (option: AnyType) => string | undefined;
  filterBy?: string[];
  selected?: string[];
  minLength?: number;
  multiple?: boolean;
  onItemSelect: (item: AnyType) => void;
  organizationIdRequired?: boolean;
  placeholder?: string;
  programIdRequired?: boolean;
  queryHook: (baseOptions?: LazyQueryHookOptions<AnyType, AnyType>) => QueryTuple<AnyType, AnyType>;
  queryOptions?: LazyQueryHookOptions<AnyType, Record<string, AnyType>>;
  queryOptionsOnSearch?: (
    queryOptions: LazyQueryHookOptions<AnyType, Record<string, AnyType>>,
    searchTerm: string
  ) => LazyQueryHookOptions<AnyType, Record<string, AnyType>>;
  searchRxIconLocation?: SearchInputWithRXGqlIconLocation;
  requestIdOptions?: any;
  setRequestIdOptions?: any;
  setIsGISrNeeded?: any;
  isGISrNeeded?: any;
  setEServicesOptions?: any;
  isDisabled?: any;
}

const getBorderClasses = (
  searchIconLocation: SearchInputWithRXGqlIconLocation
): { [key: string]: { input: string; button: string } } => {
  return {
    [SearchInputWithRXGqlDisplay.outlined]: {
      input:
        searchIconLocation === SearchInputWithRXGqlIconLocation.prepend
          ? 'border-left-0'
          : searchIconLocation !== SearchInputWithRXGqlIconLocation.none
          ? 'border-right-0'
          : '',
      button: searchIconLocation === SearchInputWithRXGqlIconLocation.prepend ? 'border-right-0' : 'border-left-0'
    },
    [SearchInputWithRXGqlDisplay.underlined]: {
      input: 'border-bottom border-right-0 border-left-0 border-top-0',
      button: 'border-0'
    }
  };
};

export const SearchInputWithRXGql = ({
  rXdisplay = SearchInputWithRXGqlDisplay.outlined,
  minLength = 2,
  organizationIdRequired = true,
  queryOptionsOnSearch = (qo) => qo,
  ...props
}: SearchInputRXWithGqlProps) => {
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId));
  const queryOptions = props.queryOptions ?? {};

  if ((organizationIdRequired && !organizationId) || (props.programIdRequired && !programId)) {
    return null;
  }

  if (!queryOptions.variables?.organizationId && organizationIdRequired) {
    queryOptions.variables = {
      ...queryOptions.variables,
      organizationId: organizationId,
      programId: programId ?? null
    };
  }

  const [execQuery, result] = props.queryHook({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  const onSearch = (searchTerm: string) => {
    execQuery(queryOptionsOnSearch({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE }, searchTerm));
  };

  const searchRXLocation = props.searchRxIconLocation ?? SearchInputWithRXGqlIconLocation.prepend;
  const borderClasses = getBorderClasses(searchRXLocation);

  const inputBorderClassName = borderClasses[rXdisplay]['input'];
  const buttonBorderClassName = borderClasses[rXdisplay]['button'];

  const icon = (
    <Button variant='transparent' className={`border ${buttonBorderClassName} rounded-right px-3`}>
      <Search className='text-gray' />
    </Button>
  );

  const onItemSelect = (selection: AnyType) => {
    const item = Array.isArray(selection) && !props.multiple ? selection[0] : selection;
    props.onItemSelect(item);
  };

  const options = result?.data?.result ?? props.asyncRxTypeaheadProps?.options ?? [];
  const isValidClass = props.asyncRxTypeaheadProps?.isValid ? 'is-valid' : '';
  const isInvalidClass = props.asyncRxTypeaheadProps?.isInvalid ? 'is-invalid' : '';

  return (
    <InputGroup className={`flex-nowrap ${isValidClass} ${isInvalidClass}`.trim()}>
      {/* This is crude and only a temporary work-around. */}
      {searchRXLocation === SearchInputWithRXGqlIconLocation.prepend && <InputGroup.Prepend>{icon}</InputGroup.Prepend>}
      <AsyncTypeahead
        {...props.asyncRxTypeaheadProps}
        highlightOnlyResult={!props.asyncRxTypeaheadProps?.allowNew}
        id='search-typeahead'
        inputProps={{
          ...props.asyncRxTypeaheadProps?.inputProps,
          className: inputBorderClassName
        }}
        isLoading={result.loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        labelKey={props.labelRenderer}
        minLength={minLength}
        multiple={props.multiple}
        onSearch={onSearch}
        onChange={onItemSelect}
        options={options}
        placeholder={props.placeholder}
        filterBy={props.filterBy}
        selected={props.selected}
        disabled={props?.isDisabled}
      />
      {searchRXLocation === SearchInputWithRXGqlIconLocation.append && <InputGroup.Append>{icon}</InputGroup.Append>}
    </InputGroup>
  );
};
