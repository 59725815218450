import { useMemo } from 'react';

import { useFlags } from 'flagsmith/react';

import * as data from '../../utils/data';

export default function useStates() {
  const { states } = useFlags(['states']);

  return useMemo(() => {
    if (!states?.enabled) {
      return data.states;
    }

    const json = JSON.parse(states.value as string) as Record<string, string>;

    return data.formatToArray(json);
  }, [states?.enabled, states?.value]);
}
