import React from 'react';
import { Nav } from 'react-bootstrap';
import {
  ArrowRightCircleFill,
  FileEarmarkTextFill,
  FileMedicalFill,
  FolderFill,
  HouseFill,
  Truck
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

import './CaseDetailSidebarNav.scss';

const summaryEventKey = '#summary';
const messagesEventKey = '#messages';
const documentsEventKey = '#documents';
const biEventKey = '#bi';
const paEventKey = '#pa';

export const CaseDetailSidebarNav = () => {
  const history = useHistory();

  const onTabSelect = (eventKey: string | null) => {
    if (!eventKey) return;

    history.push(eventKey);
  };

  return (
    <Nav variant='tabs' className='cmx__case-detail-sidebar-nav flex-column' onSelect={onTabSelect}>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link eventKey={summaryEventKey} className='cmx__case-detail-sidebar-nav__nav-link-selected'>
          <HouseFill size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>OVERVIEW</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link href='/patients/:id/cases' className='cmx__case-detail-sidebar-nav__nav-link-unselected'>
          <FolderFill size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>CASES</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link href='/patients/:id/requests' className='cmx__case-detail-sidebar-nav__nav-link-unselected'>
          <ArrowRightCircleFill size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>REQUESTS</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link href='/patients/:id/documents' className='cmx__case-detail-sidebar-nav__nav-link-unselected'>
          <FileEarmarkTextFill size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>DOCUMENTS</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link href='/patients/:id/despenses' className='cmx__case-detail-sidebar-nav__nav-link-unselected'>
          <Truck size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>DISPENSES</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__case-detail-sidebar-nav__nav-item'>
        <Nav.Link href='/patients/:id/claims' className='cmx__case-detail-sidebar-nav__nav-link-unselected'>
          <FileMedicalFill size={20} />
          <span className='cmx__case-detail-sidebar-nav__nav-text'>CLAIMS</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};
