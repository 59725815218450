import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import { List } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { ConnectedHcpGridWithGQL } from '.';
import { useGetPatientDispensesQueryQuery } from '../graphql';
import {
  carrierColumnDefinition,
  dateColumnDefinition,
  prescriberColumnDefinition,
  trackingColumnDefinition
} from '../utils';

export const DispensesTable = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <p>
        <i>
          To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
          criteria.
        </i>
      </p>
      <ConnectedHcpGridWithGQL
        queryHook={useGetPatientDispensesQueryQuery}
        queryOptions={{
          variables: {
            patientId: id
          }
        }}
        overlayNoRowsTemplate={'<span style="padding: 10px;">You currently have no dispenses for this patient.</span>'}
        pagination>
        <AgGridColumn {...dateColumnDefinition('shippedDate')} headerName='SHIPPED' />
        <AgGridColumn field='pharmacyName' headerName='PHARMACY' />
        <AgGridColumn field='productName' headerName='PRODUCT' />
        <AgGridColumn field='dispenseType' headerName='TYPE' />
        <AgGridColumn field='quantity' headerName='QTY' />
        <AgGridColumn field='fillNumber' headerName='FILL #' />
        <AgGridColumn field='refillRemaining' headerName='REFILLS' />
        <AgGridColumn {...carrierColumnDefinition('CARRIER')} />
        <AgGridColumn {...trackingColumnDefinition('TRACKING #')} />
        <AgGridColumn {...prescriberColumnDefinition('PROVIDER')} />
        <AgGridColumn field='physicianNpi' headerName='PROVIDER NPI' />
      </ConnectedHcpGridWithGQL>
    </>
  );
};
