import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldTextSection, fieldLoginInput } from '..';

export const getLoginFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'login form',
  fields: [
    fieldLoginInput('username', schema?.username, {
      sizes: { xs: 12 },
      validate: [
        {
          type: 'username'
        }
      ]
    }),
    fieldLoginInput('password', schema?.password, {
      type: 'password',
      sizes: { xs: 12 },
      autoComplete: 'new-password',
      validate: [
        {
          type: 'strong-password'
        }
      ]
    })
  ].filter(Boolean)
});

// -----===[test schema]===-----
export const loginFormSchema: DBFormSchema = {
  username: { label: 'username', required: true },
  password: { label: 'password', required: true }
};
