import { ICellRendererParams } from 'ag-grid-community';
import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useSetState } from '../../../api';
import { AppRootState, AuthUser } from '../../../redux';
import { clientApplicationNameSelector } from '../../tenancy-utils';

export const UserStatusRenderer = (params: ICellRendererParams) => {
  const isActive = params.data.isActive;
  const StatusIcon = isActive ? ToggleOn : ToggleOff;
  const currentUser: AuthUser | undefined = useSelector((state: AppRootState) => state.auth.session?.user);
  const isCurrentUser = currentUser?.email === params.data.email;
  const { mutate: setStatus, loading } = useSetState({});
  const applicationName = useSelector(clientApplicationNameSelector);

  const onClick = useCallback(async () => {
    if (isCurrentUser) return;

    const nextStatus = isActive ? 'disable' : 'enable';

    if (window.confirm(`Are you sure you want to ${nextStatus} ${params.data.firstName} ${params.data.lastName}?`)) {
      const newValue = !isActive;
      const result = await setStatus({
        applicationName: applicationName,
        userName: params.data.userName,
        activate: newValue
      });

      if (result.errors && result.errors.length > 0) {
        alert(result.errors.map((e) => e.description).join(' '));
        return;
      }

      params.setValue(newValue);
    }
  }, [isCurrentUser, isActive]);

  return (
    <div onClick={onClick}>
      <StatusIcon className='mr-2 text-info' size={20} /> {isActive ? 'Active' : 'Inactive'}
      {loading && <Spinner animation='border' size='sm' />}
    </div>
  );
};
