import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CONFIG_DOMAIN, GATEWAY_API_URL } from './api';
import { useGetPortalConfigurationHcp } from './api/portal-config.generated';
import { HcpRequestStateUI, LogoToolbar, OverbrandToolbar } from './components';
import { useCacheTenancyFeatures, useCacheTenancyMenus, useCacheTenancyResources, useCacheTenancyDrugs } from './hooks';
import { cacheAppConfig, loadConfig } from './redux';
import flagsmith from 'flagsmith';

const LOCALHOST_NAMES = ['localhost', '127.0.0.1'];

export const AppConfig = (props: PropsWithChildren<unknown>) => {
  const dispatch = useDispatch();
  const isLocalUrl = LOCALHOST_NAMES.includes(location.hostname);
  const { data, loading, error, refetch } = useGetPortalConfigurationHcp({
    base: GATEWAY_API_URL,
    lazy: true
  });

  const loadData = useCallback(() => {
    const configDomain = isLocalUrl ? CONFIG_DOMAIN : location.hostname;
    refetch({ queryParams: { url: configDomain } });
  }, []);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    if (!data) return;

    dispatch(cacheAppConfig(data));
    dispatch(loadConfig());

    flagsmith.init({
      environmentID: (window as any).REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
      identity: `Anon_${data.applicationName}`,
      traits: {
        programId: data.programId ?? null
      }
    });
  }, [dispatch, data]);

  return (
    <AppConfigWrapper wrap={!data}>
      <HcpRequestStateUI
        loadingUIEnabled
        loadingText='Loading Application Config'
        isLoading={loading}
        errorUIEnabled
        errorText={error?.message}
        errorTitle='Configuration Error'
        isError={error}
        reloadButtonText='Retry'
        reloadingEnabled
        reload={loadData}>
        <AppDataCache />
        {props.children}
      </HcpRequestStateUI>
    </AppConfigWrapper>
  );
};

// A simple component to control the data cache timing
const AppDataCache = () => {
  useCacheTenancyFeatures();
  useCacheTenancyMenus();
  useCacheTenancyResources();
  useCacheTenancyDrugs();
  // useCacheTenancyService('test');

  return null;
};

interface AppConfigWrapperProps extends PropsWithChildren<unknown> {
  wrap: boolean;
}

const AppConfigWrapper = ({ wrap = true, ...props }: AppConfigWrapperProps) => {
  if (!wrap) return <>{props.children}</>;

  return (
    <>
      <OverbrandToolbar withLinks={false} />
      <LogoToolbar />
      <Container className='py-4'>{props.children}</Container>
    </>
  );
};
