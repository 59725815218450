import React, { useState, useRef, useEffect } from 'react';
import './ContactusReq.scss';
import { ResourceContent } from '../components';
import { Col, Row } from 'react-bootstrap';
import { rawSafeHtmlProps } from '../utils';

export const ContactUsRequirement = (props: any) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { arrow, top, left, marginLeft } = props;

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(!show);
    }
  };

  // This will run in all render and handle outside click of Contact us popup box
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div className='contactUs-requirement-box' data-testid='contactUs-requirement-box'>
      <Col xs={12} className='text-center'>
        <p className='fs-2'>
          <ResourceContent contentKey='contact.us-teaser' />{' '}
          <a className='text-secondary' onClick={() => setShow(!show)} data-testid='cmx__login__contactUs'>
            CONTACT US
          </a>
        </p>
      </Col>
      <div>
        {show && (
          <Col sm={5} lg={4}>
            <div
              className={`py-4 px-4 bg-light-gray contactUs-requirement contactUs-Box  ${arrow}`}
              style={{ top, left, marginLeft }}
              ref={ref}>
              <ResourceContent
                contentKey='contact_us_details'
                sourceDataKey='content'
                render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
              />
              <table className='mb-4'>
                <tbody>
                  <tr>
                    <th>Phone:</th>
                    <td className='pl-4'>
                      <ResourceContent
                        contentKey='contact_us_details'
                        sourceDataKey='phone'
                        render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className='pt-2'>Fax:</th>
                    <td className='pl-4 pt-2'>
                      <ResourceContent
                        contentKey='contact_us_details'
                        sourceDataKey='fax'
                        render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className='mb-0'>
                <ResourceContent
                  contentKey='contact_us_details'
                  sourceDataKey='hours'
                  render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                />
              </p>
            </div>
          </Col>
        )}
      </div>
    </div>
  );
};
