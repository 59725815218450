import React, { FC, useContext, useState, useEffect } from 'react';
import { Accordion, AccordionContext, Card, Col, Row, Form } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCaseDetailsQuery } from '../graphql';
import { casedateFormatter, extractCaseUrl, DBFieldSchema, getDateValue } from '../utils';
import { AnyType } from '../interfaces';
import { useSelector } from 'react-redux';
import { tenancyFeatureSelector } from '../redux/selectors';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { isEmptyObject } from '../utils/helpers';

import './CaseDetailMainSectionSummaryTab.scss';

interface CaseDetailMainSectionSummaryTabParams {
  caseId: string;
}

export const CaseDetailMainSectionSummaryTab = () => {
  const [summaryFields, setSummaryFields]: [DBFieldSchema, AnyType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const setSummaryFieldsSchema = async () => {
    const summaryFieldsSchema: AnyType = await useSelector<AnyType>(tenancyFeatureSelector('casedetails.summary'));
    if (summaryFieldsSchema) {
      const { fields } = await summaryFieldsSchema;
      setSummaryFields(fields);
    }
  };
  setSummaryFieldsSchema();

  useEffect(() => {
    if (summaryFields && !isEmptyObject(summaryFields)) {
      setIsLoading(false);
    }
  }, [summaryFields]);

  const { caseId } = useParams<CaseDetailMainSectionSummaryTabParams>();

  const caseResult = useCaseDetailsQuery({ variables: { caseRecordId: caseId } });
  const caseData = caseResult.data?.result;
  const casePAS = (caseData?.priorAuthorizations ?? [])[0];
  const casePAS1 = (caseData?.priorAuthorizations ?? [])[1];
  const prescriptionIntakes = (caseData?.prescriptionIntakes ?? [])[0];
  const benefitInvestigations = (caseData?.benefitInvestigations ?? [])[0];
  const benefitInvestigations1 = (caseData?.benefitInvestigations ?? [])[1];
  const triage = (caseData?.triagePrescriptions ?? [])[0];
  const patientEnrollment = (caseData?.patientEnrollments ?? [])[0];
  const appeal = (caseData?.appeals ?? [])[0];
  const history = useHistory();

  const hashValue: string | undefined = extractCaseUrl(useLocation())?.hashValue;
  const hashValueIndex = hashValue?.lastIndexOf('#');
  const hashValueItem = hashValueIndex ? hashValue?.substring(0, hashValueIndex) : '';

  const handleClick = (transactionType: string, hash: string) => {
    if (transactionType) {
      history.push(`${hashValueItem}${hash}-${transactionType}`);
    } else {
      history.push(`${hashValueItem}${hash}`);
    }
  };
  const transactions = caseData?.transactions ?? [];
  const transactionsLength = transactions.length;

  const prescriptoinIntakeMileStone = transactions.find((element) => element?.caseMileStoneCode === 'PIN');
  const benefitInvestigationMileStone = transactions.find((element) => element?.caseMileStoneCode === 'BI');
  const priorAuthorizationMileStone = transactions.find((element) => element?.caseMileStoneCode === 'PA');
  const prescriptionTriageMileStone = transactions.find((element) => element?.caseMileStoneCode === 'TP');
  const patientEnrollmentMileStone = transactions.find((element) => element?.caseMileStoneCode === 'ENR');
  const appealMileStone = transactions.find((element) => element?.caseMileStoneCode === 'APP');

  const defaultActiveValue = transactionsLength - 1;
  const defaultActiveKey = defaultActiveValue.toString();

  interface Props {
    children: React.ReactNode;
    eventKey: string;
  }

  const ContextAwareToggle: FC<Props> = (props: Props) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        className='cmx__case-detail-main-section-summary-tab__card-header'
        eventKey={props.eventKey}>
        <div>{props.children}</div>
        <hr />
        {isCurrentEventKey && <ChevronUp />}
        {!isCurrentEventKey && <ChevronDown />}
      </Accordion.Toggle>
    );
  };
  const PatientEnrollmentSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {patientEnrollmentMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='4'>
              <strong>PATIENT ENROLLMENT</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='4'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <Row>
                  <Col className={formFields?.patient_enrollment_id?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.patient_enrollment_id?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.patient_enrollment_status?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.patient_enrollment_status?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.patient_enrollment_received_date?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.patient_enrollment_received_date?.label}
                    </Form.Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className={formFields?.patient_enrollment_id?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{patientEnrollment?.caseTransactionId}</p>
                  </Col>
                  <Col className={formFields?.patient_enrollment_status?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{patientEnrollment?.caseTransactionStatus}</p>
                  </Col>
                  <Col className={formFields?.patient_enrollment_received_date?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{casedateFormatter(patientEnrollment?.created)}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };
  const TriageSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {prescriptionTriageMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='3'>
              <strong>PRESCRIPTION TRIAGE</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='3'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <Row>
                  <Col className={formFields?.prescription_triage_receiving_pharmacy?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.prescription_triage_receiving_pharmacy?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_triage_date_sent?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_triage_date_sent?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_triage_status?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_triage_status?.label}</Form.Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className={formFields?.prescription_triage_receiving_pharmacy?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{triage?.sentToPharmacy}</p>
                  </Col>
                  <Col className={formFields?.prescription_triage_date_sent?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{casedateFormatter(triage?.dateSentToPharmacy)}</p>
                  </Col>
                  <Col className={formFields?.prescription_triage_status?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{triage?.status}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };
  const PriorAuthorizationSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {priorAuthorizationMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='2'>
              <strong>PRIOR AUTHORIZATION</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='2'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <table className='details-table'>
                  <tr className='title-row'>
                    <td className={formFields?.prior_authorization_plan_name?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_plan_name?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_plan_type?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_plan_type?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_type?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_pa_type?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_on_file?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_pa_on_file?.label}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_plan_name?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>
                        {casePAS ? (
                          casePAS?.insurancePlanName ? (
                            casePAS?.ePATransactionID ? (
                              <a
                                onClick={() => handleClick(casePAS?.pAType ?? '', '#pa')}
                                href={casePAS?.pAType ? '#pa-' + casePAS?.pAType : '#pa'}>
                                {casePAS?.insurancePlanName}
                              </a>
                            ) : (
                              <p>{casePAS?.insurancePlanName}</p>
                            )
                          ) : casePAS?.insurancePlanType === 'Pharmacy PA' ? (
                            <a
                              onClick={() => handleClick(casePAS?.pAType ?? '', '#pa')}
                              href={casePAS?.pAType ? '#pa-' + casePAS?.pAType : '#pa'}>
                              Proceed to PA
                            </a>
                          ) : null
                        ) : null}
                      </p>
                    </td>
                    <td className={formFields?.prior_authorization_plan_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.insurancePlanType}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.pAType}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_on_file?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.pAOnFile}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_plan_name?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>
                        {casePAS1 ? (
                          casePAS1?.insurancePlanName ? (
                            casePAS1?.ePATransactionID ? (
                              <a
                                onClick={() => handleClick(casePAS1?.pAType ?? '', '#pa')}
                                href={casePAS1?.pAType ? '#pa-' + casePAS1?.pAType : '#pa'}>
                                {casePAS1?.insurancePlanName}
                              </a>
                            ) : (
                              <p>{casePAS1?.insurancePlanName}</p>
                            )
                          ) : casePAS?.insurancePlanType === 'Pharmacy PA' ? (
                            <a
                              onClick={() => handleClick(casePAS1?.pAType ?? '', '#pa')}
                              href={casePAS1?.pAType ? '#pa-' + casePAS1?.pAType : '#pa'}>
                              Proceed to PA
                            </a>
                          ) : null
                        ) : null}
                      </p>
                    </td>
                    <td className={formFields?.prior_authorization_plan_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.insurancePlanType}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.pAType}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_on_file?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.pAOnFile}</p>
                    </td>
                  </tr>
                  <tr className='title-row'>
                    <td className={formFields?.prior_authorization_submission_date?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_submission_date?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_reference_key?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_reference_key?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_status?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_pa_status?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_number?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_number?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_expiration_date?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_expiration_date?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_submission_type?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_submission_type?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_provider_pa_website?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_provider_pa_website?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_approval_date?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_approval_date?.label}</p>
                    </td>
                    <td className={formFields?.prior_authorization_denial_date?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_denial_date?.label}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_submission_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS?.pASubmissionDate)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_reference_key?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.referenceKey}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_status?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.pAStatus}</p>
                    </td>
                    <td className={formFields?.prior_authorization_number?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.authorizationNumber}</p>
                    </td>
                    <td className={formFields?.prior_authorization_expiration_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS?.pAExpiresOn)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_submission_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS?.pASubmissionType}</p>
                    </td>
                    <td
                      className={formFields?.prior_authorization_provider_pa_website?.hideField ? 'd-none' : ''}
                      style={{ maxWidth: '102px' }}>
                      <p className='text-gray'>
                        <a href={casePAS?.providerPAWebsite || undefined} target='blank'>
                          {casePAS?.providerPAWebsite}
                        </a>
                      </p>
                    </td>
                    <td className={formFields?.prior_authorization_approval_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS?.pAApprovalDate)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_denial_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS?.pADenialDate)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_submission_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS1?.pASubmissionDate)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_reference_key?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.referenceKey}</p>
                    </td>
                    <td className={formFields?.prior_authorization_pa_status?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.pAStatus}</p>
                    </td>
                    <td className={formFields?.prior_authorization_number?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.authorizationNumber}</p>
                    </td>
                    <td className={formFields?.prior_authorization_expiration_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS1?.pAExpiresOn)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_submission_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.pASubmissionType}</p>
                    </td>
                    <td className={formFields?.prior_authorization_provider_pa_website?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>
                        <a href={casePAS1?.providerPAWebsite || undefined} target='blank'>
                          {casePAS1?.providerPAWebsite}
                        </a>
                      </p>
                    </td>
                    <td className={formFields?.prior_authorization_approval_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS1?.pAApprovalDate)}</p>
                    </td>
                    <td className={formFields?.prior_authorization_denial_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{getDateValue(casePAS1?.pADenialDate)}</p>
                    </td>
                  </tr>
                  <tr className='title-row'>
                    <td className={formFields?.prior_authorization_denial_reason?.hideField ? 'd-none' : ''}>
                      <p>{formFields?.prior_authorization_denial_reason?.label}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_denial_reason?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>
                        {casePAS?.denialReason} {casePAS?.otherDenialReason && `: ${casePAS?.otherDenialReason}`}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.prior_authorization_denial_reason?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casePAS1?.denialReason}</p>
                    </td>
                  </tr>
                </table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };
  const BenefitInvestigatioSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {benefitInvestigationMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='1'>
              <strong>BENEFIT INVESTIGATION</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <Row>
                  <Col className={formFields?.benefit_investigation_plan_name?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.benefit_investigation_plan_name?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.benefit_investigation_plan_type?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.benefit_investigation_plan_type?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.benefit_investigation_type?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.benefit_investigation_type?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.benefit_investigation_product_covered?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.benefit_investigation_product_covered?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.benefit_investigation_pa_required?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.benefit_investigation_pa_required?.label}
                    </Form.Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className={formFields?.benefit_investigation_plan_name?.hideField ? 'd-none' : ''}>
                    <p>
                      {benefitInvestigations ? (
                        benefitInvestigations?.insurancePlanName ? (
                          benefitInvestigations?.eBVTransactionId ? (
                            <a
                              onClick={() => handleClick(benefitInvestigations?.bIType ?? '', '#bi')}
                              href={benefitInvestigations?.bIType ? '#bi-' + benefitInvestigations?.bIType : '#bi'}>
                              {benefitInvestigations?.insurancePlanName}
                            </a>
                          ) : (
                            <p>{benefitInvestigations?.insurancePlanName}</p>
                          )
                        ) : benefitInvestigations?.insurancePlanType === 'Pharmacy' ? (
                          benefitInvestigations?.eBVTransactionId ? (
                            <a
                              onClick={() => handleClick(benefitInvestigations?.bIType ?? '', '#bi')}
                              href={benefitInvestigations?.bIType ? '#bi-' + benefitInvestigations?.bIType : '#bi'}>
                              Proceed to BI
                            </a>
                          ) : (
                            <p>Proceed to BI</p>
                          )
                        ) : null
                      ) : null}
                    </p>
                  </Col>
                  <Col className={formFields?.benefit_investigation_plan_type?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{benefitInvestigations?.insurancePlanType}</p>
                  </Col>
                  <Col className={formFields?.benefit_investigation_type?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{benefitInvestigations?.bIType}</p>
                  </Col>
                  <Col className={formFields?.benefit_investigation_product_covered?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>
                      {benefitInvestigations?.productCovered === 'Y'
                        ? 'Yes'
                        : benefitInvestigations?.productCovered === 'N'
                        ? 'No'
                        : benefitInvestigations?.productCovered === 'WR'
                        ? 'With Restrictions'
                        : ''}
                    </p>
                  </Col>
                  <Col className={formFields?.benefit_investigation_pa_required?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>
                      {benefitInvestigations?.priorAuthRequired === 'Y'
                        ? 'Yes'
                        : benefitInvestigations?.priorAuthRequired === 'N'
                        ? 'No'
                        : benefitInvestigations?.priorAuthRequired === 'U'
                        ? 'Undetermined'
                        : ''}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {benefitInvestigations1 ? (
                        benefitInvestigations1?.insurancePlanName ? (
                          <a
                            onClick={() => handleClick(benefitInvestigations1?.bIType ?? '', '#bi')}
                            href={benefitInvestigations1?.bIType ? '#bi-' + benefitInvestigations1?.bIType : '#bi'}>
                            {benefitInvestigations1?.insurancePlanName}
                          </a>
                        ) : benefitInvestigations1?.insurancePlanType === 'Pharmacy' ? (
                          <a
                            onClick={() => handleClick(benefitInvestigations1?.bIType ?? '', '#bi')}
                            href={benefitInvestigations1?.bIType ? '#bi-' + benefitInvestigations1?.bIType : '#bi'}>
                            Proceed to BI
                          </a>
                        ) : null
                      ) : null}
                    </p>
                  </Col>
                  <Col>
                    <p className='text-gray'>{benefitInvestigations1?.insurancePlanType}</p>
                  </Col>
                  <Col>
                    <p className='text-gray'>{benefitInvestigations1?.bIType}</p>
                  </Col>
                  <Col>
                    <p className='text-gray'>
                      {benefitInvestigations1?.productCovered === 'Y'
                        ? 'Yes'
                        : benefitInvestigations1?.productCovered === 'N'
                        ? 'No'
                        : benefitInvestigations1?.productCovered === 'WR'
                        ? 'With Restrictions'
                        : ''}
                    </p>
                  </Col>
                  <Col>
                    <p className='text-gray'>
                      {benefitInvestigations1?.priorAuthRequired === 'Y'
                        ? 'Yes'
                        : benefitInvestigations1?.priorAuthRequired === 'N'
                        ? 'No'
                        : benefitInvestigations1?.priorAuthRequired === 'U'
                        ? 'Undetermined'
                        : ''}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };
  const PrescriptoinIntakeSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {prescriptoinIntakeMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='0'>
              <strong>PRESCRIPTION INTAKE</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <Row>
                  <Col className={formFields?.prescription_intake_id?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_intake_id?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_intake_dose_type?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_intake_dose_type?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_intake_medication_name?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.prescription_intake_medication_name?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_intake_received_date?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>
                      {formFields?.prescription_intake_received_date?.label}
                    </Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_intake_ndc?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_intake_ndc?.label}</Form.Label>
                  </Col>
                  <Col className={formFields?.prescription_intake_quantity?.hideField ? 'd-none' : ''}>
                    <Form.Label className='headerColor'>{formFields?.prescription_intake_quantity?.label}</Form.Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col className={formFields?.prescription_intake_id?.hideField ? 'd-none' : ''}>
                    <a onClick={() => handleClick('', '#patientIntake')} href={'#patientIntake'}>
                      {prescriptionIntakes?.prescriptionId}
                    </a>
                  </Col>
                  <Col className={formFields?.prescription_intake_dose_type?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{prescriptionIntakes?.doseType}</p>
                  </Col>
                  <Col className={formFields?.prescription_intake_medication_name?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{prescriptionIntakes?.drugName}</p>
                  </Col>
                  <Col className={formFields?.prescription_intake_received_date?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{casedateFormatter(prescriptionIntakes?.prescriptionReceivedDate)}</p>
                  </Col>
                  <Col className={formFields?.prescription_intake_ndc?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{prescriptionIntakes?.ndc}</p>
                  </Col>
                  <Col className={formFields?.prescription_intake_quantity?.hideField ? 'd-none' : ''}>
                    <p className='text-gray'>{prescriptionIntakes?.quantity}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };
  const AppealSection = (props: any) => {
    const formFields = props.formFields;
    return (
      <Accordion defaultActiveKey={defaultActiveKey} className='cmx__case-detail-main-section-summary-tab'>
        {appealMileStone && (
          <Card className='cmx__case-detail-main-section-summary-tab__card'>
            <ContextAwareToggle eventKey='0'>
              <strong>APPEAL</strong>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                <table className='details-table'>
                  <tr className='title-row'>
                    <td className={formFields?.appeal_insurance_plan_name?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_insurance_plan_name?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_insurance_plan_type?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_insurance_plan_type?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_type?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_type?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_therapy_or_drug?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_therapy_or_drug?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_initiated?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_initiated?.label}</Form.Label>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.appeal_insurance_plan_name?.hideField ? 'd-none' : ''}>
                      <a onClick={() => handleClick('', '#appeal')} href={'#appeal'}>
                        {appeal?.insurancePlanName}
                      </a>
                    </td>
                    <td className={formFields?.appeal_insurance_plan_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.insurancePlanType}</p>
                    </td>
                    <td className={formFields?.appeal_type?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.appealType}</p>
                    </td>
                    <td className={formFields?.appeal_therapy_or_drug?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.therapyOrDrug}</p>
                    </td>
                    <td className={formFields?.appeal_initiated?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.appealInitiated}</p>
                    </td>
                  </tr>
                  <tr className='title-row'>
                    <td className={formFields?.appeal_status?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_status?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_denial_reasons?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_denial_reasons?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_denial_reason_other?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_denial_reason_other?.label}</Form.Label>
                    </td>
                    <td className={formFields?.appeal_denial_date?.hideField ? 'd-none' : ''}>
                      <Form.Label className='headerColor'>{formFields?.appeal_denial_date?.label}</Form.Label>
                    </td>
                  </tr>
                  <tr>
                    <td className={formFields?.appeal_status?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.appealStatus}</p>
                    </td>
                    <td className={formFields?.appeal_denial_reasons?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.appealDenialReasons}</p>
                    </td>
                    <td className={formFields?.appeal_denial_reason_other?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{appeal?.appealDenialReasonOther}</p>
                    </td>
                    <td className={formFields?.appeal_denial_date?.hideField ? 'd-none' : ''}>
                      <p className='text-gray'>{casedateFormatter(appeal?.appealDenialDate)}</p>
                    </td>
                  </tr>
                </table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };

  const CaseRow = transactions
    .slice(0)
    .reverse()
    .map((item) =>
      item?.caseMileStoneCode === 'PIN' ? (
        <PrescriptoinIntakeSection formFields={summaryFields} />
      ) : item?.caseMileStoneCode === 'BI' ? (
        <BenefitInvestigatioSection formFields={summaryFields} />
      ) : item?.caseMileStoneCode === 'PA' ? (
        <PriorAuthorizationSection formFields={summaryFields} />
      ) : item?.caseMileStoneCode === 'ENR' ? (
        <PatientEnrollmentSection formFields={summaryFields} />
      ) : item?.caseMileStoneCode === 'TP' ? (
        <TriageSection formFields={summaryFields} />
      ) : item?.caseMileStoneCode === 'APP' ? (
        <AppealSection formFields={summaryFields} />
      ) : (
        <p></p>
      )
    );
  if (isLoading) {
    return <> </>;
  }
  return (
    <>
      <p>{CaseRow}</p>
    </>
  );
};
