import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { PublicLayout } from '../components';
import { ResourceContent } from '../components';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { rawSafeHtmlProps, htmlProps } from '../utils';

export const PrivacyPolicyView = () => {
  const privacyPolicy = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['privacy-policy.body' ?? ''] as AnyType)?.data
  );
  return (
    <PublicLayout className='view-container'>
      <p className='mb-0'>
        <span {...htmlProps(privacyPolicy)} />
      </p>
    </PublicLayout>
  );
};
