import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal, ProgressBar } from 'react-bootstrap';
import { CloudUpload, Trash } from 'react-bootstrap-icons';
import { useDropzone, FileRejection } from 'react-dropzone';
import './RxAdmin.scss';
interface FileUploadProps {
  setShowFileUploadModal: (show: boolean) => void;
  setFileToSubmit: (file: File) => void;
  showFileUploadModal?: boolean;
}

export const FileUploadModel = ({ setShowFileUploadModal, showFileUploadModal, setFileToSubmit }: FileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const simulateUploading = () => {
    setIsUploading(true);
    setUploadingProgress(0);

    const totalChunks = 100;
    let currentChunk = 0;

    const uploadInterval = setInterval(() => {
      currentChunk += 1;
      const progress = (currentChunk / totalChunks) * 100;
      setUploadingProgress(progress);
      if (currentChunk === totalChunks) {
        clearInterval(uploadInterval);
      }
    }, 0);
  };

  useEffect(() => {
    if (file) {
      simulateUploading();
    }
  }, [file]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setError(null);
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const rejection = fileRejections[0];
      const reason = rejection.errors[0].message;
      setFile(null);
      setError(`File rejection: ${reason}`);
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: ['.xls', '.xlsx', '.xlsm'],
    onDrop,
    onDropRejected,
    multiple: false,
    maxFiles: 1,
    maxSize: 2000000
  });

  const handleUpload = () => {
    if (file) {
      setShowFileUploadModal(false);
      setFileToSubmit(file);
      setFile(null);
      setError(null);
    } else {
      setError('Please select a valid file before uploading.');
    }
  };

  const fileDeleteHandler = () => {
    setFile(null);
  };

  const handleClose = () => setShowFileUploadModal(false);
  return (
    <Modal size='lg' show={showFileUploadModal} onHide={handleClose} centered>
      <Modal.Body>
        <div className='file-upload-container text-center'>
          <div
            {...getRootProps({
              className: `upload-box border-primary p-4 mb3 ${isDragActive ? 'drag-active' : ''}`
            })}>
            <input {...getInputProps()} />
            <CloudUpload size={60} color='#007ea5' />
            <p className='text-secondary mt-2'>
              {isDragActive ? 'Drop your file here...' : 'Drag and drop a file here'}
            </p>
            <p className='mt-2'>
              <strong>OR</strong>
            </p>
            <Button variant='outline-primary mb-2'>CHOOSE A FILE</Button>
            <p className='text-secondary'>
              Accepted formats: <strong>XLS, XLSX</strong>
            </p>
            <p>You may upload a maximum of 1 document.</p>
            {error && <p className='text-danger'>{error}</p>}
          </div>
          {file && (
            <div className='mt-3 p-3 border'>
              <p className='text-left'>{file.name}</p>
              <Row className='align-items-center'>
                <Col xs={11}>{isUploading && <ProgressBar now={uploadingProgress} />}</Col>
                <Col xs={1}>
                  <Trash className='pointer' onClick={fileDeleteHandler} />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleClose}>
          CANCEL
        </Button>
        <Button variant='secondary' onClick={handleUpload} disabled={(error && error.length) || !file ? true : false}>
          UPLOAD
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
