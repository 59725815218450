import React, { useContext, useEffect } from 'react';

import { Col, Form } from 'react-bootstrap';
import { FormSpy, FormSpyRenderProps, useField, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';

import { API_URL } from '../api';
import {
  FormWizardPageChangeContext,
  NpiSearchProviderField,
  PublicLayout,
  ResourceContent,
  SelectField,
  TaxIdField,
  TextField
} from '../components';
import { ProviderNpiFragment } from '../graphql';
import flags from '../hooks/flags';
import { AnyType, Breadcrumb } from '../interfaces';
import { AppRootState, tenancyFeatureSelector } from '../redux';
import { getNpiValidationMessage } from '../redux/helpers';
import {
  clientApplicationNameSelector,
  dataDrivenFormFieldSection,
  FORM_WIZARD_VALUES,
  NOT_REQUIRED,
  ROUTE_PATHS,
  validations
} from '../utils';
import { RegistrationFormProps } from './Registration';

export const prescriberSchema = ({ programId }: { programId: number | undefined }) => {
  const prescriberFormSchema: AnyType = useSelector<AnyType>(tenancyFeatureSelector('form.prescriber-info'));

  return object({
    prescriber_npi: validations
      .npiValidation()
      .nullable()
      .required()
      .test('npi_prescriber_validate', getNpiValidationMessage(), async function () {
        const { prescriber_npi, administrator_is_prescriber, organization_npi } = this.parent ?? {};
        if (prescriber_npi === null || prescriber_npi === undefined) return true;
        if (
          administrator_is_prescriber === 'no' &&
          prescriber_npi?.toString()?.length == 10 &&
          organization_npi?.toString()?.length == 10
        ) {
          const url = `${API_URL}/api/register/IsNpiExists`;
          const queryParams = `orgNpi=${organization_npi}&physicianNpi=${prescriber_npi}&programId=${programId}`;
          const response = await window.fetch(`${url}?${queryParams}`, {
            method: 'GET'
          });
          const json = await response.json();
          return json?.isSuccess;
        }
        return true;
      }),
    prescriber_first_name: validations.nameValidation().label('First Name'),
    prescriber_middle_name: validations.nameValidation(NOT_REQUIRED).label('Middle Name'),
    prescriber_last_name: validations.nameValidation().label('Last Name'),
    prescriber_suffix: validations.suffixValidation(),
    prescriber_tax_id: validations.taxIdValidation(NOT_REQUIRED),
    prescriber_state_license: string().notRequired().label('State License #'),
    prescriber_licensing_state: validations.conditionalValidation(
      'prescriber_state_license',
      string().required().label('License State'),
      (value: string) => !!value
    ),
    prescriber_ptan: validations.ptanValidation(prescriberFormSchema?.fields?.prescriber_ptan?.required ?? false),
    prescriber_dea: validations.deaValidation(prescriberFormSchema?.fields?.prescriber_dea?.required ?? false),
    prescriber_hin: validations.hinValidation(prescriberFormSchema?.fields?.prescriber_hin?.required ?? false),
    prescriber_email: validations.emailValidation(prescriberFormSchema?.fields?.prescriber_email?.required ?? false)
  });
};

export const PrescriberView = (props: RegistrationFormProps) => {
  let suffix = useSelector((state: AppRootState) => state.app.suffix);
  const applicationName = useSelector(clientApplicationNameSelector);
  suffix =
    applicationName === 'celltrionconnecthcpportal'
      ? [...suffix, { label: 'DO', value: 'DO' }, { label: 'NP', value: 'NP' }, { label: 'PA', value: 'PA' }]
      : suffix;

  const states = flags.useStates();
  const { input } = useField('prescriber_state_license');
  const form = useForm();

  useEffect(() => {
    if (input.value === '') {
      form.change('prescriber_licensing_state', '');
    }
  }, [input, form]);

  useEffect(() => {
    setTimeout(() => {
      const values = form.getState().values;
      if (values.administrator_is_prescriber === 'yes') {
        form.batch(() => {
          form.change('prescriber_first_name', values.administrator_first_name);
          form.change('prescriber_last_name', values.administrator_last_name);
          form.change('prescriber_suffix', values.administrator_suffix);
        });
      }
    });
  }, [form]);

  const fields = props.formFields;
  const onNpiSelect = (npi: ProviderNpiFragment) => {
    if (!npi) return;

    let stateLicense;

    if (npi?.stateLicenses && npi?.stateLicenses?.length > 0) {
      stateLicense = npi.stateLicenses[0];
    }
    form.change('prescriber_npi', Number(npi?.npi) || null);
    npi?.firstName && form.change('prescriber_first_name', npi?.firstName ?? '');
    npi?.middleName && form.change('prescriber_middle_name', npi?.middleName ?? '');
    npi?.lastName && form.change('prescriber_last_name', npi?.lastName ?? '');
    stateLicense?.licenseNumber && form.change('prescriber_state_license', stateLicense?.licenseNumber ?? '');
    stateLicense?.stateCode && form.change('prescriber_licensing_state', stateLicense?.stateCode ?? '');
    npi?.taxId && form.change('prescriber_tax_id', npi?.taxId ?? '');
  };

  const changeFormWizardPage = useContext(FormWizardPageChangeContext);

  const breadcrumbs: Breadcrumb[] = [
    {
      href: ROUTE_PATHS.registrationWelcome,
      label: 'WELCOME',
      testId: 'welcome'
    },
    {
      onClick: () => changeFormWizardPage(FORM_WIZARD_VALUES?.pages?.registration?.names?.organization),
      label: 'ORGANIZATION INFORMATION',
      testId: 'organization'
    }
  ];
  if (props.isLoading) {
    return <p>...loading Prescriber Form</p>;
  }
  return (
    <PublicLayout breadcrumbs={breadcrumbs} className='view-container' pageTitle='Prescriber'>
      <div className='d-flex justify-content-end'>
        <p className='required-text pt-3'>
          <ResourceContent contentKey='general.required-fields-note' />
        </p>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps) => (
          <>
            <Form.Row>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_npi'
                className={'hideField' in fields?.prescriber_npi ? 'd-none' : ''}>
                <NpiSearchProviderField
                  asyncTypeaheadProps={{ disabled: values.administrator_is_prescriber === 'yes' }}
                  onNpiSelect={onNpiSelect}
                  fieldName='prescriber_npi'
                  {...dataDrivenFormFieldSection('prescriber_npi', fields?.prescriber_npi)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_first_name'
                className={'hideField' in fields?.prescriber_first_name ? 'd-none' : ''}>
                <TextField
                  disabled={values.administrator_is_prescriber === 'yes'}
                  {...dataDrivenFormFieldSection('prescriber_first_name', fields?.prescriber_first_name)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_middle_name'
                className={'hideField' in fields?.prescriber_middle_name ? 'd-none' : ''}>
                <TextField {...dataDrivenFormFieldSection('prescriber_middle_name', fields?.prescriber_middle_name)} />
              </Form.Group>

              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_last_name'
                className={'hideField' in fields?.prescriber_last_name ? 'd-none' : ''}>
                <TextField
                  disabled={values.administrator_is_prescriber === 'yes'}
                  {...dataDrivenFormFieldSection('prescriber_last_name', fields?.prescriber_last_name)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={2}
                controlId='prescriber_suffix'
                className={'hideField' in fields?.prescriber_suffix ? 'd-none' : ''}>
                <SelectField
                  options={suffix}
                  {...dataDrivenFormFieldSection('prescriber_suffix', fields?.prescriber_suffix)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_tax_id'
                className={'hideField' in fields?.prescriber_tax_id ? 'd-none' : ''}>
                <TaxIdField
                  data-testid='cmx__prescriber-tax-id'
                  {...dataDrivenFormFieldSection('prescriber_tax_id', fields?.prescriber_tax_id)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_state_license'
                className={'hideField' in fields?.prescriber_state_license ? 'd-none' : ''}>
                <TextField
                  {...dataDrivenFormFieldSection('prescriber_state_license', fields?.prescriber_state_license)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={3}
                lg={2}
                controlId='prescriber_licensing_state'
                className={'hideField' in fields?.prescriber_licensing_state ? 'd-none' : ''}>
                <SelectField
                  options={states}
                  disabled={!values.prescriber_state_license || values.prescriber_state_license === ''}
                  {...dataDrivenFormFieldSection('prescriber_licensing_state', fields?.prescriber_licensing_state)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_ptan'
                className={'hideField' in fields?.prescriber_ptan ? 'd-none' : ''}>
                <TextField {...dataDrivenFormFieldSection('prescriber_ptan', fields?.prescriber_ptan)} />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_dea'
                className={'hideField' in fields?.prescriber_dea ? 'd-none' : ''}>
                <TextField {...dataDrivenFormFieldSection('prescriber_dea', fields?.prescriber_dea)} />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_hin'
                className={'hideField' in (fields?.prescriber_hin ?? { hideField: '' }) ? 'd-none' : ''}>
                <TextField {...dataDrivenFormFieldSection('prescriber_hin', fields?.prescriber_hin)} />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                controlId='prescriber_email'
                className={'hideField' in fields?.prescriber_email ? 'd-none' : ''}>
                <TextField {...dataDrivenFormFieldSection('prescriber_email', fields?.prescriber_email)} />
              </Form.Group>
            </Form.Row>
          </>
        )}
      </FormSpy>
    </PublicLayout>
  );
};
