import { AnyObject } from 'final-form';
import { mixed, number, string } from 'yup';
import { ValidatorMapper } from '@data-driven-forms/react-form-renderer/dist/cjs/validator-mapper';

import { AnyType } from '../../interfaces';
import { validations } from '..';

export const validatorMapper: ValidatorMapper = {
  address: ({ label, max }: AnyType) =>
    validateYup(
      string()
        .max(max ?? 50)
        .allowedNonAlphaNumeric()
        .nullable()
        .label(label ?? 'Address')
    ),
  city: ({ label, max }: AnyType) =>
    validateYup(
      string()
        .max(max ?? 30)
        .allowedNonAlphaNumeric()
        .label(label ?? 'City')
    ),
  dob: () => validateYup(validations.dobValidation()),
  phone: () => validateYup(validations.phoneValidation().notRequired().nullable()),
  'phone-conditional': (props: AnyType) => (value: AnyType, allValues: AnyObject | undefined) => {
    let requiredError;
    if (allValues?.[props.compareToField]) {
      let notRequiredError;
      validations
        .phoneValidation()
        .notRequired()
        .nullable()
        .validate(value)
        .catch((e: AnyType) => (notRequiredError = e.message));
      return notRequiredError;
    }
    validations
      .phoneValidation('Either home phone or mobile number is required')
      .validate(value)
      .catch((e: AnyType) => (requiredError = e.message));
    return requiredError;
  },
  email: ({ label, max }: AnyType) =>
    validateYup(
      string()
        .max(max ?? 100)
        .email()
        .label(label ?? 'Email')
    ),
  id: ({ label, max, strict }: AnyType) =>
    validateYup(
      string()
        .alphaNumeric(strict ?? false)
        .max(max ?? 20)
        .label(label ?? 'ID')
    ),
  name: ({ label, max }: AnyType) =>
    validateYup(
      string()
        .allowedNonAlphaNumeric()
        .max(max ?? 50)
        .label(label ?? 'Name')
    ),
  number: ({ label }: AnyType) => validateYup(number().label(label)),
  'not-required': () => validateYup(mixed().notRequired()),
  'strong-password': () => validateYup(string().min(8).strongPassword().label('Password')),
  'same-as': (props: AnyType) => (value: AnyType, allValues: AnyObject | undefined) => {
    if (props?.strict) {
      return value === allValues?.[props.compareToField] ? '' : 'fields must match';
    }
    return value.toLowerCase() === allValues?.[props.compareToField].toLowerCase() ? '' : 'fields must match';
  },
  'tax-id': () => validateYup(string().maskLength('tax').label('Tax ID')),
  username: ({ max, label }: AnyType) =>
    validateYup(
      string()
        .max(max ?? 20)
        .matches(/^[\w\S]+$/gi, 'Username cannot contain spaces.')
        .label(label ?? 'Username')
    ),
  zip: ({ label }: AnyType) =>
    validateYup(
      string()
        .length(5)
        .maskLength('zip')
        .label(label ?? 'Zip Code')
    ),
  'at-least-one-required': ({ compareToField, message }: AnyType) => (value: AnyType, allVlaues: AnyType) =>
    !value && !allVlaues?.[compareToField] ? message ?? 'Required' : undefined,
  'required-if': ({ compareToField, message }: AnyType) => (value: AnyType, allVlaues: AnyType) =>
    !value && allVlaues?.[compareToField] ? message ?? 'Required' : undefined
};

export const validateYup = (schema: AnyType) => (value: AnyType) => {
  let error;
  schema?.validate(value).catch((e: AnyType) => (error = e.message));
  return error;
};
