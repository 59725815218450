import React, { FC, useContext } from 'react';
import { Accordion, AccordionContext, Card, Col, Form, Row } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { dateFormatter } from '../utils';

export type AppealTabProps = {
  appeals?: AnyType[] | AnyType;
  orgnizationId?: number;
};

export const AppealTabCollapsableSection = (appealProps: AppealTabProps) => {
  const appealFields = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['case-details.appeal'] as AnyType)?.data?.fields || {}
  );

  type AppealListType = { appealType: string; appealCreatedDate: string; appeals: AnyType[] };
  const appealList: AnyType[] = appealProps?.appeals || [];

  interface Props {
    children: React.ReactNode;
    eventKey: string;
  }

  const ContextAwareToggle: FC<Props> = (props: Props) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        className='cmx__case-detail-main-section-summary-tab__card-header'
        eventKey={props.eventKey}>
        <div>{props.children}</div>
        <hr />
        {isCurrentEventKey && <ChevronUp />}
        {!isCurrentEventKey && <ChevronDown />}
      </Accordion.Toggle>
    );
  };

  const FieldHeaderCol = ({ fieldName }: { fieldName: string }) => (
    <Col className={appealFields?.[fieldName]?.hideField ? 'd-none' : 'md-'}>
      <Form.Label className='headerColor'>{appealFields?.[fieldName]?.label}</Form.Label>
    </Col>
  );

  const FieldValueCol = ({ appeal, fieldName, isDate }: { appeal: AnyType; fieldName: string; isDate?: boolean }) => (
    <Col className={appealFields?.[fieldName]?.hideField ? 'd-none' : ''}>
      <p className='text-gray'>{isDate ? dateFormatter(appeal?.[fieldName]) : appeal?.[fieldName]}</p>
    </Col>
  );

  const IndividualAppealCollapsableSection = ({ appealType, appealCreatedDate, appeals }: AppealListType) => (
    <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
      <Card className='cmx__case-detail-main-section-summary-tab__card'>
        <ContextAwareToggle eventKey='4'>
          <strong>{`${appealType} - ${dateFormatter(appealCreatedDate)}`.toUpperCase()}</strong>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey='4'>
          <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
            <Row>
              <FieldHeaderCol fieldName='therapyOrDrug' />
              <FieldHeaderCol fieldName='appealRequirements' />
              <FieldHeaderCol fieldName='appealInitiated' />
              <FieldHeaderCol fieldName='appealCompletedDate' />
            </Row>
            {appeals &&
              appeals?.length > 0 &&
              appeals?.map((appeal: AnyType, index: number) => (
                <Row key={'appeal-tab-collapsable-sec-data-first-row-' + index}>
                  <FieldValueCol appeal={appeal} fieldName='therapyOrDrug' />
                  <FieldValueCol appeal={appeal} fieldName='appealRequirements' />
                  <FieldValueCol appeal={appeal} fieldName='appealInitiated' />
                  <FieldValueCol appeal={appeal} fieldName='appealCompletedDate' isDate={true} />
                </Row>
              ))}
            <br />
            <Row>
              <FieldHeaderCol fieldName='appealSubmitters' />
              <FieldHeaderCol fieldName='appealMethods' />
              <FieldHeaderCol fieldName='appealContactName' />
              <FieldHeaderCol fieldName='appealContactAddress' />
            </Row>
            {appeals &&
              appeals?.length > 0 &&
              appeals?.map((appeal: AnyType, index: number) => (
                <Row key={'appeal-tab-collapsable-sec-data-second-row-' + index}>
                  <FieldValueCol appeal={appeal} fieldName='appealSubmitters' />
                  <FieldValueCol appeal={appeal} fieldName='appealMethods' />
                  <FieldValueCol appeal={appeal} fieldName='appealContactName' />
                  <FieldValueCol appeal={appeal} fieldName='appealContactAddress' />
                </Row>
              ))}
            <br />
            <Row>
              <FieldHeaderCol fieldName='appealContactPhoneNumber' />
              <FieldHeaderCol fieldName='appealContactFaxNumber' />
              <FieldHeaderCol fieldName='appealOnlineSubmissionUrl' />
              <FieldHeaderCol fieldName='appealResponseTurnaroundTime' />
            </Row>
            {appeals &&
              appeals?.length > 0 &&
              appeals?.map((appeal: AnyType, index: number) => (
                <Row key={'appeal-tab-collapsable-sec-data-third-row-' + index}>
                  <FieldValueCol appeal={appeal} fieldName='appealContactPhoneNumber' />
                  <FieldValueCol appeal={appeal} fieldName='appealContactFaxNumber' />
                  <FieldValueCol appeal={appeal} fieldName='appealOnlineSubmissionUrl' />
                  <FieldValueCol appeal={appeal} fieldName='appealResponseTurnaroundTime' />
                </Row>
              ))}
            <Row>
              <FieldHeaderCol fieldName='appealStatus' />
              <FieldHeaderCol fieldName='appealDenialReasons' />
              <FieldHeaderCol fieldName='appealDenialReasonOther' />
              <FieldHeaderCol fieldName='appealDenialDate' />
            </Row>
            {appeals &&
              appeals?.length > 0 &&
              appeals?.map((appeal: AnyType, index: number) => (
                <Row key={'appeal-tab-collapsable-sec-data-fourth-row-' + index}>
                  <FieldValueCol appeal={appeal} fieldName='appealStatus' />
                  <FieldValueCol appeal={appeal} fieldName='appealDenialReasons' />
                  <FieldValueCol appeal={appeal} fieldName='appealDenialReasonOther' />
                  <FieldValueCol appeal={appeal} fieldName='appealDenialDate' isDate={true} />
                </Row>
              ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  return (
    <div>
      {appealList?.map((app: AnyType, index: number) => (
        <IndividualAppealCollapsableSection
          key={app?.appealType + index}
          appealType={app?.appealType}
          appealCreatedDate={app?.appealCreatedDate}
          appeals={[app]}
        />
      ))}
    </div>
  );
};
