import React, { useContext, useEffect, useState } from 'react';
import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FormWizardActions, FormWizardProgress, FormWizardTabs, Wizard, WizardContext } from '.';
import { AnyType } from '../../interfaces';
import { tenancyFeatureSelector, useTenancyDrugListSelector } from '../../redux';
import { FIELD_NAMES } from '../../utils';
import { Drug } from '../../api/portal-config.generated';

let validations = false;
interface FormWizardInternalProps {
  name: string;
}

const FormWizardInternal = React.memo((props: FormWizardInternalProps) => {
  const { activePage, activeStepIndex, currentStep, fields, formOptions, handleNext, handlePrev } = useContext(
    WizardContext
  );

  const autoServiceOption: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceOption'));
  const autoServiceTabSequence: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceTabSequence'));
  const { getState } = useFormApi();
  const form = useFormApi();
  const { hasValidationErrors, values } = getState();
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [serviceStep, setserviceStep] = useState('');

  if (autoServiceOption?.enabled) {
    const enrollemtData =
      sessionStorage?.enrollmentObj === '' ? '' : JSON.parse(sessionStorage?.enrollmentObj?.enrollmentData);
    //holds of  data from first service untill daisy chain proces untill new start request start
    if (sessionStorage.getItem('enrollmentObj') === '') {
      sessionStorage.getItem('primaryDiagnosed') === ''
        ? sessionStorage.setItem('primaryDiagnosed', values['clinical_patient_primary_diagnosed'])
        : (values['clinical_patient_primary_diagnosed'] = sessionStorage.getItem('primaryDiagnosed'));

      sessionStorage.getItem('previousTreatment') === ''
        ? sessionStorage.setItem('previousTreatment', values['clinical_add_previous_treatment'])
        : sessionStorage.getItem('previousTreatment');
    } else {
      if (enrollemtData !== '') {
        sessionStorage.setItem('primaryDiagnosed', enrollemtData?.clinical?.primaryDiagnosisCode);
        sessionStorage.setItem('previousTreatment', enrollemtData?.previousTreatments?.treatment);
      }
    }

    const newDrugList: Drug[] = useTenancyDrugListSelector();

    useEffect(() => {
      validations = hasValidationErrors;
      if (currentStep?.title === serviceStep) {
        setAutoSubmit(true);
      }
    }, [hasValidationErrors, handleNext, activeStepIndex]);

    useEffect(() => {
      SkippingProcess('patient').then(() => {
        form.change(FIELD_NAMES.clinicalFields.ndc, newDrugList[0]?.DrugNDC);
        form.change(FIELD_NAMES.clinicalFields.qty, newDrugList[0]?.Quantity !== null ? newDrugList[0]?.Quantity : '1');
        form.change(
          FIELD_NAMES.clinicalFields.daysSupply,
          newDrugList[0]?.DaysSupply !== null ? newDrugList[0]?.DaysSupply : '1'
        );
        form.change(FIELD_NAMES.clinicalFields.previousTreatment, sessionStorage.getItem('previousTreatment'));
        form.change(FIELD_NAMES.clinicalFields.primaryDiagnosed, sessionStorage.getItem('primaryDiagnosed'));
      });
    }, []);
  }

  const SkippingProcess = React.useCallback(
    async (nextStep: string) => {
      let validationError = false;
      let processstep = 0;
      const json = JSON.stringify(autoServiceTabSequence);
      const parsed = JSON?.parse(json);
      const steps = parsed[props.name];
      const stepsLenght = parsed[props.name]?.length;
      const noSkiptab = parsed['no-skip-tab'];

      if (steps && activeStepIndex! < stepsLenght && autoServiceOption) {
        const current = activeStepIndex! + 1;
        const length = steps.length;
        for (let i = current; i < length; i++) {
          processstep = i;
          if (validations || steps[i] == noSkiptab) {
            validationError = true;
            break;
          }
          if (!validations) {
            handleNext?.(steps[i]);
          }
        }
      }
      if (!hasValidationErrors && stepsLenght === processstep + 1) {
        setserviceStep(steps[processstep]);
      }
    },
    [handleNext]
  );

  return (
    <Col>
      <FormWizardProgress fields={fields} currentStep={currentStep} />
      <Row className='mb-5'>
        <Col sm={2} className='pr-0'>
          <FormWizardTabs fields={fields ?? []} currentStep={activeStepIndex ?? 0} />
        </Col>
        <Col className='border border-primary shadow p-3'>
          <Row>{activePage()}</Row>
          <FormWizardActions
            {...currentStep}
            formOptions={formOptions}
            handleNext={handleNext}
            handlePrev={handlePrev}
            disableBack={activeStepIndex === 0}
            autoService={autoSubmit}
          />
        </Col>
      </Row>
    </Col>
  );
});

FormWizardInternal.displayName = 'FormWizardInternal';

// eslint-disable-next-line react/display-name
export default (props: AnyObject) => <Wizard key={props.name} {...props} Wizard={FormWizardInternal ?? null} />;
