import React, { Props } from 'react';
import { rawSafeHtmlProps } from '../utils';

export interface HcpToastProps {
  toastTitle?: string;
  toastMessage?: string;
  className?: string;
}

export const HcpToast = (props: HcpToastProps) => {
  const { toastTitle, toastMessage } = props;
  return (
    <div className={'hcp-toast ' + props?.className}>
      <div className='hcp-toast__content'>
        {toastTitle && <p className='hcp-toast__title'>{toastTitle}</p>}
        {toastMessage && <p className='hcp-toast__detail' {...rawSafeHtmlProps(toastMessage)} />}
      </div>
    </div>
  );
};
