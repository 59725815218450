import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { ConnectedPatientSearch, RequestAddPatient, ResourceContent, SearchInputWithGqlDisplay } from '.';
import { PatientRequestViewModelFragment } from '../graphql';

interface AddPatientFormProps {
  defaultPatient?: PatientRequestViewModelFragment;
  onPatientSelect: (patient: PatientRequestViewModelFragment) => void;
  setDefaultPrescriber?: any;
  setDefaultLocation?: any;
  isDisabled?: boolean;
}

export const AddPatientForm = (props: AddPatientFormProps) => {
  const [isShown, setShown] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientRequestViewModelFragment | undefined>(
    props.defaultPatient
  );

  const onPatientSelect = (patient: PatientRequestViewModelFragment) => {
    props?.setDefaultPrescriber(null);
    props?.setDefaultLocation(null);
    props.onPatientSelect(patient);
    setSelectedPatient(patient);
  };

  const defaultSelected = props.defaultPatient ? [props.defaultPatient] : [];

  return (
    <Row>
      <Col className='mb-3'>
        <Row>
          <Col className='flex-nowrap w-75'>
            <ResourceContent
              contentKey='requests.new.patient.search.placeholder'
              render={({ content: placeholder }) => (
                <ConnectedPatientSearch
                  asyncTypeaheadProps={{
                    defaultSelected,
                    inputProps: { 'data-testid': 'cmx__add-patient__search' }
                  }}
                  display={SearchInputWithGqlDisplay.outlined}
                  placeholder={placeholder}
                  onPatientSelect={onPatientSelect}
                  isDisabled={props?.isDisabled}
                />
              )}
            />
          </Col>
          <Col>
            <ResourceContent
              contentKey={['requests.new.patient.button.add', 'requests.new.patient.button.cancel']}
              render={([{ content: addButtonLabel = '' }, { content: cancelButtonLabel = '' }]) => (
                <Button
                  className='text-nowrap py-1 my-auto ml-2 h-100'
                  variant='outline-secondary'
                  disabled={!!selectedPatient}
                  onClick={() => setShown(!isShown)}
                  data-testid='cmx__add-patient__button'>
                  {isShown ? cancelButtonLabel : addButtonLabel}
                </Button>
              )}
            />
          </Col>
        </Row>
      </Col>

      {isShown && !selectedPatient && (
        <div className='bg-light-gray py-2 px-2 my-4'>
          <RequestAddPatient setShown={() => setShown(false)} setServiceAddPatientView={true} />
        </div>
      )}
    </Row>
  );
};
