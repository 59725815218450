import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../..';
import { DBFormSchema, fieldTextSection, spacerSection } from '..';
import { fieldSelectSection, fieldSelectionDateTime, fieldRXPrescriberSearchSection } from '../field-helpers';

import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';

const { rxAdminDoseFields } = FIELD_NAMES;

export const getEditAdminDoseSchema = (schema: DBFormSchema): Schema => ({
  title: 'edit dose',
  fields: [
    spacerSection(1, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.patientName, schema?.[rxAdminDoseFields.patientName], {
      sizes: { md: 8 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.product, schema?.[rxAdminDoseFields.product], {
      sizes: { md: 8 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(3, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.dose, schema?.[rxAdminDoseFields.dose], {
      sizes: { md: 8 },
      validate: [
        {
          type: 'number'
        }
      ],
      readonly: 'readonly'
    }),
    spacerSection(4, { sizes: { sm: 3 } }),
    fieldSelectionDateTime(rxAdminDoseFields.adminDate, schema?.[rxAdminDoseFields.adminDate], {
      sizes: { md: 4 }
    }),
    fieldSelectSection(rxAdminDoseFields.status, schema?.[rxAdminDoseFields.status], {
      sizes: { md: 4 },
      options: [
        {
          label: 'Canceled',
          value: 'Canceled'
        },
        {
          label: 'Pending',
          value: 'Pending'
        },
        {
          label: 'Scheduled',
          value: 'Scheduled'
        },
        {
          label: 'Completed',
          value: 'Completed'
        }
      ]
    }),
    spacerSection(5, { sizes: { sm: 3 } }),
    fieldRXPrescriberSearchSection(rxAdminDoseFields.prescriberName, schema?.[rxAdminDoseFields.prescriberName], {
      sizes: { md: 8 }
    }),
    spacerSection(6, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.notes, schema?.[rxAdminDoseFields.notes], {
      sizes: { md: 8 }
    })
  ]
});
