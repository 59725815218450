import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import { useSelector } from 'react-redux';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';
import { ConditionDefinition } from '@data-driven-forms/react-form-renderer';

import {
  FIELD_NAMES,
  fieldSelectSection,
  fieldTypeAheadCptCodeSection,
  placeOfService,
  plainTextSection,
  primaryDiagnosisCodes,
  StepNames
} from '../..';
import { AppRootState } from '../../../redux';
import {
  DBFormSchema,
  fieldCheckboxSection,
  fieldDateSection,
  fieldDiagnosisSearchSection,
  fieldRadioSection,
  fieldSelectPrimaryDiagnosisCodesSection,
  fieldTextSection,
  headerSection,
  fieldPlainTextSection,
  spacerSection,
  addRemoveFieldsButton,
  inlineCheckBoxWithContent,
  fieldTypeAheadDrugDetails,
  titleWrapSection,
  fieldInputWithInlineLabel,
  plainTextSectionWithRawHtml,
  fieldCheckboxSectionForClinicalTab,
  fieldSelectE78DiagnosisCodesSection,
  fieldClinicalRadioSection
} from '../field-helpers';

const { clinicalFields } = FIELD_NAMES;

export const clinicalTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const clinicalTab = schema?.clinical_tab;
  const portal_name = useSelector((state: AppRootState) => state.app?.config?.applicationName);

  if (!clinicalTab || clinicalTab?.hideTab) return null;

  return {
    title: clinicalTab?.label ?? 'Clinical',
    name: StepNames.CLINICAL,
    nextStep: clinicalTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.clinical_information_header?.label, schema?.clinical_information_header),
      spacerSection('clinical_1', { sizes: { sm: 12 } }),
      fieldCheckboxSection(clinicalFields.previouslyEnrolled, schema?.[clinicalFields.previouslyEnrolled], {
        sizes: { md: 12 }
      }),
      fieldDateSection(clinicalFields.injectionDate, schema?.[clinicalFields.injectionDate], {
        sizes: { md: 4 }
      }),
      plainTextSectionWithRawHtml(
        clinicalFields.patientsMeetingCriteriaOtherHeader,
        schema?.[clinicalFields.patientsMeetingCriteriaOtherHeader],
        {
          sizes: { md: 12 }
        }
      ),
      fieldClinicalRadioSection(clinicalFields.E78Diagnosed, schema?.[clinicalFields.E78Diagnosed], {
        sizes: { md: 12 },
        className: 'radio-marign',
        options: [
          { value: 'e78', label: 'E78' },
          { value: 'e7801', label: 'E78.01 FAMILIAL HYPERCHOLESTEROLEMIA (e.g., HeFH)' },
          { value: 'other', label: 'OTHER (SPECIFY ICD-10-CM)' }
        ]
      }),

      spacerSection(`clinical_2`, {
        sizes: { sm: 2 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: (value: string[]) => !value?.includes('e78')
          }
        ]
      }),
      spacerSection(`clinical_6`, {
        sizes: { sm: 2 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e7801'
          }
        ]
      }),

      fieldSelectE78DiagnosisCodesSection(clinicalFields.E78Select, schema?.[clinicalFields.E78Select], {
        sizes: { md: 2 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),
      fieldCheckboxSection(clinicalFields.clinicalZ8342, schema?.[clinicalFields.clinicalZ8342], {
        sizes: { md: 7 }
      }),
      spacerSection(`clinical_4`, {
        sizes: { sm: 3 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: (value: string[]) => !value?.includes('other')
          },
          {
            when: clinicalFields.E78Diagnosed,
            is: (value: string[]) => !value?.includes('e7801')
          }
        ]
      }),
      fieldTextSection(clinicalFields.clinicalOther, schema?.[clinicalFields.clinicalOther], {
        sizes: { md: 3 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
            message: 'Please enter alphaNumeric'
          }
        ],
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'other'
          }
        ]
      }),
      spacerSection(`clinical_3`, { sizes: { sm: 2 } }),
      spacerSection(`clinical_5`, {
        sizes: { sm: 2 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e7801'
          }
        ]
      }),
      fieldCheckboxSection(clinicalFields.clinicalE755, schema?.[clinicalFields.clinicalE755], {
        sizes: { md: 9 }
      }),

      spacerSection(`clinical_5`, { sizes: { sm: 2 } }),
      fieldCheckboxSection(clinicalFields.clinicalOtherICD, schema?.[clinicalFields.clinicalOtherICD], {
        sizes: { md: 3 }
      }),
      fieldTextSection(clinicalFields.clinicalSpecifyOuterText, schema?.[clinicalFields.clinicalSpecifyOuterText], {
        sizes: { md: 3 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
            message: 'Please enter alphaNumeric'
          }
        ]
      }),

      fieldPlainTextSection(clinicalFields.clinicalSupportText, schema?.[clinicalFields.clinicalSupportText], {
        sizes: { md: 12 }
      }),

      plainTextSectionWithRawHtml(
        clinicalFields.clinicalPatientSecondaryheader,
        schema?.[clinicalFields.clinicalPatientSecondaryheader],
        {
          sizes: { md: 12 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),
      fieldCheckboxSection(clinicalFields.clinicalAscvd, schema?.[clinicalFields.clinicalAscvd], {
        sizes: { md: 7 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldCheckboxSection(clinicalFields.clinicalOtherRiskFactors, schema?.[clinicalFields.clinicalOtherRiskFactors], {
        sizes: { md: 5 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldCheckboxSection(clinicalFields.clinical12, schema?.[clinicalFields.clinical12], {
        sizes: { md: 1 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldTextSection(clinicalFields.clinical12TextOne, schema?.[clinicalFields.clinical12TextOne], {
        sizes: { md: 1 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
            message: 'Please enter alphaNumeric'
          }
        ],
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),
      fieldTextSection(clinicalFields.clinical12TextTwo, schema?.[clinicalFields.clinical12TextTwo], {
        sizes: { md: 2 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
            message: 'Please enter alphaNumeric'
          }
        ],
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),
      fieldPlainTextSection(
        clinicalFields.clinicalIschemicHeartLabel,
        schema?.[clinicalFields.clinicalIschemicHeartLabel],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldCheckboxSection(
        clinicalFields.clinicalDiabetes11Diagnosis,
        schema?.[clinicalFields.clinicalDiabetes11Diagnosis],
        {
          sizes: { md: 1 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(clinicalFields.clinicalDiabetesMellitus, schema?.[clinicalFields.clinicalDiabetesMellitus], {
        sizes: { md: 1 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldPlainTextSection(
        clinicalFields.clinicalDiabetesMellitusTitle,
        schema?.[clinicalFields.clinicalDiabetesMellitusTitle],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),
      fieldCheckboxSection(
        clinicalFields.clinical16CerebrovascularDiagnosis,
        schema?.[clinicalFields.clinical16CerebrovascularDiagnosis],
        {
          sizes: { md: 1 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(
        clinicalFields.clinical16CerebrovascularTextOne,
        schema?.[clinicalFields.clinical16CerebrovascularTextOne],
        {
          sizes: { md: 1 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
              message: 'Please enter alphaNumeric'
            }
          ],
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),
      fieldTextSection(
        clinicalFields.clinical16CerebrovascularTexttwo,
        schema?.[clinicalFields.clinical16CerebrovascularTexttwo],
        {
          sizes: { md: 2 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
              message: 'Please enter alphaNumeric'
            }
          ],
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),
      fieldPlainTextSection(
        clinicalFields.clinicalcerebrovascularLabel,
        schema?.[clinicalFields.clinicalcerebrovascularLabel],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldCheckboxSection(
        clinicalFields.clinicalHyperTensionI10Diagnosis,
        schema?.[clinicalFields.clinicalHyperTensionI10Diagnosis],
        {
          sizes: { md: 1 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(clinicalFields.clinicalHyperTension, schema?.[clinicalFields.clinicalHyperTension], {
        sizes: { md: 1 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldPlainTextSection(
        clinicalFields.clinicalHyperTensionTitle,
        schema?.[clinicalFields.clinicalHyperTensionTitle],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldCheckboxSection(
        clinicalFields.clinicalAtherosClerosisI70Diagnosis,
        schema?.[clinicalFields.clinicalAtherosClerosisI70Diagnosis],
        {
          sizes: { md: 1 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(
        clinicalFields.clinicalAtherosClerosisText,
        schema?.[clinicalFields.clinicalAtherosClerosisText],
        {
          sizes: { md: 3 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
              message: 'Please enter alphaNumeric'
            }
          ],
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldPlainTextSection(
        clinicalFields.clinicalAtherosClerosisLabel,
        schema?.[clinicalFields.clinicalAtherosClerosisLabel],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldCheckboxSection(
        clinicalFields.clinicalSpecifyICD10cmDiagnosis,
        schema?.[clinicalFields.clinicalSpecifyICD10cmDiagnosis],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(clinicalFields.clinicalOtherspecifyICD, schema?.[clinicalFields.clinicalOtherspecifyICD], {
        sizes: { md: 2 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldCheckboxSection(
        clinicalFields.clinicalOtherPeripheralVasculari73Diagnosis,
        schema?.[clinicalFields.clinicalOtherPeripheralVasculari73Diagnosis],
        {
          sizes: { md: 1 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldTextSection(
        clinicalFields.clinicalPeripheral1Vasculari173,
        schema?.[clinicalFields.clinicalPeripheral1Vasculari173],
        {
          sizes: { md: 3 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldPlainTextSection(
        clinicalFields.clinicalOtherPeripheralDiseaseTitle,
        schema?.[clinicalFields.clinicalOtherPeripheralDiseaseTitle],
        {
          sizes: { md: 8 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),

      fieldCheckboxSection(clinicalFields.clinicalOtherSpecifyICD, schema?.[clinicalFields.clinicalOtherSpecifyICD], {
        sizes: { md: 3 },
        condition: [
          {
            when: clinicalFields.E78Diagnosed,
            is: 'e78'
          }
        ]
      }),

      fieldTextSection(
        clinicalFields.clinicalOthersSpecifyICDText,
        schema?.[clinicalFields.clinicalOthersSpecifyICDText],
        {
          sizes: { md: 4 },
          condition: [
            {
              when: clinicalFields.E78Diagnosed,
              is: 'e78'
            }
          ]
        }
      ),
      spacerSection('clinical_1', { sizes: { sm: 5 } }),

      plainTextSectionWithRawHtml(
        clinicalFields.clienicalLdlLevelHeader,
        schema?.[clinicalFields.clienicalLdlLevelHeader],
        {
          sizes: { md: 12 }
        }
      ),

      fieldPlainTextSection(clinicalFields.clienicalLdlLevel, schema?.[clinicalFields.clienicalLdlLevel], {
        sizes: { md: 1 }
      }),

      fieldTextSection(clinicalFields.clienicalLdlText, schema?.[clinicalFields.clienicalLdlText], {
        sizes: { md: 2 },
        type: 'number',
        validate: [{ type: 'number', label: 'LDL Level' }]
      }),
      fieldPlainTextSection(clinicalFields.clinicalDateTakenText, schema?.[clinicalFields.clinicalDateTakenText], {
        sizes: { md: 1 }
      }),
      fieldDateSection(clinicalFields.clinicalDateTaken, schema?.[clinicalFields.clinicalDateTaken], {
        sizes: { md: 2 }
      }),
      fieldPlainTextSection(
        clinicalFields.clinicalLDLTreatmentLabel,
        schema?.[clinicalFields.clinicalLDLTreatmentLabel],
        {
          sizes: { md: 3 }
        }
      ),
      fieldTextSection(clinicalFields.clinicalLDLTratment, schema?.[clinicalFields.clinicalLDLTratment], {
        sizes: { md: 2 }
      }),

      fieldCheckboxSectionForClinicalTab(
        clinicalFields.patientMeetingCriteriaOther,
        schema?.[clinicalFields.patientMeetingCriteriaOther],
        {
          sizes: { md: 12 },
          customClassName: 'mb-0'
        }
      ),
      titleWrapSection(schema?.clinical_ascvd_selected_header?.label, schema?.clinical_ascvd_selected_header, {
        className: 'col-4'
      }),
      titleWrapSection(schema?.clinical_other_selected_header?.label, schema?.clinical_hefh_selected_header, {
        className: 'col-4 pl-1'
      }),
      fieldInputWithInlineLabel(
        clinicalFields.clinicalOtherSelectedHeader,
        schema?.[clinicalFields.clinicalOtherSelectedHeader],
        {
          sizes: { md: 4 },
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => value?.includes('other')
            }
          ],
          className: 'pl-0'
        }
      ),
      fieldInputWithInlineLabel(
        clinicalFields.clinicalOtherSelectedHeader,
        schema?.[clinicalFields.clinicalOtherSelectedHeaderOptional],
        {
          sizes: { md: 4 },
          optional: true,
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => !value?.includes('other')
            }
          ],
          className: 'pl-0'
        }
      ),
      fieldTypeAheadDrugDetails(clinicalFields.ndc, schema?.[clinicalFields.ndc], {
        placeholder: 'Type to search',
        sizes: { md: 12 }
      }),
      spacerSection('clinical_3', { sizes: { sm: 12 } }),
      fieldTextSection(clinicalFields.qty, schema?.[clinicalFields.qty], {
        type: 'number',
        sizes: { md: 4 },
        validate: [{ type: 'number', label: 'Quantity' }]
      }),
      spacerSection('clinical_4', { sizes: { sm: 12 } }),
      fieldTextSection(clinicalFields.daysSupply, schema?.[clinicalFields.daysSupply], {
        type: 'number',
        sizes: { md: 4 },
        validate: [{ type: 'number', label: 'Days Supply' }]
      }),
      spacerSection('clinical_5', { sizes: { sm: 12 } }),
      fieldSelectPrimaryDiagnosisCodesSection(
        clinicalFields.primaryDiagnosedSelect,
        schema?.[clinicalFields.primaryDiagnosedSelect],
        {
          sizes: { md: 4 }
        }
      ),
      fieldSelectPrimaryDiagnosisCodesSection(
        clinicalFields.primaryDiagnosedSelectAnother,
        schema?.[clinicalFields.primaryDiagnosedSelectAnother],
        {
          sizes: { md: 4 },
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => value?.includes('hasASCVD')
            }
          ]
        }
      ),
      fieldSelectPrimaryDiagnosisCodesSection(
        clinicalFields.primaryDiagnosedSelectAnother,
        schema?.[clinicalFields.primaryDiagnosedSelectOptional],
        {
          sizes: { md: 4 },
          optional: true,
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => !value?.includes('hasASCVD')
            }
          ]
        }
      ),
      fieldCheckboxSectionForClinicalTab(
        clinicalFields.hefhSelectedOptions,
        schema?.[clinicalFields.hefhSelectedOptionsOptional],
        {
          sizes: { md: 4 },
          optionsClassName: 'mr-0 full-width',
          customClassName: 'mb-0 col-md-6 px-1 full-width',
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => !value?.includes('hasHeFH')
            }
          ]
        }
      ),
      fieldCheckboxSectionForClinicalTab(
        clinicalFields.hefhSelectedOptions,
        schema?.[clinicalFields.hefhSelectedOptions],
        {
          sizes: { md: 4 },
          optionsClassName: 'mr-0 full-width',
          customClassName: 'mb-0 col-md-6 px-1 full-width',
          condition: [
            {
              when: clinicalFields.patientMeetingCriteriaOther,
              is: (value: string[]) => value?.includes('hasHeFH')
            }
          ]
        }
      ),

      fieldTextSection(clinicalFields.otherDiagnosisCode, schema?.[clinicalFields.otherDiagnosisCode], {
        sizes: { md: 4 },
        options: [...primaryDiagnosisCodes],
        condition: {
          or: [
            {
              when: clinicalFields.primaryDiagnosedSelectAnother,
              is: (value: string) => value?.toLowerCase() === 'other'
            },
            {
              when: clinicalFields.primaryDiagnosedSelect,
              is: (value: string) => value?.toLowerCase() === 'other'
            },
            {
              when: clinicalFields.primaryDiagnosedSelectOptional,
              is: (value: string) => value?.toLowerCase() === 'other'
            },
            {
              when: clinicalFields.primaryDiagnosed,
              is: (value: string) => value?.toLowerCase() === 'other'
            },
            {
              when: clinicalFields.secondaryDiagnosed,
              is: (value: string) => value?.toLowerCase() === 'other'
            },
            {
              when: clinicalFields.tertiaryDiagnosed,
              is: (value: string) => value?.toLowerCase() === 'other'
            }
          ]
        }
      }),

      spacerSection('clinical_8', {
        sizes: { sm: 4 },
        condition: [
          {
            when: clinicalFields.primaryDiagnosedSelectAnother,
            is: (value: string) => value?.toLowerCase() !== 'other'
          },
          {
            when: clinicalFields.primaryDiagnosedSelectOptional,
            is: (value: string) => value?.toLowerCase() !== 'other'
          }
        ]
      }),
      fieldTextSection(clinicalFields.clinicalHefhOtherSelected, schema?.[clinicalFields.clinicalHefhOtherSelected], {
        sizes: { md: 4 },
        className: 'p-1 hefh-selected-other',
        condition: [
          {
            when: clinicalFields.hefhSelectedOptions,
            is: (value: string[]) => value?.includes('other')
          }
        ]
      }),
      fieldTextSection(
        clinicalFields.clinicalHefhOtherSelected,
        schema?.[clinicalFields.clinicalHefhOtherSelectedOptional],
        {
          sizes: { md: 4 },
          className: 'p-1 hefh-selected-other',
          optional: true,
          condition: [
            {
              when: clinicalFields.hefhSelectedOptions,
              is: (value: string[]) => !value?.includes('other')
            }
          ]
        }
      ),
      spacerSection('clinical_17', { sizes: { sm: 12 } }),
      plainTextSection(clinicalFields.secondaryDiagnosisAlert, schema?.[clinicalFields.secondaryDiagnosisAlert]),
      spacerSection('clinical_14', { sizes: { sm: 12 } }),
      fieldDiagnosisSearchSection(clinicalFields.primaryDiagnosed, schema?.[clinicalFields.primaryDiagnosed], {
        sizes: { md: 7 }
      }),
      spacerSection('clinical_6', { sizes: { sm: 12 } }),
      fieldDiagnosisSearchSection(clinicalFields.secondaryDiagnosed, schema?.[clinicalFields.secondaryDiagnosed], {
        sizes: { md: 7 },
        filter: false
      }),
      spacerSection('clinical_7', { sizes: { sm: 12 } }),
      fieldDiagnosisSearchSection(clinicalFields.tertiaryDiagnosed, schema?.[clinicalFields.tertiaryDiagnosed], {
        sizes: { md: 7 }
      }),
      spacerSection('clinical_9', { sizes: { sm: 12 } }),
      fieldRadioSection(clinicalFields.diagnosed, schema?.[clinicalFields.diagnosed], {
        sizes: { md: 12 },
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
        ]
      }),
      fieldCheckboxSection(clinicalFields.patientMeetingCriteria, schema?.[clinicalFields.patientMeetingCriteria], {
        sizes: { md: 12 }
      }),
      spacerSection('clinical_10', { sizes: { sm: 12 } }),

      // Treatment Information Section
      headerSection(schema?.treatment_info_header?.label, schema?.treatment_info_header),
      spacerSection('clinical_11', { sizes: { sm: 12 } }),
      fieldDateSection(clinicalFields.treatmentStartDate, schema?.[clinicalFields.treatmentStartDate], {
        sizes: { md: 4 }
      }),
      fieldDateSection(clinicalFields.nextTreatmentDate, schema?.[clinicalFields.nextTreatmentDate], {
        sizes: { md: 4 }
      }),
      fieldSelectSection(clinicalFields.placeOfService, schema?.[clinicalFields.placeOfService], {
        sizes: { md: 4 },
        options: [...placeOfService(portal_name)]
      }),
      fieldTypeAheadCptCodeSection(clinicalFields.cptCodes, schema?.[clinicalFields.cptCodes], {
        sizes: { md: 4 }
      }),
      spacerSection('clinical_12', { sizes: { sm: 12 } }),
      fieldRadioSection(
        clinicalFields.treatmentInfoBillingPreference,
        schema?.[clinicalFields.treatmentInfoBillingPreference],
        {
          sizes: { sm: 5 },
          options: [
            { value: 'Buy & Bill', label: 'Buy & Bill' },
            { value: 'Specialty Pharmacy Dispense', label: 'Specialty Pharmacy Dispense' }
          ]
        }
      ),
      fieldRadioSection(
        clinicalFields.treatmentInfoSampleProductAdministered,
        schema?.[clinicalFields.treatmentInfoSampleProductAdministered],
        {
          sizes: { sm: 4 },
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
          ]
        }
      ),
      fieldDateSection(
        clinicalFields.treatmentInfoDateProductNeeded,
        schema?.[clinicalFields.treatmentInfoDateProductNeeded],
        {
          sizes: { md: 3 }
        }
      ),
      spacerSection('clinical_13', { sizes: { sm: 12 } }),
      fieldRadioSection(
        clinicalFields.treatmentInfoAnyKnownDrugAllergies,
        schema?.[clinicalFields.treatmentInfoAnyKnownDrugAllergies],
        {
          sizes: { sm: 4 },
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
          ]
        }
      ),
      fieldTextSection(clinicalFields.treatmentInfoAllergies, schema?.[clinicalFields.treatmentInfoAllergies], {
        sizes: { sm: 4 },
        condition: {
          when: clinicalFields.treatmentInfoAnyKnownDrugAllergies,
          is: 'yes'
        }
      }),
      fieldTextSection(
        clinicalFields.treatmentInfoContraindications,
        schema?.[clinicalFields.treatmentInfoContraindications],
        {
          sizes: { sm: 4 }
        }
      ),
      fieldRadioSection(
        clinicalFields.treatmentInfoPatientStartedTreatment,
        schema?.[clinicalFields.treatmentInfoPatientStartedTreatment],
        {
          sizes: { sm: 4 },
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
          ]
        }
      ),
      fieldTextSection(clinicalFields.visualAccuityRight, schema?.[clinicalFields.visualAccuityRight], {
        sizes: { sm: 4 }
      }),
      fieldTextSection(clinicalFields.visualAccuityLeft, schema?.[clinicalFields.visualAccuityLeft], {
        sizes: { sm: 4 }
      }),
      fieldDateSection(clinicalFields.previousStartDate, schema?.[clinicalFields.previousStartDate], {
        sizes: { md: 4 }
      }),
      fieldDateSection(clinicalFields.previousEndDate, schema?.[clinicalFields.previousEndDate], {
        sizes: { md: 4 }
      }),
      spacerSection(`clinical_5`, { sizes: { sm: 12 } }),
      fieldTextSection(clinicalFields.previousDose, schema?.[clinicalFields.previousDose], {
        sizes: { md: 3 }
      }),
      fieldTextSection(clinicalFields.previousTreatment, schema?.[clinicalFields.previousTreatment], {
        sizes: { md: 4 }
      }),
      fieldTextSection(clinicalFields.previousFrequency, schema?.[clinicalFields.previousFrequency], {
        sizes: { md: 4 }
      }),
      spacerSection(`clinical_7`, { sizes: { sm: 12 } }),
      ...addClinicalFields(schema, 1),
      ...addClinicalFields(schema, 2),
      spacerSection(`clinical_8`, { sizes: { sm: 12 } }),
      addRemoveFieldsButton(clinicalFields.addPreviousTreatment, schema?.[clinicalFields.addPreviousTreatment], {
        sizes: { sm: 12 },
        initialValue: 0,
        maxFields: 2,
        addLabel: 'ADD PREVIOUS TREATMENT',
        removeLabel: 'REMOVE PREVIOUS TREATMENT'
      }),
      spacerSection(`clinical_9`, { sizes: { sm: 12 } }),
      headerSection(schema?.clinical_opt_in_info_header?.label, schema?.clinical_opt_in_info_header),
      inlineCheckBoxWithContent(clinicalFields.optIn, schema?.[clinicalFields.optIn], {
        sizes: { sm: 12 }
      })
    ].filter(Boolean)
  };
};

const addClinicalFields = (schema: DBFormSchema, index: number) =>
  getAddPreviousTreatmentSchema(schema, index).fields.map((field) => {
    return {
      ...field,
      clearedValue: null,
      initializeOnMount: false,
      condition: {
        when: clinicalFields.addPreviousTreatment,
        greaterThanOrEqualTo: index,
        then: { visible: true },
        else: { set: { [field.name]: null }, visible: false }
      }
    };
  });

export const e78diagnisisSelect: { condition: ConditionDefinition[] } = {
  condition: [
    {
      when: clinicalFields.E78Diagnosed,
      is: 'e7801'
    },
    {
      when: clinicalFields.clinicalAscvd,
      is: ''
    }
  ]
};

export const getAddPreviousTreatmentSchema = (schema: DBFormSchema, index: number): Schema => ({
  title: `add previous treatment${index} form`,
  fields: [
    fieldDateSection(`clinical_previous_start_date${index}`, schema?.[`clinical_previous_start_date${index}`], {
      sizes: { md: 4 }
    }),
    fieldDateSection(`clinical_previous_end_date${index}`, schema?.[`clinical_previous_end_date${index}`], {
      sizes: { md: 4 }
    }),
    spacerSection(`clinical_${5 * index}`, { sizes: { sm: 12 } }),
    fieldTextSection(`clinical_previous_dose${index}`, schema?.[`clinical_previous_dose${index}`], {
      sizes: { md: 3 }
    }),
    fieldTextSection(`clinical_previous_treatment${index}`, schema?.[`clinical_previous_treatment${index}`], {
      sizes: { md: 4 }
    }),
    fieldTextSection(`clinical_previous_frequency${index}`, schema?.[`clinical_previous_frequency${index}`], {
      sizes: { md: 4 }
    }),
    spacerSection(`clinical_${7 * index}`, { sizes: { sm: 12 } })
  ]
});
