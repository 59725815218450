import _ from 'lodash';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { getAllTenancyFeatureSelector, AppRootState } from '../redux';

export type ScrollToElementProps = {
  parentEle: AnyType;
  navItemProps?: AnyType;
};

export const ScrollToElement = ({ parentEle, navItemProps }: ScrollToElementProps) => {
  const isMultiDrugEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isMultiDrugEnabled'] as AnyType)?.data?.enabled
  );

  const kebabLabel = _.kebabCase(parentEle?.label);

  const handleClickScroll = () => {
    const element = document.getElementById(parentEle?.scrollToElement);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {parentEle && 'scrollToElement' === parentEle?.type && parentEle?.featureKey && parentEle?.enabled && (
        <>
          {!isMultiDrugEnabled ? (
            <Nav.Link
              className='mx-2'
              href={parentEle?.url}
              onClick={handleClickScroll}
              style={{ color: navItemProps ?? '' }}
              data-testid={`cmx__${kebabLabel}`}>
              {parentEle?.label}
            </Nav.Link>
          ) : null}
        </>
      )}
    </>
  );
};
