import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RadioField } from '.';
import { Drug } from '../api/portal-config.generated';
import { useSelectTenancyResources } from '../hooks';
import { AnyType, ServiceOption } from '../interfaces';
import {
  ALL_SERVICE_REQUEST_KEY,
  INDIVIDUAL_SERVICE_REQUEST_KEY,
  tenancyDrugServicesSelector
} from '../redux/selectors/multi-tenancy.selector';
import { AppRootState } from '../redux';
import { useParams } from 'react-router-dom';
import { useCaseRecordsQuery, GRAPHQL_DEFAULT_CACHE, useCaseDetailsQuery } from '../graphql';

interface DrugServicesProps {
  drug: Drug;
  getServiceValue: (arg0: any) => unknown;
  caseDrugName: string;
}

export const DrugServices = (props: DrugServicesProps) => {
  const services = useSelector(tenancyDrugServicesSelector(props.drug.DrugId));
  const { patientId } = useParams<{ patientId: string }>();
  const { caseId } = useParams<{ caseId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fields, setFields] = useState<any[]>([]);

  const allResults = useSelectTenancyResources(ALL_SERVICE_REQUEST_KEY) || {};
  const individualResults = useSelectTenancyResources(INDIVIDUAL_SERVICE_REQUEST_KEY) ?? {};

  const [patientCaseRecord, setPatientCaseRecord] = useState<any>(null);
  const [checkPatientEnrollment, setCheckPatientEnrollment] = useState<boolean>(false);
  const [enrollmentEnabledDrug, setEnrollmentEnabledDrug] = useState<any>('');
  const [showHubEnrollment, setShowHubEnrollment] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const queryVariables = {
    organizationId: useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId) || '',
    programId: useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '',
    patientId: patientId,
    userName: useSelector((state: AppRootState) => state.auth.session?.user?.Login) || ''
  };

  const result = useCaseRecordsQuery({ variables: { ...queryVariables }, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  useEffect(() => {
    if (props?.caseDrugName) {
      const data = result?.data?.result;
      setPatientCaseRecord(data?.find((d) => d?.caseRecordId.toString() === caseId));
    }
  }, [result?.data?.result]);

  const caseResult = useCaseDetailsQuery({ variables: { caseRecordId: caseId } });
  useEffect(() => {
    if (props?.caseDrugName) {
      const caseTransactions = caseResult.data?.result?.transactions ?? [];
      setCheckPatientEnrollment(
        caseTransactions?.find((element: any) => element?.caseMileStoneCode === 'ENR') ? true : false
      );
    }
  }, [caseResult.data?.result]);

  const enrollmentEnabledDrugs =
    useSelector(
      (state: AppRootState) =>
        (((state.app?.entities?.features as AnyType) ?? {})['drugbased-enrollmentModule.button' ?? ''] as AnyType)?.data
    ) ?? [];

  const patientRequestText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['requests.new.service.text' ?? ''] as AnyType)?.data
  );

  useEffect(() => {
    if (props?.caseDrugName) {
      setEnrollmentEnabledDrug(
        enrollmentEnabledDrugs?.enable?.find(
          (element: any) => element?.toLowerCase() === props?.caseDrugName?.toLowerCase()
        )
      );
    }
  }, [enrollmentEnabledDrugs]);

  useEffect(() => {
    if (props?.caseDrugName) {
      setShowLoader(true);
      setShowHubEnrollment(
        patientCaseRecord && !checkPatientEnrollment && !patientCaseRecord?.caseClosed && enrollmentEnabledDrug
      );
      setShowLoader(false);
    }
  }, [enrollmentEnabledDrug, checkPatientEnrollment, patientCaseRecord]);

  useEffect(() => {
    if (!allResults || !individualResults) return;
    let options = [];
    if (props?.caseDrugName) {
      if (showHubEnrollment) {
        options = [...(allResults.options ?? []), ...(individualResults.options ?? [])];
      } else {
        options = [...(individualResults.options ?? [])];
      }
    } else {
      options = [...(allResults.options ?? []), ...(individualResults.options ?? [])];
    }

    // If there are no options, just stop here.
    if (options.length === 0) return;

    // Create the array of form elements
    setFields(
      options
        .map((serviceOption: Partial<ServiceOption>, i: number) => {
          // Prefix the DrugId so it matches the uniqueness approach in [app.reducer.ts].
          const featureKey = `service-request.${serviceOption.value}`;

          return (
            services[featureKey as string]?.data?.enabled && (
              <Form.Group as={Col} controlId='service' className='mb-0' key={featureKey}>
                <RadioField
                  id={`service_${i}`}
                  name='service'
                  value={serviceOption.value}
                  label={<p className='mb-0 fs-4'>{serviceOption.label}</p>}
                  disabled={serviceOption.disabled}
                  onClick={() => props.getServiceValue(services[featureKey as string]?.data)}
                  description={<p className='fs-3 font-italic'>{serviceOption.description}</p>}
                  data-testid={`cmx__request__${serviceOption.value}`}
                />
              </Form.Group>
            )
          );
        })
        .filter(Boolean)
    );
  }, [allResults, individualResults, showHubEnrollment]);

  const hasFields = fields.length > 0;

  return (
    <>
      <h3 className='fs-4 font-weight-bold text-primary mt-3 mb-4 text-uppercase'>{patientRequestText.content}</h3>

      {showLoader ? <Spinner animation='border' size='sm' /> : hasFields && fields}

      {!hasFields && (
        <Alert variant='info'>
          {props.drug.DrugBrandName} selection not available online currently, please submit enrollment form via fax.
        </Alert>
      )}
    </>
  );
};
