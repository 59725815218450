import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';

import { StepNames } from '.';
import { FIELD_NAMES } from '../../field-names';
import {
  DBFormSchema,
  fieldOrganizationNpiSection,
  fieldRadioSection,
  fieldAdministrationInfo,
  fieldSelectStatesSection,
  fieldTextSection,
  fieldUploadSection,
  headerSection,
  plainTextSection,
  spacerSection,
  fieldCheckboxSection,
  fieldPlainTextSection,
  fieldNpiInput,
  plainTextSectionWithRawHtml,
  fieldDateSection
} from '../field-helpers';
import { EMAIL_REGEX, EMAIL_VALIDATION_MESSAGE, NUMBER_REGEX, REQUIRED_MESSAGE } from '../../constants';

const { copayFields, facilityFields, treatmentSiteFields } = FIELD_NAMES;

export const otherTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const otherTab = schema?.other_tab;

  if (!otherTab || otherTab?.hideTab) return null;

  const isCheckOnly = true;
  let copayClaimPaymentCheckBox = [];
  //CRSN-493 Leqvio
  if (schema?.copay_preferred_payment_method?.hideEft) {
    copayClaimPaymentCheckBox = [{ label: 'Check', value: 'CHECK', checked: { isCheckOnly } }];
  } else if (schema?.copay_preferred_payment_method?.options) {
    copayClaimPaymentCheckBox = [...schema?.copay_preferred_payment_method?.options];
  } else {
    copayClaimPaymentCheckBox = [
      { label: 'Check', value: 'CHECK' },
      { label: 'EFT', value: 'EFT' }
    ];
  }

  return {
    title: otherTab?.label ?? 'Other',
    name: StepNames.OTHER,
    nextStep: otherTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.administration_information_header?.label, schema?.administration_information_header),
      fieldAdministrationInfo(facilityFields.administrationInfo, schema?.[facilityFields.administrationInfo], {
        options: schema?.administration_info_options,
        sizes: { md: 8 }
      }),
      spacerSection('other_1', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      headerSection(schema?.alternate_facility_header?.label, schema?.alternate_facility_header, {
        ...isAlternateInjectionCondition
      }),
      fieldOrganizationNpiSection(facilityFields.npi, schema?.[facilityFields.npi], {
        sizes: { md: 5 },
        ...isAlternateInjectionCondition
      }),
      spacerSection('other_2', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      fieldTextSection(facilityFields.name, schema?.[facilityFields.name], {
        sizes: { md: 6 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      spacerSection('other_3', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      fieldTextSection(facilityFields.address1, schema?.[facilityFields.address1], {
        sizes: { md: 6 },
        validate: [
          {
            type: 'address'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      fieldTextSection(facilityFields.address2, schema?.[facilityFields.address2], {
        sizes: { md: 6 },
        validate: [
          {
            type: 'address'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      spacerSection('other_4', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      fieldTextSection(facilityFields.zip, schema?.[facilityFields.zip], {
        type: 'zip',
        sizes: { md: 2 },
        validate: [
          {
            type: 'zip'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      fieldTextSection(facilityFields.city, schema?.[facilityFields.city], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'city'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      fieldSelectStatesSection(facilityFields.state, schema?.[facilityFields.state], {
        sizes: { md: 2 },
        ...isAlternateInjectionCondition
      }),
      fieldTextSection(facilityFields.taxId, schema?.[facilityFields.taxId], {
        type: 'tax',
        sizes: { md: 4 },
        validate: [
          {
            type: 'tax-id'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      spacerSection('other_5', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      fieldTextSection(facilityFields.officeContactFirstName, schema?.[facilityFields.officeContactFirstName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      fieldTextSection(facilityFields.officeContactLastName, schema?.[facilityFields.officeContactLastName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      fieldTextSection(facilityFields.officeContactPhone, schema?.[facilityFields.officeContactPhone], {
        type: 'tel',
        sizes: { md: 3 },
        validate: [
          {
            type: 'phone'
          }
        ],
        ...isAlternateInjectionCondition
      }),
      spacerSection('other_6', { sizes: { sm: 12 }, ...isAlternateInjectionCondition }),
      plainTextSection('triage_services', schema?.triage_services, {
        sizes: { md: 12 }
      }),
      spacerSection('other_7', { sizes: { sm: 12 } }),
      headerSection(schema?.copay_claim_information_header?.label, schema?.copay_claim_information_header),
      fieldRadioSection(copayFields.preferredPaymentMethod, schema?.[copayFields.preferredPaymentMethod], {
        sizes: { md: 4 },
        options: copayClaimPaymentCheckBox
      }),
      spacerSection('other_7_1', { sizes: { sm: 12 } }),
      fieldTextSection(copayFields.cardId, schema?.[copayFields.cardId], {
        sizes: { md: 4 }
      }),
      spacerSection('other_7_2', { sizes: { sm: 12 }, hideField: schema?.spacer_other_7_2?.hideField }),
      fieldTextSection(copayFields.groupNumber, schema?.[copayFields.groupNumber], {
        sizes: { md: 4 }
      }),
      spacerSection('other_8', { sizes: { sm: 12 } }),
      fieldTextSection(copayFields.emailId, schema?.[copayFields.emailId], {
        type: 'email',
        sizes: { md: 4 },
        validate: [{ type: 'email' }],
        condition: {
          when: copayFields.preferredPaymentMethod,
          is: 'EFT'
        }
      }),
      schema?.copay_preferred_payment_method?.hideEft
        ? fieldTextSection(copayFields.emailId, schema?.[copayFields.emailId], {
            type: 'email',
            sizes: { md: 4 },
            validate: [{ type: 'email' }]
          })
        : fieldTextSection(copayFields.emailId, schema?.[copayFields.emailId2], {
            type: 'email',
            sizes: { md: 4 },
            validate: [{ type: 'email' }],
            condition: {
              when: copayFields.preferredPaymentMethod,
              is: 'CHECK'
            }
          }),
      fieldTextSection(copayFields.checkPayableTo, schema?.[copayFields.checkPayableTo], {
        sizes: { md: 4 }
      }),
      spacerSection('other_8_1', { sizes: { sm: 12 } }),
      fieldTextSection(
        copayFields.copayProviderPaymentRemittanceAddress,
        schema?.[copayFields.copayProviderPaymentRemittanceAddress],
        {
          sizes: { md: 8 }
        }
      ),
      spacerSection('other_8_2', { sizes: { sm: 12 } }),
      fieldTextSection(copayFields.copayCity, schema?.[copayFields.copayCity], {
        sizes: { md: 4 }
      }),
      fieldSelectStatesSection(copayFields.copayState, schema?.[copayFields.copayState], {
        sizes: { md: 4 }
      }),
      fieldTextSection(copayFields.copayZipCode, schema?.[copayFields.copayZipCode], {
        sizes: { md: 4 }
      }),
      spacerSection('other_8_3', { sizes: { sm: 12 } }),
      fieldTextSection(copayFields.copayFaxNumber, schema?.[copayFields.copayFaxNumber], {
        sizes: { md: 4 }
      }),
      fieldTextSection(copayFields.copayNpiNumber, schema?.[copayFields.copayNpiNumber], {
        sizes: { md: 4 }
      }),
      fieldTextSection(copayFields.copayTaxIdNumber, schema?.[copayFields.copayTaxIdNumber], {
        sizes: { md: 4 }
      }),
      spacerSection('other_9', { sizes: { sm: 12 } }),
      headerSection(copayFields.copayReimbursementHeader, schema?.[copayFields.copayReimbursementHeader]),
      spacerSection('other_8_4', { sizes: { sm: 12 }, hideField: schema?.spacer_other_8_4?.hideField ?? true }),
      fieldTextSection(copayFields.copayMedicationName, schema?.[copayFields.copayMedicationName], {
        sizes: { md: 4 }
      }),
      fieldTextSection(copayFields.copayRegistrationRepayment, schema?.[copayFields.copayRegistrationRepayment], {
        sizes: { md: 4 }
      }),
      spacerSection('other_8_5', { sizes: { sm: 12 }, hideField: schema?.spacer_other_8_5?.hideField ?? true }),
      fieldTextSection(
        copayFields.copayPaymentReimbursementAddress,
        schema?.[copayFields.copayPaymentReimbursementAddress],
        {
          sizes: { md: 8 }
        }
      ),
      spacerSection('other_11', { sizes: { sm: 12 }, hideField: schema?.spacer_other_11?.hideField ?? true }),
      fieldTextSection(copayFields.copaySuite, schema?.[copayFields.copaySuite], {
        sizes: { md: 8 }
      }),
      spacerSection('other_12', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12?.hideField ?? true }),
      fieldTextSection(copayFields.copayReimbursementCity, schema?.[copayFields.copayReimbursementCity], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'city'
          }
        ]
      }),
      fieldSelectStatesSection(copayFields.copayReimbursementState, schema?.[copayFields.copayReimbursementState], {
        sizes: { md: 2 }
      }),
      fieldTextSection(copayFields.copayReimbursementZip, schema?.[copayFields.copayReimbursementZip], {
        type: 'zip',
        sizes: { md: 2 },
        validate: [
          {
            type: 'zip'
          }
        ]
      }),
      spacerSection('other_12_1', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12_1?.hideField ?? true }),
      fieldTextSection(copayFields.copayPrimaryPayerName, schema?.[copayFields.copayPrimaryPayerName], {
        sizes: { md: 4 }
      }),
      fieldDateSection(copayFields.copayDateOfService, schema?.[copayFields.copayDateOfService], {
        sizes: { md: 4 }
      }),
      spacerSection('other_12_2', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12_2?.hideField ?? true }),
      fieldTextSection(copayFields.copayPatientCostShareEOD, schema?.[copayFields.copayPatientCostShareEOD], {
        sizes: { md: 4 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: NUMBER_REGEX,
            message: REQUIRED_MESSAGE
          }
        ]
      }),
      fieldTextSection(
        copayFields.copayReimbursementAmountRequested,
        schema?.[copayFields.copayReimbursementAmountRequested],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: NUMBER_REGEX,
              message: REQUIRED_MESSAGE
            }
          ]
        }
      ),
      spacerSection('other_12_3', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12_3?.hideField ?? true }),
      headerSection(copayFields.copayRequestorHeader, schema?.[copayFields.copayRequestorHeader]),
      spacerSection('other_12_4', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12_4?.hideField ?? true }),
      fieldTextSection(copayFields.copayReqFirstname, schema?.[copayFields.copayReqFirstname], {
        sizes: { md: 4 }
      }),
      fieldTextSection(copayFields.copayRequestorLastName, schema?.[copayFields.copayRequestorLastName], {
        sizes: { md: 4 }
      }),
      spacerSection('other_12_5', { sizes: { sm: 12 }, hideField: schema?.spacer_other_12_5?.hideField ?? true }),
      fieldTextSection(copayFields.copayRequestorPhoneNumber, schema?.[copayFields.copayRequestorPhoneNumber], {
        sizes: { md: 4 },
        type: 'tel',
        validate: [
          {
            type: 'phone'
          }
        ]
      }),
      fieldTextSection(copayFields.copayRequestorEmail, schema?.[copayFields.copayRequestorEmail], {
        sizes: { md: 4 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: EMAIL_REGEX,
            message: EMAIL_VALIDATION_MESSAGE
          }
        ],
        condition: {
          when: copayFields.preferredPaymentMethod,
          is: (value: string) => {
            if (value === 'ACH') return false;
            return true;
          }
        }
      }),
      spacerSection('other_13', { sizes: { sm: 12 } }),
      headerSection(schema?.required_documentation_header?.label, schema?.required_documentation_header),
      plainTextSection('upload_information_text', schema?.upload_information_text, {
        sizes: { md: schema?.upload_information_text?.sizes ?? 6 }
      }),
      headerSection(schema?.upload_forms_header?.label, schema?.upload_forms_header),
      fieldUploadSection(copayFields.additionalDocumentsUpload, schema?.[copayFields.additionalDocumentsUpload], {
        sizes: { md: 12 }
      }),
      spacerSection('other_10', { sizes: { sm: 12 } }),
      plainTextSectionWithRawHtml(
        treatmentSiteFields.treatingSiteHeader,
        schema?.[treatmentSiteFields.treatingSiteHeader],
        {
          sizes: { md: 12 }
        }
      ),
      fieldCheckboxSection(treatmentSiteFields.referMyPatient, schema?.[treatmentSiteFields.referMyPatient], {
        sizes: { md: 12 }
      }),
      fieldCheckboxSection(
        treatmentSiteFields.sendUpdatesAndCommnunication,
        schema?.[treatmentSiteFields.sendUpdatesAndCommnunication],
        {
          sizes: { md: 12 },
          ...isReferMyPatientCondition
        }
      ),
      fieldTextSection(treatmentSiteFields.siteName, schema?.[treatmentSiteFields.siteName], {
        sizes: { sm: 4 },
        ...isReferMyPatientCondition
      }),
      fieldTextSection(treatmentSiteFields.contactName, schema?.[treatmentSiteFields.contactName], {
        sizes: { sm: 4 },
        ...isReferMyPatientCondition
      }),
      fieldTextSection(treatmentSiteFields.phone, schema?.[treatmentSiteFields.phone], {
        sizes: { sm: 4 },
        type: 'tel',
        ...isReferMyPatientCondition
      }),
      fieldTextSection(treatmentSiteFields.fax, schema?.[treatmentSiteFields.fax], {
        sizes: { sm: 4 },
        type: 'tel',
        ...isReferMyPatientCondition
      }),
      fieldTextSection(treatmentSiteFields.email, schema?.[treatmentSiteFields.email], {
        sizes: { sm: 4 },
        ...isReferMyPatientCondition
      }),
      fieldNpiInput(treatmentSiteFields.npiNumber, schema?.[treatmentSiteFields.npiNumber], {
        sizes: { sm: 4 },
        ...isReferMyPatientCondition
      }),
      fieldTextSection(treatmentSiteFields.taxId, schema?.[treatmentSiteFields.taxId], {
        sizes: { sm: 4 },
        type: 'tax',
        validate: [
          {
            type: 'tax-id'
          }
        ],
        ...isReferMyPatientCondition
      })
    ].filter(Boolean)
  };
};

const isAlternateInjectionCondition = {
  condition: {
    when: facilityFields.administrationInfo,
    is: 'Alternate Injection Center (AIC)/Hospital Outpatient Department (HOPD)'
  }
};

const isReferMyPatientCondition = {
  condition: {
    when: treatmentSiteFields.referMyPatient,
    is: (value: any) => value?.length
  }
};
