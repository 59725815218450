import React from 'react';
import { useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

export const AppInsights = () => {
  const browserHistory = createBrowserHistory({ basename: '' });
  const connectionString = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.features as AnyType) ?? {})['appInsights.connectionString'] as AnyType
  );
  if (connectionString && connectionString?.data.appInsights_connectionString) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString?.data.appInsights_connectionString,
        extensions: [reactPlugin],
        isCookieUseDisabled: true,
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    });
    appInsights.loadAppInsights();
  }

  return <></>;
};
