import React, { useEffect } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';

import { useForgotPassword } from '../api';
import {
  AuthLayout,
  HcpFormRenderer,
  HcpFormTemplate,
  PublicLayout,
  ResourceAuthLayoutHeader,
  ResourceContent
} from '../components';
import { clientApplicationNameSelector, getForgotPasswordFormSchema, ROUTE_PATHS } from '../utils';
import { AnyType, GeneralObject } from '../interfaces';
import { AppRootState, navigateToForgotPasswordSuccess } from '../redux';
import image from '../images/img_Hero.jpg';

export const ForgotPasswordView = () => {
  const dispatch = useDispatch();
  const result = useForgotPassword({ lazy: true });
  const isError = result.data && (result.data?.errors?.length ?? 0) > 0;
  const isComplete = result.response?.status === 200 && !isError;
  const applicationName = useSelector(clientApplicationNameSelector);
  const isLoginImage = useSelector(
    (state: AppRootState) => ((state.app?.entities?.resources as AnyType) ?? {})['login.image'] as AnyType
  );

  const onSubmit = async (values: GeneralObject) => {
    await result.refetch({
      queryParams: {
        userName: String(values.username),
        applicationName: applicationName
      }
    });
  };

  const renderTemplate = (formProps: FormTemplateRenderProps) => {
    return <ForgotPasswordFormTemplate {...formProps} isError={isError} />;
  };

  useEffect(() => {
    if (!isComplete) return;

    dispatch(navigateToForgotPasswordSuccess());
  }, [isComplete, dispatch]);

  return (
    <PublicLayout fluid className='authlayout_custom_image'>
      <AuthLayout image={isLoginImage?.data?.url}>
        <ResourceAuthLayoutHeader contentKey='forgot-password.title'>
          <ResourceContent contentKey='forgot-password.body' />
        </ResourceAuthLayoutHeader>

        <HcpFormRenderer
          contentKey='form.forgot-password'
          FormTemplate={renderTemplate}
          noErrorMessage
          onSubmit={onSubmit}
          schemaMethod={getForgotPasswordFormSchema}
        />
      </AuthLayout>
    </PublicLayout>
  );
};

const ForgotPasswordFormTemplate = (props: FormTemplateRenderProps) => {
  const renderUserNotFound = () => {
    return (
      <Col>
        <p className='text-danger fs-3'>
          <ExclamationCircleFill /> <ResourceContent contentKey='forgot-password.error' />
        </p>
      </Col>
    );
  };

  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const formState = formProps.getState();
        const { submitting, hasValidationErrors } = formState;
        return (
          <Row>
            {props.isError && renderUserNotFound()}
            <Col xs={12} className='text-center my-3'>
              <Button
                type='submit'
                variant='secondary'
                disabled={submitting || hasValidationErrors}
                data-testid='cmx__login-button'>
                {submitting ? 'SUBMITTING...' : 'RESET MY PASSWORD'}
              </Button>
            </Col>
            <Col xs={12} className='text-center text-custom-50'>
              <p className='fs-2'>
                <ResourceContent contentKey='general.register-teaser' />
                {'   '}
                <Link
                  to={ROUTE_PATHS.registrationWelcome}
                  className='text-secondary'
                  data-testid='cmx__login__register'>
                  REGISTER
                </Link>
              </p>
            </Col>
          </Row>
        );
      }}
    />
  );
};
