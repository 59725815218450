import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ResourcePublicLayout, ResourceContent } from '../components';
import { navigateToLogin } from '../redux';
import { ACCESS_URL, RESOURCES_URL } from '../utils';
import { rawSafeHtmlProps } from '../utils';

export const RegistrationSubmitView = () => {
  const dispatch = useDispatch();
  return (
    <ResourcePublicLayout contentKey='registration.submitted.title'>
      <Row className='py-4'>
        <Col lg={8}>
          <ResourceContent
            contentKey='registration.submitted.body'
            sourceDataKey='content'
            render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
          />
        </Col>

        <Col lg={4} sm={5}>
          <div className='py-4 px-4 bg-light-gray'>
            <ResourceContent
              contentKey='registration.submitted.assistance_notice'
              sourceDataKey='content'
              render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
            />
            <table className='mb-4'>
              <tbody>
                <tr>
                  <th>Phone:</th>
                  <td className='pl-4'>
                    <ResourceContent
                      contentKey='registration.submitted.assistance_notice'
                      sourceDataKey='phone'
                      render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                    />
                  </td>
                </tr>
                <tr>
                  <th className='pt-2'>Fax:</th>
                  <td className='pl-4 pt-2'>
                    <ResourceContent
                      contentKey='registration.submitted.assistance_notice'
                      sourceDataKey='fax'
                      render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <p className='mb-0'>
              <ResourceContent
                contentKey='registration.submitted.assistance_notice'
                sourceDataKey='hours'
                render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
              />
            </p>
          </div>
        </Col>
      </Row>

      <Row className='pb-3'>
        <Col lg={{ offset: 9, span: 3 }} sm={{ offset: 5, span: 7 }}>
          <div className='text-right'>
            <Button variant='secondary' onClick={() => dispatch(navigateToLogin())}>
              BACK TO HOME PAGE
            </Button>
          </div>
        </Col>
      </Row>
    </ResourcePublicLayout>
  );
};
