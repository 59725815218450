import React, { useEffect } from 'react';

export const Feedback = () => {
  useScript('https://resources.digital-cloud.medallia.eu/wdceu/157096/onsite/embed.js');

  return <></>;
};
const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
