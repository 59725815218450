import _ from 'lodash';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { ScrollToElement } from '../utils';
import { CustomDurgSpecificNavDropdownLinks } from './CustomDurgSpecificNavDropdownLinks';

export const CustomLinks = () => {
  const publicLink = useSelector(
    (state: AppRootState) => (((state.app.entities?.menus as AnyType) ?? {})['public.header'] as AnyType)?.data?.links
  );
  return (
    <Col lg md={6} className='d-flex flex-wrap justify-content-between justify-content-md-end'>
      <div className='mr-1 d-flex align-items-center'>
        {publicLink
          ?.filter((link?: AnyType) => !link?.type || 'link' === link?.type)
          .map((link?: AnyType) => (
            <a key={_.kebabCase(link.label)} href={link.url} target='_blank' rel='noreferrer'>
              {link.label}
            </a>
          ))}
        {publicLink
          ?.filter(
            (link?: AnyType) =>
              link?.type && 'drug-specific-dropdown' === link?.type && link?.featureKey && link?.enabled
          )
          .map((link?: AnyType) => (
            <CustomDurgSpecificNavDropdownLinks key={_.kebabCase(`${link.label}-dropdown`)} parentEle={link} />
          ))}
        {publicLink
          ?.filter(
            (link?: AnyType) => link?.type && 'scrollToElement' === link?.type && link?.featureKey && link?.enabled
          )
          .map((link?: AnyType) => (
            <ScrollToElement key={_.kebabCase(`${link.label}`)} parentEle={link} navItemProps='white' />
          ))}
      </div>
    </Col>
  );
};
