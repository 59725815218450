import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { PatientsTable, PrivateLayout } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { navigateToPatientsNew } from '../redux';

export const PatientsView = () => {
  const dispatch = useDispatch();
  const renderButton = () => {
    return (
      <FeatureGuard contentKey='patient-management.manage'>
        <Button
          variant='secondary'
          size='sm'
          onClick={() => dispatch(navigateToPatientsNew())}
          data-testid='cmx__add-patient-button'>
          ADD A PATIENT
        </Button>
      </FeatureGuard>
    );
  };
  return (
    <PrivateLayout pageTitle='patients' subtoolbarChildren={renderButton()}>
      <PatientsTable enableExternalFilters />
    </PrivateLayout>
  );
};
