import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../..';
import {
  DBFormSchema,
  fieldPlainTextSection,
  headerSection,
  separatorSection,
  spacerSection,
  titleSection,
  fieldTextLabel,
  fieldPlainTextFieldDiagnosisListItems
} from '..';

const { copayFields, insuranceFields, patientFields, diagnosisFields } = FIELD_NAMES;

export const getPatientProfileDetailsSchema = (schema: DBFormSchema): Schema => ({
  title: 'patient profile details form',
  fields: [
    headerSection(schema?.personal_information_header?.label, schema?.personal_information_header),
    fieldPlainTextSection(patientFields.firstName, schema?.[patientFields.firstName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.middleName, schema?.[patientFields.middleName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.lastName, schema?.[patientFields.lastName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.id, schema?.[patientFields.id], {
      sizes: { md: 3 }
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldPlainTextSection(patientFields.dob, schema?.[patientFields.dob], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.gender, schema?.[patientFields.gender], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.genderOther, schema?.[patientFields.genderOther], {
      sizes: { md: 3 },
      condition: {
        when: patientFields.gender,
        is: 'O'
      }
    }),
    fieldPlainTextSection(patientFields.preferredLanguage, schema?.[patientFields.preferredLanguage], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.externalId, schema?.[patientFields.externalId], {
      sizes: { md: 3 }
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    headerSection(schema?.contact_information_header?.label, schema?.contact_information_header),
    fieldPlainTextSection(patientFields.email, schema?.[patientFields.email], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.homeNumber, schema?.[patientFields.homeNumber], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.mobileNumber, schema?.[patientFields.mobileNumber], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.messageOption, schema?.[patientFields.messageOption], {
      sizes: { md: 3 }
    }),
    spacerSection(3, { sizes: { sm: 12 } }),
    fieldPlainTextSection(patientFields.address1, schema?.[patientFields.address1], {
      sizes: { md: 6 }
    }),
    fieldPlainTextSection(patientFields.city, schema?.[patientFields.city], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(patientFields.state, schema?.[patientFields.state], {
      sizes: { md: 1 }
    }),
    fieldPlainTextSection(patientFields.zip, schema?.[patientFields.zip], {
      sizes: { md: 2 }
    }),
    spacerSection(4, { sizes: { sm: 12 } }),
    headerSection(schema?.caregiver_information_header?.label, schema?.caregiver_information_header),
    fieldPlainTextSection(patientFields.careGiverFirstName, schema?.[patientFields.careGiverFirstName], {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection(patientFields.caregiverMiddlName, schema?.[patientFields.caregiverMiddlName], {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection(patientFields.caregiverLastName, schema?.[patientFields.caregiverLastName], {
      sizes: { md: 4 }
    }),
    spacerSection(5, { sizes: { sm: 12 } }),
    fieldPlainTextSection(patientFields.caregiverEmail, schema?.[patientFields.caregiverEmail], {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection(patientFields.caregiverHomePhone, schema?.[patientFields.caregiverHomePhone], {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection(patientFields.caregiverMobilePhone, schema?.[patientFields.caregiverMobilePhone], {
      sizes: { md: 4 }
    }),
    spacerSection(6, { sizes: { sm: 12 } }),
    fieldPlainTextSection(
      patientFields.caregiverPatientRelationship,
      schema?.[patientFields.caregiverPatientRelationship],
      {
        sizes: { md: 12 }
      }
    ),
    spacerSection(7, { sizes: { sm: 12 } }),
    fieldPlainTextSection(patientFields.caregiverLegallyAuthRep, schema?.[patientFields.caregiverLegallyAuthRep], {
      sizes: { md: 12 },
      condition: {
        when: patientFields.caregiverLegallyAuthRep,
        is: (value: boolean) => value === true
      }
    }),
    spacerSection(22, { sizes: { sm: 12 } }),
    headerSection(schema?.diagnosis_information_header?.label, schema?.diagnosis_information_header),
    fieldPlainTextFieldDiagnosisListItems(diagnosisFields.diagnosisCode, schema?.[diagnosisFields.diagnosisCode], {
      sizes: { md: 12 }
    }),
    spacerSection(8, { sizes: { sm: 12 } }),
    separatorSection(1),
    headerSection(schema?.insurance_information_header?.label, schema?.insurance_information_header),
    headerSection(schema?.medical_information_header?.label, schema?.medical_information_header, {
      subsection: true
    }),
    titleSection(schema?.title_section_primary?.label, schema?.title_section_primary, {
      name: 'title_section_primary'
    }),
    fieldPlainTextSection(insuranceFields.primaryPlanPayerName, schema?.[insuranceFields.primaryPlanPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.primaryPlanName, schema?.[insuranceFields.primaryPlanName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(insuranceFields.primaryPlanCardholderId, schema?.[insuranceFields.primaryPlanCardholderId], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.primaryPlanGroupNumber, schema?.[insuranceFields.primaryPlanGroupNumber], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.primaryPlanPhoneNumber, schema?.[insuranceFields.primaryPlanPhoneNumber], {
      sizes: { md: 2 }
    }),
    spacerSection(9, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_secondary?.label, schema?.title_section_secondary, {
      name: 'title_section_secondary'
    }),
    fieldPlainTextSection(insuranceFields.secondaryPlanPayerName, schema?.[insuranceFields.secondaryPlanPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.secondaryPlanName, schema?.[insuranceFields.secondaryPlanName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(
      insuranceFields.secondaryPlanCardholderId,
      schema?.[insuranceFields.secondaryPlanCardholderId],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.secondaryPlanGroupNumber,
      schema?.[insuranceFields.secondaryPlanGroupNumber],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.secondaryPlanPhoneNumber,
      schema?.[insuranceFields.secondaryPlanPhoneNumber],
      {
        sizes: { md: 2 }
      }
    ),
    spacerSection(10, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_tertiary?.label, schema?.title_section_tertiary, {
      name: 'title_section_tertiary'
    }),
    fieldPlainTextSection(insuranceFields.tertiaryPlanPayerName, schema?.[insuranceFields.tertiaryPlanPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.tertiaryPlanName, schema?.[insuranceFields.tertiaryPlanName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(
      insuranceFields.tertiaryPlanCardholderId,
      schema?.[insuranceFields.tertiaryPlanCardholderId],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(insuranceFields.tertiaryPlanGroupNumber, schema?.[insuranceFields.tertiaryPlanGroupNumber], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.tertiaryPlanPhoneNumber, schema?.[insuranceFields.tertiaryPlanPhoneNumber], {
      sizes: { md: 2 }
    }),
    spacerSection(11, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_other?.label, schema?.title_section_other, {
      name: 'title_section_other'
    }),
    fieldPlainTextSection(insuranceFields.otherPlanPayerName, schema?.[insuranceFields.otherPlanPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.otherPlanName, schema?.[insuranceFields.otherPlanName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(insuranceFields.otherPlanCardholderId, schema?.[insuranceFields.otherPlanCardholderId], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.otherPlanGroupNumber, schema?.[insuranceFields.otherPlanGroupNumber], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.otherPlanPhoneNumber, schema?.[insuranceFields.otherPlanPhoneNumber], {
      sizes: { md: 2 }
    }),
    spacerSection(12, { sizes: { sm: 12 } }),
    headerSection(schema?.pharmacy_insurance_header?.label, schema?.pharmacy_insurance_header, {
      subsection: true
    }),
    titleSection(schema?.title_section_primary_pharmacy?.label, schema?.title_section_primary_pharmacy, {
      name: 'title_section_primary_pharmacy'
    }),
    fieldPlainTextSection(insuranceFields.pharmacyPayerName, schema?.[insuranceFields.pharmacyPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.pharmacyName, schema?.[insuranceFields.pharmacyName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(insuranceFields.pharmacyCardholderId, schema?.[insuranceFields.pharmacyCardholderId], {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection(insuranceFields.pharmacyPhoneNumber, schema?.[insuranceFields.pharmacyPhoneNumber], {
      sizes: { md: 2 }
    }),
    spacerSection(13, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_primary_pharmacy_next?.label, schema?.title_section_primary_pharmacy_next, {
      name: 'title_section_primary_pharmacy_next'
    }),
    fieldPlainTextSection(insuranceFields.pharmacyRxGroupNumber, schema?.[insuranceFields.pharmacyRxGroupNumber], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.pharmacyRxBinIin, schema?.[insuranceFields.pharmacyRxBinIin], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(insuranceFields.pharmacyRxPcn, schema?.[insuranceFields.pharmacyRxPcn], {
      sizes: { md: 2 }
    }),
    spacerSection(14, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_secondary_pharmacy?.label, schema?.title_section_secondary_pharmacy, {
      name: 'title_section_secondary_pharmacy'
    }),
    fieldPlainTextSection(
      insuranceFields.secondaryPharmacyPayerName,
      schema?.[insuranceFields.secondaryPharmacyPayerName],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(insuranceFields.secondaryPharmacyName, schema?.[insuranceFields.secondaryPharmacyName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(
      insuranceFields.secondaryPharmacyCardholderId,
      schema?.[insuranceFields.secondaryPharmacyCardholderId],
      {
        sizes: { md: 4 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.secondaryPharmacyPhoneNumber,
      schema?.[insuranceFields.secondaryPharmacyPhoneNumber],
      {
        sizes: { md: 2 }
      }
    ),
    spacerSection(15, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_secondary_pharmacy_next?.label, schema?.title_section_secondary_pharmacy_next, {
      name: 'title_section_secondary_pharmacy_next'
    }),
    fieldPlainTextSection(
      insuranceFields.secondaryPharmacyRxGroupNumber,
      schema?.[insuranceFields.secondaryPharmacyRxGroupNumber],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.secondaryPharmacyRxBinIin,
      schema?.[insuranceFields.secondaryPharmacyRxBinIin],
      {
        sizes: { md: 3 }
      }
    ),
    fieldPlainTextSection(insuranceFields.secondaryPharmacyRxPcn, schema?.[insuranceFields.secondaryPharmacyRxPcn], {
      sizes: { md: 2 }
    }),
    spacerSection(16, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_tertiary_pharmacy?.label, schema?.title_section_tertiary_pharmacy, {
      name: 'title_section_tertiary_pharmacy'
    }),
    fieldPlainTextSection(
      insuranceFields.tertiaryPharmacyPayerName,
      schema?.[insuranceFields.tertiaryPharmacyPayerName],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(insuranceFields.tertiaryPharmacyName, schema?.[insuranceFields.tertiaryPharmacyName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(
      insuranceFields.tertiaryPharmacyCardholderId,
      schema?.[insuranceFields.tertiaryPharmacyCardholderId],
      {
        sizes: { md: 4 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.tertiaryPharmacyPhoneNumber,
      schema?.[insuranceFields.tertiaryPharmacyPhoneNumber],
      {
        sizes: { md: 2 }
      }
    ),
    spacerSection(17, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_tertiary_pharmacy_next?.label, schema?.title_section_tertiary_pharmacy_next, {
      name: 'title_section_tertiary_pharmacy_next'
    }),
    fieldPlainTextSection(
      insuranceFields.tertiaryPharmacyRxGroupNumber,
      schema?.[insuranceFields.tertiaryPharmacyRxGroupNumber],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.tertiaryPharmacyRxBinIin,
      schema?.[insuranceFields.tertiaryPharmacyRxBinIin],
      {
        sizes: { md: 3 }
      }
    ),
    fieldPlainTextSection(insuranceFields.tertiaryPharmacyRxPcn, schema?.[insuranceFields.tertiaryPharmacyRxPcn], {
      sizes: { md: 2 }
    }),
    spacerSection(18, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_other_pharmacy?.label, schema?.title_section_other_pharmacy, {
      name: 'title_section_other_pharmacy'
    }),
    fieldPlainTextSection(insuranceFields.otherPharmacyPayerName, schema?.[insuranceFields.otherPharmacyPayerName], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(insuranceFields.otherPharmacyName, schema?.[insuranceFields.otherPharmacyName], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(
      insuranceFields.otherPharmacyCardholderId,
      schema?.[insuranceFields.otherPharmacyCardholderId],
      {
        sizes: { md: 4 }
      }
    ),
    fieldPlainTextSection(
      insuranceFields.otherPharmacyPhoneNumber,
      schema?.[insuranceFields.otherPharmacyPhoneNumber],
      {
        sizes: { md: 2 }
      }
    ),
    spacerSection(19, { sizes: { sm: 12 } }),
    titleSection(schema?.title_section_other_pharmacy_next?.label, schema?.title_section_other_pharmacy_next, {
      name: 'title_section_other_pharmacy_next'
    }),
    fieldPlainTextSection(
      insuranceFields.otherPharmacyRxGroupNumber,
      schema?.[insuranceFields.otherPharmacyRxGroupNumber],
      {
        sizes: { md: 2 }
      }
    ),
    fieldPlainTextSection(insuranceFields.otherPharmacyRxBinIin, schema?.[insuranceFields.otherPharmacyRxBinIin], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(insuranceFields.otherPharmacyRxPcn, schema?.[insuranceFields.otherPharmacyRxPcn], {
      sizes: { md: 2 }
    }),
    spacerSection(20, { sizes: { sm: 12 } }),
    separatorSection(2),
    headerSection(schema?.copay_header?.label, schema?.copay_header),
    fieldPlainTextSection(copayFields.memberId, schema?.[copayFields.memberId], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(copayFields.groupNumber, schema?.[copayFields.groupNumber], {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection(copayFields.rxBin, schema?.[copayFields.rxBin], {
      sizes: { md: 3 }
    }),
    fieldPlainTextSection(copayFields.rxPcn, schema?.[copayFields.rxPcn], {
      sizes: { md: 2 }
    }),
    spacerSection(21, { sizes: { sm: 12 } })
  ].filter(Boolean)
});

// -----===[test schema]===-----
export const patientProfileDetailsSchema: DBFormSchema = {
  personal_information_header: { label: 'personal information' },
  patient_first_name: { label: 'first name' },
  patient_middle_name: { label: 'middle name' },
  patient_last_name: { label: 'last name' },
  patient_id: { label: 'patient id' },
  patient_dob: { label: 'date of birth (age)' },
  patient_gender: { label: 'gender' },
  patient_preferred_language: { label: 'preferred language' },
  contact_information_header: { label: 'contact and address information' },
  caregiver_information_header: { label: 'caregiver information' },
  diagnosis_information_header: { label: 'diagnosis information' },
  patient_email: { label: 'email address' },
  patient_home_number: { label: 'home phone number' },
  patient_mobile_number: { label: 'mobile phone number' },
  patient_message_option: { label: 'okay to leave message on' },
  patient_address_1: { label: 'address' },
  patient_city: { label: 'city' },
  patient_state: { label: 'state' },
  patient_zip: { label: 'zip code' },
  insurance_information_header: { label: 'insurance information' },
  medical_information_header: { label: 'medical information' },
  title_section_primary: { label: 'primary' },
  primary_plan_payer_name: { label: 'payer name' },
  primary_plan_name: { label: 'plan name' },
  primary_plan_cardholder_id: { label: 'cardholder id' },
  primary_plan_group_number: { label: 'group #' },
  primary_plan_phone_number: { label: 'phone number' },
  title_section_secondary: { label: 'secondary' },
  secondary_plan_payer_name: { label: 'payer name' },
  secondary_plan_name: { label: 'plan name' },
  secondary_plan_cardholder_id: { label: 'cardholder id' },
  secondary_plan_group_number: { label: 'group #' },
  secondary_plan_phone_number: { label: 'phone number' },
  title_section_tertiary: { label: 'tertiary' },
  tertiary_plan_payer_name: { label: 'payer name' },
  tertiary_plan_name: { label: 'plan name' },
  tertiary_plan_cardholder_id: { label: 'cardholder id' },
  tertiary_plan_group_number: { label: 'group #' },
  tertiary_plan_phone_number: { label: 'phone number' },
  title_section_other: { label: 'other' },
  other_plan_payer_name: { label: 'payer name' },
  other_plan_name: { label: 'plan name' },
  other_plan_cardholder_id: { label: 'cardholder id' },
  other_plan_group_number: { label: 'group #' },
  other_plan_phone_number: { label: 'phone number' },
  pharmacy_insurance_header: { label: 'Pharmacy or Prescription Insurance' },
  title_section_primary_pharmacy: { label: 'primary' },
  pharmacy_payer_name: { label: 'payer name' },
  pharmacy_plan_name: { label: 'plan name' },
  pharmacy_cardholder_id: { label: 'cardholder id' },
  pharmacy_phone_number: { label: 'phone number' },
  title_section_primary_pharmacy_next: { label: '' },
  pharmacy_rx_group_number: { label: 'rx group #' },
  pharmacy_rx_bin_iin: { label: 'bin' },
  pharmacy_rx_pcn: { label: 'pcn' },
  title_section_secondary_pharmacy: { label: 'secondary' },
  secondary_pharmacy_payer_name: { label: 'payer name' },
  secondary_pharmacy_plan_name: { label: 'plan name' },
  secondary_pharmacy_cardholder_id: { label: 'cardholder id' },
  secondary_pharmacy_phone_number: { label: 'phone number' },
  title_section_secondary_pharmacy_next: { label: '' },
  secondary_pharmacy_rx_group_number: { label: 'rx group #' },
  secondary_pharmacy_rx_bin_iin: { label: 'bin' },
  secondary_pharmacy_rx_pcn: { label: 'pcn' },
  title_section_tertiary_pharmacy: { label: 'tertiary' },
  tertiary_pharmacy_payer_name: { label: 'payer name' },
  tertiary_pharmacy_plan_name: { label: 'plan name' },
  tertiary_pharmacy_cardholder_id: { label: 'cardholder id' },
  tertiary_pharmacy_phone_number: { label: 'phone number' },
  title_section_tertiary_pharmacy_next: { label: '' },
  tertiary_pharmacy_rx_group_number: { label: 'rx group #' },
  tertiary_pharmacy_rx_bin_iin: { label: 'bin' },
  tertiary_pharmacy_rx_pcn: { label: 'pcn' },
  title_section_other_pharmacy: { label: 'other' },
  other_pharmacy_payer_name: { label: 'payer name' },
  other_pharmacy_plan_name: { label: 'plan name' },
  other_pharmacy_cardholder_id: { label: 'cardholder id' },
  other_pharmacy_phone_number: { label: 'phone number' },
  title_section_other_pharmacy_next: { label: '' },
  other_pharmacy_rx_group_number: { label: 'rx group #' },
  other_pharmacy_rx_bin_iin: { label: 'bin' },
  other_pharmacy_rx_pcn: { label: 'pcn' },
  copay_header: { label: 'copay' },
  copay_member_id: { label: 'member id' },
  copay_group_number: { label: 'group' },
  copay_rx_bin: { label: 'bin' },
  copay_rx_pcn: { label: 'pcn' }
};
