import { AnyObject } from 'final-form';
import { AnyAction } from 'redux';
import { TransactionActionsEnum } from '../actions/ebvTransaction.actions';

const initialState: AnyObject = {
  ebvTransaction: {}
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TransactionActionsEnum.CACHE_EBV_TRANSACTION:
      return { ...state, ebvTransaction: action.payload };
    default:
      return state;
  }
};
