import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

import { FIELD_NAMES, languages, StepNames } from '../..';
import {
  DBFormSchema,
  fieldCheckboxSection,
  fieldPlainTextSection,
  fieldSelectRelationshipsSection,
  fieldSelectSection,
  fieldSelectStatesSection,
  fieldTextSection,
  headerSection,
  separatorSection,
  spacerSection
} from '../field-helpers';

const { caregiverFields, patientFields } = FIELD_NAMES;

export const patientTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const patientTab = schema?.patient_tab;

  if (!patientTab || patientTab?.hideTab) return null;

  return {
    title: patientTab?.label ?? 'Patient',
    name: StepNames.PATIENT,
    nextStep: patientTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.personal_information_header?.label, schema?.personal_information_header),
      fieldPlainTextSection(patientFields.firstName, schema?.[patientFields.firstName], {
        sizes: { md: 4 }
      }),
      fieldPlainTextSection(patientFields.middleName, schema?.[patientFields.middleName], {
        sizes: { md: 4 }
      }),
      fieldPlainTextSection(patientFields.lastName, schema?.[patientFields.lastName], {
        sizes: { md: 4 }
      }),
      spacerSection('patient_1', { sizes: { sm: 12 } }),
      fieldPlainTextSection(patientFields.dob, schema?.[patientFields.dob], {
        sizes: { md: 4 }
      }),
      fieldPlainTextSection(patientFields.gender, schema?.[patientFields.gender], {
        sizes: { md: 4 }
      }),
      fieldPlainTextSection(patientFields.genderOther, schema?.[patientFields.genderOther], {
        sizes: { md: 4 },
        condition: {
          when: patientFields.gender,
          is: 'O'
        }
      }),
      fieldPlainTextSection(patientFields.zip, schema?.[patientFields.zip], {
        sizes: { md: 2 },
        type: 'zip',
        validate: [
          {
            type: 'zip'
          }
        ]
      }),
      fieldTextSection(patientFields.last4SSN, schema?.[patientFields.last4SSN], {
        type: 'number',
        sizes: { md: 3 }
      }),
      spacerSection('patient_2', { sizes: { sm: 12 } }),
      separatorSection('patient_1', schema.separator_patient_1),
      fieldSelectSection(patientFields.preferredLanguage, schema?.[patientFields.preferredLanguage], {
        sizes: { md: 4 },
        options: [...languages]
      }),
      fieldTextSection(patientFields.otherLanguage, schema?.[patientFields.otherLanguage], {
        sizes: { md: 4 },
        condition: {
          when: patientFields.preferredLanguage,
          is: 'Other'
        }
      }),
      spacerSection('patient_3', { sizes: { sm: 12 } }),
      headerSection(schema?.contact_information_header?.label, schema?.contact_information_header),
      fieldTextSection(patientFields.email, schema?.[patientFields.email], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'email'
          }
        ]
      }),
      fieldTextSection(patientFields.email, schema?.[patientFields.editableEmail], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'email'
          }
        ]
      }),
      fieldTextSection(patientFields.homeNumber, schema?.[patientFields.homeNumber], {
        sizes: { md: 2 },
        type: 'tel',
        validate: [
          {
            type: 'phone-conditional',
            compareToField: patientFields.mobileNumber
          }
        ]
      }),
      fieldTextSection(patientFields.mobileNumber, schema?.[patientFields.mobileNumber], {
        sizes: { md: 2 },
        type: 'tel',
        validate: [
          {
            type: 'phone-conditional',
            compareToField: patientFields.homeNumber
          }
        ]
      }),
      fieldSelectSection(patientFields.preferredPhone, schema?.[patientFields.preferredPhone], {
        sizes: { md: 4 }
      }),
      fieldCheckboxSection(patientFields.messageOption, schema?.[patientFields.messageOption], {
        sizes: { md: 4 },
        options: [
          {
            value: 'home',
            label: 'Home'
          },
          {
            value: 'mobile',
            label: 'Mobile'
          }
        ]
      }),
      spacerSection('patient_4', { sizes: { sm: 12 } }),
      fieldTextSection(patientFields.address1, schema?.[patientFields.address1], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'address'
          }
        ]
      }),
      fieldTextSection(patientFields.address2, schema?.[patientFields.address2], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'address'
          }
        ]
      }),
      spacerSection('patient_5', { sizes: { sm: 12 } }),
      // fieldPlainTextSection(patientFields.zip, schema?.[patientFields.zip], {
      //   sizes: { md: 2 },
      //   type: 'zip',
      //   validate: [
      //     {
      //       type: 'zip'
      //     }
      //   ]
      // }),
      fieldTextSection(patientFields.city, schema?.[patientFields.city], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'city'
          }
        ]
      }),
      fieldSelectStatesSection(patientFields.state, schema?.[patientFields.state], {
        sizes: { md: 2 }
      }),
      spacerSection('patient_6', { sizes: { sm: 12 } }),

      // Caregiver Information
      headerSection(schema?.caregiver_information_header?.label, schema?.caregiver_information_header),
      fieldCheckboxSection(patientFields.hasCaregiver, schema?.[patientFields.hasCaregiver], {
        sizes: { md: 4 },
        options: [
          {
            value: 'yes',
            label: 'My patient has a caregiver'
          }
        ]
      }),
      spacerSection('patient_7', { sizes: { sm: 12 }, ...hasCaregiverCondition }),
      fieldTextSection(caregiverFields.firstName, schema?.[caregiverFields.firstName], {
        sizes: { md: 4 },
        ...hasCaregiverCondition
      }),
      fieldTextSection(caregiverFields.middleName, schema?.[caregiverFields.middleName], {
        sizes: { md: 4 },
        ...hasCaregiverCondition
      }),
      fieldTextSection(caregiverFields.lastName, schema?.[caregiverFields.lastName], {
        sizes: { md: 4 },
        ...hasCaregiverCondition
      }),
      fieldTextSection(caregiverFields.email, schema?.[caregiverFields.email], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'email'
          }
        ],
        ...hasCaregiverCondition
      }),
      fieldTextSection(caregiverFields.homePhone, schema?.[caregiverFields.homePhone], {
        sizes: { md: 2 },
        type: 'tel',
        ...hasCaregiverCondition
      }),
      fieldTextSection(caregiverFields.mobilePhone, schema?.[caregiverFields.mobilePhone], {
        sizes: { md: 2 },
        type: 'tel',
        ...hasCaregiverCondition
      }),
      fieldSelectRelationshipsSection(
        caregiverFields.patientRelationship,
        schema?.[caregiverFields.patientRelationship],
        {
          sizes: { md: 3 },
          ...hasCaregiverCondition
        }
      ),
      spacerSection('patient_10', { sizes: { sm: 12 }, ...hasCaregiverCondition }),
      fieldCheckboxSection(
        caregiverFields.isAuthorizedRepForPatient,
        schema?.[caregiverFields.isAuthorizedRepForPatient],
        {
          sizes: { md: 12 },
          options: [
            {
              value: 'yes',
              label: 'Is Legally Authorized Representative?'
            }
          ],
          ...hasCaregiverCondition
        }
      ),
      spacerSection('patient_11', { sizes: { sm: 12 }, ...hasCaregiverCondition })
    ].filter(Boolean)
  };
};

export const hasCaregiverCondition = {
  condition: {
    when: patientFields.hasCaregiver,
    is: (value: string[]) => value && value?.length && !!value?.[0]
  }
};
