import React, { useState } from 'react';
import { Button, Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import { CheckCircle, Download, Trash } from 'react-bootstrap-icons';
import './RxAdmin.scss';
import { FileUploadModel } from './RxAdminDragDropModal';
import { API_URL } from '../api';
import { useSelector } from 'react-redux';
import { AppRootState, AuthState } from '../redux';
import { getAuthorizationHeader } from '../utils';

export const RxAdmin: React.FC = () => {
  const [fileDownloaded, setFileDownloaded] = useState<boolean>(false);
  const [showDownloadedAlert, setShowDownloadedAlert] = useState<boolean>(false);
  const [showSubmitAlert, setShowSubmitAlert] = useState<boolean>(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState<boolean>(false);
  const [fileToSubmit, setFileToSubmit] = useState<File | null>(null);
  const [fileSubmitted, setFileSubmitted] = useState<boolean>(false);
  const [errorSubmit, setErrorSubmit] = useState<boolean>(false);
  const auth: AuthState = useSelector((state: AppRootState) => state.auth);
  const bearerToken = getAuthorizationHeader();

  //handler to download the template file
  const handleDownload = async () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/vnd.ms-excel');
    headers.append('Authorization', bearerToken ?? '');
    try {
      const response = await fetch(`${API_URL}/api/InjectionTracker/download`, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/"/g, '')
          : 'Template.xlsm';
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        setFileDownloaded(true);
        setShowDownloadedAlert(true);
      }
    } catch (error) {
      setFileDownloaded(false);
      setShowDownloadedAlert(false);
    }
  };

  // handler to show the drag and drop modal to upload the file
  const handleFileChange = () => {
    setShowFileUploadModal(true);
  };

  // handler to download the uploaded file through download icon
  const handleDownloadUploadedFile = () => {
    if (fileToSubmit) {
      const url = URL.createObjectURL(fileToSubmit);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileToSubmit.name;
      a.click();
      a.remove();
      URL.revokeObjectURL(url); // Clean up after download
    }
  };
  // handler for submitting the uploaded file
  const handleSubmit = async () => {
    const user = auth && auth.session && auth.session.user ? auth.session.user : '';
    if (fileToSubmit && user && user.Login && user.ProgramId) {
      const formData = new FormData();
      formData.append('file', fileToSubmit);
      formData.append('LoggedInUser', user.Login);
      formData.append('ProgramId', user.ProgramId);
      const headers = new Headers();
      headers.append('Authorization', bearerToken ?? '');
      try {
        const response = await fetch(`${API_URL}/api/InjectionDataUpload/upload`, {
          method: 'POST',
          body: formData,
          headers
        });
        if (response.ok) {
          setShowSubmitAlert(true);
          setFileSubmitted(true);
        } else {
          setErrorSubmit(true);
        }
      } catch (error) {
        setErrorSubmit(true);
      }
    } else if (fileToSubmit) {
      setErrorSubmit(true);
    }
  };
  return (
    <Container>
      <Row className='mt-3'>
        <Col>
          <h6>
            <span className='border-bottom-offset'>&nbsp;&nbsp;&nbsp;&nbsp;RX ADMIN&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </h6>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <p className='mb-0'>Step 1: Download the custom template created for your practice.</p>
          <p className='mb-0'>Step 2: Fill in the template with your patients&apos; historical data.</p>
          <p className='mb-0'>Step 3: Upload and submit your completed Excel template for processing.</p>
          <p className='mb-0'>Step 4: Access Patient Profile</p>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col md={6}>
          <h6 style={{ color: 'gray' }}>
            <b>Download Template File:</b>
          </h6>
          <Button variant='outline-primary' className='mb-3' onClick={handleDownload}>
            DOWNLOAD TEMPLATE
          </Button>
          {fileDownloaded && showDownloadedAlert && (
            <Row className='mb3 rxAdminAlert'>
              <Col>
                <Alert show={true} variant='success' onClose={() => setShowDownloadedAlert(false)} dismissible>
                  <Alert.Heading>
                    <CheckCircle data-testid='customAlertIcon' className='mr-2' />
                    <b>Downloaded</b>
                  </Alert.Heading>
                  <hr className='m-2' />
                  <p className='mb-0'>
                    Populate your template with the information you want to have loaded for your patients.
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
        </Col>
        <Col md={6}>
          <h6 style={{ color: 'gray' }}>
            <b>Upload Template File:</b>
          </h6>
          <Row className='align-items-center mb-3'>
            {!fileSubmitted && !errorSubmit && (
              <Col md={4}>
                <Button variant='outline-secondary' onClick={handleFileChange}>
                  SELECT FILE
                </Button>
              </Col>
            )}
            {fileToSubmit && !errorSubmit && (
              <Col md={5} className='border ml-3 p-3 pr-5 d-flex align-items-center'>
                <div className='flex-grow-1'>
                  <p className='mb-2'>{fileToSubmit.name}</p>
                  <div className='d-flex align-items-center'>
                    <small>File successfully uploaded</small>
                    <Download onClick={handleDownloadUploadedFile} className='ml-2 pointer' />
                    <Trash onClick={() => setFileToSubmit(null)} className='ml-2 pointer' />
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <FileUploadModel
            setFileToSubmit={setFileToSubmit}
            setShowFileUploadModal={setShowFileUploadModal}
            showFileUploadModal={showFileUploadModal}
          />
          {!errorSubmit && !showSubmitAlert && (
            <Col className='pl-0'>
              <Button variant='primary' className='mb-3' onClick={handleSubmit}>
                SUBMIT
              </Button>
            </Col>
          )}
          {fileToSubmit && errorSubmit && (
            <Row className='justify-content-start rxAdmin-progress-bar ml-1'>
              <Col md={5} className='border p-3 pr-5 d-flex align-items-center'>
                <div className='flex-grow-1'>
                  <p className='mb-2'>{fileToSubmit.name}</p>
                  <ProgressBar now={100} variant='danger' />
                </div>
              </Col>
              <Alert variant='danger' className='mt-3 rxAdminAlert' dismissible onClose={() => setErrorSubmit(false)}>
                We&apos;ve run into an issue processing this request.
              </Alert>
            </Row>
          )}
          {showSubmitAlert && (
            <Row className='mb3 rxAdminAlert'>
              <Col>
                <Alert show={true} variant='success' onClose={() => setShowSubmitAlert(false)} dismissible>
                  <Alert.Heading>
                    <CheckCircle data-testid='customAlertIcon' className='mr-2' />
                    <b>Success</b>
                  </Alert.Heading>
                  <p className='mb-0'>Your file was successfully uploaded.</p>
                  <hr className='m-2' />
                  <p className='mb-0'>Your request has been received.</p>
                  <p className='mb-0'>Please allow for up to 3 business days for processing.</p>
                </Alert>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};
