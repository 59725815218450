import { OktaAuth } from '@okta/okta-auth-js';
import { History } from 'history';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRouting } from './AppRouting';
import { ClickToChat, FeatureGuard, Footer, LoadingIndicatorRow } from './components';
import { ISIFooter } from './components/ISIFooter';
import { CustomThemes } from './utils/custom-themes';
import { useOrganizationDetailsLazyQuery, usePhoneNumberLazyQuery, useUserInfoLazyQuery } from './graphql';
import { AppRootState, AuthState, cacheUserData } from './redux';
import { LuckyOrange } from './components/LuckyOrange';
import { AppInsights } from './components/AppInsights';
import { useSelectTenancyResources } from './hooks';

interface AppProps extends PropsWithChildren<unknown> {
  history: History;
}

const App = ({ history }: AppProps) => {
  const dispatch = useDispatch();
  const auth: AuthState = useSelector((state: AppRootState) => state.auth);
  const [execOrgDetailsQuery, { data: organizationData }] = useOrganizationDetailsLazyQuery({});
  const [execPhoneNumberQuery, { data: phoneData }] = usePhoneNumberLazyQuery({});
  const [execUserInfo, { data: userInfo }] = useUserInfoLazyQuery({});
  const clientColors = useSelectTenancyResources('style.client-color') || {};
  CustomThemes();
  useEffect(() => {
    if (auth.session?.user && !auth.session.orgDetails) {
      execOrgDetailsQuery({ variables: { organizationId: String(auth.session?.user?.OrganizationId) } });
    }
    if (auth.session?.user && !auth.session.userDetails) {
      execPhoneNumberQuery({
        variables: {
          recordId: String(auth.session?.user?.CareMetxRecordId),
          recordType: String(auth.session?.user?.CareMetxRecordType)
        }
      });
      execUserInfo({
        variables: {
          recordId: String(auth.session?.user?.CareMetxRecordId),
          recordType: String(auth.session?.user?.CareMetxRecordType)
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    if (organizationData?.result && phoneData?.result && userInfo?.result) {
      const payload = {
        userDetails: phoneData.result,
        orgDetails: organizationData.result,
        userInfo: userInfo.result
      };
      organizationData?.result && phoneData?.result && dispatch(cacheUserData(payload));
    }
  }, [dispatch, organizationData, phoneData, userInfo]);

  const lightenDarkenColor = (color: string, percent: number) => {
    const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  useEffect(() => {
    const root = document.documentElement;
    Object.entries(clientColors).forEach((entry) => {
      const [key, value] = entry;
      root.style.setProperty(`--${key}`, `${value}`);
      root.style.setProperty(`--${key}-dark`, lightenDarkenColor(`${value}`, -10));
      root.style.setProperty(`--${key}-light`, lightenDarkenColor(`${value}`, 10));
    });
  }, [clientColors]);

  if (!auth?.oktaConfig || !auth?.oktaAuth) return <LoadingIndicatorRow />;
  if (auth?.configError) return <>There was an issue getting config data.</>;

  return (
    <>
      <AppRouting oktaAuth={auth.oktaAuth as OktaAuth} config={auth.oktaConfig} history={history} />
      <ISIFooter />
      <Footer />
      <ClickToChat />
      <AppInsights />
      <FeatureGuard contentKey='luckyorange.button'>
        <LuckyOrange />
      </FeatureGuard>
    </>
  );
};

export default App;
