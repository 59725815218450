import React, { Component } from 'react';
import { useSelector } from 'react-redux';

import { PublicLayout, ResourceContent } from '../components';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { htmlProps, rawSafeHtmlProps } from '../utils';

export const TermsOfUseView = () => {
  const termsAndConditions = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['terms-use.body' ?? ''] as AnyType)?.data
  );
  const domainURL = String(useSelector((state: AppRootState) => state.app?.config?.domainURL ?? ''));

  const modifidedTermsAndConditions = termsAndConditions
    ? termsAndConditions
        .replace(`https://standardhcpportal.caremetx.com`, `https://${domainURL}`)
        .replace(`https://standardhcpportal.caremetx.com/privacy`, `https://${domainURL}/privacy`)
    : '';

  return (
    <PublicLayout className='view-container'>
      <p className='section-text'>
        <ResourceContent contentKey='terms-use.title' />
      </p>
      <p className='mb-0'>
        <span {...htmlProps(modifidedTermsAndConditions)} />
      </p>
    </PublicLayout>
  );
};
