import React from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

import { Search } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { AnyType } from '../interfaces';

interface LocationSearchProps {
  onSearch?: (value: string | undefined) => void;
}

export const LocationSearch = (props: LocationSearchProps) => {
  const onSearch = (e: AnyType) => {
    e.preventDefault();
    const value = e.target.value;
    props.onSearch && props.onSearch(value);
  };

  return (
    <InputGroup>
      <FormControl
        placeholder=''
        onChange={onSearch}
        aria-label='Patient'
        aria-describedby='basic-addon1'
        className='border-right-0'
      />
      <InputGroup.Prepend>
        <Button variant='transparent' className='border border-left-0 rounded-right px-3'>
          <Search className='text-gray' />
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ConnectedLocationSearch = connect(mapStateToProps, mapDispatchToProps)(LocationSearch);
