import React from 'react';

import { Button, Col, Form } from 'react-bootstrap';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';

import { AnyType } from '../../interfaces';

interface HcpFormTemplateProps extends FormTemplateRenderProps {
  noControls?: boolean;
  renderControls?: (props: AnyType) => JSX.Element;
}

export const HcpFormTemplate = ({ formFields, noControls, renderControls = defaultRender }: HcpFormTemplateProps) => {
  const formOptions = useFormApi();
  return (
    <Form onSubmit={formOptions.handleSubmit}>
      <Form.Row className='mb-4'>{formFields}</Form.Row>
      <>{noControls ? <></> : renderControls(formOptions)}</>
    </Form>
  );
};

const defaultRender = (formOptions: FormOptions) => {
  const { submitError, hasValidationErrors } = formOptions.getState();
  return (
    <Form.Row>
      <Form.Group as={Col} md='6'>
        {submitError && <span className='text-danger'>{submitError}</span>}
        <Button type='submit' variant='secondary' disabled={hasValidationErrors} className='text-uppercase'>
          submit
        </Button>
      </Form.Group>
      {formOptions.onCancel && (
        <Form.Group as={Col} md='6'>
          <Button
            onClick={formOptions.onCancel}
            type='reset'
            variant='transparent'
            className='text-danger text-uppercase float-right'>
            cancel
          </Button>
        </Form.Group>
      )}
    </Form.Row>
  );
};
