import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';

import React from 'react';
import { useSelector } from 'react-redux';
import { HcpGrid } from '.';

import {
  amountColumnDefinition,
  dateColumnDefinition,
  dateColumnDefinitionWithTime,
  featureGridColumnsSelector,
  getFeatureGridColumnProps
} from '../utils';

interface ClaimDetailsTableProps {
  claimDetails?: any;
}

const columnSelector = featureGridColumnsSelector('copay-claim-details.table');

export const CopayClaimDetailsTable = ({ claimDetails }: ClaimDetailsTableProps) => {
  const columns = useSelector(columnSelector);
  if (!columns) return null;

  return (
    <>
      <p className='text-uppercase section-text'>Claim Details</p>
      <HcpGrid rowData={claimDetails ?? []} pagination>
        <AgGridColumn
          {...dateColumnDefinition('dateOfService')}
          {...getFeatureGridColumnProps(columns.dateOfService)}
        />
        <AgGridColumn field='billingCode' {...getFeatureGridColumnProps(columns.billingCode)} />
        <AgGridColumn field='quantity' {...getFeatureGridColumnProps(columns.quantity)} />
        <AgGridColumn
          {...amountColumnDefinition('originalBilledAmount')}
          {...getFeatureGridColumnProps(columns.originalBilledAmount)}
        />
        <AgGridColumn
          {...amountColumnDefinition('primaryPlanPaidAmount')}
          {...getFeatureGridColumnProps(columns.primaryPaidAmount)}
        />
        <AgGridColumn
          {...amountColumnDefinition('patientCopayAmount')}
          {...getFeatureGridColumnProps(columns.copayAmount)}
        />
        <AgGridColumn
          {...amountColumnDefinition('patientDueAmount')}
          {...getFeatureGridColumnProps(columns.amountToPay)}
        />
        <AgGridColumn field='status' {...getFeatureGridColumnProps(columns.status)} />
        <AgGridColumn
          {...dateColumnDefinitionWithTime('statusUpdatedDate')}
          {...getFeatureGridColumnProps(columns.statusUpdatedDate)}
        />
        <AgGridColumn field='statusUpdatedBy' {...getFeatureGridColumnProps(columns.statusUpdatedBy)} />
        <AgGridColumn field='checkNumber' {...getFeatureGridColumnProps(columns.checkNumber)} />
        <AgGridColumn {...dateColumnDefinition('checkDate')} {...getFeatureGridColumnProps(columns.checkDate)} />
        <AgGridColumn {...amountColumnDefinition('checkAmount')} {...getFeatureGridColumnProps(columns.checkAmount)} />
      </HcpGrid>
    </>
  );
};
