import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { LocationsTable, PrescribersTable, PrivateLayout, UsersTable } from '../components';

const users = '#users';
const prescribers = '#prescribers';
const locations = '#locations';
const availableTabs = [users, prescribers, locations];

export const PracticeManagementView = () => {
  const { hash } = useLocation();
  const history = useHistory();

  const defaultActiveKey = availableTabs.includes(hash) ? hash : users;

  const onTabSelect = (eventKey: string | null) => {
    if (!eventKey) return;

    history.push(eventKey);
  };

  return (
    <PrivateLayout contentKey='practice.title'>
      <Tabs defaultActiveKey={defaultActiveKey} className='tabs-primary' mountOnEnter onSelect={onTabSelect}>
        <Tab eventKey={users} title='USERS' className='pt-3'>
          <UsersTable />
        </Tab>
        <Tab eventKey={prescribers} title='PRESCRIBERS' className='pt-3'>
          <PrescribersTable />
        </Tab>
        <Tab eventKey={locations} title='LOCATIONS' className='pt-3'>
          <LocationsTable />
        </Tab>
      </Tabs>
    </PrivateLayout>
  );
};
