import React, { useContext, useMemo } from 'react';
import './ConsentStatus.scss';
import { ConsentContext } from './context';
import { ConsentFlow, ConsentStatusEnum } from './types';
import { Printer } from 'react-bootstrap-icons';

export default function ConsentStatus() {
  const { consentStatus, flow } = useContext(ConsentContext);

  const hasPatientConsent = useMemo(() => flow === ConsentFlow.UPDATE || consentStatus === ConsentStatusEnum.SUCCESS, [
    flow,
    consentStatus
  ]);
  return (
    <div className='d-flex align-items-center'>
      {hasPatientConsent && <Printer className='mr-2' height={'20px'} width={'20px'} />}
      <span className={`cmx__consent_status__span-consent-on-file ${hasPatientConsent ? 'success' : ''}`}>
        {hasPatientConsent ? 'Patient consent on file' : 'Patient consent not on file'}
      </span>
    </div>
  );
}
