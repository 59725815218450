import { useSelector } from 'react-redux';
import { EnrollmentRequest, RX_APPLICATION_NAME } from '../../api';
import { PortalEnrollmentRequest } from '../../api/interface-portal';
import { AppRootState } from '../../redux';
import { FINISHED_EVENT, standardPortalConfig } from '../constants';

export const getPharmacyEpaPayload = (
  values: PortalEnrollmentRequest,
  userName: string | undefined,
  organizationId: string | undefined
) => {
  const patient = values?.patient;
  const physicians = values?.physicians;
  const drugInfo: any = values?.drugInfo;
  const phyzApplicationName = 'PhyzConnect';

  const patientPhoneNumber = (type: 'home' | 'mobile') =>
    patient?.patientPhoneNumbers?.find((phoneNumber) => phoneNumber.phoneType === type)?.phoneNumber;
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  return {
    ClientApplication: standardPortalConfig.isStandardPortalUrl
      ? standardPortalConfig.applicationName
      : portal_ApplicationName === 'PhyzProviderPortal'
      ? phyzApplicationName
      : portal_ApplicationName ?? RX_APPLICATION_NAME,
    UserName: String(userName),
    RequestData: JSON.stringify({
      PatientFirstName: patient?.firstName ?? null,
      PatientMiddleName: patient?.middleName ?? null,
      PatientLastName: patient?.lastName ?? null,
      PatientGender: (patient?.gender as 'M' | 'F' | 'U') ?? 'U',
      PatientDOB: patient?.dob ?? null,
      PatientSuffix: null,
      PatientPrefix: null,
      PatientPhone: patientPhoneNumber('home') || patientPhoneNumber('mobile'),
      PatientAddressLine1: patient?.address1 ?? null,
      PatientAddressLine2: patient?.address2 ?? null,
      PatientCity: patient?.city ?? null,
      PatientState: patient?.state ?? null,
      PatientZip: patient?.zip ?? null,
      PhysicianFirstName: physicians?.firstName ?? null,
      PhysicianMiddleName: physicians?.middleName ?? null,
      PhysicianLastName: physicians?.lastName ?? null,
      PhysicianNPI: physicians?.npi ?? null,
      PhysicianSuffix: null,
      PhysicianPrefix: null,
      PhysicianPhone: physicians?.organization?.organizationAddress?.phoneNumber?.replace(/[^+\d]+/g, ''),
      PhysicianFax: physicians?.organization?.organizationAddress?.faxNumber?.replace(/[^+\d]+/g, ''),
      PhysicianAddressLine1: physicians?.organization?.organizationAddress?.address1,
      PhysicianAddressLine2: physicians?.organization?.organizationAddress?.address2,
      PhysicianCity: physicians?.organization?.organizationAddress?.city,
      PhysicianState: physicians?.organization?.organizationAddress?.state,
      PhysicianZip: physicians?.organization?.organizationAddress?.zip,
      PhysicianPracticeName: physicians?.organization?.name,
      PhysicianPracticeNpi: physicians?.npi,
      DrugDescription: drugInfo?.ndc?.split('-')?.[1]?.trim(),
      DrugNDC: drugInfo?.ndc?.split('-')?.[0]?.trim(),
      DrugQuantity: drugInfo?.quantity,
      DrugDaysSupply: drugInfo?.daysSupply,
      ExternalAccountID: organizationId ?? null,
      ExternalPatientID: String(patient?.patientId),
      CustomRedirectUrl1: FINISHED_EVENT
      // CareMetxPayerID: null
    })
  };
};
