import { eiGetMessageListener, EI_IFRAME_ID } from '@caremetx/enrollment-integration';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { AnyObject } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AnyType } from '../../interfaces';
import Highlight from './components/Highlight';
import { getEnrollmentModuleEndpoint, INIT_REUQST_URL_PATH, TOKEN_URL_PATH } from './data';

import { API_URL, ENROLLMENT_MODULE_TRIGGER_RULE, useEnrollment } from '../../api';
import { Drug, EnrollmentEvents, getAuthorizationHeader, ROUTE_PATHS } from '../../utils';
import { AppRootState, navigateToHome, useTenancyDrugListSelector } from '../../redux';
import { callModuleEnrollmentApi, prepareEnollmentPayload } from '../../views';

/* ENROLLMENT MODULE AUTHENTICATOR */
export const getToken = async (triggerRule: string) => {
  const bearerToken = getAuthorizationHeader();

  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', bearerToken ?? '');

  return fetch(`${API_URL}/api/Auth/Token?TriggerRule=` + triggerRule, {
    method: 'GET',
    headers
  });
};

/* ENROLLMENT MODULE REQUEST INITIATOR */
const initRequest = async (accessToken: string, data: any) => {
  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);

  const body = JSON.stringify(data);

  const requestOptions = {
    method: 'POST',
    headers,
    body
  };

  return fetch(getEnrollmentModuleEndpoint(INIT_REUQST_URL_PATH), requestOptions);
};

/* ENROLLMENT MODULE MAIN COMPONENT */
export type EnrollmentModuleProps = {
  payload: AnyType;
};

const EnrollmentModule = (props: EnrollmentModuleProps) => {
  const [accessToken, setAccessToken] = useState<string | null>();
  const [apiError, setAPIError] = useState<string | null>();
  const [accessError, setAccessError] = useState<string | null>();
  const [enrollmentJson, setEnrollmentJson] = useState({});
  const [enrollmentProcessed, setEnrollmentProcessed] = useState(false);
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [url, setUrl] = useState<any>();
  const [isClientConnected, setClientConnected] = useState<boolean>(false);
  const history = useHistory<AnyType>();
  const dispatch = useDispatch();
  const sessionDrug = sessionStorage.getItem('drugList');
  const tenancyDrugList = useTenancyDrugListSelector();

  const { mutate: enroll } = useEnrollment({});
  let finishURL: string;
  const isSkipEnrollment: AnyType = useSelector(
    (state: AppRootState) => state?.app?.entities?.features?.SkipEnrollmentSubmissionPage?.data
  );
  const isUsingEnrollmentModule = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );
  const loadAccessToken = useCallback(async () => {
    setAPIError(null);
    setAccessError(null);
    setUrl(null);
    setClientConnected(false);

    const token = await getToken(ENROLLMENT_MODULE_TRIGGER_RULE);
    const result = await token.text();

    try {
      const json = JSON.parse(result);
      setAccessToken(json.authToken);
      sessionStorage.setItem('authToken', json.authToken);
    } catch (e) {
      setAccessError(result);
    }
  }, []);

  useEffect(() => {
    if (enrollmentProcessed && finishButtonClicked) {
      history.push(ROUTE_PATHS.requestsSubmitted, { enrollmentJson: enrollmentJson });
    }
  }, [enrollmentProcessed, finishButtonClicked]);

  const loadData = useCallback(async () => {
    if (!accessToken) return;

    const response = await initRequest(accessToken, props?.payload);
    const result = await response.text();

    try {
      const json = JSON.parse(result);
      if (json.errors?.length > 0) setAPIError(JSON.stringify(json.errors));
      const enrollmentId = json?.URL?.split('enrollmentId=')?.[1]?.split('&externalPracticeId')?.[0];
      finishURL = decodeURIComponent(json?.URL?.split('finishURL=')?.[1]);
      sessionStorage.setItem('enrollmentModuleEnrollmentId', enrollmentId);
      sessionStorage.setItem('enrollmentModulePracticeId', props?.payload?.practice?.externalId);
      setUrl(json.URL);
    } catch (e) {
      setAccessError(result);
    }
  }, [accessToken]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadAccessToken();
  }, [loadAccessToken]);

  const drugList: Drug[] = sessionDrug ? JSON.parse(sessionDrug) : tenancyDrugList;

  const createEnrollment = async (payload: AnyObject) => {
    try {
      const result = await enroll(payload);

      if (!result?.errors) {
        return;
      }

      if (result.errors.length === 0) {
        // clear storage on success

        sessionStorage.setItem('enrollmentModuleEnrollmentId', '');
        sessionStorage.setItem('enrollmentModulePracticeId', '');
        sessionStorage.setItem('caseId', '');
        sessionStorage.setItem('drugList', '');
        sessionStorage.setItem('locationId', '');
        sessionStorage.setItem('patientIdEnrollment', '');
        sessionStorage.setItem('caseIdEnrollment', '');

        setEnrollmentProcessed(true);
      } else {
        setAPIError(JSON.stringify(result.errors));
      }
    } catch (e) {
      throw e?.message;
    }
  };

  useEffect(() => {
    setEnrollmentProcessed(false);
    setFinishButtonClicked(false);

    if (!accessToken) return;

    setEnrollmentJson({});

    const onInitialized = () => {
      setClientConnected(true);
    };

    const getDrugId = (jsonDrug: any) => {
      if (!jsonDrug) return null;
      const selectedDrug = drugList.find((element: Drug) => element?.JCode1 === jsonDrug?.jCode ?? 0);
      return selectedDrug
        ? selectedDrug?.DrugId
        : drugList.find((element: Drug) => element?.DrugNDC === jsonDrug?.ndc ?? 0)?.DrugId;
    };
    const fetchDataFromEnrollmentCreateSr = async () => {
      const response = await callModuleEnrollmentApi(
        accessToken,
        sessionStorage.getItem('enrollmentModuleEnrollmentId'),
        sessionStorage.getItem('enrollmentModulePracticeId')
      );
      const result = await response.text();
      try {
        const json = JSON.parse(result);
        setEnrollmentJson(json);
        const drugId = getDrugId(json?.drug);

        createEnrollment(
          prepareEnollmentPayload(json, 0, null, Number(drugId) ?? 0, json?.enrollmentDocument, isUsingEnrollmentModule)
        );
      } catch (e) {
        throw e?.message;
      }
    };

    const listener = eiGetMessageListener({ accessToken, onInitialized, enableLogging: true });

    window.addEventListener('message', listener);
    const onSubmit = (event: AnyType) => {
      if (event?.data?.eventName === EnrollmentEvents.OnSubmitSuccess && finishURL && isSkipEnrollment?.enabled) {
        fetchDataFromEnrollmentCreateSr();
      }
      if (event?.data?.eventName === EnrollmentEvents.OnFinish && isSkipEnrollment?.enabled) {
        setFinishButtonClicked(true);
      }
      if (event.data.eventName === EnrollmentEvents.OnCancel) {
        dispatch(navigateToHome());
      }
    };
    window.addEventListener('message', onSubmit);
    return () => {
      window.removeEventListener('message', listener);
      window.removeEventListener('message', onSubmit);
    };
  }, [accessToken]);

  const isLoading = (!accessToken || !url || !isClientConnected) === true;

  return (
    <Container fluid className='my-2 h-100'>
      <Row>
        <Col className='align-items-center'>
          {isLoading && (
            <div className='d-inline-flex align-items-center'>
              <Spinner animation='border' variant='secondary' />
              &nbsp;
              <span className='text-secondary'>{'We are initializing the enrollment process - please wait'}</span>
            </div>
          )}
        </Col>
      </Row>

      <Row className='h-100'>
        {accessError && (
          <Col className='my-2'>
            <strong>The API returned a non-JSON response.</strong>
            <Highlight className='xml my-2'>{accessError}</Highlight>
          </Col>
        )}
        {apiError && (
          <Col className='my-2'>
            <strong>The API returned an error.</strong>
            <Highlight className='json my-2'>{apiError}</Highlight>
          </Col>
        )}

        {
          // consider a Processing Enrollment message here so the user knows why the page is paused
          // this would require PRC legal review, so will probably be a follow up story
        }

        {!accessError && !apiError && url && (
          <Col className=''>
            <iframe id={EI_IFRAME_ID} title='enrollment-module' className='w-100 h-100 border-0' src={url} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default EnrollmentModule;
