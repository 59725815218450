import React, { useState, useMemo, useEffect, useCallback, useImperativeHandle } from 'react';
import { X } from 'react-bootstrap-icons';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import {
  RxAdminDose,
  useCreateRxAdminDose,
  useCheckRxAdminSRExist,
  useCheckRxAdminStatusExist,
  useGetRxAdminList
} from '../api';
import { AnyObject } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { defaultDateFormatWithAge, FIELD_NAMES, getAddAdminDoseSchema } from '../utils';
import { usePatientInfoQuery } from '../graphql';
import { HcpFormRenderer, HcpFormTemplate } from './DynamicForms';
import { HcpButton } from './HcpButton';
import { FormOptions, FormTemplateRenderProps } from '@data-driven-forms/react-form-renderer';

export const AddRxAdmin = React.forwardRef((props: { patientId: number | undefined; onUpdated?: () => void }, ref) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const { rxAdminDoseFields } = FIELD_NAMES;
  const drugs = useSelector((state: AppRootState) => state.app?.entities?.drugList);
  const { mutate: CreateRxAdminDose } = useCreateRxAdminDose({});

  const { data, refetch: checkSRExists } = useCheckRxAdminSRExist({});
  const { data: rxAdminStatus, refetch: CheckRxAdminStatusExist } = useCheckRxAdminStatusExist({});
  const { data: doseLists, refetch: getRxAdminDoseLists } = useGetRxAdminList({ lazy: true });

  const fetchRxAdminDose = useCallback(async () => {
    if (!props?.patientId) return;
    await getRxAdminDoseLists({ queryParams: { patientId: props?.patientId } });
  }, [props?.patientId, doseLists]);

  const isSRExists = useCallback(async () => {
    if (!props?.patientId) return;
    await checkSRExists({ queryParams: { patientId: props?.patientId } });
  }, [props?.patientId]);

  useEffect(() => {
    fetchRxAdminDose();
    if (!doseLists) return;
  }, [props?.patientId]);

  const isNewDoseAllow = useCallback(async () => {
    if (!props?.patientId) return;
    await CheckRxAdminStatusExist({ queryParams: { patientId: props?.patientId } });
  }, [props.patientId]);

  useEffect(() => {
    if (!props?.patientId) return;
    isSRExists();
  }, [isSRExists]);

  useEffect(() => {
    if (!props?.patientId) return;
    isNewDoseAllow();
  }, [isNewDoseAllow, props?.patientId]);

  const onClick = () => {
    setModalShow(true);
  };

  const onHide = () => {
    setModalShow(false);
  };

  // Currently, RX Admin explicitly only supports a single drug.
  // Until we support a dropdown in the "Add Dose" modal, we can't
  // safely allow a new routine to be started as the user has no way
  // of specifying what drug they want to administer.
  useEffect(() => {
    if (modalShow && drugs && drugs.length > 1) {
      throw new Error('Only a single drug is currently supported.');
    }
  }, [modalShow]);

  useImperativeHandle(ref, () => {
    return {
      invalidateAddRxAdmin: isNewDoseAllow
    };
  });

  const patientResult = usePatientInfoQuery({ variables: { patientId: props?.patientId?.toString() ?? '' } });
  const patientData = patientResult?.data?.result;

  const onSubmit = async (values: AnyObject) => {
    try {
      const payload: RxAdminDose = {
        patient: {
          id: props.patientId!,
          patientFirstName: patientData?.firstName,
          patientLastName: patientData?.lastName,
          patientDob: patientData?.dob
        },
        adminDose: parseInt(values[rxAdminDoseFields.dose]),
        adminDate: values[rxAdminDoseFields.adminDate]?.toISOString(),
        drug: {
          id: values[rxAdminDoseFields.drug_id],
          name: values[rxAdminDoseFields.product]
        },
        adminSource: 'HCP Portal',
        adminStatus: values[rxAdminDoseFields.status],
        adminNotes: values[rxAdminDoseFields.notes],
        physician: {
          id: parseInt(values[rxAdminDoseFields.prescriberId]),
          name: values[rxAdminDoseFields.prescriberName],
          npi: values[rxAdminDoseFields.npi]
        }
      };
      const result = await CreateRxAdminDose(payload);
      if (result?.errors?.length === 0) {
        setModalShow(false);
        props?.onUpdated?.();
        isNewDoseAllow();
        fetchRxAdminDose();
      }
      if (result?.errors && result?.errors?.length > 0) {
        const errors = result?.errors?.map((e) => e.description).join(' ');

        // Return the form error to display
        return { [FORM_ERROR]: errors };
      }
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };
  const payload = useMemo(() => {
    return {
      [rxAdminDoseFields.patientName]:
        patientData?.firstName + ' ' + patientData?.lastName + ' | ' + defaultDateFormatWithAge(patientData?.dob),
      [rxAdminDoseFields.adminDate]: new Date(),
      [rxAdminDoseFields.drug_id]: drugs?.[0]?.DrugId,
      [rxAdminDoseFields.product]: drugs?.[0]?.DrugBrandName?.toUpperCase(),
      [rxAdminDoseFields.status]: 'Pending',
      [rxAdminDoseFields.patientId]: props?.patientId,
      [rxAdminDoseFields.dose]: 1
    };
  }, [patientData, drugs, props?.patientId]);
  return (
    <div>
      {doseLists?.rxAdminDoseList?.length === 0 && (
        <Button
          className='text-nowrap mb-2 h-100'
          disabled={(!data?.patientSrExist || rxAdminStatus?.rxAdminStatusExist) ?? false}
          onClick={onClick}
          variant='secondary'>
          START
        </Button>
      )}
      <Modal show={modalShow} size='lg' className='w-90' onHide={onHide}>
        <Modal.Header className='bg-primary text-light'>
          <Modal.Title as={'h6'}>Add Dose</Modal.Title>
          <X className='pointer' size={24} onClick={onHide} />
        </Modal.Header>
        <Modal.Body>
          <HcpFormRenderer
            contentKey='form.rxAdmin.dose'
            initialValues={payload}
            noControls
            FormTemplate={AddRxAdminDoseView}
            onSubmit={onSubmit}
            onCancel={() => setModalShow(false)}
            schemaMethod={getAddAdminDoseSchema}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

const AddRxAdminDoseView = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, hasValidationErrors, submitError, values } = formProps.getState();
        return (
          <>
            <Modal.Footer className='d-flex justify-content-between'>
              <HcpButton
                variant='transparent'
                data-testid='cmx__cancel-button'
                className='text-danger mt-3 mt-md-0'
                onClick={formProps.onCancel}>
                CANCEL
              </HcpButton>
              <HcpButton
                className='mb-0 mb-lg-0 mr-lg-2'
                disabled={submitting || hasValidationErrors}
                onClick={() => formProps.change('redirect', true)}
                type='submit'
                variant='secondary'
                data-testid='cmx__save_changes-button'
                loading={submitting && values.redirect}>
                SAVE CHANGES
              </HcpButton>
            </Modal.Footer>
            {submitError && <p className='text-danger'>{submitError}</p>}
          </>
        );
      }}
    />
  );
};
