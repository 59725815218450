import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldPlainTextSection, headerSection, spacerSection } from '..';

export const getCopayCardDetailsSchema = (schema: DBFormSchema): Schema => ({
  title: 'vendor details',
  fields: [
    headerSection(schema?.copay_card_details_header?.label, schema?.copay_card_details_header),
    fieldPlainTextSection('copay_card_id', schema?.copay_card_id, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('copay_claim_payee', schema?.copay_claim_payee, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('balance_amount', schema?.balance_amount, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('copay_card_effective_date', schema?.copay_card_effective_date, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('copay_card_end_date', schema?.copay_card_end_date, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('date_eob_received', schema?.date_eob_received, {
      sizes: { md: 4 }
    }),
    spacerSection(3, { sizes: { sm: 12 } })
  ]
});
