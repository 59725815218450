import React, { useCallback } from 'react';

import {
  localDateFormat,
  getRequestDetailsSchema,
  nextStepParser,
  nextStepParserForRequestDetails,
  localDateTableFormatAnother
} from '../utils';
import { HcpFormRenderer } from '.';
import { ServiceRequestViewFragment } from '../graphql';

interface RequestDetailsFormProps {
  request?: ServiceRequestViewFragment | null;
}

export const RequestDetailsForm = ({ request }: RequestDetailsFormProps) => {
  const payload = useCallback(() => buildRequestDetails(request), [request]);
  return (
    <HcpFormRenderer
      contentKey='form.request-details'
      initialValues={payload()}
      noControls
      noErrorMessage
      schemaMethod={getRequestDetailsSchema}
    />
  );
};

const buildRequestDetails = (request?: ServiceRequestViewFragment | null) => {
  return {
    request_type: request?.requestType,
    request_id: request?.id,
    request_submitted: localDateTableFormatAnother(request?.requestStartDate),
    request_status: request?.statusText,
    request_prescriber: `${request?.physicianLastName}, ${request?.physicianFirstName}`,
    request_next_steps: nextStepParserForRequestDetails(request)
  };
};
