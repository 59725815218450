import React, { Props, useState } from 'react';

import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';
import { AnyObject, FORM_ERROR } from 'final-form';
import { Col, Row } from 'react-bootstrap';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { useDispatch, useSelector } from 'react-redux';

import { AppRootState, navigateToPracticeLocations } from '../redux';
import { AnyType, GeneralObject } from '../interfaces';
import { HcpButton, HcpFormRenderer, HcpFormTemplate, PrivateLayout } from '../components';
import { OrganizationAddressRequest, useCreateAddress } from '../api';
import { FIELD_NAMES, getAddLocationFormSchema, ROUTE_PATHS } from '../utils';

const { prescriberFields } = FIELD_NAMES;

export interface AddLocationViewProps extends Props<JSX.Element> {
  isStartRequestPage?: boolean;
  patientId?: string;
  prescriberId?: number;
  addedNewLocation: (locationId: number | null | undefined) => void;
}

export const AddLocationView = (props: AddLocationViewProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppRootState) => state.auth.session?.user);
  const [formKey, setFormKey] = useState(new Date().toISOString());
  const { mutate: createLocation } = useCreateAddress({});

  const submitLocation = async (values: GeneralObject) => {
    try {
      const payload: OrganizationAddressRequest = {
        addressType: 'Other',
        address1: String(values[prescriberFields.locationAddress1]),
        address2: values[prescriberFields.locationAddress2] as string,
        city: String(values[prescriberFields.locationAddressCity]),
        state: String(values[prescriberFields.locationAddressState]),
        zip: String(values[prescriberFields.locationAddressZip]),
        phone1: String(values[prescriberFields.locationPhone1]),
        phone1Type: 'Work',
        fax: String(values[prescriberFields.locationFaxNumber]),
        prescriberId: props?.prescriberId || null
      };

      const result = await createLocation(payload);
      const { organizationAddressId } = result;

      if (result.errors && result.errors.length > 0) {
        const errors = result.errors?.map((e) => e.description).join(' ');

        // Return the form error to display
        return { [FORM_ERROR]: errors };
      }

      // Submission worked so let's redirect, if desired based on the button used
      if (values.redirect === true) {
        if (props.isStartRequestPage) {
          props.addedNewLocation(organizationAddressId);
          return;
        }
        dispatch(navigateToPracticeLocations());
        return;
      }

      // Low-tech way to reset the form until form.restart is available;
      // see https://github.com/final-form/react-final-form/issues/840
      setFormKey(new Date().toISOString());
    } catch (e) {
      // TODO: validate the messages that _could_ show here.
      return { [FORM_ERROR]: e.message };
    }
  };

  if (props.isStartRequestPage) {
    return (
      <HcpFormRenderer
        contentKey='form.location'
        FormTemplate={(props) => <AddLocationFormTemplate {...props} isStartRequestPage={true} />}
        key={formKey}
        onCancel={() => dispatch(navigateToPracticeLocations())}
        onSubmit={submitLocation}
        schemaMethod={getAddLocationFormSchema}
      />
    );
  }

  const backNavigationText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['back.navigation.text' ?? ''] as AnyType)?.data
  );

  return (
    <PrivateLayout
      pageTitle='add a location'
      breadcrumbs={[{ label: backNavigationText?.content, href: `${ROUTE_PATHS.practice}#locations` }]}>
      <HcpFormRenderer
        contentKey='form.location'
        FormTemplate={AddLocationFormTemplate}
        key={formKey}
        onCancel={() => dispatch(navigateToPracticeLocations())}
        onSubmit={submitLocation}
        schemaMethod={getAddLocationFormSchema}
      />
    </PrivateLayout>
  );
};

export interface AddLocationFormTemplateProps extends FormTemplateRenderProps {
  isStartRequestPage?: boolean;
}

export const AddLocationFormTemplate = (props: AddLocationFormTemplateProps) => {
  const submitForm = (formProps: AnyObject) => {
    formProps.change('redirect', true);
    formProps.submit();
  };
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, hasValidationErrors, submitError, values } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row>
              {!props.isStartRequestPage && (
                <Col xs={12} md={6} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                  <HcpButton
                    className='mb-2 mb-lg-0 mr-lg-2'
                    disabled={submitting || hasValidationErrors}
                    onClick={() => formProps.change('redirect', true)}
                    type='submit'
                    variant='secondary'
                    data-testid='cmx__add-location-button'
                    loading={submitting && values.redirect}>
                    {submitting && values.redirect ? 'SUBMITTING...' : 'ADD LOCATION AND CLOSE'}
                  </HcpButton>
                  <HcpButton
                    disabled={submitting || hasValidationErrors}
                    className='mt-2 mt-lg-0 ml-lg-2'
                    onClick={() => formProps.change('redirect', false)}
                    type='submit'
                    variant='outline-secondary'
                    loading={submitting && !values.redirect}>
                    {submitting && !values.redirect ? 'SUBMITTING...' : 'SAVE LOCATION AND ADD ANOTHER'}
                  </HcpButton>
                </Col>
              )}
              {props.isStartRequestPage && (
                <Col xs={12} md={6} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                  <HcpButton
                    className='mb-2 mb-lg-0 mr-lg-2'
                    disabled={submitting || hasValidationErrors}
                    onClick={() => submitForm(formProps)}
                    variant='secondary'
                    data-testid='cmx__add-location-button'
                    loading={submitting && values.redirect}>
                    {submitting && values.redirect ? 'SUBMITTING...' : 'ADD LOCATION AND SELECT'}
                  </HcpButton>
                </Col>
              )}

              <Col xs={12} md={6} className='d-flex justify-content-md-end'>
                <HcpButton variant='transparent' className='text-danger mt-3 mt-md-0' onClick={formProps.onCancel}>
                  CANCEL
                </HcpButton>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
