import React, { useCallback, useContext, useRef, useState } from 'react';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { AnyType } from '../../interfaces';
import { DisplayErrorOrHelperTextFeedback } from '../DynamicForms';
import ConsentStatusC from './ConsentStatus';
import { FileUpload } from './FileUpload';
import { ConsentContext } from './context';
import { ConsentStatusEnum, FilePreview } from './types';
import { CONSENT_MODAL_LABEL } from '../../utils';

export default function UploadConsent() {
  const [files, setFiles] = useState<FilePreview[]>([]);
  const declarationRef = useRef<HTMLInputElement>(null);
  const { updateConsentStatus } = useContext(ConsentContext);

  const manualPatientConsentDocLink = `Click to <a href='' download='' target='_blank'><u>download</u></a> form`;

  const fieldProps = {
    showHelperText: false,
    showFileBadge: false,
    showDocumentDate: true,
    showGenericFileName: false,
    dropzoneOptions: {
      maxFiles: 1,
      maxSize: 1000000
    }
  };

  const removeFile = useCallback((file: FilePreview) => {
    setFiles((prevFiles) => prevFiles.filter((item) => item.path !== file.path));
    updateConsentStatus(ConsentStatusEnum.PENDING);
  }, []);

  const fileBadges = files.map((file: FilePreview, i: number) => (
    <div key={i}>
      <Badge pill variant='primary' className='px-2 py-1 mb-1 cmx__request_consent_modal__badge-file-name'>
        <span className='text-truncate'>
          {fieldProps.showGenericFileName ? 'File uploaded on ' + file.date : file?.path}
        </span>
        <X size={20} className='pointer ml-2' color='white' onClick={() => removeFile(file)} />
      </Badge>
    </div>
  ));

  const onFileUpload = useCallback((files: FilePreview[]) => {
    setFiles(files);
    updateConsentStatus(ConsentStatusEnum.SUCCESS);
  }, []);

  const input = {
    name: '',
    value: []
  };

  return (
    <div className='cmx__request_consent_modal_upload_consent'>
      <Col>
        <Row>
          <Col className='col-md-4 mr-n4'>
            <FileUpload onLoadEnd={onFileUpload} {...input} {...(fieldProps as AnyType)} value={files} />
            <DisplayErrorOrHelperTextFeedback meta={''} helperText={''} />
          </Col>
          <Col className='col-md-6  ml-3 d-flex flex-column justify-content-between'>
            <label className='consent-text-font' dangerouslySetInnerHTML={{ __html: manualPatientConsentDocLink }} />
            {fileBadges}
          </Col>
        </Row>
        <Row className='col-md-11'>
          {/* TODO check if required {files?.length > 0 && <p className='required-text-color consent-text-font consent-required-text'>Required</p>} */}
          <label className='consent-text-font mt-1 d-flex' htmlFor='declaration'>
            <Form.Check
              ref={declarationRef}
              type='checkbox'
              className='align-self-start cmx__request_consent_modal-checkbox-upload-declaration'
              id='declaration'
              required
            />
            <span className='ml-1'>{CONSENT_MODAL_LABEL.UPLOAD_DECLARATION}</span>
          </label>
        </Row>
      </Col>
      <div className='d-flex justify-content-end mt-2 cmx_consent_status'>
        <ConsentStatusC />
      </div>
    </div>
  );
}
