import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { defaultDateFormat, findInsurancePolicy } from '..';
import { EnrollmentRequest } from '../../api';
import { PortalEnrollmentRequest } from '../../api/interface-portal';
import { AnyType } from '../../interfaces';
import { AppRootState } from '../../redux';
import { authUserDetailsSelector } from '../../redux/selectors/auth.selector';
import { FINISHED_EVENT, standardPortalConfig } from '../constants';
import { getPatientHomePhoneNumber, getPatientMobilePhoneNumber } from '../helpers';

export const getMedicalEpaPayload = (
  values: PortalEnrollmentRequest,
  userName: string,
  organizationId: string,
  previousResults?: AnyType
) => {
  let selectedPolicyType: 'primary' | 'secondary' | 'tertiary' | 'other' = 'primary';
  const { state } = useLocation<AnyType>();
  if (state?.selectedPolicyType) {
    selectedPolicyType = state.selectedPolicyType;
  }
  const { diagnosis, patient = {}, physicians, insurance = {}, cpt, drugInfo, drugId } = values ?? {};
  const policy = findInsurancePolicy(selectedPolicyType, insurance) || {};
  const { family_name: userLastName, given_name: userFirstName, number: userPhoneNumber } =
    useSelector(authUserDetailsSelector) || {};
  const portalName = useSelector((state: AppRootState) => state.app?.config?.name);
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  let { drug } = useParams<{ drug: string }>();
  if (!drug) {
    drug = drugId?.toString() || '';
  }
  const drugList = useSelector((state: AppRootState) => state.app?.entities?.drugList) ?? null;
  const drugDetails =
    drug && Number(drug) !== 0 ? drugList?.filter((d) => d?.DrugId === Number(drug) && d.DrugId) : drugList;
  return {
    ClientApplication:
      standardPortalConfig.isStandardPortalUrl || portalName === 'Verrica HCP Portal' //Need to confirm about verrica
        ? standardPortalConfig.applicationName
        : portal_ApplicationName,
    UserName: String(userName),
    RequestData: JSON.stringify({
      Cpt: {
        Code1: cpt?.code1,
        Code2: cpt?.code2,
        Code3: cpt?.code3
      },
      CustomRedirectUrl1: FINISHED_EVENT,
      Diagnosis: {
        Primary: diagnosis?.primarydiagnosisCode,
        Secondary: diagnosis?.diagnosisCodesSecondary,
        Tertiary: diagnosis?.diagnosisCodesTertiary
      },
      Drug: {
        DaysSupply: drugInfo?.daysSupply, // TODO: Discover where to get this data
        Description: drugDetails?.[0]?.DrugLabelName,
        JCode: drugDetails?.[0]?.JCode1 ?? drugDetails?.[0]?.Jcode2, // TODO: Discover where to get this data
        NDC: drugInfo?.ndc, // TODO: Discover where to get this data
        Quantity: drugInfo?.quantity //
      },
      ExternalAccountId: organizationId ?? null,
      ExternalPatientId: String(patient?.patientId),
      Insurance: {
        Company: 'COMPANY', // TODO: Discover where to get this data
        GroupNumber: policy.medicalGroup,
        MemberID: policy.medicalCardHolderId,
        Plan: policy.medicalPlanName,
        State: 'NY', // TODO: Discover where to get this data,
        CareMetxPayerID: policy?.payerIds?.[2] ?? policy?.medPAPayerId,
        SamaCarePayerID: policy?.payerIds?.[2] ?? policy?.medPAPayerId
      },
      Patient: {
        Address: {
          Address1: patient.address1,
          Address2: patient.address2,
          City: patient.city,
          State: patient.state,
          ZIP: patient.zip
        },
        DOB: patient.dob ? defaultDateFormat(patient.dob) : '',
        Gender: patient.gender,
        Name: {
          FirstName: patient.firstName,
          LastName: patient.lastName,
          MiddleName: patient.middleName || 'NA',
          Prefix: 'NA', // TODO: Discover where to get this data
          Suffix: 'NA' // TODO: Discover where to get this data
        },
        Phone: getPatientHomePhoneNumber(patient) || getPatientMobilePhoneNumber(patient) || '5555555555'
      },
      Practice: {
        Address: {
          Address1: physicians?.organization?.organizationAddress?.address1,
          Address2: physicians?.organization?.organizationAddress?.address2,
          City: physicians?.organization?.organizationAddress?.city,
          State: physicians?.organization?.organizationAddress?.state,
          ZIP: physicians?.organization?.organizationAddress?.zip
        },
        Name: physicians?.organization?.name || 'NA',
        NPI: physicians?.npi,
        TaxId: physicians?.organization?.taxId ? physicians?.organization?.taxId : 'Unknown'
      },
      Prescriber: {
        Fax: physicians?.organization?.organizationAddress?.faxNumber?.replace(/[^+\d]+/g, ''),
        Name: {
          FirstName: physicians?.firstName,
          LastName: physicians?.lastName,
          MiddleName: physicians?.middleName || 'NA', // TODO: Discover where to get this data
          Prefix: 'NA', // TODO: Discover where to get this data
          Suffix: 'NA' // TODO: Discover where to get this data
        },
        NPI: physicians?.npi,
        Phone: physicians?.officeContact?.phoneNumber?.replace(/[^+\d]+/g, ''),
        TaxId: physicians?.taxId ?? '00-0000000'
      },
      StartDateOfService: diagnosis?.treatmentStartDate && defaultDateFormat(diagnosis?.treatmentStartDate),
      UserInformation: {
        Name: {
          FirstName: userFirstName,
          LastName: userLastName
        },
        PhoneNumber: userPhoneNumber?.replace(/[^+\d]+/g, '')
      },
      CallingTransactionType: previousResults?.callingTransactionType,
      CallingTransactionID: previousResults?.medEBV?.transactionId
    })
  };
};
