import React from 'react';

import { Form } from 'react-bootstrap';
import { Meta } from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api';

import { AnyType } from '../../interfaces';

export interface DisplayErrorProps {
  helperText?: string;
  meta: Meta<AnyType>;
  requireTouch?: boolean;
  className?: string;
}

export const isValid = (meta: Meta<AnyType>): boolean | undefined => {
  return !meta.error && meta.active;
};

export const isInvalid = (meta: Meta<AnyType>): boolean | undefined => {
  return meta.error && meta.touched;
};

export const DisplayErrorOrHelperTextFeedback = ({
  meta,
  helperText,
  requireTouch = true,
  className
}: DisplayErrorProps) => {
  if (!(meta.error && ((requireTouch && meta.touched) || !requireTouch))) {
    return helperText ? <Form.Text muted>{helperText}</Form.Text> : <></>;
  }

  return (
    <Form.Control.Feedback type='invalid' tooltip={false} className={className}>
      {meta.error}
    </Form.Control.Feedback>
  );
};

export const DisplayErrorOrHelperText = ({ meta, helperText, requireTouch = true }: DisplayErrorProps) => {
  if (!(meta.error && ((requireTouch && (meta.touched || meta.dirty)) || !requireTouch))) {
    return helperText ? <p className='fs-2 text-danger'>{helperText}</p> : <></>;
  }
  return <p className='fs-2 text-danger'>{meta.error}</p>;
};

export const validationProps = (
  meta: Meta<AnyType>
): { isValid: boolean | undefined; isInvalid: boolean | undefined } => ({
  isValid: isValid(meta),
  isInvalid: isInvalid(meta)
});
