import React from 'react';

import { GATEWAY_API_URL } from '../../../api';
import { Drug } from '../../../api/portal-config.generated';
import {
  Organization,
  OrganizationAddressFragment,
  PatientFragment,
  PatientInsurance,
  PatientPhoneNumberFragment,
  PhoneNumberDetailsFragment,
  PhysicianFragment,
  UserInfoFragment
} from '../../../graphql';
import { AnyType } from '../../../interfaces';
import { AuthUser } from '../../../redux';
import { clientApplicationNameSelector, ROUTE_PATHS, standardPortalConfig } from '../../../utils';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { PortalOrganization } from '../../../api/interface-portal';
import { useSelector } from 'react-redux';

/* ENROLLMENT MODULE ENDPOINT */
const endpoint = GATEWAY_API_URL;

export const TOKEN_URL_PATH = '/oauth2/token';
export const INIT_REUQST_URL_PATH = '/enrollment/initRequest';

export const getEnrollmentModuleEndpoint = (pathname: string): string => {
  return `${endpoint}${pathname}`?.replace(/([^:]\/)\/+/g, '$1');
};

/* ENROLLMENT MODULE CLIENT DETAIL */
enum ClientOptions {
  WeInfuse = 'WeInfuse',
  SamaCare = 'SamaCare',
  StandardPortal = 'standardhcpportal',
  PhyzPortal = 'phyzhcpportal'
}

/* ENROLLMENT MODULE REQUEST PAYLOAD */

export type EnrollmentModulePayloadProps = {
  user?: AuthUser;
  userDetail?: PhoneNumberDetailsFragment & Partial<AuthUser>;
  userInfo?: UserInfoFragment;
  patient?: PatientFragment | undefined | null;
  drug?: Drug | null;
  prescriber?: PhysicianFragment;
  insurance?: Array<PatientInsurance> | undefined;
  prescription?: AnyObject | null | undefined;
  orgDetail?: PortalOrganization;
  location?: OrganizationAddressFragment;
  redirectUrl?: { cancelUrl?: string; finishUrl?: string; goBackUrl?: string };
  service?: string | null | undefined;
};

export const prepareInsurancePayload = (insurance: Array<PatientInsurance> | undefined) => {
  //clear previous insurance id from session
  sessionStorage.removeItem('primaryInsId');
  sessionStorage.removeItem('secondaryInsId');
  sessionStorage.removeItem('tertiaryInsId');
  if (!insurance || insurance?.length === 0) return { hasInsurance: null };

  let insurancePayload = {};
  const getInsuranceValues = () => {
    const insuranceValue: Array<AnyObject> = [];
    insurance.map((i: PatientInsurance) => {
      if (i.payerName && i.medicalCardHolderId) {
        insuranceValue.push({
          PayerId: i?.payerId?.toString(),
          medicalInsuranceType: i?.insuranceType,
          insuranceName: i?.payerName,
          planName: i?.medicalPlanName,
          cardHolderName: i?.cardholderName,
          cardHolderId: i?.medicalCardHolderId,
          groupNumber: i?.medicalGroup,
          planType: i?.planType,
          phoneNumber: {
            phoneNumber: i?.phoneNumber,
            phoneType: null,
            okToLeaveMessage: true,
            preferredNumber: true
          },
          cardHolderRelationship: i?.relationship, //i?.relationship,
          employer: i?.employerName,
          medigapPolicy: null,
          cardholderDOB: i?.subscriberDOB
        });
      }
      if (i?.insuranceId) {
        sessionStorage.setItem(i.insuranceType?.toLowerCase() + 'InsId', i.insuranceId + '');
      }
    });
    return insuranceValue;
  };

  const getPharmacyInsuranceValues = () => {
    if (!insurance) return {};
    let selectedInsuranceType = null;
    selectedInsuranceType = insurance.find((i) => i?.insuranceType?.toLowerCase() === 'primary');
    if (
      !selectedInsuranceType ||
      !selectedInsuranceType?.pharmacyCardholderID ||
      !selectedInsuranceType?.insuranceName
    ) {
      selectedInsuranceType = insurance.find((i) => i?.insuranceType?.toLowerCase() === 'secondary');
    }
    if (!selectedInsuranceType || !selectedInsuranceType?.insuranceName || !selectedInsuranceType?.pharmacyCardholderID)
      return {};
    return {
      PayerId: selectedInsuranceType?.payerId?.toString(),
      insuranceName: selectedInsuranceType?.pharmacyPlanName,
      planName: selectedInsuranceType?.pharmacyPlanName,
      cardHolderId: selectedInsuranceType?.pharmacyCardholderID,
      groupNumber: selectedInsuranceType?.pharmacyGroup,
      bin: selectedInsuranceType?.pharmacyBINNumber,
      pcn: selectedInsuranceType?.pharmacyPCN,
      phoneNumber: {
        phoneNumber: selectedInsuranceType?.pharmacyPhoneNumber,
        phoneType: null,
        okToLeaveMessage: true,
        preferredNumber: true
      }
    };
  };

  const insuranceValues = getInsuranceValues();
  const pharmacyValues = getPharmacyInsuranceValues();

  if (insuranceValues?.length > 0) {
    insurancePayload = {
      ...insurancePayload,
      medicalInsurances: insuranceValues
    };
  }

  if (Object.keys(pharmacyValues).length > 0) {
    insurancePayload = {
      ...insurancePayload,
      pharmacyInsurance: pharmacyValues
    };
  }

  if (Object.keys(insurancePayload).length > 0) {
    return {
      ...insurancePayload,
      hasInsurance: true,
      medicareId: null,
      checkPharmacyInsurance: pharmacyValues ? 'Yes' : 'No',
      serviceType: ''
    };
  }

  return { hasInsurance: null };
};

export const prepareClinicalPayload = (prescription: AnyObject | null | undefined) => {
  if (prescription && prescription.diagnoses) {
    return {
      primaryDiagnosisCode: prescription?.diagnoses,
      secondaryDiagnosisCode: null,
      tertiaryDiagnosisCode: null,
      otherDiagnosisCode: null,
      primaryIndication: null,
      secondaryIndication: null,
      tertiaryIndication: null,
      otherIndication: null,
      dosage: null,
      frequency: null,
      numberOfVials: null,
      numberOfInfusions: null,
      weightLB: null,
      weightKG: null,
      dateOfTreatment: null
    };
  }
  return null;
};

export const prepareCaregiverInformation = (caregiver: AnyObject | null | undefined) => {
  if (!caregiver || caregiver.length <= 0) return null;
  const caregiverInfo = caregiver[0];
  return {
    person: {
      firstName: caregiverInfo?.contact?.firstName,
      middleName: caregiverInfo?.contact?.middleName,
      lastName: caregiverInfo?.contact?.lastName,
      gender: caregiverInfo?.contact?.gender
    },
    phoneNumbers: buildPatientPhoneNumbers(caregiverInfo?.phoneNumbers),
    relationship: caregiverInfo?.relationship,
    isLegallyAuthorizedRepresentative: caregiverInfo?.legallyAuthRep,
    email: caregiverInfo?.contact?.email
  };
};

export const buildRequestPayload = (props: EnrollmentModulePayloadProps) => {
  const user = props?.user;
  const userDetail = props?.userDetail;
  const userInfo = props?.userInfo;
  const patient = props?.patient;
  const drug = props?.drug;
  const prescriber = props?.prescriber;
  const orgDetail = props?.orgDetail;
  const location = props?.location;
  const insurance = props?.insurance;
  const prescription = props?.prescription;
  const service = props?.service;

  return {
    sourceSystem: {
      sourceSystemUser: {
        userName: user?.Login,
        person: {
          firstName: user?.given_name,
          middleName: user?.family_name,
          lastName: user?.family_name,
          gender: formatGender(null)
        },
        phoneNumber: null
      },
      cancelUrl: buildRedirectURL(props?.redirectUrl?.cancelUrl || ROUTE_PATHS.home, 'cancel'),
      finishUrl: buildRedirectURL(props?.redirectUrl?.finishUrl || ROUTE_PATHS.requestsSubmitted, 'finish'),
      goBackUrl: buildRedirectURL(props?.redirectUrl?.goBackUrl || ROUTE_PATHS.serviceRequest, 'goBack')
    },
    patient: {
      externalId: patient?.id,
      email: patient?.email === '' ? null : patient?.email,
      person: {
        firstName: patient?.firstName,
        middleName: patient?.middleName,
        lastName: patient?.lastName,
        gender: formatGender(patient?.gender, patient?.genderOther)
      },
      dateOfBirth: patient?.dob,
      preferredLanguages: [
        {
          language: patient?.preferredLanguage
        }
      ],
      preferredLanguageOther: patient?.preferredLanguageOther,
      phoneNumbers: buildPatientPhoneNumbers(patient?.phoneNumbers),
      addresses: [
        {
          address1: patient?.address?.address1,
          address2: patient?.address?.address2,
          city: patient?.address?.city,
          state: patient?.address?.state,
          zip: patient?.address?.zip
        }
      ],
      hasCaregiver: patient && patient?.patientContact && patient?.patientContact?.length > 0,
      caregiver: prepareCaregiverInformation(patient?.patientContact)
    },
    practice: {
      externalId: orgDetail?.organizationId,
      email: orgDetail?.email,
      name: orgDetail?.organizationName,
      npi: orgDetail?.npi,
      taxId: formatNumber(orgDetail?.taxId),
      location: {
        address: {
          addressType: null,
          address1: location?.address1,
          address2: location?.address2,
          city: location?.city,
          state: location?.state,
          zip: location?.zip
        },
        phoneNumbers: buildPracticePhoneNumber(location)
      },
      officeContact: {
        person: {
          firstName: userDetail?.given_name,
          middleName: null,
          lastName: userDetail?.family_name,
          gender: formatGender(null)
        },
        phoneNumbers: buildPracticePhoneNumber(location),
        email: userDetail?.email
      }
    },
    prescriber: {
      physician: {
        person: {
          firstName: prescriber?.firstName,
          middleName: prescriber?.middleName,
          lastName: prescriber?.lastName,
          gender: formatGender(null)
        },
        npi: prescriber?.npi,
        taxId: formatNumber(prescriber?.details?.taxId?.toString()),
        stateLicenseNumber: prescriber?.stateLicenses?.stateLicenseNumber?.toString(),
        licensingState: prescriber?.stateLicenses?.stateIssuer,
        physicianType: prescriber?.providerType,
        deaNumber: prescriber?.details?.dEA,
        hin: prescriber?.details?.hIN,
        ptanNumber: prescriber?.details?.pTAN,
        specialty: prescriber?.physicianSpecialties?.[0]?.lookupDataListValue,
        medicaidMedicareProviderNumber: prescriber?.details?.medicareID ?? prescriber?.details?.medicaidID
      }
    },
    serviceType: service,
    additionalPhysicians: [],
    drug: {
      name: drug?.DrugBrandName,
      jCode: drug?.JCode1 || drug?.Jcode2,
      ndc: drug?.DrugNDC,
      quantity: drug?.Quantity,
      daysSupply: drug?.DaysSupply
    },
    insurance: prepareInsurancePayload(insurance),
    clinical: prepareClinicalPayload(prescription)
  };
};

const buildRedirectURL = (pathname: string, key: string) => {
  const patientID = sessionStorage.getItem('patientIdEnrollment');
  const caseId = sessionStorage.getItem('caseIdEnrollment');
  if (patientID !== 'undefined' && caseId !== 'undefined') {
    switch (key) {
      case 'cancel': {
        return `${
          window.origin.includes('localhost') ? standardPortalConfig?.url : window.origin
        }/patients/${patientID}/${caseId}`;
      }
      case 'finish': {
        return `${window.origin.includes('localhost') ? standardPortalConfig?.url : window.origin}/enrollmentSR`;
      }
      case 'goBack': {
        return `${
          window.origin.includes('localhost') ? standardPortalConfig?.url : window.origin
        }/patients/${patientID}/${caseId}`;
      }
      default: {
        return `${window.origin.includes('localhost') ? standardPortalConfig?.url : window.origin}${pathname}`;
      }
    }
  } else {
    return `${window.origin.includes('localhost') ? standardPortalConfig?.url : window.origin}${pathname}`;
  }
};

const buildPatientPhoneNumbers = (phoneNumbers: AnyType): AnyType[] => {
  const mappedValue: AnyType[] = [];
  phoneNumbers?.forEach((obj: PatientPhoneNumberFragment) => {
    mappedValue.push({
      phoneNumber: formatNumber(obj?.phoneNumber),
      phoneType: obj?.phoneType,
      okToLeaveMessage: obj?.okToLeaveMsg,
      preferredNumber: false
    });
  });
  return mappedValue;
};

const buildPracticePhoneNumber = (location: AnyType): AnyType[] => {
  const mappedValue: AnyType[] = [];
  if (location?.phone1) {
    mappedValue.push({
      phoneNumber: formatNumber(location?.phone1),
      phoneType: location?.phone1Type?.toLowerCase() === 'cell' ? 'Mobile' : location?.phone1Type || 'work',
      okToLeaveMessage: null
    });
  }
  if (location?.phone2) {
    mappedValue.push({
      phoneNumber: formatNumber(location?.phone2),
      phoneType: location?.phone2Type?.toLowerCase() === 'cell' ? 'Mobile' : location?.phone2Type || 'work',
      okToLeaveMessage: null
    });
  }
  if (location?.fax) {
    mappedValue.push({
      phoneNumber: formatNumber(location?.fax),
      phoneType: 'fax',
      okToLeaveMessage: null
    });
  }
  return mappedValue;
};

const formatNumber = (phoneNumber: AnyType) => {
  return phoneNumber?.replaceAll(')', '').replaceAll('(', '').replaceAll(' ', '').replaceAll('-', '');
};

const formatGender = (gender?: string | null, otherGender?: string | null) => {
  if ('F' === gender || 'FEMALE' === gender?.toUpperCase()) {
    return 'FEMALE';
  } else if ('M' === gender || 'MALE' === gender?.toUpperCase()) {
    return 'MALE';
  } else if ('O' === gender || 'OTHER' === gender?.toUpperCase()) {
    return otherGender?.toUpperCase();
  } else {
    return null;
  }
};
