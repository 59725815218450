import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  DocumentsTable,
  HcpModal,
  HcpRequestStateUI,
  NotificationToastMessage,
  PrivateLayout,
  RequestDetailsForm,
  VendorDetailsForm,
  CopayCardDetailsForm,
  CopayClaimDetailsTable
} from '../components';
import { usePatientInfoLazyQuery, useServiceRequestWithDocumentsInfoLazyQuery } from '../graphql';
import { AppRootState, navigateToServiceRequestForPatient, tenancyFeatureSelector } from '../redux';
import { defaultDateFormatWithAge, getEnrollmentType, ROUTE_PATHS } from '../utils';
import { BvSummaryModule } from './BvSummaryDetails';
import { ServiceRequestType } from '../hooks/use-bv-summary';
import { EnrollmentModuleResult } from '../components/EnrollmentModuleResult';
import { AnyType, ServiceOptionType } from '../interfaces';
import { checkConsentStatusByDrug } from '../components/consent-modal/utils';

interface DocumentProps {
  __typename?: string | null;
  created: string;
  createdBy?: string | null;
  documentId?: string | null;
  documentType?: string | null;
  entityId?: string | null;
  entityName?: string | null;
  fileName?: string | null;
  fileStorageId?: string | null;
  formComplete?: string | null;
  lastUpdated?: string | null;
  product?: string | null;
  programId?: string | null;
  signatureRequired?: boolean | null;
  source?: string | null;
  status?: string | null;
  totalPages?: number | null;
}

export const RequestDetailsView = () => {
  const dispatch = useDispatch();
  const [consentStatus, setConsentStatus] = useState<string>('');
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const portalName: AnyType = useSelector<AnyType>((state: AppRootState) => state.app?.config?.applicationName);
  const { enabled: displayAdditionalCopayDetails }: any = useSelector(
    tenancyFeatureSelector('form.displayAdditionalCopayDetails')
  );

  const authorizationEnhancementEnabled = useSelector(
    (state: AppRootState) =>
      (state.app.entities.features?.['PatientAuthorizationEnhancement']?.data as any)?.enabled ?? false
  );

  const { id, patientId, requestType, requestId, enrollmentGuid } = useParams<{
    id: string;
    patientId: string;
    requestType: string;
    requestId: string;
    enrollmentGuid?: string;
  }>();
  const [execQuery, result] = useServiceRequestWithDocumentsInfoLazyQuery();
  const [execPatientQuery, patientResult] = usePatientInfoLazyQuery();

  useEffect(() => {
    if (ServiceRequestType.includes(requestType) || !currentUser) return;

    execQuery({
      variables: {
        serviceRequestId: +id,
        organizationId: String(currentUser.OrganizationId),
        programId: String(currentUser.ProgramId)
      }
    });
  }, [execQuery, currentUser, id]);

  const serviceRequests = result?.data?.result;
  const serviceRequest =
    serviceRequests && serviceRequests.length > 0 ? serviceRequests.filter((s: any) => s?.id === requestId)[0] : null;

  useEffect(() => {
    execPatientQuery({
      variables: {
        patientId: patientId
      }
    });
  }, [execPatientQuery, patientId]);

  const patient = patientResult.data?.result;

  const checkConsentStatus = async () => {
    if (serviceRequest?.authorizationReceived) {
      setConsentStatus('Yes');
    } else {
      try {
        const checkStatusResponse = await checkConsentStatusByDrug(patient, '');
        if (checkStatusResponse) {
          setConsentStatus('Yes');
        } else {
          setConsentStatus('No');
        }
      } catch (e) {
        setConsentStatus('No');
      }
    }
  };
  useEffect(() => {
    if (serviceRequest) {
      authorizationEnhancementEnabled
        ? checkConsentStatus()
        : setConsentStatus(serviceRequest?.authorizationReceived ? 'Yes' : 'No');
    }
  }, [patient, serviceRequest]);

  const generateSRDocumentType = () => {
    const documentArray: DocumentProps[] = [];
    serviceRequests?.map((sr) => {
      sr?.documents?.map((d) => {
        documentArray.push({
          __typename: d?.__typename ?? null,
          created: d?.created ?? new Date().toLocaleString() + '',
          createdBy: 'HCP Portal',
          documentId: d?.id ?? null,
          documentType: d?.documentType ?? null,
          entityId: d?.entityId ?? null,
          entityName: d?.entityName ?? null,
          fileName: d?.fileName ?? null,
          fileStorageId: d?.fileStorageId ?? null,
          formComplete: d?.formComplete ?? null,
          lastUpdated: d?.lastUpdated ?? null,
          product: d?.product ?? null,
          programId: d?.programId ?? null,
          signatureRequired: d?.signatureRequired ?? null,
          source: d?.source ?? null,
          status: d?.status ?? null,
          totalPages: d?.totalPages ?? null
        });
      });
    });
    return documentArray;
  };

  const documents = generateSRDocumentType()
    .sort((a, b) => a.created.localeCompare(b.created))
    ?.reverse();

  const renderSubtoolbarChildren = () => {
    return (
      <Row className='align-items-center justify-content-end flex-nowrap'>
        <Col sm='auto' className='pr-5'>
          <span className='subtoolbar__data-label'>DATE OF BIRTH (AGE)</span>
          <span className='subtoolbar__data-value'>{defaultDateFormatWithAge(patient?.dob) ?? ''}</span>
        </Col>
        <Col sm='auto' className='pr-5'>
          <span className='subtoolbar__data-label'>GENDER</span>
          <span className='subtoolbar__data-value'>{patient?.gender}</span>
        </Col>
        <Col sm='auto' className='pr-5'>
          <span className='subtoolbar__data-label'>PATIENT AUTHORIZATION</span>
          <span className='subtoolbar__data-value'>{consentStatus}</span>
        </Col>

        <Col sm='auto'>
          <Button
            className='text-nowrap'
            variant='secondary'
            size='sm'
            onClick={() => dispatch(navigateToServiceRequestForPatient(patientId))}>
            START NEW REQUEST
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <PrivateLayout
      pageTitle={`${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`}
      breadcrumbs={[{ label: '<< back to view requests', href: ROUTE_PATHS.requests }]}
      subtoolbarChildren={renderSubtoolbarChildren()}>
      {requestType && ServiceRequestType.includes(requestType) && requestId ? (
        <BvSummaryModule transactionId={requestId} transactionType={requestType} />
      ) : (
        <HcpRequestStateUI
          errorUIEnabled
          loadingUIEnabled
          isLoading={result.loading}
          isError={result.error}
          reloadingEnabled={false}>
          <RequestDetailsForm request={serviceRequest} />
          <NotificationMessage serviceRequest={serviceRequest} patient={patient} />
          {enrollmentGuid && <EnrollmentModuleSummaryPopup enrollmentGuid={enrollmentGuid} />}
          <DocumentsTable
            documents={documents}
            serviceRequest={serviceRequests || []}
            gridColumnFeatureKey='request-detail-page.documents.table'
          />
          <div className='mt-5' />
          {getEnrollmentType(ServiceOptionType?.CopayClaim, portalName) === serviceRequest?.requestType &&
            displayAdditionalCopayDetails && (
              <div>
                <VendorDetailsForm copayCardDetails={serviceRequest?.copayCard} />
                <CopayCardDetailsForm copayCardDetails={serviceRequest?.copayCard} />
                <CopayClaimDetailsTable claimDetails={serviceRequest?.srCopayPaymentEob} />
              </div>
            )}
        </HcpRequestStateUI>
      )}
    </PrivateLayout>
  );
};

const EnrollmentModuleSummaryPopup = (props: { enrollmentGuid: string }) => {
  const orgDetail = useSelector((state: AppRootState) => state.auth?.session?.orgDetails);
  const practiceId = orgDetail && orgDetail?.organizationId ? String(orgDetail?.organizationId) : '';

  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Button
        variant='secondary'
        size='sm'
        className='mb-3'
        onClick={() => {
          setModalShow(true);
        }}>
        View Enrollment Response
      </Button>
      <HcpModal
        enableCancel
        modalProps={{ show: modalShow, onHide: () => setModalShow(false), className: 'enrollmentModuleContainer' }}
        body={<EnrollmentModuleResult enrollmentId={props.enrollmentGuid} practiceId={practiceId || ''} />}
      />
    </div>
  );
};

const NotificationMessage = ({ serviceRequest, patient }: AnyType) => {
  // NOTE: CAREMETXPD-4719: Here toast message is only shown for Janssen Hub Enrollment as requirement.
  //                        Need to update request type logic later if required.
  //                        For janssen we only have Hub enrollment which is request type is 'all'
  const requestType = serviceRequest?.requestType?.toLocaleLowerCase()?.includes(ServiceOptionType.All)
    ? ServiceOptionType.All
    : null;
  const drugName = serviceRequest?.sRDrugName;
  const patientGender = patient?.gender;
  const messageKey = 'notificationMessage1';
  const genderSpecificMessageKey = 'genderSpecificNotificationMessage1';
  const displayNotificationFlagKey = 'displayNotificationMessage1';

  return (
    <>
      <NotificationToastMessage
        className='mb-3'
        requestType={requestType}
        drugName={drugName}
        patientGender={patientGender}
        messageKey={messageKey}
        genderSpecificMessageKey={genderSpecificMessageKey}
        displayNotificationFlagKey={displayNotificationFlagKey}
      />
    </>
  );
};
