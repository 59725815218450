import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getHashValue,
  PrivateLayout,
  ResourcesAdditionalResourcesTab,
  ResourcesContactInformationTab,
  ResourcesSpecialtyPharmacyTab
} from '../components';
import { AnyType } from '../interfaces';
import { tenancyFeatureSelector, AppRootState } from '../redux';
import { extractCaseUrl } from '../utils';

const contactInfomationTabKey = '#contactInfomation';
const specialtyPharmacyTaKey = '#specialtyPharmacy';
const additionalResourcesTabKey = '#additionalResources';

export const ResourcesView = () => {
  const history = useHistory();
  const isMultiDrugEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isMultiDrugEnabled'] as AnyType)?.data?.enabled
  );
  const resourcesConfig: AnyType = useSelector<AnyType>(tenancyFeatureSelector('resources.tabs'));

  const hashValue: string | undefined = extractCaseUrl(useLocation())?.hashValue;
  const defaultActiveKey = hashValue ? getHashValue(hashValue) : additionalResourcesTabKey;

  const onTabSelect = (eventKey: string | null) => {
    if (!eventKey) return;

    history.push(eventKey);
  };

  return (
    <PrivateLayout pageTitle='Resources'>
      <Tabs className='tabs-primary' defaultActiveKey={defaultActiveKey} mountOnEnter onSelect={onTabSelect}>
        {resourcesConfig?.contactInfo?.enabled && isMultiDrugEnabled && (
          <Tab className='pt-3' eventKey={contactInfomationTabKey} title='CONTACT INFORMATION'>
            <ResourcesContactInformationTab contactInfoConfig={resourcesConfig?.contactInfo} />
          </Tab>
        )}
        {resourcesConfig?.specialtyPharmacy?.enabled && isMultiDrugEnabled && (
          <Tab className='pt-3' eventKey={specialtyPharmacyTaKey} title='SPECIALTY PHARMACY'>
            <ResourcesSpecialtyPharmacyTab specialtyPharmacyConfig={resourcesConfig?.specialtyPharmacy} />
          </Tab>
        )}
        {resourcesConfig?.additionalResources?.enabled && (
          <Tab className='pt-3' eventKey={additionalResourcesTabKey} title='ADDITIONAL RESOURCES'>
            <ResourcesAdditionalResourcesTab />
          </Tab>
        )}
      </Tabs>
    </PrivateLayout>
  );
};
