import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { AppRootState, logout, navigateToSettings } from '../redux';
import { renderTenancyLinksAsNavItems } from '../utils';

export const UserMenu = () => {
  const dispatch = useDispatch();
  const session = useSelector((state: AppRootState) => state.auth.session);
  const adminMenu = useSelector(
    (state: AppRootState) => (((state.app.entities?.menus as AnyType) ?? {})['user.admin'] as AnyType)?.data?.links
  );
  const generalMenu = useSelector(
    (state: AppRootState) => (((state.app.entities?.menus as AnyType) ?? {})['user.general'] as AnyType)?.data?.links
  );
  const isAdmin = Boolean(useSelector((state: AppRootState) => state.auth?.isAdmin));

  const renderUserTitle = () => {
    if (!session) return '';

    return (
      <span className='no-transform text-truncate d-inline-block' style={{ maxWidth: '200px' }}>
        {session?.user?.name} <br />@ {session?.orgDetails?.organizationName}
      </span>
    );
  };

  return (
    <NavDropdown
      id='user-menu-dropdown'
      title={renderUserTitle()}
      className='cmx__hcp-user-nav w-auto'
      data-testid='cmx__usermenu'>
      {isAdmin && renderTenancyLinksAsNavItems(dispatch, adminMenu)}
      {renderTenancyLinksAsNavItems(dispatch, generalMenu)}

      <NavDropdown.Item data-testid='cmx__my-account' eventKey='account' onClick={() => dispatch(navigateToSettings())}>
        <NavDropdown.ItemText>My Account</NavDropdown.ItemText>
      </NavDropdown.Item>
      <NavDropdown.Item data-testid='cmx__logout' eventKey='logout' onClick={() => dispatch(logout())}>
        <NavDropdown.ItemText>Logout</NavDropdown.ItemText>
      </NavDropdown.Item>
    </NavDropdown>
  );
};
