import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { CustomLinks } from '.';
import { ResourceContent } from '.';
import { CustomThemes } from '../utils/custom-themes';

interface OverbrandToolbarProps {
  withLinks?: boolean;
}

export const OverbrandToolbar = ({ withLinks = true }: OverbrandToolbarProps) => {
  CustomThemes();
  return (
    <Container fluid className='cmx__overbrand' data-testid='cmx__overbrand'>
      <Container className='h-100'>
        <Row className='h-100 align-items-center flex-column flex-md-row'>
          <Col lg md={6}>
            <ResourceContent contentKey='header.usage-notice' />
          </Col>
          {withLinks && <CustomLinks />}
        </Row>
      </Container>
    </Container>
  );
};
