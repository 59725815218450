import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { formatPhoneNumber, emailValidationHelper } from '../utils/helpers';

const EditUserDetails = (props: any) => {
  const { handleClose, data, onSubmit, error, isLoading } = props;
  const [email, setEmail] = useState(data?.email);
  const [phone, setPhone] = useState(data?.cellNumber);
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    handlePhoneInput(phone);
  }, []);

  const handleSubmit = () => {
    const result = onSubmit({ email, cellNumber: phone, userName: data?.userName });
    return;
  };

  const handlePhoneInput = (value: string) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    if (formattedPhoneNumber?.length && formattedPhoneNumber?.length < 14) {
      setPhoneError('User phone must have 10 digits');
    } else if (formattedPhoneNumber?.length === 0) {
      setPhoneError('');
    } else {
      setPhoneError('');
    }
    setPhone(formattedPhoneNumber);
  };

  const handleEmailInput = (value: string) => {
    if (value === '') {
      setEmailError('Email is a required field');
    } else if (emailValidationHelper(value)) {
      setEmailError('Email is invalid');
    } else {
      setEmailError('');
    }
    setEmail(value);
  };

  return (
    <div className='popup-box'>
      <div className='popup-box-container'>
        <p className='text-uppercase section-text'>Update User</p>
        {error && <p className='invalid-feedback-input'>{error}</p>}
        <div className='form-row'>
          <div className='form-group col-md-8'>
            <label className='text-uppercase form-label'>Email</label>
            <input
              type='text'
              className={emailError ? 'form-control is-invalid' : 'form-control'}
              value={email}
              onChange={(e) => handleEmailInput(e.target.value)}
            />
            {emailError && <p className='invalid-feedback-input'>{emailError}</p>}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-8'>
            <label className='text-uppercase form-label'>Phone number</label>
            <input
              type='text'
              className={phoneError ? 'form-control is-invalid' : 'form-control'}
              value={phone}
              onChange={(e) => handlePhoneInput(e.target.value)}
            />
            {phoneError && <p className='invalid-feedback-input'> {phoneError} </p>}
          </div>
        </div>
        <div className='mb-4 form-row'>
          <div className='d-flex flex-column flex-lg-row justify-content-md-start col-md-6 col-12'>
            <button
              className='mb-2 mb-lg-0 mr-lg-2 btn btn-secondary disabled'
              disabled={emailError.length + phoneError.length !== 0}
              onClick={handleSubmit}>
              UPDATE USER AND CLOSE
            </button>
            {isLoading && <Spinner animation='border' variant='secondary' />}
          </div>
          <div className='d-flex justify-content-md-end col-md-6 col-12'>
            <button className='text-danger mt-3 mt-md-0 btn btn-transparent' onClick={handleClose}>
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserDetails;
