import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldTextSection, fieldLoginInput } from '..';

export const getResetPasswordFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'reset password form',
  fields: [
    fieldLoginInput('new_password', schema?.new_password, {
      type: 'password',
      sizes: { xs: 12 },
      passwordRequirementBox: true,
      top: '-6px',
      left: '-32px',
      marginLeft: '214px',
      validate: [
        {
          type: 'same-as',
          strict: true,
          compareToField: 'confirm_password'
        },
        {
          type: 'strong-password'
        }
      ]
    }),
    fieldLoginInput('confirm_password', schema?.confirm_password, {
      type: 'password',
      sizes: { xs: 12 },
      validate: [
        {
          type: 'same-as',
          strict: true,
          compareToField: 'new_password'
        },
        {
          type: 'strong-password'
        }
      ]
    })
  ].filter(Boolean)
});

// -----===[test schema]===-----
export const resetPasswordFormSchema: DBFormSchema = {
  new_password: { label: 'enter your new password', required: true },
  confirm_password: { label: 'confirm your password', required: true }
};
