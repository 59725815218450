import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../..';
import { DBFormSchema, fieldTextSection, spacerSection } from '..';

const { rxAdminDoseFields } = FIELD_NAMES;

export const getviewAdminDoseSchema = (schema: DBFormSchema): Schema => ({
  title: 'edit dose',
  fields: [
    spacerSection(1, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.patientName, schema?.[rxAdminDoseFields.patientName], {
      sizes: { md: 8 },
      readonly: 'readonly'
    }),
    spacerSection(2, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.product, schema?.[rxAdminDoseFields.product], {
      sizes: { md: 8 },
      readonly: 'readonly'
    }),
    spacerSection(3, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.dose, schema?.[rxAdminDoseFields.dose], {
      sizes: { md: 8 },
      readonly: 'readonly'
    }),
    spacerSection(4, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.adminDate, schema?.[rxAdminDoseFields.adminDate], {
      sizes: { md: 4 },
      readonly: 'readonly'
    }),
    fieldTextSection(rxAdminDoseFields.status, schema?.[rxAdminDoseFields.status], {
      sizes: { md: 4 },
      readonly: 'readonly'
    }),
    spacerSection(5, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.prescriberName, schema?.[rxAdminDoseFields.prescriberViewName], {
      sizes: { md: 8 },
      readonly: 'readonly'
    }),
    spacerSection(6, { sizes: { sm: 3 } }),
    fieldTextSection(rxAdminDoseFields.notes, schema?.[rxAdminDoseFields.notes], {
      sizes: { md: 8 },
      readonly: 'readonly'
    })
  ]
});
