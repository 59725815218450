import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

import {
  DBFormSchema,
  fieldRadioSection,
  fieldSelectSection,
  fieldTextSection,
  headerSection,
  spacerSection
} from '../field-helpers';
import { hasInsuranceCondition, StepNames } from '.';
import { primaryDiagnosisCodes } from '../../data';
import { FIELD_NAMES } from '../../field-names';

const { pharmacyFields } = FIELD_NAMES;

export const pharmacyTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const pharmacyTab = schema?.pharmacy_tab;

  if (!pharmacyTab || pharmacyTab?.hideTab) return null;

  return {
    title: 'Pharmacy',
    name: StepNames.PHARMACY,
    nextStep: pharmacyTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.pharmacy_information_header?.label, schema?.pharmacy_information_header),
      fieldRadioSection(pharmacyFields?.pharmacyName, schema?.[pharmacyFields.pharmacyName], {
        className: 'vertical-options'
      }),
      spacerSection('pharmacy_1', { sizes: { sm: 12 } }),
      fieldTextSection(pharmacyFields.otherPharmacyName, schema?.[pharmacyFields.otherPharmacyName], {
        sizes: { md: 7 },
        options: [...primaryDiagnosisCodes],
        condition: {
          when: pharmacyFields.pharmacyName,
          is: (value: string) => value?.toLowerCase() === 'other'
        }
      })
    ]
  };
};
