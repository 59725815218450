import React from 'react';
import { ToggleButton, ToggleButtonProps } from 'react-bootstrap';

export const FiltersButton = (props: ToggleButtonProps) => {
  return (
    <ToggleButton {...props} className='mr-3 px-3 rounded text-uppercase' size='sm'>
      {props.children}
    </ToggleButton>
  );
};
