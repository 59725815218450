import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../../field-names';
import {
  DBFormSchema,
  fieldCheckboxSection,
  fieldDateSection,
  fieldRadioSection,
  fieldTextSection,
  headerSection,
  spacerSection
} from '..';
import {
  fieldSelectLanguagesSection,
  fieldSelectRelationshipsSection,
  fieldSelectStatesSection
} from '../field-helpers';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';

const { caregiverFields, patientFields } = FIELD_NAMES;

export const getPatientFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'Add Patient Form',
  fields: [
    headerSection(schema?.personal_info_header?.label, schema?.personal_info_header),
    fieldTextSection(patientFields.firstName, schema?.[patientFields.firstName], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'name',
          max: 20,
          label: 'First Name'
        }
      ]
    }),
    fieldTextSection(patientFields.middleName, schema?.[patientFields.middleName], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'name',
          max: 20,
          label: 'Middle Name'
        }
      ]
    }),
    fieldTextSection(patientFields.lastName, schema?.[patientFields.lastName], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'name',
          max: 20,
          label: 'Last Name'
        }
      ]
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldDateSection(patientFields.dob, schema?.[patientFields.dob], { sizes: { md: 2.5 } }),
    fieldRadioSection(patientFields.gender, schema?.[patientFields.gender], {
      sizes: { md: 2.5 },
      className: 'custom-class',
      options: schema?.[patientFields.otherGender]
        ? [
            {
              value: 'M',
              label: 'Male'
            },
            {
              value: 'F',
              label: 'Female'
            },
            {
              value: 'O',
              label: 'Other'
            }
          ]
        : [
            {
              value: 'M',
              label: 'Male'
            },
            {
              value: 'F',
              label: 'Female'
            }
          ]
    }),
    fieldTextSection(patientFields.otherGender, schema?.[patientFields.otherGender], {
      sizes: { md: 2 },
      condition: {
        when: patientFields.gender,
        is: 'O'
      }
    }),
    fieldSelectLanguagesSection(patientFields.preferredLanguage, schema?.[patientFields.preferredLanguage], {
      sizes: { md: 2 },
      className: 'select-language'
    }),
    fieldTextSection(patientFields.otherLanguage, schema?.[patientFields.otherLanguage], {
      sizes: { md: 2 },
      condition: {
        when: patientFields.preferredLanguage,
        is: 'Other'
      }
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    headerSection(schema?.contact_header?.label, schema?.contact_header),
    fieldTextSection(patientFields.email, schema?.[patientFields.email], {
      sizes: { md: 4 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
          message: 'Email is invalid'
        }
      ]
    }),
    fieldTextSection(patientFields.homeNumber, schema?.[patientFields.homeNumber], {
      sizes: { md: 2 },
      type: 'tel',
      validate: [
        {
          type: 'phone-conditional',
          compareToField: patientFields.mobileNumber
        }
      ]
    }),
    fieldTextSection(patientFields.mobileNumber, schema?.[patientFields.mobileNumber], {
      sizes: { md: 2 },
      type: 'tel',
      validate: [
        {
          type: 'phone-conditional',
          compareToField: patientFields.homeNumber
        }
      ]
    }),
    fieldCheckboxSection(patientFields.messageOption, schema?.[patientFields.messageOption], {
      sizes: { md: 4 },
      options: [
        {
          value: 'home',
          label: 'Home'
        },
        {
          value: 'mobile',
          label: 'Mobile'
        }
      ]
    }),
    spacerSection(3, { sizes: { sm: 12 } }),
    fieldTextSection(patientFields.address1, schema?.[patientFields.address1], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'address'
        }
      ]
    }),
    fieldTextSection(patientFields.address2, schema?.[patientFields.address2], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'address'
        }
      ]
    }),
    spacerSection(4, { sizes: { sm: 12 } }),
    fieldTextSection(patientFields.zip, schema?.[patientFields.zip], {
      sizes: { md: 2 },
      type: 'zip',
      validate: [
        {
          type: 'zip'
        }
      ]
    }),
    fieldTextSection(patientFields.city, schema?.[patientFields.city], {
      sizes: { md: 2 },
      validate: [
        {
          type: 'city'
        }
      ]
    }),
    fieldSelectStatesSection(patientFields.state, schema?.[patientFields.state], {
      sizes: { md: 2 }
    }),
    spacerSection(5, { sizes: { sm: 12 } }),
    headerSection(schema?.caregiver_information_header?.label, schema?.caregiver_information_header),
    fieldCheckboxSection(patientFields.hasCaregiver, schema?.[patientFields.hasCaregiver], {
      sizes: { md: 4 },
      options: [
        {
          value: true,
          label: 'My patient has a caregiver'
        }
      ]
    }),
    spacerSection(6, { sizes: { sm: 12 }, ...hasCaregiverCondition }),
    fieldTextSection(caregiverFields.firstName, schema?.[caregiverFields.firstName], {
      sizes: { md: 4 },
      ...hasCaregiverCondition
    }),
    fieldTextSection(caregiverFields.middleName, schema?.[caregiverFields.middleName], {
      sizes: { md: 4 },
      ...hasCaregiverCondition
    }),
    fieldTextSection(caregiverFields.lastName, schema?.[caregiverFields.lastName], {
      sizes: { md: 4 },
      ...hasCaregiverCondition
    }),
    spacerSection(7, { sizes: { sm: 12 }, ...hasCaregiverCondition }),
    fieldTextSection(caregiverFields.email, schema?.[caregiverFields.email], {
      sizes: { md: 4 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
          message: 'Email is invalid'
        }
      ],
      ...hasCaregiverCondition
    }),
    fieldTextSection(caregiverFields.homePhone, schema?.[caregiverFields.homePhone], {
      sizes: { md: 2 },
      type: 'tel',
      ...hasCaregiverCondition
    }),
    fieldTextSection(caregiverFields.mobilePhone, schema?.[caregiverFields.mobilePhone], {
      sizes: { md: 2 },
      type: 'tel',
      ...hasCaregiverCondition
    }),
    spacerSection(8, { sizes: { sm: 12 }, ...hasCaregiverCondition }),
    fieldSelectRelationshipsSection(
      caregiverFields.patientRelationship,
      schema?.[caregiverFields.patientRelationship],
      {
        sizes: { md: 3 },
        ...hasCaregiverCondition
      }
    ),
    spacerSection(9, { sizes: { sm: 12 }, ...hasCaregiverCondition }),
    fieldCheckboxSection(
      caregiverFields.isAuthorizedRepForPatient,
      schema?.[caregiverFields.isAuthorizedRepForPatient],
      {
        sizes: { md: 6 },
        options: [
          {
            value: true,
            label: 'This caregiver is a Legally Authorized Representative for the patient'
          }
        ],
        ...hasCaregiverCondition
      }
    ),
    spacerSection(10, { sizes: { sm: 12 }, ...hasCaregiverCondition })
  ].filter(Boolean)
});

const hasCaregiverCondition = {
  condition: {
    when: patientFields.hasCaregiver,
    is: (value: boolean[]) => value && value?.length && !!value?.[0]
  }
};

// -----===[test schema]===-----
export const addPatientFormSchema: DBFormSchema = {
  personal_info_header: { label: 'personal information' },
  patient_first_name: { label: 'first name', required: true },
  patient_middle_name: { label: 'middle name', placeholder: 'optional' },
  patient_last_name: { label: 'last name', required: true },
  patient_dob: { label: 'date of birth', required: true },
  patient_gender: { label: 'gender', required: true },
  patient_preferred_language: { label: 'preferred language', required: true },
  patient_other_language: { label: 'specify "other" language', required: true },
  contact_header: { label: 'contact and address information' },
  patient_email: { label: 'email address' },
  patient_home_number: { label: 'home phone number', placeholder: 'optional' },
  patient_mobile_number: { label: 'mobile number', placeholder: 'optional' },
  patient_message_option: { label: 'okay to leave message' },
  patient_address_1: { label: 'address 1', required: true },
  patient_address_2: { label: 'address 2', placeholder: 'optional' },
  patient_zip: { label: 'zip code', required: true },
  patient_city: { label: 'city', required: true },
  patient_state: { label: 'state', required: true },
  caregiver_information_header: { label: 'caregiver information' },
  patient_has_caregiver: {},
  caregiver_first_name: { label: 'first name', required: true },
  caregiver_middle_name: { label: 'middle name' },
  caregiver_last_name: { label: 'last name', required: true },
  caregiver_email: { label: 'email address' },
  caregiver_home_phone: { label: 'home phone number' },
  caregiver_mobile_phone: { label: 'mobile number' },
  caregiver_patient_relationship: { label: 'relationship to patient', required: true },
  caregiver_is_authorized_representative: {}
};
