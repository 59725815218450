import React from 'react';

import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';
import { FORM_ERROR } from 'final-form';
import { Button, Col, Row } from 'react-bootstrap';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { useDispatch, useSelector } from 'react-redux';
import { useChangePassword } from '../api';
import { AppRootState, logout, navigateToHome, navigateToLogin } from '../redux';
import { GeneralObject } from '../interfaces';
import { HcpFormRenderer, HcpFormTemplate } from '.';
import { clientApplicationNameSelector, getMyAccountFormSchema } from '../utils';

export const SettingsChangePasswordForm = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const { mutate: changePassword } = useChangePassword({});
  const applicationName = useSelector(clientApplicationNameSelector);

  const onSubmit = async (values: GeneralObject) => {
    try {
      const result = await changePassword({
        oldPassword: String(values.current_password),
        newPassword: String(values.new_password)
      });

      if ((result?.errors?.length ?? 0) > 0)
        return { [FORM_ERROR]: result?.errors?.map((e) => e.description)?.join(' ') };

      dispatch(logout());
      dispatch(navigateToLogin());
      /*This is a temporary fix we will have a permanent fix after the okta SDK resolved the issue.
       Okta open Issue in git: https://github.com/okta/okta-oidc-js/issues/898.*/
      window.location.reload();
    } catch (error) {
      return { [FORM_ERROR]: error.message };
    }
  };

  return (
    <HcpFormRenderer
      contentKey='form.my-account'
      FormTemplate={SettingsFormTemplate}
      onCancel={() => dispatch(navigateToHome())}
      onSubmit={onSubmit}
      schemaMethod={getMyAccountFormSchema}
    />
  );
};

const SettingsFormTemplate = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, hasValidationErrors, submitError } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row>
              <Col xs={12} md={6}>
                <Button type='submit' variant='secondary' disabled={submitting || hasValidationErrors}>
                  {submitting ? 'SUBMITTING...' : 'UPDATE PASSWORD'}
                </Button>
              </Col>
              <Col xs={12} md={6} className='d-flex justify-content-md-end'>
                <Button
                  type='reset'
                  variant='transparent'
                  className='text-danger mt-3 mt-md-0'
                  onClick={formProps?.onCancel}>
                  CANCEL
                </Button>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
