import React from 'react';
import { Col } from 'react-bootstrap';
import { CaseDetailSidebarPatientInfo, CaseDetailSidebarNav } from '.';
import './CaseDetailSidebar.scss';
import { PatientDetailSideBarNav } from './PatientDetailSidebarNav';

const PrimarySidebar = () => {
  return (
    <Col xs={4} md={2} lg={3} className='cmx__case-detail-sidebar d-flex flex-column overflow-hidden'>
      <CaseDetailSidebarPatientInfo />
      <PatientDetailSideBarNav />
    </Col>
  );
};
export default PrimarySidebar;
