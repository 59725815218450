import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import InfoIcon from '../InfoIcon';

import { DisplayErrorOrHelperTextFeedback } from '../../components/DynamicForms/Common';
import { validationProps } from '../../components/DynamicForms/Common';
import { useSelector } from 'react-redux';
import { AppRootState } from '../../redux/reducers/types';
import {
  PhysicianFragment,
  useGetPatientDetailByIdLazyQuery,
  usePhysiciansInfoLazyQuery
} from '../../graphql/generated/types';
import { GRAPHQL_DEFAULT_CACHE } from '../../graphql/apollo';

import { displayRxErrorText } from '../../components/FormRXFields';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { SearchInputWithRXGql } from '../../components/SearchInputWithRXGql';
import { SearchInputWithRXGqlDisplay } from '../../components/SearchInputWithRXGql';
import { SearchInputWithRXGqlIconLocation } from '../../components/SearchInputWithRXGql';

import { AnyType } from '../../interfaces';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const HcpSpacerRxAdmin = ({ empty, sizes, ...props }: UseFieldApiConfig) =>
  empty && !props.sizes ? <></> : <Form.Group as={Col} className='m-0' {...sizes} {...props} id={props.name} />;
export const HcpSeparatorRxAdmin = (props: UseFieldApiConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} sm={12} id={props.name}>
      <hr {...fieldProps} />
    </Form.Group>
  );
};
export const HcpInputRxAdminField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, passwordRequirementBox, ...fieldProps } = useFieldApi(props);
  const optional = fieldProps.optional && meta.error === 'Required';
  if (props.className) {
    return (
      <Form.Group as={Col} {...props.sizes} className={props.className}>
        {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
        {optional ? (
          <Form.Control {...input} {...fieldProps} className='' />
        ) : (
          <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
        )}
        {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
      </Form.Group>
    );
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {passwordRequirementBox ? <InfoIcon arrow='arrow-top-left' {...fieldProps} /> : null}
      {optional ? (
        <Form.Control {...input} {...fieldProps} className='' />
      ) : (
        <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};
export const HcpSectionRxAdmin = ({ name, label, subsection, subsectionuncaps, ...props }: UseFieldApiConfig) => {
  return (
    <Col sm={12} id={name} {...props}>
      <p
        className={`${
          subsection ? 'text-capitalize sub-section' : subsectionuncaps ? 'sub-section' : 'text-uppercase section'
        }-text`}>
        {label}
      </p>
    </Col>
  );
};
export const HCPDatePickerRxAdminWithTime = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, placeholder, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();
  // date range for datepicker
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const optional = fieldProps.optional && meta.error === 'Required';
  return (
    <Form.Group as={Col} {...fieldProps} className='col-md-4'>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        className='form-control'
        showTimeInput
        placeholderText={placeholder ?? ''}
        selected={form.getState().values['rx_administration_date']}
        onChange={(date: AnyType) => {
          form.change('rx_administration_date', date);
        }}
        {...fieldProps}
        {...validationProps(meta)}
      />
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : null}
    </Form.Group>
  );
};
export const HcpRxAdminSelectField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, optional, meta, className, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} className={className} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {optional ? (
        <Form.Control as='select' {...input} {...fieldProps}>
          <option value=''>--Select One--</option>
          {props.options?.map((option: { value: string; label?: string }, i: number) => (
            <option key={i} value={option.value}>
              {option?.label}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Form.Control as='select' {...input} {...fieldProps} {...validationProps(meta)}>
          <option value=''>--Select One--</option>
          {props.options?.map((option: { value: string; label?: string }, i: number) => (
            <option key={i} value={option.value}>
              {option?.label}
            </option>
          ))}
        </Form.Control>
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};
export const HcpRxAdminSearchOnlyPrescriber = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldprops = useFieldApi(props);
  const [prescribersearch, setprescribersearch] = useState<boolean>(false);
  const organizationid = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const [execpatientdetailquery, patientdetailresult] = useGetPatientDetailByIdLazyQuery({
    variables: {
      organization: parseInt(organizationid || ''),
      patientId: parseInt(form.getState().values['patient_id']) || 0
    },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });
  const loaddefaultphysician = useCallback(() => {
    execpatientdetailquery();
  }, []);
  useEffect(loaddefaultphysician, [loaddefaultphysician]);
  useEffect(() => {
    const result = patientdetailresult?.data;
    if (!result) return;
  }, [organizationid]);
  const getDefaultPrescriber = (
    data: string | null
  ): { firstName?: string; lastName?: string; middleName?: string; npi?: string }[] | string => {
    if (!data) return '';
    const prescriberArray = data?.split('|');
    const fullName = prescriberArray?.[0]?.split(' ') ?? [];
    return [
      {
        firstName: fullName?.[0],
        lastName: fullName?.[1],
        npi: prescriberArray?.[1]
      }
    ];
  };
  const onprescriberselect = (prescriber: PhysicianFragment | null) => {
    setprescribersearch(true);
    form.batch(() => {
      form.change('prescriber_id', prescriber?.id);
      form.change('prescriber_first_name', prescriber?.firstName);
      form.change('prescriber_middle_name', prescriber?.middleName);
      form.change('prescriber_last_name', prescriber?.lastName);
      form.change(
        'prescriber_npi',
        // a server-sent blank string can cause a nan-related error during validation conversion, so we default to null:
        prescriber?.npi || null
      );
      form.change('prescriber_name', `${prescriber?.firstName} ${prescriber?.lastName}`);
      form.change('prescriber_tax_id', prescriber?.details?.taxId);
      form.change('prescriber_state_license_number', prescriber?.stateLicenses?.stateLicenseNumber);
      form.change('prescriber_licensing_state', prescriber?.stateLicenses?.stateIssuer);
      form.change('prescriber_ptan', prescriber?.details?.pTAN);
      form.change('prescriber_dea', prescriber?.details?.dEA);
      form.change('prescriber_email', prescriber?.email);
    });
  };
  const renderlabel = (option: PhysicianFragment): string => {
    return `${option.firstName} ${option.lastName} | ${option.npi}`;
  };
  if (organizationid && form.getState().values['patient_id'] && !patientdetailresult?.data) {
    return (
      <Form.Group as={Col} {...props.sizes}>
        <LoadingIndicator />
      </Form.Group>
    );
  }
  if (patientdetailresult?.data?.patientDetailByPatientID?.physician && !prescribersearch) {
    const prescriber = patientdetailresult?.data?.patientDetailByPatientID?.physician;
    form.change('prescriber_npi', prescriber?.npi || null);
    // form.change('prescriber_id', prescriber?.physicianId);
    // form.change('prescriber_first_name', prescriber?.firstName);
    // form.change('prescriber_middle_name', prescriber?.middleName);
    // form.change('prescriber_last_name', prescriber?.lastName);
    // form.change('prescriber_npi', prescriber?.npi);
    // form.change('prescriber_name', `${prescriber?.firstName} ${prescriber?.lastName}`);
    // form.change('prescriber_tax_id', prescriber?.details?.taxId);
    // form.change('prescriber_state_license_number', prescriber?.stateLicenses?.stateLicenseNumber);
    // form.change('prescriber_licensing_state', prescriber?.stateLicenses?.stateIssuer);
    // form.change('prescriber_ptan', prescriber?.details?.pTAN);
    // form.change('prescriber_dea', prescriber?.details?.dEA);
    // form.change('prescriber_email', prescriber?.email);
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldprops.label && <Form.Label className='text-uppercase'>{fieldprops.label}</Form.Label>}
      <div className='d-flex align-items-center'>
        <SearchInputWithRXGql
          asyncRxTypeaheadProps={{
            ...(props.asyncTypeaheadProps ?? {}),
            defaultSelected: form.getState().values['prescriber_name']
              ? getDefaultPrescriber(form.getState().values['prescriber_name'])
              : []
          }}
          searchRxIconLocation={SearchInputWithRXGqlIconLocation.append}
          rXdisplay={SearchInputWithRXGqlDisplay.outlined}
          labelRenderer={renderlabel}
          queryHook={usePhysiciansInfoLazyQuery}
          onItemSelect={onprescriberselect}
          placeholder={props.placeholder}
          isDisabled={fieldprops?.readonly}
          {...fieldprops}
        />
      </div>

      {displayRxErrorText(fieldprops)}
    </Form.Group>
  );
};

export const rxAdmincomponentMapper = {
  section: HcpSectionRxAdmin,
  spacer: HcpSpacerRxAdmin,
  [componentTypes.TIME_PICKER]: HCPDatePickerRxAdminWithTime,
  [componentTypes.TEXT_FIELD]: HcpInputRxAdminField,
  [componentTypes.SELECT]: HcpRxAdminSelectField,
  'prescriberRx-search': HcpRxAdminSearchOnlyPrescriber
};
