import React from 'react';
import { Row } from 'react-bootstrap';

import { rawSafeHtmlProps } from '../../utils';
import { ResourceContent } from '../multi-tenant';

export const ResourcesAdditionalResourcesTab = () => {
  return (
    <>
      <Row className='col-lg-7 col-sm-6'>
        <ResourceContent
          contentKey='resources.body'
          sourceDataKey='content'
          render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
        />
      </Row>
    </>
  );
};
