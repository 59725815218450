import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PatientDetailMainSection, HcpRequestStateUI, PrivateLayout } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import PrimarySidebar from '../components/PrimarySidebar';
import { PatientFragment, usePatientInfoQuery, useServiceRequestWithDocumentsInfoLazyQuery } from '../graphql';
import { AnyType, SelectedRequestInfo } from '../interfaces';
import { AppRootState, navigateToServiceRequestForPatient } from '../redux';
import { defaultDateFormatWithAge, extractCaseUrl, ROUTE_PATHS } from '../utils';
import { PatientProfileContextProvider } from '../components/patient-profile-context';

export const PatientProfileView = () => {
  const dispatch = useDispatch();
  const [selectedRequestInfo, setSelectedRequestInfo] = useState<SelectedRequestInfo | null>(null);
  const { id } = useParams<{ id: string }>();
  const { caseId } = useParams<{ caseId: string }>();
  const result = usePatientInfoQuery({ variables: { patientId: id } });
  const patient = result.data?.result;

  const patientName = [
    patient?.firstName?.trim() ?? '',
    patient?.middleName?.trim() ?? '',
    patient?.lastName?.trim() ?? ''
  ]
    .join(' ')
    .trim();

  const isError = result.error || patientName.length === 0;
  return (
    <PatientProfileContextProvider value={{ selectedRequestInfo, setSelectedRequestInfo }}>
      <PrivateLayout
        pageTitle='case details'
        breadcrumbs={[
          { label: 'PATIENTS', href: ROUTE_PATHS.patients },
          { label: patientName, href: ROUTE_PATHS.patients + '/' + patient?.id + '#summary' }
        ]}
        disableViewContainer={true}>
        <div className='cmx__case-detail-view-container h-100'>
          <PrimarySidebar />
          <PatientProfileComponent patient={patient} />
          <PatientDetailMainSection patient={patient} />
        </div>
      </PrivateLayout>
    </PatientProfileContextProvider>
  );
};

interface PatientProfileComponentProps {
  patient?: PatientFragment | null;
}

const PatientProfileComponent = ({ patient }: PatientProfileComponentProps) => {
  const history = useHistory();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);

  const [execQuery, result] = useServiceRequestWithDocumentsInfoLazyQuery();

  const cases = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['case-journey.manage' ?? ''] as AnyType)?.data?.enabled
  );
  useEffect(() => {
    if (!patient || !currentUser) return;

    execQuery({
      variables: {
        patientId: patient.id,
        organizationId: String(currentUser.OrganizationId),
        programId: String(currentUser.ProgramId)
      }
    });
  }, [execQuery, currentUser, patient]);

  const serviceRequest = result?.data?.result;

  const documents = serviceRequest
    ?.filter((sr) => sr && sr?.documents)
    ?.map((sr) => sr?.documents)
    ?.flat<DocumentFragment[]>();

  return (
    <HcpRequestStateUI
      errorUIEnabled
      isError={result.error}
      isLoading={result.loading}
      loadingUIEnabled
      reload={result.refetch}
      reloadingEnabled>
      <></>
    </HcpRequestStateUI>
  );
};
