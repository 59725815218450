import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';

export const CustomThemes = () => {
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  if (portal_ApplicationName) themeType(portal_ApplicationName);
};

export const themeType = (appName: string) => {
  import('../styles/main.scss');
  switch (appName) {
    case 'VerricaProviderPortal':
      import('../styles/styles-collate/verrica-styles.scss');
      break;
    case 'OrapharmaProviderPortal':
      import('../styles/styles-collate/oraPharma-styles.scss');
      break;
    case 'TaihoProviderPortal':
      import('../styles/styles-collate/taiho-styles.scss');
      break;
    case 'JanssencarepathProviderPortal':
      import('../styles/styles-collate/janssen-styles.scss');
      break;
    case 'BiogenbiosimilarsupportservicesProviderPortal':
      import('../styles/styles-collate/biogen-styles.scss');
      break;
    case 'ApellisassistgaProviderPortal':
      import('../styles/styles-collate/apellis-styles.scss');
      break;
    case 'demoproviderportal':
      import('../styles/styles-collate/demotechnica-styles.scss');
      break;
    case 'AccessPlusHCPPortal':
      import('../styles/styles-collate/accessPlus-styles.scss');
      break;
    default:
      import('../styles/styles-collate/default-styles.scss');
  }
};
