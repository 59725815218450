import React from 'react';
import { Card } from 'react-bootstrap';
import { ArrowRightShort } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { rawCleanHtmlProps, rawSafeHtmlProps, ROUTE_PATHS } from '../utils';
import { CustomThemes } from '../utils/custom-themes';

interface Actions extends Partial<Location> {
  label: string;
  target?: string;
}
type LinkAction = Array<Actions> | Actions;

interface ActionCardProps {
  title: string;
  description: string | JSX.Element;
  action: LinkAction;
  subtitle?: string;
}

export const ActionCard = ({ title, description, action, subtitle }: ActionCardProps) => {
  const tempAction: Array<Actions> = Array.isArray(action) ? action : [action];

  CustomThemes();

  if (!title && !subtitle && !description) return null;

  return (
    <Card border='primary' className='cmx__action-card h-100'>
      {title && (
        <Card.Header
          as='h5'
          className='font-weight-bold text-secondary bg-transparent text-uppercase border-0 pb-2'
          {...rawCleanHtmlProps(title)}
        />
      )}
      <Card.Body className='pt-1'>
        {subtitle && (
          <Card.Subtitle className='text-primary'>
            <h6 className='font-weight-bold' {...rawCleanHtmlProps(subtitle?.toString())} />
          </Card.Subtitle>
        )}
        {description && <div className='text-muted' {...rawSafeHtmlProps(description?.toString())} />}
      </Card.Body>
      {action && tempAction && (
        <Card.Footer className='bg-primary action__footer'>
          {tempAction.map((action, i) => {
            return (
              <>
                <Link
                  key={i}
                  to={{ ...action, pathname: action?.pathname ?? ROUTE_PATHS.home }}
                  replace
                  target={action?.target}>
                  <Card.Text
                    className='cmx__action-card__action-text text-uppercase text-right font-weight-bold mr-2'
                    {...rawCleanHtmlProps(action?.label)}
                  />
                </Link>
                {i < tempAction?.length - 1 && (
                  <p className='cmx__action-card__action-text text-right font-weight-bold mr-2 mb-0'>or</p>
                )}
              </>
            );
          })}
          <ArrowRightShort size={37} />
        </Card.Footer>
      )}
    </Card>
  );
};
