import React, { createContext } from 'react';

import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { noop } from 'lodash';
import Field from '@data-driven-forms/react-form-renderer/dist/cjs/field';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { AnyType } from '../../interfaces';

export interface WizardContextProps extends AnyObject {
  activePage: () => AnyObject;
  activeStepIndex?: number;
  crossroads?: AnyType;
  currentStep: Schema;
  fields?: Field[];
  formOptions: FormOptions;
  handleNext?: (nextStep: string) => void;
  handlePrev?: () => void;
  isDynamic?: boolean;
  jumpToStep?: (index: number, valid?: boolean) => void;
  maxStepIndex?: number;
  navSchema?: AnyObject;
  prevSteps?: string[];
  selectNext?: (nextStep: AnyType, getState: AnyType) => AnyObject;
  setPrevSteps?: () => void;
}

export const FormWizardPageChangeContext = createContext(noop);
export const WizardContext = createContext<WizardContextProps>({
  activePage: () => (<></>) as AnyType,
  currentStep: { fields: [] },
  formOptions: {} as FormOptions
});
