import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import {
  navigateToServiceRequest,
  navigateToHome,
  navigateToServiceRequestSelected,
  navigateToEnrollmentModule,
  AppRootState
} from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { EnrollmentModuleViewState } from '../views';
import { Drug } from '../api/portal-config.generated';
import { useTenancyDrugListSelector } from '../redux';
import { useParams } from 'react-router-dom';
import { AnyType } from '../interfaces';
import {
  usePatientInfoLazyQuery,
  PatientRequestViewModelFragment,
  useGetPatientDetailByIdLazyQuery,
  GRAPHQL_DEFAULT_CACHE,
  useOrganizationAddressInfoQuery
} from '../graphql';
export interface SelectService {
  currentService: any;
  patientId: any;
  prescriberId?: any | string;
  locationId?: any | string;
}
export const DaisyChainingButtons = (props: SelectService) => {
  const patientId = props?.patientId;
  const prescriberId: string = props?.prescriberId;
  const jh: number = props?.prescriberId;
  const locationId = props?.locationId;
  const dispatch = useDispatch();
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  const { caseDrugName } = useParams<{ caseDrugName: string }>();
  const organizationId: any = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const [patient, setPatient] = useState<PatientRequestViewModelFragment>();
  const [prescriber, setPrescriber] = useState<any>(null);
  const [defaultLocation, setDefaultLocation] = useState<any>(null);
  const [prescriberDetails, setDefaultPrescriber] = useState<any>(null);
  const [singleDrug, setSingleDrug] = useState<Drug | null | undefined>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [drug, setDrug] = useState<Drug | null | undefined>(null);
  const path = window.location.pathname;

  const [execPatientQuery, patientResult] = usePatientInfoLazyQuery({ variables: { patientId } });
  const enrollmentModuleIsEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );

  const { data, loading, refetch } = useOrganizationAddressInfoQuery({ variables: { organizationId } });

  const [execPatientDetailQuery, patientDetailResult] = useGetPatientDetailByIdLazyQuery({
    variables: { organization: parseInt(organizationId || ''), patientId: parseInt(patientId) || 0 },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });
  const isMultiDrugEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isMultiDrugEnabled'] as AnyType)?.data?.enabled
  );

  const onPatientSelect = useCallback((newPatient: PatientRequestViewModelFragment) => {
    if (!newPatient) return;

    setPrescriber(null);

    setPatient(newPatient);
    execPatientDetailQuery();
  }, []);

  const onPrescriberSelect = useCallback((newPrescriber: any) => {
    if (!newPrescriber) {
      setPrescriber(null);
      return;
    } else {
      setPrescriber(newPrescriber);
    }
  }, []);

  const loadDefaultPatient = useCallback(() => {
    if (!patientId) return;

    execPatientQuery({
      variables: {
        patientId
      }
    });
  }, [patientId, onPatientSelect]);

  useEffect(() => {
    const patientDetails = patientDetailResult?.data?.patientDetailByPatientID;
    if (patientDetails) {
      setDefaultPrescriber(patientDetails?.physician);
      onPrescriberSelect(patientDetails?.physician);
      setDefaultLocation(data?.result?.find((p: AnyType) => locationId?.toString() === p?.id));
    }
  }, [patientDetailResult]);

  useEffect(loadDefaultPatient, [loadDefaultPatient]);

  useEffect(() => {
    if (!patientResult?.data?.result) return;

    const newPatient: PatientRequestViewModelFragment = {
      actionAlertCount: 0,
      authorizationReceived: false,
      dob: patientResult.data.result.dob,
      firstName: patientResult.data.result.firstName,
      gender: patientResult.data.result.gender,
      lastName: patientResult.data.result.lastName,
      middleName: patientResult.data.result.middleName,
      patientId: patientResult.data.result.id,
      updateAlertCount: 0
    };
    onPatientSelect(newPatient);
  }, [patientResult, onPatientSelect]);

  useEffect(() => {
    if (!newDrugList) return;
    // Set the drug by default to the first item if there is only one drug
    if (newDrugList?.length === 1 && !isMultiDrugEnabled) {
      setSingleDrug(newDrugList[0]);
    }
  }, [newDrugList]);

  useEffect(() => {
    if (isDisabled) {
      const drug = newDrugList?.filter((drug: any) => drug?.DrugBrandName === caseDrugName);
      setDrug(drug?.[0]);
    }
  }, [isDisabled, newDrugList]);

  useEffect(() => {
    setIsDisabled(path?.includes('/case'));
  }, []);

  const redirectToEnrollmentModule = () => {
    buildPerformedServices('HUB Enrollment');
    execPatientDetailQuery();
    if (patientId && prescriberId) {
      const enrollmentRouteState: EnrollmentModuleViewState = {
        patientId: patientId,
        drug: drug || singleDrug || {},
        prescriber: prescriber,
        location: defaultLocation
      };
      dispatch(navigateToEnrollmentModule(enrollmentRouteState));
    }
  };

  const buildPerformedServices = (serviceName: string) => {
    const services = sessionStorage.getItem('performedServices');
    if (services?.length == 0 && services.indexOf(serviceName) === -1) {
      sessionStorage.setItem('performedServices', serviceName);
    } else {
      sessionStorage.setItem('performedServices', services?.toString() + ',' + props?.currentService);
    }
  };

  const clickOnSelecteService = () => {
    if (props.currentService === 'HUB Enrollment' && enrollmentModuleIsEnabled) {
      redirectToEnrollmentModule();
    } else {
      buildPerformedServices(props?.currentService);
      dispatch(navigateToServiceRequestSelected(props?.currentService, patientId, null, prescriberId, locationId));
    }
  };

  return (
    <>
      <br />
      <Row lg={2}>
        <Col lg={6}>
          <Button variant='secondary' size='sm' className='px-5 ml-3 lg=6' onClick={() => clickOnSelecteService()}>
            Proceed
          </Button>
        </Col>
        <Col lg={3}>
          <Button
            variant='secondary'
            size='sm'
            className='px-4 ml-5'
            onClick={() => {
              dispatch(navigateToServiceRequest());
            }}>
            START NEW REQUEST
          </Button>
        </Col>
        <Col lg={3}>
          <Button
            variant='outline-secondary'
            size='sm'
            className='px-5 ml-5'
            onClick={() => dispatch(navigateToHome())}>
            CLOSE
          </Button>
        </Col>
      </Row>
    </>
  );
};
