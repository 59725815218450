import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DisplayDetailComponent } from '../components/DisplayDetailComponent';
import { usePrescriptionByIdQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { clientApplicationNameSelector, DBFieldSchema } from '../utils';
import { tenancyFeatureSelector } from '../redux/selectors';
import { isEmptyObject } from '../utils/helpers';

interface PrescriptionDetailsInterface {
  prescriptionID: string;
  updateContent: number;
}

export const PrescriptionDetails = (props: PrescriptionDetailsInterface) => {
  const [prescriptionFields, setPrescriptionFields]: [DBFieldSchema, AnyType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const setPrescriptionFieldsSchemaSchema = async () => {
    const prescriptionFieldsSchema: AnyType = await useSelector<AnyType>(
      tenancyFeatureSelector('casedetails.prescription_intake')
    );
    if (prescriptionFieldsSchema) {
      const { fields } = await prescriptionFieldsSchema;
      setPrescriptionFields(fields);
    }
  };
  setPrescriptionFieldsSchemaSchema();

  useEffect(() => {
    if (!isEmptyObject(prescriptionFields)) {
      setIsLoading(false);
    }
  }, [prescriptionFields]);

  const prescriptionDetailsResult = usePrescriptionByIdQuery({ variables: { prescriptionId: props?.prescriptionID } });
  const prescriptionDetails = prescriptionDetailsResult?.data?.prescriptionById;
  const applicationName = useSelector(clientApplicationNameSelector);
  const defaultPharmacyModal = {
    prescriptionPharmacyId: null,
    prescriptionId: null,
    storeName: null,
    ncpdp: null,
    npi: null,
    addressline1: null,
    addressline2: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    fax: null,
    createdOn: null,
    createdBy: null,
    lastUpdated: null,
    lastUpdatedBy: null,
    __typename: null
  };

  const prescriptionProvider = prescriptionDetails?.prescriptionProviders?.find((p) => !p?.isSupervisingPrescriberinfo);
  const pharmacy =
    prescriptionDetails &&
    prescriptionDetails?.prescriptionPharmacy &&
    prescriptionDetails?.prescriptionPharmacy?.length > 0
      ? prescriptionDetails?.prescriptionPharmacy[0]
      : defaultPharmacyModal;
  const supervisingPrescriptionProvider = prescriptionDetails?.prescriptionProviders?.find(
    (p) => p?.isSupervisingPrescriberinfo
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDisplayValue = (value: any) => {
    if (!value) return '---';
    return value.toString() || '---';
  };

  useEffect(() => {
    refetchPrescription(prescriptionDetailsResult, props?.prescriptionID);
  }, [props.updateContent]);

  if (isLoading) {
    return <> </>;
  }

  return (
    <div>
      <div>
        <Row>
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.incoming_prescription_id?.label}
            hideField={prescriptionFields?.incoming_prescription_id?.hidefield}
            value={getDisplayValue(prescriptionDetails?.prescriptionId)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.prescription_source?.label}
            hideField={prescriptionFields?.prescription_source?.hidefield}
            value={getDisplayValue(prescriptionDetails?.prescriptionSource)}
          />
          {applicationName !== 'PhyzProviderPortal' ? (
            <DisplayDetailComponent
              size='4'
              label='Application Source'
              value={getDisplayValue(prescriptionDetails?.applicationSource)}
            />
          ) : null}
        </Row>
      </div>
      <div>
        <Row>
          <Col md='12'>
            <p className='section__title'>
              <p>Patient</p>
              <hr />
            </p>
          </Col>
        </Row>
        <Row>
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_first_name?.label}
            hideField={prescriptionFields?.patient_first_name?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientFirstName)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_middle_name?.label}
            hideField={prescriptionFields?.patient_middle_name?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientMiddleName)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_last_name?.label}
            hideField={prescriptionFields?.patient_last_name?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientLastName)}
          />
        </Row>
        <Row>
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_suffix?.label}
            hideField={prescriptionFields?.patient_suffix?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientSuffix)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_date_of_birth?.label}
            hideField={prescriptionFields?.patient_date_of_birth?.hidefield}
            value={
              prescriptionDetails?.patientDob
                ? moment.utc(getDisplayValue(prescriptionDetails?.patientDob)).format('YYYY-MM-DD')
                : getDisplayValue(prescriptionDetails?.patientDob)
            }
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_gender?.label}
            hideField={prescriptionFields?.patient_gender?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientGender)}
          />
        </Row>
        <Row>
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_address_1?.label}
            hideField={prescriptionFields?.patient_address_1?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientAddressLine1)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_address_2?.label}
            hideField={prescriptionFields?.patient_address_2?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientAddressLine2)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_zip?.label}
            hideField={prescriptionFields?.patient_zip?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientZipCode)}
          />
        </Row>
        <Row>
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_city?.label}
            hideField={prescriptionFields?.patient_city?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientCity)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_phone_1?.label}
            hideField={prescriptionFields?.patient_phone_1?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientPhoneNumber1)}
          />
          <DisplayDetailComponent
            size='4'
            label={prescriptionFields?.patient_phone_2?.label}
            hideField={prescriptionFields?.patient_phone_2?.hidefield}
            value={getDisplayValue(prescriptionDetails?.patientPhoneNumber2)}
          />
        </Row>
      </div>
      <div>
        <Row>
          <Col md='12'>
            <p className='section__title'>
              <p>Prescriber</p>
              <hr />
            </p>
          </Col>
        </Row>
        <div>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_first_name?.label}
              hideField={prescriptionFields?.prescriber_first_name?.hidefield}
              value={getDisplayValue(prescriptionProvider?.firstName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_middle_name?.label}
              hideField={prescriptionFields?.prescriber_middle_name?.hidefield}
              value={getDisplayValue(prescriptionProvider?.middleName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_last_name?.label}
              hideField={prescriptionFields?.prescriber_last_name?.hidefield}
              value={getDisplayValue(prescriptionProvider?.lastName)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_suffix?.label}
              hideField={prescriptionFields?.prescriber_suffix?.hidefield}
              value={getDisplayValue(prescriptionProvider?.suffix)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_npi?.label}
              hideField={prescriptionFields?.prescriber_npi?.hidefield}
              value={getDisplayValue(prescriptionProvider?.npi)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_dea?.label}
              hideField={prescriptionFields?.prescriber_dea?.hidefield}
              value={getDisplayValue(prescriptionProvider?.dea)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_clinic_name?.label}
              hideField={prescriptionFields?.prescriber_clinic_name?.hidefield}
              value={getDisplayValue(prescriptionProvider?.clinicName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_address_1?.label}
              hideField={prescriptionFields?.prescriber_address_1?.hidefield}
              value={getDisplayValue(prescriptionProvider?.addressLine1)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_address_2?.label}
              hideField={prescriptionFields?.prescriber_address_2?.hidefield}
              value={getDisplayValue(prescriptionProvider?.addressLine2)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_zip?.label}
              hideField={prescriptionFields?.prescriber_zip?.hidefield}
              value={getDisplayValue(prescriptionProvider?.zip)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_city?.label}
              hideField={prescriptionFields?.prescriber_city?.hidefield}
              value={getDisplayValue(prescriptionProvider?.city)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_state?.label}
              hideField={prescriptionFields?.prescriber_state?.hidefield}
              value={getDisplayValue(prescriptionProvider?.state)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_phone_1?.label}
              hideField={prescriptionFields?.prescriber_phone_1?.hidefield}
              value={getDisplayValue(prescriptionProvider?.phone1)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_phone_2?.label}
              hideField={prescriptionFields?.prescriber_phone_2?.hidefield}
              value={getDisplayValue(prescriptionProvider?.phone2)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_fax?.label}
              hideField={prescriptionFields?.prescriber_fax?.hidefield}
              value={getDisplayValue(prescriptionProvider?.fax)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.prescriber_email?.label}
              hideField={prescriptionFields?.prescriber_email?.hidefield}
              value={getDisplayValue(prescriptionProvider?.email)}
            />
          </Row>
        </div>
        <div>
          <div className='section__title'>
            <p>Supporting/Supervising Prescriber</p>
            <hr />
          </div>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_first_name?.label}
              hideField={prescriptionFields?.supervising_prescriber_first_name?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.firstName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_middle_name?.label}
              hideField={prescriptionFields?.supervising_prescriber_middle_name?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.middleName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_last_name?.label}
              hideField={prescriptionFields?.supervising_prescriber_last_name?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.lastName)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_suffix?.label}
              hideField={prescriptionFields?.supervising_prescriber_suffix?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.suffix)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_npi?.label}
              hideField={prescriptionFields?.supervising_prescriber_npi?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.npi)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_dea?.label}
              hideField={prescriptionFields?.supervising_prescriber_dea?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.dea)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_clinic_name?.label}
              hideField={prescriptionFields?.supervising_prescriber_clinic_name?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.clinicName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_address_1?.label}
              hideField={prescriptionFields?.supervising_prescriber_address_1?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.addressLine1)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_address_2?.label}
              hideField={prescriptionFields?.supervising_prescriber_address_2?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.addressLine2)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_zip?.label}
              hideField={prescriptionFields?.supervising_prescriber_zip?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.zip)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_city?.label}
              hideField={prescriptionFields?.supervising_prescriber_city?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.city)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_state?.label}
              hideField={prescriptionFields?.supervising_prescriber_state?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.state)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_phone_1?.label}
              hideField={prescriptionFields?.supervising_prescriber_phone_1?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.phone1)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_phone_2?.label}
              hideField={prescriptionFields?.supervising_prescriber_phone_2?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.phone2)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_fax?.label}
              hideField={prescriptionFields?.supervising_prescriber_fax?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.fax)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.supervising_prescriber_email?.label}
              hideField={prescriptionFields?.supervising_prescriber_email?.hidefield}
              value={getDisplayValue(supervisingPrescriptionProvider?.email)}
            />
          </Row>
        </div>
        <div>
          <div className='section__title'>
            <p>Therapy/Drug</p>
            <hr />
          </div>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_basis?.label}
              hideField={prescriptionFields?.drug_basis?.hidefield}
              value={getDisplayValue(prescriptionDetails?.drugBasis)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_dose_type?.label}
              hideField={prescriptionFields?.drug_dose_type?.hidefield}
              value={getDisplayValue(prescriptionDetails?.doseType)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_date_written?.label}
              hideField={prescriptionFields?.drug_date_written?.hidefield}
              value={
                prescriptionDetails?.dateWritten
                  ? moment.utc(getDisplayValue(prescriptionDetails?.dateWritten)).format('YYYY-MM-DD')
                  : getDisplayValue(prescriptionDetails?.dateWritten)
              }
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_drug_name?.label}
              hideField={prescriptionFields?.drug_drug_name?.hidefield}
              value={getDisplayValue(prescriptionDetails?.drugName)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_ndc?.label}
              hideField={prescriptionFields?.drug_ndc?.hidefield}
              value={getDisplayValue(prescriptionDetails?.ndc)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_sig?.label}
              hideField={prescriptionFields?.drug_sig?.hidefield}
              value={getDisplayValue(prescriptionDetails?.sig)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_quantity?.label}
              hideField={prescriptionFields?.drug_quantity?.hidefield}
              value={getDisplayValue(prescriptionDetails?.quantity)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_unit_of_measure?.label}
              hideField={prescriptionFields?.drug_unit_of_measure?.hidefield}
              value={getDisplayValue(prescriptionDetails?.unitofMeasure)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_days_supply?.label}
              hideField={prescriptionFields?.drug_days_supply?.hidefield}
              value={getDisplayValue(prescriptionDetails?.daysSupply)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_refills?.label}
              hideField={prescriptionFields?.drug_refills?.hidefield}
              value={getDisplayValue(prescriptionDetails?.refills)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_refills_qualifier?.label}
              hideField={prescriptionFields?.drug_refills_qualifier?.hidefield}
              value={getDisplayValue(prescriptionDetails?.refillQualifier)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_dispense_as_written?.label}
              hideField={prescriptionFields?.drug_dispense_as_written?.hidefield}
              value={getDisplayValue(prescriptionDetails?.daw)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_last_fill_date?.label}
              hideField={prescriptionFields?.drug_last_fill_date?.hidefield}
              value={
                prescriptionDetails?.lastFillDate
                  ? moment.utc(getDisplayValue(prescriptionDetails?.lastFillDate)).format('YYYY-MM-DD')
                  : getDisplayValue(prescriptionDetails?.lastFillDate)
              }
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_effective_date?.label}
              hideField={prescriptionFields?.drug_effective_date?.hidefield}
              value={
                prescriptionDetails?.rxEffectiveDate
                  ? moment.utc(getDisplayValue(prescriptionDetails?.rxEffectiveDate)).format('YYYY-MM-DD')
                  : getDisplayValue(prescriptionDetails?.rxEffectiveDate)
              }
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_diagnoses?.label}
              hideField={prescriptionFields?.drug_diagnoses?.hidefield}
              value={getDisplayValue(prescriptionDetails?.diagnoses)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_prior_auth_status?.label}
              hideField={prescriptionFields?.drug_prior_auth_status?.hidefield}
              value={getDisplayValue(prescriptionDetails?.priorAuthStatus)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_prior_auth_qual?.label}
              hideField={prescriptionFields?.drug_prior_auth_qual?.hidefield}
              value={getDisplayValue(prescriptionDetails?.priorAuthQual)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_prior_auth?.label}
              hideField={prescriptionFields?.drug_prior_auth?.hidefield}
              value={getDisplayValue(prescriptionDetails?.priorAuth)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.drug_substitution_permitted?.label}
              hideField={prescriptionFields?.drug_substitution_permitted?.hidefield}
              value={getDisplayValue(prescriptionDetails?.substitutionPermitted)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='12'
              label={prescriptionFields?.drug_note?.label}
              hideField={prescriptionFields?.drug_note?.hidefield}
              value={getDisplayValue(prescriptionDetails?.note)}
            />
          </Row>
        </div>
        <div key={pharmacy?.prescriptionPharmacyId}>
          <div className='section__title'>
            <p>Pharmacy</p>
            <hr />
          </div>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_store_name?.label}
              hideField={prescriptionFields?.pharmacy_store_name?.hidefield}
              value={getDisplayValue(pharmacy?.storeName)}
            />

            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_ncpdp?.label}
              hideField={prescriptionFields?.pharmacy_ncpdp?.hidefield}
              value={getDisplayValue(pharmacy?.ncpdp)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_npi?.label}
              hideField={prescriptionFields?.pharmacy_npi?.hidefield}
              value={getDisplayValue(pharmacy?.npi)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_address_1?.label}
              hideField={prescriptionFields?.pharmacy_address_1?.hidefield}
              value={getDisplayValue(pharmacy?.addressline1)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_address_2?.label}
              hideField={prescriptionFields?.pharmacy_address_2?.hidefield}
              value={getDisplayValue(pharmacy?.addressline2)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_zip?.label}
              hideField={prescriptionFields?.pharmacy_zip?.hidefield}
              value={getDisplayValue(pharmacy?.zip)}
            />
          </Row>
          <Row>
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_city?.label}
              hideField={prescriptionFields?.pharmacy_city?.hidefield}
              value={getDisplayValue(pharmacy?.city)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_phone?.label}
              hideField={prescriptionFields?.pharmacy_phone?.hidefield}
              value={getDisplayValue(pharmacy?.phone)}
            />
            <DisplayDetailComponent
              size='4'
              label={prescriptionFields?.pharmacy_fax?.label}
              hideField={prescriptionFields?.pharmacy_fax?.hidefield}
              value={getDisplayValue(pharmacy?.fax)}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export const refetchPrescription = (caseResult: AnyType, prescriptionId: string) => {
  const queryVars = { prescriptionId: prescriptionId };
  caseResult.refetch(queryVars);
};
