import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import { StepNames } from '.';
import { FIELD_NAMES } from '../../field-names';
import {
  DBFormSchema,
  fieldScrollSection,
  headerSection,
  inlineCheckBoxWithContent,
  spacerSection,
  inlineCheckBoxWithContentAndHyperLink
} from '../field-helpers';
import { signatureSection } from '../fields/signature-fields';

const { prescriberAttestationFields } = FIELD_NAMES;

export const prescriberAttestationTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const prescriberAttestationTab = schema?.prescriber_attestation_tab;
  if (!prescriberAttestationTab || prescriberAttestationTab?.hideTab) return null;
  return {
    title: prescriberAttestationTab?.label ?? 'Prescriber Attestation & Signature',
    name: StepNames.PRESCRIBER_ATTESTATION,
    nextStep: prescriberAttestationTab?.nextStep ?? nextStep,
    fields: [
      spacerSection('prescriber_attestation_18', { sizes: { sm: 12 } }),
      // PRESCRIBER ATTESTATION
      headerSection(
        schema?.pa_attestation_acknowledgement_header?.label,
        schema?.pa_attestation_acknowledgement_header
      ),
      fieldScrollSection(
        prescriberAttestationFields.certifiedText,
        schema?.[prescriberAttestationFields.certifiedText],
        {
          sizes: { md: 12 }
        }
      ),
      spacerSection('prescriber_attestation_19', { sizes: { sm: 12 } }),
      inlineCheckBoxWithContent(
        prescriberAttestationFields.isCertified,
        schema?.[prescriberAttestationFields.isCertified],
        {
          sizes: { md: 12 }
        }
      ),
      spacerSection('prescriber_attestation_19_1', { sizes: { sm: 12 } }),
      inlineCheckBoxWithContentAndHyperLink(
        prescriberAttestationFields.isCertified3,
        schema?.[prescriberAttestationFields.isCertified3],
        {
          sizes: { md: 12 }
        }
      ),
      spacerSection('prescriber_attestation_19_2', { sizes: { sm: 12 } }),
      inlineCheckBoxWithContent(
        prescriberAttestationFields.isCertified2,
        schema?.[prescriberAttestationFields.isCertified2],
        {
          sizes: { md: 12 }
        }
      ),
      spacerSection('prescriber_attestation_20', { sizes: { sm: 12 } }),
      signatureSection(
        schema,
        prescriberAttestationFields.prescriberSigningAttestation,
        prescriberAttestationFields.signature,
        prescriberAttestationFields.signatureDate,
        ''
      ),
      spacerSection('prescriber_attestation_21', { sizes: { sm: 12 } })
    ].filter(Boolean)
  };
};
