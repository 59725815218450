import React from 'react';

import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import { Col, Row } from 'react-bootstrap';
import { FORM_ERROR } from 'final-form';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';

import { HcpFormTemplate, HcpFormRenderer, HcpButton, FeatureGuard } from '.';
import { useLinkPatient, GATEWAY_API_URL } from '../api';
import { useCreatePatient } from '../api/add-patient.generated';
import { AppRootState, navigateToHome, navigateToServiceRequestForPatient } from '../redux';
import { getAddPatientPayload, getPatientFormSchema } from '../utils';

interface RequestAddPatientProps {
  setShown?: () => void;
  setServiceAddPatientView?: boolean;
}

export const RequestAddPatient = ({ setShown, setServiceAddPatientView }: RequestAddPatientProps) => {
  const dispatch = useDispatch();
  const isJanssen = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? '')) == '127';
  const defaultError = 'There was a problem adding a patient. Please contact support.';
  const user = useSelector((state: AppRootState) => state.auth.session?.user);
  const { mutate: linkPatient } = useLinkPatient({});
  const { mutate: addPatient } = useCreatePatient({
    base: GATEWAY_API_URL
  });

  const onSubmit = async (values: AnyObject) => {
    try {
      values['isJanssen'] = isJanssen;
      const { Patient, errors: addPatientErrors } = await addPatient(getAddPatientPayload(values));
      if (addPatientErrors && addPatientErrors.length > 0) {
        const errors = addPatientErrors.map((e) => e.description).join(' ');
        return { [FORM_ERROR]: errors ?? defaultError };
      }

      const { errors: linkPatientErrors } = await linkPatient({
        patientId: Number(Patient?.PatientID)
      });

      if (linkPatientErrors && linkPatientErrors.length > 0) {
        const errors = linkPatientErrors.map((e) => e.description).join(' ');
        return { [FORM_ERROR]: errors ?? defaultError };
      }

      if (!values.redirect_home && Patient?.PatientID) {
        dispatch(navigateToServiceRequestForPatient(String(Patient?.PatientID)));
        return;
      }

      setShown ? setShown() : dispatch(navigateToHome());
    } catch (e) {
      return { [FORM_ERROR]: e.message ?? defaultError };
    }
  };

  return (
    <HcpFormRenderer
      contentKey='form.patient'
      FormTemplate={setServiceAddPatientView ? AddSelectServicePatientTemplate : AddPatientFormTemplate}
      onCancel={() => {
        setShown ? setShown() : dispatch(navigateToHome());
      }}
      onSubmit={onSubmit}
      schemaMethod={getPatientFormSchema}
    />
  );
};

const AddSelectServicePatientTemplate = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...(props as FormTemplateRenderProps)}
      renderControls={(formProps: FormOptions) => {
        const { submitError, submitting, hasValidationErrors, values } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row>
              <Col xs={12} md={6} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                <HcpButton
                  disabled={submitting || hasValidationErrors}
                  type='submit'
                  variant='secondary'
                  className='mb-2 mb-lg-0 mr-lg-2'
                  onClick={() => formProps.change('redirect_home', false)}
                  data-testid='cmx__add-patient-and-start-request-button'
                  loading={submitting && !values.redirect_home}>
                  {submitting && !values.redirect_home ? 'SUBMITTING...' : 'ADD PATIENT AND SELECT'}
                </HcpButton>
              </Col>

              <Col xs={12} md={6} className='d-flex justify-content-md-end'>
                <HcpButton
                  disabled={submitting}
                  variant='transparent'
                  className='text-danger mt-3 mt-md-0'
                  onClick={formProps.onCancel}>
                  CANCEL
                </HcpButton>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
const AddPatientFormTemplate = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...(props as FormTemplateRenderProps)}
      renderControls={(formProps: FormOptions) => {
        const { submitError, submitting, hasValidationErrors, values } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row>
              <Col xs={12} md={6} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                <FeatureGuard contentKey='service-request.manage'>
                  <HcpButton
                    disabled={submitting || hasValidationErrors}
                    type='submit'
                    variant='secondary'
                    className='mb-2 mb-lg-0 mr-lg-2'
                    onClick={() => formProps.change('redirect_home', false)}
                    data-testid='cmx__add-patient-and-start-request-button'
                    loading={submitting && !values.redirect_home}>
                    {submitting && !values.redirect_home ? 'SUBMITTING...' : 'ADD PATIENT AND START A REQUEST'}
                  </HcpButton>
                </FeatureGuard>

                <HcpButton
                  disabled={submitting || hasValidationErrors}
                  type='submit'
                  variant='outline-secondary'
                  className='mt-2 mt-lg-0 ml-lg-2'
                  onClick={() => formProps.change('redirect_home', true)}
                  loading={submitting && values.redirect_home}>
                  {submitting && values.redirect_home ? 'SUBMITTING...' : 'SAVE PATIENT AND CLOSE'}
                </HcpButton>
              </Col>

              <Col xs={12} md={6} className='d-flex justify-content-md-end'>
                <HcpButton
                  disabled={submitting}
                  variant='transparent'
                  className='text-danger mt-3 mt-md-0'
                  onClick={formProps.onCancel}>
                  CANCEL
                </HcpButton>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
