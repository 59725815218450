import React from 'react';

import { Col, Form } from 'react-bootstrap';
import { useField, useForm } from 'react-final-form';
import { boolean, number, object, string } from 'yup';

import { FIELD_NAMES, NOT_REQUIRED, validations } from '../utils';
import { AnyType } from '../interfaces';
import { NpiSearchProviderField, StatesSelect, TaxIdField, TextField } from '.';
import { ProviderNpiFragment } from '../graphql';

const { prescriberFields } = FIELD_NAMES;
const validateForConditional = (schema: AnyType, schemaType: AnyType = string()) =>
  validations.conditionalValidation('new_prescriber', schema, true, schemaType);

export const prescriberInfoSchema = object({
  new_prescriber: boolean().default(false),
  [prescriberFields.id]: validations.conditionalValidation(
    'new_prescriber',
    string().required('Select or add a prescriber'),
    false
  ),
  [prescriberFields.firstName]: validateForConditional(validations.nameValidation().label('First Name').nullable()),
  [prescriberFields.lastName]: validateForConditional(validations.nameValidation().label('Last Name').nullable()),
  [prescriberFields.middleName]: validateForConditional(
    validations.nameValidation(NOT_REQUIRED).label('Middle Name').nullable()
  ),
  [prescriberFields.npi]: validateForConditional(validations.npiValidation().nullable(), number()),
  [prescriberFields.taxId]: validateForConditional(validations.taxIdValidation(NOT_REQUIRED).notRequired().nullable()),
  [prescriberFields.stateLicenseNumber]: validateForConditional(
    string().notRequired().label('State License #').nullable()
  ),
  [prescriberFields.licensingState]: validations.conditionalValidation(
    prescriberFields.stateLicenseNumber,
    string().required().label('Licensing State').nullable(),
    (stateNumber: string) => !!stateNumber
  )
});

export const PrescribersInformationFields = () => {
  const form = useForm();
  const stateLicenseInput = useField(prescriberFields.stateLicenseNumber);

  const onNpiSelect = (npi: ProviderNpiFragment) => {
    let stateLicense;

    if (npi?.stateLicenses && npi?.stateLicenses?.length > 0) {
      stateLicense = npi.stateLicenses[0];
    }

    form.change(prescriberFields.npi, Number(npi?.npi) || null);
    npi?.firstName && form.change(prescriberFields.firstName, npi.firstName);
    npi?.middleName && form.change(prescriberFields.middleName, npi.middleName);
    npi?.lastName && form.change(prescriberFields.lastName, npi.lastName);
    stateLicense?.licenseNumber && form.change(prescriberFields.stateLicenseNumber, stateLicense.licenseNumber);
    stateLicense?.stateCode && form.change(prescriberFields.licensingState, stateLicense.stateCode);
    npi?.taxId && form.change(prescriberFields.taxId, npi.taxId);
  };

  return (
    <>
      <Form.Row>
        <Form.Group lg={3} md={3} as={Col} controlId={prescriberFields.npi}>
          <NpiSearchProviderField label='PRESCRIBER NPI' fieldName={prescriberFields.npi} onNpiSelect={onNpiSelect} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group md={4} as={Col} controlId={prescriberFields.firstName}>
          <TextField name={prescriberFields.firstName} label='FIRST NAME' data-testid='cmx__prescriber-first-name' />
        </Form.Group>
        <Form.Group md={4} as={Col} controlId={prescriberFields.middleName}>
          <TextField
            name={prescriberFields.middleName}
            label='MIDDLE NAME'
            placeholder='optional'
            data-testid='cmx__prescriber-middle-name'
          />
        </Form.Group>
        <Form.Group md={4} as={Col} controlId={prescriberFields.lastName}>
          <TextField name={prescriberFields.lastName} label='LAST NAME' data-testid='cmx__prescriber-last-name' />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group md={4} as={Col} controlId={prescriberFields.taxId}>
          <TaxIdField
            name={prescriberFields.taxId}
            label='TAX ID # (TIN)'
            placeholder='optional'
            data-testid='cmx__prescriber-tax-id'
          />
        </Form.Group>
        <Form.Group md={4} as={Col} controlId={prescriberFields.stateLicenseNumber}>
          <TextField
            name={prescriberFields.stateLicenseNumber}
            label='STATE LICENSE #'
            placeholder='optional'
            data-testid='cmx__prescriber-state-license-number'
          />
        </Form.Group>
        <Form.Group md={4} as={Col} controlId={prescriberFields.licensingState}>
          <StatesSelect
            name={prescriberFields.licensingState}
            label='LICENSING STATE'
            disabled={!stateLicenseInput.input.value}
            data-testid='cmx__prescriber-licensing-state'
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};
