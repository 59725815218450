import { AgGridColumn } from 'ag-grid-react';
import { AnyObject } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Dropdown, Row } from 'react-bootstrap';
import { HcpGrid } from './HcpGrid';
import { List } from 'react-bootstrap-icons';
import { CaseDetail, CaseTransaction, CaseMileStone } from '../graphql';
import { featureGridColumnsSelector, getFeatureGridColumnProps } from '../utils';
import { useSelector } from 'react-redux';
import { DocumentUpload } from './DocumentUpload';
import { ICellRendererParams } from 'ag-grid-community';
export interface CaseMileStoneSelectionTableProps {
  caseDetails?: CaseTransaction[];
  caseMileStones?: CaseMileStone[];
  gridColumnFeatureKey?: string;
  buttonName?: string;
}
const columnSelector = featureGridColumnsSelector('caseMileStone.table');

export const CaseMileStoneSelectionTable = ({
  caseDetails,
  caseMileStones,
  gridColumnFeatureKey
}: CaseMileStoneSelectionTableProps) => {
  const CellButton = (params: ICellRendererParams) => {
    return (
      <>
        <DocumentUpload buttonAlign='left' caseDetail={params.data} buttonName='Select' />
      </>
    );
  };
  const CellMileStoneType = (params: ICellRendererParams) => {
    const value = caseMileStones?.filter((element: any) => element?.mileStoneCode === params.data?.caseMileStoneCode);
    if (value) {
      return <> {value?.[0]?.mileStoneName}</>;
    }
  };

  const frameworkComponents = {
    CellButton: CellButton,
    CellMileStoneType: CellMileStoneType
  };

  const rowheight = () => {
    return 110;
  };

  let columns = useSelector(columnSelector);
  if (gridColumnFeatureKey) {
    const pageSpecificColumnSelector = featureGridColumnsSelector(gridColumnFeatureKey);
    const pageSpecificColumns = useSelector(pageSpecificColumnSelector);
    columns = pageSpecificColumns ? { ...columns, ...pageSpecificColumns } : columns;
  }

  if (!columns) return null;
  return (
    <>
      <p>
        <i>
          To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
          criteria.
        </i>
      </p>
      <HcpGrid
        rowData={caseDetails ?? []}
        pagination
        frameworkComponents={frameworkComponents}
        getRowHeight={rowheight}>
        <AgGridColumn headerName='CASE MILESTONE' cellRenderer='CellMileStoneType' minWidth={200} />
        <AgGridColumn
          headerName='Action'
          cellRenderer='CellButton'
          autoHeight={true}
          minWidth={425}
          cellStyle={{ textAlign: 'center' }}
        />
      </HcpGrid>
    </>
  );
};
