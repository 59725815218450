import React, { FC, useContext } from 'react';
import { Accordion, AccordionContext, Card, Col, Form, Row } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { dateFormatter } from '../utils';

export type PATabProps = {
  pas?: AnyType;
  orgnizationId?: number;
};

export const PATabCollapsableSection = (paProps: PATabProps) => {
  const paFields = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['case-details.pa'] as AnyType)?.data?.fields || {}
  );

  type PAListType = { pAType: string; pACreatedDate: string; pas: AnyType[] };
  const pAList: AnyType[] = paProps?.pas || [];

  interface Props {
    children: React.ReactNode;
    eventKey: string;
  }

  const ContextAwareToggle: FC<Props> = (props: Props) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        className='cmx__case-detail-main-section-summary-tab__card-header'
        eventKey={props.eventKey}>
        <div>{props.children}</div>
        <hr />
        {isCurrentEventKey && <ChevronUp />}
        {!isCurrentEventKey && <ChevronDown />}
      </Accordion.Toggle>
    );
  };

  const FieldHeaderCol = ({ fieldName }: { fieldName: string }) => (
    <Col className={paFields?.[fieldName]?.hideField ? 'd-none' : 'md-'}>
      <Form.Label className='headerColor'>{paFields?.[fieldName]?.label}</Form.Label>
    </Col>
  );

  const FieldValueCol = ({ pa, fieldName, isDate }: { pa: AnyType; fieldName: string; isDate?: boolean }) => (
    <Col className={paFields?.[fieldName]?.hideField ? 'd-none' : ''}>
      <p className='text-gray'>{isDate ? dateFormatter(pa?.[fieldName]) : pa?.[fieldName]}</p>
    </Col>
  );

  const IndividualPACollapsableSection = ({ pAType, pACreatedDate, pas }: PAListType) => (
    <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
      <Card className='cmx__case-detail-main-section-summary-tab__card'>
        <ContextAwareToggle eventKey='4'>
          <strong>{`PRIOR AUTHORIZATION - ${pAType} - ${dateFormatter(pACreatedDate)}`.toUpperCase()}</strong>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey='4'>
          <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
            <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
              <Card className='cmx__case-detail-main-section-summary-tab__card'>
                <ContextAwareToggle eventKey='4'>
                  <strong>{'PA DETAILS'.toUpperCase()}</strong>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                    <Row>
                      <FieldHeaderCol fieldName='therapyOrDrug' />
                      <FieldHeaderCol fieldName='pAType' />
                      <FieldHeaderCol fieldName='daysToCompletePAReview' />
                      <FieldHeaderCol fieldName='pAOnFile' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-second-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='therapyOrDrug' />
                          <FieldValueCol pa={pa} fieldName='pAType' />
                          <FieldValueCol pa={pa} fieldName='daysToCompletePAReview' />
                          <FieldValueCol pa={pa} fieldName='pAOnFile' />
                        </Row>
                      ))}
                    <br />
                    <Row>
                      <FieldHeaderCol fieldName='pASubmissionDate' />
                      <FieldHeaderCol fieldName='pAStatus' />
                      <FieldHeaderCol fieldName='authorizationNumber' />
                      <FieldHeaderCol fieldName='pAExpiresOn' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-third-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='pASubmissionDate' isDate={true} />
                          <FieldValueCol pa={pa} fieldName='pAStatus' />
                          <FieldValueCol pa={pa} fieldName='authorizationNumber' />
                          <FieldValueCol pa={pa} fieldName='pAExpiresOn' isDate={true} />
                        </Row>
                      ))}
                    <br />
                    <Row>
                      <FieldHeaderCol fieldName='denialReason' />
                      <FieldHeaderCol fieldName='otherDenialReason' />
                      <FieldHeaderCol fieldName='pAApprovalDate' />
                      <FieldHeaderCol fieldName='pADenialDate' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-fourth-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='denialReason' />
                          <FieldValueCol pa={pa} fieldName='otherDenialReason' />
                          <FieldValueCol pa={pa} fieldName='pAApprovalDate' isDate={true} />
                          <FieldValueCol pa={pa} fieldName='pADenialDate' isDate={true} />
                        </Row>
                      ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
              <Card className='cmx__case-detail-main-section-summary-tab__card'>
                <ContextAwareToggle eventKey='4'>
                  <strong>{'PA REQUIREMENTS'.toUpperCase()}</strong>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                    <Row>
                      <FieldHeaderCol fieldName='pARequirements' />
                      <FieldHeaderCol fieldName='stepTherapyRequirements' />
                      <FieldHeaderCol fieldName='otherPARequirements' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-fifth-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='pARequirements' />
                          <FieldValueCol pa={pa} fieldName='stepTherapyRequirements' />
                          <FieldValueCol pa={pa} fieldName='otherPARequirements' />
                        </Row>
                      ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey={'0'} className='cmx__case-detail-main-section-summary-tab'>
              <Card className='cmx__case-detail-main-section-summary-tab__card'>
                <ContextAwareToggle eventKey='4'>
                  <strong>{'ADDITIONAL FIELDS'.toUpperCase()}</strong>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='cmx__case-detail-main-section-summary-tab__card-body'>
                    <Row>
                      <FieldHeaderCol fieldName='additionalComments' />
                      <FieldHeaderCol fieldName='ePAStatus' />
                      <FieldHeaderCol fieldName='referenceKey' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-sixth-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='additionalComments' />
                          <FieldValueCol pa={pa} fieldName='ePAStatus' />
                          <FieldValueCol pa={pa} fieldName='referenceKey' />
                        </Row>
                      ))}
                    <Row>
                      <FieldHeaderCol fieldName='providerPAWebsite' />
                    </Row>
                    {pas &&
                      pas?.length > 0 &&
                      pas?.map((pa: AnyType, index: number) => (
                        <Row key={'pa-tab-collapsable-sec-data-sixth-row-' + index}>
                          <FieldValueCol pa={pa} fieldName='providerPAWebsite' />
                        </Row>
                      ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  return (
    <div>
      {pAList?.map((pa: AnyType, index: number) => (
        <IndividualPACollapsableSection
          key={pa?.pAType + index}
          pAType={pa?.pAType}
          pACreatedDate={pa?.pACreatedDate}
          pas={[pa]}
        />
      ))}
    </div>
  );
};
