import { Field } from '@data-driven-forms/react-form-renderer';

import {
  DBFormSchema,
  fieldSignature,
  inlineCheckBoxWithContent,
  plainSignature,
  plainSignatureDate,
  fieldAdministrationInfo
} from '../field-helpers';

export const signatureSection = (
  schema: DBFormSchema,
  signatureAttestationFieldName: string,
  signatureFieldName: string,
  signatureDateFieldName: string,
  physicianSignatureTypeFieldName: string
): Field[] => [
  inlineCheckBoxWithContent(signatureAttestationFieldName, schema?.[signatureAttestationFieldName], {
    sizes: { md: 12 },
    id: signatureAttestationFieldName
  }),
  fieldAdministrationInfo(physicianSignatureTypeFieldName, schema?.[physicianSignatureTypeFieldName], {
    sizes: { md: 12 }
  }),
  fieldSignature(signatureFieldName, schema?.[signatureFieldName], {
    sizes: { md: 5 }
  }),
  plainSignatureDate(signatureDateFieldName, schema?.[signatureDateFieldName], {
    sizes: { md: 3 }
  })
];

export const signatureSectionReadonly = (
  schema: DBFormSchema,
  signatureAttestationFieldName: string,
  signatureFieldName: string,
  signatureDateFieldName: string
): Field[] => [
  inlineCheckBoxWithContent(signatureAttestationFieldName, schema?.[signatureAttestationFieldName], {
    sizes: { md: 12 },
    id: signatureAttestationFieldName,
    disabled: 'true'
  }),
  plainSignature(signatureFieldName, schema?.[signatureFieldName], {
    sizes: { md: 5 }
  }),
  plainSignatureDate(signatureDateFieldName, schema?.[signatureDateFieldName], {
    sizes: { md: 3 },
    isreadonly: true
  })
];
