import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useEffect, useCallback, useImperativeHandle } from 'react';
import { List } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { HcpGrid } from '.';
import {
  MIN_UPDATED_DATE,
  dobDateColumnFormat,
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  localDateFormat,
  rxAdminActionColumnDefinition
} from '../utils';
import { rxAdminDoseList, useGetRxAdminList } from '../api';
import { AppRootState } from '../redux';
interface RxAdminTableProps {
  patientId?: number;
  onUpdated?: () => void;
}
const columnSelector = featureGridColumnsSelector('rxadmin.table');
export const RxAdminTable = React.forwardRef(({ patientId, onUpdated }: RxAdminTableProps, ref) => {
  let rxAdminData = [];
  const columns = useSelector(columnSelector ?? []);
  const updatedBy = useSelector((state: AppRootState) => state?.auth?.session?.userDetails?.Login);
  const { id }: { id: string } = useParams();
  const { data, refetch: getRxAdminDoseLists } = useGetRxAdminList({ lazy: true });
  const fetchRxAdminDose = useCallback(async () => {
    if (!id) return;
    await getRxAdminDoseLists({ queryParams: { patientId: parseInt(id) } });
  }, [id, data]);

  useImperativeHandle(
    ref,
    () => {
      return {
        invalidateTable: fetchRxAdminDose
      };
    },
    [fetchRxAdminDose]
  );

  useEffect(() => {
    fetchRxAdminDose();
    if (!data) return;
  }, [id]);

  const prepareUpdatedDate = (createdDate: string | null, updatedDate: string | null): string | null => {
    if (!createdDate && !updatedDate) return '';
    return moment(updatedDate).isAfter(MIN_UPDATED_DATE) ? localDateFormat(updatedDate) : localDateFormat(createdDate);
  };
  const prepareRxAdminColumn = (values: rxAdminDoseList[]) => {
    if (values?.length > 0) {
      return values?.map((dt: rxAdminDoseList) => {
        return {
          adminDate: localDateFormat(dt?.adminDate),
          dose: dt?.adminDose,
          adminNotes: dt?.adminNotes,
          status: dt?.adminStatus,
          source: dt?.adminSource,
          updatedDate: prepareUpdatedDate(dt?.createdDate, dt?.updatedDate),
          product: dt?.drugName?.toUpperCase(),
          updatedBy: updatedBy,
          date: dt?.adminDate, //for full date and timestamp
          id: dt?.patient?.id, // for edit
          prescriber: dt?.prescriber,
          prescriberId: dt?.prescriberId,
          npi: dt?.npi
        };
      });
    }
    return [];
  };
  rxAdminData = prepareRxAdminColumn(data?.rxAdminDoseList || []);
  if (!columns) return null;
  return (
    <>
      <p>
        <i>
          To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
          criteria.
        </i>
      </p>
      <HcpGrid rowData={rxAdminData ?? []} pagination>
        <AgGridColumn field='dose' {...getFeatureGridColumnProps(columns.dose)} />
        <AgGridColumn
          field='adminDate'
          {...dobDateColumnFormat('adminDate')}
          {...getFeatureGridColumnProps(columns.adminDate)}
        />
        <AgGridColumn field='status' {...getFeatureGridColumnProps(columns.status)} />
        <AgGridColumn field='product' {...getFeatureGridColumnProps(columns.product)} />
        <AgGridColumn field='source' {...getFeatureGridColumnProps(columns.source)} />
        <AgGridColumn field='updatedDate' {...getFeatureGridColumnProps(columns.updatedDate)} />
        <AgGridColumn field='updatedBy' {...getFeatureGridColumnProps(columns.updatedBy)} />
        <AgGridColumn
          {...rxAdminActionColumnDefinition()}
          headerComponentParams={{ invalidateTable: fetchRxAdminDose, invalidateAddRxAdmin: onUpdated }}
        />
      </HcpGrid>
    </>
  );
});
