import { RowNode } from 'ag-grid-community';
import React from 'react';
import { EnvelopeFill, Eye, FileText } from 'react-bootstrap-icons';
import { Maybe, ServiceRequestViewFragment } from '../graphql';
import {
  ActionColumnActions,
  actionsColumnDefinition,
  globalDefaultColDefs,
  nextStepColumnDefinition,
  prescriberColumnDefinition,
  ROUTE_PATHS,
  serviceRequestAlertColumnDefinition,
  dateColumnDefinition,
  idColumnDefinition
} from '../utils';
import { HcpGrid } from '.';

interface ActionItemTableProps {
  serviceRequests?: Maybe<
    ReadonlyArray<Maybe<{ readonly __typename?: 'ServiceRequestViewModel' } & ServiceRequestViewFragment>>
  >;
}

export const ActionItemTableColumnDefs = [
  { ...serviceRequestAlertColumnDefinition() },
  { headerName: 'REQUEST TYPE', field: 'requestType' },
  { ...idColumnDefinition('id', 'REQUEST ID') },
  { ...dateColumnDefinition('requestStartDate', 'SUBMITTED') },
  { ...prescriberColumnDefinition() },
  { headerName: 'STATUS', field: 'statusText' },
  { ...nextStepColumnDefinition() },
  {
    ...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
      {
        href: `${ROUTE_PATHS.requests}/${node.data.id}`,
        label: 'View Request',
        icon: Eye
      },
      {
        href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
        label: 'Start New Request',
        icon: FileText
      },
      {
        href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}&request=${node.data.id}#compose`,
        label: 'Send a Message',
        icon: EnvelopeFill
      }
    ])
  }
];

export const ActionItemTable = ({ serviceRequests }: ActionItemTableProps) => {
  const actionItems = serviceRequests?.filter((sr) => (sr?.updateAlert || sr?.actionAlert) && sr?.displayOnGrid !== 0);

  return (
    <HcpGrid
      columnDefs={ActionItemTableColumnDefs}
      rowData={actionItems}
      defaultColDef={globalDefaultColDefs}
      pagination
    />
  );
};
