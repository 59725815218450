import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  ArrowLeftCircleFill,
  ArrowLeftSquareFill,
  ArrowRightSquareFill,
  ChevronDown,
  ChevronRight,
  ReplyFill
} from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { MessageReply, MessageReplyProps } from '.';
import { Message, MessageFile, MessageThread } from '../api';
import { useBinaryObjectUrl } from '../hooks';
import { HcpModal } from './HcpModal';
import { INCOMING_MESSAGE, OUTGOING_MESSAGE, REPLY } from '../utils';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';

interface MessageStepProps {
  canReply?: boolean;
  isMessageOpen?: boolean;
  replyProps: Partial<MessageReplyProps>;
  thread: MessageThread | null;
  threadMessage: Message | null;
}

export const MessageStep = (props: MessageStepProps) => {
  const { hash } = useLocation();
  const openByDefault = hash === '#reply';
  const [isMessageShown, setShowMessage] = useState(props.isMessageOpen);
  const [isReplyShown, setIsReplyShown] = useState(openByDefault);
  const files: MessageFile[] | null | undefined = props.threadMessage?.files;
  const isJanssen = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? '')) == '127';

  const onShowClick = useCallback(() => setShowMessage((shown) => !shown), [setShowMessage]);
  const onReplyToggle = useCallback(() => setIsReplyShown((shown) => !shown), [setIsReplyShown]);

  const CaretIcon = isMessageShown ? ChevronDown : ChevronRight;
  let DirectionIcon;
  let directionIconColorClass;
  let dividerColorClass;
  let to;
  let from;
  let tooltip;

  if (props.threadMessage?.isSentFromCurrentUser) {
    DirectionIcon = ArrowLeftSquareFill;
    directionIconColorClass = 'text-gray';
    dividerColorClass = 'text-gray-dark';
    if (!isJanssen) {
      to = props.thread?.assignedTo;
    }
    from = props.threadMessage?.sender;
    tooltip = OUTGOING_MESSAGE;
  } else {
    DirectionIcon = ArrowRightSquareFill;
    directionIconColorClass = 'text-primary';
    dividerColorClass = 'border-primary';
    if (!isJanssen) {
      from = props.thread?.assignedTo;
    }
    to = props.threadMessage?.sender;
    tooltip = INCOMING_MESSAGE;
  }

  return (
    <Row className='mb-5'>
      <Col xs='auto'>
        <CaretIcon className='pointer' onClick={onShowClick} />
      </Col>
      <Col xs='auto' className='justify-content-center'>
        <Row className='mb-1'>
          <Col className='text-center'>
            <OverlayTrigger placement='top' overlay={<Tooltip id='message-tooltip'>{tooltip}</Tooltip>}>
              <DirectionIcon className={directionIconColorClass} size={24} />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className='h-100'>
          <Col className={`border-right border-right-2 ${dividerColorClass}`} />
          <Col />
        </Row>
      </Col>
      <Col data-testid='messageStepHeader' className='text-left'>
        <Row data-testid='messageStepClickToShow' onClick={onShowClick} className='pointer'>
          <Col className='text-primary'>
            {to && <span className='mr-4'>To: {to}</span>}
            {from && <span>FROM: {from}</span>}
          </Col>
          <Col className='text-right text-muted'>
            <MessageFiles files={files} />
            <small className='ml-3'>{moment(props.threadMessage?.messageDate).format('LT | MM/DD/YYYY')}</small>
          </Col>
        </Row>
        {isMessageShown && (
          <Row data-testid='messageDisplayContainer' className='mt-3'>
            <Col>
              <p className='section rounded border bg-white text-white-space-wrap'>
                {props.threadMessage?.messageText}
              </p>
            </Col>
            {props.canReply && (
              <Col xs='auto'>
                <OverlayTrigger placement='top' overlay={<Tooltip id='reply-message'>{REPLY}</Tooltip>}>
                  <Button className='px-2 py-1' onClick={onReplyToggle} size='sm' variant='secondary'>
                    <ReplyFill className='icon-flipped' />
                  </Button>
                </OverlayTrigger>
              </Col>
            )}
          </Row>
        )}
        {props.canReply && isReplyShown && (
          <MessageReply
            {...props.replyProps}
            onCancel={onReplyToggle}
            thread={props.thread ?? null}
            threadMessage={props.threadMessage ?? null}
          />
        )}
      </Col>
    </Row>
  );
};

function MessageFiles({ files }: { files: MessageFile[] | null | undefined }) {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<MessageFile | undefined>(undefined);
  const selectedFileUrl = useBinaryObjectUrl(selectedFile?.fileData, 'application/pdf');

  const onFileClick = (file: MessageFile) => {
    setSelectedFile(file);
    setModalShow(true);
  };

  return (
    <>
      {files?.map((file, i, arr) => (
        <small key={file.documentId}>
          <span
            className='text-secondary'
            onClick={(e) => {
              e.stopPropagation();
              onFileClick(file);
            }}>
            <span role='link'>{file.fileName}</span>
            {arr.length - 1 !== i ? ', ' : ''}
          </span>
        </small>
      ))}
      <HcpModal
        enableCancel
        modalProps={{ show: modalShow, onHide: () => setModalShow(false) }}
        header={selectedFile?.fileName ?? 'No file name'}
        body={
          <object
            aria-label={`Document ${selectedFile?.fileName}`}
            height={600}
            width='100%'
            type='application/pdf'
            name={selectedFile?.fileName?.toString()}
            data={selectedFileUrl}
          />
        }
      />
    </>
  );
}
