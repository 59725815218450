import { RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useEffect, useState } from 'react';
import { EnvelopeFill, Eye, FileText, PersonFill } from 'react-bootstrap-icons';
import { connect, useSelector } from 'react-redux';
import { ConnectedHcpGridWithGQL } from '.';
import { ServiceRequestViewFragment, useGetSrHubStatusesQuery, useServiceRequestInfoQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import {
  ActionColumnActions,
  actionsColumnDefinition,
  dateColumnDefinition,
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  idColumnDefinition,
  nextStepColumnWithoutTooltip,
  prescriberColumnDefinition,
  nameColumnDefinition,
  ROUTE_PATHS,
  serviceRequestAlertColumnDefinition,
  filterGridComparator,
  dobDateColumnFormat
} from '../utils';
import { GridFilters } from './GridFilters';

interface RequestsTableProps {
  enableExternalFilters?: boolean;
  filteredStatus?: string;
}

export interface StatusDescription {
  statusDescription: string;
  __typename: string;
}
export interface FilterInterface {
  primaryFilter?: string;
  secondaryFilterList?: Set<string>;
  strictCompare?: boolean;
}

const columnSelector = featureGridColumnsSelector('service-request.table');

export const RequestsTable = (props: RequestsTableProps) => {
  const programId = Number(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId));
  const drugList: AnyType = useSelector((state: AppRootState) => state?.app?.entities?.drugList);

  const [gridFilter, setGridFilter] = useState<FilterInterface>({ strictCompare: true });
  const [filter, setFilter] = useState<string>();
  const [filterList, setFilterList] = useState<Set<string>>(new Set([]));
  const [statusesLoaded, setStatusesLoaded] = useState(false);
  const [statusDescriptions, setStatusDescriptions] = useState<StatusDescription[]>([]);

  useEffect(() => {
    setGridFilter({ ...gridFilter, secondaryFilterList: filterList, primaryFilter: filter });
  }, [filterList, filter]);

  const filterProps = props.enableExternalFilters
    ? {
        isFilterActive: true,
        filterComparator: filterGridComparator,
        filterValue: gridFilter
      }
    : {};

  const columns = useSelector(columnSelector);

  // if there is no columns or druglist comes from state then return null else show the grid
  if (!columns || !drugList) return null;

  const isDrugs = drugList?.[0] ? 'false' : 'true';

  return (
    <>
      {props.enableExternalFilters && (
        <GridFilters
          filterName='Requests'
          filterOptions={statusDescriptions}
          onFilterChange={(filter: string) => setFilter(filter)}
          onFilterOptionChange={(filterList: Set<string>) => setFilterList(filterList)}
          hideDropDownFilter={false}
        />
      )}

      <ConnectedHcpGridWithGQL
        className='grid-wrapper'
        descriptionType='SrHubStatusDescription'
        dataProcessor={(data: AnyType) =>
          data?.result?.filter((d: ServiceRequestViewFragment) => d.displayOnGrid !== 0)
        }
        {...filterProps}
        queryHook={useServiceRequestInfoQuery}
        onDataLoad={(statusDescriptions: AnyType[]) => {
          setStatusDescriptions(statusDescriptions);
        }}
        queryOptions={{
          variables: {
            programId
          }
        }}
        pagination>
        <AgGridColumn {...serviceRequestAlertColumnDefinition()} />
        <AgGridColumn
          field='patientLastName'
          tooltipField='patientLastName'
          {...nameColumnDefinition(columns.patientLastName)}
        />
        <AgGridColumn
          field='patientFirstName'
          tooltipField='patientFirstName'
          {...nameColumnDefinition(columns.patientFirstName)}
        />
        <AgGridColumn {...dobDateColumnFormat('patientDOB')} {...getFeatureGridColumnProps(columns.dob)} />
        <AgGridColumn {...idColumnDefinition('patientId')} {...getFeatureGridColumnProps(columns.patientId)} />
        <AgGridColumn {...prescriberColumnDefinition()} {...getFeatureGridColumnProps(columns.prescriber)} />
        <AgGridColumn {...dateColumnDefinition('requestStartDate')} {...getFeatureGridColumnProps(columns.submitted)} />
        <AgGridColumn
          field='requestType'
          tooltipField='requestType'
          {...getFeatureGridColumnProps(columns.requestType)}
        />
        <AgGridColumn field='sRDrugName' headerName='DRUG' tooltipField='Drug Name' hide={isDrugs} />
        <AgGridColumn {...idColumnDefinition('id')} {...getFeatureGridColumnProps(columns.id)} hide='true' />
        <AgGridColumn field='requestID' tooltipField='requestID' {...getFeatureGridColumnProps(columns.id)} />
        <AgGridColumn field='statusText' tooltipField='statusText' {...getFeatureGridColumnProps(columns.status)} />
        <AgGridColumn {...nextStepColumnWithoutTooltip()} {...getFeatureGridColumnProps(columns.nextSteps)} />
        <AgGridColumn
          {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
            {
              href: `${ROUTE_PATHS.requests}/${node.data.id}/${node.data.patientId}/${node.data.requestType}/${
                node.data.requestID
              }${node.data.patientEnrollmentGuid ? `/${node.data.patientEnrollmentGuid}` : ''}`,
              label: 'View Request',
              icon: Eye
            },
            {
              href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
              label: 'Start New Request',
              icon: FileText,
              featureKey: 'service-request.manage'
            },
            {
              href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}&request=${node.data.id}#compose`,
              label: 'Send a Message',
              icon: EnvelopeFill,
              featureKey: 'secure-message.manage'
            },
            {
              href: `${ROUTE_PATHS.patients}/${node.data.patientId}#summary`,
              label: 'Patient Profile',
              icon: PersonFill
            }
          ])}
        />
      </ConnectedHcpGridWithGQL>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ConnectedRequestsTable = connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
