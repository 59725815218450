import React from 'react';

import { Col, Form } from 'react-bootstrap';

import { TextField } from '.';
import { OrganizationAddressFragment } from '../graphql';
import { FIELD_NAMES } from '../utils';

interface SelectedLocationFormProps {
  location?: OrganizationAddressFragment;
}

const { prescriberFields } = FIELD_NAMES;

export const SelectedLocationForm = ({ location }: SelectedLocationFormProps) => {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col}>
          <TextField disabled name={prescriberFields.locationAddressFull} label='address' plaintext readOnly />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group>
          <TextField
            disabled
            name={prescriberFields.locationPhone1}
            label='phone'
            defaultValue={location?.phone1 ?? location?.phone2}
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group>
          <TextField
            disabled
            name={prescriberFields.locationFaxNumber}
            label='fax'
            defaultValue={location?.fax}
            plaintext
            readOnly
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};
