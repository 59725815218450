import { Col, Form } from 'react-bootstrap';
import React from 'react';
import { lte } from 'lodash';

interface DisplayDetailComponentParams {
  size?: string;
  label: string;
  value: string | null;
  hideField?: boolean;
}
export const DisplayDetailComponent = (props: DisplayDetailComponentParams) => {
  const { hideField } = props;
  let colClassName = 'detail__wrapper';
  if (hideField) {
    colClassName += ' d-none';
  }
  return (
    <Col md={props?.size ? parseInt(props?.size) : 12} className={colClassName}>
      <Form.Label className='detail__label'>{props.label}</Form.Label>
      <p className='detail__value text-gray'>{props.value}</p>
    </Col>
  );
};
