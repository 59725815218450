import axios, { AxiosResponse } from 'axios';
import { AnyObject } from 'final-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  EnrollmentRequest,
  RX_APPLICATION_NAME,
  SERVICES_GATEWAY_URL,
  RX_AUTH_URL,
  RX_PASSWORD,
  RX_SESSION_TIME,
  RX_URL,
  RX_USERNAME,
  API_URL,
  MEDEBV_MODULE_TRIGGER_RULE,
  SERVICE_MODULE_TRIGGER_RULE
} from '../api';
import { PortalEnrollmentRequest } from '../api/interface-portal';
import { AnyType, ServiceOptionType } from '../interfaces';
import { AppRootState } from '../redux';
import { authOrgDetailsSelector } from '../redux/selectors/auth.selector';
import {
  getMedicalEbvPayload,
  getPharmacyEbvPayload,
  getPharmacyEpaPayload,
  standardPortalConfig,
  yearFirstDateFormat
} from '../utils';
import { getMedicalEpaPayload } from '../utils/payloads/medical-epa-payload';
import { UseAxiosOpts } from './use-axios';
import { getAuthorizationHeader } from '../utils';

export interface RequestHookOpts extends AnyObject {
  lazy?: boolean;
  data?: AnyObject;
}

interface RxModuleMetadata {
  [key: string]: {
    abbreviation: string;
    payload: (...args: AnyType) => AnyObject;
  };
}

type RxModulePayloadType = {
  enrollmentPayload: PortalEnrollmentRequest;
  userName: string;
  organizationId: string;
  previousResults?: AnyType;
};

export const rxModulesMetadata: RxModuleMetadata = {
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: {
    abbreviation: 'RxeBV',
    payload: ({ enrollmentPayload, userName, organizationId }: RxModulePayloadType) =>
      getPharmacyEbvPayload(enrollmentPayload, userName, organizationId)
  },
  [ServiceOptionType.MedicalElectronicBenefitVerification]: {
    abbreviation: 'MedeBV',
    payload: ({ enrollmentPayload, userName, organizationId, previousResults }: RxModulePayloadType) =>
      getMedicalEbvPayload(enrollmentPayload, userName, organizationId, previousResults)
  },
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: {
    abbreviation: 'RxePA',
    payload: ({ enrollmentPayload, userName, organizationId }: RxModulePayloadType) =>
      getPharmacyEpaPayload(enrollmentPayload, userName, organizationId)
  },
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: {
    abbreviation: 'MedPA',
    payload: ({ enrollmentPayload, userName, organizationId, previousResults }: RxModulePayloadType) =>
      getMedicalEpaPayload(enrollmentPayload, userName, organizationId, previousResults)
  }
};

export const useRxModuleRequest = ({ data, lazy }: RequestHookOpts) => {
  const routeData = useLocation<AnyType>();
  const { service } = useParams<{ service: ServiceOptionType }>();
  const metadata = (rxModulesMetadata as AnyType)[service];

  const getCallingTransactionType = (state: AnyType) => {
    if (state?.enrollmentResult?.enrollmentId) {
      if (state?.eBVResult?.transactionId) {
        return 'Med eBV';
      } else {
        return 'HCP Enrollment';
      }
    }
    return null;
  };
  const previousResults = {
    callingTransactionType: getCallingTransactionType(routeData?.state),
    enrollment: routeData?.state?.enrollmentResult,
    medEBV: routeData?.state?.eBVResult
  };
  const initURL = metadata.abbreviation === 'MedeBV' ? SERVICES_GATEWAY_URL + '/module' : RX_URL;
  const headers =
    metadata.abbreviation === 'MedeBV'
      ? {}
      : {
          'Access-Control-Allow-Origin': 'true',
          mode: 'no-cors'
        };
  const withCredentials = metadata.abbreviation === 'MedeBV' ? false : true;
  return useRxModuleAPI({
    lazy,
    method: 'POST',
    url: `${initURL}/api/${metadata.abbreviation}/Request`,
    data: metadata.payload({
      enrollmentPayload: routeData?.state?.enrollmentPayload,
      userName: data?.userName,
      organizationId: data?.organizationId,
      previousResults: previousResults
    }),
    withCredentials: withCredentials,
    headers: headers
  });
};

interface RxModulesListRequestOpts {
  endDate: string;
  externalPatientId?: string | null;
  physicianNpi: string;
  startDate: string;
  abbreviation: string;
}

export const useListRequest = ({
  endDate,
  externalPatientId: ExternalPatientID = null,
  physicianNpi: PhysicianNPI,
  startDate,
  abbreviation
}: RxModulesListRequestOpts) => {
  const organizationId = useSelector(authOrgDetailsSelector)?.organizationId;
  const initURL = abbreviation === 'MedeBV' ? SERVICES_GATEWAY_URL + '/module' : RX_URL;
  const headers =
    abbreviation === 'MedeBV'
      ? {}
      : {
          'Access-Control-Allow-Origin': 'true',
          mode: 'no-cors',
          withCredentials: true
        };
  const withCredentials = abbreviation === 'MedeBV' ? false : true;
  const request = useRxModuleAPI({
    headers: headers,
    lazy: true,
    method: 'POST',
    url: `${initURL}/api/${abbreviation}/List`,
    withCredentials: withCredentials,
    data: JSON.stringify({
      ClientApplicationName: standardPortalConfig.isStandardPortalUrl
        ? standardPortalConfig.applicationName
        : RX_APPLICATION_NAME,
      EndDate: yearFirstDateFormat(endDate),
      ExternalAccountID: organizationId,
      ExternalPatientID,
      PhysicianNPI,
      StartDate: yearFirstDateFormat(startDate)
    })
  });

  useEffect(() => {
    if (organizationId) {
      request.fetchData();
    }
  }, [organizationId]);

  return request;
};

export const useRxModuleAPI = ({ method = 'GET', ...opts }: UseAxiosOpts) => {
  const [data, setData] = useState<AnyObject | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const portalApplicationName = useSelector((state: AppRootState) => state?.app?.config?.applicationName);
  const userName = useSelector((state: AppRootState) => state?.auth?.session?.user?.Login);
  const authTriggerRule = opts?.url?.includes('MedeBV') ? SERVICE_MODULE_TRIGGER_RULE : MEDEBV_MODULE_TRIGGER_RULE;
  const fetchData = async () => {
    try {
      setLoading(true);
      if (userName && portalApplicationName) {
        const authResponse = await getAuthToken(authTriggerRule).catch(() => {
          throw new Error('There was a data error, please contact the administrator.');
        });
        const authData = (await (authResponse as Response)?.json()) ?? {};
        if (authData && authData?.authToken) {
          localStorage.setItem('caremetx-authtoken', authData?.authToken);
        }
        const storeCookie =
          authTriggerRule === SERVICE_MODULE_TRIGGER_RULE
            ? true
            : authData?.authToken
            ? await getNewAuthToken(authData?.authToken).catch(() => {
                throw new Error('There was a data error, please contact the administrator.');
              })
            : null;
        if (storeCookie && authData?.authToken != null && authData?.authToken.length > 0) {
          const response = await axios(opts?.url ?? '', {
            ...opts,
            method: method ?? 'GET',
            headers: {
              'Content-Type': 'application/json',
              ...opts?.headers,
              Authorization: 'Bearer ' + authData?.authToken
            }
          }).catch(() => {
            throw new Error('There was an error loading the module, please contact the administrator.');
          });
          const fetchedData = await (response as AxiosResponse)?.data;
          setData(fetchedData);
        } else {
          throw new Error(authData?.Message ?? 'Please contact the administrator.');
        }
      } else {
        throw new Error('There was a data error, please contact the administrator.');
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return { loading, data, error, fetchData };
};

const getAuthToken = (triggerRule: string) => {
  const bearerToken = getAuthorizationHeader();

  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', bearerToken ?? '');

  return fetch(`${API_URL}/api/Auth/Token?TriggerRule=` + triggerRule, {
    method: 'GET',
    headers
  });
};
//adding the cookie and the localstorage from the modules
const getNewAuthToken = (authToken: string) => {
  return fetch(`${RX_AUTH_URL}/api/GetNewAuthToken`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Referer: 'test',
      'Content-Type': 'application/json',
      mode: 'no-cors',
      Authorization: `Bearer ` + authToken
    }
  });
};
