/* eslint @typescript-eslint/no-empty-function: 0 */

import React from 'react';
import { SelectedRequestInfo } from '../interfaces';

type PatientProfileContextType = {
  selectedRequestInfo: SelectedRequestInfo | null;
  setSelectedRequestInfo: React.Dispatch<React.SetStateAction<SelectedRequestInfo | null>>;
};

const PatientProfileContext = React.createContext<PatientProfileContextType>({
  selectedRequestInfo: {
    id: '',
    drugName: '',
    requestStatus: '',
    requestType: '',
    authorizationReceived: false
  },
  setSelectedRequestInfo: (_requestInfo) => {}
});

const { Provider: PatientProfileContextProvider } = PatientProfileContext;

export { PatientProfileContext, PatientProfileContextProvider };
