import React from 'react';
import { ResourceContent } from '.';
import { rawSafeHtmlProps } from '../utils';

export const AdditionalSupport = () => {
  return (
    <div className='bg-light-gray p-2'>
      <p className='mb-4 section-text'>
        <ResourceContent contentKey='service-request.additional-support.title' />
      </p>
      <p className='mb-0'>
        <ResourceContent
          contentKey='service-request.additional-support.body'
          sourceDataKey='content'
          render={(body) => <span {...rawSafeHtmlProps((body ?? '')?.toString())} />}
        />
      </p>
    </div>
  );
};
