import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldPlainTextSection, headerSection, spacerSection } from '..';

export const getRequestDetailsSchema = (schema: DBFormSchema): Schema => ({
  title: 'request details form',
  fields: [
    headerSection(schema?.request_details_header?.label, schema?.request_details_header),
    fieldPlainTextSection('request_type', schema?.request_type, {
      sizes: { md: 6 }
    }),
    fieldPlainTextSection('request_id', schema?.request_id, {
      sizes: { md: 2 }
    }),
    fieldPlainTextSection('request_submitted', schema?.request_submitted, {
      sizes: { md: 4 }
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldPlainTextSection('request_status', schema?.request_status, {
      sizes: { md: 6 }
    }),
    fieldPlainTextSection('request_prescriber', schema?.request_prescriber, {
      sizes: { md: 6 }
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    fieldPlainTextSection('request_next_steps', schema?.request_next_steps, {
      sizes: { md: 12 }
    }),
    spacerSection(3, { sizes: { sm: 12 } })
  ]
});

// -----===[test schema]===-----
export const requestDetailsSchema: DBFormSchema = {
  request_details_header: { label: 'request details' },
  request_type: { label: 'request type' },
  request_id: { label: 'request id' },
  request_submitted: { label: 'submitted' },
  request_status: { label: 'status' },
  request_prescriber: { label: 'prescriber' },
  request_next_steps: { label: 'next steps' }
};
