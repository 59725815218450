import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../..';
import {
  DBFormSchema,
  fieldProviderNpiSection,
  fieldSelectSection,
  fieldTextSection,
  headerSection,
  spacerSection
} from '..';
import { fieldCheckboxSection } from '../field-helpers';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';

const { prescriberFields } = FIELD_NAMES;

export const getAddUserSchema = (schema: DBFormSchema): Schema => ({
  title: 'add user form',
  fields: [
    headerSection(schema?.users_information_header?.label, schema?.users_information_header),
    fieldSelectSection('user_type', schema?.user_type, {
      sizes: { md: 2 },
      options: [
        { value: 'Contact', label: 'Contact' },
        { value: 'Provider', label: 'Provider' }
      ]
    }),
    fieldProviderNpiSection(prescriberFields.npi, schema?.[prescriberFields.npi], {
      sizes: { md: 2 },
      condition: {
        when: 'user_type',
        is: 'Provider'
      }
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.firstName, schema?.[prescriberFields.firstName], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    fieldTextSection(prescriberFields.lastName, schema?.[prescriberFields.lastName], {
      sizes: { md: 4 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.email, schema?.[prescriberFields.email], {
      sizes: { md: 4 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
          message: 'Email is invalid'
        }
      ]
    }),
    fieldTextSection('prescriber_confirm_email', schema?.prescriber_confirm_email, {
      sizes: { md: 4 },
      validate: [
        {
          type: 'same-as',
          strict: true,
          compareToField: prescriberFields.email
        },
        {
          type: 'email'
        },
        {
          type: validatorTypes.PATTERN,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
          message: 'Email is invalid'
        }
      ]
    }),
    spacerSection(3, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.cellNumber, schema?.[prescriberFields.cellNumber], {
      sizes: { md: 4 },
      type: 'tel'
    }),
    fieldTextSection('username', schema?.username, {
      sizes: { md: 4 },
      validate: [(value: string) => (value.match(/^[\w\S]+$/gi) ? undefined : 'Username cannot contain spaces.')]
    }),
    fieldTextSection(prescriberFields.fax, schema?.[prescriberFields.fax], {
      sizes: { md: 4 },
      type: 'tel'
    }),
    spacerSection(4, { sizes: { sm: 12 } }),
    fieldCheckboxSection('is_admin', schema?.is_admin, {
      sizes: { md: 4 },
      options: [{ value: true, label: 'This user is an Administrator' }]
    }),
    spacerSection(5, { sizes: { sm: 12 } })
  ]
});

// -----===[test schema]===-----
export const addUserSchema: DBFormSchema = {
  users_information_header: { label: "user's information" },
  user_type: { label: 'user type', required: true },
  prescriber_npi: { label: 'prescriber npi', required: true },
  prescriber_first_name: { label: 'first name', required: true },
  prescriber_last_name: { label: 'last name', required: true },
  prescriber_email: { label: 'email address', required: true },
  prescriber_confirm_email: { label: 'confirm email address', required: true },
  username: { label: 'username', required: true },
  is_admin: {}
};
