import React, { Props, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { RadioField } from '.';
import { useSelectTenancyResources } from '../hooks';
import { AnyType, ServiceOption } from '../interfaces';
import {
  AppRootState,
  INDIVIDUAL_SERVICE_REQUEST_KEY,
  tenancyDrugServicesSelector,
  useTenancyDrugListSelector
} from '../redux';
import { ResourceContent } from './multi-tenant';
import { useSelector } from 'react-redux';
import { Drug } from '../api/portal-config.generated';
import { useParams } from 'react-router-dom';
import { cleanHtml, htmlProps, rawCleanHtmlProps } from '../utils';

export interface IndividualServiceProps extends Props<JSX.Element> {
  drug: Drug;
  isEnrollmentModuleView?: boolean;
  getServiceValue: (arg0: any) => unknown;
}

export const IndividualServiceOptions = (props: IndividualServiceProps) => {
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  const { caseDrugName } = useParams<{ caseDrugName: string }>();
  const drug = newDrugList?.filter((drug: any) => drug?.DrugBrandName === caseDrugName);

  const services =
    useSelector(tenancyDrugServicesSelector(props.drug.DrugId || drug?.[0]?.DrugId)) ??
    useSelector((state: AppRootState) => (state.app?.entities?.features as AnyType) ?? {});

  const [serviceOptions, setServiceOptions] = useState<ServiceOption[]>([]);

  const result = useSelectTenancyResources(INDIVIDUAL_SERVICE_REQUEST_KEY) || {};

  useEffect(() => {
    if (result.options && result.options.length > 0) {
      const options = result.options.map((option: Partial<ServiceOption>) => {
        option.featureKey = `service-request.${option.value}`;
        return option;
      });

      setServiceOptions(options);
    }
  }, [result]);

  if (!(Object.keys(services).length !== 0)) {
    return null;
  }

  return (
    <>
      {!props.isEnrollmentModuleView && (
        <h3 className='fs-4 font-weight-bold text-primary mt-3 mb-4'>
          <ResourceContent contentKey={INDIVIDUAL_SERVICE_REQUEST_KEY} />
        </h3>
      )}
      <>
        {serviceOptions.map(
          (serviceOption: ServiceOption, i: number) =>
            services[serviceOption.featureKey as string]?.data?.enabled && (
              <Form.Group as={Col} controlId='service' className='mb-0'>
                <RadioField
                  id={`service_${i}`}
                  name='service'
                  value={serviceOption.value}
                  label={<p className='mb-0 fs-4'>{serviceOption.label}</p>}
                  onClick={() => props.getServiceValue(services[serviceOption.featureKey as string]?.data)}
                  disabled={serviceOption.disabled}
                  description={
                    serviceOption?.description && (
                      <p className='fs-3 mb-0 font-italic' {...htmlProps(serviceOption.description)} />
                    )
                  }
                  data-testid={`cmx__request__${serviceOption.value}`}
                />
              </Form.Group>
            )
        )}
      </>
    </>
  );
};
