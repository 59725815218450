import { Schema } from '@data-driven-forms/react-form-renderer';
import upperFirst from 'lodash/upperFirst';
import { AnyObject } from 'final-form';
import { memoize } from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { HcpFormRenderer } from '.';
import {
  GRAPHQL_DEFAULT_CACHE,
  OrganizationAddressFragment,
  OrganizationFragment,
  PatientFragment,
  PatientInsuranceQuery,
  PhoneNumberDetailsFragment,
  PhysicianFragment,
  useOrganizationAddressInfoQuery,
  usePatientInfoQuery,
  usePatientInsuranceQuery,
  usePhysiciansInfoQuery,
  UserInfoFragment
} from '../graphql';
import { ServiceOptionType } from '../interfaces';
import { AppRootState, AuthUser } from '../redux';
import {
  DBFormSchema,
  getDateValue,
  getCurrentDate,
  localDateFormat,
  defaultDateFormatWithAge,
  FIELD_NAMES,
  formatContactNumber,
  getServiceRequestAllFormSchema,
  getServiceRequestCopayClaimFormSchema,
  getServiceRequestCopayEnrollmentFormSchema,
  getServiceRequestInsuranceReviewFormSchema,
  getServiceRequestMedEBVFormSchema,
  getServiceRequestMedEPAFormSchema,
  getServiceRequestPatientResourcesFormSchema,
  getServiceRequestRxEBVFormSchema,
  getServiceRequestRxEPAFormSchema,
  getServiceRequestFieldManagerFormSchema
} from '../utils';
import { LoadingIndicatorRow } from './LoadingIndicator';
import { useSelector } from 'react-redux';
import { useDrugFeatureKeyPrefix } from '../hooks/use-drug-feature-key-prefix';

interface SelectedServiceRequestContentProps extends PropsWithChildren<unknown> {
  onSubmit: (values: AnyObject) => void;
  service: ServiceOptionType;
  userDetails?: PhoneNumberDetailsFragment & Partial<AuthUser>;
  orgDetails?: OrganizationFragment;
  userInfo?: UserInfoFragment;
  prescriberId?: number | string;
  locationId?: number | string;
}

interface FormMap {
  [key: string]: {
    contentKey: string;
    schemaMethod: (schema: DBFormSchema) => Schema;
    testSchema?: DBFormSchema;
  };
}

const BASE_KEY = 'form.service-request-';

const formMap: FormMap = {
  [ServiceOptionType.All]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.All}`,
    schemaMethod: getServiceRequestAllFormSchema
  },
  [ServiceOptionType.FieldReimbursementManager]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.FieldReimbursementManager}`,
    schemaMethod: getServiceRequestFieldManagerFormSchema
  },
  [ServiceOptionType.CopayEnrollment]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.CopayEnrollment}`,
    schemaMethod: getServiceRequestCopayEnrollmentFormSchema
  },
  [ServiceOptionType.CopayClaim]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.CopayClaim}`,
    schemaMethod: getServiceRequestCopayClaimFormSchema
  },
  [ServiceOptionType.InsuranceReview]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.InsuranceReview}`,
    schemaMethod: getServiceRequestInsuranceReviewFormSchema
  },
  [ServiceOptionType.MedicalElectronicBenefitVerification]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.MedicalElectronicBenefitVerification}`,
    schemaMethod: getServiceRequestMedEBVFormSchema
  },
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.MedicalElectronicPriorAuthorization}`,
    schemaMethod: getServiceRequestMedEPAFormSchema
  },
  [ServiceOptionType.PatientResources]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.PatientResources}`,
    schemaMethod: getServiceRequestPatientResourcesFormSchema
  },
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.PharmacyElectronicBenefitVerification}`,
    schemaMethod: getServiceRequestRxEBVFormSchema
  },
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: {
    contentKey: `${BASE_KEY}${ServiceOptionType.PharmacyElectronicPriorAuthorization}`,
    schemaMethod: getServiceRequestRxEPAFormSchema
  }
};

export const SelectedServiceRequestContent = React.memo((props: SelectedServiceRequestContentProps) => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data } = usePatientInfoQuery({ variables: { patientId } });
  const schema = formMap[props.service];
  const portalApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  const [selectedPrescriber, setSelectedPrescriber] = useState<any>(undefined);
  const [selectedLocation, setSelectedLocation] = useState<any>(undefined);
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const prescriberId = queryParams.get('prescriberId') ?? '';
  const locationId = queryParams.get('locationId') ?? '';
  const { data: insuranceData } = usePatientInsuranceQuery({
    variables: {
      patientId: patientId
    }
  });

  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId) || '';
  const { data: prescriberList, refetch: refetchPrescriberList } = usePhysiciansInfoQuery({
    variables: { organizationId },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });

  const { data: locationList, refetch: refetchLocationList } = useOrganizationAddressInfoQuery({
    variables: { organizationId },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });

  useEffect(() => {
    if (organizationId && !prescriberList) {
      refetchPrescriberList();
    }
    if (organizationId && !locationList) {
      refetchLocationList();
    }
    const sPrescriber = prescriberList?.result?.find((p) => p?.id === prescriberId);
    const sLocation = locationList?.result?.find((l) => l?.id === locationId);
    setSelectedPrescriber(sPrescriber);
    setSelectedLocation(sLocation);
  }, [organizationId, prescriberList, locationList, prescriberId, locationId]);

  const featureKeyPrefix = useDrugFeatureKeyPrefix();

  if (prescriberId && locationId) {
    if (!selectedPrescriber || !selectedLocation) {
      return <LoadingIndicatorRow />;
    }
  }

  if (!data?.result) return <LoadingIndicatorRow />;

  return (
    <>
      <HcpFormRenderer
        key={props.service}
        contentKey={!schema?.testSchema ? `${featureKeyPrefix}${schema?.contentKey}` : undefined}
        initialValues={buildPatient(
          data?.result,
          props.userDetails,
          props.orgDetails,
          props.userInfo,
          selectedPrescriber,
          selectedLocation,
          portalApplicationName,
          insuranceData
        )}
        noControls
        onSubmit={props.onSubmit}
        schemaMethod={schema?.schemaMethod}
      />
      {props.children}
    </>
  );
});

const {
  copayFields,
  organizationFields,
  patientFields,
  prescriberAttestationFields,
  prescriptionFields,
  prescriberFields,
  insuranceFields
} = FIELD_NAMES;

const buildPatient = (
  patient?: PatientFragment | undefined | null,
  userDetails?: PhoneNumberDetailsFragment & Partial<AuthUser>,
  orgDetails?: OrganizationFragment,
  userInfo?: UserInfoFragment,
  prescriber?: PhysicianFragment | undefined,
  location?: OrganizationAddressFragment | undefined,
  portalApplicationName?: any,
  insurance?: PatientInsuranceQuery | undefined
) => {
  const organization = orgDetails;
  const homePhone = patient?.phoneNumbers?.find((phone) => phone?.phoneType?.toLowerCase() === 'home');
  const mobilePhone = patient?.phoneNumbers?.find((phone) => phone?.phoneType?.toLowerCase() === 'mobile');
  const messageOptions = [homePhone?.okToLeaveMsg ? 'home' : null, mobilePhone?.okToLeaveMsg ? 'mobile' : null].filter(
    Boolean
  );
  const primaryInsurance = insurance?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'primary');
  const secondaryInsurance = insurance?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'secondary');
  const tertiaryInsurance = insurance?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'tertiary');
  const otherInsurance = insurance?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'other');

  return {
    [patientFields.firstName]: patient?.firstName,
    [patientFields.middleName]: patient?.middleName,
    [patientFields.lastName]: patient?.lastName,
    [patientFields.dob]: defaultDateFormatWithAge(patient?.dob),
    [patientFields.gender]: patient?.gender,
    [patientFields.genderOther]: patient?.gender === 'O' ? patient?.genderOther : null,
    [patientFields.preferredLanguage]: patient?.preferredLanguage ? upperFirst(patient?.preferredLanguage) : '',
    [patientFields.otherLanguage]: patient?.preferredLanguageOther ? upperFirst(patient?.preferredLanguageOther) : '',
    [patientFields.email]: patient?.email,
    [patientFields.homeNumber]: homePhone?.phoneNumber,
    [patientFields.mobileNumber]: mobilePhone?.phoneNumber,
    [patientFields.messageOption]: messageOptions,
    [patientFields.address1]: patient?.address?.address1,
    [patientFields.address2]: patient?.address?.address2,
    [patientFields.zip]: patient?.address?.zip,
    [patientFields.city]: patient?.address?.city,
    [patientFields.state]: patient?.address?.state,
    [organizationFields.name]: organization?.organizationName,
    [organizationFields.npi]: organization?.npi,
    [organizationFields.taxId]: organization?.taxId,
    [organizationFields.officeContactFirstName]: userDetails?.given_name,
    [organizationFields.officeContactLastName]: userDetails?.family_name,
    [organizationFields.officeContactPhone]: formatContactNumber(userInfo?.phone),
    [organizationFields.officeContactFax]: formatContactNumber(userInfo?.fax),
    [organizationFields.officeContactEmail]: userInfo?.email,
    [organizationFields.portalApplicationName]: portalApplicationName,
    [prescriberAttestationFields.certifiedText]: false,
    [prescriberAttestationFields.signatureDate]: getCurrentDate(),
    [prescriptionFields.signatureDate]: localDateFormat(new Date()),
    [copayFields.emailId]: organization?.email,
    [prescriberFields.id]: prescriber?.id ?? 0,
    [prescriberFields.firstName]: prescriber?.firstName,
    [prescriberFields.middleName]: prescriber?.middleName,
    [prescriberFields.lastName]: prescriber?.lastName,
    [prescriberFields.npi]: prescriber?.npi,
    [prescriberFields.taxId]: prescriber?.details?.taxId,
    [prescriberFields.stateLicenseNumber]: prescriber?.stateLicenses?.stateLicenseNumber,
    [prescriberFields.licensingState]: prescriber?.stateLicenses?.stateIssuer,
    [prescriberFields.ptan]: prescriber?.details?.pTAN,
    [prescriberFields.dea]: prescriber?.details?.dEA,
    [prescriberFields.email]: prescriber?.email,
    [prescriberFields.locationId]: location?.id,
    [prescriberFields.locationAddress1]: location?.address1,
    [prescriberFields.locationAddress2]: location?.address2,
    [prescriberFields.locationAddressZip]: location?.zip,
    [prescriberFields.locationAddressCity]: location?.city,
    [prescriberFields.locationAddressState]: location?.state,
    [prescriberFields.locationPhone1]: formatContactNumber(location?.phone1 ?? location?.phone2),
    [prescriberFields.locationFaxNumber]: formatContactNumber(location?.fax),
    [prescriberFields.locationAddressFull]: `${location?.address1} ${location?.address2 ?? ''} | ${location?.city}${
      location?.state || location?.zip ? ', ' : ''
    } ${location?.state ?? ''} ${location?.zip ?? ''}`,
    [insuranceFields.primaryPlanName]: primaryInsurance?.medicalPlanName,
    [insuranceFields.primaryPlanCardholderId]: primaryInsurance?.medicalCardHolderId,
    [insuranceFields.primaryPlanGroupNumber]: primaryInsurance?.medicalGroup,
    [insuranceFields.primaryPlanPhoneNumber]: primaryInsurance?.medicalPhoneNumber,
    [insuranceFields.primaryPlanPayerName]: primaryInsurance?.payerName,
    [insuranceFields.primaryPlanPayerId]: primaryInsurance?.payerId,
    [insuranceFields.primaryPlanMedePayerId]: primaryInsurance?.medePayerId,
    [insuranceFields.primaryPlanMedPAPayerId]: primaryInsurance?.medPAPayerId,
    [insuranceFields.primaryIsCardholder]: primaryInsurance?.isPatientPrimaryCardholder ? 'Y' : 'N',
    [insuranceFields.primarySubscriberFirstName]: primaryInsurance?.subscriberFirstName,
    [insuranceFields.primarySubscriberLastName]: primaryInsurance?.subscriberLastName,
    [insuranceFields.primarySubscriberGender]: primaryInsurance?.subscriberGender,
    [insuranceFields.primarySubscriberRelationship]: primaryInsurance?.relationship,
    [insuranceFields.secondaryPlanName]: secondaryInsurance?.medicalPlanName,
    [insuranceFields.secondaryPlanCardholderId]: secondaryInsurance?.medicalCardHolderId,
    [insuranceFields.secondaryPlanCardholderIdOpt]: secondaryInsurance?.medicalCardHolderId,
    [insuranceFields.secondaryPlanGroupNumber]: secondaryInsurance?.medicalGroup,
    [insuranceFields.secondaryPlanGroupNumberOpt]: secondaryInsurance?.medicalGroup,
    [insuranceFields.secondaryPlanPhoneNumber]: secondaryInsurance?.medicalPhoneNumber,
    [insuranceFields.secondaryPlanPayerName]: secondaryInsurance?.payerName,
    [insuranceFields.secondaryPlanPayerId]: secondaryInsurance?.payerId,
    [insuranceFields.secondaryPlanMedePayerId]: secondaryInsurance?.medePayerId,
    [insuranceFields.secondaryPlanMedPAPayerId]: secondaryInsurance?.medPAPayerId,
    [insuranceFields.secondarySubscriberFirstName]: secondaryInsurance?.subscriberFirstName,
    [insuranceFields.secondarySubscriberLastName]: secondaryInsurance?.subscriberLastName,
    [insuranceFields.secondarySubscriberGender]: secondaryInsurance?.subscriberGender,
    [insuranceFields.secondarySubscriberRelationship]: secondaryInsurance?.relationship,
    [insuranceFields.tertiaryPlanPayerName]: tertiaryInsurance?.payerName,
    [insuranceFields.tertiaryPlanPayerId]: tertiaryInsurance?.payerId,
    [insuranceFields.tertiaryPlanMedePayerId]: tertiaryInsurance?.medePayerId,
    [insuranceFields.tertiaryPlanMedPAPayerId]: tertiaryInsurance?.medPAPayerId,
    [insuranceFields.tertiaryPlanCardholderId]: tertiaryInsurance?.medicalCardHolderId,
    [insuranceFields.tertiaryPlanCardholderIdOpt]: tertiaryInsurance?.medicalCardHolderId,
    [insuranceFields.tertiarySubscriberFirstName]: tertiaryInsurance?.subscriberFirstName,
    [insuranceFields.tertiarySubscriberLastName]: tertiaryInsurance?.subscriberLastName,
    [insuranceFields.tertiarySubscriberGender]: tertiaryInsurance?.subscriberGender,
    [insuranceFields.pharmacyName]: primaryInsurance?.pharmacyPlanName,
    [insuranceFields.pharmacyCardholderId]: primaryInsurance?.pharmacyCardholderID,
    [insuranceFields.pharmacyRxGroupNumber]: primaryInsurance?.pharmacyGroup,
    [insuranceFields.pharmacyRxBinIin]: primaryInsurance?.pharmacyBINNumber,
    [insuranceFields.pharmacyRxPcn]: primaryInsurance?.pharmacyPCN,
    [insuranceFields.pharmacyPhoneNumber]: primaryInsurance?.pharmacyPhoneNumber,
    [insuranceFields.pharmacyCardholderIdOpt]: primaryInsurance?.pharmacyCardholderID,
    [insuranceFields.pharmacyRxGroupNumberOpt]: primaryInsurance?.pharmacyGroup,
    [insuranceFields.otherPlanName]: otherInsurance?.medicalPlanName,
    [insuranceFields.otherPlanCardholderId]: otherInsurance?.medicalCardHolderId,
    [insuranceFields.otherPlanGroupNumber]: otherInsurance?.medicalGroup,
    [insuranceFields.otherPlanPhoneNumber]: otherInsurance?.medicalPhoneNumber
  };
};
