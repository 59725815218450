import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES, HIN_REGEX, HIN_VALIDATION_MESSAGE } from '../..';
import { DBFormSchema, fieldProviderNpiSection, fieldTextSection, headerSection, spacerSection } from '..';
import { fieldSelectStatesSection } from '../field-helpers';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';

const { prescriberFields } = FIELD_NAMES;

export const getAddPrescriberSchema = (schema: DBFormSchema): Schema => ({
  title: 'add prescriber form',
  fields: [
    fieldProviderNpiSection(prescriberFields.npi, schema?.[prescriberFields.npi], {
      sizes: { md: 3 }
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.firstName, schema?.[prescriberFields.firstName], {
      sizes: { md: 3 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    fieldTextSection(prescriberFields.middleName, schema?.[prescriberFields.middleName], {
      sizes: { md: 3 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    fieldTextSection(prescriberFields.lastName, schema?.[prescriberFields.lastName], {
      sizes: { md: 3 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.taxId, schema?.[prescriberFields.taxId], {
      type: 'tax',
      sizes: { md: 3 },
      validate: [
        {
          type: 'tax-id'
        }
      ]
    }),
    fieldTextSection(prescriberFields.stateLicenseNumber, schema?.[prescriberFields.stateLicenseNumber], {
      sizes: { md: 3 }
    }),
    fieldSelectStatesSection(prescriberFields.licensingState, schema?.[prescriberFields.licensingState], {
      sizes: { md: 3, lg: 2 },
      condition: {
        when: prescriberFields.stateLicenseNumber,
        is: (value: string) => !!value
      }
    }),
    spacerSection(3, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.ptan, schema?.[prescriberFields.ptan], {
      sizes: { md: 3 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
          message: 'PTAN needs to be in alphaNumeric  or numeric characters only'
        }
      ]
    }),
    fieldTextSection(prescriberFields.dea, schema?.[prescriberFields.dea], {
      sizes: { md: 3 }
    }),
    fieldTextSection(prescriberFields.hin, schema?.[prescriberFields.hin], {
      sizes: { md: 3 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: HIN_REGEX,
          message: HIN_VALIDATION_MESSAGE
        }
      ]
    }),
    fieldTextSection(prescriberFields.email, schema?.[prescriberFields.email], {
      sizes: { md: 3 },
      validate: [
        {
          type: 'email'
        },
        {
          type: validatorTypes.PATTERN,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
          message: 'Email is invalid'
        }
      ]
    })
  ]
});

// -----===[test schema]===-----
export const addPrescriberSchema: DBFormSchema = {
  prescribers_information_header: { label: "prescriber's information" },
  prescriber_npi: { label: 'prescriber npi', required: true },
  prescriber_first_name: { label: 'first name', required: true },
  prescriber_middle_name: { label: 'middle name' },
  prescriber_last_name: { label: 'last name', required: true },
  prescriber_tax_id: { label: 'tax id # (tin)' },
  prescriber_state_license_number: { label: 'state license #' },
  prescriber_licensing_state: { label: 'licensing state', required: true }
};
