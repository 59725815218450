import React, { HTMLAttributes, useEffect, useState } from 'react';

const convertMillisecondsToMinSec = (time: number) => {
  const date = new Date(time);
  const min = date.getMinutes().toString();
  const sec = date.getSeconds().toString();

  return min.padStart(2, '0') + ':' + sec.padStart(2, '0');
};

interface HcpCountdownProps extends HTMLAttributes<HTMLSpanElement> {
  timeout: number;
  onTimeout?: () => void;
}

export const HcpCountdown = ({ timeout, onTimeout, ...props }: HcpCountdownProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(timeout);

  useEffect(() => {
    let interval: number;

    const clearTimer = () => {
      clearTimeout(interval);
    };

    const startTimer = () => {
      clearTimer();
      interval = window.setTimeout(updateTime, 1000);
    };

    const updateTime = () => {
      setRemainingTime((s) => {
        const newTime = s - 1000;

        if (newTime === 0) {
          onTimeout && onTimeout();
        } else {
          startTimer();
        }

        return newTime;
      });
    };

    startTimer();

    return () => {
      clearTimer();
    };
  }, [onTimeout, timeout]);

  return <span {...props}>{convertMillisecondsToMinSec(remainingTime)} remaining</span>;
};
