import React, { useCallback, useEffect, useState } from 'react';
import { getToken } from './enrollment-module';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux/reducers';
import { EI_IFRAME_ID, eiGetMessageListener } from '@caremetx/enrollment-integration';
import { Container, Spinner } from 'react-bootstrap';
import { GATEWAY_API_URL, ENROLLMENT_MODULE_TRIGGER_RULE } from '../api';
import { ROUTE_PATHS } from '../utils';

export interface EnrollmentModuleResultProps {
  enrollmentId: string;
  practiceId: string;
  updateContent?: number;
}

const getSummaryUrl = async (accessToken: string, data: any) => {
  const headers = new Headers();
  headers.append('Referer', 'test');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);

  const requestOptions = {
    method: 'GET',
    headers
  };

  return fetch(
    `${GATEWAY_API_URL}${ROUTE_PATHS.enrollmentSummary}${data?.moduleEnrollmentId}/${data?.externalPracticeId}`,
    requestOptions
  );
};

export const EnrollmentModuleResult = (props: EnrollmentModuleResultProps) => {
  const { enrollmentId, practiceId, updateContent } = props;
  const application = useSelector((state: AppRootState) => state.app?.config?.domainURL);
  const [accessToken, setAccessToken] = useState<string | null>();
  const [apiError, setAPIError] = useState<string | null>();
  const [accessError, setAccessError] = useState<string | null>();
  const [isClientConnected, setClientConnected] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const summaryApi = {
    moduleEnrollmentId: enrollmentId,
    externalPracticeId: practiceId
  };

  const loadData = useCallback(async () => {
    if (!accessToken) return;

    const response = await getSummaryUrl(accessToken, summaryApi);
    const result = await response.text();

    try {
      const json = JSON.parse(result);
      if (json.errors?.length > 0) setAPIError(JSON.stringify(json.errors));
      setUrl(json.URL);
    } catch (e) {
      setAccessError(result ?? null);
    }
  }, [accessToken]);

  useEffect(() => {
    loadData();
  }, [loadData, updateContent]);

  const loadAccessToken = useCallback(async () => {
    setAPIError(null);
    setAccessError(null);
    setClientConnected(false);

    const token = await getToken(ENROLLMENT_MODULE_TRIGGER_RULE);
    const result = await token.text();

    try {
      const json = JSON.parse(result);
      setAccessToken(json.authToken);
      sessionStorage.setItem('authToken', json.authToken);
    } catch (e) {
      setAccessError(result ?? null);
    }
  }, []);

  useEffect(() => {
    loadAccessToken();
  }, [loadAccessToken]);

  useEffect(() => {
    if (!accessToken) return;
    const onInitialized = () => {
      setClientConnected(true);
    };

    const listener = eiGetMessageListener({ accessToken, onInitialized, enableLogging: true });

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [accessToken]);

  const isLoading = !accessToken || !isClientConnected;

  return (
    <Container fluid className='my-2'>
      <div className='text-center'>
        {isLoading && (
          <div className='d-inline-flex align-items-center'>
            <Spinner animation='border' variant='secondary' />
            &nbsp;
            <span className='text-secondary'>{'Loading Enrollment Result...'}</span>
            <iframe className='d-none' src={url} />
          </div>
        )}
      </div>
      {!isLoading && (
        <iframe id={EI_IFRAME_ID} title='enrollment-module' className='w-100 border-0' height='730' src={url} />
      )}
    </Container>
  );
};
