import React, { useEffect, useState, useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { RadioField } from './FormFields';
import { testOnSubmit } from '../utils';
import { AnyType, ServiceOption, ServiceOptionType } from '../interfaces';
import { useSelectTenancyResources } from '../hooks';
import { AppRootState } from '../redux';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DaisyChainingButtons } from './DaisyChainingButtons';
export interface SubmitServiceOption {
  currentService: string;
  enrollmentData: AnyType;
}

export const DaisyChainingSubmitServiceOptionOption = (props: SubmitServiceOption) => {
  const services = useSelector((state: AppRootState) => (state.app?.entities?.features as AnyType) ?? {});
  const prescriberId = sessionStorage.getItem('prescriberId');
  const locationId = sessionStorage.getItem('locationId');
  const patId = sessionStorage.getItem('patientId');
  const [serviceOptions, setServiceOptions] = useState<ServiceOption[]>([]);
  const result = useSelectTenancyResources('request.submit.daisy.Chaining-services') || {};
  const [selectedService, setSelectedService] = useState<any>({});
  const [performedServices, setPerformedServices] = useState<any>([props?.currentService]);
  const [enrollmentData, setEnrollmentData] = useState<any>([props?.enrollmentData]);
  const [getPatientId, SetPatientId] = useState<any>(props?.enrollmentData?.patientId);
  const enrollmentModuleIsEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );

  useEffect(() => {
    if (result.options && result.options.length > 0) {
      const options = result.options.map((option: Partial<ServiceOption>) => {
        if (performedServices.indexOf(option.value) === -1) {
          option.featureKey = `service-request.${option.value}`;
        }
        return option;
      });

      setServiceOptions(options);

      if (performedServices?.indexOf(props?.currentService) === -1) {
        setPerformedServices(props?.currentService);
      }
      if (sessionStorage.getItem('performedServices')?.length == 0) {
        setPerformedServices(props?.currentService);
        sessionStorage.setItem('performedServices', props?.currentService);
      } else {
        setPerformedServices(sessionStorage.getItem('performedServices'));
      }
      setEnrollmentData(props.enrollmentData);
      SetPatientId(props?.enrollmentData?.patientId);
    }
  }, [result]);
  const getServiceValue = useCallback((selectedService: any) => {
    setSelectedService('');
    setSelectedService(
      selectedService.key === 'service-request.pharmacy-ebv'
        ? ServiceOptionType.PharmacyElectronicBenefitVerification
        : selectedService.key === 'service-request.pharmacy-epa'
        ? ServiceOptionType.PharmacyElectronicPriorAuthorization
        : selectedService.key === 'service-request.copay-claim'
        ? ServiceOptionType.CopayClaim
        : selectedService.key === 'service-request.medical-epa'
        ? ServiceOptionType.MedicalElectronicPriorAuthorization
        : selectedService.key === 'service-request.medical-ebv'
        ? ServiceOptionType.MedicalElectronicBenefitVerification
        : enrollmentModuleIsEnabled
        ? 'HUB Enrollment'
        : ''
    );
  }, []);
  if (!(Object.keys(services).length !== 0)) {
    return null;
  }
  return (
    <FinalForm
      onSubmit={testOnSubmit}
      render={() => (
        <Form>
          <span>
            <p className='ml-3'>Now that we have that information, we can use it to provide additional services.</p>
          </span>
          <span>
            <p className='ml-3'>
              Select the service you would like to begin next from our options below, start a new request for another
              patient, or return to your dashboard.
            </p>
          </span>
          {serviceOptions
            .filter((serviceOption) => performedServices.indexOf(serviceOption.value) === -1)
            .map(
              (serviceOption: ServiceOption, i: number) =>
                services[serviceOption.featureKey as string]?.data?.enabled && (
                  <Form.Group as={Col} controlId='service' className='mb-0'>
                    <RadioField
                      id={`service_${i}`}
                      name='service'
                      value={serviceOption.value}
                      label={<p className='mb-0 fs-4'>{serviceOption.label}</p>}
                      onClick={() => getServiceValue(services[serviceOption.featureKey as string])}
                      disabled={serviceOption.disabled}
                      data-testid={`cmx__request__${serviceOption.value}`}
                    />
                  </Form.Group>
                )
            )}
          <DaisyChainingButtons
            currentService={selectedService}
            patientId={patId}
            prescriberId={prescriberId}
            locationId={locationId}
          />
        </Form>
      )}
    />
  );
};
