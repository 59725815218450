import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import React, { useEffect, useState } from 'react';
import { HcpUpload, HcpUploadProps } from '.';
import {
  useMediscanCreateDocument,
  useMediscanGetFrontWhite,
  useMediscanPostFrontWhite,
  useMediscanReturnData
} from '../hooks/use-medicscan';
import { FilePreview } from '../interfaces';
import { dataURItoBlob, FIELD_NAMES } from '../utils';

const { insuranceFields } = FIELD_NAMES;

export const HcpMedicscanUpload = (props: HcpUploadProps) => {
  const [files, setFiles] = useState<FilePreview[]>([]);
  const form = useFormApi();
  const {
    fetchData: fetchInstanceId,
    data: instanceId,
    loading: loadingCreateDocument
    // errorCreateDocument
  } = useMediscanCreateDocument({ lazy: true });
  const {
    fetchData: postFrontOfCard,
    success: frontOfCardSuccess,
    loading: loadingPostFront
    // error: errorPostFront
  } = useMediscanPostFrontWhite({
    lazy: true,
    instanceId
  });
  const {
    fetchData: getFrontOfCard,
    data: frontOfCardData,
    loading: loadingGetFromWhite
    // error: errorGetFromWhite
  } = useMediscanGetFrontWhite({
    lazy: true,
    instanceId
  });
  const {
    fetchData: getMediscanData,
    data: mediscanData,
    loading: loadingReturnData
    // error: errorReturnData
  } = useMediscanReturnData({
    lazy: true,
    instanceId
  });

  const isLoading =
    loadingCreateDocument || loadingPostFront || loadingGetFromWhite || loadingReturnData || props.isLoading;

  useEffect(() => {
    if (!files?.length || instanceId) return;
    fetchInstanceId();
  }, [files]);

  useEffect(() => {
    if (!instanceId || !files?.length || frontOfCardSuccess) return;

    postFrontOfCard({
      body: dataURItoBlob(files[0].bytes as string),
      headers: { 'Content-Type': 'image/jpeg' }
    });
  }, [instanceId, files, frontOfCardSuccess]);

  useEffect(() => {
    if (!instanceId || !frontOfCardSuccess || frontOfCardData) return;

    getFrontOfCard();
  }, [instanceId, frontOfCardSuccess, frontOfCardData]);

  useEffect(() => {
    if (!instanceId || !frontOfCardSuccess || !frontOfCardData || mediscanData) return;

    getMediscanData();
  }, [instanceId, frontOfCardSuccess, frontOfCardData]);

  useEffect(() => {
    if (!mediscanData) return;
    const noFiles = !files?.length;
    form.change(insuranceFields.primaryPlanName, noFiles ? '' : mediscanData?.PlanProvider);
    form.change(insuranceFields.primaryPlanCardholderId, noFiles ? '' : mediscanData?.MemberId);
    form.change(insuranceFields.primaryPlanGroupNumber, noFiles ? '' : mediscanData?.GroupNumber);
    form.change(insuranceFields.primaryPlanPhoneNumber, noFiles ? '' : mediscanData?.ListTelephone[0].Value);
  }, [files, form, mediscanData]);

  return (
    <>
      <HcpUpload
        {...props}
        isLoading={isLoading}
        label='SCAN ID CARD'
        onChange={setFiles}
        dropzoneOptions={{ accept: '.jpg' }}
      />
    </>
  );
};
