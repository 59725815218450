import { QueryHookOptions, QueryResult } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { HcpGrid, HcpGridProps, HcpRequestStateUI, StatusDescription } from '.';
import { GRAPHQL_DEFAULT_CACHE } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { getStatusDescriptions } from '../utils';

interface HcpGridWithGQLProps extends HcpGridProps {
  dataProcessor?: (data: unknown) => AnyType[] | undefined;
  organizationId?: string;
  programId?: string;
  programIdRequired?: boolean;
  descriptionType?: string;
  // NOTE: Lazy queries are not an option here.
  queryHook: (baseOptions: QueryHookOptions<AnyType, AnyType>) => QueryResult<AnyType, AnyType>;
  queryOptions?: QueryHookOptions<AnyType, Record<string, AnyType>>;
  userName?: string;
  onDataLoad?: (statusDescriptions: StatusDescription[]) => void;
  isScrollbarEnabled?: boolean;
}

export const HcpGridWithGQL = (props: HcpGridWithGQLProps) => {
  const queryOptions = props.queryOptions ?? {};
  const { descriptionType } = props;
  if (!props.organizationId || (props.programIdRequired && !props.programId)) {
    return null;
  }

  if (!queryOptions.variables?.organizationId) {
    queryOptions.variables = {
      ...queryOptions.variables,
      organizationId: props.organizationId,
      userName: props.userName,
      programId: parseInt(props.programId || '') ?? null
    };
  }

  const result = props.queryHook({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE });
  const data = props.dataProcessor ? props.dataProcessor?.(result?.data) : result?.data?.result;

  const statusDescriptions = useMemo(() => getStatusDescriptions(data ?? [], descriptionType ?? 'StatusDescription'), [
    data
  ]);

  useEffect(() => {
    if (result?.data && props.onDataLoad) {
      props.onDataLoad(statusDescriptions);
    }
  }, [result]);

  return (
    <HcpRequestStateUI
      errorUIEnabled
      isError={result.error}
      reloadingEnabled
      reload={() => result.refetch(queryOptions.variables)}>
      <HcpGrid rowData={data} {...props} />
    </HcpRequestStateUI>
  );
};

const mapStateToProps = (state: AppRootState) => ({
  organizationId: state.auth.session?.user?.OrganizationId,
  programId: state.auth.session?.user?.ProgramId,
  userName: state.auth.session?.user?.Login,
  isScrollbarEnabled: (state.app.entities.features?.['scrollbar-grid']?.data as any)?.enabled
});

const mapDispatchToProps = {};

export const ConnectedHcpGridWithGQL = connect(mapStateToProps, mapDispatchToProps)(HcpGridWithGQL);
