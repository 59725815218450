import _ from 'lodash';
import { AnyObject } from 'react-final-form';
import Field from '@data-driven-forms/react-form-renderer/dist/cjs/field';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';
import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import { Validator } from '@data-driven-forms/react-form-renderer';

import {
  verricaCptCode,
  cptCodes,
  insuranceProviders,
  languages,
  primaryDiagnosisCodes,
  relationships,
  secondaryDiagnosisCodes,
  states,
  suffix,
  tertiaryDiagnosisCodes,
  drugDescriptionType,
  qualityUnitOfMeasure,
  supplimentPlanLetter,
  cardholderRelationship,
  cardHolderRelationshipTahio,
  regeneronDiagnosisCodes,
  placeOfService,
  formatToArray
} from '../data';
import { useSelector } from 'react-redux';
import { AppRootState, tenancyDrugServicesSelector, useTenancyDrugListSelector } from '../../redux';
import { useEffect, useState } from 'react';
import {
  PayerFragment,
  PayersQuery,
  usePayersLazyQuery,
  useDrugDetailsByClientIdLazyQuery,
  DrugDetailsByClientIdQuery,
  useDrugCptCodeByProgramLazyQuery,
  DrugCptCode,
  usePatientInsuranceLazyQuery,
  usePatientDiagnosisByProgramLazyQuery
} from '../../graphql';
import { FIELD_NAMES, clientApplicationNameSelector } from '../../utils';
import { useLocation } from 'react-router-dom';
import { Drug } from '../../api/portal-config.generated';
import { AnyType } from '../../interfaces';
import { NEVER } from 'rxjs';
import { prescriberNpiValidation } from '../input-validations';
import flagsmith from 'flagsmith';
const { prescriberFields } = FIELD_NAMES;

// -----===[HELPERS]===-----

type FieldOpts = Omit<Field, 'name' | 'component'>;

export interface DBFormSchema {
  [name: string]: DBFieldSchema;
}

export interface DBFieldSchema extends AnyObject {
  helperText?: string;
  hideField?: boolean;
  hideTab?: boolean;
  label?: string;
  nextStep?: string;
  placeholder?: string;
  required?: boolean;
  sizes?: AnyObject;
  max?: number;
  className?: string;
  options?: AnyObject[];
  validate?: Validator[];
  initialValue?: any;
  value?: any;
  npiValidation?: boolean;
  showRequiredText?: boolean;
  readonly?: boolean;
}

export interface DBDataDrivenFieldSchema extends AnyObject {
  helperText?: string;
  hideField?: boolean;
  hideTab?: boolean;
  label?: string;
  nextStep?: string;
  placeholder?: string;
  required?: boolean;
  sizes?: AnyObject;
  max?: number;
  className?: string;
  options?: AnyObject[];
  readonly?: boolean;
}

export interface DrugDetailInterface {
  ndc?: string;
  quantity?: string;
  daysSupply?: string;
  description?: string;
}

export const buildPlaceholder = (placeholder?: string, required?: boolean | undefined) =>
  required ? placeholder ?? '' : placeholder ?? 'optional';

export const buildRequiredValidation = (required?: boolean) => (required ? validatorTypes.REQUIRED : 'not-required');

export const buildMaxValidation = (max?: number) => {
  return { type: validatorTypes.MAX_LENGTH, threshold: max };
};

export const buildMinValidation = (min?: number) => {
  return { type: validatorTypes.MIN_LENGTH, threshold: min };
};

export const buildPatternValidation = (patternType?: string) => {
  switch (patternType) {
    case 'email':
      return {
        type: validatorTypes.PATTERN,
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
        message: 'Email is invalid'
      };
    case 'ptan':
      return {
        type: validatorTypes.PATTERN,
        pattern: /^(?![a-zA-Z]+$)[a-zA-Z0-9]+$/,
        message: 'PTAN needs to be in alphaNumeric  or numeric characters only'
      };
  }
};

export const buildExactLengthValidation = (exact?: number) => {
  return { type: validatorTypes.EXACT_LENGTH, threshold: exact };
};

export const separatorSection = (key: string | number, fieldObj?: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    name: `separator_${key}`,
    component: 'separator',
    ...fieldObj,
    ...opts
  };
};

export const headerSection = (label?: string, fieldObj?: DBFieldSchema, opts?: FieldOpts): Field => {
  const snakeCaseLabel = _.snakeCase(label);
  return {
    name: `${snakeCaseLabel}_section`,
    component: 'section',
    ...fieldObj,
    ...opts
  };
};

export const headerWithPasswordRequirement = (name?: string, fieldObj?: DBFieldSchema, opts?: FieldOpts): Field => {
  const snakeCaseLabel = _.snakeCase(name);
  return {
    component: 'header_with_password_requirement',
    ...baseFieldSection(snakeCaseLabel, fieldObj, opts)
  };
};

export const titleSection = (label?: string, fieldObj?: DBFieldSchema, opts?: FieldOpts): Field => {
  const snakeCaseLabel = _.snakeCase(label);
  return {
    name: `${opts?.name ? opts.name : snakeCaseLabel}_section`,
    component: 'title-section',
    ...fieldObj,
    ...opts
  };
};

export const titleWrapSection = (label?: string, fieldObj?: DBFieldSchema, opts?: FieldOpts): Field => {
  const snakeCaseLabel = _.snakeCase(label);
  return {
    name: `${opts?.name ? opts.name : snakeCaseLabel}_section`,
    component: 'title-wrap-section',
    ...fieldObj,
    ...opts
  };
};

export const spacerSection = (key: string | number, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => ({
  name: `spacer_${key}`,
  component: 'spacer',
  ...opts,
  ...fieldObj
});

export const plainTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'plain-text-section',
    ...baseFieldSection(name, fieldObj, opts)
  };
};
export const plainItalicTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'italic-text-section',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const plainItalicTextSectionWithRawHtml = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'italic-text-section-with-raw-html',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const plainTextSectionWithRawHtml = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'raw-html-text',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const addRemoveFieldsButton = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'add-remove-fields',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldAddressLocationTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'address-location',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPlainTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: componentTypes.PLAIN_TEXT,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPlainTextMultipleSelectSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'plain-text-multiple-select-section',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPlainTextFieldDiagnosisListItems = (
  name: string,
  fieldObj: DBFieldSchema,
  opts?: FieldOpts
): Field => {
  return {
    component: 'plain-text-field-diagnosis-list-items',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPlainTexBoldUnderlined = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'plain-text-bold-underlined',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldTextLabel = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'text-label',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPlainTextTypeSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'type-text-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldTypeAheadTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'typeaheadtext',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldprimaryDiagnosedSelectText = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'plaintextlabel',
    options: [...primaryDiagnosisCodes],
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const inlineCheckBoxWithContent = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'inline-check-box-with-content',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const inlineCheckBoxWithContentAndHyperLink = (
  name: string,
  fieldObj: DBFieldSchema,
  opts?: FieldOpts
): Field => {
  const applicationName = useSelector(clientApplicationNameSelector);
  if (applicationName === 'TaihoProviderPortal') {
    const url = `<a href= "${process.env.PUBLIC_URL + '/documents/Taiho - CareMetx BAA.pdf'}" target="_blank">Here</a>`;
    fieldObj.content = fieldObj.content.replace('[HCP_Portal_Template_Hyper_Link_Code]', url);
  }

  return {
    component: 'inline-check-box-with-content',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldCheckboxSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: componentTypes.CHECKBOX,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldCheckboxSectionForClinicalTab = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'check-field-for-clinical-options',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldRadioSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: componentTypes.RADIO,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldClinicalRadioSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'clinical-radio',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldAdministrationInfo = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'administration-info',
    ...baseFieldSection(name, fieldObj, opts)
  };
};
export const fieldAttestationTypes = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'attestation-types',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldHasInsuranceTogglerSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'has-insurance-toggler',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' }
    ],
    ...baseFieldSection(name, fieldObj, { ...opts, insuranceMandatory: fieldObj?.hideField })
  };
};

export const fieldDateSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    type: 'date',
    component: componentTypes.DATE_PICKER,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldSelectSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    type: 'select',
    component: componentTypes.SELECT,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldSelectStatesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const flag = flagsmith.getValue<Record<string, string>>('states', { json: true });

  const options = flag ? formatToArray(flag) : [...states];

  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options
  };
};

export const fieldSelectSuffixSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...suffix]
  };
};

export const fieldSelectLanguagesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...languages]
  };
};

export const fieldSelectRelationshipsSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const relationshipTypeData = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['relationship.patient'] as AnyType)?.data
  );
  const relationshipTypes = formatToArray(relationshipTypeData);
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...relationshipTypes]
  };
};

export const fieldSelectCardholderRelationshipsSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const applicationName = useSelector(clientApplicationNameSelector);
  if (applicationName === 'TaihoProviderPortal') {
    return {
      ...fieldSelectSection(name, fieldObj, opts),
      options: [...cardHolderRelationshipTahio]
    };
  } else {
    return {
      ...fieldSelectSection(name, fieldObj, opts),
      options: [...cardholderRelationship]
    };
  }
};

export const fieldSelectSupplimentPlanLetter = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...supplimentPlanLetter]
  };
};

export const fieldSelectPrimaryDiagnosisCodesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: getPrimaryDiagnosisCodeData()
  };
};

export const fieldSelectSecondaryDiagnosisCodesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...secondaryDiagnosisCodes]
  };
};

export const fieldSelectTertiaryDiagnosisCodesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...tertiaryDiagnosisCodes]
  };
};

export const fieldDrugDescriptionSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...drugDescriptionType(portal_ApplicationName ?? '')]
  };
};
export const fieldSelectE78DiagnosisCodesSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: getPrimaryDiagnosisCodeData()
  };
};
export const fieldQuantityUnitOfMeasureSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldSelectSection(name, fieldObj, opts),
    options: [...qualityUnitOfMeasure]
  };
};

export const fieldTypeAheadSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'typeahead',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldTypeAheadInsuranceProvidersSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    ...fieldTypeAheadSection(name, fieldObj, opts),
    options: [...insuranceProviders],
    multiple: true
  };
};

export const fieldTypeAheadCptCodeSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const urlDrugId = queryParams.get('drug') ?? '';
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  let drugId = 0;
  const programId = parseInt(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '0');
  if (urlDrugId) {
    drugId = parseInt(urlDrugId);
  } else {
    drugId = newDrugList[0]?.DrugId || 0;
  }
  const services =
    useSelector(tenancyDrugServicesSelector(drugId)) &&
    Object.keys(useSelector(tenancyDrugServicesSelector(drugId))).length !== 0
      ? useSelector(tenancyDrugServicesSelector(drugId))
      : useSelector((state: AppRootState) => state.app?.entities?.features as AnyType);
  const filtered = services?.['filteredList.CPT']?.data;
  const [runFilterQuery, filterResult] = useDrugCptCodeByProgramLazyQuery();
  const portal_name = useSelector((state: AppRootState) => state.app?.config?.name);
  useEffect(() => {
    runFilterQuery({
      variables: {
        programId: programId,
        drugId: filtered?.enabled ? drugId : null
      }
    });
  }, []);

  const cptCode = filterResult?.data?.result;

  const prepareCPTDropdown = (cptList: any) => {
    let cptDropdownList = {};
    if (cptList) {
      cptList.forEach((cpt: DrugCptCode) => {
        const cptCode: string = cpt?.cptCode || '';
        cptDropdownList = { ...cptDropdownList, [cptCode]: `${cpt.cptCode} - ${cpt.cptCodeDescription}` };
      });
    }
    return formatToArray(cptDropdownList);
  };
  const dropdownList = prepareCPTDropdown(cptCode);

  return {
    ...fieldTypeAheadSection(name, fieldObj, opts),
    options: dropdownList,
    multiple: true
  };
};

export const fieldUploadSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'upload',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldUploadNameSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'filename',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const medicscanUploadSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'medicscan',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldTextSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  const typeMap = {
    tax: 'tax',
    tel: 'tel',
    zip: 'zip'
  };
  return {
    type: opts?.type ?? 'text',
    component: (typeMap as AnyObject)[opts?.type] ?? componentTypes.TEXT_FIELD,
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldLast4SSNInput = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const opt = { ...opts, autoComplete: 'off' };
  return {
    component: 'last-4-ssn-input-field',
    ...baseFieldSection(name, fieldObj, opt)
  };
};

export const fieldInputWithInlineLabel = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'input-field-with-inline-label',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldLoginInput = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const opt = { ...opts };
  return {
    component: 'login-input-filed',
    className: 'text-custom-50',
    ...baseFieldSection(name, fieldObj, opt)
  };
};

export const fieldNpiInput = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'npi-input-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldScrollSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'scroll',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldOrganizationNpiSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'npi-organization-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldProviderNpiSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'npi-provider-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldPrescriberAddSearchSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'prescriber-add-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldLocationAddSearchSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'location-add-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldDiagnosisSearchSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const portal_name = useSelector((state: AppRootState) => state.app?.config?.name);
  if (portal_name === 'Regeneron HCP Portal') {
    return {
      component: 'icd-diagnosis-search',
      ...baseFieldSection(name, fieldObj, opts),
      options: [...regeneronDiagnosisCodes]
    };
  }
  return {
    component: 'icd-diagnosis-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldIsPrimaryCardholderToggler = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts): Field => {
  return {
    component: 'is-primary-cardholder-toggler',
    options: [
      { value: 'Y', label: 'Yes' },
      { value: 'N', label: 'No' }
    ],
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldTypeAheadDrugDetails = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const clientId = useSelector((state: AppRootState) => state.app.config?.clientId) || 0;
  const [drugDetails, setDrugDetails] = useState<{ label: string; value: DrugDetailInterface }[]>([]);
  const { search: queryString } = useLocation();
  const drugList = useSelector((state: AppRootState) => state.app?.entities?.drugList) ?? null;
  const queryParams = new URLSearchParams(queryString);
  //check the URL if there is a drug for multiple else get the first drug for single drug tenants
  const drug = queryParams.get('drug') || drugList?.[0]?.DrugId?.toString() || '';

  const [execQuery, { data }] = useDrugDetailsByClientIdLazyQuery({});

  useEffect(() => {
    execQuery({
      variables: { clientId: clientId.toString() }
    });
  }, []);

  useEffect(() => {
    const queryResult: DrugDetailsByClientIdQuery | undefined = data;
    const ndcWithDiscriptionCollection: { label: string; value: DrugDetailInterface }[] = [];
    if (queryResult?.result) {
      const drugInfoByDrugID: any = queryResult?.result.filter((result) => result?.drugId.toString() === drug);
      if (drugInfoByDrugID?.[0]?.drugDetails) {
        drugInfoByDrugID?.[0]?.drugDetails.forEach((drugDetail: any) => {
          const drugDescription = drugDetail.drugDescription || drugInfoByDrugID?.[0]?.drugBrandName;
          const ndcWithDiscription = `${drugDetail.drugNdc} - ${drugDescription}`;
          const drugInfo = {
            ndc: drugDetail?.drugNdc,
            quantity: drugDetail?.quantity,
            daysSupply: drugDetail?.daysSupply,
            description: drugDescription
          };
          ndcWithDiscriptionCollection.push({
            value: drugInfo,
            label: ndcWithDiscription
          });
        });
      }
      setDrugDetails(ndcWithDiscriptionCollection);
    }
  }, [data]);

  return {
    component: 'typeahead-ndc-dropdown',
    ...baseFieldSection(name, fieldObj, opts),
    options: [...drugDetails],
    multiple: false
  };
};

export const fieldTypeAheadPayer = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const [execQuery, { data }] = usePayersLazyQuery({});
  const [payers, setPayers] = useState<{ label: string; value: string[] }[]>([]);
  const categories = fieldObj?.categories ?? 'RX,MEDICAL,PBM,OTHER';

  useEffect(() => {
    execQuery({
      variables: {
        categories: categories,
        payerName: ''
      }
    });
  }, []);

  useEffect(() => {
    const queryResult: PayersQuery | undefined = data;
    const payerOptions: { label: string; value: string[] }[] = [];
    if (queryResult?.result) {
      queryResult?.result?.forEach((payer) => {
        if (payer?.payerId && payer?.payerName) {
          payerOptions.push({
            value: [
              payer?.payerId.toString(),
              payer?.medePayerId ?? payer?.payerId.toString(),
              payer?.medPAPayerId ?? ''
            ],
            label: payer?.payerName
          });
        }
      });
      setPayers(payerOptions);
    }
  }, [data]);

  return {
    component: 'typeahead-with-dropdown-icon',
    ...baseFieldSection(name, fieldObj, opts),
    options: [...payers],
    multiple: false
  };
};

export const fieldPlanName = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'plan-name-text-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const fieldSignature = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'signature-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const plainSignature = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'plain-signature-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

export const plainSignatureDate = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'plain-signature-date-field',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

const baseFieldSection = (name: string, fieldObj?: DBFieldSchema, opts?: FieldOpts) => {
  if (!fieldObj) {
    fieldObj = { label: '', hideField: true };
  }
  if (fieldObj.hideField) {
    return { name, ...fieldObj, ...opts };
  }
  if (fieldObj.readonly) {
    return { name, ...fieldObj, ...opts, readonly: 'readonly' };
  }

  const validateType = validationTypes(name, fieldObj, opts);
  return {
    name,
    placeholder: buildPlaceholder(fieldObj?.placeholder, fieldObj?.required),
    ...fieldObj,
    ...opts,
    initialValue: fieldObj?.initialValue ?? fieldObj?.value ?? opts?.initialValue,
    validate: validateType
  };
};

export const dataDrivenFormFieldSection = (name: string, fieldObj: DBDataDrivenFieldSchema, opts?: FieldOpts) => {
  if (!fieldObj) {
    fieldObj = { label: '', hideField: true };
  }
  if (fieldObj.hideField) {
    return { name, ...fieldObj, ...opts };
  }
  return {
    name,
    placeholder: buildPlaceholder(fieldObj?.placeholder, fieldObj?.required),
    required: fieldObj?.required ?? false,
    ...fieldObj,
    ...opts
  };
};

const validationTypes = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  const isRequired = !fieldObj?.hideField && (opts?.required ?? fieldObj?.required);
  const isMax = !fieldObj?.hideField && (opts?.max ?? fieldObj?.max);
  const isMin = !fieldObj?.hideField && (opts?.min ?? fieldObj?.min);
  const isExact = !fieldObj?.hideField && (opts?.exact ?? fieldObj?.exact);
  const isPattern = !fieldObj?.hideField && (opts?.pattern ?? fieldObj?.pattern);
  const validateNPI = !fieldObj?.hideField && (opts?.npiValidation ?? fieldObj?.npiValidation);
  const validationType = [];
  if (validateNPI) validationType.push(prescriberNpiValidation);

  if (isMax && isMin && isPattern) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildMinValidation(isMin) });
    validationType.push({ ...buildPatternValidation(isPattern) });
    validationType.push({ ...buildMaxValidation(isMax) });
    validationType.push(...(opts?.validate ?? []));
  } else if (isMax && isMin) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildMaxValidation(isMax) });
    validationType.push({ ...buildMinValidation(isMin) });
    validationType.push(...(opts?.validate ?? []));
  } else if (isMax) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildMaxValidation(isMax) });
    validationType.push(...(opts?.validate ?? []));
  } else if (isExact) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildExactLengthValidation(isExact) });
    validationType.push(...(opts?.validate ?? []));
  } else if (isMin) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildMinValidation(isMin) });
    validationType.push(...(opts?.validate ?? []));
  } else if (isPattern) {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push({ ...buildPatternValidation(isPattern) });
    validationType.push(...(opts?.validate ?? []));
  } else {
    validationType.push({ type: buildRequiredValidation(isRequired) });
    validationType.push(...(opts?.validate ?? []));
  }
  validationType.push(...(fieldObj?.validate ?? []));
  return validationType;
};

export const compareAndGetNonHiddenField = (field1: string, field2: string, schema?: DBFormSchema): string | null => {
  return schema?.[field1]?.hideField === true ? (schema?.[field2]?.hideField === true ? null : field2) : field1;
};

export const buildPlaceOfService = (field?: string, portalName?: string) => {
  const placeOfServiceType = placeOfService(portalName);
  if (placeOfServiceType) {
    for (const val of placeOfServiceType) {
      if (val.value === field) {
        return val.label;
      }
    }
  }
  return null;
};

export const getPrimaryDiagnosisCodeData = () => {
  const [dynamicICD, setDynamicICD] = useState<{ label?: string; value?: string | null | undefined }[]>([]);
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const urlDrugId = queryParams.get('drug') ?? '';
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  const programId = parseInt(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '0');
  let drugId: number | undefined = undefined;
  if (urlDrugId) {
    drugId = parseInt(urlDrugId);
  } else {
    drugId = newDrugList[0]?.DrugId;
  }

  const services =
    useSelector(tenancyDrugServicesSelector(drugId)) &&
    Object.keys(useSelector(tenancyDrugServicesSelector(drugId))).length !== 0
      ? useSelector(tenancyDrugServicesSelector(drugId))
      : useSelector((state: AppRootState) => state.app?.entities?.features as AnyType);
  const filtered = services?.['filteredList.ICD']?.data;
  const [execQuery, { data }] = usePatientDiagnosisByProgramLazyQuery({});
  useEffect(() => {
    execQuery({
      variables: {
        iCDCode: '',
        programId: programId,
        drugId: drugId || 0
      }
    });
  }, []);

  useEffect(() => {
    let tempIcd = dynamicICD;
    if (data?.result && data?.result?.length > 0) {
      data?.result?.map((d) => {
        tempIcd = [...tempIcd, { label: `${d?.icdcode} ${d?.icddescription}`, value: d?.icdcode }];
      });
      if (programId !== 250) {
        tempIcd = [...tempIcd, { label: 'Other', value: 'other' }];
      }
    }
    setDynamicICD(tempIcd);
  }, [data?.result]);
  return filtered?.enabled ? dynamicICD : primaryDiagnosisCodes;
};

//prescriber search only
export const fieldPrescriberSearchSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'prescriber-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};
export const fieldRXPrescriberSearchSection = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    component: 'prescriberRx-search',
    ...baseFieldSection(name, fieldObj, opts)
  };
};

//date-picker with time
export const fieldSelectionDateTime = (name: string, fieldObj: DBFieldSchema, opts?: FieldOpts) => {
  return {
    type: 'date',
    component: componentTypes.TIME_PICKER,
    dateFormat: 'MM/dd/yyyy h:mm aa',
    ...baseFieldSection(name, fieldObj, opts)
  };
};
