import React from 'react';

import { AnyType } from '../../interfaces';
import { CareCoordinatorTable } from './CareCoordinatorTable';
import { FieldReimbursementManagerTable } from './FieldReimbursementManagerTable';
import { AppRootState } from '../../redux';
import { useSelector } from 'react-redux';

export type ResourcesContactInformationProp = {
  contactInfoConfig: AnyType;
};

export const ResourcesContactInformationTab = (props: ResourcesContactInformationProp) => {
  const contactInfoConfig: AnyType = props?.contactInfoConfig;

  const resources_contactinfo_therapy_title = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.resources as AnyType) ?? {})['resources_contactinfo_therapy_title'] as AnyType
  );

  const resources_contactinfo_coordinator_title = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.resources as AnyType) ?? {})['resources_contactinfo_coordinator_title'] as AnyType
  );

  return (
    <>
      {contactInfoConfig?.fieldReimbursementManagerTab?.enabled && (
        <div className='mt-3'>
          <p className='text-uppercase section-text'>
            {resources_contactinfo_therapy_title?.data?.therapy_access_manager_title}
          </p>
          <div>
            <FieldReimbursementManagerTable />
          </div>
        </div>
      )}
      {contactInfoConfig?.careCoordinatorTab?.enabled && (
        <div className='mt-3'>
          <p className='text-uppercase section-text'>
            {resources_contactinfo_coordinator_title?.data?.care_coordinator_title}
          </p>
          <div>
            <CareCoordinatorTable />
          </div>
        </div>
      )}
    </>
  );
};
