import { AnyObject } from 'final-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyType = any;

export interface ActionItem {
  type: string;
  request_id: string;
  submitted: string;
  prescriber_name: string;
  status: StatusEnum;
  next_steps: string;
}

export enum AttestationType {
  Electronic = 'electronic',
  Manual = 'manual'
}

export interface Breadcrumb {
  href?: string;
  label: string;
  onClick?: () => void;
  testId?: string;
}

export interface Document {
  name: string;
  type: string;
  product: string;
  created_date: string;
}

export interface PharmacyEBVRequestData {
  PatientFirstName: string;
  PatientMiddleName?: string;
  PatientLastName: string;
  PatientGender: 'M' | 'F' | 'U';
  PatientDOB: string;
  PatientSuffix?: string;
  PatientAddress1: string;
  PatientAddress2?: string;
  PatientCity: string;
  PatientState: string;
  PatientZip: string;
  PatientMobile?: string;
  PhysicianFirstName: string;
  PhysicianLastName: string;
  PhysicianNPI: string;
  DrugNDC: string;
  MedicareID: string;
  SSN: string;
  ExternalAccountID: string;
  ExternalPatientID: string;
  CustomRedirectUrl1?: string;
  PharmacyNCPDPID?: number;
}

export interface PharmacyEPARequestData {
  PatientFirstName: string;
  PatientMiddleName?: string;
  PatientLastName: string;
  PatientGender: 'M' | 'F' | 'U';
  PatientDOB: string;
  PatientSuffix?: string;
  PatientPrefix?: string;
  PatientPhone?: number;
  PatientAddress1: string;
  PatientAddress2?: string;
  PatientCity: string;
  PatientState: string;
  PatientZip?: number;
  PhysicianFirstName: string;
  PhysicianMiddleName?: string;
  PhysicianLastName: string;
  PhysicianNPI: string;
  PhysicianSuffix?: string;
  PhysicianPrefix?: string;
  PhysicianPhone: number;
  PhysicianFax: number;
  PhysicianAddressLine1: string;
  PhysicianAddressLine2?: string;
  PhysicianCity: string;
  PhysicianState: string;
  PhysicianZip: number;
  PhysicianPracticeName?: string;
  PhysicianPracticeNPI?: number;
  DrugDescription: string;
  DrugNDC: string;
  DrugQuantity?: string;
  DrugDaysSupply?: string;
  ExternalAccountID: string;
  ExternalPatientID: string;
  CustomRedirectUrl1?: string;
  CareMetxPayerID?: string;
}

export interface FilePreview {
  bytes: string | ArrayBuffer | null;
  path: string;
  preview: string;
}

export interface GeneralObject {
  [key: string]: string | number | string[] | unknown[] | unknown;
}

export interface HcpSelectProps {
  [key: string]: AnyType;
  label?: string;
  name: string;
  placeholder?: string;
}

export interface Patient {
  actionAlertCount: number;
  authorizationReceived: boolean;
  dob: string;
  firstName: string;
  gender: string;
  lastName: string;
  location: string;
  middleName: string;
  nextStep: string;
  patientId: number;
  physicianFirstName: string;
  physicianLastName: string;
  updateAlertCount: number;
}

export interface Prescriber {
  email?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  npi?: string;
  physicianId?: string;
  physiciansDetail?: {
    created?: string;
    lastUpdated?: string;
    physiciansDetailId?: string;
    taxId?: string;
  };
  physicianStateLicenses?: {
    created?: string;
    expirationDate?: string;
    lastUpdated?: string;
    licenseState?: string;
    physicianStateLicenseId?: string;
    stateLicenseNumber?: string;
  };
  prefix?: string;
  suffix?: string;
}

export interface Request {
  actionAlert: boolean;
  authorizationReceived: boolean;
  copayCardId: string | null;
  displayOnGrid: number;
  documents: Array<AnyType>;
  id: string;
  nextStep: string;
  patientDOB: string | null;
  patientFirstName: string;
  patientId: string;
  patientLastName: string;
  physicianFirstName: string;
  physicianLastName: string;
  requestStartDate: string;
  requestStatus: string;
  requestType: string;
  shippedDate: string | null;
  shippedStatus: string;
  statusText: string;
  updateAlert: boolean;
  updatedAlertDate: string;
}

export interface RequestHistory {
  type: string;
  request_id: string;
  submitted: string;
  prescriber_name: string;
  status: StatusEnum;
  next_steps: string;
}

export interface RxBasePayload {
  ClientApplication: string;
  UserName: string;
  RequestData: string | PharmacyEBVRequestData | PharmacyEPARequestData;
}

export interface ServiceOption {
  featureKey: string;
  label: string;
  value: string;
  description: string;
  disabled?: boolean;
}

export enum ServiceOptionType {
  All = 'all',
  FieldReimbursementManager = 'field-reimbursement-manager',
  CopayClaim = 'copay-claim',
  CopayEnrollment = 'copay-enrollment',
  ElectronicBenefitVerification = 'electronic-benefit-verification',
  InsuranceReview = 'insurance-review',
  MedicalElectronicBenefitVerification = 'medical-ebv',
  MedicalElectronicPriorAuthorization = 'medical-epa',
  PatientResources = 'patient-resources',
  PharmacyElectronicBenefitVerification = 'pharmacy-ebv',
  PharmacyElectronicPriorAuthorization = 'pharmacy-epa'
}

export enum AutomatedServiceOptionType {
  AUTO_MED_EBV = `auto-medebv`,
  AUTO_MED_EPA = `auto-medepa`
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface UseDataHookResponse {
  loading: boolean;
  data: AnyObject | null;
  error?: string | null;
  fetchData?: AnyType | null;
}

export interface User {
  firstName: string;
  lastName: string;
  userName: string;
  userType: string;
  email: string;
  isActive: boolean;
  isAdmin: boolean;
  careMetxRecordId: number;
  careMetxRecordType: string;
  created: string;
}

export interface Location {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  fax: string;
  id: string;
  name: string;
  organizationId: number;
  phone1: string;
  phone1Type: string;
  phone2: string;
  phone2Type: string;
  state: string;
  zip: string;
}

export interface Message {
  assignedTo: string;
  created: string;
  isRead: boolean;
  lastMessageDate: string;
  lastMessageSource: string;
  messageText: string;
  organizationName: string;
  patientDOB: string;
  patientFirstName: string;
  patientID: number;
  patientLastName: string;
  patientName: string;
  providerName: string;
  sender: string;
  source: string;
  status: string;
  subject: string;
  therapy: string;
  threadID: number;
}

export interface OktaConfig {
  clientId: string;
  issuer: string;
  redirectUri: string;
  scopes: string[];
  responseType?: string[];
  pkce?: boolean;
}

export enum StatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  UNREGISTERED = 'unregistered'
}

// Multi-tenancy

export interface DashboardContentSection {
  body?: string;
  grid: DashboardContentGridItem[];
  header: string;
}
export interface DashboardContentGridItem {
  action: {
    hash?: string;
    label: string;
    pathname: string;
    target?: string;
  };
  content: string;
  subtitle?: string;
  title: string;
}
export interface DashboardContent {
  sections: DashboardContentSection[];
}

export interface PhoneNumber {
  phoneNumber: string;
  phoneType: string;
  okToLeaveMessage: boolean;
}
export interface CustomSvgIcon {
  tabIndex?: number;
  className?: string;
  fillColor?: string;
  id?: string;
  title?: string;
  height?: number;
}

export type SelectedRequestInfo = {
  id: string;
  drugName: string;
  requestType: string;
  requestStatus: string;
  authorizationReceived: boolean;
};
