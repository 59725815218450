import { Dropdown } from 'react-bootstrap';
import { Sliders } from 'react-bootstrap-icons';
import React from 'react';
import './GridFilterDropdown.scss';

interface FiltersDropdownProps {
  renderFilterOptions: (filter: string | null) => void;
  filterOptions: [any];
}

export const GridFilterDropdown = (props: FiltersDropdownProps) => {
  const handleFilterClick = (e: string | null) => {
    props.renderFilterOptions(e);
  };

  return (
    <Dropdown
      className='filter-dropdown'
      style={{ float: 'right', border: 'none', color: 'primary' }}
      onSelect={handleFilterClick}>
      <Dropdown.Toggle className='text-primary border-0' variant='link' id='dropdown-basic'>
        <Sliders /> FILTERS
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu'>
        {props.filterOptions.map((filterOption, i) => {
          return filterOption?.statusDescription ? (
            <>
              <Dropdown.Item key={`${i}`} eventKey={`${filterOption?.statusDescription}`}>
                {filterOption?.statusDescription}
              </Dropdown.Item>
            </>
          ) : null;
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
