export const FIELD_NAMES = {
  patientFields: {
    id: 'patient_id',
    addressId: 'patient_address_id',
    address1: 'patient_address_1',
    address2: 'patient_address_2',
    city: 'patient_city',
    dob: 'patient_dob',
    email: 'patient_email',
    externalId: 'patient_external_id',
    editableEmail: 'patient_email_editable',
    firstName: 'patient_first_name',
    gender: 'patient_gender',
    genderOther: 'patient_gender_other',
    hasInsurance: 'patient_has_insurance',
    hasCaregiver: 'patient_has_caregiver',
    homeNumberId: 'patient_home_number_id',
    homeNumber: 'patient_home_number',
    homeNumberType: 'Home',
    insurances: 'patient_insurances',
    lastName: 'patient_last_name',
    last4SSN: 'patient_last_4_SSN',
    messageOption: 'patient_message_option',
    middleName: 'patient_middle_name',
    mobileNumberId: 'patient_mobile_number_id',
    mobileNumber: 'patient_mobile_number',
    mobileNumberType: 'Mobile',
    otherInsurance: 'patient_other_insurance',
    otherLanguage: 'patient_other_language',
    otherGender: 'patient_other_gender',
    preferredLanguage: 'patient_preferred_language',
    preferredPhone: 'patient_preferred_phone',
    state: 'patient_state',
    workNumber: 'patient_work_number',
    workNumberType: 'Work',
    zip: 'patient_zip',
    careGiverFirstName: 'caregiver_first_name',
    caregiverMiddlName: 'caregiver_middle_name',
    caregiverLastName: 'caregiver_last_name',
    caregiverEmail: 'caregiver_email',
    caregiverHomePhone: 'caregiver_home_phone',
    caregiverMobilePhone: 'caregiver_mobile_phone',
    caregiverPatientRelationship: 'caregiver_patient_relationship',
    caregiverIsAuthorizedRepresentative: 'caregiver_is_authorized_representative',
    caregiverLegallyAuthRep: 'caregiver_legally_auth_rep'
  },
  diagnosisFields: {
    diagnosisCode: 'diagnosis_code',
    diagnosisDescription: 'diagnosis_description'
  },
  insuranceFields: {
    medicareBeneficiaryIDNumber: 'medicare_beneficiary_id_number',
    medicareBeneficiaryIDNumberOptional: 'medicare_beneficiary_id_number_optional',
    eitherBiOrLast4SsnText: 'either_bi_or_last_4_ssn_text',
    last4SSN: 'last_4_SSN',
    last4SSNOptional: 'last_4_SSN_optional',
    medicscanUpload: 'medicscan_upload',
    cardUpload: 'insurance_card_upload',
    cardUploadOpt: 'insurance_card_upload_opt',
    medicaidPending: 'medicaid_pending',
    otherPharmacyCardholderId: 'other_pharmacy_cardholder_id',
    otherPharmacyName: 'other_pharmacy_plan_name',
    otherPharmacyPayerName: 'other_pharmacy_payer_name',
    otherPharmacyPhoneNumber: 'other_pharmacy_phone_number',
    otherPharmacyRxBinIin: 'other_pharmacy_rx_bin_iin',
    otherPharmacyRxGroupNumber: 'other_pharmacy_rx_group_number',
    otherPharmacyRxPcn: 'other_pharmacy_rx_pcn',
    otherPlanCardholderId: 'other_plan_cardholder_id',
    otherPolicyHolderName: 'other_policy_holder_name',
    otherPlanGroupNumber: 'other_plan_group_number',
    otherPlanName: 'other_plan_name',
    otherPlanPayerName: 'other_plan_payer_name',
    otherPlanPhoneNumber: 'other_plan_phone_number',
    pharmacyCardholderId: 'pharmacy_cardholder_id',
    pharmacyCardholderIdOpt: 'pharmacy_cardholder_id_opt',
    pharmacyName: 'pharmacy_plan_name',
    pharmacyPayerName: 'pharmacy_payer_name',
    pharmacyPhoneNumber: 'pharmacy_phone_number',
    pharmacyRxBinIin: 'pharmacy_rx_bin_iin',
    pharmacyRxGroupNumber: 'pharmacy_rx_group_number',
    pharmacyRxGroupNumberOpt: 'pharmacy_rx_group_number_opt',
    pharmacyRxPcn: 'pharmacy_rx_pcn',
    primaryPlanCardholderId: 'primary_plan_cardholder_id',
    primaryPlanCardholderIdOpt: 'primary_plan_cardholder_id_opt',
    primaryPlanGroupNumber: 'primary_plan_group_number',
    primaryPlanGroupNumberOpt: 'primary_plan_group_number_opt',
    primaryPlanName: 'primary_plan_name',
    primaryPlanPayerName: 'primary_plan_payer_name',
    primaryPlanPayerId: 'primary_plan_payer_id',
    primaryPlanMedePayerId: 'primary_plan_medePayerId',
    primaryPlanMedPAPayerId: 'primary_plan_medPAPayerId',
    primaryPlanPhoneNumber: 'primary_plan_phone_number',
    primaryIsCardholder: 'primary_is_cardholder',
    primaryMedicareBeneficiaryIdNumber: 'primary_medicare_beneficiary_id_number',
    primaryPolicyHolderName: 'primary_policy_holder_name',
    primarySubscriberFirstName: 'primary_subscriber_first_name',
    primarySubscriberLastName: 'primary_subscriber_last_name',
    primarySubscriberDob: 'primary_subscriber_dob',
    primarySubscriberGender: 'primary_subscriber_gender',
    primaryEmployer: 'primary_employer',
    primarySubscriberRelationship: 'primary_subscriber_relation_to_cardholder',
    secondaryPharmacyCardholderId: 'secondary_pharmacy_cardholder_id',
    secondaryPharmacyName: 'secondary_pharmacy_plan_name',
    secondaryPharmacyPayerName: 'secondary_pharmacy_payer_name',
    secondaryPharmacyPhoneNumber: 'secondary_pharmacy_phone_number',
    secondaryPharmacyRxBinIin: 'secondary_pharmacy_rx_bin_iin',
    secondaryPharmacyRxGroupNumber: 'secondary_pharmacy_rx_group_number',
    secondaryPharmacyRxPcn: 'secondary_pharmacy_rx_pcn',
    secondaryPlanCardholderId: 'secondary_plan_cardholder_id',
    secondaryPlanGroupNumber: 'secondary_plan_group_number',
    secondaryPlanName: 'secondary_plan_name',
    secondaryPlanPayerName: 'secondary_plan_payer_name',
    secondaryPlanPayerId: 'secondary_plan_payer_id',
    secondaryPlanMedePayerId: 'secondary_plan_medePayerId',
    secondaryPlanMedPAPayerId: 'secondary_plan_medPAPayerId',
    secondaryPlanPhoneNumber: 'secondary_plan_phone_number',
    secondaryIsCardholder: 'secondary_is_cardholder',
    secondaryPolicyHolderName: 'secondary_policy_holder_name',
    secondarySubscriberFirstName: 'secondary_subscriber_first_name',
    secondarySubscriberLastName: 'secondary_subscriber_last_name',
    secondarySubscriberDob: 'secondary_subscriber_dob',
    secondarySubscriberDobOpt: 'secondary_subscriber_dob_opt',
    secondarySubscriberGender: 'secondary_subscriber_gender',
    secondaryPlanCardholderIdOpt: 'secondary_plan_cardholder_id_opt',
    secondaryPlanGroupNumberOpt: 'secondary_plan_group_number_opt',
    secondaryEmployer: 'secondary_employer',
    secondarySubscriberRelationship: 'secondary_subscriber_relation_to_cardholder',
    secondarySubscriberRelationshipOpt: 'secondary_subscriber_relation_to_cardholder_opt',
    secondaryIsMedicalSupplementPlan: 'medical_supplement_plan',
    secondaryMedicalSupplementPlanLetter: 'medical_supplement_plan_letter',
    tertiaryPharmacyCardholderId: 'tertiary_pharmacy_cardholder_id',
    tertiaryPharmacyName: 'tertiary_pharmacy_plan_name',
    tertiaryPharmacyPayerName: 'tertiary_pharmacy_payer_name',
    tertiaryPharmacyPhoneNumber: 'tertiary_pharmacy_phone_number',
    tertiaryPharmacyRxBinIin: 'tertiary_pharmacy_rx_bin_iin',
    tertiaryPharmacyRxGroupNumber: 'tertiary_pharmacy_rx_group_number',
    tertiaryPharmacyRxPcn: 'tertiary_pharmacy_rx_pcn',
    tertiaryPlanCardholderId: 'tertiary_plan_cardholder_id',
    tertiaryPlanGroupNumber: 'tertiary_plan_group_number',
    tertiaryPlanName: 'tertiary_plan_name',
    tertiaryPlanType: 'tertiary_plan_type',
    tertiaryPlanPayerName: 'tertiary_plan_payer_name',
    tertiaryPlanPayerId: 'tertiary_plan_payer_id',
    tertiaryPlanMedePayerId: 'tertiary_plan_medePayerId',
    tertiaryPlanMedPAPayerId: 'tertiary_plan_medPAPayerId',
    tertiaryPlanPhoneNumber: 'tertiary_plan_phone_number',
    tertiaryIsCardholder: 'tertiary_is_cardholder',
    tertiarySubscriberFirstName: 'tertiary_subscriber_first_name',
    tertiarySubscriberLastName: 'tertiary_subscriber_last_name',
    tertiarySubscriberDob: 'tertiary_subscriber_dob',
    tertiarySubscriberGender: 'tertiary_subscriber_gender',
    tertiaryPlanCardholderIdOpt: 'tertiary_plan_cardholder_id_opt',
    insuranceInfoBillingPreference: 'insurance_info_billing_preference'
  },
  rxAdminDoseFields: {
    patientId: 'patient_id',
    drug_id: 'drug_id',
    product: 'product_name',
    dose: 'rx_dose',
    adminDate: 'rx_administration_date',
    status: 'status',
    prescriberId: 'prescriber_id',
    notes: 'notes',
    patientName: 'patient_name',
    prescriberName: 'prescriber_name',
    prescriberViewName: 'prescriberView_name',
    npi: 'prescriber_npi'
  },
  prescriberFields: {
    email: 'prescriber_email',
    fax: 'prescriber_fax',
    firstName: 'prescriber_first_name',
    id: 'prescriber_id',
    lastName: 'prescriber_last_name',
    licensingState: 'prescriber_licensing_state',
    locationId: 'prescriber_location_id',
    locationAddress1: 'prescriber_location_address_1',
    locationAddress2: 'prescriber_location_address_2',
    locationAddressCity: 'prescriber_location_city',
    locationAddressState: 'prescriber_location_state',
    locationAddressZip: 'prescriber_location_zip',
    locationAddressFull: 'prescriber_location_address_full',
    locationPhone1: 'prescriber_location_phone_1',
    locationPhone1Type: 'prescriber_location_phone_1_type',
    locationPhone2: 'prescriber_location_phone_2',
    locationPhone2Type: 'prescriber_location_phone_2_type',
    locationFaxNumber: 'prescriber_location_fax_number',
    locationName: 'prescriber_location_name',
    middleName: 'prescriber_middle_name',
    npi: 'prescriber_npi',
    phone: 'prescriber_phone',
    stateLicenseNumber: 'prescriber_state_license_number',
    suffix: 'prescriber_suffix',
    taxId: 'prescriber_tax_id',
    isNewPrescriber: 'is_new_prescriber',
    isNewLocationAddress: 'is_new_locationaddress',
    dashes: 'dashes',
    locationDashes: 'location_dashes',
    ptan: 'prescriber_ptan',
    dea: 'prescriber_dea',
    cellNumber: 'prescriber_cell_number',
    hin: 'prescriber_hin'
  },
  organizationFields: {
    name: 'organization_name',
    npi: 'organization_npi',
    officeContactFirstName: 'organization_office_contact_first_name',
    officeContactLastName: 'organization_office_contact_last_name',
    officeContactPhone: 'organization_office_contact_phone',
    taxId: 'organization_tax_id',
    officeContactFax: 'organization_office_contact_fax',
    officeContactEmail: 'organization_office_contact_email',
    portalApplicationName: 'portal_application_name'
  },
  clinicalFields: {
    diagnosisCode: 'clinical_treatment_diagnosis_code',
    otherDiagnosisCode: 'clinical_treatment_other',
    treatmentStartDate: 'clinical_treatment_start_date',
    previouslyEnrolled: 'clinical_patient_previously_enrolled',
    diagnosed: 'clinical_patient_diagnosed',
    primaryDiagnosed: 'clinical_patient_primary_diagnosed',
    primaryDiagnosedSelect: 'clinical_patient_primary_diagnosed_select',
    primaryDiagnosedSelectAnother: 'clinical_patient_primary_diagnosed_select_another',
    primaryDiagnosedSelectOptional: 'clinical_patient_primary_diagnosed_select_optional',
    secondaryDiagnosed: 'clinical_patient_secondary_diagnosed',
    tertiaryDiagnosed: 'clinical_patient_tertiary_diagnosed',
    ndc: 'clinical_ndc_drug_name',
    qty: 'clinical_qty',
    daysSupply: 'clinical_days_supply',
    cptCodes: 'clinical_cpt_code',
    placeOfService: 'clinical_place_of_service',
    nextTreatmentDate: 'clinical_next_treatment_date',
    cptCode1: 'clinical_cpt_code1',
    cptCode2: 'clinical_cpt_code2',
    cptCode3: 'clinical_cpt_code3',
    cptCode4: 'clinical_cpt_code4',
    cptCode5: 'clinical_cpt_code5',
    treatmentInfoBillingPreference: 'treatment_info_billing_preference',
    treatmentInfoSampleProductAdministered: 'treatment_info_sample_product_administered',
    treatmentInfoDateProductNeeded: 'treatment_info_date_product_needed',
    treatmentInfoAnyKnownDrugAllergies: 'treatment_info_any_known_drug_allergies',
    treatmentInfoAllergies: 'treatment_info_allergies',
    treatmentInfoContraindications: 'treatment_info_contraindications',
    treatmentInfoPatientStartedTreatment: 'treatment_info_patient_started_treatment',
    visualAccuityRight: 'clinical_visual_accuity_right',
    visualAccuityLeft: 'clinical_visual_accuity_left',
    addPreviousTreatment: 'clinical_add_previous_treatment',
    previousStartDate: 'clinical_previous_start_date',
    previousEndDate: 'clinical_previous_end_date',
    previousDose: 'clinical_previous_dose',
    previousTreatment: 'clinical_previous_treatment',
    previousFrequency: 'clinical_previous_frequency',
    previousStartDate1: 'clinical_previous_start_date1',
    previousEndDate1: 'clinical_previous_end_date1',
    previousDose1: 'clinical_previous_dose1',
    previousTreatment1: 'clinical_previous_treatment1',
    previousFrequency1: 'clinical_previous_frequency1',
    previousStartDate2: 'clinical_previous_start_date2',
    previousEndDate2: 'clinical_previous_end_date2',
    previousDose2: 'clinical_previous_dose2',
    previousTreatment2: 'clinical_previous_treatment2',
    previousFrequency2: 'clinical_previous_frequency2',
    optIn: 'clinical_optIn',
    injectionDate: 'injection_date',
    secondaryDiagnosisAlert: 'secondary_diagnosis_alert',
    patientMeetingCriteria: 'patients_meeting_criteria',
    patientMeetingCriteriaOther: 'patients_meeting_criteria_other',
    clinicalOtherSelectedHeader: 'clinical_other_selected_header',
    clinicalOtherSelectedHeaderOptional: 'clinical_other_selected_header_Optional',
    hefhSelectedOptionsOptional: 'hefh_selected_options_optional',
    hefhSelectedOptions: 'hefh_selected_options',
    clinicalHefhOtherSelected: 'clinical_hefh_other_selected',
    clinicalHefhOtherSelectedOptional: 'clinical_hefh_other_selected_Optional',
    patientsMeetingCriteriaOtherHeader: 'patients_meeting_criteria_other_header',
    E78Diagnosed: 'clinical_diagnosed_E78',
    E78Select: 'clinical_patient_E78_diagnosed_select',
    E7801: 'clinical_diagnosed_E7801',
    OtherICD10CM: 'clinical_diagnosed_OtherICD10CM',
    clinicalOther: 'clinical_diagnosed_Other',
    clinicalZ8342: 'clinical_diagnosed_Z8342',
    clinicalE755: 'clinical_diagnosed_E755',
    clinicalOtherspecifyICD: 'clinical_diagnosed_Other_Specify_ICD',
    clinicalOtherICD: 'clinical_diagnosed_Specify_ICD_Other',
    clinicalPatientSecondaryheader: 'patients_secondary_diagnosis_header',
    clinicalSupportText: 'clinical_support_text',
    clinicalAscvd: 'clinical_ASCVD_diagnosis',
    clinicalOtherRiskFactors: 'clinical_other_Risk_Factors',
    clinical12: 'clinical_IschemicHeart_12_diagnosis',
    clinical12TextOne: 'clinical_IschemicHeart_Text_one',
    clinical12TextTwo: 'clinical_IschemicHeart_Text_two',
    clinicalIschemicHeartLabel: 'clinical_IschemicHeart_Title',
    clinicalDiabetes11Diagnosis: 'clinical_diabetes_mellitus_11_diagnosis',
    clinicalDiabetesMellitus: 'clinical_diabetes_mellitus',
    clinicalDiabetesMellitusTitle: 'clinical_diabetes_mellitus_Title',
    clinical16CerebrovascularDiagnosis: 'clinical_cerebrovascular_disease_16_diagnosis',
    clinical16CerebrovascularTextOne: 'clinical_cerebrovascular_disease_Text_one',
    clinical16CerebrovascularTexttwo: 'clinical_cerebrovascular_disease_Text_two',
    clinicalcerebrovascularLabel: 'clinical_cerebrovascular_disease_Title',
    clinicalHyperTensionI10Diagnosis: 'clinical_hyper_tension_I10_diagnosis',
    clinicalHyperTension: 'clinical_hyper_tension',
    clinicalHyperTensionTitle: 'clinical_hyper_tension_Title',
    clinicalAtherosClerosisI70Diagnosis: 'clinical_atherosclerosis_I70_diagnosis',
    clinicalAtherosClerosisText: 'clinical_atherosclerosis_Text',
    clinicalAtherosClerosisLabel: 'clinical_atherosclerosis_Title',
    clinicalSpecifyICD10cmDiagnosis: 'clinical_other_specifyICD_10_cm_diagnosis',
    clinicalOther_SpecifyICD_10_cm: 'clinical_other_specifyICD_10_cm',
    clinicalOtherPeripheralVasculari73Diagnosis: 'clinical_other_peripheral_vascular_i73_diagnosis',
    clinicalPeripheral1Vasculari173: 'clinical_other_peripheral_Text',
    clinicalOtherPeripheralDiseaseTitle: 'clinical_other_peripheral_disease_Title',
    clinicalOtherSpecifyTextSection: 'clinical_other_specifyICD',
    clinicalOtherSpecifyICD: 'clinical_others_specifyICD',
    clinicalOthersSpecifyICDText: 'clinical_others_specifyICD_Text',
    clienicalLdlLevelHeader: 'clinical_LDL_c_level_header',
    clienicalLdlLevel: 'clinical_current_ldl_level',
    clienicalLdlText: 'clinical_current_ldl_Text',
    clinicalDateTaken: 'clinical_date_taken',
    clinicalDateTakenText: 'clinical_date_taken_text',
    clinicalLDLTreatmentLabel: 'clinical_current_ldl_Treatment_Label',
    clinicalLDLTratment: 'clinical_current_ldl_Treatment_text',
    clinicalSpecifyOuterText: 'clinical_specifiy_OtherICD10CM',
    clinicalOtherspecifyICDSelection: 'clinical_others_specifyICD'
  },
  prescriptionFields: {
    prescriptionMandatoryNotice: 'prescription_mandatory_notice',
    ndc: 'prescription_ndc_code',
    qty: 'prescription_qty',
    uom: 'prescription_unit_of_measure',
    daysSupply: 'prescription_days_supply',
    numberOfRefills: 'prescription_number_of_refills',
    effectiveDate: 'prescription_effective_date',
    allowSubstitutions: 'prescription_allow_substitutions',
    numberOfPeriodontalSites: 'number_of_periodontal_sites',
    directionForUse: 'prescription_direction_for_use',
    additionalNotes: 'prescription_additional_notes',
    signature: 'prescription_signature',
    signatureDate: 'prescription_signature_date',
    signingAttestation: 'prescription_signing_attestation',
    height: 'prescription_height',
    weight: 'prescription_weight',
    bsa: 'prescription_bsa',
    milligrams15: 'prescription_15_mg',
    milligrams20: 'prescription_20_mg',
    fixedDoseTablet: 'prescription_fixed_tablet',
    tabletsPerCycle: 'prescription_tablet_per_cycle',
    refills: 'prescription_refills',
    sig: 'prescription_sig',
    initalDose: 'prescription_initial_dose',
    secondDose: 'prescription_second_dose',
    mgCycle15: 'prescription_mg_cycle_15',
    mg20: 'prescription_20_mg_lonsurf',
    refillLonsurf: 'prescription_refills_lonsurf',
    fixedDose: 'prescription_fixed_dose',
    recommendedStartingDose: 'prescription_recommended_starting_dose',
    takeTablet: 'prescription_take_tablet',
    sigAlternate: 'prescription_sig_alternate',
    sigAlternateLonsurf: 'prescription_sig_alternate_lonsurf',
    numberOfRefillsAnother: 'prescription_number_of_refills_another',
    tabletsPerCycleAnother: 'prescription_tablet_per_cycle_another',
    physicianSignatureType: 'prescription_physician_signature_type'
  },
  facilityFields: {
    address1: 'facility_address_1',
    address2: 'facility_address_2',
    administrationInfo: 'administration_info',
    city: 'facility_city',
    facilityId: 'facility_id',
    name: 'facility_name',
    npi: 'facility_npi',
    officeContactFirstName: 'facility_office_contact_first_name',
    officeContactLastName: 'facility_office_contact_last_name',
    officeContactPhone: 'facility_office_contact_phone',
    state: 'facility_state',
    taxId: 'facility_tax_id',
    zip: 'facility_zip'
  },
  treatmentSiteFields: {
    referMyPatient: 'treating_site_refer_my_patient',
    sendUpdatesAndCommnunication: 'treating_site_send_updates_and_communications',
    siteName: 'treating_site_name',
    phone: 'treating_site_phone',
    fax: 'treating_site_fax',
    email: 'treating_site_email',
    npiNumber: 'treating_site_npi_number',
    taxId: 'treating_site_tax_id',
    treatingSiteHeader: 'treating_site_header',
    contactName: 'treating_site_contact_name'
  },
  patientAttestationFields: {
    attestationType: 'patient_attestation_type',
    patientEmailAttestation: 'patient_email_attestation',
    patientEmailEditableAttestation: 'patient_email_editable_attestation',
    patientmobileNumber: 'patient_attestation_mobile_number',
    attestationDocument: 'patient_attestation_document'
  },
  prescriberAttestationFields: {
    canContactPatient: 'can_contact_patient',
    signature: 'prescriber_attestation_signature',
    isCertified: 'prescriber_attestation_is_certified',
    signatureDate: 'prescriber_attestation_signature_date',
    certifiedText: 'prescriber_attestation_certified_text',
    prescriberSigningAttestation: 'prescriber_signing_attestation',
    isCertified2: 'prescriber_attestation_is_certified2',
    isCertified3: 'prescriber_attestation_is_certified3'
  },
  copayFields: {
    cardId: 'copay_card_id',
    groupNumber: 'copay_group_number',
    preferredPaymentMethod: 'copay_preferred_payment_method',
    additionalDocumentsUpload: 'copay_additional_documents_upload',
    emailId: 'copay_email_id',
    emailId2: 'copay_email_id_check',
    memberId: 'copay_member_id',
    rxBin: 'copay_rx_bin',
    rxPcn: 'copay_rx_pcn',
    checkPayableTo: 'copay_check_payable_to',
    copayProviderPaymentRemittanceAddress: 'copay_provider_payment_remittance_address',
    copayCity: 'copay_city',
    copayState: 'copay_state',
    copayZipCode: 'copay_zip_code',
    copayFaxNumber: 'copay_fax_number',
    copayNpiNumber: 'copay_npi_number',
    copayTaxIdNumber: 'copay_tax_id_number',
    copayMedicationName: 'copay_medication_reimbursement',
    copayRegistrationRepayment: 'copay_registration_repayment_name',
    copayPaymentReimbursementAddress: 'copay_payment_reimbursement_address',
    copaySuite: 'copay_reimbursement_suite',
    copayReimbursementCity: 'copay_reimbursement_city',
    copayReimbursementState: 'copay_reimbursement_state',
    copayReimbursementZip: 'copay_reimbursement_zip_code',
    copayPrimaryPayerName: 'copay_primary_payer_name',
    copayDateOfService: 'copay_date_of_service',
    copayPatientCostShareEOD: 'copay_patient_cost_share_eod',
    copayReimbursementAmountRequested: 'copay_reimbursement_requested_amount',
    copayReqFirstname: 'copay_requestor_first_name',
    copayRequestorLastName: 'copay_requestor_last_name',
    copayRequestorPhoneNumber: 'copay_requestor_phone_number',
    copayRequestorEmail: 'copay_requestor_email_address',
    copayReimbursementHeader: 'copay_claim_reimbursement_header',
    copayRequestorHeader: 'copay_requestor_details_header'
  },
  caregiverFields: {
    firstName: 'caregiver_first_name',
    middleName: 'caregiver_middle_name',
    lastName: 'caregiver_last_name',
    email: 'caregiver_email',
    homePhone: 'caregiver_home_phone',
    mobilePhone: 'caregiver_mobile_phone',
    patientRelationship: 'caregiver_patient_relationship',
    isAuthorizedRepForPatient: 'caregiver_is_authorized_representative'
  },
  pharmacyFields: {
    pharmacyName: 'pharmacy_name',
    otherPharmacyName: 'other_pharmacy_name'
  }
};
