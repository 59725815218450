import { ValidatorType } from '@data-driven-forms/react-form-renderer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Badge, Button } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import { DropzoneOptions, FileError, FileRejection, useDropzone } from 'react-dropzone';

import { AnyType, FilePreview } from '../interfaces';
import { LoadingIndicator } from './LoadingIndicator';

export interface HcpUploadProps {
  buttonAlign?: 'left' | 'right';
  disabled?: boolean;
  dropzoneOptions: DropzoneOptions;
  onChange?: (files: FilePreview[]) => void;
  value?: FilePreview[];
  isLoading?: boolean;
  label?: string;
  icon?: JSX.Element;
  buttonClassName?: string;
  buttonVariant?: string;
  wrapperDivClassName?: string;
  maxFiles?: number;
  validate?: ValidatorType[];
  showRequiredText?: boolean;
}

export const RequiredText = () => {
  return <span className='text-danger'>Required</span>;
};

export const HcpUpload = (props: HcpUploadProps) => {
  const [files, setFiles] = useState<FilePreview[]>([]);
  const { buttonAlign = 'left' } = props;
  const showRequiredText = useMemo(() => props?.validate?.some((o) => props.showRequiredText && o.type == 'required'), [
    props.validate
  ]);
  if (props?.maxFiles && !props.dropzoneOptions.maxFiles) {
    props['dropzoneOptions']['maxFiles'] = props.maxFiles;
  }

  useEffect(() => {
    if (props.value) {
      setFiles(props.value);
    }
  }, [props.value]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length > (props.dropzoneOptions?.maxFiles ?? 1)) {
        return;
      }
      const formattedFiles = acceptedFiles.map((acceptedFile: File) => {
        const preview = URL.createObjectURL(acceptedFile);
        let filePayload: Partial<FilePreview> = { ...acceptedFile, preview };
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFile);
        reader.onloadend = () => {
          const bytes = reader?.result;
          filePayload = Object.assign(filePayload, { bytes });
        };
        return filePayload;
      });

      const accumulateFiles = [...formattedFiles, ...files].splice(0, props.dropzoneOptions?.maxFiles ?? 1);

      setFiles(accumulateFiles);
      if (props.onChange) props.onChange(accumulateFiles);
    },
    [props]
  );

  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    onDrop: props.dropzoneOptions?.onDrop ?? onDrop,
    noClick: props.dropzoneOptions?.noClick ?? true,
    noKeyboard: props.dropzoneOptions?.noKeyboard ?? true,
    accept: props.dropzoneOptions?.accept ?? '.pdf',
    multiple: (props.dropzoneOptions?.maxFiles ?? 1) > 1,
    maxFiles: props.dropzoneOptions?.maxFiles ?? 1,
    maxSize: props.dropzoneOptions?.maxSize ?? 10000000, // kb to mb
    ...props.dropzoneOptions
  });

  const removeFile = (file: FilePreview) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    props.onChange && props.onChange(newFiles);
  };

  const fileErrors = fileRejections.reduce(
    (acc: AnyType, rejection: FileRejection) => {
      if (rejection.errors.find((e: FileError) => e.code === 'file-invalid-type')) {
        return { ...acc, invalid: [...acc.invalid, rejection] };
      }
      if (rejection.errors.find((e: FileError) => e.code === 'file-too-large')) {
        return { ...acc, tooLarge: [...acc.tooLarge, rejection] };
      }
      if (rejection.errors.find((e: FileError) => e.code === 'too-many-files')) {
        return { ...acc, tooMany: [...acc.tooMany, rejection] };
      }
    },
    {
      invalid: [],
      tooLarge: [],
      tooMany: []
    }
  );

  const errors = fileRejections.length > 0 && (
    <ul className='text-danger fs-2'>
      {fileErrors.invalid.length > 0 && (
        <li>Please upload a {getAcceptFiles(props.dropzoneOptions?.accept ?? '.pdf')} document.</li>
      )}
      {fileErrors.tooLarge.length > 0 && (
        <li>Please upload a {getAcceptFiles(props.dropzoneOptions?.accept ?? '.pdf')} document less than 10 MB.</li>
      )}
      {fileErrors.tooMany.length > (props.dropzoneOptions?.maxFiles ?? 1) && (
        <li>Please upload {props.dropzoneOptions?.maxFiles} documents or less.</li>
      )}
    </ul>
  );

  const fileBadges = files.map((file: FilePreview, i: number) => (
    <Badge key={i} pill variant='primary' className='p-2 mb-1'>
      <span className='text-truncate'>{file?.path}</span>
      <XCircleFill className='pointer ml-2' color='white' onClick={removeFile(file)} />
    </Badge>
  ));

  return (
    <div
      {...getRootProps()}
      className={`d-flex align-items-center ${buttonAlign === 'left' ? 'flex-row' : 'flex-row-reverse'}`}
      style={{ gap: 10 }}>
      <div className={props?.wrapperDivClassName}>
        <input {...getInputProps()} />
        <div className='badge-left'>{fileBadges}</div>
        <Button
          className={`mr-2 ${props?.buttonClassName}`}
          variant={props?.buttonVariant ?? 'outline-secondary'}
          disabled={props.disabled || files?.length >= (props.dropzoneOptions?.maxFiles ?? 1)}
          onClick={open}>
          {props.isLoading && <LoadingIndicator spinnerProps={{ size: 'sm', className: 'mr-2' }} />}
          {props?.icon ?? props.label ?? 'ATTACH'}
        </Button>
        {showRequiredText && <RequiredText />}
        <div className='badge-right'>{fileBadges}</div>
        {errors}
      </div>
    </div>
  );
};

export const getAcceptFiles = (accpetFilesArray: string | string[]): string => {
  const tempArray: string[] = typeof accpetFilesArray === 'string' ? accpetFilesArray?.split(',') : accpetFilesArray;
  let formattedFilesString = '';
  if (tempArray && tempArray?.length > 0) {
    tempArray?.forEach((file: AnyType, index: number) => {
      const fileExt = file?.replaceAll('.', '');
      formattedFilesString += index !== 0 ? (tempArray?.length - 1 === index ? `, & ` : `, `) + fileExt : fileExt;
    });
  }
  return formattedFilesString;
};
