import React from 'react';
import { useSelector } from 'react-redux';

import { Drug } from '../api/portal-config.generated';
import { AnyType, ServiceOptionType } from '../interfaces';
import { tenancyDrugServicesSelector, useTenancyDrugListSelector } from '../redux';
import { HcpToast } from './HcpToast';

export interface NotificationToastMessageProps {
  selectedService?: AnyType;
  drugID?: number; // when selectedService is unknown, then required drugID or drugNDC or drugName.
  drugNDC?: string; // when drug ID is unknown.
  drugName?: string; // when drug ID or drugNDC is unknown.
  requestType?: AnyType; // when selectedService is not given and to get it by service type provided in URL.
  patientGender?: string;
  messageKey?: string;
  genderSpecificMessageKey?: string;
  displayNotificationFlagKey?: string;
  className?: string;
}

export const NotificationToastMessage = (props: NotificationToastMessageProps) => {
  const messageTitleKey = 'notificationTitle';
  const messageKey = props?.messageKey || 'notificationMessage';
  const genderSpecificMessageKey = props?.genderSpecificMessageKey || 'genderSpecificNotificationMessage';
  const displayNotificationFlagKey = props?.displayNotificationFlagKey || 'displayNotification';

  let selectedService = props?.selectedService;
  if (!selectedService) {
    let drugID = null;
    if (props?.drugID) drugID = props?.drugID;
    if (!drugID && props?.drugNDC)
      drugID = (useTenancyDrugListSelector() || []).find((drug: Drug) => drug?.DrugNDC === props?.drugNDC)?.DrugId;
    if (!drugID && props?.drugName)
      drugID = (useTenancyDrugListSelector() || []).find(
        (drug: Drug) => drug?.DrugBrandName?.toLocaleLowerCase() === props?.drugName?.toLocaleLowerCase()
      )?.DrugId;

    if (drugID) {
      selectedService = (useSelector(tenancyDrugServicesSelector(drugID)) ?? {})[
        'service-request.' + (props?.requestType?.trim()?.length > 0 ? props?.requestType : ServiceOptionType.All)
      ]?.data;
    }
  }

  const isNotificationEnabled = selectedService?.notification?.[displayNotificationFlagKey];

  const isPatientGenderSpecificNotificationEnabled =
    selectedService?.notification?.onlyForPatientGenderType?.length > 0;

  const hasPatientGenderSpecificNotification = selectedService?.notification?.onlyForPatientGenderType?.find(
    (gender: string) => gender?.toLocaleLowerCase() === props?.patientGender?.substring(0, 1)?.toLocaleLowerCase()
  );

  return (
    <>
      {selectedService &&
        isNotificationEnabled &&
        (!isPatientGenderSpecificNotificationEnabled || !hasPatientGenderSpecificNotification) && (
          <HcpToast
            className={props?.className}
            toastTitle={selectedService?.notification?.[messageTitleKey]}
            toastMessage={selectedService?.notification?.[messageKey]}
          />
        )}
      {selectedService && isNotificationEnabled && hasPatientGenderSpecificNotification && (
        <HcpToast
          className={props?.className}
          toastTitle={selectedService?.notification?.[messageTitleKey]}
          toastMessage={selectedService?.notification?.[genderSpecificMessageKey]}
        />
      )}
    </>
  );
};
