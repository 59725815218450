import { RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useEffect, useState } from 'react';
import { EnvelopeFill, FileText, PersonFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConnectedHcpGridWithGQL, FilterInterface, HCPDatePickerWithTime, PatientsFilters, StatusDescription } from '.';
import { usePatientInfoQuery, usePatientRequestInfoQuery, usePatientStatusesQueryQuery } from '../graphql';
import {
  ActionColumnActions,
  actionsColumnDefinition,
  alertColumnDefinition,
  authorizationReceivedFormatter,
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  idColumnDefinition,
  nextStepColumnWithoutTooltip,
  nameColumnDefinition,
  prescriberColumnDefinition,
  patientHubStatusColumnDefinition,
  ROUTE_PATHS,
  filterGridComparator,
  dobDateColumnFormat,
  dateColumnDefinition,
  rxAdminColumnWithTooltip
} from '../utils';
import { GridFilters } from './GridFilters';
import { RxAdminDropdownIcon } from './custom-icons';

interface PatientsTableProps {
  enableExternalFilters?: boolean;
  filteredStatus?: string;
}

const columnSelector = featureGridColumnsSelector('patients.table');

export const PatientsTable = (props: PatientsTableProps) => {
  const [gridFilter, setGridFilter] = useState<FilterInterface>({ strictCompare: false });
  const [filter, setFilter] = useState<string>();
  const [filterList, setFilterList] = useState<Set<string>>(new Set([]));
  const [statusDescriptions, setStatusDescriptions] = useState<StatusDescription[]>([]);

  const columns = useSelector(columnSelector);
  const history = useHistory();

  useEffect(() => {
    setGridFilter({ ...gridFilter, secondaryFilterList: filterList, primaryFilter: filter });
  }, [filterList, filter]);

  if (!columns) return null;

  const goToPatientDetails = (params: any) => {
    history.push(`${ROUTE_PATHS.patients}/${params.data.patientId}#summary`);
  };

  const filterProps = props.enableExternalFilters
    ? {
        isFilterActive: true,
        filterComparator: filterGridComparator,
        filterValue: gridFilter
      }
    : {};

  return (
    <div>
      {props.enableExternalFilters && (
        <GridFilters
          filterName='Patients'
          filterOptions={statusDescriptions}
          onFilterChange={(filter: string) => setFilter(filter)}
          onFilterOptionChange={(filterList: Set<string>) => setFilterList(filterList)}
          hideDropDownFilter={columns?.patientHubStatus?.hide}
        />
      )}
      <ConnectedHcpGridWithGQL
        className='grid-wrapper'
        descriptionType='PatientStatusDescription'
        {...filterProps}
        queryHook={usePatientRequestInfoQuery}
        onDataLoad={(statusDescriptions: StatusDescription[]) => setStatusDescriptions(statusDescriptions)}
        pagination>
        <AgGridColumn {...alertColumnDefinition()} onCellClicked={goToPatientDetails} />
        <AgGridColumn field='lastName' {...nameColumnDefinition(columns.lastName)} onCellClicked={goToPatientDetails} />
        <AgGridColumn
          field='firstName'
          {...nameColumnDefinition(columns.firstName)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...dobDateColumnFormat('dob')}
          {...getFeatureGridColumnProps(columns.dob)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...idColumnDefinition('patientId')}
          {...getFeatureGridColumnProps(columns.id)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          filterValueGetter={authorizationReceivedFormatter}
          valueFormatter={authorizationReceivedFormatter}
          valueGetter={authorizationReceivedFormatter}
          {...getFeatureGridColumnProps(columns.authorization)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...prescriberColumnDefinition()}
          {...getFeatureGridColumnProps(columns.prescriber)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...patientHubStatusColumnDefinition('patientHubStatus', columns.patientHubStatus.headerName)}
          {...getFeatureGridColumnProps(columns.patientHubStatus)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...nextStepColumnWithoutTooltip()}
          {...getFeatureGridColumnProps(columns.nextSteps)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          field='location'
          {...getFeatureGridColumnProps(columns.site)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          {...dobDateColumnFormat('rxAdminDate')}
          {...rxAdminColumnWithTooltip()}
          {...getFeatureGridColumnProps(columns.rxAdminDate)}
          onCellClicked={goToPatientDetails}
        />
        <AgGridColumn
          cellClass='patient-options'
          {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
            {
              href: `${ROUTE_PATHS.patients}/${node.data.patientId}#summary`,
              label: 'Patient Profile',
              icon: PersonFill,
              testId: 'cmx__patient-profile-option'
            },
            {
              href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
              label: 'Start a Service',
              icon: FileText,
              testId: 'cmx__service-request-option',
              featureKey: 'service-request.manage'
            },
            {
              href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}#compose`,
              label: 'Send a Message',
              icon: EnvelopeFill,
              testId: 'cmx__send-message-option',
              featureKey: 'secure-message.manage'
            },
            {
              href: `${ROUTE_PATHS.patients}/${node.data.patientId}#rxadmin`,
              label: 'Rx Admin',
              icon: RxAdminDropdownIcon,
              testId: 'cmx__patient-rxadmin-option',
              featureKey: 'rxadmin'
            }
          ])}
        />
      </ConnectedHcpGridWithGQL>
    </div>
  );
};
