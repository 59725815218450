import React from 'react';

import Field from '@data-driven-forms/react-form-renderer/dist/cjs/field';
import { Nav } from 'react-bootstrap';
import { ExclamationCircleFill, CheckCircleFill } from 'react-bootstrap-icons';
import { isEqual } from 'lodash';

interface FormWizardTabsProps {
  currentStep: number;
  fields: Field[];
}

export const FormWizardTabs = React.memo(
  ({ currentStep, fields }: FormWizardTabsProps) => {
    return (
      <Nav variant='tabs' className='flex-column border border-bottom-0 border-left-0 border-top-0'>
        {fields.map((field: Field, i: number) => {
          const isCurrentTab = i === currentStep;
          const isValidated = i < currentStep;
          const linkBackgroundClass = isCurrentTab ? 'active-tab bg-info text-white' : 'bg-light-gray text-muted';
          const exclamationColorClass = isCurrentTab ? 'text-white' : 'text-warning';
          return (
            <Nav.Item key={field.name} className='mb-2'>
              <Nav.Link
                eventKey={i}
                bsPrefix='nav-link-nostyle'
                className={`d-flex py-3 px-3 align-items-center border border-dark border-right-0 rounded-left text-decoration-none pointer-none ${linkBackgroundClass}`}>
                {!isValidated && (
                  <ExclamationCircleFill className={`pull-left mr-3 flex-shrink-0 ${exclamationColorClass}`} />
                )}
                {isValidated && <CheckCircleFill className='text-success pull-left mr-3 flex-shrink-0' />}
                <span className='text-uppercase'>{field.title}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    );
  },
  (prev, next) => isEqual(next, prev)
);

FormWizardTabs.displayName = 'FormWizardTabs';
