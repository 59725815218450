import { FormApi, FORM_ERROR } from 'final-form';
import React, { useCallback } from 'react';
import { Button, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ArrowLeftCircleFill, X } from 'react-bootstrap-icons';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';

import { API_URL, ComposeMessageRequest, DocumentModel, useComposeMessage } from '../api';
import { FilePreview, GeneralObject } from '../interfaces';
import { AppRootState } from '../redux';
import {
  clientApplicationNameSelector,
  getFilePayload,
  OUTGOING_MESSAGE,
  validateFormValues,
  SENT_MESSAGE
} from '../utils';
import { PaperClip, PaperPlane } from './custom-icons';
import { FileUploadField, TextAreaField } from './FormFields';
import { MessageSubjectsSelect } from './HcpSelects';

const messageSchema = object({
  message: string().max(5000).required().label('Message'),
  subject: string().max(100).required().label('Subject')
});

export interface MessageComposeProps {
  serviceRequestId: number;
  isShown: boolean;
  threadId?: number;
  defaultSubject?: string;
  onSent?: (message: ComposeMessageRequest) => void | null;
  onHide?: () => void | null;
}

export const CaseMessageCompose = (props: MessageComposeProps) => {
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const applicationName = useSelector(clientApplicationNameSelector);
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? ''));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const userName = String(useSelector((state: AppRootState) => state.auth.session?.user?.Login ?? ''));
  const { id } = useParams<{ id: string; caseId: string }>();

  const { mutate: sendMessage } = useComposeMessage({ base: API_URL });

  const onSend = useCallback(
    async (values: GeneralObject, form: FormApi) => {
      const files = (values.files as Array<FilePreview>)?.map<DocumentModel | undefined>((f) =>
        getFilePayload(f)
      ) as DocumentModel[];

      const payload: ComposeMessageRequest = {
        applicationName: applicationName,
        files,
        messageText: String(values.message).trim(),
        patientId: Number(id),
        serviceRequestId: props?.serviceRequestId,
        subject: props?.threadId ? props?.defaultSubject?.trim() : String(values.subject).trim(),
        userName: currentUser?.Login,
        threadId: Number(props?.threadId)
      };
      const result = await sendMessage(payload);
      if (result.errors && result.errors.length > 0) {
        return { [FORM_ERROR]: result.errors.map((e) => e.description).join(' ') };
      }

      props.onSent && props.onSent(payload);
      props.onHide && props.onHide();
      setTimeout(form.reset, 0);
    },
    [props.onSent, currentUser]
  );

  const onHide = useCallback(() => {
    props.onHide && props.onHide();
  }, [props.onHide]);

  return (
    <>
      {props?.serviceRequestId && (
        <FinalForm
          onSubmit={onSend}
          validate={validateFormValues(messageSchema)}
          initialValues={messageSchema.default()}
          render={({ handleSubmit, submitting, submitError, hasValidationErrors }) => (
            <Form onSubmit={handleSubmit} noValidate id='HCP-Message'>
              <div className='case-message-wrapper'>
                <div className='case-message-wrapper__icon'>
                  <OverlayTrigger placement='top' overlay={<Tooltip id='outgoing-message'>{OUTGOING_MESSAGE}</Tooltip>}>
                    <ArrowLeftCircleFill tabIndex={0} className='pointer fs-6 my-auto' />
                  </OverlayTrigger>
                </div>
                <div className='case-message-wrapper__message'>
                  <div className='case-message-wrapper__head'>
                    <div className='case-message-wrapper__subject'>
                      <Form.Group>
                        <Form.Label className='text-right' sm={3}>
                          SUBJECT
                        </Form.Label>
                        <Col>
                          {props?.threadId && `${props?.defaultSubject}`}
                          <MessageSubjectsSelect
                            name='subject'
                            disabled={props?.threadId}
                            defaultValue={props?.defaultSubject}
                            style={props?.threadId && { display: 'none' }}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className='case-message-wrapper__from'>
                      <Form.Group>
                        <Form.Label className='text-right' sm={3}>
                          FROM
                        </Form.Label>
                        <Col>{`${currentUser?.name}`}</Col>
                      </Form.Group>
                      <Button type='reset' onClick={onHide} className='text-danger bg-transparent border-0 p-0'>
                        <X tabIndex={0} className='pointer fs-6 my-auto' />
                      </Button>
                    </div>
                  </div>
                  <div className='case-message-wrapper__body'>
                    <TextAreaField
                      name='message'
                      placeholder='Type Your Message here'
                      maxLength={5000}
                      rows={5}
                      className='w-100'
                    />
                  </div>
                  <div className='case-message-wrapper__footer pt-2'>
                    <div className='case-message-wrapper__error-wrapper'>
                      {submitError && (
                        <Form.Group>
                          <span className='text-danger'>{submitError}</span>
                        </Form.Group>
                      )}
                    </div>
                    <div className='case-message-wrapper__btn-wrapper'>
                      <FileUploadField
                        name='files'
                        dropzoneOptions={{ maxFiles: 5, accept: '.pdf,.jpeg,.jpg,.png' }}
                        icon={<PaperClip tabIndex={0} className='pointer fs-6 my-auto' />}
                        buttonClassName={`bg-transparent border-0 p-0`}
                        buttonVariant='none'
                        wrapperDivClassName='badge-wrapper'
                      />
                      <Button
                        type='submit'
                        id='Message-Submit'
                        variant='outline-primary'
                        disabled={submitting || hasValidationErrors}
                        className='case-message-wrapper__send-button bg-transparent border-0 p-0'>
                        <OverlayTrigger placement='top' overlay={<Tooltip id='sent_message'>{SENT_MESSAGE}</Tooltip>}>
                          <span>
                            <PaperPlane tabIndex={0} className='pointer fs-6 my-auto' />
                          </span>
                        </OverlayTrigger>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      )}
    </>
  );
};
