import { AnyObject, FORM_ERROR } from 'final-form';
import React, { Props, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  baaSchema,
  BAAView,
  organizationSchema,
  OrganizationView,
  prescriberSchema,
  PrescriberView,
  termsAndConditionsSchema,
  TermsAndConditionsView
} from '.';
import { GATEWAY_API_URL } from '../api';
import { useNewRegistration, useRegistration } from '../api/registration.generated';
import { FormWizard } from '../components';
import {
  AppRootState,
  navigateToLogin,
  navigateToRegistrationSubmitted,
  navigateToRegistrationWelcome,
  tenancyFeatureSelector
} from '../redux';
import { DBFieldSchema, FORM_WIZARD_VALUES, validateFormValues } from '../utils';
import { getOrganizationOnNextSchema } from './Organization';
import { AnyType } from '../interfaces';

const REGISTRATION_FORM_PAGE_NAMES = FORM_WIZARD_VALUES?.pages?.registration?.names;

export interface RegistrationFormProps extends Props<JSX.Element> {
  isLoading: boolean;
  formFields: DBFieldSchema;
}

export const RegistrationView = () => {
  const [prescriberFormFields, setPrescriberFormFields]: [DBFieldSchema, AnyType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const applicationName = useSelector((state: AppRootState) => state.app.config?.applicationName);
  const programId = useSelector((state: AppRootState) => state.app.config?.programId);
  const dispatch = useDispatch();
  const { mutate: submitRegistration } = useNewRegistration({});

  // Getting the steps object from 'registration.steps' feature
  const stepsObject: AnyType = useSelector<AnyType>(tenancyFeatureSelector('registration.steps'));

  const getPrescriberFormSchema = async () => {
    const prescriberFormSchema: AnyType = await useSelector<AnyType>(tenancyFeatureSelector('form.prescriber-info'));
    if (prescriberFormSchema) {
      const { fields } = await prescriberFormSchema;
      setPrescriberFormFields(fields);
      setIsLoading(false);
    }
  };
  getPrescriberFormSchema();

  const onSubmit = async (values: AnyObject) => {
    const result = await submitRegistration({
      PracticeInfo: {
        ProgramId: Number(programId),
        PracticeName: values.organization_name,
        TaxId: values.tax_id,
        NPI: String(values.organization_npi),
        AddressLine1: values.address_1,
        AddressLine2: values.address_2,
        City: values.city,
        State: values.state,
        Zip: values.zip,
        PhoneNumber: values.organization_phone,
        FaxNumber: values.organization_fax
      },
      AdministratorInfo: {
        IsPhysician: values.administrator_is_prescriber === 'yes' ? 1 : 0,
        NPI: values.administrator_prescriber_npi ? String(values.administrator_prescriber_npi) : '',
        FirstName: values.administrator_first_name,
        LastName: values.administrator_last_name,
        Suffix: values.administrator_suffix,
        PhoneNumber: values.administrator_phone,
        FaxNumber: values.administrator_fax,
        EMail: values.administrator_email,
        UserName: values.administrator_username
      },
      PhysicianInfo: {
        NPI: String(values.prescriber_npi),
        FirstName: values.prescriber_first_name,
        LastName: values.prescriber_last_name,
        MiddleName: values.prescriber_middle_name,
        TaxID: values.prescriber_tax_id,
        StateLicenseNumber: values.prescriber_state_license,
        LicensingState: values.prescriber_licensing_state,
        DEA: values.prescriber_dea,
        PTAN: values.prescriber_ptan,
        EMAIL: values.prescriber_email,
        HIN: values.prescriber_hin
      },
      TermsAndConditionsAcknowledgement: values.acknowledge_terms,
      BAAAcknowledgement: values.acknowledge_baa,
      BAALanguageAcknowledgement: values.acknowledge_language,
      BAASignatureText: values.signature,
      BAASignatureDate: new Date(values.signature_date)
    });

    if (result?.isSuccess) {
      dispatch(navigateToRegistrationSubmitted());
      return;
    }

    const defaultError = 'There was a problem registering the user. Please contact support.';
    const error = result.errors?.map((e) => e?.description).join(' ');

    return { [FORM_ERROR]: error ?? defaultError };
  };
  return (
    <FormWizard
      isLastPage={stepsObject?.steps?.baa?.hide ? true : false}
      actionsClassName='container'
      initialValues={{
        ...organizationSchema({ programId }).default(),
        ...prescriberSchema({ programId }).default(),
        ...termsAndConditionsSchema.default(),
        ...baaSchema.default()
      }}
      onBack={() => dispatch(navigateToRegistrationWelcome())}
      onCancel={() => dispatch(navigateToLogin())}
      onSubmit={onSubmit}>
      <FormWizard.Page
        name={REGISTRATION_FORM_PAGE_NAMES.organization}
        onFormNext={validateFormValues(getOrganizationOnNextSchema(applicationName))}
        validate={validateFormValues(organizationSchema({ programId }))}>
        <OrganizationView />
      </FormWizard.Page>

      <FormWizard.Page
        name={REGISTRATION_FORM_PAGE_NAMES.prescriber}
        validate={validateFormValues(prescriberSchema({ programId }))}>
        <PrescriberView isLoading={isLoading} formFields={prescriberFormFields} />
      </FormWizard.Page>

      <FormWizard.Page
        name={REGISTRATION_FORM_PAGE_NAMES.termsAndConditions}
        validate={validateFormValues(termsAndConditionsSchema)}>
        <TermsAndConditionsView />
      </FormWizard.Page>

      <FormWizard.Page name={REGISTRATION_FORM_PAGE_NAMES.baa} validate={validateFormValues(baaSchema)}>
        <BAAView />
      </FormWizard.Page>
    </FormWizard>
  );
};

// c

//

/*  */
