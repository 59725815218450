import { RowNode } from 'ag-grid-community';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import React, { useContext } from 'react';
import { EnvelopeFill, Eye, FileText } from 'react-bootstrap-icons';
import { ServiceRequestViewFragment } from '../graphql';
import {
  ActionColumnActions,
  actionsColumnDefinition,
  globalDefaultColDefs,
  nextStepColumnWithoutTooltip,
  nextStepColumnDefinition,
  prescriberColumnDefinition,
  ROUTE_PATHS,
  serviceRequestAlertColumnDefinition,
  dateColumnDefinition,
  idColumnDefinition,
  getFeatureGridColumnProps,
  featureGridColumnsSelector
} from '../utils';
import { HcpGrid } from '.';
import { AnyType } from '../interfaces';
import { AgGridColumn } from 'ag-grid-react';
import { PatientProfileContext } from './patient-profile-context';

interface RequestHistoryTableProps {
  serviceRequests?: Maybe<
    ReadonlyArray<Maybe<{ readonly __typename?: 'ServiceRequestViewModel' } & ServiceRequestViewFragment>>
  >;
}
//Need to make this dynamic
const columnDefs_MultiDrugs = [
  { ...serviceRequestAlertColumnDefinition() },
  { headerName: 'REQUEST TYPE', field: 'requestType' },
  { headerName: 'DRUG', field: 'sRDrugName' },
  { headerName: 'REQUEST ID', field: 'requestID' },
  { ...dateColumnDefinition('requestStartDate', 'SUBMITTED') },
  { ...prescriberColumnDefinition() },
  { headerName: 'STATUS', field: 'statusText' },
  { ...nextStepColumnWithoutTooltip() },
  {
    ...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
      {
        href: `${ROUTE_PATHS.requests}/${node.data.id}/${node.data.patientId}/${node.data.requestType}/${node.data.requestID}`,
        label: 'View Request',
        icon: Eye
      },
      {
        href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
        label: 'Start New Request',
        icon: FileText,
        featureKey: 'service-request.manage'
      },
      {
        href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}&request=${node.data.id}#compose`,
        label: 'Send a Message',
        icon: EnvelopeFill,
        featureKey: 'secure-message.manage'
      }
    ])
  }
];
//Need to make this dynamic
const columnDefs = [
  { ...serviceRequestAlertColumnDefinition() },
  { headerName: 'REQUEST TYPE', field: 'requestType' },
  { headerName: 'REQUEST ID', field: 'requestID' },
  { ...dateColumnDefinition('requestStartDate', 'SUBMITTED') },
  { ...prescriberColumnDefinition() },
  { headerName: 'STATUS', field: 'statusText' },
  { ...nextStepColumnWithoutTooltip() },
  {
    ...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
      {
        href: `${ROUTE_PATHS.requests}/${node.data.id}/${node.data.patientId}/${node.data.requestType}/${node.data.requestID}`,
        label: 'View Request',
        icon: Eye
      },
      {
        href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
        label: 'Start New Request',
        icon: FileText,
        featureKey: 'service-request.manage'
      },
      {
        href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}&request=${node.data.id}#compose`,
        label: 'Send a Message',
        icon: EnvelopeFill,
        featureKey: 'secure-message.manage'
      }
    ])
  }
];

export const RequestHistoryTable = ({ serviceRequests }: RequestHistoryTableProps) => {
  const { setSelectedRequestInfo } = useContext(PatientProfileContext);
  const history = serviceRequests?.filter((sr) => sr?.displayOnGrid !== 0);
  const drugList: AnyType = useSelector((state: AppRootState) => state?.app?.entities?.drugList);
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  const columnSelector = featureGridColumnsSelector('patient-service-request.table');
  const columns = useSelector(columnSelector);
  //Need to remove this in the future
  const customJanssenPortal =
    portal_ApplicationName && portal_ApplicationName !== 'JanssencarepathProviderPortal' ? true : false;

  // if there is no druglist comes from state then return null else show the grid
  if (!drugList) return null;

  const column = drugList?.[0] ? columnDefs_MultiDrugs : columnDefs;
  const isDrugs = drugList?.[0] ? false : true;

  const onSelectRequest = (id: string) => {
    const selectedRequest = serviceRequests?.find((item) => item?.id === id);
    if (selectedRequest) {
      const { sRDrugName, requestType, requestStatus, requestID, authorizationReceived } = selectedRequest;
      setSelectedRequestInfo({
        id: requestID ?? '',
        drugName: sRDrugName ?? '',
        requestType: requestType ?? '',
        requestStatus: requestStatus ?? '',
        authorizationReceived: authorizationReceived ?? false
      });
    }
  };

  //Need to make this dynamic
  if (!customJanssenPortal) {
    return (
      <>
        <HcpGrid
          rowData={history}
          rowSelection='single'
          onRowClicked={(e) => {
            onSelectRequest(e.data.id);
          }}
          pagination
          className='grid-wrapper'
          defaultColDef={globalDefaultColDefs}>
          <AgGridColumn
            field='requestType'
            tooltipField='requestType'
            {...getFeatureGridColumnProps(columns.requestType)}
          />
          <AgGridColumn field='sRDrugName' headerName='DRUG' tooltipField='Drug Name' hide={isDrugs} />
          <AgGridColumn field='requestID' tooltipField='requestID' {...getFeatureGridColumnProps(columns.id)} />
          <AgGridColumn
            {...dateColumnDefinition('requestStartDate')}
            {...getFeatureGridColumnProps(columns.submitted)}
          />
          <AgGridColumn {...prescriberColumnDefinition()} {...getFeatureGridColumnProps(columns.prescriber)} />
          <AgGridColumn field='statusText' tooltipField='statusText' {...getFeatureGridColumnProps(columns.status)} />
          <AgGridColumn {...nextStepColumnWithoutTooltip()} {...getFeatureGridColumnProps(columns.nextSteps)} />
          <AgGridColumn
            {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
              {
                href: `${ROUTE_PATHS.requests}/${node.data.id}/${node.data.patientId}/${node.data.requestType}/${node.data.requestID}`,
                label: 'View Request',
                icon: Eye
              },
              {
                href: `${ROUTE_PATHS.serviceRequest}/patient/${node.data.patientId}`,
                label: 'Start New Request',
                icon: FileText,
                featureKey: 'service-request.manage'
              },
              {
                href: `${ROUTE_PATHS.messages}?patient=${node.data.patientId}&request=${node.data.id}#compose`,
                label: 'Send a Message',
                icon: EnvelopeFill,
                featureKey: 'secure-message.manage'
              }
            ])}
          />
        </HcpGrid>
      </>
    );
  }
  //Need to make this dynamic
  return (
    <HcpGrid
      columnDefs={column}
      rowData={history}
      defaultColDef={globalDefaultColDefs}
      className='grid-wrapper'
      rowSelection='single'
      onRowClicked={(e) => {
        onSelectRequest(e.data.id);
      }}
      pagination
    />
  );
};
