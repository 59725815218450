import React from 'react';
import { Row } from 'react-bootstrap';
import { MessageThread } from '../api';

interface MessageDetailProps {
  thread: MessageThread | null;
}

export const MessageDetail = ({ thread }: MessageDetailProps) => {
  // const [execQuery, result] = useOrganizationAddressInfoLazyQuery();

  // useEffect(() => {
  //   if (!thread) return;

  //   execQuery({ variables: { organizationId: String(thread.organizationId) } });
  // }, [thread, execQuery]);

  if (!thread) return null;

  // result?.data?.result?.find((l) => location with type main)
  const siteLocation = thread?.organizationName;

  return (
    <div className='my-4 mx-5'>
      <Row className='justify-content-between'>
        <span className='fs-3'>
          <span>Subject</span>: <strong>{thread.subject}</strong>
        </span>
        <span className='fs-3'>
          <span>Drug / Product</span>: <strong>{thread.therapy}</strong>
        </span>
      </Row>
      <Row className='justify-content-between'>
        <span className='fs-3'>
          <span>Patient ID</span>: <strong>{thread.patientId}</strong>
        </span>
        <span className='fs-3'>
          <span>Prescriber Name</span>: <strong>{thread.providerName}</strong>
        </span>
        <span className='fs-3'>
          <span>Site/Location</span>: <strong>{siteLocation}</strong>
        </span>
      </Row>
    </div>
  );
};
