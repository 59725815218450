import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { CustomThemes } from '../utils/custom-themes';
import { ResourceContent } from './multi-tenant';
import { rawSafeHtmlProps } from '../utils';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';

const ISI_FOOTER_KEY = 'footer.isi';

export const ISIFooter = () => {
  CustomThemes();
  const isISIFooterEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})[ISI_FOOTER_KEY ?? ''] as AnyType)?.data?.enabled
  );

  return (
    <>
      {isISIFooterEnabled === true ? (
        <Container fluid className='cmx__footer__isi' id='isi-footer'>
          <ResourceContent
            contentKey={ISI_FOOTER_KEY}
            sourceDataKey='content'
            render={(body) => <span {...rawSafeHtmlProps(body?.toString())} />}
          />
        </Container>
      ) : null}
    </>
  );
};
