import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { ROUTE_PATHS } from '../../utils';
import { ServiceOptionType } from '../../interfaces';
import { AnyType } from '../../interfaces';
import { Route } from 'react-router-dom';

export const navigateTo = (dispatch: Dispatch, path: string, state?: AnyType) => {
  dispatch(push(path, state));
};

export const navigateToTenancyLink = (link: AnyType) => (dispatch: Dispatch) =>
  navigateTo(dispatch, link.url ? link.url : (ROUTE_PATHS as AnyType)[link.routeName]);

export const navigateToLoginCallback = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.loginCallback);

export const navigateToBaa = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.baa);

export const navigateToForgotPassword = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.forgotPassword);

export const navigateToForgotPasswordSuccess = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.forgotPasswordSuccess);

export const navigateToLogin = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.login);

export const navigateToLoginPostReset = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.login}#reset`);

export const navigateToPrivacy = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.privacy);

export const navigateToTermsAndConditions = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.termsAndConditions);

export const navigateToTerms = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.terms);

export const navigateToRegistration = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.registration);

export const navigateToContactUSDetails = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.contactUs);

export const navigateToRegistrationWelcome = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.registrationWelcome);

export const navigateToRegistrationSubmitted = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.registrationSubmitted);

export const navigateToHome = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.home);

export const navigateToLocationsNew = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.locationsNew);

export const navigateToMessages = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.messages);

export const navigateToMessage = (id: string | number) => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.messages}/${id}`);

export const navigateToOrganization = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.organization);

export const navigateToPatients = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.patients);

export const navigateToPatient = (id: string | number) => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.patients}/${id}#summary`);

export const navigateToPatientsNew = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.patientsNew);

export const navigateToPractice = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.practice);

export const navigateToPracticeLocations = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.practice}#locations`);

export const navigateToPracticePrescribers = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.practice}#prescribers`);

export const navigateToPrescriber = (id: string | number) => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.prescribers}/${id}`);

export const navigateToPrescriberNew = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.prescriberNew);

export const navigateToRequests = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.requests);

export const navigateToRequestsSubmitted = (serviceOption: ServiceOptionType, state?: AnyType) => (
  dispatch: Dispatch
) => navigateTo(dispatch, `${ROUTE_PATHS.requestsSubmitted}#${serviceOption}`, state);

export const navigateToResources = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.resources);

export const navigateToServiceRequest = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.serviceRequest);

export const navigateToRxModule = (patientId: string, service: string, state: AnyType) => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.rxModule?.replace(/:id/gi, patientId).replace(/:service/gi, service), state);

export const navigateToServiceRequestForPatient = (patientId: string) => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.serviceRequest}/patient/${patientId}`);

export const navigateToServiceRequestSelected = (
  serviceRequest: string,
  patientId: string | number,
  drugId?: number | null,
  prescriberId?: string | null | undefined,
  locationId?: string | null | undefined
) => (dispatch: Dispatch) =>
  navigateTo(
    dispatch,
    `${ROUTE_PATHS.requests}/new/${serviceRequest}/patient/${patientId}${drugId ? `?drug=${drugId}` : '?'}${
      prescriberId ? `&prescriberId=${prescriberId}` : ''
    }${locationId ? `&locationId=${locationId}` : ''}`
  );

export const navigateToSettings = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.settings);

export const navigateToCases = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.cases);

export const navigateToRequestSubmitted = () => (dispatch: Dispatch) =>
  navigateTo(dispatch, ROUTE_PATHS.caseRequestsSubmitted);

export const navigateToUserNew = () => (dispatch: Dispatch) => navigateTo(dispatch, ROUTE_PATHS.userNew);

export const navigateToRequestServiceIntermediator = (serviceOption: ServiceOptionType, state?: AnyType) => (
  dispatch: Dispatch
) => navigateTo(dispatch, `${ROUTE_PATHS.requestServiceIntermediator}#${serviceOption}`, state);

export const navigateToEnrollmentModule = (state?: AnyType) => (dispatch: Dispatch) =>
  navigateTo(dispatch, `${ROUTE_PATHS.enrollmentModule}`, state);
