import { AnyObject } from 'react-final-form';
import { flattenDeep, get, memoize, set } from 'lodash';
import { FormApi } from 'final-form';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import Field from '@data-driven-forms/react-form-renderer/dist/cjs/field';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { AnyType } from '../../interfaces';

export const confirmWithUserAndPrepForBackNavigation = (form: FormApi): boolean => {
  if (!form) return false;

  const fieldsWithValidationErrors = Object?.keys(form?.getState()?.errors ?? {});

  const navigationIsConfirmed = fieldsWithValidationErrors.length
    ? window.confirm('Are you sure you want to go back? Any currently invalid fields will be cleared.')
    : true;

  if (!navigationIsConfirmed) return false;

  form.batch(() => fieldsWithValidationErrors?.forEach((fieldName) => form.change(fieldName, undefined)));

  return true;
};

// -----===[reducer utils]===-----
export const DYNAMIC_WIZARD_TYPES = ['function', 'object'];

export const createSchema = memoize(({ formOptions, fields }: { formOptions: FormOptions; fields: Field[] }) => {
  const { values } = formOptions.getState();
  let schema: AnyType[] = [];
  let field: Field | undefined = fields[0]; // find first wizard step
  let index = -1;

  while (field) {
    index += 1;
    schema = [
      ...schema,
      {
        name: field.name,
        title: field.title,
        substepOf: field.substepOf?.name || field.substepOf,
        substepOfTitle:
          (field.substepOf === schema[schema.length - 1]?.substepOf && schema[schema.length - 1]?.substepOfTitle) ||
          field.substepOf?.title ||
          field.substepOf,
        index,
        primary:
          !schema[schema.length - 1] || !field.substepOf || field.substepOf !== schema[schema.length - 1].substepOf
      }
    ];

    let nextStep = field?.nextStep;

    if (typeof field?.nextStep === 'object') {
      nextStep = nextStep.stepMapper[get(values, nextStep.when)];
    }

    if (typeof field?.nextStep === 'function') {
      nextStep = field?.nextStep({ values });
    }

    if (nextStep) {
      field = fields?.find((field: AnyType) => field.name === nextStep) as Field;
    } else {
      field = undefined;
    }
  }

  return schema;
});

export const enterHandler = (
  e: AnyType,
  formOptions: FormOptions,
  activeStep: AnyType,
  findCurrentStep: AnyType,
  handleNext: AnyType,
  handleSubmit: AnyType
) => {
  if (e.key === 'Enter') {
    const isNotButton = e.target.type !== 'button';

    if (isNotButton) {
      e.preventDefault();

      const schemaNextStep = findCurrentStep(activeStep).nextStep;
      const hasCustomButtons = findCurrentStep(activeStep).buttons;

      let nextStep;
      if (schemaNextStep) {
        nextStep = selectNext(schemaNextStep, formOptions.getState);
      }

      const canContinue = (formOptions as AnyType).valid && !formOptions.getState().validating;

      if (canContinue && nextStep && !hasCustomButtons) {
        handleNext(nextStep, formOptions.getRegisteredFields);
      } else if (canContinue && !schemaNextStep && !hasCustomButtons) {
        handleSubmit();
      }
    }
  }
};

export const findCurrentStep = (activeStep: string, fields: Field[]): Schema =>
  (fields?.find((field) => field.name === activeStep) as unknown) as Schema;

export const handleNext = (state: AnyObject, nextStep: string, formOptions: FormOptions, fields: Field[]) => {
  const newActiveIndex = state.activeStepIndex + 1;
  const shouldInsertStepIntoHistory = state.prevSteps.includes(state.activeStep);

  return {
    ...state,
    registeredFieldsHistory: {
      ...state.registeredFieldsHistory,
      [state.activeStep]: formOptions.getRegisteredFields()
    },
    activeStep: nextStep,
    prevSteps: shouldInsertStepIntoHistory ? state.prevSteps : [...state.prevSteps, state.activeStep],
    activeStepIndex: newActiveIndex,
    maxStepIndex: newActiveIndex > state.maxStepIndex ? newActiveIndex : state.maxStepIndex,
    navSchema: state.isDynamic
      ? createSchema({
          fields,
          formOptions
        })
      : state.navSchema
  };
};

export const prepareValues = (
  state: AnyObject,
  values: AnyObject,
  visitedSteps: string[],
  getRegisteredFields: AnyType
) => {
  // Add the final step fields to history
  const finalRegisteredFieldsHistory = {
    ...state.registeredFieldsHistory,
    [state.activeStep]: getRegisteredFields()
  };

  const finalObject = {};

  // Find only visited fields
  flattenDeep(
    Object.values(
      [...visitedSteps, state.activeStep].reduce(
        (obj, key) => ({ ...obj, [key]: finalRegisteredFieldsHistory[key] }),
        {}
      )
    )
  ).forEach((key) => set(finalObject, key, get(values, key)));

  return finalObject;
};

export const jumpToStep = (
  state: AnyObject,
  index: number,
  valid: boolean,
  fields: Field[],
  crossroads: AnyType,
  formOptions: FormOptions
) => {
  if (index === state.activeStepIndex) {
    return state;
  }

  const clickOnPreviousStep = state.prevSteps[index];

  if (clickOnPreviousStep) {
    const originalActiveStep = state.activeStep;

    const includeActiveStep = state.prevSteps.includes(state.activeStep, fields);

    const newState = {
      ...state,
      activeStep: state.prevSteps[index],
      prevSteps: includeActiveStep ? state.prevSteps : [...state.prevSteps, state.activeStep],
      activeStepIndex: index
    };

    const INDEXING_BY_ZERO = 1;

    const currentStep = findCurrentStep(newState.prevSteps[index], fields);

    const currentStepHasStepMapper = DYNAMIC_WIZARD_TYPES.includes(typeof (currentStep as AnyType).nextStep);

    const hardcodedCrossroads = crossroads;
    const dynamicStepShouldDisableNav = (newState as AnyType).isDynamic && currentStepHasStepMapper;

    const invalidStepShouldDisableNav = valid === false;

    let updatedState: AnyObject = {
      ...newState
    };

    if (dynamicStepShouldDisableNav && !hardcodedCrossroads) {
      updatedState = {
        ...updatedState,
        navSchema: createSchema({
          formOptions,
          fields
        }),
        prevSteps: newState.prevSteps.slice(0, index),
        maxStepIndex: index
      };
    } else if ((currentStep as AnyType).disableForwardJumping) {
      updatedState = {
        ...updatedState,
        prevSteps: newState.prevSteps.slice(0, index),
        maxStepIndex: index
      };
    } else if (invalidStepShouldDisableNav) {
      const indexOfCurrentStep = newState.prevSteps.indexOf(originalActiveStep);

      updatedState = {
        ...updatedState,
        prevSteps: newState.prevSteps.slice(0, indexOfCurrentStep + INDEXING_BY_ZERO),
        maxStepIndex: newState.prevSteps.slice(0, indexOfCurrentStep + INDEXING_BY_ZERO).length - INDEXING_BY_ZERO
      };
    }

    return updatedState;
  }
};

export const selectNext = (nextStep: AnyType, getState: AnyType) => {
  if (typeof nextStep === 'string') {
    return nextStep;
  }

  if (typeof nextStep === 'function') {
    return nextStep({ values: getState().values });
  }

  const selectedValue = get(getState().values, nextStep?.when);

  return nextStep?.stepMapper[selectedValue];
};
