import { AnyObject, FORM_ERROR } from 'final-form';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { OrganizationAddressRequest, Physician, useCreateAddress, useCreatePhysician, useEnrollment } from '../api';
import { PrivateLayout, SelectedServiceRequestContent } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { useDrugFeatureKeyPrefix } from '../hooks/use-drug-feature-key-prefix';
import { AnyType, AutomatedServiceOptionType, ServiceOptionType } from '../interfaces';
import {
  AppRootState,
  navigateToRequestsSubmitted,
  navigateToRxModule,
  navigateToServiceRequest,
  tenancyFeatureSelector
} from '../redux';
import { FIELD_NAMES, getEnrollmentPayload, RX_MODULES } from '../utils';

interface SelectedServiceRequestViewParams {
  patientId: string;
  service: ServiceOptionType;
}

export const SelectedServiceRequestView = React.memo(
  () => {
    const dispatch = useDispatch();
    const { mutate: enroll } = useEnrollment({});
    const user = useSelector((state: AppRootState) => state.auth.session?.user);
    const userDetails = useSelector((state: AppRootState) => state.auth.session?.userDetails);
    const orgDetails = useSelector((state: AppRootState) => state.auth.session?.orgDetails);
    const userInfo = useSelector((state: AppRootState) => state.auth.session?.userInfo);
    const { service, patientId } = useParams<SelectedServiceRequestViewParams>();
    const { mutate: createPhysician } = useCreatePhysician({});
    const { mutate: createLocation } = useCreateAddress({});
    const { prescriberFields } = FIELD_NAMES;
    const featureKeyPrefix = useDrugFeatureKeyPrefix();
    const { search: queryString } = useLocation();
    const drugList = useSelector((state: AppRootState) => state.app?.entities?.drugList) ?? null;
    const queryParams = new URLSearchParams(queryString);
    const autoServiceOption: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceOption'));
    if (autoServiceOption?.enabled) {
      for (const [key, value] of queryParams.entries()) {
        if (key == 'prescriberId') {
          sessionStorage.setItem('prescriberId', value);
        }
        if (key == 'locationId') {
          sessionStorage.setItem('locationId', value);
        }
      }
      sessionStorage.setItem('patientId', patientId);
    }
    const drugId = queryParams.get('drug') || drugList?.[0]?.DrugId?.toString() || '';

    const formSchema: AnyType = useSelector<AnyType>(
      tenancyFeatureSelector(`${featureKeyPrefix}form.service-request-${service}` ?? '')
    );
    const portalName: AnyType = useSelector<AnyType>((state: AppRootState) => state.app?.config?.applicationName);
    const autoMedEBV: AnyType = useSelector<AnyType>(tenancyFeatureSelector(AutomatedServiceOptionType.AUTO_MED_EBV));

    const onSubmit = useCallback(
      (values: AnyObject) => {
        const asyncSubmit = async () => {
          let routerState: AnyObject = {};
          const submitPayload = getEnrollmentPayload(
            values,
            patientId,
            service,
            portalName,
            user,
            formSchema?.fields,
            drugId
          );

          if (
            service === ServiceOptionType.PharmacyElectronicPriorAuthorization ||
            service === ServiceOptionType.PharmacyElectronicBenefitVerification ||
            service === ServiceOptionType.MedicalElectronicBenefitVerification ||
            service === ServiceOptionType.MedicalElectronicPriorAuthorization
          ) {
            const isNewPrescriber = values[prescriberFields.isNewPrescriber];
            const isNewLocationAddress = values[prescriberFields.isNewLocationAddress];

            try {
              if (isNewPrescriber == true) {
                const payload: Physician = {
                  firstName: values[prescriberFields.firstName],
                  lastName: values[prescriberFields.lastName],
                  licenseState: values[prescriberFields.licensingState],
                  middleName: values[prescriberFields.middleName],
                  npi: values[prescriberFields.npi] && values[prescriberFields.npi]?.toString(),
                  stateLicenseNumber: values[prescriberFields.stateLicenseNumber],
                  taxId: values[prescriberFields.taxId],
                  ptan: values[prescriberFields.ptan],
                  dea: values[prescriberFields.dea],
                  email: values[prescriberFields.email]
                };

                const result = await createPhysician(payload);
                if (result.errors && result.errors.length > 0) {
                  const errors = result.errors?.map((e) => e.description).join(' ');
                  // Return the form error to display
                  return { [FORM_ERROR]: errors };
                }
              }
              if (isNewLocationAddress) {
                const locationPayload: OrganizationAddressRequest = {
                  addressType: 'Other',
                  address1: String(values[prescriberFields.locationAddress1]),
                  address2: values[prescriberFields.locationAddress2] as string,
                  city: String(values[prescriberFields.locationAddressCity]),
                  state: String(values[prescriberFields.locationAddressState]),
                  zip: String(values[prescriberFields.locationAddressZip]),
                  phone1: String(values[prescriberFields.locationPhone1]),
                  phone1Type: 'Work',
                  fax: String(values[prescriberFields.locationFaxNumber])
                };
                const locationResult = await createLocation(locationPayload);
                if (locationResult.errors && locationResult.errors.length > 0) {
                  const errors = locationResult.errors?.map((e) => e.description).join(' ');
                  return { [FORM_ERROR]: errors };
                }
              }
            } catch (e) {
              // TODO: validate the messages that _could_ show here.
              return { [FORM_ERROR]: e.message };
            }
          }
          if (RX_MODULES.includes(service)) {
            return dispatch(
              navigateToRxModule(patientId, service, {
                enrollmentPayload: submitPayload ?? {},
                isMedEbvAutomationEnabled: false
              })
            );
          }

          try {
            const result = await enroll(submitPayload);

            if (result.errors && result.errors.length > 0) {
              const errors = result.errors.map((e) => e.description).join(' ');

              return { [FORM_ERROR]: errors };
            }

            if (service === ServiceOptionType.PatientResources) {
              const { patientFields } = FIELD_NAMES;
              routerState = {
                patientName: `${values[patientFields.firstName]} ${values[patientFields.lastName]}`,
                email: values[patientFields.email]
              };
            } else {
              routerState = {
                patientId: patientId,
                enrollmentPayload: submitPayload ?? {},
                isMedEbvAutomationEnabled: autoMedEBV?.enabled,
                loadingService: ServiceOptionType.MedicalElectronicBenefitVerification,
                enrollmentResult: {
                  enrollmentId: result?.enrollmentId,
                  serviceRequestId: result?.serviceRequestId
                },
                allPAExecuted: false
              };
            }

            if (autoMedEBV?.enabled && routerState?.enrollmentPayload?.insurance?.hasPatientInsurance) {
              dispatch(
                navigateToRxModule(
                  routerState?.patientId,
                  ServiceOptionType.MedicalElectronicBenefitVerification,
                  routerState
                )
              );
            } else {
              dispatch(navigateToRequestsSubmitted(service, routerState));
            }
          } catch (e) {
            // TODO: validate the messages that _could_ show here.
            return { [FORM_ERROR]: e.message };
          }
        };

        asyncSubmit();
      },
      [patientId, service, user]
    );

    return (
      <FeatureGuard
        contentKey={`${featureKeyPrefix}service-request.${service}`}
        disabledHandler={() => dispatch(navigateToServiceRequest())}>
        <PrivateLayout pageTitle='new request'>
          <SelectedServiceRequestContent
            onSubmit={onSubmit}
            service={service}
            userDetails={userDetails}
            orgDetails={orgDetails}
            userInfo={userInfo}
          />
        </PrivateLayout>
      </FeatureGuard>
    );
  },
  (prev, next) => isEqual(next, prev)
);
