import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Drug } from '../../api/portal-config.generated';
import { useTenancyDrugListSelector } from '../../redux';
import { featureGridColumnsSelector, getFeatureGridColumnProps } from '../../utils';
import { SepeciltyPharmacySearch, SPSearchOptionType } from './SepeciltyPharmacySearch';
import { ConnectedHcpGridWithGQL } from '../HcpGridWithGQL';
import { useSpecialtyPharmacyContactQuery } from '../../graphql';

const columnSelector = featureGridColumnsSelector('resources.specialty-pharmacy.contact-info.table');

export const SpecialtyPharmacyTable = () => {
  const drugList: Drug[] = useTenancyDrugListSelector();
  const drugOptions: SPSearchOptionType[] = drugList?.map((drug: Drug) => ({
    label: `${drug?.DrugLabelName}`,
    value: drug?.DrugBrandName?.toString() || ''
  }));
  const [selectedDrugName, setSelectedDrugName] = useState<string>(drugOptions?.[0]?.value);
  let queryOptions = {
    variables: {
      brandName: selectedDrugName
    }
  };
  useEffect(() => {
    if (selectedDrugName) {
      queryOptions = {
        variables: {
          brandName: selectedDrugName
        }
      };
    }
  }, [selectedDrugName]);

  const columns = useSelector(columnSelector);
  if (!columns) return null;

  return (
    <>
      <div className='d-flex justify-content-between mb-3'>
        <SepeciltyPharmacySearch onSearch={setSelectedDrugName} options={drugOptions} defaultValue={drugOptions?.[0]} />
      </div>
      <ConnectedHcpGridWithGQL queryHook={useSpecialtyPharmacyContactQuery} queryOptions={queryOptions} pagination>
        <AgGridColumn field='specialtyPharmacyName' {...getFeatureGridColumnProps(columns?.specialtyPharmacyName)} />
        <AgGridColumn field='specialtyPharmacyType' {...getFeatureGridColumnProps(columns?.specialtyPharmacyType)} />
        <AgGridColumn field='phoneNumber' {...getFeatureGridColumnProps(columns?.phone)} />
        <AgGridColumn field='faxNumber' {...getFeatureGridColumnProps(columns?.fax)} />
        <AgGridColumn field='website' {...getFeatureGridColumnProps(columns?.website)} />
      </ConnectedHcpGridWithGQL>
    </>
  );
};
