import { useSelector } from 'react-redux';

import { EnrollmentRequest, RX_APPLICATION_NAME } from '../../api';

import { PortalEnrollmentRequest } from '../../api/interface-portal';
import { RxBasePayload } from '../../interfaces';
import { AppRootState } from '../../redux';
import { FINISHED_EVENT, standardPortalConfig } from '../constants';

export const getPharmacyEbvPayload = (
  values: PortalEnrollmentRequest,
  userName: string,
  organizationId: string
): RxBasePayload => {
  const patient = values?.patient;
  const physicians = values?.physicians;
  const drug: any = values?.drugInfo;
  const mobileNumber = patient?.patientPhoneNumbers?.find((phoneNumber) => phoneNumber.phoneType === 'mobile')
    ?.phoneNumber;
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  const insuranceDetails = values?.insurance;
  return {
    ClientApplication: standardPortalConfig.isStandardPortalUrl
      ? standardPortalConfig.applicationName
      : portal_ApplicationName ?? RX_APPLICATION_NAME,
    UserName: String(userName),
    RequestData: JSON.stringify({
      PatientFirstName: patient?.firstName ?? '',
      PatientMiddleName: patient?.middleName ?? '',
      PatientLastName: patient?.lastName ?? '',
      PatientGender: (patient?.gender as 'M' | 'F' | 'U') ?? 'U',
      PatientDOB: patient?.dob ?? '',
      PatientSuffix: null,
      PatientAddress1: patient?.address1 ?? '',
      PatientAddress2: patient?.address2 ?? '',
      PatientCity: patient?.city ?? '',
      PatientState: patient?.state ?? '',
      PatientZip: patient?.zip ?? '',
      PatientMobile: mobileNumber ?? '',
      PhysicianFirstName: physicians?.firstName ?? '',
      PhysicianLastName: physicians?.lastName ?? '',
      PhysicianNPI: physicians?.npi ?? '',
      DrugDescription: drug?.ndc?.split('-')?.[1]?.trim(),
      DrugNDC: drug?.ndc?.split('-')?.[0]?.trim(),
      DrugQuantity: drug?.quantity,
      DrugDaysSupply: drug?.daysSupply,
      MedicareID: null,
      SSN: null,
      ExternalAccountID: organizationId ?? null,
      ExternalPatientID: String(patient?.patientId),
      CustomRedirectUrl1: FINISHED_EVENT,
      PharmacyNCPDPID: 0,
      Last4SSN: insuranceDetails?.last4SSN,
      MedicareBeneficiaryId: insuranceDetails?.medicareBeneficiaryId
    })
  };
};
