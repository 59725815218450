import React, { useState } from 'react';

import { FORM_ERROR } from 'final-form';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';

import { FIELD_NAMES, getAddUserSchema, ROUTE_PATHS } from '../utils';
import { API_APPLICATION_NAME, API_DEMO_APPLICATION_NAME, useCreatePortalUser } from '../api';
import { AppRootState, navigateToPractice } from '../redux';
import { AnyType, GeneralObject } from '../interfaces';
import { HcpButton, HcpFormRenderer, HcpFormTemplate, PrivateLayout } from '../components';
import { standardPortalConfig } from '../utils/constants';

const { prescriberFields } = FIELD_NAMES;

export const AddUserView = () => {
  const dispatch = useDispatch();
  const [formKey, setFormKey] = useState(new Date().toISOString());
  const { mutate: createUser } = useCreatePortalUser({});
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);

  const onSubmit = async (values: GeneralObject) => {
    try {
      const payload = {
        firstName: String(values[prescriberFields.firstName]),
        lastName: String(values[prescriberFields.lastName]),
        userName: String(values.username),
        userType: String(values.user_type),
        emailAddress: String(values[prescriberFields.email]),
        userRole: (values?.is_admin as AnyType[])?.[0] ? 'Admin' : null,
        npi: String(values[prescriberFields.npi]),
        faxNumber: values[prescriberFields.fax] ? String(values[prescriberFields.fax]) : null,
        cellNumber: String(values[prescriberFields.cellNumber])?.replace(/[^\d]/g, ''),
        applicationName: standardPortalConfig.isStandardPortalUrl
          ? String(API_DEMO_APPLICATION_NAME)
          : String(portal_ApplicationName)
      };

      const result = await createUser(payload);

      if (result.errors && result.errors.length > 0) {
        const errors = result.errors?.map((e) => e.description).join(' ');

        // Return the form error to display
        return { [FORM_ERROR]: errors };
      }

      // Submission worked so let's redirect, if desired based on the button used
      if (values.redirect === true) {
        dispatch(navigateToPractice());
        return;
      }

      // Low-tech way to reset the form until form.restart is available;
      // see https://github.com/final-form/react-final-form/issues/840
      setFormKey(new Date().toISOString());
    } catch (e) {
      // TODO: validate the messages that _could_ show here.
      return { [FORM_ERROR]: e.message };
    }
  };

  const backNavigationText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['back.navigation.text' ?? ''] as AnyType)?.data
  );

  return (
    <PrivateLayout
      pageTitle='add a user'
      breadcrumbs={[{ label: backNavigationText?.content, href: `${ROUTE_PATHS.practice}#users` }]}>
      <HcpFormRenderer
        contentKey='form.user'
        FormTemplate={AddUserFormTemplate}
        key={formKey}
        onCancel={() => dispatch(navigateToPractice())}
        onSubmit={onSubmit}
        schemaMethod={getAddUserSchema}
      />
    </PrivateLayout>
  );
};

const AddUserFormTemplate = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const { submitting, hasValidationErrors, submitError, values } = formProps.getState();
        return (
          <>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Row className='mt-4'>
              <Col md={7} className='d-flex flex-column flex-lg-row justify-content-md-start'>
                <HcpButton
                  className='mb-2 mb-lg-0 mr-lg-2'
                  disabled={submitting || hasValidationErrors}
                  onClick={() => formProps.change('redirect', true)}
                  type='submit'
                  variant='secondary'
                  data-testid='cmx__submit-button'
                  loading={submitting && values.redirect}>
                  {submitting && values.redirect ? 'SUBMITTING...' : 'ADD USER AND CLOSE'}
                </HcpButton>
                <HcpButton
                  disabled={submitting || hasValidationErrors}
                  className='mt-2 mt-lg-0 ml-lg-2'
                  onClick={() => formProps.change('redirect', false)}
                  type='submit'
                  variant='outline-secondary'
                  loading={submitting && !values.redirect}>
                  {submitting && !values.redirect ? 'SUBMITTING...' : 'SAVE USER AND ADD ANOTHER'}
                </HcpButton>
              </Col>
              <Col md={5} className='d-flex justify-content-md-end'>
                <HcpButton variant='transparent' className='text-danger mt-3 mt-md-0' onClick={formProps.onCancel}>
                  CANCEL
                </HcpButton>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};
