import _ from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ResourceContent, ResourceImage } from '.';
import { AnyType } from '../interfaces';
import { AppRootState, navigateToHome, navigateToLogin } from '../redux';
import { rawHtmlProps, ROUTE_PATHS } from '../utils';
import { CustomThemes } from '../utils/custom-themes';

export const Footer = () => {
  CustomThemes();
  const isAuthenticated = useSelector((state: AppRootState) => state.auth.session?.user !== undefined);
  const footerLinks = useSelector((state: AppRootState) => (state.app.entities?.menus as AnyType)?.footer?.data?.links);
  const isFooterLogo = useSelector(
    (state: AppRootState) => ((state.app?.entities?.resources as AnyType) ?? {})['footer.caremetx-logo'] as AnyType
  );
  const isFooterText = useSelector(
    (state: AppRootState) => ((state.app?.entities?.resources as AnyType) ?? {})['footer.text'] as AnyType
  );
  const dispatch = useDispatch();

  return (
    <Container fluid className='cmx__footer' data-testid='cmx__footer'>
      <Container className='text-center'>
        {isFooterLogo?.data?.url && (
          <ResourceImage
            contentKey='footer.caremetx-logo'
            className='cmx__footer__logo pointer'
            onClick={() => dispatch(isAuthenticated ? navigateToHome() : navigateToLogin())}
          />
        )}
        {isFooterText?.data?.content && (
          <ResourceContent
            contentKey='footer.text'
            sourceDataKey='content'
            render={(body) => <span {...rawHtmlProps(body?.toString())} />}
          />
        )}
      </Container>
      <Container className='cmx__footer__container'>
        <Row />
      </Container>
      <Container className='cmx__footer__sub'>
        <Row>
          <Col md='6' lg='4' className='text-center text-md-left text-uppercase'>
            {footerLinks?.map((link: AnyType) => (
              <a
                key={_.kebabCase(link.label)}
                href={(ROUTE_PATHS as AnyType)[link.url]}
                target='_blank'
                rel='noreferrer'
                className='pr-5'>
                {link.label}
              </a>
            ))}
          </Col>

          <Col md='6' lg='4' className='text-center text-md-right text-lg-center'>
            &copy;{new Date().getFullYear()} <ResourceContent contentKey='footer.copyright' />
          </Col>
          <Col lg='4' className='text-center text-lg-right'>
            <em>
              <ResourceContent contentKey='footer.powered-by-label' />
            </em>
            <ResourceImage contentKey='footer.powered-by-logo' className='w-25' />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
