import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AuthLayout, AuthLayoutHeader, PublicLayout } from '../components';
import { ResourceContent } from '../components';
import image from '../images/img_Hero.jpg';
import { navigateToForgotPassword } from '../redux';
import { ROUTE_PATHS } from '../utils';
import { useSelectTenancyResources } from '../hooks';

export const ForgotPasswordSuccessView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successMessage = useSelectTenancyResources('forgot-password.success');
  const [preparedSuccessMessage, setPreparedSuccessMessage] = useState<string>('');

  useEffect(() => {
    if (successMessage?.content) {
      const mapObj = {
        '<timeframe>': '24 hours',
        '<system email>': 'caremetx.system.messages@caremetx.com'
      };
      const msg = successMessage?.content?.replace(/<timeframe>|<system email>/gi, (matched: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return mapObj[matched];
      });
      setPreparedSuccessMessage(msg);
    }
  }, [successMessage]);

  // Redirect the user if they came to the success page without starting at the forgot password page
  useEffect(() => {
    if (history.action.toString().toLowerCase() === 'pop') {
      dispatch(navigateToForgotPassword());
    }
  }, [history, dispatch]);

  return (
    <PublicLayout fluid className='authlayout_custom_image'>
      <AuthLayout image={image}>
        <AuthLayoutHeader title='Forgot Your Password?' />
        <Row className='mb-4'>
          <Col>
            <h2 className='text-secondary'>
              <CheckCircleFill /> SUCCESS
            </h2>
          </Col>
        </Row>

        <Row>
          <Col className='text-custom-50'>
            <p>{preparedSuccessMessage}</p>
            <p>
              If you need to send the link again, click <Link to={ROUTE_PATHS.forgotPassword}>HERE</Link>.
            </p>
          </Col>
        </Row>
      </AuthLayout>
    </PublicLayout>
  );
};
