import { RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HcpGrid, HcpRequestStateUI, UserSearch } from '.';
import { useUpdateUser, useGetUsers, API_DEMO_APPLICATION_NAME } from '../api';
import { AnyType, GeneralObject } from '../interfaces';
import { AppRootState, loadConfig, navigateToUserNew } from '../redux';
import {
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  isEmptyObject,
  phoneValueFormatter,
  rawHtmlProps
} from '../utils';
import EditUserDetails from './EditUserDetails';
import { FeatureGuard } from './multi-tenant/FeatureGuard';
import { standardPortalConfig } from '../utils/constants';

const columnSelector = featureGridColumnsSelector('users.table');
const initialValue = { email: '', phone: '' };

export const UsersTable = () => {
  const [landing, setLanding] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateOperation, setUpdateOperation] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>();
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  const { mutate: updateUser } = useUpdateUser({});
  const result = useGetUsers({ lazy: true });
  const verbiageText = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['account-administration-verbiage'] as AnyType)?.data || {}
  );

  const onSubmit = async (values: GeneralObject) => {
    setLoading(true);
    setError('');
    try {
      const payload = {
        applicationName: standardPortalConfig.isStandardPortalUrl
          ? String(API_DEMO_APPLICATION_NAME)
          : String(portal_ApplicationName),
        emailAddress: String(values.email),
        cellNumber: String(values.cellNumber)?.replace(/[^\d]/g, ''),
        userName: String(values.userName)
      };

      const updateUserResult = await updateUser(payload);

      if (updateUserResult.errors && updateUserResult.errors.length > 0) {
        const errors = updateUserResult.errors?.map((e) => e.description).join(' ');
        setLoading(false);
        setUpdateOperation(true);
        setError(errors);
        return errors;
      }

      if (updateUserResult?.isSuccess) {
        setLoading(false);
        handleClose();
        dispatch(loadConfig());
        setUpdateOperation(true);
        return;
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
      return { error: e.message };
    }
  };

  const loadData = useCallback(() => {
    if (!organizationId) return;
    if (landing || updateOperation) {
      result.refetch({});
      setUpdateOperation(false);
    }
    setLanding(false);
  }, [organizationId]);

  useEffect(loadData, [loadData, updateOperation]);

  const handleClickOpen = (data: any) => {
    setFormData(data);
    setOpen(true);
    setError('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterUsers = (node: RowNode, value: string | undefined) => {
    const name = `${node.data?.firstName} ${node.data?.lastName}`.toLowerCase();
    const username = node.data?.userName?.toLowerCase();
    const email = node.data?.email?.toLowerCase();
    const cellNumber = node.data?.cellNumber?.toLowerCase();
    return (
      name.includes(value ?? '') ||
      username.includes(value ?? '') ||
      email.includes(value ?? '') ||
      (cellNumber && cellNumber.includes(value ?? ''))
    );
  };

  const columns = useSelector(columnSelector);

  if (!columns) return null;

  return (
    <>
      <HcpRequestStateUI
        loadingUIEnabled
        isLoading={result.loading || !result.data}
        errorUIEnabled
        isError={result.error}
        reloadingEnabled
        reload={loadData}>
        <div className='d-flex justify-content-between mb-3'>
          <div className='w-25'>
            <UserSearch onSearch={setSearchValue} />
          </div>
          <FeatureGuard contentKey={'practice-management.users.manage'}>
            <Button
              variant='secondary'
              data-testid='navigateToUserNewBtn'
              onClick={() => dispatch(navigateToUserNew())}>
              ADD A USER
            </Button>
          </FeatureGuard>
        </div>
        <HcpGrid
          isFilterActive={!!searchValue}
          filterComparator={filterUsers}
          filterValue={searchValue}
          rowData={result.data?.users || []}
          pagination>
          <AgGridColumn field='firstName' {...getFeatureGridColumnProps(columns.firstName)} />
          <AgGridColumn field='lastName' {...getFeatureGridColumnProps(columns.lastName)} />
          <AgGridColumn field='userName' {...getFeatureGridColumnProps(columns.username)} />
          <AgGridColumn field='userType' maxWidth='125' {...getFeatureGridColumnProps(columns.userType)} />
          <AgGridColumn field='email' {...getFeatureGridColumnProps(columns.email)} />
          <AgGridColumn
            field='cellNumber'
            {...getFeatureGridColumnProps(columns.phone)}
            valueFormatter={phoneValueFormatter}
            valueGetter={phoneValueFormatter}
          />
          <AgGridColumn
            cellRenderer='IsAdminRenderer'
            colId='isAdmin'
            field='isAdmin'
            maxWidth='100'
            {...getFeatureGridColumnProps(columns.admin)}
          />
          <AgGridColumn
            cellRenderer='UserStatusRenderer'
            colId='isActive'
            field='isActive'
            filter='false'
            maxWidth='100'
            {...getFeatureGridColumnProps(columns.status)}
          />
          <AgGridColumn
            cellRenderer='ResetPasswordRenderer'
            filter='false'
            maxWidth='125'
            sortable='false'
            {...getFeatureGridColumnProps(columns.password)}
          />
          <AgGridColumn
            headerName='EDIT'
            cellRenderer='EditUserRenderer'
            onCellClicked={(params: any) => handleClickOpen(params?.data)}
            filter='false'
            maxWidth='125'
            sortable='false'
          />
        </HcpGrid>
      </HcpRequestStateUI>
      {!isEmptyObject(verbiageText) && (
        <span className='text-primary ag-theme-alpine' {...rawHtmlProps(verbiageText)} />
      )}
      {open && (
        <EditUserDetails
          isLoading={loading}
          error={error}
          handleClose={handleClose}
          data={formData}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
