import React, { useState } from 'react';

import { AnyObject } from 'final-form';
import { Button, Col, Row } from 'react-bootstrap';
import FormSpy from '@data-driven-forms/react-form-renderer/dist/cjs/form-spy';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { navigateToHome, navigateToServiceRequest } from '../../redux';
import { selectNext } from '.';
import { useDispatch } from 'react-redux';
import { scrollToTop } from '../../utils';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

interface NextButtonProps extends FormOptions {
  handleNext?: (nextStep: string) => void;
  nextStep: string;
}

const NextButton = ({ nextStep, handleNext, getState, handleSubmit }: NextButtonProps) => {
  const { hasValidationErrors, validating } = getState();
  const [submitting, setSubmitting] = useState(false);
  const onNext = () => {
    if (nextStep) {
      handleNext?.(selectNext(nextStep, getState));
      scrollToTop();
    } else {
      setSubmitting(true);
      handleSubmit();
    }
  };

  return (
    <Button
      type='button'
      variant='secondary'
      className='text-uppercase'
      disabled={hasValidationErrors || validating || submitting}
      onClick={onNext}>
      {nextStep ? 'next' : submitting ? 'submitting...' : 'submit'}
    </Button>
  );
};

interface FormWizardActions extends Schema, AnyObject {
  disableBack: boolean;
  formOptions: FormOptions;
  handleNext?: (nextStep: string) => void;
  handlePrev?: () => void;
  autoService?: boolean;
}

export const FormWizardActions = ({ formOptions, ...props }: FormWizardActions) => {
  const dispatch = useDispatch();

  if (props?.autoService && props?.nextStep == '') {
    formOptions.handleSubmit();
  }
  const handleCancel = () => {
    sessionStorage.setItem('performedServices', '');
    formOptions.reset({});
    dispatch(navigateToHome());
  };
  return (
    <FormSpy subscription={{ valid: true, validating: true, submitting: true, submitError: true }}>
      {({ submitError }) => (
        <Row className='mt-4'>
          {submitError && <p className='text-danger fs-2'>{submitError}</p>}
          <Col xs={4} className='d-flex justify-content-md-start'>
            {!props.disableBack && (
              <Button type='button' variant='outline-secondary' className='text-uppercase' onClick={props.handlePrev}>
                back
              </Button>
            )}
            {props.disableBack && (
              <Button
                type='button'
                variant='outline-secondary'
                className='text-uppercase'
                onClick={() => dispatch(navigateToServiceRequest())}>
                back
              </Button>
            )}
          </Col>
          <Col xs={4} className='d-flex justify-content-md-center'>
            <Button type='button' variant='transparent' className='text-uppercase text-danger' onClick={handleCancel}>
              cancel
            </Button>
          </Col>
          <Col xs={4} className='d-flex justify-content-md-end'>
            <NextButton {...formOptions} nextStep={props.nextStep} handleNext={props?.handleNext} />
          </Col>
        </Row>
      )}
    </FormSpy>
  );
};
