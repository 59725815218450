import React from 'react';
import { Image, Container } from 'react-bootstrap';
import { ResourceImage } from '.';
import logo from '../images/logo_CareMetx.png';
import { ROUTE_PATHS } from '../utils';
import { Link } from 'react-router-dom';
import { CustomThemes } from '../utils/custom-themes';

export const LogoToolbar = () => {
  CustomThemes();
  return (
    <Container fluid className='cmx__logo-toolbar'>
      <Container className='d-flex justify-content-center justify-content-md-start'>
        <Link to={ROUTE_PATHS.login}>
          <ResourceImage contentKey='header.logo' className='cmx__logo-toolbar__image pointer' />
        </Link>
      </Container>
    </Container>
  );
};
