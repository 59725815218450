import React, { useEffect } from 'react';

import { Container } from 'react-bootstrap';

import { Breadcrumb } from '../interfaces';
import { BreadcrumbToolbar, ConnectedMainToolbar, HcpSessionManager, SubToolbar } from '.';
import { scrollToTop } from '../utils';

interface PrivateLayoutProps extends React.PropsWithChildren<unknown> {
  className?: string;
  breadcrumbs?: Breadcrumb[];
  fullWidth?: boolean;
  pageTitle?: string;
  subtoolbarChildren?: React.ReactNode;
  disableViewContainer?: boolean;
  contentKey?: string;
}

export const PrivateLayout = ({
  breadcrumbs,
  children,
  className,
  fullWidth,
  pageTitle,
  subtoolbarChildren,
  disableViewContainer,
  contentKey
}: PrivateLayoutProps) => {
  useEffect(scrollToTop, []);

  const WrapperComponent = fullWidth ? 'div' : Container;
  const wrapperClassName = fullWidth ? 'px-4' : '';

  return (
    <>
      <ConnectedMainToolbar />
      {!disableViewContainer && (
        <SubToolbar pageTitle={pageTitle} contentKey={contentKey}>
          {subtoolbarChildren}
        </SubToolbar>
      )}
      {breadcrumbs && <BreadcrumbToolbar breadcrumbs={breadcrumbs} />}
      {!disableViewContainer && (
        <WrapperComponent className={`view-container ${wrapperClassName} ${className ?? ''}`.trim()}>
          <HcpSessionManager />
          {children}
        </WrapperComponent>
      )}
      {disableViewContainer && (
        <div>
          <HcpSessionManager />
          {children}
        </div>
      )}
    </>
  );
};
