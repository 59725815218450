import React from 'react';
import { Redirect } from 'react-router';
import { tenancyFeatureSelector } from '../redux/selectors';
import { useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { LoadingIndicator } from '../components';

export const LandingPage = () => {
  const landingPageURI: AnyType = useSelector(tenancyFeatureSelector('landingPageURI'));

  if (!landingPageURI) {
    return (
      <div className='text-center p-4'>
        <p>Loading Application Configurations</p>
        <LoadingIndicator />
      </div>
    );
  }
  return <Redirect to={landingPageURI?.URI} />;
};
