import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

import { DBFormSchema } from '../field-helpers';
import { StepNames } from '.';

export const reviewTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const reviewTab = schema?.review_tab;

  if (!reviewTab || reviewTab?.hideTab) return null;

  return {
    title: reviewTab?.label ?? 'Review & Submit',
    name: StepNames.REVIEW,
    nextStep: reviewTab?.nextStep ?? nextStep,
    fields: [
      {
        name: 'review',
        component: 'service-request-review'
      }
    ]
  };
};
