import { RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react';
import React, { useState } from 'react';
import { Eye, FileText } from 'react-bootstrap-icons';
import { useHistory, useParams } from 'react-router-dom';

import { alertCellRenderer, CasesFilter, ConnectedHcpGridWithGQL } from '.';
import { useCaseRecordsQuery } from '../graphql';
import {
  casefilterGridComparator,
  CasesFilterTypes,
  dateColumnDefinition,
  providerColumnDefinition,
  ROUTE_PATHS,
  caseStatusValueFormatter,
  drugDescription,
  ActionColumnActions,
  actionsColumnDefinition,
  ActionsCellRenderer,
  ActionsCellEditor
} from '../utils';

interface PatientCasesTableProps {
  enableExternalFilters?: boolean;
  filteredStatus?: string;
}

export const PatientCasesTable = (props: PatientCasesTableProps) => {
  const [filters, setFilters] = useState<Set<string>>(new Set());

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const redirectToCase = (params: any, tabName: string) => {
    history.push(`${ROUTE_PATHS.patients}/${params.data.patientId}/${params.data.caseRecordId}#${tabName}`);
  };

  const filterProps = props.enableExternalFilters
    ? {
        isFilterActive: !filters?.has(CasesFilterTypes.all),
        filterComparator: casefilterGridComparator,
        filterValue: filters
      }
    : {};

  return (
    <>
      {props.enableExternalFilters && <CasesFilter onFilterChange={(filter: Set<string>) => setFilters(filter)} />}
      {((props.enableExternalFilters && filters) || !props.enableExternalFilters) && (
        <ConnectedHcpGridWithGQL
          {...filterProps}
          queryHook={useCaseRecordsQuery}
          queryOptions={{
            variables: {
              patientId: id
            }
          }}
          pagination
          className='grid-wrapper'
          frameworkComponents={{
            alertCellRenderer,
            ActionsCellRenderer,
            ActionsCellEditor
          }}>
          <AgGridColumn
            headerName=''
            cellRenderer='alertCellRenderer'
            maxWidth={75}
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            {...providerColumnDefinition('PROVIDER')}
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            {...drugDescription('caseDrugDescription', 'DRUG')}
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            field='caseStatus'
            headerName='STATUS'
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            field='caseSubStatus'
            headerName='SUB-STATUS'
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            field='caseClosed'
            headerName='CASE STATUS'
            valueFormatter={caseStatusValueFormatter}
            valueGetter={caseStatusValueFormatter}
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            field='caseRecordId'
            headerName='CASE ID'
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            {...dateColumnDefinition('caseReceivedDate')}
            headerName='RECEIVED'
            onCellClicked={(params: any) => redirectToCase(params, 'caseDetail')}
          />
          <AgGridColumn
            {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
              {
                href: `${ROUTE_PATHS.patients}/${node.data.patientId}/${node.data.caseRecordId}#${'caseDetail'}`,
                label: 'View Case',
                icon: Eye
              },
              {
                href: `${ROUTE_PATHS.serviceRequest}/case/${node.data.caseRecordId}/patient/${node.data.patientId}/${node.data.caseDrugName}`,
                label: 'Start New Request',
                icon: FileText
              }
            ])}
          />
        </ConnectedHcpGridWithGQL>
      )}
    </>
  );
};
