import React, { useCallback, useEffect, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { ArrowLeftCircleFill, ArrowRightCircleFill, CircleFill, ReplyFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { API_URL, Message, MessageFile, MessageThreadViewModel, useCaseThreads, useThreadDetails } from '../api';
import { useCaseDetailsQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState, getUnreadMessageCount } from '../redux';
import {
  clientApplicationNameSelector,
  dateFormatter,
  INCOMING_MESSAGE,
  OUTGOING_MESSAGE,
  REPLY,
  timeFormatter,
  UNREAD_MESSAGE
} from '../utils';
import { CaseMessageCompose } from './CaseMessageCompose';
import { AngleDown, AngleUp } from './custom-icons';
import { first } from 'rxjs/operators';

interface CaseMessagesSectionParams {
  updateContent: number;
}

export const CaseMessagesSection = (props: CaseMessagesSectionParams) => {
  const { caseId } = useParams<{ patientId: string; caseId: string }>();
  const [isComposing, setIsComposing] = useState<boolean>(false);
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const applicationName = useSelector(clientApplicationNameSelector);
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? ''));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const userName = String(useSelector((state: AppRootState) => state.auth.session?.user?.Login ?? ''));
  let foundFirstUnreadMessage = false;
  let isFistUnreadMessage = false;

  // Prepare the latest opened SR ID.
  const caseResult = useCaseDetailsQuery({ variables: { caseRecordId: caseId } });
  const caseData = caseResult?.data?.result;
  const caseTransactions = caseData?.transactions ?? [];
  const openSRs = caseTransactions?.filter(
    (txn) => 'True' !== txn?.caseTransactionState && 'ServiceRequest' === txn?.caseTransactionType
  );
  openSRs?.sort(
    (a: AnyType, b: AnyType) =>
      new Date(a?.mileStoneCreatedDate)?.valueOf() - new Date(b?.mileStoneCreatedDate)?.valueOf()
  );
  const latestSRID = openSRs[openSRs.length - 1]?.caseTransactionId;

  // Loading the threads and messages.
  const [threads, setThreads] = useState<MessageThreadViewModel[]>([]);
  const caseSecureMessageThreads = useCaseThreads({
    base: API_URL,
    lazy: true
  });
  const { data: messages, loading, ...result } = caseSecureMessageThreads;
  const loadData = useCallback(() => {
    if (!currentUser) return;
    result.refetch({
      queryParams: {
        applicationName: applicationName,
        caseRecordId: Number(caseId)
      }
    });
  }, [currentUser]);

  useEffect(loadData, [loadData, props.updateContent]);

  useEffect(() => {
    if (messages && messages?.messageThreads) {
      setThreads(
        messages?.messageThreads?.sort(
          (a: MessageThreadViewModel, b: MessageThreadViewModel) =>
            new Date(b?.lastMessageDate ?? '')?.valueOf() - new Date(a?.lastMessageDate ?? '')?.valueOf()
        ) ?? []
      );
    }
  }, [messages]);

  // Other events
  const onComposeComplete = useCallback(() => {
    setIsComposing(false);
    loadData();
  }, [isComposing]);

  const onComposeHide = useCallback(() => {
    setIsComposing(false);
  }, [setIsComposing]);

  return (
    <>
      <div>
        <NewMessageButton
          className='text-right'
          isComposing={isComposing}
          serviceRequestId={latestSRID}
          onClick={() => setIsComposing(true)}
          display={!isComposing && threads?.length > 0}
          value='New Message'
        />

        {isComposing && latestSRID && (
          <CaseMessageCompose
            isShown={isComposing}
            onHide={onComposeHide}
            onSent={onComposeComplete}
            serviceRequestId={latestSRID}
          />
        )}
      </div>
      <div>
        {loading && <Spinner animation='border' size='sm' />}
        {!loading &&
          threads?.length > 0 &&
          threads?.map((thread: MessageThreadViewModel) => {
            isFistUnreadMessage = false;
            if (!foundFirstUnreadMessage && !thread?.isRead) {
              foundFirstUnreadMessage = true;
              isFistUnreadMessage = true;
            }
            return (
              <MessageThreadSection
                isFirstUnreadMessage={isFistUnreadMessage}
                key={`message-thread-${thread?.threadID}`}
                mainThread={thread}
                caseId={caseId}
              />
            );
          })}
        {!loading && threads?.length <= 0 && !isComposing && (
          <div className='case-message-wrapper__no-messages'>
            <p>You currently have no messages for this patient. Click below to start a new one.</p>
            <NewMessageButton
              isComposing={isComposing}
              serviceRequestId={latestSRID}
              onClick={() => setIsComposing(true)}
              display={!isComposing}
              value='Start a New Message'
            />
          </div>
        )}
      </div>
    </>
  );
};

type NewMessageButtonType = {
  isComposing: boolean;
  serviceRequestId: number | undefined;
  onClick: AnyType;
  className?: string;
  display: boolean;
  value: string;
};
const NewMessageButton = ({
  className,
  isComposing,
  serviceRequestId,
  onClick,
  display,
  value
}: NewMessageButtonType) => {
  return display ? (
    display && (
      <div className={className}>
        <Button
          className='text-nowrap'
          variant='outline-secondary'
          size='sm'
          onClick={onClick}
          disabled={isComposing || !serviceRequestId}>
          {value}
        </Button>
      </div>
    )
  ) : (
    <></>
  );
};

const MessageThreadSection = ({
  isFirstUnreadMessage,
  mainThread,
  caseId
}: {
  isFirstUnreadMessage: boolean;
  mainThread: MessageThreadViewModel;
  caseId: string;
}) => {
  const [collapse, setCollapse] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  const applicationName = useSelector(clientApplicationNameSelector);
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? ''));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const userName = String(useSelector((state: AppRootState) => state.auth.session?.user?.Login ?? ''));
  const caseSecureMessage = useThreadDetails({
    base: API_URL,
    lazy: true
  });
  const { data: response, loading, ...result } = caseSecureMessage;
  const [toggleCount, setToggleCount] = useState(0);
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (!mainThread?.threadID || !currentUser) return;

    result.refetch({
      queryParams: {
        applicationName: applicationName,
        threadId: Number(mainThread?.threadID)
      }
    });
  }, [mainThread, currentUser]);

  useEffect(() => {
    if (response && response?.messageThread) {
      setMessages(
        response?.messageThread?.messages?.sort(
          (a: Message, b: Message) =>
            new Date(b?.messageDate ?? '')?.valueOf() - new Date(a?.messageDate ?? '')?.valueOf()
        ) ?? []
      );
    }
  }, [response]);

  const toggleCollapse = () => {
    const nextCollapseState = !collapse;
    // toggleCount is maintained to display unread message subject bold on first open
    toggleCount < 1 ? setOpened(false) : setOpened(true);
    dispatch(getUnreadMessageCount(caseId));
    if (!nextCollapseState) {
      loadData();
    }
    setCollapse(nextCollapseState);
    setToggleCount(toggleCount + 1);
  };

  // Other events
  const onComposeComplete = useCallback(() => {
    setIsReplying(false);
    loadData();
  }, [isReplying]);

  const onComposeHide = useCallback(() => {
    setIsReplying(false);
  }, [setIsReplying]);

  const isSRClosed = (): boolean => 'c' === response?.messageThread?.sR_Status?.toLowerCase();

  useEffect(() => {
    if (isFirstUnreadMessage && !mainThread?.isRead) {
      toggleCollapse();
    }
  }, []);

  return (
    <div className='case-message-wrapper__thread-wrapper'>
      <div className='case-message-wrapper__list-head' onClick={toggleCollapse}>
        <div className='case-message-wrapper__head-detail'>
          <p className={!mainThread?.isRead && !opened ? 'case-message-wrapper__unread-message' : ''}>
            {mainThread?.subject}
          </p>
          <p>
            <small className={!mainThread?.isRead && !opened ? 'case-message-wrapper__unread-message' : ''}>
              Last message: {timeFormatter(mainThread?.lastMessageDate)} | {dateFormatter(mainThread?.lastMessageDate)}
            </small>
            <small className='case-message-wrapper__unread-icon'>
              {!mainThread?.isRead && !opened && (
                <OverlayTrigger placement='top' overlay={<Tooltip id='incoming-message'>{UNREAD_MESSAGE}</Tooltip>}>
                  <CircleFill />
                </OverlayTrigger>
              )}
            </small>
          </p>
        </div>
        <hr />
        <div>
          {collapse ? (
            <AngleDown tabIndex={0} className='pointer fs-6 my-auto' />
          ) : (
            <AngleUp tabIndex={0} className='pointer fs-6 my-auto' />
          )}
        </div>
      </div>
      <div className={collapse ? `collapse` : `collapse show`} id={`${mainThread?.threadID}`}>
        {loading && <Spinner animation='border' size='sm' />}
        {!loading &&
          isReplying &&
          response?.messageThread?.serviceRequestID &&
          !isSRClosed() &&
          response?.messageThread?.threadID && (
            <CaseMessageCompose
              isShown={isReplying}
              onHide={onComposeHide}
              onSent={onComposeComplete}
              serviceRequestId={response?.messageThread?.serviceRequestID}
              threadId={response?.messageThread?.threadID}
              defaultSubject={response?.messageThread?.subject ?? undefined}
            />
          )}
        {!loading &&
          messages?.map((message: Message) => (
            <div key={message.messageID} className='case-message-wrapper'>
              <div className='case-message-wrapper__icon'>
                {'HCP' === message?.source ? (
                  <OverlayTrigger placement='top' overlay={<Tooltip id='outgoing-message'>{OUTGOING_MESSAGE}</Tooltip>}>
                    <ArrowLeftCircleFill tabIndex={0} className='pointer fs-6 my-auto' />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger placement='top' overlay={<Tooltip id='incoming-message'>{INCOMING_MESSAGE}</Tooltip>}>
                    <ArrowRightCircleFill tabIndex={0} className='pointer fs-6 my-auto' />
                  </OverlayTrigger>
                )}
              </div>
              <div className='case-message-wrapper__message'>
                <div className='case-message-wrapper__thread-head'>
                  <p>
                    <small
                      className={
                        !message?.isRead && 'HCP' !== message?.source ? 'case-message-wrapper__unread-message' : ''
                      }>
                      FROM: {message?.sender}
                    </small>
                    <small className='case-message-wrapper__unread-icon'>
                      {!message?.isRead && 'HCP' !== message?.source && (
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='incoming-message'>{UNREAD_MESSAGE}</Tooltip>}>
                          <CircleFill />
                        </OverlayTrigger>
                      )}
                    </small>
                  </p>
                  <p>
                    <small
                      className={
                        !message?.isRead && 'HCP' !== message?.source ? 'case-message-wrapper__unread-message' : ''
                      }>{`${timeFormatter(message?.messageDate)} | ${dateFormatter(message?.messageDate)}`}</small>
                  </p>
                </div>
                <div className='card card-body'>
                  <div className='text-white-space-wrap'>{message?.messageText}</div>
                  <div className='mt-3'>
                    {message?.files &&
                      message?.files.map(
                        (file: MessageFile) =>
                          file?.fileData && (
                            <a
                              key={file?.documentId}
                              href={`data:${file?.fileType};base64,${file?.fileData}`}
                              download={file?.fileName}
                              target='_blank'
                              className='case-message-wrapper__download-files'
                              rel='noreferrer'>
                              {file?.fileName}
                            </a>
                          )
                      )}
                  </div>
                </div>
              </div>
              <div className={`case-message-wrapper__icon--reply ${isSRClosed() && 'text-muted'}`}>
                <OverlayTrigger placement='top' overlay={<Tooltip id='case_reply-message'>{REPLY}</Tooltip>}>
                  <ReplyFill
                    tabIndex={0}
                    className={`${!isSRClosed() && 'pointer'} fs-6 my-auto`}
                    onClick={() => setIsReplying(true)}
                  />
                </OverlayTrigger>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
