import React from 'react';
import { AnyType } from '../../interfaces';

import { SpecialtyPharmacyTable } from './SpecialtyPharmacyTable';

export type ResourcesSpecialtyPharmacyProp = {
  specialtyPharmacyConfig: AnyType;
};

export const ResourcesSpecialtyPharmacyTab = (props: ResourcesSpecialtyPharmacyProp) => {
  const specialtyPharmacyConfig: AnyType = props?.specialtyPharmacyConfig;
  return (
    <>
      {specialtyPharmacyConfig?.contactInfo?.enabled && (
        <div className='mt-3'>
          <div>
            <SpecialtyPharmacyTable />
          </div>
        </div>
      )}
    </>
  );
};
