import { LazyQueryHookOptions, QueryTuple } from '@apollo/client';
import { AnyObject } from 'final-form';
import React, { PropsWithRef, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { AsyncTypeahead, AsyncTypeaheadProps, TypeaheadLabelKey } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';
import { GRAPHQL_DEFAULT_CACHE } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { debounce } from 'lodash';

export enum SearchInputWithGqlIconLocation {
  prepend,
  append,
  none
}

export enum SearchInputWithGqlDisplay {
  outlined = 'outlined',
  underlined = 'underlined'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SearchInputWithGqlProps {
  asyncTypeaheadProps?: Partial<AsyncTypeaheadProps<AnyType> & PropsWithRef<AnyType>>;
  display?: SearchInputWithGqlDisplay;
  labelRenderer: TypeaheadLabelKey<AnyType>; // (option: AnyType) => string | undefined;
  filterBy?: string[];
  selected?: string[];
  minLength?: number;
  multiple?: boolean;
  onItemSelect: (item: AnyType) => void;
  organizationIdRequired?: boolean;
  placeholder?: string;
  programIdRequired?: boolean;
  queryHook: (baseOptions?: LazyQueryHookOptions<AnyType, AnyType>) => QueryTuple<AnyType, AnyType>;
  queryOptions?: LazyQueryHookOptions<AnyType, Record<string, AnyType>>;
  queryOptionsOnSearch?: (
    queryOptions: LazyQueryHookOptions<AnyType, Record<string, AnyType>>,
    searchTerm: string
  ) => LazyQueryHookOptions<AnyType, Record<string, AnyType>>;
  searchIconLocation?: SearchInputWithGqlIconLocation;
  requestIdOptions?: any;
  setRequestIdOptions?: any;
  setIsGISrNeeded?: any;
  isGISrNeeded?: any;
  setEServicesOptions?: any;
  isDisabled?: any;
}

const getBorderClasses = (
  searchIconLocation: SearchInputWithGqlIconLocation
): { [key: string]: { input: string; button: string } } => {
  return {
    [SearchInputWithGqlDisplay.outlined]: {
      input:
        searchIconLocation === SearchInputWithGqlIconLocation.prepend
          ? 'border-left-0'
          : searchIconLocation !== SearchInputWithGqlIconLocation.none
          ? 'border-right-0'
          : '',
      button: searchIconLocation === SearchInputWithGqlIconLocation.prepend ? 'border-right-0' : 'border-left-0'
    },
    [SearchInputWithGqlDisplay.underlined]: {
      input: 'border-bottom border-right-0 border-left-0 border-top-0',
      button: 'border-0'
    }
  };
};

export const SearchInputWithGqlRequestWithDocumentInfo = ({
  display = SearchInputWithGqlDisplay.outlined,
  minLength = 2,
  organizationIdRequired = true,
  queryOptionsOnSearch = (qo) => qo,
  ...props
}: SearchInputWithGqlProps) => {
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId));
  const queryOptions = props.queryOptions ?? {};
  const secureMessageClosedServiceEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['secure-message.closed-service-request'] as AnyType)?.data
        ?.enabled
  );

  if ((organizationIdRequired && !organizationId) || (props.programIdRequired && !programId)) {
    return null;
  }

  if (!queryOptions.variables?.organizationId && organizationIdRequired) {
    queryOptions.variables = {
      ...queryOptions.variables,
      organizationId: organizationId,
      programId: programId ?? null
    };
  }

  const [execQuery, result] = props.queryHook({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  const onSearch = debounce((searchTerm: string) => {
    execQuery(queryOptionsOnSearch({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE }, searchTerm));
  }, 500);

  const onFocus = () => {
    execQuery();
  };

  useEffect(() => {
    let options = result?.data?.result ?? props.asyncTypeaheadProps?.options ?? [];
    const openCases = options.filter((request: any) => request?.requestStatus === 'O');
    const closeCase = options.filter((request: any) => request?.requestStatus === 'C');
    if (programId === '250' && closeCase.length > 0) {
      props?.setIsGISrNeeded(false);
    }
    if (openCases.length > 0) {
      props?.setIsGISrNeeded(false);
    } else {
      const eserviceTransactions = options.filter((request: any) => request?.isEserviceTransaction);
      if (eserviceTransactions?.length) {
        props?.setEServicesOptions(eserviceTransactions);
        props?.setIsGISrNeeded(true);
      } else {
        props?.setEServicesOptions([]);
        props?.setIsGISrNeeded(false);
      }
    }
    options = options.filter((option: AnyObject) => {
      if (secureMessageClosedServiceEnabled) {
        return option;
      } else {
        return option?.requestStatus === 'O';
      }
    });
    props?.setRequestIdOptions(options);
  }, [result?.data?.result]);

  const searchLocation = props.searchIconLocation ?? SearchInputWithGqlIconLocation.prepend;
  const borderClasses = getBorderClasses(searchLocation);

  const inputBorderClassName = borderClasses[display]['input'];
  const buttonBorderClassName = borderClasses[display]['button'];

  const icon = (
    <Button variant='transparent' className={`border ${buttonBorderClassName} rounded-right px-3`}>
      <Search className='text-gray' />
    </Button>
  );

  const onItemSelect = (selection: AnyType) => {
    const item = Array.isArray(selection) && !props.multiple ? selection[0] : selection;
    props.onItemSelect(item);
  };

  const isValidClass = props.asyncTypeaheadProps?.isValid ? 'is-valid' : '';
  const isInvalidClass = props.asyncTypeaheadProps?.isInvalid ? 'is-invalid' : '';

  return (
    <InputGroup className={`flex-nowrap ${isValidClass} ${isInvalidClass}`.trim()}>
      {/* This is crude and only a temporary work-around. */}
      {searchLocation === SearchInputWithGqlIconLocation.prepend && <InputGroup.Prepend>{icon}</InputGroup.Prepend>}
      <AsyncTypeahead
        {...props.asyncTypeaheadProps}
        highlightOnlyResult={!props.asyncTypeaheadProps?.allowNew}
        id='search-typeahead'
        inputProps={{
          ...props.asyncTypeaheadProps?.inputProps,
          className: inputBorderClassName
        }}
        isLoading={result.loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        labelKey={props.labelRenderer}
        minLength={minLength}
        multiple={props.multiple}
        onSearch={onSearch}
        onChange={onItemSelect}
        options={props?.requestIdOptions}
        useCache={false}
        placeholder={props.placeholder}
        filterBy={props.filterBy}
        selected={props.selected}
        onFocus={onFocus}
      />
      {searchLocation === SearchInputWithGqlIconLocation.append && <InputGroup.Append>{icon}</InputGroup.Append>}
    </InputGroup>
  );
};

export const SearchInputWithGql = ({
  display = SearchInputWithGqlDisplay.outlined,
  minLength = 2,
  organizationIdRequired = true,
  queryOptionsOnSearch = (qo) => qo,
  ...props
}: SearchInputWithGqlProps) => {
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId));
  const queryOptions = props.queryOptions ?? {};

  if ((organizationIdRequired && !organizationId) || (props.programIdRequired && !programId)) {
    return null;
  }

  if (!queryOptions.variables?.organizationId && organizationIdRequired) {
    queryOptions.variables = {
      ...queryOptions.variables,
      organizationId: organizationId,
      programId: programId ?? null
    };
  }

  const [execQuery, result] = props.queryHook({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  const onSearch = (searchTerm: string) => {
    execQuery(queryOptionsOnSearch({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE }, searchTerm));
  };

  const searchLocation = props.searchIconLocation ?? SearchInputWithGqlIconLocation.prepend;
  const borderClasses = getBorderClasses(searchLocation);

  const inputBorderClassName = borderClasses[display]['input'];
  const buttonBorderClassName = borderClasses[display]['button'];

  const icon = (
    <Button variant='transparent' className={`border ${buttonBorderClassName} rounded-right px-3`}>
      <Search className='text-gray' />
    </Button>
  );

  const onItemSelect = (selection: AnyType) => {
    const item = Array.isArray(selection) && !props.multiple ? selection[0] : selection;
    props.onItemSelect(item);
  };

  const options = result?.data?.result ?? props.asyncTypeaheadProps?.options ?? [];
  const isValidClass = props.asyncTypeaheadProps?.isValid ? 'is-valid' : '';
  const isInvalidClass = props.asyncTypeaheadProps?.isInvalid ? 'is-invalid' : '';

  return (
    <InputGroup className={`flex-nowrap ${isValidClass} ${isInvalidClass}`.trim()}>
      {/* This is crude and only a temporary work-around. */}
      {searchLocation === SearchInputWithGqlIconLocation.prepend && <InputGroup.Prepend>{icon}</InputGroup.Prepend>}
      <AsyncTypeahead
        {...props.asyncTypeaheadProps}
        highlightOnlyResult={!props.asyncTypeaheadProps?.allowNew}
        id='search-typeahead'
        inputProps={{
          ...props.asyncTypeaheadProps?.inputProps,
          className: inputBorderClassName
        }}
        isLoading={result.loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        labelKey={props.labelRenderer}
        minLength={minLength}
        multiple={props.multiple}
        onSearch={onSearch}
        onChange={onItemSelect}
        options={options}
        placeholder={props.placeholder}
        filterBy={props.filterBy}
        selected={props.selected}
        disabled={props?.isDisabled}
      />
      {searchLocation === SearchInputWithGqlIconLocation.append && <InputGroup.Append>{icon}</InputGroup.Append>}
    </InputGroup>
  );
};

export const SearchInputICDWithGql = ({
  display = SearchInputWithGqlDisplay.outlined,
  minLength = 2,
  organizationIdRequired = true,
  queryOptionsOnSearch = (qo) => qo,
  ...props
}: SearchInputWithGqlProps) => {
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId));
  const queryOptions = props.queryOptions ?? {};

  if ((organizationIdRequired && !organizationId) || (props.programIdRequired && !programId)) {
    return null;
  }

  if (!queryOptions.variables?.organizationId && organizationIdRequired) {
    queryOptions.variables = {
      ...queryOptions.variables,
      organizationId: organizationId,
      programId: programId ?? null
    };
  }

  const [execQuery, result] = props.queryHook({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE });

  const onSearch = (searchTerm: string) => {
    execQuery(queryOptionsOnSearch({ ...queryOptions, fetchPolicy: GRAPHQL_DEFAULT_CACHE }, searchTerm));
  };

  const searchLocation = props.searchIconLocation ?? SearchInputWithGqlIconLocation.prepend;
  const borderClasses = getBorderClasses(searchLocation);

  const inputBorderClassName = borderClasses[display]['input'];
  const buttonBorderClassName = borderClasses[display]['button'];

  const icon = (
    <Button variant='transparent' className={`border ${buttonBorderClassName} rounded-right px-3`}>
      <Search className='text-gray' />
    </Button>
  );

  const onItemSelect = (selection: AnyType) => {
    const item = Array.isArray(selection) && !props.multiple ? selection[0] : selection;
    props.onItemSelect(item);
  };

  const options = result?.data?.result ?? props.asyncTypeaheadProps?.options ?? [];
  let transformedOptions = [];
  if (props?.labelRenderer === 'codeWithDescription' && options.length > 0) {
    transformedOptions = options.map((option: AnyObject) => ({
      codeWithDescription: option.icdcode + ' - ' + option.icddescription,
      ...option
    }));
  }
  const isValidClass = props.asyncTypeaheadProps?.isValid ? 'is-valid' : '';
  const isInvalidClass = props.asyncTypeaheadProps?.isInvalid ? 'is-invalid' : '';
  const filterByDiagnosisCode = (option: AnyObject, props: AnyObject) => {
    if (props?.labelKey === 'codeWithDescription') {
      return (
        option?.icdcode?.toUpperCase().startsWith(props.text.toUpperCase()) ||
        option?.icddescription?.toUpperCase().startsWith(props.text.toUpperCase())
      );
    }
    return true;
  };

  return (
    <InputGroup className={`flex-nowrap ${isValidClass} ${isInvalidClass}`.trim()}>
      {/* This is crude and only a temporary work-around. */}
      {searchLocation === SearchInputWithGqlIconLocation.prepend && <InputGroup.Prepend>{icon}</InputGroup.Prepend>}
      <AsyncTypeahead
        {...props.asyncTypeaheadProps}
        highlightOnlyResult={!props.asyncTypeaheadProps?.allowNew}
        id='search-typeahead'
        inputProps={{
          ...props.asyncTypeaheadProps?.inputProps,
          className: inputBorderClassName
        }}
        isLoading={result.loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        labelKey={props.labelRenderer}
        minLength={minLength}
        multiple={props.multiple}
        onSearch={onSearch}
        onChange={onItemSelect}
        options={transformedOptions.length > 0 ? transformedOptions : options}
        placeholder={props.placeholder}
        filterBy={filterByDiagnosisCode}
      />
      {searchLocation === SearchInputWithGqlIconLocation.append && <InputGroup.Append>{icon}</InputGroup.Append>}
    </InputGroup>
  );
};
