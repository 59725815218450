import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React from 'react';
import { useSelector } from 'react-redux';

import { featureGridColumnsSelector, getFeatureGridColumnProps } from '../../utils';
import { OrganizationAddress, useFieldReimbursementManagerContactQuery } from '../../graphql';
import { AppRootState } from '../../redux/reducers';
import { ConnectedHcpGridWithGQL } from '../HcpGridWithGQL';
import { HcpGridDummayData } from './HcpGridDummyData';
import { AnyType } from '../../interfaces';

const columnSelector = featureGridColumnsSelector('resources.contact-info.field-reimbursement-manager.table');
const PRIMARY = 1;

export const FieldReimbursementManagerTable = () => {
  const columns = useSelector(columnSelector);
  let zipCode = useSelector((state: AppRootState) => state.auth.session?.userInfo?.zip);
  const orgAddresses: OrganizationAddress | AnyType = useSelector(
    (state: AppRootState) => state.auth.session?.orgDetails?.organizationAddresses
  );
  if ((!zipCode || zipCode?.length <= 0) && orgAddresses && orgAddresses?.length > 0) {
    zipCode = orgAddresses?.find((orgAdd: OrganizationAddress) => PRIMARY === orgAdd?.rank)?.zip;
  }

  const queryOptions = {
    variables: {
      zip: zipCode
    }
  };
  if (!columns) return null;

  return (
    <>
      {!zipCode || zipCode?.length <= 0 ? (
        <HcpGridDummayData data={[]} pagination>
          <AgGridColumn field='name' {...getFeatureGridColumnProps(columns?.name)} />
          <AgGridColumn field='territoryName' {...getFeatureGridColumnProps(columns?.territoryName)} />
          <AgGridColumn field='email' {...getFeatureGridColumnProps(columns?.email)} />
          <AgGridColumn field='phoneNumber' {...getFeatureGridColumnProps(columns?.phone)} />
        </HcpGridDummayData>
      ) : (
        <ConnectedHcpGridWithGQL
          queryHook={useFieldReimbursementManagerContactQuery}
          queryOptions={queryOptions}
          pagination>
          <AgGridColumn field='name' {...getFeatureGridColumnProps(columns?.name)} />
          <AgGridColumn field='territoryName' {...getFeatureGridColumnProps(columns?.territoryName)} />
          <AgGridColumn field='email' {...getFeatureGridColumnProps(columns?.email)} />
          <AgGridColumn field='phoneNumber' {...getFeatureGridColumnProps(columns?.phone)} />
        </ConnectedHcpGridWithGQL>
      )}
    </>
  );
};
