import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema } from '..';
import { patientTabFields, prescriberTabFields, prescriptionTabFields, reviewTabFields, StepNames } from '.';
import { ServiceOptionType } from '../../../interfaces';

export const getServiceRequestPatientResourcesFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'Service Request PATIENT RESOURCES Form',
  fields: [
    {
      component: componentTypes.WIZARD,
      name: `service-request-${ServiceOptionType.PatientResources}`,
      fields: [
        patientTabFields(schema, StepNames.PRESCRIBER),
        prescriberTabFields(schema, StepNames.REVIEW),
        prescriptionTabFields(schema, StepNames.OTHER),
        reviewTabFields(schema)
      ].filter(Boolean)
    }
  ]
});

export const serviceRequestPatientResourcesFormSchema: DBFormSchema = {
  // !PATIENT TAB
  patient_tab: { label: 'patient' },
  personal_information_header: { label: 'personal information' },
  patient_first_name: { label: 'first name' },
  patient_middle_name: { label: 'middle name' },
  patient_last_name: { label: 'last name' },
  spacer_patient_1: { hideField: true },
  patient_dob: { label: 'date of birth' },
  patient_gender: { label: 'gender' },
  spacer_patient_2: {},
  separator_patient_1: {},
  patient_preferred_language: { hideField: true },
  patient_other_language: { hideField: true },
  spacer_patient_3: { hideField: true },
  contact_information_header: { hideField: true },
  patient_email: { label: 'email', placeholder: 'optional', readOnly: true, plaintext: true },
  patient_home_number: { hideField: true },
  patient_mobile_number: { hideField: true },
  patient_message_option: { hideField: true },
  spacer_patient_4: { hideField: true },
  patient_address_1: { hideField: true },
  patient_address_2: { hideField: true },
  spacer_patient_5: { hideField: true },
  patient_zip: { hideField: true },
  patient_city: { hideField: true },
  patient_state: { hideField: true },
  spacer_patient_6: { hideField: true },
  // !PRESCRIBER TAB
  prescriber_tab: { label: 'prescriber' },
  prescriber_id: { label: 'select or add a prescriber', required: true },
  spacer_prescriber_1: {},
  prescribers_information_header: { label: "prescriber's information" },
  prescriber_first_name: { label: 'first name', required: true },
  prescriber_middle_name: { label: 'middle name' },
  prescriber_last_name: { label: 'last name', required: true },
  prescriber_npi: { label: 'prescriber npi', required: true },
  prescriber_tax_id: { label: 'tax id # (tin)' },
  prescriber_state_license_number: { label: 'state license #' },
  prescriber_licensing_state: { label: 'licensing state', required: true },
  separator_prescriber_1: { hideField: true },
  prescribers_practice_information_header: { hideField: true },
  organization_name: { hideField: true },
  organization_npi: { hideField: true },
  organization_tax_id: { hideField: true },
  spacer_prescriber_2: { hideField: true },
  prescriber_location_id: { hideField: true },
  spacer_prescriber_3: { hideField: true },
  location_header: { hideField: true },
  spacer_prescriber_4: { hideField: true },
  prescriber_location_address_full: { hideField: true },
  prescriber_location_address_1: { hideField: true },
  prescriber_location_address_2: { hideField: true },
  prescriber_location_zip: { hideField: true },
  prescriber_location_city: { hideField: true },
  prescriber_location_state: { hideField: true },
  prescriber_location_phone_1: { hideField: true },
  prescriber_location_fax_number: { hideField: true },
  separator_prescriber_2: { hideField: true },
  office_contact_header: { hideField: true },
  spacer_prescriber_5: { hideField: true },
  organization_office_contact_first_name: { hideField: true },
  organization_office_contact_last_name: { hideField: true },
  organization_office_contact_phone: { hideField: true },
  dashes: { label: '---', required: true },
  location_dashes: { label: '---', required: true },
  spacer_prescriber_6: { hideField: true },
  // !REVIEW
  review_tab: { label: 'review & submit' }
};
