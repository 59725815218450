import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenancyFeatureSelector } from '../redux';
import { AnyType, AutomatedServiceOptionType, ServiceOptionType } from '../interfaces';
import { rawSafeHtmlProps } from '../utils';
import { Feedback } from './Feedback';
import { ResourceContent } from './multi-tenant';
import { FeatureGuard } from './multi-tenant/FeatureGuard';
import { NotificationToastMessage } from './NotificationToastMessage';
import { DaisyChainingSubmitServiceOptionOption } from './DaisyChainingSubmitServiceOption';

const REQUEST_SUBMISSION_KEY = 'service-request.submission-message';
const VIEW_STATUS_KEY = 'view-status';
const CONTACT_INFO_KEY = 'contact-info';
const DEFAULT_MESSAGE_KEY = 'default-message';

interface RequestSubmissionResourcesContentProps {
  serviceKey: string;
  replacers?: { findStr: string; replaceWith: string }[];
}

const RequestSubmissionResourcesContent = ({ serviceKey, replacers = [] }: RequestSubmissionResourcesContentProps) => {
  const renderer = (body: AnyType) => {
    if (body && body.content && replacers && replacers.length > 0) {
      let tempValue = body.content;
      replacers.forEach(({ findStr, replaceWith }) => {
        tempValue = tempValue.replaceAll(findStr, replaceWith);
      });
      return <span {...rawSafeHtmlProps(tempValue?.toString() ?? '')} />;
    }
    return <span {...rawSafeHtmlProps(body?.content?.toString() ?? '')} />;
  };
  return <ResourceContent contentKey={REQUEST_SUBMISSION_KEY} sourceDataKey={`${serviceKey}`} render={renderer} />;
};

const AllMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.All} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};

const FieldReimbursementManagerMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.FieldReimbursementManager} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};

const CopayClaimMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.CopayClaim} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};

const CopayEnrollmentMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.CopayEnrollment} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};

const DefaultMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={DEFAULT_MESSAGE_KEY} />
    </Col>
  );
};

const PharmacyElectronicBenefitVerificationMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.PharmacyElectronicBenefitVerification} />
      <ViewStatus />
    </Col>
  );
};

const PharmacyElectronicPriorAuthorizationMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.PharmacyElectronicPriorAuthorization} />
      <ViewStatus />
    </Col>
  );
};

const MedicalElectronicBenefitVerificationMessage = () => {
  const {
    state: { isMedEbvAutomationEnabled }
  }: AnyType = useLocation();

  const serviceKey = isMedEbvAutomationEnabled
    ? AutomatedServiceOptionType.AUTO_MED_EBV
    : ServiceOptionType.MedicalElectronicBenefitVerification;

  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={serviceKey} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};
const MedicalElectronicPriorAuthorizationMessage = () => {
  const {
    state: { isMedEbvAutomationEnabled, isAutoMedEPAEnabled, loadingService }
  }: AnyType = useLocation();

  const serviceKey =
    isMedEbvAutomationEnabled &&
    isAutoMedEPAEnabled &&
    loadingService === ServiceOptionType.MedicalElectronicPriorAuthorization
      ? AutomatedServiceOptionType.AUTO_MED_EPA
      : ServiceOptionType.MedicalElectronicPriorAuthorization;

  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={serviceKey} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};
const InsuranceReviewMessage = () => {
  return (
    <Col>
      <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.InsuranceReview} />
      <ViewStatus />
      <ContactInfo />
    </Col>
  );
};

const PatientResourcesMessage = () => {
  const {
    state: { patientName, email }
  }: AnyType = useLocation();

  const replacers = [
    { findStr: '{patientName}', replaceWith: patientName },
    { findStr: '{email}', replaceWith: email }
  ];

  return (
    <div>
      <Col>
        <RequestSubmissionResourcesContent serviceKey={ServiceOptionType.PatientResources} replacers={replacers} />
        <ContactInfo />
      </Col>
    </div>
  );
};

const ContactInfo = () => {
  return (
    <>
      <RequestSubmissionResourcesContent serviceKey={CONTACT_INFO_KEY} />
    </>
  );
};

const ViewStatus = () => {
  const autoServiceOption: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceOption'));
  return <>{!autoServiceOption?.enabled && <RequestSubmissionResourcesContent serviceKey={VIEW_STATUS_KEY} />}</>;
};

const requestSubmitMessageMappings: { [key: string]: FC } = Object.freeze({
  [ServiceOptionType.All]: AllMessage,
  [ServiceOptionType.FieldReimbursementManager]: FieldReimbursementManagerMessage,
  [ServiceOptionType.CopayClaim]: CopayClaimMessage,
  [ServiceOptionType.CopayEnrollment]: CopayEnrollmentMessage,
  [ServiceOptionType.PharmacyElectronicBenefitVerification]: PharmacyElectronicBenefitVerificationMessage,
  [ServiceOptionType.PharmacyElectronicPriorAuthorization]: PharmacyElectronicPriorAuthorizationMessage,
  [ServiceOptionType.MedicalElectronicBenefitVerification]: MedicalElectronicBenefitVerificationMessage,
  [ServiceOptionType.MedicalElectronicPriorAuthorization]: MedicalElectronicPriorAuthorizationMessage,
  [ServiceOptionType.InsuranceReview]: InsuranceReviewMessage,
  [ServiceOptionType.PatientResources]: PatientResourcesMessage,
  //   TODO: Add Patient Education from the Mocks
  default: DefaultMessage
});

const NotificationMessage = ({ requestType, enrollmentData }: AnyType) => {
  const drugNDC = enrollmentData?.drug?.ndc;
  const drugId = enrollmentData?.drugId;
  const patientGender = enrollmentData?.patient?.person?.gender || enrollmentData?.patient?.gender;
  const messageKey = 'notificationMessage1';
  const genderSpecificMessageKey = 'genderSpecificNotificationMessage1';
  const displayNotificationFlagKey = 'displayNotificationMessage1';

  return (
    <>
      <Col>
        <NotificationToastMessage
          requestType={requestType}
          drugNDC={drugNDC}
          drugID={drugId}
          patientGender={patientGender}
          messageKey={messageKey}
          genderSpecificMessageKey={genderSpecificMessageKey}
          displayNotificationFlagKey={displayNotificationFlagKey}
        />
      </Col>
    </>
  );
};

export const RequestSubmitMessage = ({ enrollmentData }: AnyType) => {
  const { hash } = useLocation();
  const requestType = hash.replace('#', '');
  const Message = requestSubmitMessageMappings[requestType] || requestSubmitMessageMappings.default;
  const autoServiceOption: AnyType = useSelector<AnyType>(tenancyFeatureSelector('autoServiceOption'));
  if (autoServiceOption && enrollmentData !== undefined) {
    const dataObj = {
      enrollmentData
    };
    sessionStorage.setItem('enrollmentObj', JSON.stringify(dataObj));
  }
  const serviceType = autoServiceOption && requestType === '' ? 'all' : requestType;

  return (
    <>
      <FeatureGuard contentKey='feedback.button'>
        <>
          <Feedback />
        </>
      </FeatureGuard>
      <Message />
      <NotificationMessage requestType={requestType} enrollmentData={enrollmentData} />
      {autoServiceOption?.enabled && (
        <DaisyChainingSubmitServiceOptionOption
          currentService={serviceType.toString()}
          enrollmentData={enrollmentData}
        />
      )}
    </>
  );
};
