import React, { useLayoutEffect, useReducer } from 'react';

import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';

import { AnyType } from '../../interfaces';
import { DYNAMIC_WIZARD_TYPES, findCurrentStep, selectNext, WizardContext, wizardReducer, WIZARD_ACTIONS } from '.';

export const Wizard = React.memo(({ crossroads, fields, isDynamic, Wizard, initialState, ...props }: AnyObject) => {
  const formOptions = useFormApi();

  const [state, dispatch] = useReducer(wizardReducer, {
    activeStep: fields[0]?.name,
    prevSteps: [],
    activeStepIndex: 0,
    maxStepIndex: 0,
    ...initialState,
    isDynamic: isDynamic || fields.some(({ nextStep }: AnyObject) => DYNAMIC_WIZARD_TYPES.includes(typeof nextStep)),
    loading: true
  });

  useLayoutEffect(() => {
    dispatch({ type: WIZARD_ACTIONS.FINISH_LOADING, payload: { formOptions, fields } });
  }, [fields]);

  if (state.loading) {
    return null;
  }

  const activePage = () => {
    return findCurrentStep?.(state.activeStep, fields)?.fields.map((some: AnyType) => formOptions.renderForm([some]));
  };

  const handleNext = (nextStep: string) =>
    dispatch({ type: WIZARD_ACTIONS.HANDLE_NEXT, payload: { nextStep, formOptions, fields } });

  const jumpToStep = (index: number, valid?: boolean) =>
    dispatch({ type: WIZARD_ACTIONS.HANDLE_JUMP_TO_STEP, payload: { index, valid, fields, crossroads, formOptions } });

  const handlePrev = () => jumpToStep(state.activeStepIndex - 1);

  const setPrevSteps = () => dispatch({ type: WIZARD_ACTIONS.HANDLE_PREV_STEPS, payload: { formOptions, fields } });

  return (
    <WizardContext.Provider
      value={{
        activePage,
        activeStepIndex: state.activeStepIndex,
        crossroads,
        currentStep: findCurrentStep(state.activeStep, fields),
        fields,
        formOptions,
        handleNext,
        handlePrev,
        isDynamic: state.isDynamic,
        jumpToStep,
        maxStepIndex: state.maxStepIndex,
        navSchema: state.navSchema,
        prevSteps: state.prevSteps,
        selectNext,
        setPrevSteps
      }}>
      <Wizard key={props.name} {...props} />
    </WizardContext.Provider>
  );
});
