import { useSelector } from 'react-redux';

import { EnrollmentRequest } from '../../api';
import { PortalEnrollmentRequest } from '../../api/interface-portal';
import { AnyType } from '../../interfaces';
import { AppRootState } from '../../redux';
import { FINISHED_EVENT, standardPortalConfig } from '../constants';
import { drugDescriptionType } from '../data';
import { useLocation, useParams } from 'react-router-dom';

export const getMedicalEbvPayload = (
  values: PortalEnrollmentRequest,
  userName: string,
  organizationId: string,
  previousResults?: AnyType
) => {
  const { diagnosis, patient, physicians, insurance, cpt, pos, nextScheduledDate, drugId } = values ?? {};
  const findPolicy = (policyType: 'primary' | 'secondary' | 'tertiary' | 'other') =>
    insurance?.patientInsurances?.find((insurance) => insurance?.insuranceType?.toLowerCase() === policyType) ?? null;
  const primaryPolicy = findPolicy('primary');
  const secondaryPolicy = findPolicy('secondary');
  const tertiaryPolicy = findPolicy('tertiary');
  const otherPolicy = findPolicy('other');
  const portal_ApplicationName = useSelector((state: AppRootState) => state.app?.config?.applicationName);
  let { drug } = useParams<{ drug: string }>();
  if (!drug) {
    drug = drugId?.toString() || '';
  }
  const drugList = useSelector((state: AppRootState) => state.app?.entities?.drugList) ?? null;
  const drugDetails =
    drug && Number(drug) !== 0 ? drugList?.filter((d) => d?.DrugId === Number(drug) && d.DrugId) : drugList;

  return {
    ClientApplication: standardPortalConfig.isStandardPortalUrl
      ? standardPortalConfig.applicationName
      : portal_ApplicationName,
    UserName: String(userName),
    RequestData: JSON.stringify({
      AdditionalDetails: {
        IsHubRequest: false,
        HUB: {
          ServiceRequestID: 0,
          BenefitInvestigationID: 0,
          ProgramId: 0
        }
      },
      ExternalAccountId: organizationId ?? null,
      ExternalPatientId: String(patient?.patientId),
      Patient: {
        Name: {
          FirstName: patient?.firstName,
          LastName: patient?.lastName
        },
        DOB: patient?.dob,
        Gender: patient?.gender,
        SSN: null,
        Zip: patient?.zip
      },
      Provider: {
        Name: {
          FirstName: physicians?.firstName,
          LastName: physicians?.lastName
        },
        Title: null,
        NPI: physicians?.npi,
        State: physicians?.organization?.organizationAddress?.state,
        TaxId: physicians?.taxId
      },
      Practice: {
        Name: physicians?.organization?.name,
        Address: {
          Address1: physicians?.organization?.organizationAddress?.address1,
          Address2: physicians?.organization?.organizationAddress?.address2,
          City: physicians?.organization?.organizationAddress?.city,
          Zip: physicians?.organization?.organizationAddress?.zip,
          State: physicians?.organization?.organizationAddress?.state,
          StateSpecified: !!physicians?.organization?.organizationAddress?.state
        },
        NPI: physicians?.npi,
        FaxNumber: physicians?.organization?.organizationAddress?.faxNumber,
        Contact: {
          FirstName: physicians?.officeContact?.firstName,
          LastName: physicians?.officeContact?.lastName
        }
      },
      ClientPatientId: null,
      MedicareId: insurance?.medicareBeneficiaryId,
      Product: drugDetails?.[0]?.DrugLabelName,
      Cpt: {
        Code1: cpt?.code1,
        Code2: cpt?.code2,
        Code3: cpt?.code3,
        Code4: cpt?.code4,
        Code5: cpt?.code5
      },
      Diagnosis: {
        Primary: diagnosis?.primarydiagnosisCode?.split(',')[0],
        Secondary: diagnosis?.diagnosisCodesSecondary?.split(',')[0],
        Tertiary: diagnosis?.diagnosisCodesTertiary?.split(',')[0]
      },
      Pos: pos,
      NextScheduledDate: nextScheduledDate,
      NoFaxingFlagSpecified: false,
      Mfg: drugDetails?.[0]?.Manufacturer,
      Policy1Params: {
        MemberId: primaryPolicy?.medicalCardHolderId,
        PolicyMedePayerId: primaryPolicy?.payerIds?.[1] ?? primaryPolicy?.medePayerId,
        PayerId:
          portal_ApplicationName === 'RegeneronProviderPortal'
            ? primaryPolicy?.payerIds?.[1]
              ? `PXT${primaryPolicy?.payerIds?.[1]}`
              : `PXT${primaryPolicy?.medePayerId}` ?? null
            : primaryPolicy?.payerIds?.[0],
        PayerName: primaryPolicy?.medicalPlanName,
        PatientSubscriber: primaryPolicy?.isPatientPrimaryCardholder,
        SubscriberInfo: {
          Name: {
            FirstName: primaryPolicy?.subscriberFirstName,
            LastName: primaryPolicy?.subscriberLastName
          },
          DOB: primaryPolicy?.subscriberDateBirth,
          Gender: primaryPolicy?.subscriberGender,
          MemberId: primaryPolicy?.medicalCardHolderId
        },
        PriorAuth: {
          OnFileSpecified: false,
          Number: null,
          EffDateSpecified: false,
          EndDateSpecified: false,
          Misc: null
        }
      },
      Policy2Params: {
        PolicyInfo: {
          MemberId: secondaryPolicy?.medicalCardHolderId,
          PolicyMedePayerId: secondaryPolicy?.payerIds?.[1] ?? primaryPolicy?.medePayerId,
          PayerId:
            portal_ApplicationName === 'RegeneronProviderPortal'
              ? secondaryPolicy?.payerIds?.[1]
                ? `PXT${secondaryPolicy?.payerIds?.[1]}`
                : `PXT${primaryPolicy?.medePayerId}` ?? null
              : secondaryPolicy?.payerIds?.[0],
          PayerName: secondaryPolicy?.medicalPlanName,
          PatientSubscriber: secondaryPolicy?.isPatientPrimaryCardholder,
          SubscriberInfo: {
            Name: {
              FirstName: secondaryPolicy?.subscriberFirstName,
              LastName: secondaryPolicy?.subscriberLastName
            },
            DOB: secondaryPolicy?.subscriberDateBirth,
            Gender: secondaryPolicy?.subscriberGender,
            MemberId: secondaryPolicy?.medicalCardHolderId
          },
          PriorAuth: {
            OnFileSpecified: false,
            Number: null,
            TypeOrChannelSpecified: false,
            EffDateSpecified: false,
            EndDateSpecified: false,
            Misc: null
          }
        },
        McSuppPlanLetter: null
      },
      Policy3Params: {
        MemberId: tertiaryPolicy?.medicalCardHolderId,
        PolicyMedePayerId: tertiaryPolicy?.payerIds?.[1] ?? primaryPolicy?.medePayerId,
        PayerId:
          portal_ApplicationName === 'RegeneronProviderPortal'
            ? tertiaryPolicy?.payerId?.[1]
              ? `PXT${tertiaryPolicy?.payerId?.[1]}`
              : `PXT${primaryPolicy?.medePayerId}` ?? null
            : tertiaryPolicy?.payerIds?.[0],
        PayerName: tertiaryPolicy?.medicalPlanName,
        PatientSubscriber: tertiaryPolicy?.isPatientPrimaryCardholder,
        SubscriberInfo: {
          Name: {
            FirstName: tertiaryPolicy?.subscriberFirstName,
            LastName: tertiaryPolicy?.subscriberLastName
          },
          DOB: tertiaryPolicy?.subscriberDateBirth,
          Gender: tertiaryPolicy?.subscriberGender,
          MemberId: tertiaryPolicy?.medicalCardHolderId
        },
        PriorAuth: {
          OnFileSpecified: false,
          Number: null,
          TypeOrChannelSpecified: false,
          EffDateSpecified: false,
          EndDateSpecified: false,
          Misc: null
        }
      },
      Policy4Params: {
        MemberId: otherPolicy?.medicalCardHolderId,
        PayerId: otherPolicy?.payerId,
        PayerName: otherPolicy?.medicalPlanName,
        PatientSubscriberSpecified: false,
        SubscriberInfo: {
          Name: {
            FirstName: null,
            LastName: null
          },
          DOB: null,
          Gender: null,
          MemberId: null
        },
        PriorAuth: {
          OnFileSpecified: false,
          Number: null,
          TypeOrChannelSpecified: false,
          EffDateSpecified: false,
          EndDateSpecified: false,
          Misc: null
        }
      },
      InternalReference: null,
      CopayCard: {
        ProgramSpecified: false
      },
      AltCoverage: {
        ReferralSpecified: false
      },
      DateOfServiceSpecified: false,
      SiteOfficeFax: null,
      CustomRedirectUrl1: FINISHED_EVENT,
      CallingTransactionType: previousResults?.callingTransactionType,
      CallingTransactionID: previousResults?.enrollment?.enrollmentId
        ? String(previousResults?.enrollment?.enrollmentId)
        : null
    })
  };
};
