import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldPlainTextSection, headerSection, spacerSection } from '..';

export const getVendorDetailsSchema = (schema: DBFormSchema): Schema => ({
  title: 'vendor details',
  fields: [
    headerSection(schema?.vendor_details_header?.label, schema?.vendor_details_header),
    fieldPlainTextSection('vendor_name', schema?.vendor_name, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('vendor_address', schema?.vendor_address, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('tax_id', schema?.tax_id, {
      sizes: { md: 4 }
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldPlainTextSection('total_amount_approved', schema?.total_amount_approved, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('total_amount_requested', schema?.total_amount_requested, {
      sizes: { md: 4 }
    }),
    fieldPlainTextSection('total_amount_due', schema?.total_amount_due, {
      sizes: { md: 4 }
    }),
    spacerSection(3, { sizes: { sm: 12 } })
  ]
});
