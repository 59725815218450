import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../..';
import { DBFormSchema, fieldTextSection, spacerSection } from '..';
import { fieldPrescriberSearchSection, fieldSelectSection, fieldSelectionDateTime } from '../field-helpers';

// make it configurable later
const { rxAdminDoseFields } = FIELD_NAMES;
const doseRange = Array.from({ length: 1000 }, (_, index) => ({
  label: (index + 1).toString(),
  value: index + 1
}));
export const getAddAdminDoseSchema = (schema: DBFormSchema): Schema => ({
  title: 'Add Dose',
  fields: [
    spacerSection(1, { sizes: { sm: 2 } }),
    fieldTextSection(rxAdminDoseFields.patientName, schema?.[rxAdminDoseFields.patientName], {
      sizes: { md: 9 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 2 } }),
    fieldTextSection(rxAdminDoseFields.product, schema?.[rxAdminDoseFields.product], {
      sizes: { md: 9 },
      validate: [
        {
          type: 'name'
        }
      ]
    }),
    spacerSection(3, { sizes: { sm: 2 } }),
    fieldSelectSection(rxAdminDoseFields.dose, schema?.[rxAdminDoseFields.dose], {
      sizes: { md: 9 },
      options: [...doseRange],
      validate: [
        {
          type: 'number'
        }
      ]
    }),
    spacerSection(4, { sizes: { sm: 2 } }),
    fieldSelectionDateTime(rxAdminDoseFields.adminDate, schema?.[rxAdminDoseFields.adminDate], {
      sizes: { md: 5 }
    }),
    fieldSelectSection(rxAdminDoseFields.status, schema?.[rxAdminDoseFields.status], {
      sizes: { md: 5 },
      options: [
        {
          label: 'Canceled',
          value: 'Canceled'
        },
        {
          label: 'Pending',
          value: 'Pending'
        },
        {
          label: 'Scheduled',
          value: 'Scheduled'
        },
        {
          label: 'Completed',
          value: 'Completed'
        }
      ]
    }),
    spacerSection(5, { sizes: { sm: 2 } }),
    fieldPrescriberSearchSection(rxAdminDoseFields.prescriberName, schema?.[rxAdminDoseFields.prescriberName], {
      sizes: { md: 9 }
    }),
    spacerSection(6, { sizes: { sm: 2 } }),
    fieldTextSection(rxAdminDoseFields.notes, schema?.[rxAdminDoseFields.notes], {
      sizes: { md: 9 }
    })
  ]
});

// test  schema
export const addRxAdminDose: DBFormSchema = {
  patient_name: {
    label: 'Patient Name',
    required: true,
    readOnly: true
  },
  product_name: {
    label: 'Drug(Product)',
    readOnly: true,
    required: false
  },
  rx_dose: {
    label: 'Dose#'
  },
  rx_administration_date: {
    label: 'Admin Date',
    readOnly: false
  },
  status: {
    label: 'Status',
    required: true
  },
  prescriber_name: {
    label: 'Provider'
  },
  notes: {
    label: 'Notes',
    required: false,
    max: 8
  }
};
