/* eslint @typescript-eslint/no-empty-function: 0 */

import React from 'react';
import { ConsentFlow, ConsentStatusEnum, ConsentType, EmailSmsConsentMode, PatientContactInfo } from './types';

type ConsentContextType = {
  flow: ConsentFlow;
  errorMessage: string;
  verificationCode: string;
  showCheckStatus: boolean;
  consentType: string;
  patientContactInfo: Record<string, PatientContactInfo>;
  consentMode: string;
  consentStatus: string;
  channelValue: string;
  updateChannelValue: (value: string) => void;
  sendInvite: (channel?: ConsentType) => void;
  updateEmailSmsConsentMode: (consentMode: EmailSmsConsentMode) => void;
  updateConsentStatus: (consentStatus: ConsentStatusEnum) => void;
  checkConsentStatus: () => void;
};
const ConsentContext = React.createContext<ConsentContextType>({
  flow: ConsentFlow.REQUEST,
  errorMessage: '',
  verificationCode: '',
  showCheckStatus: false,
  consentType: '',
  channelValue: '',
  updateChannelValue: (_value) => {},
  patientContactInfo: {
    Email: {},
    Sms: {}
  },
  sendInvite: () => {},
  consentMode: '',
  consentStatus: '',
  updateEmailSmsConsentMode: (_consentMode) => {},
  updateConsentStatus: (_consentStatus) => {},
  checkConsentStatus: () => {}
});

const { Provider: ConsentContextProvider } = ConsentContext;

export { ConsentContext, ConsentContextProvider };
