import React from 'react';

import { AnyType } from '../../interfaces';
import { HcpGrid, HcpGridProps } from '../HcpGrid';
import { HcpRequestStateUI } from '../HcpRequestStateUI';

interface HcpGridDummayDataProps extends HcpGridProps {
  data?: AnyType[];
  dataProcessor?: (data: unknown) => AnyType[] | undefined;
  jsonData?: (dumyData: any) => AnyType[] | undefined;
}

export const HcpGridDummayData = (props: HcpGridDummayDataProps) => {
  const data = props.dataProcessor ? props.dataProcessor?.(props?.data) : props?.data;
  return (
    <HcpRequestStateUI
      errorUIEnabled
      // isError={result.error}
      // reload={() => result.refetch(queryOptions.variables)}
      reloadingEnabled>
      <HcpGrid rowData={data} {...props} />
    </HcpRequestStateUI>
  );
};
