import { FormApi, FORM_ERROR } from 'final-form';
import React, { useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { API_URL, ComposeMessageRequest, DocumentModel, Message, MessageThread, useComposeMessage } from '../api';
import { FilePreview, GeneralObject } from '../interfaces';
import { AppRootState } from '../redux';
import { clientApplicationNameSelector, getFilePayload, validateFormValues } from '../utils';
import { FileUploadField, TextAreaField } from './FormFields';
import { CustomThemes } from '../utils/custom-themes';

const messageSchema = object({
  message: string().max(5000).required().label('Reply Message')
});

export interface MessageReplyProps {
  thread: MessageThread | null;
  threadMessage: Message | null;
  onSent?: (message: ComposeMessageRequest) => void | null;
  onCancel?: () => void | null;
}

export const MessageReply = ({ thread, ...props }: MessageReplyProps) => {
  CustomThemes();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const applicationName = useSelector(clientApplicationNameSelector);

  const { mutate: sendMessage } = useComposeMessage({
    base: API_URL
  });

  const onSend = useCallback(
    async (values: GeneralObject, form: FormApi) => {
      if (!thread) return;

      const files = (values.files as Array<FilePreview>)?.map<DocumentModel | undefined>((f) =>
        getFilePayload(f)
      ) as DocumentModel[];

      const payload: ComposeMessageRequest = {
        applicationName: applicationName,
        files,
        messageText: String(values.message).trim(),
        patientId: Number(thread.patientId),
        providerId: Number(thread.providerId),
        serviceRequestId: thread.serviceRequestID,
        subject: thread.subject,
        threadId: Number(thread.threadID),
        userName: currentUser?.Login
      };

      const result = await sendMessage(payload);

      if (result.errors && result.errors.length > 0) {
        return { [FORM_ERROR]: result.errors.map((e) => e.description).join(' ') };
      }

      props.onSent && props.onSent(payload);
      setTimeout(form.reset, 0);
    },
    [props.onSent, thread]
  );

  const onCancel = useCallback(() => props.onCancel && props.onCancel(), [props.onCancel]);

  if (!thread) return null;

  return (
    <div className='mt-3' data-testid='messageReplyContainer'>
      <FinalForm
        onSubmit={onSend}
        validate={validateFormValues(messageSchema)}
        initialValues={messageSchema.default()}
        render={({ handleSubmit, submitting, submitError, hasValidationErrors }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Row className='mb-3'>
              <Col>
                <FileUploadField name='files' dropzoneOptions={{ maxFiles: 5 }} />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <TextAreaField
                  autoFocus
                  label='Send a Reply'
                  name='message'
                  placeholder='Type Your Message here'
                  maxLength={5000}
                  rows={5}
                  className='w-100'
                />
              </Col>
            </Row>

            <Row>
              <Col className='text-right'>
                {submitError && <span className='text-danger'>{submitError}</span>}
                <Button onClick={onCancel} variant='transparent' className='text-danger mr-3'>
                  CANCEL
                </Button>
                <Button type='submit' variant='secondary' disabled={submitting || hasValidationErrors}>
                  SEND
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};
