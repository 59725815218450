import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePatientInfoQuery } from '../graphql';
import { CONSENT_MODAL_LABEL, defaultDateFormatWithAge } from '../utils';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import './CaseDetailSidebarPatientInfo.scss';
import { HcpModal } from './HcpModal';
import ConsentModal from './consent-modal';
import { PatientProfileContext } from './patient-profile-context';
import { ConsentFlow } from './consent-modal/types';

interface CaseDetailSidebarPatientInfoParams {
  id: string;
}

export const CaseDetailSidebarPatientInfo = () => {
  const { selectedRequestInfo } = useContext(PatientProfileContext);
  const { id } = useParams<CaseDetailSidebarPatientInfoParams>();
  const patientResult = usePatientInfoQuery({ variables: { patientId: id } });
  const [showConsentModal, setShowConsentModal] = useState(false);
  const patient = patientResult.data?.result;
  const patientPhoneNumber = patient?.phoneNumbers?.slice(0, 1).shift();
  const programId = useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '';
  const hasConsentFeature = useSelector(
    (state: AppRootState) =>
      (state.app.entities.features?.['show-patient-consent-invite-modal']?.data as any)?.enabled ?? false
  );

  const showPatientConsent = useMemo(() => hasConsentFeature && selectedRequestInfo, [
    selectedRequestInfo,
    hasConsentFeature
  ]);

  return (
    <div className='cmx__case-detail-sidebar-patient-info d-flex flex-column justify-content-between'>
      <div className='d-flex flex-column'>
        <div className='d-flex flex-column my-4 h5'>
          <div>{patient?.lastName ? patient.lastName + ',' : '-'}</div>
          <div>{patient?.firstName ?? '-'}</div>
        </div>
        <div className='d-flex my-2'>
          <div className='d-flex flex-column'>
            <div className='cmx__case-detail-sidebar-patient-info__grey-20-override'>DOB (AGE)</div>
            <div>{defaultDateFormatWithAge(patient?.dob) ?? '-'}</div>
          </div>
          <div className='flex-grow-1 d-flex flex-column align-items-center'>
            <div>
              <div className='cmx__case-detail-sidebar-patient-info__grey-20-override'>GENDER</div>
              <div>{patient?.gender === 'O' ? patient?.genderOther : patient?.gender ?? '-'}</div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column my-2'>
          <div className='cmx__case-detail-sidebar-patient-info__grey-20-override'>PHONE</div>
          <div>{patientPhoneNumber?.phoneNumber ?? '-'}</div>
        </div>
        <div className='d-flex flex-column my-2'>
          <div className='cmx__case-detail-sidebar-patient-info__grey-20-override'>CITY, STATE, ZIP</div>
          <div>
            <span>{patient?.address?.city ? patient?.address?.city + ', ' : ''}</span>
            <span>{patient?.address?.state ? patient?.address?.state + ', ' : ''}</span>
            <span>{patient?.address?.zip ?? ''}</span>
          </div>
        </div>
        {hasConsentFeature && (
          <div className='d-flex flex-column my-2'>
            <div className='cmx__case-detail-sidebar-patient-info__grey-20-override'>PATIENT CONSENT</div>
            <div className='d-flex flex-row justify-content-between mr-3'>
              <a
                className='cmx__case-detail-sidebar-patient-info__a-request-consent'
                onClick={() => setShowConsentModal(true)}>
                {CONSENT_MODAL_LABEL.REQUEST_CONSENT}
              </a>
            </div>
          </div>
        )}
      </div>
      <hr className='cmx__case-detail-sidebar-patient-info__hr-override my-4' />
      <HcpModal
        modalProps={{
          show: showConsentModal,
          onHide: () => {
            setShowConsentModal(false);
          },
          dialogClassName: 'consent-modal-dialog'
        }}
        body={
          <ConsentModal
            programId={programId}
            flow={ConsentFlow.REQUEST}
            patientInfo={patient}
            onClose={() => {
              setShowConsentModal(false);
            }}
          />
        }
      />
    </div>
  );
};
