import React, { useCallback } from 'react';

import { dateFormatter } from '../utils';
import { HcpFormRenderer } from '.';
import { getCopayCardDetailsSchema } from '../utils/dynamic-forms/fields/copay-card-details-fields';

interface CopayCardDetailsFormProps {
  copayCardDetails?: any | null;
}

export const CopayCardDetailsForm = ({ copayCardDetails }: CopayCardDetailsFormProps) => {
  const payload = useCallback(() => buildVendorDetails(copayCardDetails), [copayCardDetails]);
  return (
    <HcpFormRenderer
      contentKey='form.copay-card-details'
      initialValues={payload()}
      noControls
      noErrorMessage
      schemaMethod={getCopayCardDetailsSchema}
    />
  );
};

const buildVendorDetails = (copayCardDetails?: any | null) => {
  return {
    copay_card_id: copayCardDetails?.copayCardID,
    copay_claim_payee: `${copayCardDetails?.payeeType ? `${copayCardDetails?.payeeType} - ` : ''}${
      copayCardDetails?.payeeName || ''
    }`,
    balance_amount: copayCardDetails?.copayCardID,
    copay_card_effective_date: dateFormatter(copayCardDetails?.copayCardEffectiveStartDate),
    copay_card_end_date: dateFormatter(copayCardDetails?.copayCardEffectiveEndDate),
    date_eob_received: dateFormatter(copayCardDetails?.eOBReceivedDate)
  };
};
