import _ from 'lodash';
import React from 'react';
import { NavDropdown, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { getAllTenancyFeatureSelector, AppRootState } from '../redux';

export type DrugSpecificDropdownProps = {
  parentEle: AnyType;
  navItemProps?: AnyType;
};

export const CustomDurgSpecificNavDropdownLinks = ({ parentEle, navItemProps }: DrugSpecificDropdownProps) => {
  const { state }: AnyType = useLocation();
  const kebabLabel = _.kebabCase(`${parentEle.label}-dropdown`);
  let links: AnyType[] = [];

  const portalName: AnyType = useSelector<AnyType>((state: AppRootState) => state.app?.config?.applicationName);

  getAllTenancyFeatureSelector(parentEle?.featureKey, state?.drug?.DrugId)?.forEach((tempData: AnyType) => {
    if (tempData) {
      links = [...links, tempData];
    }
  });

  const isMultiDrugEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isMultiDrugEnabled'] as AnyType)?.data?.enabled
  );

  const dynamicClassName = portalName !== 'JanssencarepathProviderPortal' ? 'auto-width' : '';

  return (
    <>
      {parentEle && 'drug-specific-dropdown' === parentEle?.type && parentEle?.featureKey && parentEle?.enabled && (
        <>
          {isMultiDrugEnabled ? (
            <NavDropdown
              className={`mx-2 ${dynamicClassName}`}
              id={kebabLabel}
              title={parentEle?.label}
              data-testid={`cmx__${_.kebabCase(kebabLabel)}`}>
              {links?.length > 0 &&
                links?.map((link: AnyType) => (
                  <NavDropdown.Item
                    {...navItemProps}
                    className={'never-active'}
                    key={_.kebabCase(link?.drugLabelName)}
                    data-testid={`cmx__${kebabLabel}`}
                    eventKey={kebabLabel}
                    onClick={() => window.open(link?.url, '_blank')}>
                    <NavDropdown.ItemText style={{ textTransform: 'none' }}>{link?.drugLabelName}</NavDropdown.ItemText>
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
          ) : (
            <Nav.Link className='mx-2' href={parentEle?.url} data-testid={`cmx__${kebabLabel}`}>
              {parentEle?.label}
            </Nav.Link>
          )}
        </>
      )}
    </>
  );
};
