import React, { ChangeEvent, useCallback } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

interface MessagesSearchProps {
  onSearchChange: (search: string) => void;
}

export const MessagesSearch = (props: MessagesSearchProps) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      if (value.length < 3 && value.length !== 0) return;

      props.onSearchChange(value);
    },
    [props.onSearchChange]
  );
  return (
    <InputGroup>
      <FormControl
        aria-label='Messages'
        aria-describedby='basic-addon1'
        className='border-right-0'
        maxLength={30}
        onChange={onChange}
      />
      <InputGroup.Append>
        <InputGroup.Text className='bg-transparent border-left-0'>
          <Search className='text-gray' />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};
