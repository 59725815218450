import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { ConnectedMainToolbar, HcpRequestStateUI, HcpSessionManager } from '../components';
import { useRxModuleRequest } from '../hooks';
import { AnyType, AutomatedServiceOptionType, ServiceOptionType } from '../interfaces';
import {
  AppRootState,
  navigateToRequestServiceIntermediator,
  navigateToRequestsSubmitted,
  tenancyFeatureSelector
} from '../redux';
import { FINISHED_EVENT } from '../utils';

interface RxModuleSelectedParams {
  service: ServiceOptionType;
}

export const RxModule = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<AnyType>();
  const { drugId, patient } = state?.enrollmentPayload;
  const userName = useSelector((state: AppRootState) => state.auth.session?.user?.Login);
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const hookData = { data: { userName, organizationId } };
  const { loading, data, error, fetchData } = useRxModuleRequest({ ...hookData, lazy: true });
  const isAutoMedEPA: AnyType = useSelector<AnyType>(tenancyFeatureSelector(AutomatedServiceOptionType.AUTO_MED_EPA));
  const errorMessage =
    error ??
    (!data?.Html
      ? 'There was an issue loading the module, please contact the administrator.'
      : 'Please contact the administrator.');
  const { service } = useParams<RxModuleSelectedParams>();

  useEffect(() => {
    if (userName && organizationId) {
      fetchData();
    }
  }, [userName, organizationId]);

  const executeAutoMedEPA = useCallback(() => {
    const eBVResult = getMedEbvResult(data?.Html);
    let newRouteState = {};
    if (state?.loadingService === ServiceOptionType.MedicalElectronicBenefitVerification) {
      newRouteState = {
        ...state,
        ...{
          isAutoMedEPAEnabled: isAutoMedEPA?.enabled,
          eBVResult: {
            transactionId: data?.TransactionID,
            details: eBVResult?.details,
            policies: eBVResult?.policies
          },
          ePAResult: []
        }
      };
    } else if (state?.loadingService === ServiceOptionType.MedicalElectronicPriorAuthorization) {
      newRouteState = {
        ...state,
        ...{
          ePAResult: [
            ...state?.ePAResult,
            {
              transactionId: data?.TransactionID,
              policyType: state?.selectedPolicyType
            }
          ]
        }
      };
    }

    if (isPARequired(eBVResult?.policies || state?.eBVResult?.policies) && !state.allPAExecuted) {
      dispatch(
        navigateToRequestServiceIntermediator(ServiceOptionType.MedicalElectronicPriorAuthorization, newRouteState)
      );
    } else {
      dispatch(navigateToRequestsSubmitted(service, { ...newRouteState, drugId, patient }));
    }
  }, [data]);

  useEffect(() => {
    const handler = (event: AnyType) => {
      const physicianNpiValue = state?.enrollmentPayload?.physicians?.npi;
      const patientIdValue = state?.enrollmentPayload?.patient?.patientId;
      if (event.data !== FINISHED_EVENT) return;
      if (state?.isMedEbvAutomationEnabled && isAutoMedEPA?.enabled) {
        executeAutoMedEPA();
      } else {
        dispatch(
          navigateToRequestsSubmitted(service, {
            ...{ physicianNpiValue, patientIdValue },
            ...{
              isMedEbvAutomationEnabled: state?.isMedEbvAutomationEnabled,
              isAutoMedEPAEnabled: isAutoMedEPA?.enabled
            },
            drugId,
            patient
          })
        );
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [data]);

  let isMedeBV;
  if (data?.Html.includes('MedEbvMainDisplay') && data?.Html.includes('ResponseId')) {
    isMedeBV = true;
  } else {
    isMedeBV = false;
  }

  return (
    <>
      <ConnectedMainToolbar />
      <HcpSessionManager />
      <HcpRequestStateUI
        errorText={errorMessage}
        errorUIEnabled
        isError={!!error}
        isLoading={loading}
        loadingUIEnabled
        loadingText='Module Loading'
        reload={() => fetchData()}
        reloadingEnabled
        isMedEbvAutomationEnabled={state?.isMedEbvAutomationEnabled}
        loadingService={state?.loadingService}>
        {data && data?.Html && !isMedeBV && (
          <iframe
            id='target'
            srcDoc={data?.Html}
            frameBorder='0'
            style={{ minHeight: 900 }}
            className='fill-available'
          />
        )}
        {data && data?.Html && isMedeBV && (
          <iframe
            id='target'
            srcDoc={data?.Html}
            frameBorder='0'
            style={{ minHeight: 2030 }}
            className='fill-available'
          />
        )}
      </HcpRequestStateUI>
    </>
  );
};

const getMedEbvJson = (medEbvTransaction: AnyType) => {
  const stringEbvResponse = getMedEbvResponseJson(medEbvTransaction);
  if (!stringEbvResponse) {
    return null;
  }
  try {
    const parseJsonResponse = JSON.parse(stringEbvResponse);
    return parseJsonResponse;
  } catch (ex) {
    return null;
  }
};

const getMedEbvResponseJson = (medEbvTransaction: AnyType) =>
  medEbvTransaction?.split('var jsonResponse = ')?.[1]?.split('var apiUrl')?.[0]?.replace(';', '');

const getMedEbvResult = (medResponse: string) => {
  if (!medResponse) {
    return null;
  }
  const eBVResult: { details: AnyType; policies: AnyType[] } = {
    details: {},
    policies: []
  };
  const mapDetails = (medEBVDetail: AnyType) => ({
    responseId: medEBVDetail?.ResponseId,
    patientName: medEBVDetail?.PatientName
  });
  const medEBVJson = getMedEbvJson(medResponse);
  eBVResult.details = mapDetails(medEBVJson?.MedEbvMainDisplay);
  eBVResult.policies = medEBVJson?.ListOfMedebvPolicy?.map((response: AnyType) => ({
    policyType: response?.PolicySequence,
    insuranceStatus: response?.InsuranceStatus,
    productStatus: response?.ProductCovered,
    paStatus: response?.PriorAuthRequired,
    payerName: response?.PayerName,
    paExecutedStatus: false
  }));
  return eBVResult;
};

const isPARequired = (policies: AnyType): boolean => {
  return !policies || !Array.isArray(policies) || policies?.length === 0
    ? false
    : policies?.findIndex((policy) => 'YES' === policy?.paStatus?.toUpperCase()) !== -1;
};
