import React from 'react';

import { AnyType, CustomSvgIcon } from '../../interfaces';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ATTACHMENT } from '../../utils';

export const PaperClip = ({ tabIndex, className }: AnyType) => (
  <OverlayTrigger placement='top' overlay={<Tooltip id='message_attachment'>{ATTACHMENT}</Tooltip>}>
    <svg
      tabIndex={tabIndex}
      aria-hidden='true'
      focusable='false'
      data-prefix='fas'
      data-icon='paperclip'
      className={`${className}`}
      style={{ height: '16px' }}
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'>
      <path
        fill='currentColor'
        d='M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z'></path>
    </svg>
  </OverlayTrigger>
);

export const PaperPlane = ({ tabIndex, className }: AnyType) => (
  <svg
    tabIndex={tabIndex}
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='paper-plane'
    className={`${className}`}
    style={{ height: '16px' }}
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'>
    <path
      fill='currentColor'
      d='M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z'></path>
  </svg>
);

export const AngleDown = ({ tabIndex, className }: AnyType) => (
  <svg
    tabIndex={tabIndex}
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='angle-down'
    className={`${className}`}
    style={{ height: '16px' }}
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 512'>
    <path
      fill='currentColor'
      d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'></path>
  </svg>
);

export const AngleUp = ({ tabIndex, className }: AnyType) => (
  <svg
    tabIndex={tabIndex}
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='angle-up'
    className={`${className}`}
    style={{ height: '16px' }}
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 512'>
    <path
      fill='currentColor'
      d='M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z'></path>
  </svg>
);

export const RxAdminIcon = () => (
  <svg
    width='20'
    className='cmx__rx-admin-icon'
    height='20'
    viewBox='0 0 18 24'
    fill='white'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.875 0C17.4844 0 18 0.515625 18 1.125V3.375C18 4.03125 17.4844 4.5 16.875 4.5H1.125C0.46875 4.5 0 4.03125 0 3.375V1.125C0 0.515625 0.46875 0 1.125 0H16.875ZM1.5 22.5V6H16.5V22.5C16.5 23.3438 15.7969 24 15 24H3C2.15625 24 1.5 23.3438 1.5 22.5ZM4.5 13.875V16.125C4.5 16.3594 4.64062 16.5 4.875 16.5H7.5V19.125C7.5 19.3594 7.64062 19.5 7.875 19.5H10.125C10.3125 19.5 10.5 19.3594 10.5 19.125V16.5H13.125C13.3125 16.5 13.5 16.3594 13.5 16.125V13.875C13.5 13.6875 13.3125 13.5 13.125 13.5H10.5V10.875C10.5 10.6875 10.3125 10.5 10.125 10.5H7.875C7.64062 10.5 7.5 10.6875 7.5 10.875V13.5H4.875C4.64062 13.5 4.5 13.6875 4.5 13.875Z'
      fill='currentColor'
    />
  </svg>
);

export const RxAdminDropdownIcon = () => (
  <svg
    width='16'
    className='cmx__rx-admin-icon'
    height='16'
    viewBox='0 0 18 24'
    fill='white'
    style={{ margin: '0px 8px 0px 0px' }}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.875 0C17.4844 0 18 0.515625 18 1.125V3.375C18 4.03125 17.4844 4.5 16.875 4.5H1.125C0.46875 4.5 0 4.03125 0 3.375V1.125C0 0.515625 0.46875 0 1.125 0H16.875ZM1.5 22.5V6H16.5V22.5C16.5 23.3438 15.7969 24 15 24H3C2.15625 24 1.5 23.3438 1.5 22.5ZM4.5 13.875V16.125C4.5 16.3594 4.64062 16.5 4.875 16.5H7.5V19.125C7.5 19.3594 7.64062 19.5 7.875 19.5H10.125C10.3125 19.5 10.5 19.3594 10.5 19.125V16.5H13.125C13.3125 16.5 13.5 16.3594 13.5 16.125V13.875C13.5 13.6875 13.3125 13.5 13.125 13.5H10.5V10.875C10.5 10.6875 10.3125 10.5 10.125 10.5H7.875C7.64062 10.5 7.5 10.6875 7.5 10.875V13.5H4.875C4.64062 13.5 4.5 13.6875 4.5 13.875Z'
      fill='currentColor'
    />
  </svg>
);

export const Syringe = ({ tabIndex, className, fillColor, id, title, height }: CustomSvgIcon) => (
  <OverlayTrigger placement='top' overlay={<Tooltip id={`${id}`}>{title}</Tooltip>}>
    <svg
      tabIndex={tabIndex}
      xmlns='http://www.w3.org/2000/svg'
      fill={`${fillColor}`}
      className={`${className}`}
      height={`${height}`}
      viewBox='0 0 512 512'>
      <path d='M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z' />
    </svg>
  </OverlayTrigger>
);
