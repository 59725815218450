import _ from 'lodash';
import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { renderTenancyLinksAsNavItems } from '../utils';
import { ScrollToElement } from '../utils';
import { CustomDurgSpecificNavDropdownLinks } from './CustomDurgSpecificNavDropdownLinks';
import { FeatureGuard } from './multi-tenant/FeatureGuard';

export const AppNavigation = () => {
  const dispatch = useDispatch();
  const primaryMenu = useSelector((state: AppRootState) => (state.app.entities?.menus as AnyType)?.primary?.data);
  return (
    <>
      {primaryMenu?.links
        ?.filter((link?: AnyType) => !link?.type)
        ?.map((parentLink: AnyType) => {
          if (!parentLink.links || parentLink.links?.length === 0) {
            const kebabLabel = _.kebabCase(parentLink.label);
            return (
              <FeatureGuard key={kebabLabel} contentKey={parentLink.featureKey}>
                <Nav.Link className='mx-2' href={parentLink.url} data-testid={`cmx__${kebabLabel}`}>
                  {parentLink.label}
                </Nav.Link>
              </FeatureGuard>
            );
          }

          const kebabLabel = _.kebabCase(`${parentLink.label}-dropdown`);
          return (
            <FeatureGuard key={kebabLabel} contentKey={parentLink.featureKey}>
              <NavDropdown className='mx-2' id={kebabLabel} title={parentLink.label} data-testid={`cmx__${kebabLabel}`}>
                {renderTenancyLinksAsNavItems(dispatch, parentLink.links)}
              </NavDropdown>
            </FeatureGuard>
          );
        })}
      {primaryMenu?.links
        ?.filter((link?: AnyType) => 'drug-specific-dropdown' === link?.type)
        ?.map((link: AnyType) => (
          <CustomDurgSpecificNavDropdownLinks key={_.kebabCase(`${link.label}-dropdown`)} parentEle={link} />
        ))}
      {primaryMenu?.links
        ?.filter((link?: AnyType) => 'scrollToElement' === link?.type)
        ?.map((link: AnyType) => (
          <ScrollToElement key={_.kebabCase(`${link.label}`)} parentEle={link} />
        ))}
    </>
  );
};
