import React from 'react';

import { Form, Col } from 'react-bootstrap';

import { EmailField, TextField } from '.';
import { PhysicianFragment } from '../graphql';
import { FIELD_NAMES } from '../utils';

interface SelectedPrescribersFormProps {
  physician?: PhysicianFragment;
}

const { prescriberFields } = FIELD_NAMES;

export const SelectedPrescribersForm = ({ physician }: SelectedPrescribersFormProps) => {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} controlId={prescriberFields.firstName}>
          <TextField
            disabled
            name={prescriberFields.firstName}
            label='FIRST NAME'
            defaultValue={physician?.firstName}
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group as={Col} controlId={prescriberFields.lastName}>
          <TextField
            name={prescriberFields.lastName}
            label='LAST NAME'
            defaultValue={physician?.lastName}
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group as={Col} controlId={prescriberFields.npi}>
          <TextField
            disabled
            label='PRESCRIBER NPI'
            name={prescriberFields.npi}
            defaultValue={physician?.npi}
            plaintext
            readOnly
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group md={2} as={Col} controlId={prescriberFields.taxId}>
          <TextField
            name={prescriberFields.taxId}
            label='TAX ID # (TIN)'
            defaultValue={physician?.details?.taxId}
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group md={2} as={Col} controlId={prescriberFields.stateLicenseNumber}>
          <TextField
            name={prescriberFields.stateLicenseNumber}
            label='STATE LICENSE #'
            defaultValue={physician?.stateLicenses?.stateLicenseNumber}
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group md={2} as={Col} controlId={prescriberFields.licensingState}>
          <EmailField
            name={prescriberFields.licensingState}
            label='LICENSING STATE'
            defaultValue={physician?.stateLicenses?.stateIssuer}
            plaintext
            readOnly
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};
