import React from 'react';

import { HcpRequestStateUI } from '../components';
import { SummaryPageRequestOpts, TransactionType, useSummaryRequest } from '../hooks/use-bv-summary';

export const BvSummaryModule = ({ transactionId, transactionType }: SummaryPageRequestOpts) => {
  const { data, error, loading, fetchData } = useSummaryRequest({
    transactionId,
    transactionType
  });

  const errorMessage =
    error ??
    (!data?.Html
      ? 'There was an issue loading the module, please contact the administrator.'
      : 'Please contact the administrator.');

  const height =
    transactionType === TransactionType.MED_EBV
      ? 2050
      : transactionType === TransactionType.RX_EBV
      ? 1200
      : transactionType === TransactionType.MED_PA
      ? 1530
      : transactionType === TransactionType.RX_PA
      ? 1600
      : 2040;

  const dataHtml = transactionType === TransactionType.MED_EBV ? data?.Html : data?.toString();

  return (
    <>
      <HcpRequestStateUI
        errorText={errorMessage}
        errorUIEnabled
        isError={!!error}
        isLoading={loading}
        loadingUIEnabled
        reload={() => fetchData()}
        reloadingEnabled>
        <iframe
          id='target'
          srcDoc={dataHtml}
          frameBorder='0'
          style={{ minHeight: height }}
          className='fill-available'
        />
      </HcpRequestStateUI>
    </>
  );
};
