import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { FiltersButton } from './FiltersButton';

interface MessagesFiltersProps {
  onFilterChange: (filter: string) => void;
}

export enum MessagesFilterTypes {
  all = 'all',
  unread = 'unread',
  read = 'read'
}

const availableFilters = Object.keys(MessagesFilterTypes);

export const MessagesFilters = (props: MessagesFiltersProps) => {
  const { hash } = useLocation();
  const history = useHistory();

  const filter = ((hash && availableFilters.find((f) => hash.includes(f))) || null) ?? MessagesFilterTypes.all;

  const [selectedFilter, setSelectedFilter] = useState(filter);

  const onFilterChange = (newFilter: string) => {
    history.push(`#${newFilter}`);
    setSelectedFilter(newFilter);
    props.onFilterChange(newFilter);
  };

  useEffect(() => {
    if (!selectedFilter) return;

    props.onFilterChange(selectedFilter);
  }, [props.onFilterChange, selectedFilter]);

  const renderFilterButton = (label: string, value: string) => {
    const variant = selectedFilter === value ? 'secondary' : 'info';
    return (
      <FiltersButton variant={variant} value={value}>
        {label}
      </FiltersButton>
    );
  };

  return (
    <ToggleButtonGroup name='patients-filters' type='radio' onChange={onFilterChange}>
      {renderFilterButton('ALL', MessagesFilterTypes.all)}
      {renderFilterButton('PATIENTS WITH UNREAD', MessagesFilterTypes.unread)}
      {renderFilterButton('PATIENTS WITH READ', MessagesFilterTypes.read)}
    </ToggleButtonGroup>
  );
};
