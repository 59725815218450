import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { object, string } from 'yup';

import { EnrollmentModuleViewState } from '.';
import { Drug } from '../api/portal-config.generated';
import {
  AdditionalSupport,
  AddPatientForm,
  HcpRequestStateUI,
  IndividualServiceOptions,
  NotificationToastMessage,
  PrivateLayout,
  ResourceContent,
  SelectedPatient
} from '../components';
import { AddSearchLocation } from '../components/AddSearchLocation';
import { AddSearchPrescriber } from '../components/AddSearchPrescriber';
import { AllServicesOption } from '../components/AllServicesOption';
import { DrugServices } from '../components/DrugServices';
import {
  OrganizationAddressFragment,
  PatientRequestViewModelFragment,
  PhysicianFragment,
  usePatientInfoLazyQuery,
  useGetPatientDetailByIdLazyQuery,
  GRAPHQL_DEFAULT_CACHE,
  useCaseRecordsQuery,
  useCaseDetailsQuery
} from '../graphql';
import { AnyType, ServiceOptionType } from '../interfaces';
import {
  AppRootState,
  navigateToEnrollmentModule,
  navigateToHome,
  navigateToServiceRequestSelected,
  useTenancyDrugListSelector
} from '../redux';
import { FIELD_NAMES, validateFormValues } from '../utils';
import { Search } from 'react-bootstrap-icons';

const requestSchema = object({
  service: string().required()
});
const { prescriberFields } = FIELD_NAMES;

export const searchIcon = (
  <Button variant='transparent' className={`border border-right-0 rounded-right px-3`}>
    <Search className='text-gray' />
  </Button>
);

export const ServiceRequestView = () => {
  const history = useHistory();
  const [selectedService, setSelectedService] = useState<any>({});
  const [drug, setDrug] = useState<Drug | null | undefined>(null);
  const [singleDrug, setSingleDrug] = useState<Drug | null | undefined>(null);
  sessionStorage.setItem('enrollmentModuleEnrollmentId', '');
  sessionStorage.setItem('enrollmentModulePracticeId', '');
  sessionStorage.setItem('caseId', '');
  sessionStorage.setItem('drugList', '');
  sessionStorage.setItem('locationId', '');
  sessionStorage.setItem('prescriberId', '');
  sessionStorage.setItem('patientIdEnrollment', '');
  sessionStorage.setItem('caseIdEnrollment', '');
  sessionStorage.setItem('patientId', '');
  sessionStorage.setItem('performedServices', '');
  sessionStorage.setItem('primaryDiagnosed', '');
  sessionStorage.setItem('prescriberNpi', '');
  sessionStorage.setItem('previousTreatment', '');
  sessionStorage.setItem('enrollmentObj', '');
  sessionStorage.setItem('prescriberFirstName', '');
  sessionStorage.setItem('prescriberLastName', '');
  sessionStorage.setItem('prescriberMiddleName', '');
  useEffect(() => {
    setSelectedService('');
  }, [drug]);

  const dispatch = useDispatch();
  const { patientId } = useParams<{ patientId: string }>();
  sessionStorage.setItem('patientIdEnrollment', patientId);
  const { caseDrugName } = useParams<{ caseDrugName: string }>();
  const { caseId } = useParams<{ caseId: string }>();
  sessionStorage.setItem('caseIdEnrollment', caseId);

  const [execPatientQuery, patientResult] = usePatientInfoLazyQuery({ variables: { patientId } });
  const enrollmentModuleIsEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );
  const [patient, setPatient] = useState<PatientRequestViewModelFragment>();
  const [prescriber, setPrescriber] = useState<any>(null);
  const [providerLocation, setProviderLocation] = useState<any>(null);
  const [defaultLocation, setDefaultLocation] = useState<any>(null);
  const [defaultPrescriber, setDefaultPrescriber] = useState<any>(null);
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const [execPatientDetailQuery, patientDetailResult] = useGetPatientDetailByIdLazyQuery({
    variables: { organization: parseInt(organizationId || ''), patientId: patient?.patientId || 0 },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });
  const isMultiDrugEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isMultiDrugEnabled'] as AnyType)?.data?.enabled
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const path = window.location.pathname;

  const newDrugList: Drug[] = useTenancyDrugListSelector();
  const multipleDrugsSupported = (newDrugList?.length ?? 0) > 1;

  useEffect(() => {
    if (isDisabled) {
      const drug = newDrugList?.filter((drug: any) => drug?.DrugBrandName === caseDrugName);
      setDrug(drug?.[0]);
    }
  }, [isDisabled, newDrugList]);

  useEffect(() => {
    setIsDisabled(path?.includes('/case'));
  }, []);

  useEffect(() => {
    const patientDetails = patientDetailResult?.data?.patientDetailByPatientID;
    if (patientDetails) {
      setDefaultPrescriber(patientDetails?.physician);
      onPrescriberSelect(patientDetails?.physician);
      setDefaultLocation(patientDetails?.organizationAddress);
    }
  }, [patientDetailResult]);

  const onPatientSelect = useCallback((newPatient: PatientRequestViewModelFragment) => {
    if (!newPatient) return;

    setPrescriber(null);

    setPatient(newPatient);
    execPatientDetailQuery();
  }, []);

  const onPrescriberSelect = useCallback((newPrescriber: any) => {
    if (!newPrescriber) {
      setPrescriber(null);
      return;
    } else {
      setPrescriber(newPrescriber);
    }
  }, []);

  const onLocationSelect = useCallback((newLocation: any) => {
    if (!newLocation) return;
    setProviderLocation(newLocation);
  }, []);

  const noDrugsAvailable = newDrugList && newDrugList.length === 0;

  const getServiceValue = useCallback((selectedService: any) => {
    setSelectedService(selectedService);
  }, []);

  useEffect(() => {
    if (!newDrugList) return;

    // Set the drug by default to the first item if there is only one drug
    if (newDrugList?.length === 1 && !isMultiDrugEnabled) setSingleDrug(newDrugList[0]);
  }, [newDrugList]);

  const onDrugChange = (drugSelection: Drug) => {
    // reset notification flag when same drug is selected
    if (drugSelection === drug) {
      setSelectedService('');
    }
    if (!caseDrugName) {
      setDrug(drugSelection);
    }
    history?.replace(history?.location?.pathname, { drug: drugSelection });
  };

  const loadDefaultPatient = useCallback(() => {
    if (!patientId) return;

    execPatientQuery({
      variables: {
        patientId
      }
    });
  }, [patientId, onPatientSelect]);

  useEffect(loadDefaultPatient, [loadDefaultPatient]);

  useEffect(() => {
    if (!patientResult?.data?.result) return;

    const newPatient: PatientRequestViewModelFragment = {
      actionAlertCount: 0,
      authorizationReceived: false,
      dob: patientResult.data.result.dob,
      firstName: patientResult.data.result.firstName,
      gender: patientResult.data.result.gender,
      genderOther: patientResult.data.result?.genderOther,
      lastName: patientResult.data.result.lastName,
      middleName: patientResult.data.result.middleName,
      patientId: patientResult.data.result.id,
      updateAlertCount: 0
    };
    onPatientSelect(newPatient);
  }, [patientResult, onPatientSelect]);

  const onSubmit = useCallback(
    (values: { [key: string]: string }) => {
      if (!patient) return;
      dispatch(
        navigateToServiceRequestSelected(
          values['service'],
          patient?.patientId,
          drug?.DrugId,
          prescriber?.id,
          providerLocation?.id
        )
      );
    },
    [dispatch, patient, drug, prescriber, providerLocation]
  );

  const isBtnDisabled = () => {
    // TODO: Following 'if condition' statment need to remove later,
    // for testing purpose setting Eylea as default if no durg is configured.
    // So, Enabling 'next' button for default test drug.
    if (enrollmentModuleIsEnabled && !drug) {
      return enrollmentModuleIsEnabled ? !patient || !prescriber || !providerLocation : !patient;
    }
    return enrollmentModuleIsEnabled ? !patient || !drug || !prescriber || !providerLocation : !patient;
  };

  const redirectToEnrollmentModule = () => {
    if (patient?.patientId?.toString() && prescriber && providerLocation) {
      const enrollmentRouteState: EnrollmentModuleViewState = {
        patientId: patient?.patientId?.toString(),
        drug: drug || singleDrug || {},
        prescriber: prescriber,
        location: providerLocation
      };
      dispatch(navigateToEnrollmentModule(enrollmentRouteState));
    }
  };

  const onNextClicked = (form: AnyType, handleSubmit: AnyType) => {
    enrollmentModuleIsEnabled && ServiceOptionType.All === form?.getFieldState('service')?.value
      ? redirectToEnrollmentModule()
      : handleSubmit();
  };

  return (
    <PrivateLayout pageTitle='new request'>
      <HcpRequestStateUI
        errorUIEnabled
        isError={patientResult?.error}
        loadingUIEnabled
        isLoading={patientResult?.loading}
        reloadingEnabled
        reload={loadDefaultPatient}>
        <Row>
          <Col>
            <p className='fs-4 font-weight-bold text-primary mt-3 mb-4'>
              <ResourceContent contentKey='service-request.patient.group-header' />
            </p>

            {((patientId && patient) || !patientId) && (
              <AddPatientForm
                defaultPatient={patient}
                onPatientSelect={onPatientSelect}
                setDefaultPrescriber={setDefaultPrescriber}
                setDefaultLocation={setDefaultLocation}
                isDisabled={isDisabled}
              />
            )}
          </Col>

          <Col className='d-none d-md-block' md={{ order: 2, span: 3 }} lg={{ order: 2, span: 3 }}>
            <AdditionalSupport />
          </Col>
        </Row>
        <FinalForm
          onSubmit={onSubmit}
          validate={validateFormValues(requestSchema)}
          initialValues={requestSchema.default()}
          render={({ form, handleSubmit, hasValidationErrors }: FormRenderProps) => (
            <Form>
              <Row>
                <Col md={9} sm={12}>
                  <SelectedPatient patient={patient} />
                </Col>
              </Row>

              {enrollmentModuleIsEnabled && (
                <>
                  <AddSearchPrescriber
                    defaultPrescriber={defaultPrescriber}
                    name={prescriberFields.id}
                    onPrescriberSelect={onPrescriberSelect}
                    organizationId={organizationId}
                    prescriber={prescriber}
                    setPrescriber={setPrescriber}
                    isDisabled={isDisabled}
                  />
                  <AddSearchLocation
                    defaultLocation={defaultLocation}
                    providerLocation={providerLocation}
                    setProviderLocation={setProviderLocation}
                    name={prescriberFields.locationId}
                    onLocationSelect={onLocationSelect}
                    organizationId={organizationId}
                    prescriberId={prescriber?.id ? parseInt(prescriber?.id) : 0}
                    isDisabled={isDisabled}
                  />
                </>
              )}

              {((!multipleDrugsSupported && (drug || !isMultiDrugEnabled)) || noDrugsAvailable) && (
                <>
                  <Row>
                    <Col sm={6}>
                      <AllServicesOption
                        drug={drug || {}}
                        isEnrollmentModuleView={enrollmentModuleIsEnabled}
                        getServiceValue={getServiceValue}
                      />
                      <IndividualServiceOptions
                        drug={drug || {}}
                        isEnrollmentModuleView={enrollmentModuleIsEnabled}
                        getServiceValue={getServiceValue}
                      />
                    </Col>
                    <Col sm={6}>
                      <NotificationToastMessage
                        selectedService={selectedService}
                        patientGender={patient?.gender || undefined}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {multipleDrugsSupported && isMultiDrugEnabled && (
                <>
                  <Row>
                    <Col md={5} sm={9}>
                      <p className='fs-4 font-weight-bold text-primary mt-3 mb-4'>
                        WHICH THERAPY OR PRODUCT IS THIS REQUEST FOR?
                      </p>
                      <InputGroup className='border-left-0'>
                        <InputGroup.Prepend>{searchIcon}</InputGroup.Prepend>
                        <Typeahead
                          defaultSelected={
                            caseDrugName
                              ? newDrugList?.filter((drug: any) => drug?.DrugBrandName === caseDrugName)
                              : undefined
                          }
                          disabled={isDisabled}
                          id='product_typeahead'
                          bsSize='sm'
                          labelKey={(options: AnyType) => options.DrugLabelName}
                          options={newDrugList as AnyType}
                          onChange={(selected: AnyType) => {
                            form.change('service', null);
                            onDrugChange(selected[0]);
                          }}
                          placeholder='Type to search'
                          maxResults={4}
                          inputProps={{
                            className: 'border-left-0'
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  {drug && (
                    <Row>
                      <Col sm={6}>
                        <DrugServices drug={drug} getServiceValue={getServiceValue} caseDrugName={caseDrugName} />
                      </Col>
                      <Col>
                        <NotificationToastMessage
                          selectedService={selectedService}
                          patientGender={patient?.gender || undefined}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}

              <Row>
                <Col
                  xs={{ order: 2, span: 12 }}
                  md={{ order: 1, offset: 4, span: 4 }}
                  className='d-flex justify-content-center align-items-center'>
                  <Button
                    variant='transparent'
                    className='text-danger ml-1 mt-4 w-75'
                    onClick={() => dispatch(navigateToHome())}
                    data-testid='cmx__request__cancel-button'>
                    CANCEL
                  </Button>
                </Col>
                <Col
                  xs={{ order: 1, span: 12 }}
                  md={4}
                  className='d-flex justify-content-center justify-content-md-end align-items-center'>
                  <Button
                    variant='secondary'
                    className='w-75'
                    onClick={() => onNextClicked(form, handleSubmit)}
                    disabled={isBtnDisabled() || hasValidationErrors}
                    data-testid='cmx__request__start-request-button'>
                    START REQUEST
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col className='d-md-none mt-5'>
                  <AdditionalSupport />
                </Col>
              </Row>
            </Form>
          )}
        />
      </HcpRequestStateUI>
    </PrivateLayout>
  );
};
