import { AnyType } from '../interfaces';

export * from './portal.generated';

export const API_APPLICATION_NAME = String((window as AnyType).REACT_APP_API_APPLICATION_NAME);
export const API_DEMO_APPLICATION_NAME = String((window as AnyType).REACT_APP_API_DEMO_APPLICATION_NAME);
export const API_URL = String((window as AnyType).REACT_APP_API_URL);
export const CONFIG_DOMAIN = String((window as AnyType).REACT_APP_CONFIG_DOMAIN);
export const GATEWAY_API_URL = String((window as AnyType).REACT_APP_GATEWAY_API_URL);
export const ENROLLMENT_MODULE_TRIGGER_RULE = String((window as AnyType).REACT_APP_ENROLLMENT_MODULE_TRIGGER_RULE);
export const SERVICES_GATEWAY_URL = String((window as AnyType).REACT_APP_SERVICES_GATEWAY_URL);
export const FIVE9_PROFILE = String((window as AnyType).REACT_APP_FIVE9_PROFILE);
export const GRAPHQL_URL = String((window as AnyType).REACT_APP_GRAPHQL_URL);
export const SESSION_TIMEOUT_IN_MINUTES = String((window as AnyType).REACT_APP_SESSION_TIMEOUT_IN_MINUTES);
export const SESSION_WARNING_IN_MINUTES = String((window as AnyType).REACT_APP_SESSION_WARNING_IN_MINUTES);
export const OKTA_ISSUER_PATH = String((window as AnyType).REACT_APP_OKTA_ISSUER_PATH);
export const EHIPAA_VERIFY_URL = String((window as AnyType).REACT_APP_EHIPAA_VERIFY_URL);
export const EHIPAA_API_URL = String((window as AnyType).REACT_APP_EHIPAA_API_URL);

// -----===[RX MODULES]===-----
export const RX_APPLICATION_NAME = String((window as AnyType).REACT_APP_RX_APPLICATION_NAME);
export const RX_USERNAME = String((window as AnyType).REACT_APP_RX_USERNAME);
export const RX_PASSWORD = String((window as AnyType).REACT_APP_RX_PASSWORD);
export const RX_URL = String((window as AnyType).REACT_APP_RX_URL);
export const RX_AUTH_URL = String((window as AnyType).REACT_APP_RX_AUTH_URL);
export const RX_SESSION_TIME = String((window as AnyType).REACT_APP_RX_SESSION_TIME);
export const MEDICSCAN_API_URL = String((window as AnyType).REACT_APP_MEDICSCAN_API_URL);
export const MEDICSCAN_PASSWORD = String((window as AnyType).REACT_APP_MEDICSCAN_PASSWORD);
export const MEDICSCAN_USERNAME = String((window as AnyType).REACT_APP_MEDICSCAN_USERNAME);
export const SERVICE_MODULE_TRIGGER_RULE = String((window as AnyType).REACT_APP_SERVICE_MODULE_TRIGGER_RULE);

export const MEDEBV_MODULE_TRIGGER_RULE = String((window as AnyType).REACT_APP_MEDEBV_MODULE_TRIGGER_RULE);
// -----===[STANDARD PORTAL]===-----
export const STANDARD_PORTAL_APPLICATION_NAME = String((window as AnyType).REACT_APP_STANDARD_PORTAL_APPLICATION_NAME);
