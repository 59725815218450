import { ColDef, ColumnApi, GridApi, GridReadyEvent, PaginationChangedEvent, RowNode } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import { AnyType } from '../interfaces';
import { globalDefaultColDefs, hcpFrameworkComponents } from '../utils';

interface HcpGridState {
  columnApi?: ColumnApi | undefined;
  gridApi?: GridApi | undefined;
  paginationPageSize: number;
  paginationCurrentPage: number;
  paginationFirstRowIndex?: number;
  paginationLastRowIndex?: number;
}

export interface HcpGridProps extends AgGridReactProps {
  children?: AnyType;
  className?: string;
  filterComparator?: (node: RowNode, value: string | AnyType | undefined, columns?: ColDef[]) => unknown;
  // This is used solely as a key by the component to dictate when to tell the grid the filters have changed
  filterValue?: string | AnyType;
  isFilterActive?: boolean;
  pageSizes?: number[];
  isScrollbarEnabled?: boolean;
}

export class HcpGrid extends Component<HcpGridProps, HcpGridState> {
  static defaultProps = {
    isFilterActive: false
  };

  onWindowResizeDebounced: AnyType;

  constructor(props: HcpGridProps) {
    super(props);

    this.onWindowResizeDebounced = debounce((event: Event) => {
      this.onWindowResize(event);
    }, 500);

    this.state = {
      paginationPageSize: (this.props.pageSizes && this.props.pageSizes[0]) ?? 10,
      paginationCurrentPage: 1
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.onWindowResizeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResizeDebounced);
  }

  componentDidUpdate(prevProps: HcpGridProps) {
    if (this.props.isFilterActive !== prevProps.isFilterActive || this.props.filterValue !== prevProps.filterValue) {
      this.state.gridApi?.onFilterChanged();
    }
  }

  onWindowResize = (event?: Event) => {
    const target = (event?.target as Window) ?? window;

    if (target.innerWidth > 1040) {
      this.gridApi?.sizeColumnsToFit();
      return;
    }

    const allColumnIds: string[] = [];
    this.state.columnApi?.getAllColumns().forEach(function (column) {
      if (!(column.getDefinition() as ColDef).suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    this.state.columnApi?.autoSizeColumns(allColumnIds, false);
  };

  get gridApi(): GridApi | undefined {
    return this.state.gridApi;
  }

  isExternalFilterPresent = () => {
    return this.props.isFilterActive === true && this.props.filterComparator !== undefined;
  };

  doesExternalFilterPass = (node: RowNode): boolean => {
    return (
      (this.props.filterComparator &&
        this.props.filterComparator(node, this.props.filterValue, this.gridApi?.getColumnDefs())) === true
    );
  };

  render() {
    return (
      <div className={`ag-theme-alpine ${this.props.className ?? ''}`.trim()} data-testid='hcp-grid-wrapper'>
        <Row className='mb-3'>
          <Col>
            <AgGridReact
              data-testid='hcp-grid'
              onRowClicked={this.props.onRowClicked}
              rowSelection={this.props.rowSelection}
              doesExternalFilterPass={this.doesExternalFilterPass}
              domLayout={this.props.isScrollbarEnabled ? 'normal' : 'autoHeight'}
              enableBrowserTooltips={true}
              isExternalFilterPresent={this.isExternalFilterPresent}
              onGridReady={this.onGridReady}
              onPaginationChanged={this.onPaginationChange}
              paginationPageSize={this.state.paginationPageSize}
              suppressPaginationPanel={this.props.suppressPaginationPanel ?? true}
              frameworkComponents={hcpFrameworkComponents}
              defaultColDef={this.props.defaultColDef ?? globalDefaultColDefs}
              {...this.props}
            />
          </Col>
        </Row>
        {this.renderPaginationLayout()}
      </div>
    );
  }

  renderPaginationLayout() {
    return (
      <>
        {this.props.pagination && (
          <Row className='justify-content-between align-items-center'>
            <Col xs={{ order: 'first', span: 7 }} md={{ order: 'first', span: 2 }}>
              {this.renderPaginationInfo()}
            </Col>
            <Col xs={{ order: 'last', span: 12 }} md={{ order: 'first', span: 8 }} className='mt-3'>
              {this.renderPagination()}
            </Col>
            <Col xs={{ order: 'first', span: 3 }} md={{ order: 'first', span: 2 }}>
              {this.renderPageSize()}
            </Col>
          </Row>
        )}
      </>
    );
  }

  renderPageSize() {
    const pageSizes = this.props.pageSizes ?? [5, 10, 25, 50];
    return (
      <Dropdown className='d-flex justify-content-end'>
        <Dropdown.Toggle variant='outline-primary' className='w-sm-50 py-1 px-3'>
          {this.state.paginationPageSize}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {pageSizes.map((pageSizeOption: number, i: number) => (
            <Dropdown.Item
              key={i}
              eventKey={`${pageSizeOption}`}
              active={this.state.paginationPageSize === pageSizeOption}
              onSelect={(ekey: string | null) => this.updatePageSize(ekey)}>
              {pageSizeOption}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderPagination() {
    const totalPages = this.gridApi?.paginationGetTotalPages() || 0;

    if (totalPages <= 1) return null;

    return (
      <div className='d-flex justify-content-center'>
        <Button variant='outline-primary' className='px-2 mr-2' onClick={() => this.gridApi?.paginationGoToFirstPage()}>
          <ChevronDoubleLeft />
          First
        </Button>
        <ReactPaginate
          activeClassName='active bg-black-50'
          breakClassName='page-item'
          breakLinkClassName='page-link border-0'
          breakLabel={'...'}
          containerClassName='pagination m-0 align-items-center'
          forcePage={this.state.paginationCurrentPage}
          marginPagesDisplayed={2}
          nextClassName='page-item ml-2'
          nextLabel='>'
          nextLinkClassName='btn btn-outline-primary'
          onPageChange={this.onPaginateNavigationChange}
          pageClassName='page-item'
          pageCount={totalPages}
          pageLinkClassName='page-link border-0'
          pageRangeDisplayed={5}
          previousClassName='page-item mr-2'
          previousLabel='<'
          previousLinkClassName='btn btn-outline-primary'
        />
        <Button variant='outline-primary' className='px-2 ml-2' onClick={() => this.gridApi?.paginationGoToLastPage()}>
          Last
          <ChevronDoubleRight />
        </Button>
      </div>
    );
  }

  renderPaginationInfo = () => {
    if (this.state.paginationFirstRowIndex === null || this.state.paginationLastRowIndex === 0) return;

    return (
      <span className='text-primary'>
        Showing{' '}
        <span className='text-dark'>
          {this.state.paginationFirstRowIndex} - {this.state.paginationLastRowIndex}
        </span>{' '}
        of <span className='text-dark'>{this.state.gridApi?.paginationGetRowCount()}</span> records
      </span>
    );
  };

  updatePageSize = (eventKey: string | null) => {
    this.setState({ paginationPageSize: Number(eventKey) });
    this.gridApi?.paginationSetPageSize(Number(eventKey));
  };

  onGridReady = (event: GridReadyEvent) => {
    const api = event.api ?? undefined;

    this.setState({ gridApi: api, columnApi: event.columnApi }, this.onWindowResize);
  };

  onPaginationChange = (event: PaginationChangedEvent) => {
    const paginationCurrentPage = event.api.paginationGetCurrentPage();
    const paginationFirstRowIndex = (event.api.getFirstDisplayedRow() ?? 0) + 1;
    const paginationLastRowIndexCalc = paginationFirstRowIndex + event.api.paginationGetPageSize() - 1;
    const lastPage = event.api.paginationGetRowCount();
    const paginationLastRowIndex = paginationLastRowIndexCalc <= lastPage ? paginationLastRowIndexCalc : lastPage;

    this.setState({ paginationCurrentPage, paginationFirstRowIndex, paginationLastRowIndex });
  };

  onPaginateNavigationChange = (selectedItem: { selected: number }) => {
    // NOTE: This call will trigger AG to call `onPaginationChange`. DO NOT SET STATE HERE.
    this.gridApi?.paginationGoToPage(selectedItem.selected);
  };
}
