import { RowNode } from 'ag-grid-community';
import { setToArray } from './case-utils';
import { FilterInterface } from '../components';
import { isRxAdminEnabled } from '../redux/helpers';
import { AnyType } from '../interfaces';

const checkForStatus = (node: RowNode, statusText: string, strictCompare: boolean) => {
  return strictCompare
    ? node.data.statusText?.toLowerCase() === statusText.toLowerCase()
    : node.data.statusText?.toLowerCase().includes(statusText.toLowerCase());
};

export const filterRequest = (
  node: RowNode,
  selectedFilterNode: boolean,
  selectorFilterList: string,
  strictCompare: boolean
) => {
  if (!selectedFilterNode) return false;
  return checkForStatus(node, selectorFilterList, strictCompare);
};

export const filterGridComparator = (node: RowNode, filter?: FilterInterface) => {
  let showNode = false;
  const requestFilterArray = setToArray(filter?.secondaryFilterList || new Set());
  switch (filter?.primaryFilter?.toLowerCase()) {
    case 'actions':
      if (
        (node.data.actionAlert || !!node.data.actionAlertCount) &&
        filter?.secondaryFilterList &&
        filter?.secondaryFilterList?.size > 0
      ) {
        requestFilterArray?.forEach(
          (selectedFilter) =>
            (showNode =
              showNode ||
              filterRequest(
                node,
                node.data.actionAlert || node.data.actionAlertCount > 0,
                selectedFilter,
                filter?.strictCompare || false
              ))
        );
      } else if (node.data.rxAdminAlert && isRxAdminEnabled()) {
        showNode = node.data.rxAdminAlert;
      } else {
        showNode = node.data.actionAlert || !!node.data.actionAlertCount;
      }
      break;

    case 'updates':
      if (
        (node.data.updateAlert || !!node.data.updateAlertCount) &&
        filter?.secondaryFilterList &&
        filter?.secondaryFilterList?.size > 0
      ) {
        requestFilterArray?.forEach(
          (selectedFilter) =>
            (showNode =
              showNode ||
              filterRequest(
                node,
                node.data.updateAlert || node.data.updateAlertCount > 0,
                selectedFilter,
                filter?.strictCompare || false
              ))
        );
      } else {
        showNode = node.data.updateAlert || !!node.data.updateAlertCount;
      }
      break;

    default:
      if (filter?.secondaryFilterList && filter?.secondaryFilterList?.size > 0) {
        requestFilterArray?.forEach(
          (selectedFilter) =>
            (showNode = showNode || filterRequest(node, true, selectedFilter, filter?.strictCompare || false))
        );
      } else {
        showNode = true;
      }
  }
  return showNode;
};

export const getStatusDescriptions = (data: AnyType[], typeName: string) => {
  const statusDescriptions: string[] = [
    ...new Set(
      data.reduce((acc: string[], curr: AnyType) => {
        if (curr?.statusText) {
          acc = [...acc, curr.statusText];
        }
        return acc;
      }, [])
    )
  ];
  return statusDescriptions.map((item) => ({ statusDescription: item, __typename: typeName }));
};
