import React, { useCallback } from 'react';

import { PatientFragment, PatientInsurance, usePatientInsuranceQuery } from '../graphql';
import { HcpFormRenderer, LoadingIndicatorRow } from '.';
import {
  defaultDateFormatWithAge,
  FIELD_NAMES,
  getPatientPhoneMessageOption,
  getPatientProfileDetailsSchema
} from '../utils';

interface PatientProfileDetailsProps {
  patient?: PatientFragment | null;
}

const { copayFields, insuranceFields, patientFields, diagnosisFields } = FIELD_NAMES;

export const PatientProfileDetails = ({ patient }: PatientProfileDetailsProps) => {
  const { data, loading } = usePatientInsuranceQuery({ variables: { patientId: String(patient?.id) } });
  const payload = useCallback(() => buildPatientDetails(patient, data?.result as PatientInsurance[]), [
    data?.result,
    patient
  ]);

  return (
    <>
      {loading && <LoadingIndicatorRow />}
      <HcpFormRenderer
        contentKey='form.patient-profile-details'
        initialValues={payload()}
        noControls
        noErrorMessage
        schemaMethod={getPatientProfileDetailsSchema}
      />
    </>
  );
};

const buildPatientDetails = (patient?: PatientFragment | null, insurance?: PatientInsurance[] | null) => {
  const homePhone = patient?.phoneNumbers?.find((phone) => phone?.phoneType?.toLowerCase() === 'home');
  const mobilePhone = patient?.phoneNumbers?.find((phone) => phone?.phoneType?.toLowerCase() === 'mobile');
  const caregiverHomePhone = patient?.patientContact?.[0]?.contact?.contactPhoneNumbers?.find(
    (phone) => phone?.phoneType?.toLowerCase() === 'home'
  );
  const caregiverMobilePhone = patient?.patientContact?.[0]?.contact?.contactPhoneNumbers?.find(
    (phone) => phone?.phoneType?.toLowerCase() === 'mobile'
  );

  const primaryInsurance = insurance?.find((x) => x?.insuranceType?.toLowerCase() === 'primary');
  const secondaryInsurance = insurance?.find((x) => x?.insuranceType?.toLowerCase() === 'secondary');
  const tertiaryInsurance = insurance?.find((x) => x?.insuranceType?.toLowerCase() === 'tertiary');
  const otherInsurance = insurance?.find((x) => x?.insuranceType?.toLowerCase() === 'other');

  return {
    [patientFields.firstName]: patient?.firstName,
    [patientFields.middleName]: patient?.middleName,
    [patientFields.lastName]: patient?.lastName,
    [patientFields.id]: patient?.id,
    [patientFields.dob]: defaultDateFormatWithAge(patient?.dob),
    [patientFields.gender]: patient?.gender,
    [patientFields.genderOther]: patient?.gender === 'O' ? patient?.genderOther : null,
    [patientFields.preferredLanguage]: patient?.preferredLanguage,
    [patientFields.email]: patient?.email,
    [patientFields.externalId]: patient?.externalId,
    [patientFields.homeNumber]: homePhone?.phoneNumber,
    [patientFields.mobileNumber]: mobilePhone?.phoneNumber,
    [patientFields.messageOption]: getPatientPhoneMessageOption(homePhone, mobilePhone),
    [patientFields.address1]: patient?.address?.address1,
    [patientFields.city]: patient?.address?.city,
    [patientFields.state]: patient?.address?.state,
    [patientFields.zip]: patient?.address?.zip,
    [patientFields.careGiverFirstName]: patient?.patientContact?.[0]?.contact?.firstName,
    [patientFields.caregiverMiddlName]: patient?.patientContact?.[0]?.contact?.middleName,
    [patientFields.caregiverLastName]: patient?.patientContact?.[0]?.contact?.lastName,
    [patientFields.caregiverPatientRelationship]: patient?.patientContact?.[0]?.relationship,
    [patientFields.caregiverEmail]: patient?.patientContact?.[0]?.contact?.email,
    [patientFields.caregiverHomePhone]: caregiverHomePhone?.phoneNumber,
    [patientFields.caregiverMobilePhone]: caregiverMobilePhone?.phoneNumber,
    [patientFields.caregiverLegallyAuthRep]: patient?.patientContact?.[0]?.legallyAuthRep,
    [diagnosisFields.diagnosisCode]: patient?.patientDiagnosisDetail ?? [],
    [insuranceFields.primaryPlanPayerName]: primaryInsurance?.payerName,
    [insuranceFields.primaryPlanName]: primaryInsurance?.medicalPlanName,
    [insuranceFields.primaryPlanCardholderId]: primaryInsurance?.medicalCardHolderId,
    [insuranceFields.primaryPlanGroupNumber]: primaryInsurance?.medicalGroup,
    [insuranceFields.primaryPlanPhoneNumber]: primaryInsurance?.medicalPhoneNumber,
    [insuranceFields.secondaryPlanPayerName]: secondaryInsurance?.payerName,
    [insuranceFields.secondaryPlanName]: secondaryInsurance?.medicalPlanName,
    [insuranceFields.secondaryPlanCardholderId]: secondaryInsurance?.medicalCardHolderId,
    [insuranceFields.secondaryPlanGroupNumber]: secondaryInsurance?.medicalGroup,
    [insuranceFields.secondaryPlanPhoneNumber]: secondaryInsurance?.medicalPhoneNumber,
    [insuranceFields.tertiaryPlanPayerName]: tertiaryInsurance?.payerName,
    [insuranceFields.tertiaryPlanName]: tertiaryInsurance?.medicalPlanName,
    [insuranceFields.tertiaryPlanCardholderId]: tertiaryInsurance?.medicalCardHolderId,
    [insuranceFields.tertiaryPlanGroupNumber]: tertiaryInsurance?.medicalGroup,
    [insuranceFields.tertiaryPlanPhoneNumber]: tertiaryInsurance?.medicalPhoneNumber,
    [insuranceFields.otherPlanPayerName]: otherInsurance?.payerName,
    [insuranceFields.otherPlanName]: otherInsurance?.medicalPlanName,
    [insuranceFields.otherPlanCardholderId]: otherInsurance?.medicalCardHolderId,
    [insuranceFields.otherPlanGroupNumber]: otherInsurance?.medicalGroup,
    [insuranceFields.otherPlanPhoneNumber]: otherInsurance?.medicalPhoneNumber,
    [insuranceFields.pharmacyPayerName]: primaryInsurance?.pharmacyPlanName,
    [insuranceFields.pharmacyName]: primaryInsurance?.pharmacyPlanName,
    [insuranceFields.pharmacyCardholderId]: primaryInsurance?.pharmacyCardholderID,
    [insuranceFields.pharmacyPhoneNumber]: primaryInsurance?.pharmacyPhoneNumber,
    [insuranceFields.pharmacyRxGroupNumber]: primaryInsurance?.pharmacyGroup,
    [insuranceFields.pharmacyRxBinIin]: primaryInsurance?.pharmacyBINNumber,
    [insuranceFields.pharmacyRxPcn]: primaryInsurance?.pharmacyPCN,
    [insuranceFields.secondaryPharmacyPayerName]: secondaryInsurance?.pharmacyPlanName,
    [insuranceFields.secondaryPharmacyName]: secondaryInsurance?.pharmacyPlanName,
    [insuranceFields.secondaryPharmacyCardholderId]: secondaryInsurance?.pharmacyCardholderID,
    [insuranceFields.secondaryPharmacyPhoneNumber]: secondaryInsurance?.pharmacyPhoneNumber,
    [insuranceFields.secondaryPharmacyRxGroupNumber]: secondaryInsurance?.pharmacyGroup,
    [insuranceFields.secondaryPharmacyRxBinIin]: secondaryInsurance?.pharmacyBINNumber,
    [insuranceFields.secondaryPharmacyRxPcn]: secondaryInsurance?.pharmacyPCN,
    [insuranceFields.tertiaryPharmacyPayerName]: tertiaryInsurance?.pharmacyPlanName,
    [insuranceFields.tertiaryPharmacyName]: tertiaryInsurance?.pharmacyPlanName,
    [insuranceFields.tertiaryPharmacyCardholderId]: tertiaryInsurance?.pharmacyCardholderID,
    [insuranceFields.tertiaryPharmacyPhoneNumber]: tertiaryInsurance?.pharmacyPhoneNumber,
    [insuranceFields.tertiaryPharmacyRxGroupNumber]: tertiaryInsurance?.pharmacyGroup,
    [insuranceFields.tertiaryPharmacyRxBinIin]: tertiaryInsurance?.pharmacyBINNumber,
    [insuranceFields.tertiaryPharmacyRxPcn]: tertiaryInsurance?.pharmacyPCN,
    [insuranceFields.otherPharmacyPayerName]: otherInsurance?.payerName,
    [insuranceFields.otherPharmacyName]: otherInsurance?.pharmacyPlanName,
    [insuranceFields.otherPharmacyCardholderId]: otherInsurance?.pharmacyCardholderID,
    [insuranceFields.otherPharmacyPhoneNumber]: otherInsurance?.pharmacyPhoneNumber,
    [insuranceFields.otherPharmacyRxGroupNumber]: otherInsurance?.pharmacyGroup,
    [insuranceFields.otherPharmacyRxBinIin]: otherInsurance?.pharmacyBINNumber,
    [insuranceFields.otherPharmacyRxPcn]: otherInsurance?.pharmacyPCN,
    [copayFields.memberId]: patient?.copayCard?.id,
    [copayFields.groupNumber]: patient?.copayCard?.groupID,
    [copayFields.rxBin]: patient?.copayCard?.bin,
    [copayFields.rxPcn]: patient?.copayCard?.pcn
  };
};
