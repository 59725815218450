import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import {
  ArrowRightCircleFill,
  FileEarmarkTextFill,
  FileMedicalFill,
  FolderFill,
  HouseFill,
  Truck
} from 'react-bootstrap-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { extractCaseUrl } from '../utils';
import { FeatureGuard } from './multi-tenant';
import { AppRootState } from '../redux';
import { RxAdminIcon } from './custom-icons';

import './PatientDetailSidebarNav.scss';
import { AnyType } from '../interfaces';
import { PatientProfileContext } from './patient-profile-context';

const summaryEventKey = '#summary';
const casesEventKey = '#cases';
const documentsEventKey = '#documents';
const dispensesEventKey = '#dispenses';
const requestsEventKey = '#requests';
const claimsEventKey = '#claims';
const rxAdminEventKey = '#rxadmin';

const availableTabs = [
  summaryEventKey,
  casesEventKey,
  dispensesEventKey,
  documentsEventKey,
  requestsEventKey,
  claimsEventKey,
  rxAdminEventKey
];

export const PatientDetailSideBarNav = () => {
  const history = useHistory();
  const { setSelectedRequestInfo } = useContext(PatientProfileContext);
  const isRxAdminSidebar = useSelector(
    (state: AppRootState) => (((state.app?.entities?.features as AnyType) ?? {})['rxadmin' ?? ''] as AnyType)?.data
  );
  const RxAdminSideBarNav = () => {
    if (isRxAdminSidebar?.enabled === true) {
      return (
        <Nav.Item className='cmx__patient-detail-sidebar-nav__nav-item'>
          <Nav.Link
            eventKey={rxAdminEventKey}
            className={`cmx__patient-detail-sidebar-nav${
              defaultActiveKey === rxAdminEventKey ? '__nav-link-selected' : '__nav-link-unselected'
            }`}>
            <RxAdminIcon />
            <span className='cmx__patient-detail-sidebar-nav__nav-text'>RX ADMIN</span>
          </Nav.Link>
        </Nav.Item>
      );
    }
    return null;
  };

  const hashValue: string | undefined = extractCaseUrl(useLocation())?.hashValue;
  const defaultActiveKey = hashValue && availableTabs.includes(hashValue) ? hashValue : casesEventKey;

  const onTabSelect = (eventKey: string | null) => {
    setSelectedRequestInfo(null);
    if (!eventKey) return;
    history.push(eventKey);
  };

  return (
    <Nav
      variant='tabs'
      className='cmx__patient-detail-sidebar-nav flex-column'
      defaultActiveKey={defaultActiveKey}
      onSelect={onTabSelect}>
      <Nav.Item>
        <Nav.Link
          eventKey={summaryEventKey}
          className={`cmx__patient-detail-sidebar-nav${
            defaultActiveKey === summaryEventKey ? '__nav-link-selected' : '__nav-link-unselected'
          }`}>
          <HouseFill size={20} />
          <span className='cmx__patient-detail-sidebar-nav__nav-text'>OVERVIEW</span>
        </Nav.Link>
      </Nav.Item>
      <FeatureGuard contentKey='case-journey.manage'>
        <Nav.Item className='cmx__patient-detail-sidebar-nav__nav-item'>
          <Nav.Link
            eventKey={casesEventKey}
            className={`cmx__patient-detail-sidebar-nav${
              defaultActiveKey === casesEventKey ? '__nav-link-selected' : '__nav-link-unselected'
            }`}>
            <FolderFill size={20} />
            <span className='cmx__patient-detail-sidebar-nav__nav-text'>CASES</span>
          </Nav.Link>
        </Nav.Item>
      </FeatureGuard>
      <FeatureGuard contentKey='service-request.manage'>
        <Nav.Item className='cmx__patient-detail-sidebar-nav__nav-item'>
          <Nav.Link
            eventKey={requestsEventKey}
            className={`cmx__patient-detail-sidebar-nav${
              defaultActiveKey === requestsEventKey ? '__nav-link-selected' : '__nav-link-unselected'
            }`}>
            <ArrowRightCircleFill size={20} />
            <span className='cmx__patient-detail-sidebar-nav__nav-text'>REQUESTS</span>
          </Nav.Link>
        </Nav.Item>
      </FeatureGuard>
      <Nav.Item className='cmx__patient-detail-sidebar-nav__nav-item'>
        <Nav.Link
          eventKey={documentsEventKey}
          className={`cmx__patient-detail-sidebar-nav${
            defaultActiveKey === documentsEventKey ? '__nav-link-selected' : '__nav-link-unselected'
          }`}>
          <FileEarmarkTextFill size={20} />
          <span className='cmx__patient-detail-sidebar-nav__nav-text'>DOCUMENTS</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className='cmx__patient-detail-sidebar-nav__nav-item'>
        <Nav.Link
          eventKey={dispensesEventKey}
          className={`cmx__patient-detail-sidebar-nav${
            defaultActiveKey === dispensesEventKey ? '__nav-link-selected' : '__nav-link-unselected'
          }`}>
          <Truck size={20} />
          <span className='cmx__patient-detail-sidebar-nav__nav-text'>DISPENSES</span>
        </Nav.Link>
      </Nav.Item>
      <RxAdminSideBarNav />
    </Nav>
  );
};
