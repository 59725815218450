import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';
import { CustomThemes } from '../utils/custom-themes';
import { ContactUsRequirement } from '../components/ContactUsRequirement';
import {
  AuthLayout,
  HcpFormRenderer,
  HcpFormTemplate,
  PublicLayout,
  ResourceAuthLayoutHeader,
  ResourceContent
} from '../components';
import { AppRootState, login } from '../redux';
import { AnyType, GeneralObject } from '../interfaces';
import { clientApplicationNameSelector, getLoginFormSchema, ROUTE_PATHS } from '../utils';
import image from '../images/img_Hero.jpg';
import { API_URL, LoginUserRequest, useLoginUser } from '../api';
import { FORM_ERROR } from 'final-form';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';

export const LoginView = () => {
  CustomThemes();
  const history = useHistory();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const [isReset] = useState(hash === '#reset');
  const applicationName: any = useSelector(clientApplicationNameSelector);
  const { mutate: loginUser } = useLoginUser({});
  const isLoginImage = useSelector(
    (state: AppRootState) => ((state.app?.entities?.resources as AnyType) ?? {})['login.image'] as AnyType
  );

  const onSubmit = async (values: GeneralObject) => {
    try {
      const result = await loginUser({
        userName: String(values.username),
        password: String(values.password),
        applicationName: applicationName
      });

      if (result != null && result.loginResponse != null) {
        dispatch(login(result.loginResponse));
      }
    } catch (e) {
      return { [FORM_ERROR]: e.message };
    }
  };

  useEffect(() => {
    if (isReset) history.replace(ROUTE_PATHS.login);
  }, [isReset, history]);

  return (
    <PublicLayout fluid className='authlayout_custom_image'>
      <AuthLayout image={isLoginImage?.data?.url}>
        <ResourceAuthLayoutHeader contentKey='login.title'>
          {isReset && (
            <>
              <Row className='mb-4'>
                <Col>
                  <h2 className='text-secondary'>
                    <CheckCircleFill /> SUCCESS
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <ResourceContent contentKey='reset-password.success' />
                  </p>
                </Col>
              </Row>
            </>
          )}
          <p>
            <ResourceContent contentKey='login.body' />
          </p>
        </ResourceAuthLayoutHeader>

        <HcpFormRenderer
          contentKey='form.login'
          FormTemplate={LoginFormTemplate}
          noErrorMessage
          onSubmit={onSubmit}
          schemaMethod={getLoginFormSchema}
        />
      </AuthLayout>
    </PublicLayout>
  );
};

const LoginFormTemplate = (props: FormTemplateRenderProps) => {
  const { authError } = useSelector((state: AppRootState) => state.auth);
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const formState = formProps.getState();
        const { submitting, hasValidationErrors } = formState;
        return (
          <Row>
            {authError && <span className='text-danger'>{authError}</span>}
            <Col xs={12} className='text-center'>
              <Link
                to={ROUTE_PATHS.forgotPassword}
                className='text-secondary fs-2'
                data-testid='cmx__login__forgot-password'>
                <ResourceContent contentKey='general.forgot-password-link' />
              </Link>
            </Col>
            <Col xs={12} className='text-center my-3 text-white'>
              <Button
                type='submit'
                variant='secondary'
                disabled={submitting || hasValidationErrors}
                data-testid='cmx__login-button'>
                {submitting && !authError ? 'SUBMITTING...' : 'LOGIN'}
              </Button>
            </Col>
            <Col xs={12} className='text-center'>
              <p className='fs-2 text-custom-50'>
                <ResourceContent contentKey='general.register-teaser' />{' '}
                <Link
                  to={ROUTE_PATHS.registrationWelcome}
                  className='text-secondary'
                  data-testid='cmx__login__register'>
                  REGISTER
                </Link>
              </p>
            </Col>
            <FeatureGuard contentKey={'login_contact_us'}>
              <Col xs={12} className='text-center'>
                <p className='fs-2'>
                  <ContactUsRequirement arrow='arrow-left' top='-95px' left='227px' />
                </p>
              </Col>
            </FeatureGuard>
          </Row>
        );
      }}
    />
  );
};
