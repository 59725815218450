import React, { createRef, useEffect, useState } from 'react';
import {
  OrganizationAddressFragment,
  useOrganizationAddressInfoLazyQuery,
  useOrganizationAddressInfoQuery
} from '../graphql';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { SearchInputWithGql, SearchInputWithGqlDisplay } from './SearchInputWithGql';
import { FIELD_NAMES, formatContactNumber } from '../utils';
import { AnyObject } from 'final-form';
import { AddLocationView } from '../views';
import { AnyType } from '../interfaces';
import { useForm } from 'react-final-form';

const { prescriberFields } = FIELD_NAMES;

export const AddSearchLocation = (props: AnyObject) => {
  const form = useForm();
  const ref = createRef<HTMLDivElement>();

  const [locationId, setLocationId] = useState<any>(null);
  const [providerDefaultLocation, setProviderDefaultLocation] = useState<any>(null);
  const organizationId = props?.organizationId || '';
  const { data, loading, refetch } = useOrganizationAddressInfoQuery({ variables: { organizationId } });
  const [locationAdded, setLocationAdded] = useState(false);

  useEffect(() => {
    if (organizationId && locationId) {
      refetch();
    }
  }, [locationId]);

  useEffect(() => {
    if (!locationAdded) {
      setLocationId(providerDefaultLocation?.organizationId);
    }
  }, [providerDefaultLocation]);

  useEffect(() => {
    if (!locationAdded) {
      setProviderDefaultLocation(props?.defaultLocation);
    }
  }, [props.defaultLocation, data]);

  useEffect(() => {
    if (locationId && data) {
      props.onLocationSelect(data?.result?.find((p: AnyType) => locationId?.toString() === p?.id));
    }

    if (locationAdded) {
      const location = data?.result?.find((p: AnyType) => locationId?.toString() === p?.id);
      setProviderDefaultLocation(location);
    }
  }, [data, locationId]);

  const toggleAddInitialValue = !!(
    form.getState().values['adding_location'] || form.getState().values['prescriber_location_id']
  );
  const [toggleAdd, setToggleAdd] = useState<boolean>(toggleAddInitialValue);

  const toggleReadFields = () => {
    if (form.getState().values['adding_location'] === false) {
      form.change(prescriberFields.locationId, '');
      form.change(prescriberFields.locationAddress1, '');
      form.change(prescriberFields.locationAddress2, '');
      form.change(prescriberFields.locationPhone2, '');
      form.change(prescriberFields.locationAddressZip, '');
      form.change(prescriberFields.locationAddressCity, '');
      form.change(prescriberFields.locationAddressState, '');
      form.change(prescriberFields.locationPhone1, '');
      form.change(prescriberFields.locationFaxNumber, '');
      form.change(prescriberFields.locationAddressFull, '');
    }
  };

  const onLocationSelect = (location: OrganizationAddressFragment | null) => {
    form.batch(() => {
      form.change('adding_location', false);
      setToggleAdd(false);
      form.change(prescriberFields.locationId, location?.id);
      form.change(prescriberFields.locationAddress1, location?.address1);
      form.change(prescriberFields.locationAddress2, location?.address2);
      form.change(prescriberFields.locationAddressZip, location?.zip);
      form.change(prescriberFields.locationAddressCity, location?.city);
      form.change(prescriberFields.locationAddressState, location?.state);
      form.change(prescriberFields.locationPhone1, formatContactNumber(location?.phone1 ?? location?.phone2));
      form.change(prescriberFields.locationFaxNumber, formatContactNumber(location?.fax));
      form.change(
        prescriberFields.locationAddressFull,
        `${location?.address1} ${location?.address2 ?? ''} | ${location?.city}${
          location?.state || location?.zip ? ', ' : ''
        } ${location?.state ?? ''} ${location?.zip ?? ''}`
      );
    });
    props.onLocationSelect(location);
  };
  const onToggle = () => {
    setToggleAdd((prevToggle: boolean) => {
      form.change('adding_location', !prevToggle);
      form.change(prescriberFields.isNewPrescriber, !prevToggle);
      toggleReadFields();
      if (form.getState().values['adding_location']) {
        setLocationId(null);
      }
      return !prevToggle;
    });
  };
  const renderLabel = (option: OrganizationAddressFragment): string => `${option?.address1}`;

  const getAddedLocation = () => {
    const defaultLocation =
      data?.result?.filter(
        (p: AnyType) => (providerDefaultLocation?.organizationAddressId || providerDefaultLocation?.id) == p?.id
      ) || [];
    if (!props?.providerLocation) {
      props.setProviderLocation(defaultLocation?.[0]);
    }
    return defaultLocation;
  };

  const addedNewLocation = (locationId: number | null | undefined) => {
    setLocationAdded(true);
    setLocationId(locationId);
    onToggle();
  };

  return (
    <>
      <Form.Group {...props.sizes}>
        <p className='fs-4 font-weight-bold text-primary mt-3 mb-4 text-uppercase'>Which provider location?</p>
        <Row>
          <Col className='mb-3'>
            <Row>
              <Col className='flex-nowrap w-75'>
                {locationId &&
                  data?.result &&
                  !loading &&
                  data?.result.filter(
                    (p: AnyType) =>
                      (providerDefaultLocation?.organizationAddressId || providerDefaultLocation?.id) === p?.id
                  ).length > 0 && (
                    <SearchInputWithGql
                      asyncTypeaheadProps={{
                        ...props.asyncTypeaheadProps,
                        ref,
                        defaultSelected: data?.result ? getAddedLocation() : []
                      }}
                      display={SearchInputWithGqlDisplay.outlined}
                      labelRenderer={renderLabel}
                      queryHook={useOrganizationAddressInfoLazyQuery}
                      onItemSelect={onLocationSelect}
                      placeholder='Search Location'
                      isDisabled={props?.isDisabled}
                    />
                  )}
                {!locationId && (
                  <SearchInputWithGql
                    asyncTypeaheadProps={{
                      ref
                    }}
                    display={SearchInputWithGqlDisplay.outlined}
                    labelRenderer={renderLabel}
                    queryHook={useOrganizationAddressInfoLazyQuery}
                    onItemSelect={onLocationSelect}
                    placeholder='Search Location'
                  />
                )}
              </Col>
              <Col>
                <Button
                  variant='outline-secondary'
                  className='ml-2 text-nowrap'
                  onClick={onToggle}
                  disabled={props?.isDisabled}>
                  {toggleAdd ? 'CANCEL' : 'ADD A LOCATION'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>
      {form.getState().values['adding_location'] && (
        <>
          <AddLocationView
            isStartRequestPage={true}
            patientId={props.patientId}
            addedNewLocation={addedNewLocation}
            prescriberId={props.prescriberId}
          />
        </>
      )}
    </>
  );
};
