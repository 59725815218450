import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { RequestsTable, PrivateLayout } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { navigateToServiceRequest } from '../redux';

export const RequestsView = () => {
  const dispatch = useDispatch();

  const renderButton = () => {
    return (
      <FeatureGuard contentKey='service-request.manage'>
        <Button variant='secondary' size='sm' onClick={() => dispatch(navigateToServiceRequest())}>
          START A NEW REQUEST
        </Button>
      </FeatureGuard>
    );
  };

  return (
    <PrivateLayout pageTitle='requests' subtoolbarChildren={renderButton()}>
      <RequestsTable enableExternalFilters />
    </PrivateLayout>
  );
};
