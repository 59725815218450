import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, FieldRenderProps } from 'react-final-form';
import { OrganizationNpiFragment, useOrganizationNpiLazyQuery } from '../graphql';
import { NpiSearch, NpiSearchProps } from './NpiSearch';

interface NpiSearchOrganizationFieldProps extends Partial<NpiSearchProps> {
  name?: string;
  label?: string;
}

const renderLabel = (option: OrganizationNpiFragment): string => {
  return `${option.npi} | ${option.organizationName}`;
};

export const NpiSearchOrganizationField = (props: NpiSearchOrganizationFieldProps) => {
  return (
    <Field name={props.name ?? 'organization_npi'}>
      {(fieldRenderProps: FieldRenderProps<string[]>) => (
        <>
          {props.label && <Form.Label className='text-uppercase'>{props.label}</Form.Label>}
          <NpiSearch
            {...(props as NpiSearchProps)}
            asyncTypeaheadProps={{
              ...props.asyncTypeaheadProps,
              defaultInputValue: String(fieldRenderProps.input.value),
              allowNew: props.asyncTypeaheadProps?.allowNew ?? true,
              inputProps: {
                ...props.asyncTypeaheadProps?.inputProps,
                'data-testid': fieldRenderProps.input.name
              }
            }}
            fieldRenderProps={fieldRenderProps}
            labelRenderer={renderLabel}
            queryHook={useOrganizationNpiLazyQuery}
          />
        </>
      )}
    </Field>
  );
};
