import React, { useContext, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AnyType } from '../../interfaces';
import { CONSENT_MODAL_LABEL, CONSENT_STATUS_ON_FILE_ENABLED, EMAIL_SMS_CONSENT_MODES } from '../../utils';
import ConsentStatusC from './ConsentStatus';
import ErrorMessage from './ErrorMessage';
import { ConsentContext } from './context';
import { ConsentStatusEnum, ConsentType, EmailSmsConsentMode } from './types';

type EmailSmsConsentProps = {
  type: string;
};

export default function EmailSmsConsent(props: EmailSmsConsentProps) {
  const { type } = props;
  const {
    consentMode,
    updateEmailSmsConsentMode: updateConsentMode,
    consentStatus,
    updateChannelValue,
    updateConsentStatus,
    checkConsentStatus,
    sendInvite,
    showCheckStatus,
    patientContactInfo
  } = useContext(ConsentContext);

  const isCheckInProgress = useMemo(() => consentStatus === ConsentStatusEnum.CHECK_INPROGRESS, [consentStatus]);

  const isSendDisabled = useMemo(
    () => consentMode === EmailSmsConsentMode.DEFAULT || consentStatus === ConsentStatusEnum.INVITE_INPROGRESS,
    [consentMode, consentStatus]
  );

  const inviteButtonLabel = useMemo(() => {
    switch (consentStatus) {
      case ConsentStatusEnum.PENDING:
        return CONSENT_MODAL_LABEL.SEND_INVITATION;
      case ConsentStatusEnum.INVITE_INPROGRESS:
        return CONSENT_MODAL_LABEL.SENDING_INVITATION;
      case ConsentStatusEnum.INVITESENT:
      case ConsentStatusEnum.CHECK_INPROGRESS:
        return CONSENT_MODAL_LABEL.RESEND_INVITATION;
      default:
        return CONSENT_MODAL_LABEL.SEND_INVITATION;
    }
  }, [consentStatus]);

  const contactInfoByType = useMemo(() => {
    let contactInfo: AnyType = {
      patient: '',
      caregiver: ''
    };
    switch (type) {
      case ConsentType.EMAIL:
        contactInfo = {
          patient: patientContactInfo.Email.patient,
          caregiver: patientContactInfo.Email.caregiver
        };
        break;
      case ConsentType.SMS:
        contactInfo = {
          patient: patientContactInfo.Sms.patient,
          caregiver: patientContactInfo.Sms.caregiver
        };
        break;
      default:
        break;
    }
    return contactInfo;
  }, [patientContactInfo, type]);

  const isConsentSuccess = useMemo(() => consentStatus === ConsentStatusEnum.SUCCESS, [consentStatus]);

  const actionsJustifyPos = useMemo(() => {
    return isConsentSuccess ? 'end' : 'between';
  }, [isConsentSuccess]);

  return (
    <div className='d-flex flex-column'>
      {!isConsentSuccess && (
        <div className='d-flex cmx__request_consent-modal__div-request-options'>
          {EMAIL_SMS_CONSENT_MODES[type].map((item) => {
            const isOptionDisabled = !contactInfoByType[item.contactPerson];
            return (
              <Form.Check
                className={`d-flex align-items-center cmx__request_consent-modal__Check-option ${
                  isOptionDisabled ? 'disabled' : ''
                } `}
                key={item.value}
                disabled={isOptionDisabled}
                checked={consentMode === item.value}
                type='radio'
                id={`option-${item.value}`}
                label={item.label}
                onChange={() => {
                  if (!isConsentSuccess) {
                    updateConsentStatus(ConsentStatusEnum.PENDING);
                  }
                  updateConsentMode(item.value);
                  updateChannelValue(contactInfoByType[item.contactPerson]);
                }}
              />
            );
          })}
        </div>
      )}
      <ErrorMessage />
      <div
        className={`d-flex justify-content-${actionsJustifyPos} ${
          isConsentSuccess ? 'align-items-center' : ''
        } cmx_consent_status`}>
        {!isConsentSuccess && (
          <>
            <Button
              disabled={isSendDisabled || isCheckInProgress}
              size='sm'
              className='px-3 py-1 mr-3 button_font-12 cmx_consent_status__button-invite'
              variant='outline-secondary'
              onClick={() => {
                sendInvite();
              }}>
              {inviteButtonLabel}
            </Button>
            {showCheckStatus && (
              <Button
                size='sm'
                disabled={isSendDisabled || isCheckInProgress}
                className='px-3 mr-3 py-1 button_font-12 cmx_consent_status__button-invite'
                variant='outline-secondary'
                onClick={checkConsentStatus}>
                {isCheckInProgress ? CONSENT_MODAL_LABEL.CHECKING_STATUS : CONSENT_MODAL_LABEL.CHECK_STATUS}
              </Button>
            )}
          </>
        )}
        {CONSENT_STATUS_ON_FILE_ENABLED && <ConsentStatusC />}
      </div>
    </div>
  );
}
