import React, { useEffect, useState } from 'react';
import { Badge, Col, Row, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { FiltersButton, StatusDescription } from '.';
import { extractCaseUrl, setToArray } from '../utils';
import { GridFilterDropdown } from './GridFilterDropdown';
import { XCircleFill } from 'react-bootstrap-icons';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { AnyType } from '../interfaces';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux/reducers';
interface RequestsFiltersProps {
  onFilterChange: (filter: string) => void;
  onFilterOptionChange: (filter: Set<string>) => void;
  filterName: string;
  filterOptions: AnyType;
  hideDropDownFilter: boolean;
}

const all = 'all';
const actions = 'actions';
const updates = 'updates';

const availableFilters = [all, actions, updates];

export const GridFilters = (props: RequestsFiltersProps) => {
  const { filterOptions } = props;
  const { hash } = useLocation();
  const history = useHistory();
  const extractedUrl = extractCaseUrl(useLocation());
  const [pills, setPills] = useState<Set<string>>(new Set(extractedUrl?.filters));
  const filter = ((hash && availableFilters.find((f) => hash.includes(f))) || null) ?? all;
  const queryVariables = {
    programId: useSelector((state: AppRootState) => state.app.config?.programId) || 0,
    organizationId: parseInt(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId || ''))
  };

  const [selectedFilter, setSelectedFilter] = useState(filter);

  const onFilterChange = (newFilter: string) => {
    history.push(`#${newFilter}`);
    setSelectedFilter(newFilter);
    props.onFilterChange(newFilter);
  };

  const onFilterOptionChange = (newFilters: Set<string>) => {
    const newUrlFitlers: Set<string> = new Set(newFilters);
    history?.push(`${extractedUrl?.hashValue}?filters=${setToArray(newUrlFitlers)?.join(',')}`);
    props.onFilterOptionChange(newFilters);
  };

  useEffect(() => {
    if (!pills || pills?.size <= 0) return;
    props.onFilterOptionChange(pills);
  }, [props.onFilterOptionChange, pills]);

  useEffect(() => {
    if (!selectedFilter) return;

    props.onFilterChange(selectedFilter);
  }, [props.onFilterChange, selectedFilter]);

  const renderFilterButton = (label: string, value: string) => {
    const variant = selectedFilter === value ? 'secondary' : 'info';
    return (
      <FiltersButton variant={variant} value={value} data-testid={`cmx__filter-button__${value}`}>
        {label}
      </FiltersButton>
    );
  };

  const disMiss = (selectedFilter: string) => {
    const newSelectedFilters: Set<string> = new Set(pills);
    newSelectedFilters?.delete(selectedFilter);
    setPills(newSelectedFilters);
    onFilterOptionChange(newSelectedFilters);
  };

  const disMissAll = () => {
    const removeAllFilters: Set<string> = new Set();
    setPills(removeAllFilters);
    onFilterOptionChange(removeAllFilters);
  };

  const renderFilterOptions = (e: string | null) => {
    if (e) {
      const newSelectedFilters: Set<string> = new Set(pills);
      newSelectedFilters?.add(e);
      setPills(newSelectedFilters);
      onFilterOptionChange(new Set(newSelectedFilters));
    }
  };

  return (
    <>
      <Row className='mb-3 align-items-center'>
        <Col>
          <ToggleButtonGroup name='requests-filters' type='radio' onChange={onFilterChange}>
            {renderFilterButton('ALL', all)}
            {renderFilterButton(`${props?.filterName} WITH ACTIONS`, actions)}
            {renderFilterButton(`${props?.filterName} WITH UPDATES`, updates)}
          </ToggleButtonGroup>
        </Col>
        {!props?.hideDropDownFilter && (
          <Col md={2}>
            <GridFilterDropdown
              filterOptions={
                filterOptions.length > 0
                  ? filterOptions
                  : [{ __typename: 'StatusDescription', statusDescription: 'No Filters available' }]
              }
              renderFilterOptions={(e: string | null) => renderFilterOptions(e)}
            />
          </Col>
        )}
      </Row>
      {!props?.hideDropDownFilter && (
        <Row className='mb-3 align-items-center'>
          <Col>
            {pills?.size > 0 &&
              setToArray(pills)?.map((pill, index) => (
                <Badge className='filter__pill' variant='primary' key={`${pill},${index}`}>
                  {pill} <XCircleFill onClick={() => disMiss(pill)} />
                </Badge>
              ))}
            {pills?.size > 0 && (
              <Badge
                variant='secondary'
                style={{ float: 'right', cursor: 'default', marginTop: '4px' }}
                onClick={() => disMissAll()}>
                Clear All
              </Badge>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
