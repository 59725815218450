import hljs from 'highlight.js';
import React from 'react';

type HighlightProps = { [key: string]: any };

class Highlight extends React.Component<HighlightProps> {
  static defaultProps: HighlightProps = {
    innerHTML: false,
    className: '',
    element: null
  };

  el: any;

  constructor(props: HighlightProps) {
    super(props);

    this.setEl = this.setEl.bind(this);
  }
  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    const nodes = this.el.querySelectorAll('pre code');

    for (let i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i]);
    }
  }

  setEl(e: any) {
    this.el = e;
  }

  render() {
    const { children, className, element: Element, innerHTML } = this.props as any;
    const props = { ref: this.setEl, className };

    if (innerHTML) {
      if (Element) {
        return <Element {...props} dangerouslySetInnerHTML={{ __html: children }} />;
      }

      return <div {...props} dangerouslySetInnerHTML={{ __html: children }} />;
    }

    if (Element) {
      return <Element {...props}>{children}</Element>;
    }

    return (
      <pre ref={this.setEl}>
        <code
          className={className}
          style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 12, paddingRight: 12, whiteSpace: 'pre-wrap' }}>
          {children}
        </code>
      </pre>
    );
  }
}

export default Highlight;
