import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Drug } from '../api/portal-config.generated';
import { LoadingIndicatorRow, PrivateLayout } from '../components';
import EnrollmentModule from '../components/enrollment-module';
import { buildRequestPayload, EnrollmentModulePayloadProps } from '../components/enrollment-module/data';
import {
  OrganizationAddressFragment,
  PatientInsuranceFragment,
  PhysicianFragment,
  usePatientInfoQuery,
  GRAPHQL_DEFAULT_CACHE,
  usePatientInsuranceQuery,
  PatientInsurance,
  usePhysicianInfoQuery
} from '../graphql';
import { AppRootState, useTenancyDrugListSelector } from '../redux';
import './CaseDetail.scss';
import { clientApplicationNameSelector, ROUTE_PATHS } from '../utils';
import { AnyObject } from 'final-form';
import { AnyType } from '../interfaces';
import { getProgramIdFromStore } from '../redux/helpers/getProgramId';

export type EnrollmentModuleViewState = {
  patientId: string;
  drug?: Drug | null;
  prescriber?: PhysicianFragment;
  location?: OrganizationAddressFragment;
  insurance?: Array<PatientInsurance> | undefined;
  prescription?: AnyObject | null | undefined;
  redirectUrl?: { cancelUrl?: string; finishUrl?: string; goBackUrl?: string };
};

export const EnrollmentModuleView = (props: EnrollmentModuleViewState) => {
  const isEnrollmentPage = window?.location?.pathname?.includes('/enrollmentModule');
  let state = isEnrollmentPage ? useLocation<EnrollmentModuleViewState>()?.state : props;
  const user = useSelector((state: AppRootState) => state.auth.session?.user);
  const userDetail = useSelector((state: AppRootState) => state.auth.session?.userDetails);
  const orgDetail = useSelector((state: AppRootState) => state.auth?.session?.orgDetails);
  const userInfo = useSelector((state: AppRootState) => state.auth.session?.userInfo);
  const drugList: Drug[] = useTenancyDrugListSelector();
  const features = useSelector((state: AppRootState) => state.app?.entities?.features as AnyType);
  const enrollment_service_id =
    useSelector(
      (state: AppRootState) =>
        (((state.app?.entities?.features as AnyType) ?? {})['service-request.all' ?? ''] as AnyType)?.data
          ?.enrollmentServiceId
    ) ?? '';
  sessionStorage.setItem('drugList', JSON.stringify(drugList));
  /*
   * Short term fix to pass Standard Portals Drug JCode value as JCMTX
   * as Enrollment Module takes this value for Standard Portal only
   *
   * Needs to be removed and fixed
   * */
  state =
    useSelector(clientApplicationNameSelector)?.toLowerCase() === 'biopharmaproviderportal'
      ? {
          ...state,
          drug: {
            ...state.drug,
            JCode1: state?.drug?.Jcode2
          }
        }
      : state;

  const application = useSelector((state: AppRootState) => state.app?.config?.domainURL);

  const { data: patientData } = usePatientInfoQuery({ variables: { patientId: state?.patientId } });

  const [loading, setLoading] = useState<any>(null);
  const [prescriberData, setPrescriberData] = useState<any>(null);
  const { data: prescriberResult, loading: loadingPrescriber, refetch: refetchPrescriber } = usePhysicianInfoQuery({
    variables: { npi: state?.prescriber?.npi || '', programId: getProgramIdFromStore() }
  });

  useEffect(() => {
    setLoading(loadingPrescriber);
  }, [loadingPrescriber]);

  const { data: patientInsuranceResult, error: patientInsuranceFetchError } = usePatientInsuranceQuery({
    variables: { patientId: state?.patientId },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });
  const getPatientInsuranceDetails = (): Array<PatientInsurance> | undefined => {
    if (patientInsuranceFetchError || !patientInsuranceResult?.result) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return patientInsuranceResult?.result;
  };

  useEffect(() => {
    setPrescriberData(prescriberResult?.result);
  }, [prescriberResult]);

  if (!patientData?.result || loading) return <LoadingIndicatorRow />;

  sessionStorage.setItem('physicianId', state?.prescriber?.id ?? '0');
  const enrollmentPayloadProps: EnrollmentModulePayloadProps = {
    user: user,
    userDetail: userDetail,
    userInfo: userInfo,
    patient: patientData?.result,
    drug: state?.drug,
    prescriber: prescriberData ?? state?.prescriber,
    insurance: getPatientInsuranceDetails() ?? state?.insurance,
    orgDetail: orgDetail,
    location: state?.location,
    prescription: state?.prescription,
    redirectUrl: state?.redirectUrl,
    service: enrollment_service_id
  };
  const pId: any = enrollmentPayloadProps?.patient?.id;
  sessionStorage.setItem('prescriberId', enrollmentPayloadProps?.prescriber?.id || '');
  sessionStorage.setItem('locationId', enrollmentPayloadProps?.location?.id || '');
  sessionStorage.setItem('patientId', pId ?? '');
  if (!isEnrollmentPage && features) {
    return <EnrollmentModule payload={buildRequestPayload(enrollmentPayloadProps)} />;
  }

  return (
    <PrivateLayout pageTitle='case details' disableViewContainer={true}>
      <div className='cmx__case-detail-view-container'>
        {features && <EnrollmentModule payload={buildRequestPayload(enrollmentPayloadProps)} />}
      </div>
    </PrivateLayout>
  );
};
