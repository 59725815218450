import React, { Fragment, useCallback } from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import { FieldRenderProps } from 'react-final-form';
import { displayError } from '.';
import { AnyType } from '../interfaces';
import {
  SearchInputWithGql,
  SearchInputWithGqlDisplay,
  SearchInputWithGqlIconLocation,
  SearchInputWithGqlProps
} from './SearchInputWithGql';

export interface NpiSearchProps extends Partial<SearchInputWithGqlProps> {
  fieldRenderProps: FieldRenderProps<string[]>;
  labelRenderer: string | ((option: AnyType) => string);
  onNpiSelect: (data: AnyType) => void;
  queryHook: (baseOptions: AnyType) => AnyType;
}

export interface NpiSearchRecord {
  npi: string;
}

export const NpiSearch = ({
  display = SearchInputWithGqlDisplay.outlined,
  fieldRenderProps,
  labelRenderer,
  ...props
}: NpiSearchProps) => {
  const renderMenuItemChildren = useCallback(
    (option, { text }, index) => {
      const label = labelRenderer && typeof labelRenderer === 'function' && labelRenderer(option);
      return (
        <Fragment key={index}>
          <Highlighter search={text}>{label ?? option.npi}</Highlighter>
        </Fragment>
      );
    },
    [labelRenderer]
  );

  const innerItemSelect = useCallback(
    (option: AnyType) => {
      fieldRenderProps?.input?.onChange(null);
      if (!props.onNpiSelect || !option) return;

      let npi = {} as NpiSearchRecord;

      if (option.customOption) {
        npi.npi = option.npi;
      } else {
        npi = option;
      }

      props.onNpiSelect(npi);
    },
    [props.onNpiSelect]
  );

  function handleOnBlur(e: Event) {
    fieldRenderProps?.input?.onBlur();
    props.onNpiSelect({ npi: (e.target as HTMLInputElement)?.value });
  }

  return (
    <>
      <SearchInputWithGql
        {...props}
        asyncTypeaheadProps={{
          ...props.asyncTypeaheadProps,
          allowNew: props.asyncTypeaheadProps?.allowNew ?? true,
          id: fieldRenderProps.input.name,
          inputProps: {
            ...props.asyncTypeaheadProps?.inputProps,
            maxLength: 10,
            name: fieldRenderProps.input.name
          },
          isInvalid: fieldRenderProps.meta.touched && fieldRenderProps.meta.invalid,
          isValid: fieldRenderProps.meta.touched && fieldRenderProps.meta.valid,
          newSelectionPrefix: 'Use NPI: ',
          renderMenuItemChildren,
          onBlur: handleOnBlur
        }}
        display={display}
        labelRenderer={props.asyncTypeaheadProps?.allowNew === true ? 'npi' : labelRenderer}
        minLength={5}
        onItemSelect={innerItemSelect}
        organizationIdRequired={false}
        placeholder='Type 5 numbers to search'
        programIdRequired={false}
        queryHook={props.queryHook}
        queryOptionsOnSearch={(queryOptions, searchTerm) => ({
          ...queryOptions,
          variables: {
            ...queryOptions.variables,
            npi: searchTerm
          }
        })}
        searchIconLocation={SearchInputWithGqlIconLocation.none}
      />
      {displayError(fieldRenderProps, false)}
    </>
  );
};
