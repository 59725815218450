import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
const defaultOptions = {};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: any;
  PaginationAmount: any;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
  Uuid: any;
};

export type AddEHipaaInviteInput = {
  readonly drugName?: Maybe<Scalars['String']>;
  readonly enrollmentId: Scalars['Uuid'];
  readonly inviteId: Scalars['Long'];
  readonly pafId: Scalars['Long'];
  readonly patientId: Scalars['Uuid'];
  readonly status?: Maybe<Scalars['String']>;
  readonly userName?: Maybe<Scalars['String']>;
};

export type Appeal = {
  readonly __typename?: 'Appeal';
  readonly appealCompletedDate?: Maybe<Scalars['DateTime']>;
  readonly appealContactAddress?: Maybe<Scalars['String']>;
  readonly appealContactFaxNumber?: Maybe<Scalars['String']>;
  readonly appealContactName?: Maybe<Scalars['String']>;
  readonly appealContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly appealCreatedDate?: Maybe<Scalars['DateTime']>;
  readonly appealDenialDate?: Maybe<Scalars['DateTime']>;
  readonly appealDenialReasonOther?: Maybe<Scalars['String']>;
  readonly appealDenialReasons?: Maybe<Scalars['String']>;
  readonly appealInitiated?: Maybe<Scalars['String']>;
  readonly appealMethods?: Maybe<Scalars['String']>;
  readonly appealOnlineSubmissionUrl?: Maybe<Scalars['String']>;
  readonly appealRequirements?: Maybe<Scalars['String']>;
  readonly appealResponseTurnaroundTime?: Maybe<Scalars['String']>;
  readonly appealStatus?: Maybe<Scalars['String']>;
  readonly appealSubmitters?: Maybe<Scalars['String']>;
  readonly appealType?: Maybe<Scalars['String']>;
  readonly benefitInvestigationId: Scalars['Int'];
  readonly comments?: Maybe<Scalars['String']>;
  readonly insurancePlanName?: Maybe<Scalars['String']>;
  readonly insurancePlanType?: Maybe<Scalars['String']>;
  readonly therapyOrDrug?: Maybe<Scalars['String']>;
};

export type ApplicationDrugServices = {
  readonly __typename?: 'ApplicationDrugServices';
  readonly applicationDrugServicesId: Scalars['Uuid'];
  readonly applicationId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drug?: Maybe<Drugs>;
  readonly drugId: Scalars['Uuid'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly serviceType?: Maybe<ServiceTypes>;
  readonly serviceTypeId: Scalars['Uuid'];
};

export type Applications = {
  readonly __typename?: 'Applications';
  readonly applicationId?: Maybe<Scalars['Uuid']>;
  readonly applicationTypeId?: Maybe<Scalars['Uuid']>;
  readonly cancelURL?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly enableCancel: Scalars['Boolean'];
  readonly enableDataAggregatorExport: Scalars['Boolean'];
  readonly enableEventMessaging: Scalars['Boolean'];
  readonly enableHipaaInviteText: Scalars['Boolean'];
  readonly enableSummary: Scalars['Boolean'];
  readonly enableUploadConsent: Scalars['Boolean'];
  readonly enableWorkflowExport: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly finishURL?: Maybe<Scalars['String']>;
  readonly goBackURL?: Maybe<Scalars['String']>;
  readonly isExternalPracticeIdOptional: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly turnOffConsentBoxColor: Scalars['Boolean'];
};

export type BenefitInvestigation = {
  readonly __typename?: 'BenefitInvestigation';
  readonly additionalComments?: Maybe<Scalars['String']>;
  readonly benefitInvestigationId: Scalars['Int'];
  readonly bICreatedDate?: Maybe<Scalars['DateTime']>;
  readonly bIType?: Maybe<Scalars['String']>;
  readonly caseTransactionId: Scalars['Int'];
  readonly coInsurancePercent?: Maybe<Scalars['String']>;
  readonly dateYearPlan?: Maybe<Scalars['String']>;
  readonly deductible?: Maybe<Scalars['String']>;
  readonly deductibleAmountMet?: Maybe<Scalars['String']>;
  readonly eBVTransactionId?: Maybe<Scalars['String']>;
  readonly insurancePlanName?: Maybe<Scalars['String']>;
  readonly insurancePlanType?: Maybe<Scalars['String']>;
  readonly insuranceRepName?: Maybe<Scalars['String']>;
  readonly oopAmountMet?: Maybe<Scalars['String']>;
  readonly outOfPocketMax?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly planEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly planEndDate?: Maybe<Scalars['DateTime']>;
  readonly priorAuthRequired?: Maybe<Scalars['String']>;
  readonly productCovered?: Maybe<Scalars['String']>;
  readonly therapyOrDrug?: Maybe<Scalars['String']>;
};

export type CCareCycleOrganizations = {
  readonly __typename?: 'C_CareCycleOrganizations';
  readonly active: Scalars['Boolean'];
  readonly careCycle?: Maybe<CCareCycles>;
  readonly careCycleId: Scalars['Int'];
  readonly careCycleOrganizationId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organizationAddressId: Scalars['Int'];
  readonly organizationId: Scalars['Int'];
  readonly organizationType: Scalars['String'];
  readonly patientId: Scalars['Int'];
  readonly patientOrganizationId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
  readonly relationship?: Maybe<Scalars['String']>;
};

export type CCareCyclePhysicians = {
  readonly __typename?: 'C_CareCyclePhysicians';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly careCycle?: Maybe<CCareCycles>;
  readonly careCycleId: Scalars['Int'];
  readonly careCyclePhysicianId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly optIn?: Maybe<Scalars['Boolean']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly organizationLocationId?: Maybe<Scalars['Int']>;
  readonly patientId: Scalars['Int'];
  readonly patientPhysicianId: Scalars['Int'];
  readonly physicianId: Scalars['Int'];
  readonly physicianLocationId?: Maybe<Scalars['Int']>;
  readonly physicianType: Scalars['String'];
  readonly programId: Scalars['Int'];
  readonly providerAddressId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
  readonly relationship?: Maybe<Scalars['String']>;
};

export type CCareCycles = {
  readonly __typename?: 'C_CareCycles';
  readonly careCycleEndDate?: Maybe<Scalars['DateTime']>;
  readonly careCycleEndedBy?: Maybe<Scalars['String']>;
  readonly careCycleId: Scalars['Int'];
  readonly careCycleName?: Maybe<Scalars['String']>;
  readonly careCycleOrganizations?: Maybe<ReadonlyArray<Maybe<CCareCycleOrganizations>>>;
  readonly careCyclePhysicians?: Maybe<ReadonlyArray<Maybe<CCareCyclePhysicians>>>;
  readonly careCycleStartDate: Scalars['DateTime'];
  readonly careCycleStartedBy: Scalars['String'];
  readonly careCycleState?: Maybe<Scalars['String']>;
  readonly careCycleStatus: Scalars['String'];
  readonly careCycleTypeCode?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly custom1FieldLabel?: Maybe<Scalars['String']>;
  readonly custom1FieldValue?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugStartDate?: Maybe<Scalars['DateTime']>;
  readonly drugTherapy: Scalars['String'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId2?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly organizationLocationId?: Maybe<Scalars['Int']>;
  readonly patientAddressId: Scalars['Int'];
  readonly patientHasGenericperCc?: Maybe<Scalars['DateTime']>;
  readonly patientHubStatus?: Maybe<Scalars['String']>;
  readonly patientHubStatusDate?: Maybe<Scalars['DateTime']>;
  readonly patientId: Scalars['Int'];
  readonly patientLocationId?: Maybe<Scalars['Int']>;
  readonly patientOtherStatusType?: Maybe<Scalars['String']>;
  readonly patientOtherStatusValue?: Maybe<Scalars['String']>;
  readonly patientSpstatus?: Maybe<Scalars['String']>;
  readonly patientSpstatusDate?: Maybe<Scalars['DateTime']>;
  readonly physicianId?: Maybe<Scalars['Int']>;
  readonly physicianLocationId?: Maybe<Scalars['Int']>;
  readonly programId: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
};

export type CContactAddresses = {
  readonly __typename?: 'C_ContactAddresses';
  readonly active: Scalars['Boolean'];
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly addressType: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly contact?: Maybe<CContacts>;
  readonly contactAddressId: Scalars['Int'];
  readonly contactId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fedexAddressVerifiedFlg: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly mdmprogramId?: Maybe<Scalars['Int']>;
  readonly rank: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CContactOrganizations = {
  readonly __typename?: 'C_ContactOrganizations';
  readonly contact?: Maybe<CContacts>;
  readonly contactId: Scalars['Int'];
  readonly contactOrganizationId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organizationId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CContactPhoneNumbers = {
  readonly __typename?: 'C_ContactPhoneNumbers';
  readonly active: Scalars['Boolean'];
  readonly contact?: Maybe<CContacts>;
  readonly contactId: Scalars['Int'];
  readonly contactPhoneNumberId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly oktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
  readonly preferredTime?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CContactPhysicians = {
  readonly __typename?: 'C_ContactPhysicians';
  readonly contact?: Maybe<CContacts>;
  readonly contactId: Scalars['Int'];
  readonly contactPhysicianId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly delegate?: Maybe<Scalars['Boolean']>;
  readonly delegateDate?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly doneby?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly physicianId: Scalars['Int'];
  readonly physicianType?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CContacts = {
  readonly __typename?: 'C_Contacts';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly contactAddresses?: Maybe<ReadonlyArray<Maybe<CContactAddresses>>>;
  readonly contactCategory?: Maybe<Scalars['String']>;
  readonly contactId: Scalars['Int'];
  readonly contactOrganizations?: Maybe<ReadonlyArray<Maybe<CContactOrganizations>>>;
  readonly contactPhoneNumbers?: Maybe<ReadonlyArray<Maybe<CContactPhoneNumbers>>>;
  readonly contactPhysicians?: Maybe<ReadonlyArray<Maybe<CContactPhysicians>>>;
  readonly contactType?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly deleted: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly legacyId?: Maybe<Scalars['String']>;
  readonly masterContactId?: Maybe<Scalars['Int']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly preferredMethodOfContact?: Maybe<Scalars['String']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type CInboundPrescriptionMessagesInput = {
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdSource?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['String']>;
  readonly hubProgramId?: Maybe<Scalars['Int']>;
  readonly inboundPrescriptionMessageId: Scalars['Int'];
  readonly rxReferenceId?: Maybe<Scalars['String']>;
};

export type CInsuranceAddresses = {
  readonly __typename?: 'C_InsuranceAddresses';
  readonly active: Scalars['Boolean'];
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly addressType: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly fax?: Maybe<Scalars['String']>;
  readonly faxComments?: Maybe<Scalars['String']>;
  readonly insurance?: Maybe<CInsurances>;
  readonly insuranceAddressId: Scalars['Int'];
  readonly insuranceId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone1Comments?: Maybe<Scalars['String']>;
  readonly phone1Type?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly phone2Comments?: Maybe<Scalars['String']>;
  readonly phone2Type?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CInsuranceDynamicData = {
  readonly __typename?: 'C_InsuranceDynamicData';
  readonly answer?: Maybe<Scalars['String']>;
  readonly answerId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly dynamicDataListId: Scalars['Int'];
  readonly insurance?: Maybe<CInsurances>;
  readonly insuranceDynamicDataId: Scalars['Int'];
  readonly insuranceId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
};

export type CInsuranceNotes = {
  readonly __typename?: 'C_InsuranceNotes';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly insurance?: Maybe<CInsurances>;
  readonly insuranceId: Scalars['Int'];
  readonly insuranceNoteId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly noteText?: Maybe<Scalars['String']>;
  readonly subject?: Maybe<Scalars['String']>;
};

export type CInsurancePhoneNumbers = {
  readonly __typename?: 'C_InsurancePhoneNumbers';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly insurance?: Maybe<CInsurances>;
  readonly insuranceId: Scalars['Int'];
  readonly insurancePhoneNumberId: Scalars['Int'];
  readonly lastUpdate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type CInsurances = {
  readonly __typename?: 'C_Insurances';
  readonly active: Scalars['Boolean'];
  readonly benefitPlanType?: Maybe<Scalars['String']>;
  readonly benefitType?: Maybe<Scalars['String']>;
  readonly binnumber?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalPayorId?: Maybe<Scalars['String']>;
  readonly externalPbmid?: Maybe<Scalars['String']>;
  readonly groupNumber?: Maybe<Scalars['String']>;
  readonly insuranceAddresses?: Maybe<ReadonlyArray<Maybe<CInsuranceAddresses>>>;
  readonly insuranceBenefitPlanName: Scalars['String'];
  readonly insuranceDynamicData?: Maybe<ReadonlyArray<Maybe<CInsuranceDynamicData>>>;
  readonly insuranceEligibilityCode?: Maybe<Scalars['String']>;
  readonly insuranceEligibilityName?: Maybe<Scalars['String']>;
  readonly insuranceId: Scalars['Int'];
  readonly insuranceNotes?: Maybe<ReadonlyArray<Maybe<CInsuranceNotes>>>;
  readonly insurancePhoneNumbers?: Maybe<ReadonlyArray<Maybe<CInsurancePhoneNumbers>>>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly mdmprogramId: Scalars['Int'];
  readonly patientInsuranceMedicalInsurance?: Maybe<ReadonlyArray<Maybe<CPatientInsurance>>>;
  readonly patientInsurancePharmacyInsurance?: Maybe<ReadonlyArray<Maybe<CPatientInsurance>>>;
  readonly payerId?: Maybe<Scalars['Int']>;
  readonly payerTypeCode1?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly planTypeCode2?: Maybe<Scalars['String']>;
  readonly planTypeDetailBreakdownCode4?: Maybe<Scalars['String']>;
  readonly planTypeDetailBreakdownCode5?: Maybe<Scalars['String']>;
  readonly planTypeDetailCode3?: Maybe<Scalars['String']>;
  readonly productType?: Maybe<Scalars['String']>;
  readonly softwareVendorCertificationId?: Maybe<Scalars['String']>;
};

export type COrganizationAddresses = {
  readonly __typename?: 'C_OrganizationAddresses';
  readonly active: Scalars['Boolean'];
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly addressType: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension1?: Maybe<Scalars['String']>;
  readonly extension2?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly faxComments?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly mdmprogramId?: Maybe<Scalars['Int']>;
  readonly organization?: Maybe<COrganizations>;
  readonly organizationAddressId: Scalars['Int'];
  readonly organizationId: Scalars['Int'];
  readonly organizationNpis?: Maybe<ReadonlyArray<Maybe<COrganizationNpis>>>;
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone1Comments?: Maybe<Scalars['String']>;
  readonly phone1Type?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly phone2Comments?: Maybe<Scalars['String']>;
  readonly phone2Type?: Maybe<Scalars['String']>;
  readonly preferredMethodOfContact?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly siteOfService?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type COrganizationDynamicData = {
  readonly __typename?: 'C_OrganizationDynamicData';
  readonly answer?: Maybe<Scalars['String']>;
  readonly answerId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly dynamicDataListId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<COrganizations>;
  readonly organizationDynamicDataId: Scalars['Int'];
  readonly organizationId: Scalars['Int'];
  readonly programId: Scalars['Int'];
};

export type COrganizationNotes = {
  readonly __typename?: 'C_OrganizationNotes';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly noteText?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<COrganizations>;
  readonly organizationId: Scalars['Int'];
  readonly organizationNoteId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly subject?: Maybe<Scalars['String']>;
};

export type COrganizationNpis = {
  readonly __typename?: 'C_OrganizationNpis';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<COrganizations>;
  readonly organizationAddress?: Maybe<COrganizationAddresses>;
  readonly organizationAddressId: Scalars['Int'];
  readonly organizationId: Scalars['Int'];
  readonly organizationNpiid: Scalars['Int'];
  readonly pharmacyNabp?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
};

export type COrganizationPhoneNumbers = {
  readonly __typename?: 'C_OrganizationPhoneNumbers';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<COrganizations>;
  readonly organizationId: Scalars['Int'];
  readonly organizationPhoneNumberId: Scalars['Int'];
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
};

export type COrganizationPrograms = {
  readonly __typename?: 'C_OrganizationPrograms';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly orgnization?: Maybe<COrganizations>;
  readonly orgnizationId: Scalars['Int'];
  readonly orgnizationProgramId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly sharedRecordProgramId: Scalars['Int'];
};

export type COrganizations = {
  readonly __typename?: 'C_Organizations';
  readonly accountableCareOrganization?: Maybe<Scalars['String']>;
  readonly baaOnFile?: Maybe<Scalars['Boolean']>;
  readonly caseManager?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly dateBaaSent?: Maybe<Scalars['DateTime']>;
  readonly dateBaaSigned?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId2?: Maybe<Scalars['String']>;
  readonly highTouch?: Maybe<Scalars['Boolean']>;
  readonly highTouchNote?: Maybe<Scalars['String']>;
  readonly hin?: Maybe<Scalars['String']>;
  readonly independentPracticeOrganization?: Maybe<Scalars['String']>;
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly masterOrgnizationId?: Maybe<Scalars['Int']>;
  readonly mdmprogramId: Scalars['Int'];
  readonly npi?: Maybe<Scalars['String']>;
  readonly organizationAddresses?: Maybe<ReadonlyArray<Maybe<COrganizationAddresses>>>;
  readonly organizationDynamicData?: Maybe<ReadonlyArray<Maybe<COrganizationDynamicData>>>;
  readonly organizationId: Scalars['Int'];
  readonly organizationMethodContact?: Maybe<Scalars['String']>;
  readonly organizationName: Scalars['String'];
  readonly organizationNotes?: Maybe<ReadonlyArray<Maybe<COrganizationNotes>>>;
  readonly organizationNpis?: Maybe<ReadonlyArray<Maybe<COrganizationNpis>>>;
  readonly organizationPhoneNumbers?: Maybe<ReadonlyArray<Maybe<COrganizationPhoneNumbers>>>;
  readonly organizationPrograms?: Maybe<ReadonlyArray<Maybe<COrganizationPrograms>>>;
  readonly organizationType: Scalars['String'];
  readonly organizationUrl?: Maybe<Scalars['String']>;
  readonly otherIdentifier?: Maybe<Scalars['String']>;
  readonly preferredContactMethodId?: Maybe<Scalars['Int']>;
  readonly programName?: Maybe<Scalars['String']>;
  readonly registrationStatus?: Maybe<Scalars['String']>;
  readonly siteReadiness?: Maybe<Scalars['String']>;
  readonly subType?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
  readonly title10?: Maybe<Scalars['String']>;
  readonly title10ExpDate?: Maybe<Scalars['DateTime']>;
  readonly w9date?: Maybe<Scalars['DateTime']>;
  readonly w9onFile?: Maybe<Scalars['Boolean']>;
};

export type CPatientAddresses = {
  readonly __typename?: 'C_PatientAddresses';
  readonly active: Scalars['Boolean'];
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly addressType: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fedexAddressVerifiedFlg: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly mdmprogramId?: Maybe<Scalars['Int']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientAddressId: Scalars['Int'];
  readonly patientId: Scalars['Int'];
  readonly rank: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CPatientAttestation = {
  readonly __typename?: 'C_PatientAttestation';
  readonly attestationRecordingId?: Maybe<Scalars['String']>;
  readonly attestationType?: Maybe<Scalars['String']>;
  readonly attesterEmail?: Maybe<Scalars['String']>;
  readonly attesterFirstName?: Maybe<Scalars['String']>;
  readonly attesterLastName?: Maybe<Scalars['String']>;
  readonly attesterPhoneNumber?: Maybe<Scalars['String']>;
  readonly brccode?: Maybe<Scalars['String']>;
  readonly checkBox1?: Maybe<Scalars['Boolean']>;
  readonly checkBox2?: Maybe<Scalars['Boolean']>;
  readonly contact?: Maybe<Scalars['String']>;
  readonly contactId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly diseaseState?: Maybe<Scalars['String']>;
  readonly drugTherapy?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly expirationDateNa?: Maybe<Scalars['Boolean']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fundId?: Maybe<Scalars['Int']>;
  readonly fundName?: Maybe<Scalars['String']>;
  readonly isReceived?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly nurseOptout?: Maybe<Scalars['Boolean']>;
  readonly nurseOptOutDate?: Maybe<Scalars['DateTime']>;
  readonly optout?: Maybe<Scalars['Boolean']>;
  readonly optOutDate?: Maybe<Scalars['DateTime']>;
  readonly optoutReason?: Maybe<Scalars['String']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientAttestationId: Scalars['Int'];
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly patientLocationId?: Maybe<Scalars['Int']>;
  readonly programId?: Maybe<Scalars['Int']>;
  readonly providerId?: Maybe<Scalars['Int']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly statusChangeDate?: Maybe<Scalars['DateTime']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly verbalConsent?: Maybe<Scalars['String']>;
  readonly version?: Maybe<Scalars['String']>;
};

export type CPatientConsentAudit = {
  readonly __typename?: 'C_PatientConsentAudit';
  readonly comment?: Maybe<Scalars['String']>;
  readonly consentDateManu?: Maybe<Scalars['DateTime']>;
  readonly consentFormVersion?: Maybe<Scalars['String']>;
  readonly consentManuExpDate?: Maybe<Scalars['DateTime']>;
  readonly consentProvider?: Maybe<Scalars['String']>;
  readonly consentReceivedBy?: Maybe<Scalars['String']>;
  readonly consentReceivedManu: Scalars['Boolean'];
  readonly consentSource?: Maybe<Scalars['String']>;
  readonly consentSourceSubtype?: Maybe<Scalars['String']>;
  readonly consentType?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly isOptOut?: Maybe<Scalars['Boolean']>;
  readonly optOutDate?: Maybe<Scalars['DateTime']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientConsentAuditId: Scalars['Int'];
  readonly patientId: Scalars['Int'];
  readonly programId: Scalars['Int'];
};

export type CPatientConsentPerDrug = {
  readonly __typename?: 'C_PatientConsentPerDrug';
  readonly comment?: Maybe<Scalars['String']>;
  readonly consentDate_Manu?: Maybe<Scalars['DateTime']>;
  readonly consentFormVersion: Scalars['String'];
  readonly consentPerDrugId: Scalars['Int'];
  readonly consentReceived: Scalars['Boolean'];
  readonly consentSource: Scalars['String'];
  readonly consentSourceSubtype: Scalars['String'];
  readonly consentType?: Maybe<Scalars['String']>;
  readonly consent_Manu_ExpDate?: Maybe<Scalars['DateTime']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drugName: Scalars['String'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly optOut: Scalars['Boolean'];
  readonly optOutDate?: Maybe<Scalars['DateTime']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly serviceRequestId?: Maybe<Scalars['Int']>;
};

export type CPatientDiagnosis = {
  readonly __typename?: 'C_PatientDiagnosis';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly dateofDiagnosis?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly diagnosisListId: Scalars['Int'];
  readonly iCDCode?: Maybe<Scalars['String']>;
  readonly iCDCodeType?: Maybe<Scalars['String']>;
  readonly iCDDescription?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientDiagnosisId: Scalars['Int'];
  readonly patientId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CPatientDynamicData = {
  readonly __typename?: 'C_PatientDynamicData';
  readonly answer?: Maybe<Scalars['String']>;
  readonly answerId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly dynamicDataListId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientDynamicDataId: Scalars['Int'];
  readonly patientId: Scalars['Int'];
  readonly programId: Scalars['Int'];
};

export type CPatientInsurance = {
  readonly __typename?: 'C_PatientInsurance';
  readonly active: Scalars['Boolean'];
  readonly binnumber?: Maybe<Scalars['String']>;
  readonly cardHolder?: Maybe<Scalars['String']>;
  readonly chCell?: Maybe<Scalars['String']>;
  readonly chDob?: Maybe<Scalars['DateTime']>;
  readonly chEmail?: Maybe<Scalars['String']>;
  readonly chPhone?: Maybe<Scalars['String']>;
  readonly chSsn?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly employerAddress?: Maybe<Scalars['String']>;
  readonly employerName?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalPayorId?: Maybe<Scalars['String']>;
  readonly externalPbmid?: Maybe<Scalars['String']>;
  readonly groupNumber?: Maybe<Scalars['String']>;
  readonly insuranceEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly insuranceEndDate?: Maybe<Scalars['DateTime']>;
  readonly insurancePayerId?: Maybe<Scalars['Int']>;
  readonly insurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly insuranceType: Scalars['String'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medicalGroup?: Maybe<Scalars['String']>;
  readonly medicalInsurance?: Maybe<CInsurances>;
  readonly medicalInsuranceId?: Maybe<Scalars['Int']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly patientInsuranceId: Scalars['Int'];
  readonly payerPlanTypeLookupId?: Maybe<Scalars['Int']>;
  readonly pbmcategory?: Maybe<Scalars['String']>;
  readonly pbminsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly pharmacyInsurance?: Maybe<CInsurances>;
  readonly pharmacyInsuranceId?: Maybe<Scalars['Int']>;
  readonly programId: Scalars['Int'];
  readonly relationship?: Maybe<Scalars['String']>;
  readonly subscriberId?: Maybe<Scalars['String']>;
  readonly subscriberID_PBM?: Maybe<Scalars['String']>;
  readonly truePharmacyInsuranceId?: Maybe<Scalars['Int']>;
};

export type CPatientOrganizations = {
  readonly __typename?: 'C_PatientOrganizations';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organizationId: Scalars['Int'];
  readonly organizationType: Scalars['String'];
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly patientOrganizationId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
  readonly relationship?: Maybe<Scalars['String']>;
};

export type CPatientPhoneNumbers = {
  readonly __typename?: 'C_PatientPhoneNumbers';
  readonly active: Scalars['Boolean'];
  readonly country?: Maybe<Scalars['String']>;
  readonly countryCallingCode?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly oktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly oktoSendTextMsg?: Maybe<Scalars['Boolean']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly patientPhoneNumberId: Scalars['Int'];
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
  readonly preferredCallingRate?: Maybe<Scalars['String']>;
  readonly preferredTime?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly rank: Scalars['Int'];
};

export type CPatientPhysicians = {
  readonly __typename?: 'C_PatientPhysicians';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly optIn?: Maybe<Scalars['Boolean']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly organizationLocationId?: Maybe<Scalars['Int']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly patientPhysicianId: Scalars['Int'];
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianLocationId?: Maybe<Scalars['Int']>;
  readonly physicianType: Scalars['String'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
  readonly relationship?: Maybe<Scalars['String']>;
};

export type CPatientPrescription = {
  readonly __typename?: 'C_PatientPrescription';
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly deleted: Scalars['Boolean'];
  readonly externalPrescriptionId?: Maybe<Scalars['Long']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly patientPrescriptionId: Scalars['Int'];
  readonly prescription?: Maybe<CPrescription>;
  readonly prescriptionId: Scalars['Int'];
  readonly programId?: Maybe<Scalars['Int']>;
};

export type CPatientPrescriptionInput = {
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly deleted: Scalars['Boolean'];
  readonly externalPrescriptionId?: Maybe<Scalars['Long']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly patientPrescriptionId: Scalars['Int'];
  readonly prescription?: Maybe<CPrescriptionInput>;
  readonly prescriptionId: Scalars['Int'];
  readonly programId?: Maybe<Scalars['Int']>;
};

export type CPatientStatus = {
  readonly __typename?: 'C_PatientStatus';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patient?: Maybe<CPatients>;
  readonly patientId: Scalars['Int'];
  readonly patientStatusId: Scalars['Int'];
  readonly patientStatusList?: Maybe<CPatientStatusList>;
  readonly patientStatusListId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly specialtyPharmacyName?: Maybe<Scalars['String']>;
  readonly spid: Scalars['Int'];
  readonly statusCode?: Maybe<Scalars['String']>;
  readonly statusDate: Scalars['DateTime'];
  readonly statusDescription?: Maybe<Scalars['String']>;
  readonly statusDetailCode?: Maybe<Scalars['String']>;
  readonly statusDetailDescription?: Maybe<Scalars['String']>;
  readonly statusType: Scalars['String'];
  readonly transactionId?: Maybe<Scalars['String']>;
};

export type CPatientStatusList = {
  readonly __typename?: 'C_PatientStatusList';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patientStatus?: Maybe<ReadonlyArray<Maybe<CPatientStatus>>>;
  readonly patientStatusListId: Scalars['Int'];
  readonly patientStatusProgramList?: Maybe<ReadonlyArray<Maybe<CPatientStatusProgramList>>>;
  readonly programId?: Maybe<Scalars['Int']>;
  readonly sortOrder?: Maybe<Scalars['Decimal']>;
  readonly statusCode: Scalars['String'];
  readonly statusDescription: Scalars['String'];
  readonly statusDetailCode?: Maybe<Scalars['String']>;
  readonly statusDetailDescription?: Maybe<Scalars['String']>;
  readonly statusType: Scalars['String'];
};

export type CPatientStatusProgramList = {
  readonly __typename?: 'C_PatientStatusProgramList';
  readonly active: Scalars['Boolean'];
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly patientStatusList?: Maybe<CPatientStatusList>;
  readonly patientStatusListId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly programPatientStatusListId: Scalars['Int'];
  readonly sortOrder?: Maybe<Scalars['Decimal']>;
};

export type CPatients = {
  readonly __typename?: 'C_Patients';
  readonly consentDateManu?: Maybe<Scalars['DateTime']>;
  readonly consentManuExpDate?: Maybe<Scalars['DateTime']>;
  readonly consentProvider?: Maybe<Scalars['String']>;
  readonly consentReceivedBy?: Maybe<Scalars['String']>;
  readonly consentReceivedManu?: Maybe<Scalars['Boolean']>;
  readonly consentSource?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly deleted: Scalars['Boolean'];
  readonly dob?: Maybe<Scalars['DateTime']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId2?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly highTouch?: Maybe<Scalars['Boolean']>;
  readonly highTouchNote?: Maybe<Scalars['String']>;
  readonly hubCode?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly legacyId?: Maybe<Scalars['String']>;
  readonly marketingMemberId?: Maybe<Scalars['String']>;
  readonly masterPatientId?: Maybe<Scalars['Int']>;
  readonly mdmprogramId: Scalars['Int'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly patientAddresses?: Maybe<ReadonlyArray<Maybe<CPatientAddresses>>>;
  readonly patientAttestation?: Maybe<ReadonlyArray<Maybe<CPatientAttestation>>>;
  readonly patientAuthCode?: Maybe<Scalars['String']>;
  readonly patientConsentAudit?: Maybe<ReadonlyArray<Maybe<CPatientConsentAudit>>>;
  readonly patientConsentPerDrug?: Maybe<ReadonlyArray<Maybe<CPatientConsentPerDrug>>>;
  readonly patientDiagnosis?: Maybe<ReadonlyArray<Maybe<CPatientDiagnosis>>>;
  readonly patientDynamicData?: Maybe<ReadonlyArray<Maybe<CPatientDynamicData>>>;
  readonly patientId: Scalars['Int'];
  readonly patientInsurance?: Maybe<ReadonlyArray<Maybe<CPatientInsurance>>>;
  readonly patientMethodContact?: Maybe<Scalars['String']>;
  readonly patientOrganizations?: Maybe<ReadonlyArray<Maybe<CPatientOrganizations>>>;
  readonly patientPhoneNumbers?: Maybe<ReadonlyArray<Maybe<CPatientPhoneNumbers>>>;
  readonly patientPhysicians?: Maybe<ReadonlyArray<Maybe<CPatientPhysicians>>>;
  readonly patientStatus?: Maybe<ReadonlyArray<Maybe<CPatientStatus>>>;
  readonly portalUrgent?: Maybe<Scalars['Boolean']>;
  readonly preferredMethodOfContact?: Maybe<Scalars['String']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly productUsageDate?: Maybe<Scalars['DateTime']>;
  readonly programName?: Maybe<Scalars['String']>;
  readonly refusedToProvideDob?: Maybe<Scalars['Boolean']>;
  readonly ssn?: Maybe<Scalars['String']>;
  readonly ssnlastFourDigits?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly yearofBirth?: Maybe<Scalars['String']>;
};

export type CPhysicianAddresses = {
  readonly __typename?: 'C_PhysicianAddresses';
  readonly active: Scalars['Boolean'];
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly addressType: Scalars['String'];
  readonly city?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension1?: Maybe<Scalars['String']>;
  readonly extension2?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly fax2?: Maybe<Scalars['String']>;
  readonly fax2Comments?: Maybe<Scalars['String']>;
  readonly fax2Type?: Maybe<Scalars['String']>;
  readonly faxComments?: Maybe<Scalars['String']>;
  readonly fedexAddressVerifiedFlg: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly mdmprogramId: Scalars['Int'];
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone1Comments?: Maybe<Scalars['String']>;
  readonly phone1Type?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly phone2Comments?: Maybe<Scalars['String']>;
  readonly phone2Type?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianAddressId: Scalars['Int'];
  readonly physicianId: Scalars['Int'];
  readonly preferredMethodOfContact?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CPhysicianDynamicData = {
  readonly __typename?: 'C_PhysicianDynamicData';
  readonly answer?: Maybe<Scalars['String']>;
  readonly answerId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly dynamicDataListId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianDynamicDataId: Scalars['Int'];
  readonly physicianId: Scalars['Int'];
  readonly programId: Scalars['Int'];
};

export type CPhysicianNotes = {
  readonly __typename?: 'C_PhysicianNotes';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly noteText?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianNoteId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly subject?: Maybe<Scalars['String']>;
};

export type CPhysicianOrganizations = {
  readonly __typename?: 'C_PhysicianOrganizations';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly organizationId: Scalars['Int'];
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianOrgId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CPhysicianPhoneNumbers = {
  readonly __typename?: 'C_PhysicianPhoneNumbers';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly lastUpdate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianPhoneNumberId: Scalars['Int'];
  readonly programId: Scalars['Int'];
};

export type CPhysicianSpecialties = {
  readonly __typename?: 'C_PhysicianSpecialties';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lookupDataListId: Scalars['Int'];
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianSpecialtiesId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly providerSpecialty?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<Scalars['Int']>;
};

export type CPhysicianStateLicenses = {
  readonly __typename?: 'C_PhysicianStateLicenses';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly licenseState: Scalars['String'];
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physicianStateLicenseId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly stateLicenseNumber: Scalars['String'];
};

export type CPhysicians = {
  readonly __typename?: 'C_Physicians';
  readonly baaOnFile?: Maybe<Scalars['Boolean']>;
  readonly caseManager?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly dateBaaSent?: Maybe<Scalars['DateTime']>;
  readonly dateBaaSigned?: Maybe<Scalars['DateTime']>;
  readonly dea?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId2?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly highTouch?: Maybe<Scalars['Boolean']>;
  readonly highTouchNote?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly masterPhysicianId?: Maybe<Scalars['Int']>;
  readonly mdmprogramId: Scalars['Int'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly patientPhysicians?: Maybe<ReadonlyArray<Maybe<CPatientPhysicians>>>;
  readonly physicianAddresses?: Maybe<ReadonlyArray<Maybe<CPhysicianAddresses>>>;
  readonly physicianDynamicData?: Maybe<ReadonlyArray<Maybe<CPhysicianDynamicData>>>;
  readonly physicianId: Scalars['Int'];
  readonly physicianNotes?: Maybe<ReadonlyArray<Maybe<CPhysicianNotes>>>;
  readonly physicianOrganizations?: Maybe<ReadonlyArray<Maybe<CPhysicianOrganizations>>>;
  readonly physicianPhoneNumbers?: Maybe<ReadonlyArray<Maybe<CPhysicianPhoneNumbers>>>;
  readonly physiciansDetail?: Maybe<ReadonlyArray<Maybe<CPhysiciansDetail>>>;
  readonly physicianSpecialties?: Maybe<ReadonlyArray<Maybe<CPhysicianSpecialties>>>;
  readonly physicianStateLicenses?: Maybe<ReadonlyArray<Maybe<CPhysicianStateLicenses>>>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly programName?: Maybe<Scalars['String']>;
  readonly providerMethodContact?: Maybe<Scalars['String']>;
  readonly providerType?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly w9date?: Maybe<Scalars['DateTime']>;
  readonly w9onFile?: Maybe<Scalars['Boolean']>;
};

export type CPhysiciansDetail = {
  readonly __typename?: 'C_PhysiciansDetail';
  readonly bcbsid?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly data2000WaiverXdea?: Maybe<Scalars['String']>;
  readonly dea?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medicaidId?: Maybe<Scalars['String']>;
  readonly medicareId?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<CPhysicians>;
  readonly physicianId: Scalars['Int'];
  readonly physiciansDetailId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly ptan?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
};

export type CPrescription = {
  readonly __typename?: 'C_Prescription';
  readonly applicationSource?: Maybe<Scalars['String']>;
  readonly benefitPlanId?: Maybe<Scalars['String']>;
  readonly bin?: Maybe<Scalars['String']>;
  readonly cardholderId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly dateWritten?: Maybe<Scalars['DateTime']>;
  readonly daw?: Maybe<Scalars['String']>;
  readonly daysSupply?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly diagnoses?: Maybe<Scalars['String']>;
  readonly doseType?: Maybe<Scalars['String']>;
  readonly drugBasis?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly externalPrescriptionId: Scalars['Long'];
  readonly formularyId?: Maybe<Scalars['String']>;
  readonly futureEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly futureTerminationDate?: Maybe<Scalars['DateTime']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly helpDeskPhone?: Maybe<Scalars['String']>;
  readonly isCreatedByFax: Scalars['Boolean'];
  readonly isPrescriptionClosed: Scalars['Boolean'];
  readonly lastFillDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly licseffectDate?: Maybe<Scalars['DateTime']>;
  readonly licslevel?: Maybe<Scalars['String']>;
  readonly licsplan?: Maybe<Scalars['String']>;
  readonly licstermDate?: Maybe<Scalars['DateTime']>;
  readonly medicareFlag: Scalars['Boolean'];
  readonly ndc?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly patientAddressLine1?: Maybe<Scalars['String']>;
  readonly patientAddressLine2?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientDob?: Maybe<Scalars['DateTime']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientPhoneNumber1?: Maybe<Scalars['String']>;
  readonly patientPhoneNumber2?: Maybe<Scalars['String']>;
  readonly patientPrescription?: Maybe<ReadonlyArray<Maybe<CPatientPrescription>>>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientSuffix?: Maybe<Scalars['String']>;
  readonly patientZipCode?: Maybe<Scalars['String']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly personCode?: Maybe<Scalars['String']>;
  readonly prescriberOrderNumber?: Maybe<Scalars['String']>;
  readonly prescriptionEligibility?: Maybe<ReadonlyArray<Maybe<CPrescriptionEligibility>>>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionPharmacy?: Maybe<ReadonlyArray<Maybe<CPrescriptionPharmacy>>>;
  readonly prescriptionProviders?: Maybe<ReadonlyArray<Maybe<CPrescriptionProviders>>>;
  readonly prescriptionSource?: Maybe<Scalars['String']>;
  readonly priorAuth?: Maybe<Scalars['String']>;
  readonly priorAuthQual?: Maybe<Scalars['String']>;
  readonly priorAuthStatus?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['String']>;
  readonly refillQualifier?: Maybe<Scalars['String']>;
  readonly refills?: Maybe<Scalars['String']>;
  readonly refillsRemaining?: Maybe<Scalars['String']>;
  readonly rxEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly rxReferenceId?: Maybe<Scalars['String']>;
  readonly sig?: Maybe<Scalars['String']>;
  readonly substitutionPermitted?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']>;
  readonly unitofMeasure?: Maybe<Scalars['String']>;
};

export type CPrescriptionEligibility = {
  readonly __typename?: 'C_PrescriptionEligibility';
  readonly benefitPlanId?: Maybe<Scalars['String']>;
  readonly bin?: Maybe<Scalars['String']>;
  readonly cardholderId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly consent?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly formularyId?: Maybe<Scalars['String']>;
  readonly futureEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly futureTerminationDate?: Maybe<Scalars['DateTime']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly helpDeskPhone?: Maybe<Scalars['String']>;
  readonly licseffectDate?: Maybe<Scalars['DateTime']>;
  readonly licslevel?: Maybe<Scalars['String']>;
  readonly licsplan?: Maybe<Scalars['String']>;
  readonly licstermDate?: Maybe<Scalars['DateTime']>;
  readonly medicareFlag: Scalars['Boolean'];
  readonly mutuallyDefined?: Maybe<Scalars['String']>;
  readonly patientRelationship?: Maybe<Scalars['String']>;
  readonly payerExternalId?: Maybe<Scalars['String']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly payerResponsibilityCode?: Maybe<Scalars['String']>;
  readonly payerType?: Maybe<Scalars['String']>;
  readonly pbmexternalMemberId?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly personCode?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescription>;
  readonly prescriptionEligibilityId: Scalars['Int'];
  readonly prescriptionId?: Maybe<Scalars['Int']>;
  readonly processorIdentificationNumber?: Maybe<Scalars['String']>;
  readonly serviceTypeCoded?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']>;
};

export type CPrescriptionEligibilityInput = {
  readonly benefitPlanId?: Maybe<Scalars['String']>;
  readonly bin?: Maybe<Scalars['String']>;
  readonly cardholderId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly consent?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly formularyId?: Maybe<Scalars['String']>;
  readonly futureEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly futureTerminationDate?: Maybe<Scalars['DateTime']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly helpDeskPhone?: Maybe<Scalars['String']>;
  readonly licseffectDate?: Maybe<Scalars['DateTime']>;
  readonly licslevel?: Maybe<Scalars['String']>;
  readonly licsplan?: Maybe<Scalars['String']>;
  readonly licstermDate?: Maybe<Scalars['DateTime']>;
  readonly medicareFlag: Scalars['Boolean'];
  readonly mutuallyDefined?: Maybe<Scalars['String']>;
  readonly patientRelationship?: Maybe<Scalars['String']>;
  readonly payerExternalId?: Maybe<Scalars['String']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly payerResponsibilityCode?: Maybe<Scalars['String']>;
  readonly payerType?: Maybe<Scalars['String']>;
  readonly pbmexternalMemberId?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly personCode?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescriptionInput>;
  readonly prescriptionEligibilityId: Scalars['Int'];
  readonly prescriptionId?: Maybe<Scalars['Int']>;
  readonly processorIdentificationNumber?: Maybe<Scalars['String']>;
  readonly serviceTypeCoded?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']>;
};

export type CPrescriptionInput = {
  readonly applicationSource?: Maybe<Scalars['String']>;
  readonly benefitPlanId?: Maybe<Scalars['String']>;
  readonly bin?: Maybe<Scalars['String']>;
  readonly cardholderId?: Maybe<Scalars['String']>;
  readonly carrierName?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly dateWritten?: Maybe<Scalars['DateTime']>;
  readonly daw?: Maybe<Scalars['String']>;
  readonly daysSupply?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly diagnoses?: Maybe<Scalars['String']>;
  readonly doseType?: Maybe<Scalars['String']>;
  readonly drugBasis?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly effectiveDate?: Maybe<Scalars['DateTime']>;
  readonly externalPrescriptionId: Scalars['Long'];
  readonly formularyId?: Maybe<Scalars['String']>;
  readonly futureEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly futureTerminationDate?: Maybe<Scalars['DateTime']>;
  readonly groupId?: Maybe<Scalars['String']>;
  readonly helpDeskPhone?: Maybe<Scalars['String']>;
  readonly isCreatedByFax: Scalars['Boolean'];
  readonly isPrescriptionClosed: Scalars['Boolean'];
  readonly lastFillDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly licseffectDate?: Maybe<Scalars['DateTime']>;
  readonly licslevel?: Maybe<Scalars['String']>;
  readonly licsplan?: Maybe<Scalars['String']>;
  readonly licstermDate?: Maybe<Scalars['DateTime']>;
  readonly medicareFlag: Scalars['Boolean'];
  readonly ndc?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly patientAddressLine1?: Maybe<Scalars['String']>;
  readonly patientAddressLine2?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientDob?: Maybe<Scalars['DateTime']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientPhoneNumber1?: Maybe<Scalars['String']>;
  readonly patientPhoneNumber2?: Maybe<Scalars['String']>;
  readonly patientPrescription?: Maybe<ReadonlyArray<Maybe<CPatientPrescriptionInput>>>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientSuffix?: Maybe<Scalars['String']>;
  readonly patientZipCode?: Maybe<Scalars['String']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly pcn?: Maybe<Scalars['String']>;
  readonly personCode?: Maybe<Scalars['String']>;
  readonly prescriberOrderNumber?: Maybe<Scalars['String']>;
  readonly prescriptionEligibility?: Maybe<ReadonlyArray<Maybe<CPrescriptionEligibilityInput>>>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionPharmacy?: Maybe<ReadonlyArray<Maybe<CPrescriptionPharmacyInput>>>;
  readonly prescriptionProviders?: Maybe<ReadonlyArray<Maybe<CPrescriptionProvidersInput>>>;
  readonly prescriptionSource?: Maybe<Scalars['String']>;
  readonly priorAuth?: Maybe<Scalars['String']>;
  readonly priorAuthQual?: Maybe<Scalars['String']>;
  readonly priorAuthStatus?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['String']>;
  readonly refillQualifier?: Maybe<Scalars['String']>;
  readonly refills?: Maybe<Scalars['String']>;
  readonly refillsRemaining?: Maybe<Scalars['String']>;
  readonly rxEffectiveDate?: Maybe<Scalars['DateTime']>;
  readonly rxReferenceId?: Maybe<Scalars['String']>;
  readonly sig?: Maybe<Scalars['String']>;
  readonly substitutionPermitted?: Maybe<Scalars['String']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']>;
  readonly unitofMeasure?: Maybe<Scalars['String']>;
};

export type CPrescriptionPharmacy = {
  readonly __typename?: 'C_PrescriptionPharmacy';
  readonly addressline1?: Maybe<Scalars['String']>;
  readonly addressline2?: Maybe<Scalars['String']>;
  readonly businessName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly fax?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly ncpdp?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescription>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionPharmacyId: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly storeName?: Maybe<Scalars['String']>;
  readonly transferType?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CPrescriptionPharmacyInput = {
  readonly addressline1?: Maybe<Scalars['String']>;
  readonly addressline2?: Maybe<Scalars['String']>;
  readonly businessName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly fax?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly ncpdp?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescriptionInput>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionPharmacyId: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly storeName?: Maybe<Scalars['String']>;
  readonly transferType?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CPrescriptionProviders = {
  readonly __typename?: 'C_PrescriptionProviders';
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly clinicName?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly dea?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly isSupervisingPrescriberinfo: Scalars['Boolean'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medicaidID?: Maybe<Scalars['String']>;
  readonly medicareID?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescription>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionProviderId: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly stateLicenseNumber?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CPrescriptionProvidersInput = {
  readonly addressLine1?: Maybe<Scalars['String']>;
  readonly addressLine2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly clinicName?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly createdBy: Scalars['String'];
  readonly createdOn: Scalars['DateTime'];
  readonly dea?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly isSupervisingPrescriberinfo: Scalars['Boolean'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medicaidID?: Maybe<Scalars['String']>;
  readonly medicareID?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly prescription?: Maybe<CPrescriptionInput>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionProviderId: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly stateLicenseNumber?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type CSrHubStatus = {
  readonly __typename?: 'C_SRHubStatus';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly sRHubStatusID: Scalars['Int'];
  readonly sRHubStatusList?: Maybe<CSrHubStatusList>;
  readonly sRHubStatusListID: Scalars['Int'];
  readonly sRID: Scalars['Int'];
  readonly statusDate: Scalars['DateTime'];
  readonly statusType: Scalars['String'];
};

export type CSrHubStatusList = {
  readonly __typename?: 'C_SRHubStatusList';
  readonly active: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programID?: Maybe<Scalars['Int']>;
  readonly sRHubStatus?: Maybe<ReadonlyArray<Maybe<CSrHubStatus>>>;
  readonly sRHubStatusListID: Scalars['Int'];
  readonly statusCode: Scalars['String'];
  readonly statusDescription: Scalars['String'];
  readonly statusDetailCode?: Maybe<Scalars['String']>;
  readonly statusDetailDescription?: Maybe<Scalars['String']>;
};

export type CSrActivities = {
  readonly __typename?: 'C_SR_Activities';
  readonly activityDate: Scalars['DateTime'];
  readonly callerType?: Maybe<Scalars['String']>;
  readonly callerTypeID?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly iBOBCall?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly reasonForCall?: Maybe<Scalars['String']>;
  readonly reasonForCallID?: Maybe<Scalars['Int']>;
  readonly serviceRequestID: Scalars['Int'];
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly sR_ActivityID: Scalars['Int'];
  readonly sR_ActivityList?: Maybe<CSrActivityList>;
  readonly sR_ActivityListID: Scalars['Int'];
  readonly sR_NoteID?: Maybe<Scalars['Int']>;
};

export type CSrActivityList = {
  readonly __typename?: 'C_SR_ActivityList';
  readonly active: Scalars['Boolean'];
  readonly activityDesc: Scalars['String'];
  readonly alert?: Maybe<Scalars['Boolean']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly parentListID?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Short'];
  readonly recordType: Scalars['String'];
  readonly requireCallLog?: Maybe<Scalars['Boolean']>;
  readonly sortOrder?: Maybe<Scalars['Decimal']>;
  readonly sR_Activities?: Maybe<ReadonlyArray<Maybe<CSrActivities>>>;
  readonly sR_ActivityListID: Scalars['Int'];
  readonly sR_Tickles?: Maybe<ReadonlyArray<Maybe<CSrTickles>>>;
};

export type CSrTickles = {
  readonly __typename?: 'C_SR_Tickles';
  readonly activityDesc?: Maybe<Scalars['String']>;
  readonly autoGenerated?: Maybe<Scalars['Boolean']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly dateSnoozed?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly serviceRequestID: Scalars['Int'];
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly snoozedBy?: Maybe<Scalars['String']>;
  readonly snoozedDays?: Maybe<Scalars['Int']>;
  readonly sR_ActivityList?: Maybe<CSrActivityList>;
  readonly sR_ActivityListID: Scalars['Int'];
  readonly sR_TickleID: Scalars['Int'];
  readonly tickleAssignment?: Maybe<Scalars['String']>;
  readonly tickleComments?: Maybe<Scalars['String']>;
  readonly tickleDueDate?: Maybe<Scalars['DateTime']>;
  readonly tickleStatus: Scalars['String'];
  readonly tickleStatusDate?: Maybe<Scalars['DateTime']>;
  readonly userGroupID?: Maybe<Scalars['Int']>;
};

export type CSrTriages = {
  readonly __typename?: 'C_SR_Triages';
  readonly cancelled?: Maybe<Scalars['Boolean']>;
  readonly cancelledBy?: Maybe<Scalars['String']>;
  readonly cancelledDate?: Maybe<Scalars['DateTime']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly documentID?: Maybe<Scalars['Int']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly externalID?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly reasonSelected?: Maybe<Scalars['String']>;
  readonly redirectedToSPDate?: Maybe<Scalars['DateTime']>;
  readonly serviceRequestID: Scalars['Int'];
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly sR_TriageID: Scalars['Int'];
  readonly triagedBy?: Maybe<Scalars['String']>;
  readonly triagedDate?: Maybe<Scalars['DateTime']>;
  readonly triagePayerType?: Maybe<Scalars['String']>;
  readonly triageType: Scalars['String'];
  readonly vendorID: Scalars['Int'];
  readonly vendorName?: Maybe<Scalars['String']>;
};

export type CSrTypeDynamicData = {
  readonly __typename?: 'C_SR_TypeDynamicData';
  readonly answer?: Maybe<Scalars['String']>;
  readonly answerID?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly dynamicDataListID: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly productApproved?: Maybe<Scalars['String']>;
  readonly programID: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly serviceRequestID: Scalars['Int'];
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly sR_TypeDynamicDataID: Scalars['Int'];
};

export type CServiceRequests = {
  readonly __typename?: 'C_ServiceRequests';
  readonly autoGenerated?: Maybe<Scalars['Boolean']>;
  readonly careCycleCategory?: Maybe<Scalars['String']>;
  readonly careCycleId?: Maybe<Scalars['Int']>;
  readonly closedAt?: Maybe<Scalars['DateTime']>;
  readonly closedBy?: Maybe<Scalars['String']>;
  readonly contactAddressId?: Maybe<Scalars['Int']>;
  readonly contactId?: Maybe<Scalars['Int']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly customDate1?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly entityAsContact?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly initialInfoComplete?: Maybe<Scalars['Boolean']>;
  readonly iscmmsr?: Maybe<Scalars['Boolean']>;
  readonly lastSynced?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly nurseAgencyAddressId?: Maybe<Scalars['Int']>;
  readonly nurseAgencyId?: Maybe<Scalars['Int']>;
  readonly organizationAddressID?: Maybe<Scalars['Int']>;
  readonly organizationID: Scalars['Int'];
  readonly parentNdcid?: Maybe<Scalars['Int']>;
  readonly parentSrid?: Maybe<Scalars['Int']>;
  readonly patientAddressId?: Maybe<Scalars['Int']>;
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly physicianAddressID?: Maybe<Scalars['Int']>;
  readonly physicianID: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly resolution?: Maybe<Scalars['String']>;
  readonly resolutionId?: Maybe<Scalars['Int']>;
  readonly serviceRequestId: Scalars['Int'];
  readonly serviceRequestSubType?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly serviceRequestType?: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
  readonly srdrugName?: Maybe<Scalars['String']>;
  readonly srholdStatus?: Maybe<Scalars['Boolean']>;
  readonly sRHubStatus?: Maybe<ReadonlyArray<Maybe<CSrHubStatus>>>;
  readonly srinProgress?: Maybe<Scalars['Boolean']>;
  readonly srpriority?: Maybe<Scalars['String']>;
  readonly sR_Activities?: Maybe<ReadonlyArray<Maybe<CSrActivities>>>;
  readonly sR_ProcessDates?: Maybe<ReadonlyArray<Maybe<CSrDates>>>;
  readonly sR_Status: Scalars['String'];
  readonly sR_Tickles?: Maybe<ReadonlyArray<Maybe<CSrTickles>>>;
  readonly sR_Triages?: Maybe<ReadonlyArray<Maybe<CSrTriages>>>;
  readonly sR_TypeDynamicData?: Maybe<ReadonlyArray<Maybe<CSrTypeDynamicData>>>;
  readonly sR_TypeListID?: Maybe<Scalars['Int']>;
  readonly startedAt: Scalars['DateTime'];
  readonly startedBy: Scalars['String'];
  readonly takenAt?: Maybe<Scalars['DateTime']>;
  readonly takenBy?: Maybe<Scalars['String']>;
  readonly userGroup?: Maybe<Scalars['String']>;
  readonly userName?: Maybe<Scalars['String']>;
};

export type CSrDates = {
  readonly __typename?: 'C_SrDates';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly dateValue?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly listValue?: Maybe<Scalars['String']>;
  readonly lookupDataListId: Scalars['Int'];
  readonly na?: Maybe<Scalars['Boolean']>;
  readonly programId: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly serviceRequestId: Scalars['Int'];
  readonly serviceRequests?: Maybe<CServiceRequests>;
  readonly sR_DateID: Scalars['Int'];
};

export type CaseDetail = {
  readonly __typename?: 'CaseDetail';
  readonly appeals?: Maybe<ReadonlyArray<Maybe<Appeal>>>;
  readonly benefitInvestigations?: Maybe<ReadonlyArray<Maybe<BenefitInvestigation>>>;
  readonly mileStones?: Maybe<ReadonlyArray<Maybe<CaseMileStone>>>;
  readonly patientEnrollments?: Maybe<ReadonlyArray<Maybe<PatientIntake>>>;
  readonly prescriptionIntakes?: Maybe<ReadonlyArray<Maybe<PrescriptionIntake>>>;
  readonly priorAuthorizations?: Maybe<ReadonlyArray<Maybe<PriorAuthorization>>>;
  readonly transactions?: Maybe<ReadonlyArray<Maybe<CaseTransaction>>>;
  readonly triagePrescriptions?: Maybe<ReadonlyArray<Maybe<TriagePrescription>>>;
};

export type CaseMangerContact = {
  readonly __typename?: 'CaseMangerContact';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly userID: Scalars['Int'];
};

export type CaseMileStone = {
  readonly __typename?: 'CaseMileStone';
  readonly drugId?: Maybe<Scalars['String']>;
  readonly mileStoneCode?: Maybe<Scalars['String']>;
  readonly mileStoneId: Scalars['Int'];
  readonly mileStoneName?: Maybe<Scalars['String']>;
  readonly mileStoneType?: Maybe<Scalars['String']>;
  readonly sortOrder: Scalars['Int'];
};

export type CaseRecord = {
  readonly __typename?: 'CaseRecord';
  readonly caseClosed: Scalars['Boolean'];
  readonly caseDrugDescription?: Maybe<Scalars['String']>;
  readonly caseDrugName?: Maybe<Scalars['String']>;
  readonly caseIntakeID: Scalars['Int'];
  readonly caseMileStone?: Maybe<Scalars['String']>;
  readonly caseMileStoneStatus?: Maybe<Scalars['String']>;
  readonly caseReceivedDate: Scalars['DateTime'];
  readonly caseRecordId: Scalars['Int'];
  readonly caseStatus?: Maybe<Scalars['String']>;
  readonly caseSubStatus?: Maybe<Scalars['String']>;
  readonly caseTransactionId: Scalars['Int'];
  readonly hasUnReadMessage: Scalars['Boolean'];
  readonly isUpdatedRecently: Scalars['Boolean'];
  readonly lastUpdatedDate: Scalars['DateTime'];
  readonly needAction: Scalars['Boolean'];
  readonly organizationId: Scalars['Int'];
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly physicianId: Scalars['Int'];
  readonly providerFirstName?: Maybe<Scalars['String']>;
  readonly providerLastName?: Maybe<Scalars['String']>;
  readonly providerNpi?: Maybe<Scalars['String']>;
  readonly userId: Scalars['Uuid'];
};

export type CaseRecordDocuments = {
  readonly __typename?: 'CaseRecordDocuments';
  readonly caseRecordId: Scalars['Int'];
  readonly caseTransactionId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly documentId: Scalars['Int'];
  readonly documentType?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly entityId: Scalars['Int'];
  readonly entityName?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileStorageId?: Maybe<Scalars['Int']>;
  readonly formComplete?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly product?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly signatureRequired?: Maybe<Scalars['Boolean']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly totalPages?: Maybe<Scalars['Int']>;
};

export type CaseRecordTransaction = {
  readonly __typename?: 'CaseRecordTransaction';
  readonly caseFlowStep: Scalars['String'];
  readonly caseMileStone?: Maybe<Scalars['String']>;
  readonly caseRecordId: Scalars['Int'];
  readonly caseRecordTransactionId: Scalars['Int'];
  readonly caseTransactionId: Scalars['Int'];
  readonly caseTransactionState: Scalars['Boolean'];
  readonly caseTransactionStatus?: Maybe<Scalars['String']>;
  readonly caseTransactionType: Scalars['String'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
};

export type CaseTransaction = {
  readonly __typename?: 'CaseTransaction';
  readonly caseClosed: Scalars['Boolean'];
  readonly caseDrugDescription?: Maybe<Scalars['String']>;
  readonly caseDrugName?: Maybe<Scalars['String']>;
  readonly caseFlowStep?: Maybe<Scalars['String']>;
  readonly caseIntakeId: Scalars['Int'];
  readonly caseMileStoneCode?: Maybe<Scalars['String']>;
  readonly caseRecordId: Scalars['Int'];
  readonly caseRecordTransactionId?: Maybe<Scalars['String']>;
  readonly caseSource?: Maybe<Scalars['String']>;
  readonly caseStartTrigger?: Maybe<Scalars['String']>;
  readonly caseTransactionId: Scalars['Int'];
  readonly caseTransactionState?: Maybe<Scalars['String']>;
  readonly caseTransactionStatus?: Maybe<Scalars['String']>;
  readonly caseTransactionType?: Maybe<Scalars['String']>;
  readonly mileStoneCreatedDate: Scalars['DateTime'];
};

export type ConfigurationItemValue = {
  readonly __typename?: 'ConfigurationItemValue';
  readonly configurationItemId: Scalars['Uuid'];
  readonly configurationItemValueId: Scalars['Uuid'];
  readonly description: Scalars['String'];
  readonly value: Scalars['String'];
};

export type ConsentTypes = {
  readonly __typename?: 'ConsentTypes';
  readonly code?: Maybe<Scalars['String']>;
  readonly consentTypeId: Scalars['Uuid'];
  readonly description?: Maybe<Scalars['String']>;
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly contactCategory?: Maybe<Scalars['String']>;
  readonly contactId: Scalars['Int'];
  readonly contactPhoneNumbers?: Maybe<ReadonlyArray<Maybe<ContactPhoneNumber>>>;
  readonly contactType?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly createdProgramId?: Maybe<Scalars['Int']>;
  readonly deleted: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedProgramId?: Maybe<Scalars['Int']>;
  readonly masterContactId?: Maybe<Scalars['Int']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly patientContact?: Maybe<ReadonlyArray<Maybe<PatientContact>>>;
  readonly preferredMethodOfContact?: Maybe<Scalars['String']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly suffix?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type ContactPhoneNumber = {
  readonly __typename?: 'ContactPhoneNumber';
  readonly active: Scalars['Boolean'];
  readonly contact?: Maybe<Contact>;
  readonly contactId: Scalars['Int'];
  readonly contactPhoneNumberId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly extension?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly oktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly phoneComments?: Maybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
  readonly phoneType?: Maybe<Scalars['String']>;
  readonly preferredTime?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type ContactPhoneNumbers = {
  readonly __typename?: 'ContactPhoneNumbers';
  readonly contact?: Maybe<Contacts>;
  readonly contactId: Scalars['Uuid'];
  readonly contactPhoneNumberId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type Contacts = {
  readonly __typename?: 'Contacts';
  readonly contactId: Scalars['Uuid'];
  readonly contactPhoneNumbers?: Maybe<ReadonlyArray<Maybe<ContactPhoneNumbers>>>;
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly patientContacts?: Maybe<ReadonlyArray<Maybe<PatientContacts>>>;
};

export type CopayCard = {
  readonly __typename?: 'CopayCard';
  readonly bIN?: Maybe<Scalars['String']>;
  readonly copayCardID?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly endDate: Scalars['DateTime'];
  readonly groupID?: Maybe<Scalars['String']>;
  readonly isVendorCopayCard: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly pCN?: Maybe<Scalars['String']>;
  readonly serviceRequestId: Scalars['Int'];
  readonly sRCopayCardId: Scalars['Int'];
  readonly startDate: Scalars['DateTime'];
};

export type CopayClaimSubmissionViewModel = {
  readonly __typename?: 'CopayClaimSubmissionViewModel';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly copayCardEagleForceAPILoggedDate?: Maybe<Scalars['DateTime']>;
  readonly copayCardEagleForceAPIStatus?: Maybe<Scalars['String']>;
  readonly copayCardEffectiveEndDate?: Maybe<Scalars['DateTime']>;
  readonly copayCardEffectiveStartDate?: Maybe<Scalars['DateTime']>;
  readonly copayCardHasValidEagleForceAPICheck: Scalars['Boolean'];
  readonly copayCardID?: Maybe<Scalars['String']>;
  readonly eF_Override_BISRID?: Maybe<Scalars['String']>;
  readonly eF_Override_Date?: Maybe<Scalars['DateTime']>;
  readonly eF_Override_ExpirationDate?: Maybe<Scalars['DateTime']>;
  readonly eF_Override_Notes?: Maybe<Scalars['String']>;
  readonly eF_Override_Reason?: Maybe<Scalars['String']>;
  readonly eOBReceivedDate?: Maybe<Scalars['DateTime']>;
  readonly payeeAddress?: Maybe<Scalars['String']>;
  readonly payeeID: Scalars['Int'];
  readonly payeeName?: Maybe<Scalars['String']>;
  readonly payeeType?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly statusUpdatedBy?: Maybe<Scalars['String']>;
  readonly statusUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly taxID?: Maybe<Scalars['String']>;
  readonly totalApprovedAmount: Scalars['Decimal'];
  readonly totalCopayRequestedAmount: Scalars['Decimal'];
  readonly totalDueAmount: Scalars['Decimal'];
  readonly zip?: Maybe<Scalars['String']>;
};

export type CreateEnrollmentInput = {
  readonly additionalPhysicians?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2?: Maybe<Scalars['String']>;
  readonly administeringInformationCity?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly administeringInformationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider?: Maybe<Scalars['String']>;
  readonly administeringInformationName?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers?: Maybe<Scalars['String']>;
  readonly administeringInformationState?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId?: Maybe<Scalars['String']>;
  readonly administeringInformationType?: Maybe<Scalars['String']>;
  readonly administeringInformationZip?: Maybe<Scalars['String']>;
  readonly applicationId: Scalars['Uuid'];
  readonly cancelUrl?: Maybe<Scalars['String']>;
  readonly cardHolderData?: Maybe<Scalars['String']>;
  readonly caregiverEmail?: Maybe<Scalars['String']>;
  readonly caregiverFirstName?: Maybe<Scalars['String']>;
  readonly caregiverGender?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone?: Maybe<Scalars['String']>;
  readonly caregiverLastName?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication?: Maybe<Scalars['String']>;
  readonly caregiverRelationship?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalData?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificDate1?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificField1?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTreatmentStartDate?: Maybe<Scalars['DateTime']>;
  readonly commercialOrprivate?: Maybe<Scalars['Boolean']>;
  readonly confirmationNumber?: Maybe<Scalars['String']>;
  readonly copayAnnualMax?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance?: Maybe<Scalars['Decimal']>;
  readonly copayCardNumber?: Maybe<Scalars['String']>;
  readonly customFields?: Maybe<Scalars['String']>;
  readonly documents?: Maybe<Scalars['String']>;
  readonly drugDaysSupply?: Maybe<Scalars['Int']>;
  readonly drugJcode?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly drugQuantity?: Maybe<Scalars['Int']>;
  readonly enrollmentRequest?: Maybe<Scalars['String']>;
  readonly enrollmentStatus?: Maybe<Scalars['String']>;
  readonly finishUrl?: Maybe<Scalars['String']>;
  readonly goBackUrl?: Maybe<Scalars['String']>;
  readonly hasCaregiver?: Maybe<Scalars['Boolean']>;
  readonly hubEndDate?: Maybe<Scalars['DateTime']>;
  readonly hubPatientID?: Maybe<Scalars['String']>;
  readonly hubStartDate?: Maybe<Scalars['DateTime']>;
  readonly hubStatus?: Maybe<Scalars['String']>;
  readonly isAgreeToReceiveTextMessages?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative?: Maybe<Scalars['Boolean']>;
  readonly isOtherInsurance?: Maybe<Scalars['Boolean']>;
  readonly medicaid?: Maybe<Scalars['Boolean']>;
  readonly medicareAdvantage?: Maybe<Scalars['Boolean']>;
  readonly medicareId?: Maybe<Scalars['String']>;
  readonly medicarePartD?: Maybe<Scalars['Boolean']>;
  readonly organizationAddress1?: Maybe<Scalars['String']>;
  readonly organizationAddress2?: Maybe<Scalars['String']>;
  readonly organizationAddressType?: Maybe<Scalars['String']>;
  readonly organizationCity?: Maybe<Scalars['String']>;
  readonly organizationContactEmail?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName?: Maybe<Scalars['String']>;
  readonly organizationContactGender?: Maybe<Scalars['String']>;
  readonly organizationContactLastName?: Maybe<Scalars['String']>;
  readonly organizationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly organizationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber?: Maybe<Scalars['String']>;
  readonly organizationEmail?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly organizationFax?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationNpi?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationState?: Maybe<Scalars['String']>;
  readonly organizationTaxId?: Maybe<Scalars['String']>;
  readonly organizationZip?: Maybe<Scalars['String']>;
  readonly otherInsurance?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly otherInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly otherInsurancePayerID?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType?: Maybe<Scalars['String']>;
  readonly papEligibilityData?: Maybe<Scalars['String']>;
  readonly papFinancialData?: Maybe<Scalars['String']>;
  readonly patientAddress1?: Maybe<Scalars['String']>;
  readonly patientAddress2?: Maybe<Scalars['String']>;
  readonly patientAddressType?: Maybe<Scalars['String']>;
  readonly patientAttestationText?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientConsentType?: Maybe<Scalars['String']>;
  readonly patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly patientEmail?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientHasInsurance?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientPreferredCommunication?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther?: Maybe<Scalars['String']>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientUSResidence?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumberOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientZip?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsurancePayerId?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn?: Maybe<Scalars['String']>;
  readonly physicianAttestationCanContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationReceived?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationTerms?: Maybe<Scalars['String']>;
  readonly physicianAttestationText?: Maybe<Scalars['String']>;
  readonly physicianBusinessAssociateAggrement?: Maybe<Scalars['Boolean']>;
  readonly physicianConsentCertified?: Maybe<Scalars['Boolean']>;
  readonly physicianDeanumber?: Maybe<Scalars['String']>;
  readonly physicianDoNotContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianGender?: Maybe<Scalars['String']>;
  readonly physicianHin?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLicensingState?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber?: Maybe<Scalars['String']>;
  readonly physicianMiddleName?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions?: Maybe<Scalars['String']>;
  readonly physicianPtannumber?: Maybe<Scalars['String']>;
  readonly physicianReleasePatientInformation?: Maybe<Scalars['Boolean']>;
  readonly physicianRequestForwardPrescription?: Maybe<Scalars['Boolean']>;
  readonly physicianSpecialty?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber?: Maybe<Scalars['String']>;
  readonly physicianTaxId?: Maybe<Scalars['String']>;
  readonly physicianType?: Maybe<Scalars['String']>;
  readonly preferredLanguages?: Maybe<Scalars['String']>;
  readonly prescribingRole?: Maybe<Scalars['String']>;
  readonly prescribingType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly primaryMedicalInsurancePayerId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly priorMedications?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly secondaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly serviceSpecificData?: Maybe<Scalars['String']>;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly signatures?: Maybe<Scalars['String']>;
  readonly submitStatus?: Maybe<Scalars['String']>;
  readonly submittedDate?: Maybe<Scalars['DateTime']>;
  readonly tertiaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly tertiaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly treatingRole?: Maybe<Scalars['String']>;
  readonly treatingType?: Maybe<Scalars['String']>;
  readonly userFirstName?: Maybe<Scalars['String']>;
  readonly userGender?: Maybe<Scalars['String']>;
  readonly userLastName?: Maybe<Scalars['String']>;
  readonly userMiddleName?: Maybe<Scalars['String']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly userPhoneNumber?: Maybe<Scalars['String']>;
  readonly userPhoneType?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
  readonly vaormilitary?: Maybe<Scalars['Boolean']>;
};

export type CreatePatientConsentInput = {
  readonly consentDate: Scalars['DateTime'];
  readonly consentTypeId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly drugId: Scalars['Uuid'];
  readonly expiryDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly optedOut: Scalars['Boolean'];
  readonly optedOutDate?: Maybe<Scalars['DateTime']>;
  readonly organizationId: Scalars['Uuid'];
  readonly patientConsentId: Scalars['Uuid'];
  readonly patientId: Scalars['Uuid'];
  readonly startDate: Scalars['DateTime'];
};

export type DiagnosisList = {
  readonly __typename?: 'DiagnosisList';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly diagnosisListId?: Maybe<Scalars['Int']>;
  readonly icdcode?: Maybe<Scalars['String']>;
  readonly icdcodeType?: Maybe<Scalars['String']>;
  readonly icddescription?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['String']>;
};

export type Document = {
  readonly __typename?: 'Document';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentType?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['ID']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileStorageId?: Maybe<Scalars['ID']>;
  readonly formComplete?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['Date']>;
  readonly product?: Maybe<Scalars['String']>;
  readonly programId?: Maybe<Scalars['ID']>;
  readonly signatureRequired?: Maybe<Scalars['Boolean']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly totalPages?: Maybe<Scalars['Int']>;
};

export type DocumentAttachmentsDocTypes = {
  readonly __typename?: 'DocumentAttachmentsDocTypes';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly document?: Maybe<Documents>;
  readonly documentID: Scalars['Int'];
  readonly documentsDocTypesID: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lookupDataList?: Maybe<LookupDataList>;
  readonly lookupDataListID: Scalars['Int'];
  readonly versionNumber?: Maybe<Scalars['String']>;
};

export type DocumentFaxQueue = {
  readonly __typename?: 'DocumentFaxQueue';
  readonly active: Scalars['Boolean'];
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly directFax: Scalars['Boolean'];
  readonly documentFaxQueueId: Scalars['Int'];
  readonly documentInboundFax?: Maybe<ReadonlyArray<Maybe<DocumentInboundFax>>>;
  readonly emailBox?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly faxPortalPassword?: Maybe<Scalars['String']>;
  readonly faxPortalURL?: Maybe<Scalars['String']>;
  readonly faxPortalUserName?: Maybe<Scalars['String']>;
  readonly faxQueueName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly sortOrder?: Maybe<Scalars['Decimal']>;
  readonly source?: Maybe<Scalars['String']>;
};

export type DocumentInboundFax = {
  readonly __typename?: 'DocumentInboundFax';
  readonly assignmentInProgress?: Maybe<Scalars['Boolean']>;
  readonly directFaxDate?: Maybe<Scalars['DateTime']>;
  readonly directFaxId?: Maybe<Scalars['Int']>;
  readonly documentFaxQueue?: Maybe<DocumentFaxQueue>;
  readonly documentFaxQueueID: Scalars['Int'];
  readonly documentId?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID: Scalars['Int'];
  readonly documentStorageId: Scalars['Int'];
  readonly documentStorageType?: Maybe<Scalars['String']>;
  readonly faxBy?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly faxTo?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers?: Maybe<Scalars['String']>;
  readonly groupName?: Maybe<Scalars['String']>;
  readonly groupPageNumbers?: Maybe<Scalars['String']>;
  readonly numberofPages?: Maybe<Scalars['String']>;
  readonly pageRange?: Maybe<Scalars['String']>;
  readonly parentId?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly processedBy?: Maybe<Scalars['String']>;
  readonly processedDate?: Maybe<Scalars['DateTime']>;
  readonly reasonDeleted?: Maybe<Scalars['String']>;
  readonly receivedDate: Scalars['DateTime'];
  readonly status?: Maybe<Scalars['String']>;
  readonly takenAt?: Maybe<Scalars['DateTime']>;
  readonly takenBy?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type DocumentInboundFaxConnection = {
  readonly __typename?: 'DocumentInboundFaxConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<DocumentInboundFaxEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<DocumentInboundFax>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DocumentInboundFaxEdge = {
  readonly __typename?: 'DocumentInboundFaxEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<DocumentInboundFax>;
};

export type DocumentInboundFaxFilter = {
  readonly AND?: Maybe<ReadonlyArray<DocumentInboundFaxFilter>>;
  readonly assignmentInProgress?: Maybe<Scalars['Boolean']>;
  readonly assignmentInProgress_not?: Maybe<Scalars['Boolean']>;
  readonly directFaxDate?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_gt?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_gte?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly directFaxDate_lt?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_lte?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_not?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly directFaxDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly directFaxDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly directFaxId?: Maybe<Scalars['Int']>;
  readonly directFaxId_gt?: Maybe<Scalars['Int']>;
  readonly directFaxId_gte?: Maybe<Scalars['Int']>;
  readonly directFaxId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly directFaxId_lt?: Maybe<Scalars['Int']>;
  readonly directFaxId_lte?: Maybe<Scalars['Int']>;
  readonly directFaxId_not?: Maybe<Scalars['Int']>;
  readonly directFaxId_not_gt?: Maybe<Scalars['Int']>;
  readonly directFaxId_not_gte?: Maybe<Scalars['Int']>;
  readonly directFaxId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly directFaxId_not_lt?: Maybe<Scalars['Int']>;
  readonly directFaxId_not_lte?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_gt?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_gte?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentFaxQueueID_lt?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_lte?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_not?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_not_gt?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_not_gte?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentFaxQueueID_not_lt?: Maybe<Scalars['Int']>;
  readonly documentFaxQueueID_not_lte?: Maybe<Scalars['Int']>;
  readonly documentId?: Maybe<Scalars['Int']>;
  readonly documentId_gt?: Maybe<Scalars['Int']>;
  readonly documentId_gte?: Maybe<Scalars['Int']>;
  readonly documentId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly documentId_lt?: Maybe<Scalars['Int']>;
  readonly documentId_lte?: Maybe<Scalars['Int']>;
  readonly documentId_not?: Maybe<Scalars['Int']>;
  readonly documentId_not_gt?: Maybe<Scalars['Int']>;
  readonly documentId_not_gte?: Maybe<Scalars['Int']>;
  readonly documentId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly documentId_not_lt?: Maybe<Scalars['Int']>;
  readonly documentId_not_lte?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_gt?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_gte?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentInboundFaxID_lt?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_lte?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_not?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_not_gt?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_not_gte?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentInboundFaxID_not_lt?: Maybe<Scalars['Int']>;
  readonly documentInboundFaxID_not_lte?: Maybe<Scalars['Int']>;
  readonly documentStorageId?: Maybe<Scalars['Int']>;
  readonly documentStorageId_gt?: Maybe<Scalars['Int']>;
  readonly documentStorageId_gte?: Maybe<Scalars['Int']>;
  readonly documentStorageId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentStorageId_lt?: Maybe<Scalars['Int']>;
  readonly documentStorageId_lte?: Maybe<Scalars['Int']>;
  readonly documentStorageId_not?: Maybe<Scalars['Int']>;
  readonly documentStorageId_not_gt?: Maybe<Scalars['Int']>;
  readonly documentStorageId_not_gte?: Maybe<Scalars['Int']>;
  readonly documentStorageId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentStorageId_not_lt?: Maybe<Scalars['Int']>;
  readonly documentStorageId_not_lte?: Maybe<Scalars['Int']>;
  readonly documentStorageType?: Maybe<Scalars['String']>;
  readonly documentStorageType_contains?: Maybe<Scalars['String']>;
  readonly documentStorageType_ends_with?: Maybe<Scalars['String']>;
  readonly documentStorageType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly documentStorageType_not?: Maybe<Scalars['String']>;
  readonly documentStorageType_not_contains?: Maybe<Scalars['String']>;
  readonly documentStorageType_not_ends_with?: Maybe<Scalars['String']>;
  readonly documentStorageType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly documentStorageType_not_starts_with?: Maybe<Scalars['String']>;
  readonly documentStorageType_starts_with?: Maybe<Scalars['String']>;
  readonly faxBy?: Maybe<Scalars['String']>;
  readonly faxBy_contains?: Maybe<Scalars['String']>;
  readonly faxBy_ends_with?: Maybe<Scalars['String']>;
  readonly faxBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxBy_not?: Maybe<Scalars['String']>;
  readonly faxBy_not_contains?: Maybe<Scalars['String']>;
  readonly faxBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly faxBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly faxBy_starts_with?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly faxNumber_contains?: Maybe<Scalars['String']>;
  readonly faxNumber_ends_with?: Maybe<Scalars['String']>;
  readonly faxNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxNumber_not?: Maybe<Scalars['String']>;
  readonly faxNumber_not_contains?: Maybe<Scalars['String']>;
  readonly faxNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly faxNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly faxNumber_starts_with?: Maybe<Scalars['String']>;
  readonly faxTo?: Maybe<Scalars['String']>;
  readonly faxTo_contains?: Maybe<Scalars['String']>;
  readonly faxTo_ends_with?: Maybe<Scalars['String']>;
  readonly faxTo_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxTo_not?: Maybe<Scalars['String']>;
  readonly faxTo_not_contains?: Maybe<Scalars['String']>;
  readonly faxTo_not_ends_with?: Maybe<Scalars['String']>;
  readonly faxTo_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly faxTo_not_starts_with?: Maybe<Scalars['String']>;
  readonly faxTo_starts_with?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_contains?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_ends_with?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupMissingPageNumbers_not?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_not_contains?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_not_ends_with?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupMissingPageNumbers_not_starts_with?: Maybe<Scalars['String']>;
  readonly groupMissingPageNumbers_starts_with?: Maybe<Scalars['String']>;
  readonly groupName?: Maybe<Scalars['String']>;
  readonly groupName_contains?: Maybe<Scalars['String']>;
  readonly groupName_ends_with?: Maybe<Scalars['String']>;
  readonly groupName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupName_not?: Maybe<Scalars['String']>;
  readonly groupName_not_contains?: Maybe<Scalars['String']>;
  readonly groupName_not_ends_with?: Maybe<Scalars['String']>;
  readonly groupName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupName_not_starts_with?: Maybe<Scalars['String']>;
  readonly groupName_starts_with?: Maybe<Scalars['String']>;
  readonly groupPageNumbers?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_contains?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_ends_with?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupPageNumbers_not?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_not_contains?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_not_ends_with?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly groupPageNumbers_not_starts_with?: Maybe<Scalars['String']>;
  readonly groupPageNumbers_starts_with?: Maybe<Scalars['String']>;
  readonly numberofPages?: Maybe<Scalars['String']>;
  readonly numberofPages_contains?: Maybe<Scalars['String']>;
  readonly numberofPages_ends_with?: Maybe<Scalars['String']>;
  readonly numberofPages_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly numberofPages_not?: Maybe<Scalars['String']>;
  readonly numberofPages_not_contains?: Maybe<Scalars['String']>;
  readonly numberofPages_not_ends_with?: Maybe<Scalars['String']>;
  readonly numberofPages_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly numberofPages_not_starts_with?: Maybe<Scalars['String']>;
  readonly numberofPages_starts_with?: Maybe<Scalars['String']>;
  readonly OR?: Maybe<ReadonlyArray<DocumentInboundFaxFilter>>;
  readonly pageRange?: Maybe<Scalars['String']>;
  readonly pageRange_contains?: Maybe<Scalars['String']>;
  readonly pageRange_ends_with?: Maybe<Scalars['String']>;
  readonly pageRange_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pageRange_not?: Maybe<Scalars['String']>;
  readonly pageRange_not_contains?: Maybe<Scalars['String']>;
  readonly pageRange_not_ends_with?: Maybe<Scalars['String']>;
  readonly pageRange_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pageRange_not_starts_with?: Maybe<Scalars['String']>;
  readonly pageRange_starts_with?: Maybe<Scalars['String']>;
  readonly parentId?: Maybe<Scalars['Int']>;
  readonly parentId_gt?: Maybe<Scalars['Int']>;
  readonly parentId_gte?: Maybe<Scalars['Int']>;
  readonly parentId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly parentId_lt?: Maybe<Scalars['Int']>;
  readonly parentId_lte?: Maybe<Scalars['Int']>;
  readonly parentId_not?: Maybe<Scalars['Int']>;
  readonly parentId_not_gt?: Maybe<Scalars['Int']>;
  readonly parentId_not_gte?: Maybe<Scalars['Int']>;
  readonly parentId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly parentId_not_lt?: Maybe<Scalars['Int']>;
  readonly parentId_not_lte?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly priority_contains?: Maybe<Scalars['String']>;
  readonly priority_ends_with?: Maybe<Scalars['String']>;
  readonly priority_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priority_not?: Maybe<Scalars['String']>;
  readonly priority_not_contains?: Maybe<Scalars['String']>;
  readonly priority_not_ends_with?: Maybe<Scalars['String']>;
  readonly priority_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priority_not_starts_with?: Maybe<Scalars['String']>;
  readonly priority_starts_with?: Maybe<Scalars['String']>;
  readonly processedBy?: Maybe<Scalars['String']>;
  readonly processedBy_contains?: Maybe<Scalars['String']>;
  readonly processedBy_ends_with?: Maybe<Scalars['String']>;
  readonly processedBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly processedBy_not?: Maybe<Scalars['String']>;
  readonly processedBy_not_contains?: Maybe<Scalars['String']>;
  readonly processedBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly processedBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly processedBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly processedBy_starts_with?: Maybe<Scalars['String']>;
  readonly processedDate?: Maybe<Scalars['DateTime']>;
  readonly processedDate_gt?: Maybe<Scalars['DateTime']>;
  readonly processedDate_gte?: Maybe<Scalars['DateTime']>;
  readonly processedDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly processedDate_lt?: Maybe<Scalars['DateTime']>;
  readonly processedDate_lte?: Maybe<Scalars['DateTime']>;
  readonly processedDate_not?: Maybe<Scalars['DateTime']>;
  readonly processedDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly processedDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly processedDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly processedDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly processedDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly reasonDeleted?: Maybe<Scalars['String']>;
  readonly reasonDeleted_contains?: Maybe<Scalars['String']>;
  readonly reasonDeleted_ends_with?: Maybe<Scalars['String']>;
  readonly reasonDeleted_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly reasonDeleted_not?: Maybe<Scalars['String']>;
  readonly reasonDeleted_not_contains?: Maybe<Scalars['String']>;
  readonly reasonDeleted_not_ends_with?: Maybe<Scalars['String']>;
  readonly reasonDeleted_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly reasonDeleted_not_starts_with?: Maybe<Scalars['String']>;
  readonly reasonDeleted_starts_with?: Maybe<Scalars['String']>;
  readonly receivedDate?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_gt?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_gte?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly receivedDate_lt?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_lte?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_not?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_not_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly receivedDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly receivedDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly status_contains?: Maybe<Scalars['String']>;
  readonly status_ends_with?: Maybe<Scalars['String']>;
  readonly status_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly status_not?: Maybe<Scalars['String']>;
  readonly status_not_contains?: Maybe<Scalars['String']>;
  readonly status_not_ends_with?: Maybe<Scalars['String']>;
  readonly status_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly status_not_starts_with?: Maybe<Scalars['String']>;
  readonly status_starts_with?: Maybe<Scalars['String']>;
  readonly takenAt?: Maybe<Scalars['DateTime']>;
  readonly takenAt_gt?: Maybe<Scalars['DateTime']>;
  readonly takenAt_gte?: Maybe<Scalars['DateTime']>;
  readonly takenAt_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly takenAt_lt?: Maybe<Scalars['DateTime']>;
  readonly takenAt_lte?: Maybe<Scalars['DateTime']>;
  readonly takenAt_not?: Maybe<Scalars['DateTime']>;
  readonly takenAt_not_gt?: Maybe<Scalars['DateTime']>;
  readonly takenAt_not_gte?: Maybe<Scalars['DateTime']>;
  readonly takenAt_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly takenAt_not_lt?: Maybe<Scalars['DateTime']>;
  readonly takenAt_not_lte?: Maybe<Scalars['DateTime']>;
  readonly takenBy?: Maybe<Scalars['String']>;
  readonly takenBy_contains?: Maybe<Scalars['String']>;
  readonly takenBy_ends_with?: Maybe<Scalars['String']>;
  readonly takenBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly takenBy_not?: Maybe<Scalars['String']>;
  readonly takenBy_not_contains?: Maybe<Scalars['String']>;
  readonly takenBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly takenBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly takenBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly takenBy_starts_with?: Maybe<Scalars['String']>;
};

export type DocumentInboundFaxSort = {
  readonly assignmentInProgress?: Maybe<SortOperationKind>;
  readonly directFaxDate?: Maybe<SortOperationKind>;
  readonly directFaxId?: Maybe<SortOperationKind>;
  readonly documentFaxQueueID?: Maybe<SortOperationKind>;
  readonly documentId?: Maybe<SortOperationKind>;
  readonly documentInboundFaxID?: Maybe<SortOperationKind>;
  readonly documentStorageId?: Maybe<SortOperationKind>;
  readonly documentStorageType?: Maybe<SortOperationKind>;
  readonly faxBy?: Maybe<SortOperationKind>;
  readonly faxNumber?: Maybe<SortOperationKind>;
  readonly faxTo?: Maybe<SortOperationKind>;
  readonly groupMissingPageNumbers?: Maybe<SortOperationKind>;
  readonly groupName?: Maybe<SortOperationKind>;
  readonly groupPageNumbers?: Maybe<SortOperationKind>;
  readonly numberofPages?: Maybe<SortOperationKind>;
  readonly pageRange?: Maybe<SortOperationKind>;
  readonly parentId?: Maybe<SortOperationKind>;
  readonly priority?: Maybe<SortOperationKind>;
  readonly processedBy?: Maybe<SortOperationKind>;
  readonly processedDate?: Maybe<SortOperationKind>;
  readonly reasonDeleted?: Maybe<SortOperationKind>;
  readonly receivedDate?: Maybe<SortOperationKind>;
  readonly status?: Maybe<SortOperationKind>;
  readonly takenAt?: Maybe<SortOperationKind>;
  readonly takenBy?: Maybe<SortOperationKind>;
};

export type DocumentList = {
  readonly __typename?: 'DocumentList';
  readonly coverPageRequired?: Maybe<Scalars['Boolean']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly documentListId: Scalars['Int'];
  readonly documentsDocTypes?: Maybe<ReadonlyArray<Maybe<DocumentsDocTypes>>>;
  readonly documentSource?: Maybe<Scalars['String']>;
  readonly documentType: Scalars['String'];
  readonly fileType?: Maybe<Scalars['String']>;
  readonly groupName?: Maybe<Scalars['String']>;
  readonly groupName2?: Maybe<Scalars['String']>;
  readonly isCoverPage?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly notificationTemplate?: Maybe<Scalars['String']>;
  readonly numberofPages?: Maybe<Scalars['Int']>;
  readonly programId: Scalars['Int'];
  readonly sortOrder?: Maybe<Scalars['Float']>;
  readonly templateLocation?: Maybe<ReadonlyArray<Scalars['Byte']>>;
  readonly versionNumber?: Maybe<Scalars['String']>;
};

export type Documents = {
  readonly __typename?: 'Documents';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy: Scalars['String'];
  readonly documentAttachmentsDocTypes?: Maybe<ReadonlyArray<Maybe<DocumentAttachmentsDocTypes>>>;
  readonly documentId?: Maybe<Scalars['ID']>;
  readonly documentsDocTypes?: Maybe<ReadonlyArray<Maybe<DocumentsDocTypes>>>;
  readonly entityId?: Maybe<Scalars['ID']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly fileData?: Maybe<TblFiles>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileStorageId?: Maybe<Scalars['ID']>;
  readonly formComplete?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programId?: Maybe<Scalars['ID']>;
  readonly sigRequired?: Maybe<Scalars['Boolean']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly totalPages?: Maybe<Scalars['Int']>;
};

export type DocumentsDocTypes = {
  readonly __typename?: 'DocumentsDocTypes';
  readonly attachmentDocumentId?: Maybe<Scalars['Int']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly document?: Maybe<Documents>;
  readonly documentId: Scalars['Int'];
  readonly documentList?: Maybe<DocumentList>;
  readonly documentListId: Scalars['Int'];
  readonly documentsDocTypesId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly reviewed?: Maybe<Scalars['DateTime']>;
  readonly reviewedBy?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['Float']>;
};

export type DocumentsFilter = {
  readonly AND?: Maybe<ReadonlyArray<DocumentsFilter>>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdBy_contains?: Maybe<Scalars['String']>;
  readonly createdBy_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly createdBy_not?: Maybe<Scalars['String']>;
  readonly createdBy_not_contains?: Maybe<Scalars['String']>;
  readonly createdBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_not_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly createdBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly createdBy_starts_with?: Maybe<Scalars['String']>;
  readonly created_gt?: Maybe<Scalars['DateTime']>;
  readonly created_gte?: Maybe<Scalars['DateTime']>;
  readonly created_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly created_lt?: Maybe<Scalars['DateTime']>;
  readonly created_lte?: Maybe<Scalars['DateTime']>;
  readonly created_not?: Maybe<Scalars['DateTime']>;
  readonly created_not_gt?: Maybe<Scalars['DateTime']>;
  readonly created_not_gte?: Maybe<Scalars['DateTime']>;
  readonly created_not_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly created_not_lt?: Maybe<Scalars['DateTime']>;
  readonly created_not_lte?: Maybe<Scalars['DateTime']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly deleted_not?: Maybe<Scalars['Boolean']>;
  readonly documentId?: Maybe<Scalars['Int']>;
  readonly documentId_gt?: Maybe<Scalars['Int']>;
  readonly documentId_gte?: Maybe<Scalars['Int']>;
  readonly documentId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentId_lt?: Maybe<Scalars['Int']>;
  readonly documentId_lte?: Maybe<Scalars['Int']>;
  readonly documentId_not?: Maybe<Scalars['Int']>;
  readonly documentId_not_gt?: Maybe<Scalars['Int']>;
  readonly documentId_not_gte?: Maybe<Scalars['Int']>;
  readonly documentId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly documentId_not_lt?: Maybe<Scalars['Int']>;
  readonly documentId_not_lte?: Maybe<Scalars['Int']>;
  readonly entityId?: Maybe<Scalars['Int']>;
  readonly entityId_gt?: Maybe<Scalars['Int']>;
  readonly entityId_gte?: Maybe<Scalars['Int']>;
  readonly entityId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly entityId_lt?: Maybe<Scalars['Int']>;
  readonly entityId_lte?: Maybe<Scalars['Int']>;
  readonly entityId_not?: Maybe<Scalars['Int']>;
  readonly entityId_not_gt?: Maybe<Scalars['Int']>;
  readonly entityId_not_gte?: Maybe<Scalars['Int']>;
  readonly entityId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly entityId_not_lt?: Maybe<Scalars['Int']>;
  readonly entityId_not_lte?: Maybe<Scalars['Int']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly entityName_contains?: Maybe<Scalars['String']>;
  readonly entityName_ends_with?: Maybe<Scalars['String']>;
  readonly entityName_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly entityName_not?: Maybe<Scalars['String']>;
  readonly entityName_not_contains?: Maybe<Scalars['String']>;
  readonly entityName_not_ends_with?: Maybe<Scalars['String']>;
  readonly entityName_not_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly entityName_not_starts_with?: Maybe<Scalars['String']>;
  readonly entityName_starts_with?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId_contains?: Maybe<Scalars['String']>;
  readonly externalId_ends_with?: Maybe<Scalars['String']>;
  readonly externalId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly externalId_not?: Maybe<Scalars['String']>;
  readonly externalId_not_contains?: Maybe<Scalars['String']>;
  readonly externalId_not_ends_with?: Maybe<Scalars['String']>;
  readonly externalId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly externalId_not_starts_with?: Maybe<Scalars['String']>;
  readonly externalId_starts_with?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileName_contains?: Maybe<Scalars['String']>;
  readonly fileName_ends_with?: Maybe<Scalars['String']>;
  readonly fileName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly fileName_not?: Maybe<Scalars['String']>;
  readonly fileName_not_contains?: Maybe<Scalars['String']>;
  readonly fileName_not_ends_with?: Maybe<Scalars['String']>;
  readonly fileName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly fileName_not_starts_with?: Maybe<Scalars['String']>;
  readonly fileName_starts_with?: Maybe<Scalars['String']>;
  readonly fileStorageId?: Maybe<Scalars['Int']>;
  readonly fileStorageId_gt?: Maybe<Scalars['Int']>;
  readonly fileStorageId_gte?: Maybe<Scalars['Int']>;
  readonly fileStorageId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly fileStorageId_lt?: Maybe<Scalars['Int']>;
  readonly fileStorageId_lte?: Maybe<Scalars['Int']>;
  readonly fileStorageId_not?: Maybe<Scalars['Int']>;
  readonly fileStorageId_not_gt?: Maybe<Scalars['Int']>;
  readonly fileStorageId_not_gte?: Maybe<Scalars['Int']>;
  readonly fileStorageId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly fileStorageId_not_lt?: Maybe<Scalars['Int']>;
  readonly fileStorageId_not_lte?: Maybe<Scalars['Int']>;
  readonly formComplete?: Maybe<Scalars['String']>;
  readonly formComplete_contains?: Maybe<Scalars['String']>;
  readonly formComplete_ends_with?: Maybe<Scalars['String']>;
  readonly formComplete_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly formComplete_not?: Maybe<Scalars['String']>;
  readonly formComplete_not_contains?: Maybe<Scalars['String']>;
  readonly formComplete_not_ends_with?: Maybe<Scalars['String']>;
  readonly formComplete_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly formComplete_not_starts_with?: Maybe<Scalars['String']>;
  readonly formComplete_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdated_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_lte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_not_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_lte?: Maybe<Scalars['DateTime']>;
  readonly OR?: Maybe<ReadonlyArray<DocumentsFilter>>;
  readonly programId?: Maybe<Scalars['Int']>;
  readonly programId_gt?: Maybe<Scalars['Int']>;
  readonly programId_gte?: Maybe<Scalars['Int']>;
  readonly programId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_lt?: Maybe<Scalars['Int']>;
  readonly programId_lte?: Maybe<Scalars['Int']>;
  readonly programId_not?: Maybe<Scalars['Int']>;
  readonly programId_not_gt?: Maybe<Scalars['Int']>;
  readonly programId_not_gte?: Maybe<Scalars['Int']>;
  readonly programId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_not_lt?: Maybe<Scalars['Int']>;
  readonly programId_not_lte?: Maybe<Scalars['Int']>;
  readonly sigRequired?: Maybe<Scalars['Boolean']>;
  readonly sigRequired_not?: Maybe<Scalars['Boolean']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly source_contains?: Maybe<Scalars['String']>;
  readonly source_ends_with?: Maybe<Scalars['String']>;
  readonly source_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly source_not?: Maybe<Scalars['String']>;
  readonly source_not_contains?: Maybe<Scalars['String']>;
  readonly source_not_ends_with?: Maybe<Scalars['String']>;
  readonly source_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly source_not_starts_with?: Maybe<Scalars['String']>;
  readonly source_starts_with?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly status_contains?: Maybe<Scalars['String']>;
  readonly status_ends_with?: Maybe<Scalars['String']>;
  readonly status_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly status_not?: Maybe<Scalars['String']>;
  readonly status_not_contains?: Maybe<Scalars['String']>;
  readonly status_not_ends_with?: Maybe<Scalars['String']>;
  readonly status_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly status_not_starts_with?: Maybe<Scalars['String']>;
  readonly status_starts_with?: Maybe<Scalars['String']>;
  readonly totalPages?: Maybe<Scalars['Int']>;
  readonly totalPages_gt?: Maybe<Scalars['Int']>;
  readonly totalPages_gte?: Maybe<Scalars['Int']>;
  readonly totalPages_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly totalPages_lt?: Maybe<Scalars['Int']>;
  readonly totalPages_lte?: Maybe<Scalars['Int']>;
  readonly totalPages_not?: Maybe<Scalars['Int']>;
  readonly totalPages_not_gt?: Maybe<Scalars['Int']>;
  readonly totalPages_not_gte?: Maybe<Scalars['Int']>;
  readonly totalPages_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly totalPages_not_lt?: Maybe<Scalars['Int']>;
  readonly totalPages_not_lte?: Maybe<Scalars['Int']>;
};

export type DrugCptCodeDetail = {
  readonly __typename?: 'DrugCPTCodeDetail';
  readonly cptCode?: Maybe<Scalars['String']>;
};

export type DrugConfigurationAttributes = {
  readonly __typename?: 'DrugConfigurationAttributes';
  readonly drugConfigurationAttributeID: Scalars['Uuid'];
  readonly drugId: Scalars['Uuid'];
  readonly faxTo?: Maybe<Scalars['String']>;
};

export type DrugCptCode = {
  readonly __typename?: 'DrugCptCode';
  readonly cptCode?: Maybe<Scalars['String']>;
  readonly cptCodeDescription?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drugCptCodeId: Scalars['Int'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
};

export type DrugDetail = {
  readonly __typename?: 'DrugDetail';
  readonly daysSupply?: Maybe<Scalars['String']>;
  readonly drugDescription?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['String']>;
};

export type DrugInfo = {
  readonly __typename?: 'DrugInfo';
  readonly drugBrandName?: Maybe<Scalars['String']>;
  readonly drugCptCode?: Maybe<ReadonlyArray<Maybe<DrugCptCodeDetail>>>;
  readonly drugDetails?: Maybe<ReadonlyArray<Maybe<DrugDetail>>>;
  readonly drugGenericName?: Maybe<Scalars['String']>;
  readonly drugId: Scalars['Int'];
  readonly drugLabelName?: Maybe<Scalars['String']>;
  readonly jCode1?: Maybe<Scalars['String']>;
  readonly jCode2?: Maybe<Scalars['String']>;
  readonly manufacturer?: Maybe<Scalars['String']>;
};

export type Drugs = {
  readonly __typename?: 'Drugs';
  readonly applicationDrugServices?: Maybe<ReadonlyArray<Maybe<ApplicationDrugServices>>>;
  readonly cptcode?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly drugId: Scalars['Uuid'];
  readonly jcode: Scalars['String'];
  readonly name: Scalars['String'];
  readonly ndc?: Maybe<Scalars['String']>;
  readonly serviceTypes?: Maybe<ReadonlyArray<Maybe<ServiceTypes>>>;
};

export type EHipaainvites = {
  readonly __typename?: 'EHipaainvites';
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly drug?: Maybe<Drugs>;
  readonly drugId: Scalars['Uuid'];
  readonly eHipaainviteId: Scalars['Uuid'];
  readonly enrollment?: Maybe<Enrollment>;
  readonly enrollmentId: Scalars['Uuid'];
  readonly inviteId: Scalars['Long'];
  readonly lastUpdate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly pafId: Scalars['Long'];
  readonly patient?: Maybe<Patients>;
  readonly patientId: Scalars['Uuid'];
  readonly status?: Maybe<Scalars['String']>;
};

export type EnrollContactInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
};

export type EnrollContactPhoneNumberInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type EnrollOrganizationAddressInput = {
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type EnrollOrganizationInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

export type EnrollOrganizationWithAddressInput = {
  readonly enrollOrganization?: Maybe<EnrollOrganizationInput>;
  readonly enrollOrganizationAddress?: Maybe<EnrollOrganizationAddressInput>;
};

export type EnrollPatientAddressInput = {
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly addressType?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type EnrollPatientConsent = {
  readonly __typename?: 'EnrollPatientConsent';
  readonly consentDate: Scalars['DateTime'];
  readonly consentType?: Maybe<Scalars['String']>;
  readonly consentTypeDescription?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly optedOut: Scalars['Boolean'];
  readonly optedOutDate?: Maybe<Scalars['DateTime']>;
  readonly pdfPath?: Maybe<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export type EnrollPatientContactInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly isLegallyAuthorized: Scalars['Boolean'];
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly relationShipType?: Maybe<Scalars['String']>;
};

export type EnrollPatientInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly preferredLanguage?: Maybe<Scalars['String']>;
  readonly preferredLanguageOther?: Maybe<Scalars['String']>;
};

export type EnrollPatientPhoneNumberInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly oktoLeaveMessage: Scalars['Boolean'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type EnrollPhysicianInput = {
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly hin?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly licensingState?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly stateLicenseNumber?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
};

export type Enrollment = {
  readonly __typename?: 'Enrollment';
  readonly additionalPhysicians?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocument?: Maybe<ReadonlyArray<Scalars['Byte']>>;
  readonly administeringInformationAdditionalDocumentName?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2?: Maybe<Scalars['String']>;
  readonly administeringInformationCity?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly administeringInformationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider?: Maybe<Scalars['String']>;
  readonly administeringInformationName?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers?: Maybe<Scalars['String']>;
  readonly administeringInformationState?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId?: Maybe<Scalars['String']>;
  readonly administeringInformationType?: Maybe<Scalars['String']>;
  readonly administeringInformationZip?: Maybe<Scalars['String']>;
  readonly applicationId: Scalars['Uuid'];
  readonly cancelUrl?: Maybe<Scalars['String']>;
  readonly cardHolderData?: Maybe<Scalars['String']>;
  readonly caregiverEmail?: Maybe<Scalars['String']>;
  readonly caregiverFirstName?: Maybe<Scalars['String']>;
  readonly caregiverGender?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone?: Maybe<Scalars['String']>;
  readonly caregiverLastName?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication?: Maybe<Scalars['String']>;
  readonly caregiverRelationship?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalData?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificDate1?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificField1?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTreatmentStartDate?: Maybe<Scalars['DateTime']>;
  readonly commercialOrprivate?: Maybe<Scalars['Boolean']>;
  readonly confirmationNumber?: Maybe<Scalars['String']>;
  readonly copayAnnualMax?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance?: Maybe<Scalars['Decimal']>;
  readonly copayCardNumber?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly customFields?: Maybe<Scalars['String']>;
  readonly documents?: Maybe<Scalars['String']>;
  readonly drugDaysSupply?: Maybe<Scalars['Int']>;
  readonly drugId: Scalars['Uuid'];
  readonly drugJcode?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly drugQuantity?: Maybe<Scalars['Int']>;
  readonly enrollmentDocument?: Maybe<Scalars['String']>;
  readonly enrollmentId: Scalars['Uuid'];
  readonly enrollmentStatus?: Maybe<Scalars['String']>;
  readonly finishUrl?: Maybe<Scalars['String']>;
  readonly goBackUrl?: Maybe<Scalars['String']>;
  readonly hasCaregiver?: Maybe<Scalars['Boolean']>;
  readonly hubEndDate?: Maybe<Scalars['DateTime']>;
  readonly hubPatientID?: Maybe<Scalars['String']>;
  readonly hubStartDate?: Maybe<Scalars['DateTime']>;
  readonly hubStatus?: Maybe<Scalars['String']>;
  readonly insuranceDocuments?: Maybe<Scalars['String']>;
  readonly isAgreeToReceiveTextMessages?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative?: Maybe<Scalars['Boolean']>;
  readonly isOtherInsurance?: Maybe<Scalars['Boolean']>;
  readonly medicaid?: Maybe<Scalars['Boolean']>;
  readonly medicareAdvantage?: Maybe<Scalars['Boolean']>;
  readonly medicareId?: Maybe<Scalars['String']>;
  readonly medicarePartD?: Maybe<Scalars['Boolean']>;
  readonly organizationAddress1?: Maybe<Scalars['String']>;
  readonly organizationAddress2?: Maybe<Scalars['String']>;
  readonly organizationAddressType?: Maybe<Scalars['String']>;
  readonly organizationCity?: Maybe<Scalars['String']>;
  readonly organizationContactEmail?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName?: Maybe<Scalars['String']>;
  readonly organizationContactGender?: Maybe<Scalars['String']>;
  readonly organizationContactLastName?: Maybe<Scalars['String']>;
  readonly organizationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly organizationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber?: Maybe<Scalars['String']>;
  readonly organizationEmail?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly organizationFax?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationNpi?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationState?: Maybe<Scalars['String']>;
  readonly organizationTaxId?: Maybe<Scalars['String']>;
  readonly organizationZip?: Maybe<Scalars['String']>;
  readonly otherInsurance?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly otherInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly otherInsurancePayerID?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData?: Maybe<Scalars['String']>;
  readonly pAPFinancialData?: Maybe<Scalars['String']>;
  readonly patientAddress1?: Maybe<Scalars['String']>;
  readonly patientAddress2?: Maybe<Scalars['String']>;
  readonly patientAddressType?: Maybe<Scalars['String']>;
  readonly patientAttestationText?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientConsentDocument?: Maybe<ReadonlyArray<Scalars['Byte']>>;
  readonly patientConsentDocumentName?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientConsentType?: Maybe<Scalars['String']>;
  readonly patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly patientEmail?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientHasInsurance?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientPreferredCommunication?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther?: Maybe<Scalars['String']>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientUSResidence?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumberOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientZip?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceIsMedicarePartD?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsuranceName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsurancePayerId?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn?: Maybe<Scalars['String']>;
  readonly physicianAttestationCanContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationReceived?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationTerms?: Maybe<Scalars['String']>;
  readonly physicianAttestationText?: Maybe<Scalars['String']>;
  readonly physicianBusinessAssociateAggrement?: Maybe<Scalars['Boolean']>;
  readonly physicianConsentCertified?: Maybe<Scalars['Boolean']>;
  readonly physicianDEANumber?: Maybe<Scalars['String']>;
  readonly physicianDoNotContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianGender?: Maybe<Scalars['String']>;
  readonly physicianHin?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLicensingState?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber?: Maybe<Scalars['String']>;
  readonly physicianMiddleName?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber?: Maybe<Scalars['String']>;
  readonly physicianReleasePatientInformation?: Maybe<Scalars['Boolean']>;
  readonly physicianRequestForwardPrescription?: Maybe<Scalars['Boolean']>;
  readonly physicianSpecialty?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber?: Maybe<Scalars['String']>;
  readonly physicianTaxId?: Maybe<Scalars['String']>;
  readonly physicianType?: Maybe<Scalars['String']>;
  readonly preferredLanguages?: Maybe<Scalars['String']>;
  readonly prescribingRole?: Maybe<Scalars['String']>;
  readonly prescribingType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly primaryMedicalInsurancePayerId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly priorMedications?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly secondaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly serviceSpecificData?: Maybe<Scalars['String']>;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly serviceTypeID?: Maybe<Scalars['Uuid']>;
  readonly signatures?: Maybe<Scalars['String']>;
  readonly submitStatus?: Maybe<Scalars['String']>;
  readonly submittedDate?: Maybe<Scalars['DateTime']>;
  readonly tertiaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly tertiaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly treatingRole?: Maybe<Scalars['String']>;
  readonly treatingType?: Maybe<Scalars['String']>;
  readonly userFirstName?: Maybe<Scalars['String']>;
  readonly userGender?: Maybe<Scalars['String']>;
  readonly userLastName?: Maybe<Scalars['String']>;
  readonly userMiddleName?: Maybe<Scalars['String']>;
  readonly userName: Scalars['String'];
  readonly userOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly userPhoneNumber?: Maybe<Scalars['String']>;
  readonly userPhoneType?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
  readonly vaormilitary?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentFilter = {
  readonly additionalPhysicians?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_contains?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_ends_with?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly additionalPhysicians_not?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_not_contains?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_not_ends_with?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly additionalPhysicians_not_starts_with?: Maybe<Scalars['String']>;
  readonly additionalPhysicians_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAdditionalDocumentName_not?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAdditionalDocumentName_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAdditionalDocumentName_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAddress1_not?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAddress1_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAddress2_not?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationAddress2_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationCity?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationCity_not?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationCity_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationCity_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactEmail_not?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactFirstName_not?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactLastName_not?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly administeringInformationContactOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly administeringInformationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactPhoneType_not?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationContactPhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationDeaNumber_not?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationDeaNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationEmail_not?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationExternalId_not?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationExternalId_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationExternalId_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationInsuranceProvider_not?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationInsuranceProvider_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationName?: Maybe<Scalars['String']>;
  readonly administeringInformationName_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationName_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationName_not?: Maybe<Scalars['String']>;
  readonly administeringInformationName_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationName_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationName_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationName_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationNpi_not?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationNpi_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationPhoneNumbers_not?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationPhoneNumbers_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationState?: Maybe<Scalars['String']>;
  readonly administeringInformationState_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationState_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationState_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationState_not?: Maybe<Scalars['String']>;
  readonly administeringInformationState_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationState_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationState_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationState_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationState_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationTaxId_not?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationTaxId_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationType?: Maybe<Scalars['String']>;
  readonly administeringInformationType_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationType_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationType_not?: Maybe<Scalars['String']>;
  readonly administeringInformationType_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationType_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationType_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationType_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationZip?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationZip_not?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_not_contains?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_not_ends_with?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly administeringInformationZip_not_starts_with?: Maybe<Scalars['String']>;
  readonly administeringInformationZip_starts_with?: Maybe<Scalars['String']>;
  readonly AND?: Maybe<ReadonlyArray<EnrollmentFilter>>;
  readonly applicationId?: Maybe<Scalars['Uuid']>;
  readonly applicationId_gt?: Maybe<Scalars['Uuid']>;
  readonly applicationId_gte?: Maybe<Scalars['Uuid']>;
  readonly applicationId_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly applicationId_lt?: Maybe<Scalars['Uuid']>;
  readonly applicationId_lte?: Maybe<Scalars['Uuid']>;
  readonly applicationId_not?: Maybe<Scalars['Uuid']>;
  readonly applicationId_not_gt?: Maybe<Scalars['Uuid']>;
  readonly applicationId_not_gte?: Maybe<Scalars['Uuid']>;
  readonly applicationId_not_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly applicationId_not_lt?: Maybe<Scalars['Uuid']>;
  readonly applicationId_not_lte?: Maybe<Scalars['Uuid']>;
  readonly cancelUrl?: Maybe<Scalars['String']>;
  readonly cancelUrl_contains?: Maybe<Scalars['String']>;
  readonly cancelUrl_ends_with?: Maybe<Scalars['String']>;
  readonly cancelUrl_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly cancelUrl_not?: Maybe<Scalars['String']>;
  readonly cancelUrl_not_contains?: Maybe<Scalars['String']>;
  readonly cancelUrl_not_ends_with?: Maybe<Scalars['String']>;
  readonly cancelUrl_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly cancelUrl_not_starts_with?: Maybe<Scalars['String']>;
  readonly cancelUrl_starts_with?: Maybe<Scalars['String']>;
  readonly cardHolderData?: Maybe<Scalars['String']>;
  readonly cardHolderData_contains?: Maybe<Scalars['String']>;
  readonly cardHolderData_ends_with?: Maybe<Scalars['String']>;
  readonly cardHolderData_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly cardHolderData_not?: Maybe<Scalars['String']>;
  readonly cardHolderData_not_contains?: Maybe<Scalars['String']>;
  readonly cardHolderData_not_ends_with?: Maybe<Scalars['String']>;
  readonly cardHolderData_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly cardHolderData_not_starts_with?: Maybe<Scalars['String']>;
  readonly cardHolderData_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverEmail?: Maybe<Scalars['String']>;
  readonly caregiverEmail_contains?: Maybe<Scalars['String']>;
  readonly caregiverEmail_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverEmail_not?: Maybe<Scalars['String']>;
  readonly caregiverEmail_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverEmail_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverFirstName?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_contains?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverFirstName_not?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverGender?: Maybe<Scalars['String']>;
  readonly caregiverGender_contains?: Maybe<Scalars['String']>;
  readonly caregiverGender_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverGender_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverGender_not?: Maybe<Scalars['String']>;
  readonly caregiverGender_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverGender_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverGender_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverGender_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverGender_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_contains?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverHomePhone_not?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverHomePhone_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverLastName?: Maybe<Scalars['String']>;
  readonly caregiverLastName_contains?: Maybe<Scalars['String']>;
  readonly caregiverLastName_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverLastName_not?: Maybe<Scalars['String']>;
  readonly caregiverLastName_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverLastName_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_contains?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverMiddleName_not?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverMiddleName_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_contains?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverMobilePhone_not?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverMobilePhone_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_contains?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverPreferredCommunication_not?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverPreferredCommunication_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverRelationship?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_contains?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverRelationship_not?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly caregiverRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly caregiverRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_contains?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_ends_with?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly checkPharmacyInsurance_not?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_not_contains?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_not_ends_with?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly checkPharmacyInsurance_not_starts_with?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode1Quantity_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_not?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_not_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_not_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode1Quantity_not_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1Quantity_not_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode1_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode1_not?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode1_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode2Quantity_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_not?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_not_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_not_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode2Quantity_not_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2Quantity_not_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode2_not?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode2_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode3Quantity_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_not?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_not_gt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_not_gte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly clinicalCptadminCode3Quantity_not_lt?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3Quantity_not_lte?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode3_not?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalCptadminCode3_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalData?: Maybe<Scalars['String']>;
  readonly clinicalData_contains?: Maybe<Scalars['String']>;
  readonly clinicalData_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalData_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalData_not?: Maybe<Scalars['String']>;
  readonly clinicalData_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalData_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalData_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalData_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalData_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificDate1?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalDrugSpecificDate1_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_lte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_not?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_not_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_not_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalDrugSpecificDate1_not_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate1_not_lte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalDrugSpecificDate2_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_lte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_not?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_not_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_not_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalDrugSpecificDate2_not_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2_not_lte?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificField1?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField1_not?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField1_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField1_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField2_not?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField2_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField3_not?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField3_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField4_not?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField4_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField5_not?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalDrugSpecificField5_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_contains?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalPrimaryIcd10diagnosisCode_not?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalPrimaryIcd10diagnosisCode_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_contains?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalSecondaryIcd10diagnosisCode_not?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalSecondaryIcd10diagnosisCode_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_contains?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalTertiaryIcd10diagnosisCode_not?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_not_contains?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_not_ends_with?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly clinicalTertiaryIcd10diagnosisCode_not_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode_starts_with?: Maybe<Scalars['String']>;
  readonly clinicalTreatmentStartDate?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalTreatmentStartDate_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_lte?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_not?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly clinicalTreatmentStartDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly clinicalTreatmentStartDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly commercialOrprivate?: Maybe<Scalars['Boolean']>;
  readonly commercialOrprivate_not?: Maybe<Scalars['Boolean']>;
  readonly confirmationNumber?: Maybe<Scalars['String']>;
  readonly confirmationNumber_contains?: Maybe<Scalars['String']>;
  readonly confirmationNumber_ends_with?: Maybe<Scalars['String']>;
  readonly confirmationNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly confirmationNumber_not?: Maybe<Scalars['String']>;
  readonly confirmationNumber_not_contains?: Maybe<Scalars['String']>;
  readonly confirmationNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly confirmationNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly confirmationNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly confirmationNumber_starts_with?: Maybe<Scalars['String']>;
  readonly copayAnnualMax?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_gt?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_gte?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_in?: Maybe<ReadonlyArray<Maybe<Scalars['Decimal']>>>;
  readonly copayAnnualMax_lt?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_lte?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_not?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_not_gt?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_not_gte?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Decimal']>>>;
  readonly copayAnnualMax_not_lt?: Maybe<Scalars['Decimal']>;
  readonly copayAnnualMax_not_lte?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_gt?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_gte?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_in?: Maybe<ReadonlyArray<Maybe<Scalars['Decimal']>>>;
  readonly copayCardBalance_lt?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_lte?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_not?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_not_gt?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_not_gte?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Decimal']>>>;
  readonly copayCardBalance_not_lt?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance_not_lte?: Maybe<Scalars['Decimal']>;
  readonly copayCardNumber?: Maybe<Scalars['String']>;
  readonly copayCardNumber_contains?: Maybe<Scalars['String']>;
  readonly copayCardNumber_ends_with?: Maybe<Scalars['String']>;
  readonly copayCardNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly copayCardNumber_not?: Maybe<Scalars['String']>;
  readonly copayCardNumber_not_contains?: Maybe<Scalars['String']>;
  readonly copayCardNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly copayCardNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly copayCardNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly copayCardNumber_starts_with?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdBy_contains?: Maybe<Scalars['String']>;
  readonly createdBy_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly createdBy_not?: Maybe<Scalars['String']>;
  readonly createdBy_not_contains?: Maybe<Scalars['String']>;
  readonly createdBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly createdBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly createdBy_starts_with?: Maybe<Scalars['String']>;
  readonly created_gt?: Maybe<Scalars['DateTime']>;
  readonly created_gte?: Maybe<Scalars['DateTime']>;
  readonly created_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly created_lt?: Maybe<Scalars['DateTime']>;
  readonly created_lte?: Maybe<Scalars['DateTime']>;
  readonly created_not?: Maybe<Scalars['DateTime']>;
  readonly created_not_gt?: Maybe<Scalars['DateTime']>;
  readonly created_not_gte?: Maybe<Scalars['DateTime']>;
  readonly created_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly created_not_lt?: Maybe<Scalars['DateTime']>;
  readonly created_not_lte?: Maybe<Scalars['DateTime']>;
  readonly customFields?: Maybe<Scalars['String']>;
  readonly customFields_contains?: Maybe<Scalars['String']>;
  readonly customFields_ends_with?: Maybe<Scalars['String']>;
  readonly customFields_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly customFields_not?: Maybe<Scalars['String']>;
  readonly customFields_not_contains?: Maybe<Scalars['String']>;
  readonly customFields_not_ends_with?: Maybe<Scalars['String']>;
  readonly customFields_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly customFields_not_starts_with?: Maybe<Scalars['String']>;
  readonly customFields_starts_with?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly deleted_not?: Maybe<Scalars['Boolean']>;
  readonly documents?: Maybe<Scalars['String']>;
  readonly documents_contains?: Maybe<Scalars['String']>;
  readonly documents_ends_with?: Maybe<Scalars['String']>;
  readonly documents_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly documents_not?: Maybe<Scalars['String']>;
  readonly documents_not_contains?: Maybe<Scalars['String']>;
  readonly documents_not_ends_with?: Maybe<Scalars['String']>;
  readonly documents_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly documents_not_starts_with?: Maybe<Scalars['String']>;
  readonly documents_starts_with?: Maybe<Scalars['String']>;
  readonly drugDaysSupply?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_gt?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_gte?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly drugDaysSupply_lt?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_lte?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_not?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_not_gt?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_not_gte?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly drugDaysSupply_not_lt?: Maybe<Scalars['Int']>;
  readonly drugDaysSupply_not_lte?: Maybe<Scalars['Int']>;
  readonly drugId?: Maybe<Scalars['Uuid']>;
  readonly drugId_gt?: Maybe<Scalars['Uuid']>;
  readonly drugId_gte?: Maybe<Scalars['Uuid']>;
  readonly drugId_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly drugId_lt?: Maybe<Scalars['Uuid']>;
  readonly drugId_lte?: Maybe<Scalars['Uuid']>;
  readonly drugId_not?: Maybe<Scalars['Uuid']>;
  readonly drugId_not_gt?: Maybe<Scalars['Uuid']>;
  readonly drugId_not_gte?: Maybe<Scalars['Uuid']>;
  readonly drugId_not_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly drugId_not_lt?: Maybe<Scalars['Uuid']>;
  readonly drugId_not_lte?: Maybe<Scalars['Uuid']>;
  readonly drugJcode?: Maybe<Scalars['String']>;
  readonly drugJcode_contains?: Maybe<Scalars['String']>;
  readonly drugJcode_ends_with?: Maybe<Scalars['String']>;
  readonly drugJcode_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugJcode_not?: Maybe<Scalars['String']>;
  readonly drugJcode_not_contains?: Maybe<Scalars['String']>;
  readonly drugJcode_not_ends_with?: Maybe<Scalars['String']>;
  readonly drugJcode_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugJcode_not_starts_with?: Maybe<Scalars['String']>;
  readonly drugJcode_starts_with?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugName_contains?: Maybe<Scalars['String']>;
  readonly drugName_ends_with?: Maybe<Scalars['String']>;
  readonly drugName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugName_not?: Maybe<Scalars['String']>;
  readonly drugName_not_contains?: Maybe<Scalars['String']>;
  readonly drugName_not_ends_with?: Maybe<Scalars['String']>;
  readonly drugName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugName_not_starts_with?: Maybe<Scalars['String']>;
  readonly drugName_starts_with?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly drugNdc_contains?: Maybe<Scalars['String']>;
  readonly drugNdc_ends_with?: Maybe<Scalars['String']>;
  readonly drugNdc_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugNdc_not?: Maybe<Scalars['String']>;
  readonly drugNdc_not_contains?: Maybe<Scalars['String']>;
  readonly drugNdc_not_ends_with?: Maybe<Scalars['String']>;
  readonly drugNdc_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugNdc_not_starts_with?: Maybe<Scalars['String']>;
  readonly drugNdc_starts_with?: Maybe<Scalars['String']>;
  readonly drugQuantity?: Maybe<Scalars['Int']>;
  readonly drugQuantity_gt?: Maybe<Scalars['Int']>;
  readonly drugQuantity_gte?: Maybe<Scalars['Int']>;
  readonly drugQuantity_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly drugQuantity_lt?: Maybe<Scalars['Int']>;
  readonly drugQuantity_lte?: Maybe<Scalars['Int']>;
  readonly drugQuantity_not?: Maybe<Scalars['Int']>;
  readonly drugQuantity_not_gt?: Maybe<Scalars['Int']>;
  readonly drugQuantity_not_gte?: Maybe<Scalars['Int']>;
  readonly drugQuantity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly drugQuantity_not_lt?: Maybe<Scalars['Int']>;
  readonly drugQuantity_not_lte?: Maybe<Scalars['Int']>;
  readonly enrollmentDocument?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_contains?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_ends_with?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly enrollmentDocument_not?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_not_contains?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_not_ends_with?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly enrollmentDocument_not_starts_with?: Maybe<Scalars['String']>;
  readonly enrollmentDocument_starts_with?: Maybe<Scalars['String']>;
  readonly enrollmentId?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_gt?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_gte?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly enrollmentId_lt?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_lte?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_not?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_not_gt?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_not_gte?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_not_in?: Maybe<ReadonlyArray<Scalars['Uuid']>>;
  readonly enrollmentId_not_lt?: Maybe<Scalars['Uuid']>;
  readonly enrollmentId_not_lte?: Maybe<Scalars['Uuid']>;
  readonly enrollmentStatus?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_contains?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_ends_with?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly enrollmentStatus_not?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_not_contains?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_not_ends_with?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly enrollmentStatus_not_starts_with?: Maybe<Scalars['String']>;
  readonly enrollmentStatus_starts_with?: Maybe<Scalars['String']>;
  readonly finishUrl?: Maybe<Scalars['String']>;
  readonly finishUrl_contains?: Maybe<Scalars['String']>;
  readonly finishUrl_ends_with?: Maybe<Scalars['String']>;
  readonly finishUrl_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly finishUrl_not?: Maybe<Scalars['String']>;
  readonly finishUrl_not_contains?: Maybe<Scalars['String']>;
  readonly finishUrl_not_ends_with?: Maybe<Scalars['String']>;
  readonly finishUrl_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly finishUrl_not_starts_with?: Maybe<Scalars['String']>;
  readonly finishUrl_starts_with?: Maybe<Scalars['String']>;
  readonly goBackUrl?: Maybe<Scalars['String']>;
  readonly goBackUrl_contains?: Maybe<Scalars['String']>;
  readonly goBackUrl_ends_with?: Maybe<Scalars['String']>;
  readonly goBackUrl_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly goBackUrl_not?: Maybe<Scalars['String']>;
  readonly goBackUrl_not_contains?: Maybe<Scalars['String']>;
  readonly goBackUrl_not_ends_with?: Maybe<Scalars['String']>;
  readonly goBackUrl_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly goBackUrl_not_starts_with?: Maybe<Scalars['String']>;
  readonly goBackUrl_starts_with?: Maybe<Scalars['String']>;
  readonly hasCaregiver?: Maybe<Scalars['Boolean']>;
  readonly hasCaregiver_not?: Maybe<Scalars['Boolean']>;
  readonly hubEndDate?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_gt?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_gte?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly hubEndDate_lt?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_lte?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_not?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly hubEndDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly hubEndDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly hubPatientID?: Maybe<Scalars['String']>;
  readonly hubPatientID_contains?: Maybe<Scalars['String']>;
  readonly hubPatientID_ends_with?: Maybe<Scalars['String']>;
  readonly hubPatientID_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly hubPatientID_not?: Maybe<Scalars['String']>;
  readonly hubPatientID_not_contains?: Maybe<Scalars['String']>;
  readonly hubPatientID_not_ends_with?: Maybe<Scalars['String']>;
  readonly hubPatientID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly hubPatientID_not_starts_with?: Maybe<Scalars['String']>;
  readonly hubPatientID_starts_with?: Maybe<Scalars['String']>;
  readonly hubStartDate?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_gt?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_gte?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly hubStartDate_lt?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_lte?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_not?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly hubStartDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly hubStartDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly hubStatus?: Maybe<Scalars['String']>;
  readonly hubStatus_contains?: Maybe<Scalars['String']>;
  readonly hubStatus_ends_with?: Maybe<Scalars['String']>;
  readonly hubStatus_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly hubStatus_not?: Maybe<Scalars['String']>;
  readonly hubStatus_not_contains?: Maybe<Scalars['String']>;
  readonly hubStatus_not_ends_with?: Maybe<Scalars['String']>;
  readonly hubStatus_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly hubStatus_not_starts_with?: Maybe<Scalars['String']>;
  readonly hubStatus_starts_with?: Maybe<Scalars['String']>;
  readonly insuranceDocuments?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_contains?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_ends_with?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly insuranceDocuments_not?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_not_contains?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_not_ends_with?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly insuranceDocuments_not_starts_with?: Maybe<Scalars['String']>;
  readonly insuranceDocuments_starts_with?: Maybe<Scalars['String']>;
  readonly isAgreeToReceiveTextMessages?: Maybe<Scalars['Boolean']>;
  readonly isAgreeToReceiveTextMessages_not?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative_not?: Maybe<Scalars['Boolean']>;
  readonly isOtherInsurance?: Maybe<Scalars['Boolean']>;
  readonly isOtherInsurance_not?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdated_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_lte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_not_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_lte?: Maybe<Scalars['DateTime']>;
  readonly medicaid?: Maybe<Scalars['Boolean']>;
  readonly medicaid_not?: Maybe<Scalars['Boolean']>;
  readonly medicareAdvantage?: Maybe<Scalars['Boolean']>;
  readonly medicareAdvantage_not?: Maybe<Scalars['Boolean']>;
  readonly medicareId?: Maybe<Scalars['String']>;
  readonly medicareId_contains?: Maybe<Scalars['String']>;
  readonly medicareId_ends_with?: Maybe<Scalars['String']>;
  readonly medicareId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly medicareId_not?: Maybe<Scalars['String']>;
  readonly medicareId_not_contains?: Maybe<Scalars['String']>;
  readonly medicareId_not_ends_with?: Maybe<Scalars['String']>;
  readonly medicareId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly medicareId_not_starts_with?: Maybe<Scalars['String']>;
  readonly medicareId_starts_with?: Maybe<Scalars['String']>;
  readonly medicarePartD?: Maybe<Scalars['Boolean']>;
  readonly medicarePartD_not?: Maybe<Scalars['Boolean']>;
  readonly OR?: Maybe<ReadonlyArray<EnrollmentFilter>>;
  readonly organizationAddress1?: Maybe<Scalars['String']>;
  readonly organizationAddress1_contains?: Maybe<Scalars['String']>;
  readonly organizationAddress1_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddress1_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddress1_not?: Maybe<Scalars['String']>;
  readonly organizationAddress1_not_contains?: Maybe<Scalars['String']>;
  readonly organizationAddress1_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddress1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddress1_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationAddress1_starts_with?: Maybe<Scalars['String']>;
  readonly organizationAddress2?: Maybe<Scalars['String']>;
  readonly organizationAddress2_contains?: Maybe<Scalars['String']>;
  readonly organizationAddress2_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddress2_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddress2_not?: Maybe<Scalars['String']>;
  readonly organizationAddress2_not_contains?: Maybe<Scalars['String']>;
  readonly organizationAddress2_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddress2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddress2_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationAddress2_starts_with?: Maybe<Scalars['String']>;
  readonly organizationAddressType?: Maybe<Scalars['String']>;
  readonly organizationAddressType_contains?: Maybe<Scalars['String']>;
  readonly organizationAddressType_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddressType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddressType_not?: Maybe<Scalars['String']>;
  readonly organizationAddressType_not_contains?: Maybe<Scalars['String']>;
  readonly organizationAddressType_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationAddressType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationAddressType_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationAddressType_starts_with?: Maybe<Scalars['String']>;
  readonly organizationCity?: Maybe<Scalars['String']>;
  readonly organizationCity_contains?: Maybe<Scalars['String']>;
  readonly organizationCity_ends_with?: Maybe<Scalars['String']>;
  readonly organizationCity_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationCity_not?: Maybe<Scalars['String']>;
  readonly organizationCity_not_contains?: Maybe<Scalars['String']>;
  readonly organizationCity_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationCity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationCity_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationCity_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactEmail?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_contains?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactEmail_not?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactEmail_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_contains?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactFaxNumber_not?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactFaxNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_contains?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactFirstName_not?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactGender?: Maybe<Scalars['String']>;
  readonly organizationContactGender_contains?: Maybe<Scalars['String']>;
  readonly organizationContactGender_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactGender_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactGender_not?: Maybe<Scalars['String']>;
  readonly organizationContactGender_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactGender_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactGender_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactGender_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactGender_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactLastName?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_contains?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactLastName_not?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactLastName_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly organizationContactOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly organizationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_contains?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactPhoneType_not?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationContactPhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_contains?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_ends_with?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationDeaNumber_not?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_not_contains?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationDeaNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber_starts_with?: Maybe<Scalars['String']>;
  readonly organizationEmail?: Maybe<Scalars['String']>;
  readonly organizationEmail_contains?: Maybe<Scalars['String']>;
  readonly organizationEmail_ends_with?: Maybe<Scalars['String']>;
  readonly organizationEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationEmail_not?: Maybe<Scalars['String']>;
  readonly organizationEmail_not_contains?: Maybe<Scalars['String']>;
  readonly organizationEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationEmail_starts_with?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly organizationExternalId_contains?: Maybe<Scalars['String']>;
  readonly organizationExternalId_ends_with?: Maybe<Scalars['String']>;
  readonly organizationExternalId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationExternalId_not?: Maybe<Scalars['String']>;
  readonly organizationExternalId_not_contains?: Maybe<Scalars['String']>;
  readonly organizationExternalId_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationExternalId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationExternalId_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationExternalId_starts_with?: Maybe<Scalars['String']>;
  readonly organizationFax?: Maybe<Scalars['String']>;
  readonly organizationFax_contains?: Maybe<Scalars['String']>;
  readonly organizationFax_ends_with?: Maybe<Scalars['String']>;
  readonly organizationFax_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationFax_not?: Maybe<Scalars['String']>;
  readonly organizationFax_not_contains?: Maybe<Scalars['String']>;
  readonly organizationFax_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationFax_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationFax_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationFax_starts_with?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationName_contains?: Maybe<Scalars['String']>;
  readonly organizationName_ends_with?: Maybe<Scalars['String']>;
  readonly organizationName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationName_not?: Maybe<Scalars['String']>;
  readonly organizationName_not_contains?: Maybe<Scalars['String']>;
  readonly organizationName_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationName_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationName_starts_with?: Maybe<Scalars['String']>;
  readonly organizationNpi?: Maybe<Scalars['String']>;
  readonly organizationNpi_contains?: Maybe<Scalars['String']>;
  readonly organizationNpi_ends_with?: Maybe<Scalars['String']>;
  readonly organizationNpi_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationNpi_not?: Maybe<Scalars['String']>;
  readonly organizationNpi_not_contains?: Maybe<Scalars['String']>;
  readonly organizationNpi_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationNpi_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationNpi_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationNpi_starts_with?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly organizationState?: Maybe<Scalars['String']>;
  readonly organizationState_contains?: Maybe<Scalars['String']>;
  readonly organizationState_ends_with?: Maybe<Scalars['String']>;
  readonly organizationState_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationState_not?: Maybe<Scalars['String']>;
  readonly organizationState_not_contains?: Maybe<Scalars['String']>;
  readonly organizationState_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationState_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationState_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationState_starts_with?: Maybe<Scalars['String']>;
  readonly organizationTaxId?: Maybe<Scalars['String']>;
  readonly organizationTaxId_contains?: Maybe<Scalars['String']>;
  readonly organizationTaxId_ends_with?: Maybe<Scalars['String']>;
  readonly organizationTaxId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationTaxId_not?: Maybe<Scalars['String']>;
  readonly organizationTaxId_not_contains?: Maybe<Scalars['String']>;
  readonly organizationTaxId_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationTaxId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationTaxId_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationTaxId_starts_with?: Maybe<Scalars['String']>;
  readonly organizationZip?: Maybe<Scalars['String']>;
  readonly organizationZip_contains?: Maybe<Scalars['String']>;
  readonly organizationZip_ends_with?: Maybe<Scalars['String']>;
  readonly organizationZip_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationZip_not?: Maybe<Scalars['String']>;
  readonly organizationZip_not_contains?: Maybe<Scalars['String']>;
  readonly organizationZip_not_ends_with?: Maybe<Scalars['String']>;
  readonly organizationZip_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly organizationZip_not_starts_with?: Maybe<Scalars['String']>;
  readonly organizationZip_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurance?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardholderDOB_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardholderDOB_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderId_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderId_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderName_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderName_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderRelationship_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceCardHolderRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceEmployer_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceEmployer_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceGroupNumber_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceGroupNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceMedigapPolicy_not?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsuranceMedigapPolicy_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly otherInsuranceOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly otherInsurancePayerID?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePayerID_not?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePayerID_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePayerID_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePhoneType_not?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePlanName_not?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePlanName_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePlanType_not?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurancePlanType_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurance_contains?: Maybe<Scalars['String']>;
  readonly otherInsurance_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurance_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurance_not?: Maybe<Scalars['String']>;
  readonly otherInsurance_not_contains?: Maybe<Scalars['String']>;
  readonly otherInsurance_not_ends_with?: Maybe<Scalars['String']>;
  readonly otherInsurance_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly otherInsurance_not_starts_with?: Maybe<Scalars['String']>;
  readonly otherInsurance_starts_with?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_contains?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_ends_with?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pAPEligibilityData_not?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_not_contains?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_not_ends_with?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pAPEligibilityData_not_starts_with?: Maybe<Scalars['String']>;
  readonly pAPEligibilityData_starts_with?: Maybe<Scalars['String']>;
  readonly pAPFinancialData?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_contains?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_ends_with?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pAPFinancialData_not?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_not_contains?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_not_ends_with?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pAPFinancialData_not_starts_with?: Maybe<Scalars['String']>;
  readonly pAPFinancialData_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddress1?: Maybe<Scalars['String']>;
  readonly patientAddress1_contains?: Maybe<Scalars['String']>;
  readonly patientAddress1_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddress1_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddress1_not?: Maybe<Scalars['String']>;
  readonly patientAddress1_not_contains?: Maybe<Scalars['String']>;
  readonly patientAddress1_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddress1_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddress1_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddress1_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddress2?: Maybe<Scalars['String']>;
  readonly patientAddress2_contains?: Maybe<Scalars['String']>;
  readonly patientAddress2_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddress2_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddress2_not?: Maybe<Scalars['String']>;
  readonly patientAddress2_not_contains?: Maybe<Scalars['String']>;
  readonly patientAddress2_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddress2_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddress2_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddress2_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddressType?: Maybe<Scalars['String']>;
  readonly patientAddressType_contains?: Maybe<Scalars['String']>;
  readonly patientAddressType_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddressType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddressType_not?: Maybe<Scalars['String']>;
  readonly patientAddressType_not_contains?: Maybe<Scalars['String']>;
  readonly patientAddressType_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientAddressType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAddressType_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientAddressType_starts_with?: Maybe<Scalars['String']>;
  readonly patientAttestationText?: Maybe<Scalars['String']>;
  readonly patientAttestationText_contains?: Maybe<Scalars['String']>;
  readonly patientAttestationText_ends_with?: Maybe<Scalars['String']>;
  readonly patientAttestationText_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAttestationText_not?: Maybe<Scalars['String']>;
  readonly patientAttestationText_not_contains?: Maybe<Scalars['String']>;
  readonly patientAttestationText_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientAttestationText_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientAttestationText_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientAttestationText_starts_with?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_contains?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_ends_with?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientBestTimeToCall_not?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_not_contains?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientBestTimeToCall_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall_starts_with?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientCity_contains?: Maybe<Scalars['String']>;
  readonly patientCity_ends_with?: Maybe<Scalars['String']>;
  readonly patientCity_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientCity_not?: Maybe<Scalars['String']>;
  readonly patientCity_not_contains?: Maybe<Scalars['String']>;
  readonly patientCity_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientCity_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientCity_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientCity_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_contains?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentDocumentName_not?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_not_contains?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentDocumentName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentDocumentName_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_contains?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentEmailAddress_not?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_not_contains?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentEmailAddress_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentType?: Maybe<Scalars['String']>;
  readonly patientConsentType_contains?: Maybe<Scalars['String']>;
  readonly patientConsentType_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentType_not?: Maybe<Scalars['String']>;
  readonly patientConsentType_not_contains?: Maybe<Scalars['String']>;
  readonly patientConsentType_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientConsentType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientConsentType_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientConsentType_starts_with?: Maybe<Scalars['String']>;
  readonly patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_gt?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_gte?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly patientDateOfBirth_lt?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_lte?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_not?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_not_gt?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_not_gte?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly patientDateOfBirth_not_lt?: Maybe<Scalars['DateTime']>;
  readonly patientDateOfBirth_not_lte?: Maybe<Scalars['DateTime']>;
  readonly patientEmail?: Maybe<Scalars['String']>;
  readonly patientEmail_contains?: Maybe<Scalars['String']>;
  readonly patientEmail_ends_with?: Maybe<Scalars['String']>;
  readonly patientEmail_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientEmail_not?: Maybe<Scalars['String']>;
  readonly patientEmail_not_contains?: Maybe<Scalars['String']>;
  readonly patientEmail_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientEmail_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientEmail_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientEmail_starts_with?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly patientExternalId_contains?: Maybe<Scalars['String']>;
  readonly patientExternalId_ends_with?: Maybe<Scalars['String']>;
  readonly patientExternalId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientExternalId_not?: Maybe<Scalars['String']>;
  readonly patientExternalId_not_contains?: Maybe<Scalars['String']>;
  readonly patientExternalId_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientExternalId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientExternalId_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientExternalId_starts_with?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientFirstName_contains?: Maybe<Scalars['String']>;
  readonly patientFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientFirstName_not?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientGender_contains?: Maybe<Scalars['String']>;
  readonly patientGender_ends_with?: Maybe<Scalars['String']>;
  readonly patientGender_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientGender_not?: Maybe<Scalars['String']>;
  readonly patientGender_not_contains?: Maybe<Scalars['String']>;
  readonly patientGender_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientGender_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientGender_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientGender_starts_with?: Maybe<Scalars['String']>;
  readonly patientHasInsurance?: Maybe<Scalars['Boolean']>;
  readonly patientHasInsurance_not?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberOktoLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberPreferred_not?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientHomePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientHomePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientLastName_contains?: Maybe<Scalars['String']>;
  readonly patientLastName_ends_with?: Maybe<Scalars['String']>;
  readonly patientLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientLastName_not?: Maybe<Scalars['String']>;
  readonly patientLastName_not_contains?: Maybe<Scalars['String']>;
  readonly patientLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientLastName_starts_with?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientMiddleName_contains?: Maybe<Scalars['String']>;
  readonly patientMiddleName_ends_with?: Maybe<Scalars['String']>;
  readonly patientMiddleName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientMiddleName_not?: Maybe<Scalars['String']>;
  readonly patientMiddleName_not_contains?: Maybe<Scalars['String']>;
  readonly patientMiddleName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientMiddleName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientMiddleName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientMiddleName_starts_with?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberPreferred_not?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientMobilePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientMobilePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredCommunication_not?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_not_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredCommunication_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredCommunication_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredLanguageOther_not?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_not_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredLanguageOther_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredLanguage_not?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_not_contains?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientPreferredLanguage_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage_starts_with?: Maybe<Scalars['String']>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientState_contains?: Maybe<Scalars['String']>;
  readonly patientState_ends_with?: Maybe<Scalars['String']>;
  readonly patientState_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientState_not?: Maybe<Scalars['String']>;
  readonly patientState_not_contains?: Maybe<Scalars['String']>;
  readonly patientState_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientState_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientState_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientState_starts_with?: Maybe<Scalars['String']>;
  readonly patientUSResidence?: Maybe<Scalars['Boolean']>;
  readonly patientUSResidence_not?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumberOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberPreferred_not?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientWorkPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientWorkPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly patientZip?: Maybe<Scalars['String']>;
  readonly patientZip_contains?: Maybe<Scalars['String']>;
  readonly patientZip_ends_with?: Maybe<Scalars['String']>;
  readonly patientZip_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientZip_not?: Maybe<Scalars['String']>;
  readonly patientZip_not_contains?: Maybe<Scalars['String']>;
  readonly patientZip_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientZip_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientZip_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientZip_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceBin_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceBin_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardholderDOB_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardholderDOB_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderId_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderId_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderName_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderName_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderRelationship_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceCardHolderRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceGroupNumber_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceGroupNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceIsMedicarePartD?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsuranceIsMedicarePartD_not?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsuranceName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceName_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsuranceName_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceName_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsuranceOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsurancePayerId?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePayerId_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePayerId_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePayerId_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePhoneType_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePlanName_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurancePlanName_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurnacePcn_not?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_not_contains?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_not_ends_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly pharmacyInsurnacePcn_not_starts_with?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn_starts_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationCanContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationCanContactPatient_not?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationReceived?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationReceived_not?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationTerms?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_contains?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_ends_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianAttestationTerms_not?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_not_contains?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianAttestationTerms_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationTerms_starts_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationText?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_contains?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_ends_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianAttestationText_not?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_not_contains?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianAttestationText_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianAttestationText_starts_with?: Maybe<Scalars['String']>;
  readonly physicianBusinessAssociateAggrement?: Maybe<Scalars['Boolean']>;
  readonly physicianBusinessAssociateAggrement_not?: Maybe<Scalars['Boolean']>;
  readonly physicianConsentCertified?: Maybe<Scalars['Boolean']>;
  readonly physicianConsentCertified_not?: Maybe<Scalars['Boolean']>;
  readonly physicianDEANumber?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_contains?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_ends_with?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianDEANumber_not?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_not_contains?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianDEANumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianDEANumber_starts_with?: Maybe<Scalars['String']>;
  readonly physicianDoNotContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianDoNotContactPatient_not?: Maybe<Scalars['Boolean']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianFirstName_contains?: Maybe<Scalars['String']>;
  readonly physicianFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianFirstName_not?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly physicianGender?: Maybe<Scalars['String']>;
  readonly physicianGender_contains?: Maybe<Scalars['String']>;
  readonly physicianGender_ends_with?: Maybe<Scalars['String']>;
  readonly physicianGender_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianGender_not?: Maybe<Scalars['String']>;
  readonly physicianGender_not_contains?: Maybe<Scalars['String']>;
  readonly physicianGender_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianGender_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianGender_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianGender_starts_with?: Maybe<Scalars['String']>;
  readonly physicianHin?: Maybe<Scalars['String']>;
  readonly physicianHin_contains?: Maybe<Scalars['String']>;
  readonly physicianHin_ends_with?: Maybe<Scalars['String']>;
  readonly physicianHin_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianHin_not?: Maybe<Scalars['String']>;
  readonly physicianHin_not_contains?: Maybe<Scalars['String']>;
  readonly physicianHin_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianHin_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianHin_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianHin_starts_with?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLastName_contains?: Maybe<Scalars['String']>;
  readonly physicianLastName_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLastName_not?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_contains?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_starts_with?: Maybe<Scalars['String']>;
  readonly physicianLicensingState?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_contains?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLicensingState_not?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_not_contains?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLicensingState_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianLicensingState_starts_with?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_contains?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_ends_with?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianMedicaidMedicareProviderNumber_not?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_not_contains?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianMedicaidMedicareProviderNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber_starts_with?: Maybe<Scalars['String']>;
  readonly physicianMiddleName?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_contains?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_ends_with?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianMiddleName_not?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_not_contains?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianMiddleName_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianMiddleName_starts_with?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly physicianNpi_contains?: Maybe<Scalars['String']>;
  readonly physicianNpi_ends_with?: Maybe<Scalars['String']>;
  readonly physicianNpi_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianNpi_not?: Maybe<Scalars['String']>;
  readonly physicianNpi_not_contains?: Maybe<Scalars['String']>;
  readonly physicianNpi_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianNpi_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianNpi_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianNpi_starts_with?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_contains?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_ends_with?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianPrescriberInstructions_not?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_not_contains?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianPrescriberInstructions_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions_starts_with?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_contains?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_ends_with?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianPTANNumber_not?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_not_contains?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianPTANNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianPTANNumber_starts_with?: Maybe<Scalars['String']>;
  readonly physicianReleasePatientInformation?: Maybe<Scalars['Boolean']>;
  readonly physicianReleasePatientInformation_not?: Maybe<Scalars['Boolean']>;
  readonly physicianRequestForwardPrescription?: Maybe<Scalars['Boolean']>;
  readonly physicianRequestForwardPrescription_not?: Maybe<Scalars['Boolean']>;
  readonly physicianSpecialty?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_contains?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_ends_with?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianSpecialty_not?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_not_contains?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianSpecialty_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianSpecialty_starts_with?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_contains?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_ends_with?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianStateLicenseNumber_not?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_not_contains?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianStateLicenseNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber_starts_with?: Maybe<Scalars['String']>;
  readonly physicianTaxId?: Maybe<Scalars['String']>;
  readonly physicianTaxId_contains?: Maybe<Scalars['String']>;
  readonly physicianTaxId_ends_with?: Maybe<Scalars['String']>;
  readonly physicianTaxId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianTaxId_not?: Maybe<Scalars['String']>;
  readonly physicianTaxId_not_contains?: Maybe<Scalars['String']>;
  readonly physicianTaxId_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianTaxId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianTaxId_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianTaxId_starts_with?: Maybe<Scalars['String']>;
  readonly physicianType?: Maybe<Scalars['String']>;
  readonly physicianType_contains?: Maybe<Scalars['String']>;
  readonly physicianType_ends_with?: Maybe<Scalars['String']>;
  readonly physicianType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianType_not?: Maybe<Scalars['String']>;
  readonly physicianType_not_contains?: Maybe<Scalars['String']>;
  readonly physicianType_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianType_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianType_starts_with?: Maybe<Scalars['String']>;
  readonly preferredLanguages?: Maybe<Scalars['String']>;
  readonly preferredLanguages_contains?: Maybe<Scalars['String']>;
  readonly preferredLanguages_ends_with?: Maybe<Scalars['String']>;
  readonly preferredLanguages_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly preferredLanguages_not?: Maybe<Scalars['String']>;
  readonly preferredLanguages_not_contains?: Maybe<Scalars['String']>;
  readonly preferredLanguages_not_ends_with?: Maybe<Scalars['String']>;
  readonly preferredLanguages_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly preferredLanguages_not_starts_with?: Maybe<Scalars['String']>;
  readonly preferredLanguages_starts_with?: Maybe<Scalars['String']>;
  readonly prescribingRole?: Maybe<Scalars['String']>;
  readonly prescribingRole_contains?: Maybe<Scalars['String']>;
  readonly prescribingRole_ends_with?: Maybe<Scalars['String']>;
  readonly prescribingRole_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly prescribingRole_not?: Maybe<Scalars['String']>;
  readonly prescribingRole_not_contains?: Maybe<Scalars['String']>;
  readonly prescribingRole_not_ends_with?: Maybe<Scalars['String']>;
  readonly prescribingRole_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly prescribingRole_not_starts_with?: Maybe<Scalars['String']>;
  readonly prescribingRole_starts_with?: Maybe<Scalars['String']>;
  readonly prescribingType?: Maybe<Scalars['String']>;
  readonly prescribingType_contains?: Maybe<Scalars['String']>;
  readonly prescribingType_ends_with?: Maybe<Scalars['String']>;
  readonly prescribingType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly prescribingType_not?: Maybe<Scalars['String']>;
  readonly prescribingType_not_contains?: Maybe<Scalars['String']>;
  readonly prescribingType_not_ends_with?: Maybe<Scalars['String']>;
  readonly prescribingType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly prescribingType_not_starts_with?: Maybe<Scalars['String']>;
  readonly prescribingType_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardholderDOB_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardholderDOB_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderId_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderId_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderName_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderName_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderRelationship_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceCardHolderRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceEmployer_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceEmployer_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceGroupNumber_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceGroupNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceMedigapPolicy_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceMedigapPolicy_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceName_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsuranceName_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly primaryMedicalInsuranceOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly primaryMedicalInsurancePayerId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePayerId_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePayerId_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePayerId_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePhoneType_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePlanName_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePlanName_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePlanType_not?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_not_contains?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_not_ends_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly primaryMedicalInsurancePlanType_not_starts_with?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType_starts_with?: Maybe<Scalars['String']>;
  readonly priorMedications?: Maybe<Scalars['String']>;
  readonly priorMedications_contains?: Maybe<Scalars['String']>;
  readonly priorMedications_ends_with?: Maybe<Scalars['String']>;
  readonly priorMedications_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priorMedications_not?: Maybe<Scalars['String']>;
  readonly priorMedications_not_contains?: Maybe<Scalars['String']>;
  readonly priorMedications_not_ends_with?: Maybe<Scalars['String']>;
  readonly priorMedications_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priorMedications_not_starts_with?: Maybe<Scalars['String']>;
  readonly priorMedications_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardholderDOB_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardholderDOB_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderId_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderId_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderName_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderName_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceEmployer_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceEmployer_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceGroupNumber_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceGroupNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceMedigapPolicy_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceMedigapPolicy_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceName_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsuranceName_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly secondaryMedicalInsuranceOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly secondaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePayerID_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePayerID_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePayerID_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePhoneType_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePlanName_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePlanName_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePlanType_not?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_not_contains?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_not_ends_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly secondaryMedicalInsurancePlanType_not_starts_with?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType_starts_with?: Maybe<Scalars['String']>;
  readonly serviceSpecificData?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_contains?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_ends_with?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly serviceSpecificData_not?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_not_contains?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_not_ends_with?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly serviceSpecificData_not_starts_with?: Maybe<Scalars['String']>;
  readonly serviceSpecificData_starts_with?: Maybe<Scalars['String']>;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly serviceTypeID?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_gt?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_gte?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_in?: Maybe<ReadonlyArray<Maybe<Scalars['Uuid']>>>;
  readonly serviceTypeID_lt?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_lte?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_not?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_not_gt?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_not_gte?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Uuid']>>>;
  readonly serviceTypeID_not_lt?: Maybe<Scalars['Uuid']>;
  readonly serviceTypeID_not_lte?: Maybe<Scalars['Uuid']>;
  readonly serviceType_contains?: Maybe<Scalars['String']>;
  readonly serviceType_ends_with?: Maybe<Scalars['String']>;
  readonly serviceType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly serviceType_not?: Maybe<Scalars['String']>;
  readonly serviceType_not_contains?: Maybe<Scalars['String']>;
  readonly serviceType_not_ends_with?: Maybe<Scalars['String']>;
  readonly serviceType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly serviceType_not_starts_with?: Maybe<Scalars['String']>;
  readonly serviceType_starts_with?: Maybe<Scalars['String']>;
  readonly signatures?: Maybe<Scalars['String']>;
  readonly signatures_contains?: Maybe<Scalars['String']>;
  readonly signatures_ends_with?: Maybe<Scalars['String']>;
  readonly signatures_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly signatures_not?: Maybe<Scalars['String']>;
  readonly signatures_not_contains?: Maybe<Scalars['String']>;
  readonly signatures_not_ends_with?: Maybe<Scalars['String']>;
  readonly signatures_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly signatures_not_starts_with?: Maybe<Scalars['String']>;
  readonly signatures_starts_with?: Maybe<Scalars['String']>;
  readonly submitStatus?: Maybe<Scalars['String']>;
  readonly submitStatus_contains?: Maybe<Scalars['String']>;
  readonly submitStatus_ends_with?: Maybe<Scalars['String']>;
  readonly submitStatus_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly submitStatus_not?: Maybe<Scalars['String']>;
  readonly submitStatus_not_contains?: Maybe<Scalars['String']>;
  readonly submitStatus_not_ends_with?: Maybe<Scalars['String']>;
  readonly submitStatus_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly submitStatus_not_starts_with?: Maybe<Scalars['String']>;
  readonly submitStatus_starts_with?: Maybe<Scalars['String']>;
  readonly submittedDate?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_gt?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_gte?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly submittedDate_lt?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_lte?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_not?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly submittedDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly submittedDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly tertiaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardholderDOB_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardholderDOB_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardholderDOB_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderID_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderID_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderName_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderName_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceEmployer_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceEmployer_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceGroupNumber_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceGroupNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceName_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsuranceName_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly tertiaryMedicalInsuranceOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly tertiaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePayerID_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePayerID_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePayerID_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePhoneNumber_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePhoneType_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePlanName_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePlanName_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePlanType_not?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_not_contains?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_not_ends_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly tertiaryMedicalInsurancePlanType_not_starts_with?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType_starts_with?: Maybe<Scalars['String']>;
  readonly treatingRole?: Maybe<Scalars['String']>;
  readonly treatingRole_contains?: Maybe<Scalars['String']>;
  readonly treatingRole_ends_with?: Maybe<Scalars['String']>;
  readonly treatingRole_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly treatingRole_not?: Maybe<Scalars['String']>;
  readonly treatingRole_not_contains?: Maybe<Scalars['String']>;
  readonly treatingRole_not_ends_with?: Maybe<Scalars['String']>;
  readonly treatingRole_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly treatingRole_not_starts_with?: Maybe<Scalars['String']>;
  readonly treatingRole_starts_with?: Maybe<Scalars['String']>;
  readonly treatingType?: Maybe<Scalars['String']>;
  readonly treatingType_contains?: Maybe<Scalars['String']>;
  readonly treatingType_ends_with?: Maybe<Scalars['String']>;
  readonly treatingType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly treatingType_not?: Maybe<Scalars['String']>;
  readonly treatingType_not_contains?: Maybe<Scalars['String']>;
  readonly treatingType_not_ends_with?: Maybe<Scalars['String']>;
  readonly treatingType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly treatingType_not_starts_with?: Maybe<Scalars['String']>;
  readonly treatingType_starts_with?: Maybe<Scalars['String']>;
  readonly userFirstName?: Maybe<Scalars['String']>;
  readonly userFirstName_contains?: Maybe<Scalars['String']>;
  readonly userFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly userFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userFirstName_not?: Maybe<Scalars['String']>;
  readonly userFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly userFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly userGender?: Maybe<Scalars['String']>;
  readonly userGender_contains?: Maybe<Scalars['String']>;
  readonly userGender_ends_with?: Maybe<Scalars['String']>;
  readonly userGender_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userGender_not?: Maybe<Scalars['String']>;
  readonly userGender_not_contains?: Maybe<Scalars['String']>;
  readonly userGender_not_ends_with?: Maybe<Scalars['String']>;
  readonly userGender_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userGender_not_starts_with?: Maybe<Scalars['String']>;
  readonly userGender_starts_with?: Maybe<Scalars['String']>;
  readonly userLastName?: Maybe<Scalars['String']>;
  readonly userLastName_contains?: Maybe<Scalars['String']>;
  readonly userLastName_ends_with?: Maybe<Scalars['String']>;
  readonly userLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userLastName_not?: Maybe<Scalars['String']>;
  readonly userLastName_not_contains?: Maybe<Scalars['String']>;
  readonly userLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userLastName_starts_with?: Maybe<Scalars['String']>;
  readonly userMiddleName?: Maybe<Scalars['String']>;
  readonly userMiddleName_contains?: Maybe<Scalars['String']>;
  readonly userMiddleName_ends_with?: Maybe<Scalars['String']>;
  readonly userMiddleName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userMiddleName_not?: Maybe<Scalars['String']>;
  readonly userMiddleName_not_contains?: Maybe<Scalars['String']>;
  readonly userMiddleName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userMiddleName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userMiddleName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userMiddleName_starts_with?: Maybe<Scalars['String']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userName_contains?: Maybe<Scalars['String']>;
  readonly userName_ends_with?: Maybe<Scalars['String']>;
  readonly userName_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly userName_not?: Maybe<Scalars['String']>;
  readonly userName_not_contains?: Maybe<Scalars['String']>;
  readonly userName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userName_not_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly userName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userName_starts_with?: Maybe<Scalars['String']>;
  readonly userOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly userOKToLeaveMsg_not?: Maybe<Scalars['Boolean']>;
  readonly userPhoneNumber?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_contains?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_ends_with?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userPhoneNumber_not?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_not_contains?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_not_ends_with?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userPhoneNumber_not_starts_with?: Maybe<Scalars['String']>;
  readonly userPhoneNumber_starts_with?: Maybe<Scalars['String']>;
  readonly userPhoneType?: Maybe<Scalars['String']>;
  readonly userPhoneType_contains?: Maybe<Scalars['String']>;
  readonly userPhoneType_ends_with?: Maybe<Scalars['String']>;
  readonly userPhoneType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userPhoneType_not?: Maybe<Scalars['String']>;
  readonly userPhoneType_not_contains?: Maybe<Scalars['String']>;
  readonly userPhoneType_not_ends_with?: Maybe<Scalars['String']>;
  readonly userPhoneType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userPhoneType_not_starts_with?: Maybe<Scalars['String']>;
  readonly userPhoneType_starts_with?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
  readonly validationMessage_contains?: Maybe<Scalars['String']>;
  readonly validationMessage_ends_with?: Maybe<Scalars['String']>;
  readonly validationMessage_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly validationMessage_not?: Maybe<Scalars['String']>;
  readonly validationMessage_not_contains?: Maybe<Scalars['String']>;
  readonly validationMessage_not_ends_with?: Maybe<Scalars['String']>;
  readonly validationMessage_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly validationMessage_not_starts_with?: Maybe<Scalars['String']>;
  readonly validationMessage_starts_with?: Maybe<Scalars['String']>;
  readonly vaormilitary?: Maybe<Scalars['Boolean']>;
  readonly vaormilitary_not?: Maybe<Scalars['Boolean']>;
};

export type EntityCheckInput = {
  readonly appId: Scalars['Uuid'];
  readonly contactPhoneNumbers?: Maybe<ReadonlyArray<Maybe<EnrollContactPhoneNumberInput>>>;
  readonly enrollContact?: Maybe<EnrollContactInput>;
  readonly enrollOrganizations?: Maybe<ReadonlyArray<Maybe<EnrollOrganizationWithAddressInput>>>;
  readonly enrollPatient?: Maybe<EnrollPatientInput>;
  readonly enrollPatientContact?: Maybe<EnrollPatientContactInput>;
  readonly enrollPhysician?: Maybe<EnrollPhysicianInput>;
  readonly patientAddresses?: Maybe<ReadonlyArray<Maybe<EnrollPatientAddressInput>>>;
  readonly patientPhoneNumbers?: Maybe<ReadonlyArray<Maybe<EnrollPatientPhoneNumberInput>>>;
};

export type EntityCheckResult = {
  readonly __typename?: 'EntityCheckResult';
  readonly organizationId?: Maybe<Scalars['Uuid']>;
  readonly patientId?: Maybe<Scalars['Uuid']>;
  readonly physicianId?: Maybe<Scalars['Uuid']>;
  readonly updateSuccess: Scalars['Boolean'];
};

export type FaxDocumentInput = {
  readonly contentType?: Maybe<Scalars['String']>;
  readonly createBy?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly fileData?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileSize: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly totalPages: Scalars['Int'];
};

export type FieldPortalOrganizationProvidersResponse = {
  readonly __typename?: 'FieldPortalOrganizationProvidersResponse';
  readonly id?: Maybe<Scalars['String']>;
  readonly physicianFullName?: Maybe<Scalars['String']>;
  readonly physicianID?: Maybe<Scalars['Int']>;
  readonly physicianNPI?: Maybe<Scalars['String']>;
};

export type FieldPortalOrgnizationLocationsResponse = {
  readonly __typename?: 'FieldPortalOrgnizationLocationsResponse';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly organizationAddressID?: Maybe<Scalars['Int']>;
  readonly organizationID?: Maybe<Scalars['Int']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly preferredMethodofContact?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type FieldPortalOrgnizationPatientsResponse = {
  readonly __typename?: 'FieldPortalOrgnizationPatientsResponse';
  readonly drug?: Maybe<Scalars['String']>;
  readonly enrollmentDate?: Maybe<Scalars['DateTime']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly lastShipmentDate?: Maybe<Scalars['DateTime']>;
  readonly organizationID?: Maybe<Scalars['Int']>;
  readonly patientFullName?: Maybe<Scalars['String']>;
  readonly patientID?: Maybe<Scalars['Int']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sPName?: Maybe<Scalars['String']>;
  readonly sPStatus?: Maybe<Scalars['String']>;
  readonly sRStatus?: Maybe<Scalars['String']>;
};

export type FieldPortalOrgnizationResponse = {
  readonly __typename?: 'FieldPortalOrgnizationResponse';
  readonly address?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly organizationID?: Maybe<Scalars['Int']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type FieldPortalPatientBiHistory = {
  readonly __typename?: 'FieldPortalPatientBIHistory';
  readonly bIType?: Maybe<Scalars['String']>;
  readonly enrollmentDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['Int'];
  readonly payerName?: Maybe<Scalars['String']>;
  readonly physicianID?: Maybe<Scalars['Int']>;
  readonly physicianName?: Maybe<Scalars['String']>;
  readonly physicianState?: Maybe<Scalars['String']>;
  readonly planName?: Maybe<Scalars['String']>;
  readonly product?: Maybe<Scalars['String']>;
  readonly productCovered?: Maybe<Scalars['String']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sRType?: Maybe<Scalars['String']>;
};

export type FieldPortalPatientDetails = {
  readonly __typename?: 'FieldPortalPatientDetails';
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly patientConsent?: Maybe<Scalars['String']>;
  readonly patientID?: Maybe<Scalars['Int']>;
  readonly patientName?: Maybe<Scalars['String']>;
  readonly physicianName?: Maybe<Scalars['String']>;
};

export type FieldPortalPatientSrHistory = {
  readonly __typename?: 'FieldPortalPatientSRHistory';
  readonly closedDate?: Maybe<Scalars['DateTime']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sRHubStatus?: Maybe<Scalars['String']>;
  readonly sRStatus?: Maybe<Scalars['String']>;
  readonly sRSubType?: Maybe<Scalars['String']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type FieldPortalPhysicianDetails = {
  readonly __typename?: 'FieldPortalPhysicianDetails';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly officeContact?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly physicianID?: Maybe<Scalars['Int']>;
  readonly physicianName?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type FieldPortalPhysicianSrHistory = {
  readonly __typename?: 'FieldPortalPhysicianSRHistory';
  readonly closedDate?: Maybe<Scalars['DateTime']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sRHubStatus?: Maybe<Scalars['String']>;
  readonly sRStatus?: Maybe<Scalars['String']>;
  readonly sRSubType?: Maybe<Scalars['String']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type FieldPortalSrActivities = {
  readonly __typename?: 'FieldPortalSRActivities';
  readonly activityDate?: Maybe<Scalars['DateTime']>;
  readonly activityDesc?: Maybe<Scalars['String']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sR_ActivityID?: Maybe<Scalars['Int']>;
};

export type FieldPortalSrDetails = {
  readonly __typename?: 'FieldPortalSRDetails';
  readonly closedDate?: Maybe<Scalars['DateTime']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly patientDOB?: Maybe<Scalars['DateTime']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientID?: Maybe<Scalars['Int']>;
  readonly patientName: Scalars['String'];
  readonly physicianName?: Maybe<Scalars['String']>;
  readonly product?: Maybe<Scalars['String']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sRHubStatus?: Maybe<Scalars['String']>;
  readonly sRStatus: Scalars['String'];
  readonly sRSubType?: Maybe<Scalars['String']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly triageFinalizedDate?: Maybe<Scalars['DateTime']>;
};

export type FieldPortalSrTasks = {
  readonly __typename?: 'FieldPortalSRTasks';
  readonly createdDate?: Maybe<Scalars['DateTime']>;
  readonly taskDescription?: Maybe<Scalars['String']>;
  readonly taskDueDate?: Maybe<Scalars['DateTime']>;
};

export type FieldPortalSearchResponse = {
  readonly __typename?: 'FieldPortalSearchResponse';
  readonly drugName?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly patientID?: Maybe<Scalars['Int']>;
  readonly physicianID?: Maybe<Scalars['Int']>;
  readonly physicianName?: Maybe<Scalars['String']>;
  readonly recentActivity?: Maybe<Scalars['String']>;
  readonly serviceRequestID?: Maybe<Scalars['Int']>;
  readonly sRStatus?: Maybe<Scalars['String']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type FieldPortalSrTypes = {
  readonly __typename?: 'FieldPortalSrTypes';
  readonly description?: Maybe<Scalars['String']>;
  readonly iD?: Maybe<Scalars['String']>;
};

export type FieldReimbursementManagerContact = {
  readonly __typename?: 'FieldReimbursementManagerContact';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly territoryName?: Maybe<Scalars['String']>;
  readonly userID: Scalars['Int'];
};

export type HashDocumentInput = {
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly documentHash?: Maybe<Scalars['String']>;
  readonly entityId?: Maybe<Scalars['String']>;
  readonly entityName?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
};

export type InitiateRequestFailuresInput = {
  readonly applicationId?: Maybe<Scalars['Uuid']>;
  readonly caregiverEmail?: Maybe<Scalars['String']>;
  readonly caregiverFirstName?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone?: Maybe<Scalars['String']>;
  readonly caregiverLastName?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication?: Maybe<Scalars['String']>;
  readonly caregiverRelationship?: Maybe<Scalars['String']>;
  readonly drugDaysSupply?: Maybe<Scalars['Int']>;
  readonly drugJcode: Scalars['String'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly drugQuantity?: Maybe<Scalars['Int']>;
  readonly hasCaregiver?: Maybe<Scalars['Boolean']>;
  readonly isAgreeToReceiveTextMessages?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative?: Maybe<Scalars['Boolean']>;
  readonly organizationAddress1?: Maybe<Scalars['String']>;
  readonly organizationAddress2?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName?: Maybe<Scalars['String']>;
  readonly organizationContactLastName?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly organizationFax?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationNpi?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationTaxId?: Maybe<Scalars['String']>;
  readonly patientAddress1?: Maybe<Scalars['String']>;
  readonly patientAddress2?: Maybe<Scalars['String']>;
  readonly patientAddressType?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly patientEmail?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientPreferredCommunication?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther?: Maybe<Scalars['String']>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientUSResidence?: Maybe<Scalars['Boolean']>;
  readonly patientZip?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianHin?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLicensingState?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber?: Maybe<Scalars['String']>;
  readonly physicianTaxId?: Maybe<Scalars['String']>;
  readonly requestedIpaddress?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly userFirstName?: Maybe<Scalars['String']>;
  readonly userLastName?: Maybe<Scalars['String']>;
  readonly userName: Scalars['String'];
  readonly userPhoneNumber?: Maybe<Scalars['String']>;
};

export type LookupDataList = {
  readonly __typename?: 'LookupDataList';
  readonly active: Scalars['Boolean'];
  readonly additionalField?: Maybe<Scalars['String']>;
  readonly alwaysActive?: Maybe<Scalars['Boolean']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly documentAttachmentsDocTypes?: Maybe<ReadonlyArray<Maybe<DocumentAttachmentsDocTypes>>>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly fieldValidations?: Maybe<Scalars['String']>;
  readonly functionName?: Maybe<Scalars['String']>;
  readonly genericDrugName?: Maybe<Scalars['String']>;
  readonly isCarecycle?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly listValue?: Maybe<Scalars['String']>;
  readonly lookupDataListID: Scalars['Int'];
  readonly optionCode?: Maybe<Scalars['String']>;
  readonly portalVisibility?: Maybe<Scalars['Boolean']>;
  readonly programID: Scalars['Int'];
  readonly scientificName?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['Decimal']>;
  readonly sR_Resolutions?: Maybe<Scalars['String']>;
  readonly sR_SubTypes?: Maybe<Scalars['String']>;
  readonly sR_Types?: Maybe<Scalars['String']>;
  readonly symbol?: Maybe<Scalars['String']>;
  readonly triggerRules?: Maybe<Scalars['String']>;
};

export type MbiDetails = {
  readonly __typename?: 'MBIDetails';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medEbvResponseId: Scalars['Int'];
  readonly mOBIDetails?: Maybe<MbiServiceRequestDetailsBase>;
  readonly serviceRequestId: Scalars['Int'];
  readonly sR_MedOfframpDataId: Scalars['Int'];
  readonly sR_OfframpId: Scalars['Int'];
  readonly sR_OfframpType?: Maybe<Scalars['String']>;
  readonly sR_Status?: Maybe<Scalars['String']>;
  readonly sR_TypeFullDesc?: Maybe<Scalars['String']>;
  readonly sR_TypeListId: Scalars['Int'];
  readonly sR_TypeShortDesc?: Maybe<Scalars['String']>;
};

export type MbiServiceRequestDetailsBase = {
  readonly __typename?: 'MBIServiceRequestDetailsBase';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly medEbvResponseId: Scalars['Int'];
  readonly serviceRequestId: Scalars['Int'];
  readonly sR_MedOfframpDataId: Scalars['Int'];
  readonly sR_OfframpId: Scalars['Int'];
  readonly sR_OfframpType?: Maybe<Scalars['String']>;
  readonly sR_Status?: Maybe<Scalars['String']>;
  readonly sR_TypeFullDesc?: Maybe<Scalars['String']>;
  readonly sR_TypeListId: Scalars['Int'];
  readonly sR_TypeShortDesc?: Maybe<Scalars['String']>;
};

export type MedPaSearchViewModel = {
  readonly __typename?: 'MedPASearchViewModel';
  readonly assignmentUserGroupID?: Maybe<Scalars['Int']>;
  readonly clientId: Scalars['Int'];
  readonly contactID?: Maybe<Scalars['Int']>;
  readonly drugTherapy?: Maybe<Scalars['String']>;
  readonly eServicesMedPATransactionID: Scalars['Int'];
  readonly externalID?: Maybe<Scalars['String']>;
  readonly lastUpdated: Scalars['DateTime'];
  readonly organizationAddressID?: Maybe<Scalars['Int']>;
  readonly organizationID?: Maybe<Scalars['Int']>;
  readonly patientID?: Maybe<Scalars['Int']>;
  readonly patientLocationID?: Maybe<Scalars['Int']>;
  readonly programID: Scalars['Int'];
  readonly sRTypeShortName?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly taskComments?: Maybe<MedPaTaskCommentsModel>;
  readonly taskName?: Maybe<Scalars['String']>;
};

export type MedPaTaskCommentsModel = {
  readonly __typename?: 'MedPATaskCommentsModel';
  readonly patientDOB?: Maybe<Scalars['String']>;
  readonly patientName?: Maybe<Scalars['String']>;
  readonly payerGroupNumber?: Maybe<Scalars['String']>;
  readonly payerMemberID?: Maybe<Scalars['String']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly physicianName?: Maybe<Scalars['String']>;
  readonly physicianNPI?: Maybe<Scalars['String']>;
  readonly physicianTaxID?: Maybe<Scalars['String']>;
  readonly practiceName?: Maybe<Scalars['String']>;
  readonly practiceNPI?: Maybe<Scalars['String']>;
  readonly practiceTaxID?: Maybe<Scalars['String']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly addEhipaaInvite?: Maybe<Scalars['Uuid']>;
  readonly allPatientsByCriteria?: Maybe<PaginationResultOfCPatients>;
  readonly allProvidersByCriteria?: Maybe<PaginationResultOfCPhysicians>;
  readonly allServiceRequestsByCriteria?: Maybe<PaginationResultOfCServiceRequests>;
  readonly createEnrollment?: Maybe<Scalars['Uuid']>;
  readonly createEnrollmentOutboundFax?: Maybe<Scalars['Int']>;
  readonly createPatientConsent?: Maybe<Scalars['Boolean']>;
  readonly createPatientUploadedConsent?: Maybe<Scalars['Boolean']>;
  readonly enrollmentCreateOrganization?: Maybe<Scalars['Uuid']>;
  readonly entityCheck?: Maybe<EntityCheckResult>;
  readonly inboundPrescriptionMessageId?: Maybe<Scalars['Int']>;
  readonly prescriptionId?: Maybe<Scalars['Int']>;
  readonly saveEnrollmentHashDocument?: Maybe<Scalars['Uuid']>;
  readonly saveEnrollmentPdfDocument?: Maybe<Scalars['Int']>;
  readonly saveInitateRequestFailure?: Maybe<Scalars['Uuid']>;
  readonly searchEnrollments?: Maybe<ReadonlyArray<Maybe<Enrollment>>>;
  readonly updateEhippaInvite?: Maybe<Scalars['Boolean']>;
  readonly updateEnrollment?: Maybe<Scalars['Boolean']>;
};

export type MutationAddEhipaaInviteArgs = {
  eHipaaInvite?: Maybe<AddEHipaaInviteInput>;
};

export type MutationAllPatientsByCriteriaArgs = {
  includeNonConsentedPatients?: Maybe<Scalars['Boolean']>;
  searchOptions: SearchOptionsInput;
};

export type MutationAllProvidersByCriteriaArgs = {
  searchOptions: SearchOptionsInput;
};

export type MutationAllServiceRequestsByCriteriaArgs = {
  searchOptions: SearchOptionsInput;
};

export type MutationCreateEnrollmentArgs = {
  enrollment: CreateEnrollmentInput;
};

export type MutationCreateEnrollmentOutboundFaxArgs = {
  faxDocument: FaxDocumentInput;
};

export type MutationCreatePatientConsentArgs = {
  patientConsents: CreatePatientConsentInput;
};

export type MutationCreatePatientUploadedConsentArgs = {
  patientUploadedConsent: PatientUploadedConsentDtoInput;
};

export type MutationEnrollmentCreateOrganizationArgs = {
  appId: Scalars['Uuid'];
  organization?: Maybe<EnrollOrganizationInput>;
};

export type MutationEntityCheckArgs = {
  entityCheckInput?: Maybe<EntityCheckInput>;
};

export type MutationInboundPrescriptionMessageIdArgs = {
  inboundPrescriptionMessages: CInboundPrescriptionMessagesInput;
};

export type MutationPrescriptionIdArgs = {
  prescriptionData: CPrescriptionInput;
  programId: Scalars['Int'];
};

export type MutationSaveEnrollmentHashDocumentArgs = {
  hashDocument: HashDocumentInput;
};

export type MutationSaveEnrollmentPdfDocumentArgs = {
  pdfDocument: PdfDocumentInput;
};

export type MutationSaveInitateRequestFailureArgs = {
  initiateRequestFailure: InitiateRequestFailuresInput;
};

export type MutationSearchEnrollmentsArgs = {
  criteria: SearchCriteriaInput;
  where?: Maybe<EnrollmentFilter>;
};

export type MutationUpdateEhippaInviteArgs = {
  inviteId: Scalars['Long'];
  pafId: Scalars['Long'];
  status: Scalars['String'];
};

export type MutationUpdateEnrollmentArgs = {
  enrollment: UpdateEnrollmentInput;
};

export type NpiOrganization = {
  readonly __typename?: 'NPIOrganization';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
  readonly zipCode?: Maybe<Scalars['String']>;
};

export type NpiProvider = {
  readonly __typename?: 'NPIProvider';
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly stateLicenses?: Maybe<ReadonlyArray<Maybe<ProviderStateLicense>>>;
  readonly taxId?: Maybe<Scalars['String']>;
};

export type Organization = {
  readonly __typename?: 'Organization';
  readonly email?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly organizationAddresses?: Maybe<ReadonlyArray<Maybe<OrganizationAddress>>>;
  readonly organizationId: Scalars['Int'];
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
};

export type OrganizationAddress = {
  readonly __typename?: 'OrganizationAddress';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<Organization>;
  readonly organizationAddressId?: Maybe<Scalars['ID']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone1Type?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly phone2Type?: Maybe<Scalars['String']>;
  readonly rank?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type Organizations = {
  readonly __typename?: 'Organizations';
  readonly application?: Maybe<Applications>;
  readonly applicationId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly npi?: Maybe<Scalars['String']>;
  readonly organizationId: Scalars['Uuid'];
  readonly role?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

export type PdfDocumentInput = {
  readonly clientIPAddress?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly documentHashID: Scalars['Uuid'];
  readonly enrollmentDocument?: Maybe<Scalars['String']>;
  readonly enrollmentID: Scalars['Uuid'];
  readonly signatureDate?: Maybe<Scalars['DateTime']>;
  readonly submitDate?: Maybe<Scalars['DateTime']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']>;
};

export type PaginationResultOfCCareCycles = {
  readonly __typename?: 'PaginationResultOfC_CareCycles';
  readonly data?: Maybe<ReadonlyArray<Maybe<CCareCycles>>>;
  readonly totalCount: Scalars['Int'];
};

export type PaginationResultOfCContacts = {
  readonly __typename?: 'PaginationResultOfC_Contacts';
  readonly data?: Maybe<ReadonlyArray<Maybe<CContacts>>>;
  readonly totalCount: Scalars['Int'];
};

export type PaginationResultOfCOrganizations = {
  readonly __typename?: 'PaginationResultOfC_Organizations';
  readonly data?: Maybe<ReadonlyArray<Maybe<COrganizations>>>;
  readonly totalCount: Scalars['Int'];
};

export type PaginationResultOfCPatients = {
  readonly __typename?: 'PaginationResultOfC_Patients';
  readonly data?: Maybe<ReadonlyArray<Maybe<CPatients>>>;
  readonly totalCount: Scalars['Int'];
};

export type PaginationResultOfCPhysicians = {
  readonly __typename?: 'PaginationResultOfC_Physicians';
  readonly data?: Maybe<ReadonlyArray<Maybe<CPhysicians>>>;
  readonly totalCount: Scalars['Int'];
};

export type PaginationResultOfCServiceRequests = {
  readonly __typename?: 'PaginationResultOfC_ServiceRequests';
  readonly data?: Maybe<ReadonlyArray<Maybe<CServiceRequests>>>;
  readonly totalCount: Scalars['Int'];
};

export type Patient = {
  readonly __typename?: 'Patient';
  readonly authorizationReceived?: Maybe<Scalars['Boolean']>;
  readonly copayCard?: Maybe<CopayCard>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly diagnosisList?: Maybe<ReadonlyArray<Maybe<PatientDiagnosisViewModel>>>;
  readonly dob?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly genderOther?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly patientAddresses?: Maybe<PatientAddress>;
  readonly patientContact?: Maybe<ReadonlyArray<Maybe<PatientContact>>>;
  readonly patientId: Scalars['Int'];
  readonly patientPhoneNumbers?: Maybe<ReadonlyArray<Maybe<PatientPhoneNumber>>>;
  readonly physicians?: Maybe<ReadonlyArray<Maybe<PhysicianViewModel>>>;
  readonly preferredLanguage?: Maybe<Scalars['String']>;
  readonly preferredLanguageOther?: Maybe<Scalars['String']>;
};

export type PatientAddress = {
  readonly __typename?: 'PatientAddress';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly isMyCity?: Maybe<Scalars['Boolean']>;
  readonly isPrimary?: Maybe<Scalars['Boolean']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly patientAddressId: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type PatientAddresses = {
  readonly __typename?: 'PatientAddresses';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly addressType?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly patient?: Maybe<Patients>;
  readonly patientAddressId: Scalars['Uuid'];
  readonly patientId: Scalars['Uuid'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type PatientConsentPerDrug = {
  readonly __typename?: 'PatientConsentPerDrug';
  readonly comment?: Maybe<Scalars['String']>;
  readonly consentDateManu?: Maybe<Scalars['DateTime']>;
  readonly consentFormVersion: Scalars['String'];
  readonly consentManuExpDate?: Maybe<Scalars['DateTime']>;
  readonly consentPerDrugId: Scalars['Int'];
  readonly consentReceived: Scalars['Boolean'];
  readonly consentSource: Scalars['String'];
  readonly consentSourceSubtype: Scalars['String'];
  readonly consentType?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly drugName: Scalars['String'];
  readonly externalId?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly optOut: Scalars['Boolean'];
  readonly optOutDate?: Maybe<Scalars['DateTime']>;
  readonly patient?: Maybe<Patient>;
  readonly patientId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly serviceRequestId?: Maybe<Scalars['Int']>;
};

export type PatientConsents = {
  readonly __typename?: 'PatientConsents';
  readonly consentDate: Scalars['DateTime'];
  readonly consentTypeId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly drug?: Maybe<Drugs>;
  readonly drugId: Scalars['Uuid'];
  readonly expiryDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly optedOut: Scalars['Boolean'];
  readonly optedOutDate?: Maybe<Scalars['DateTime']>;
  readonly organizationId: Scalars['Uuid'];
  readonly patient?: Maybe<Patients>;
  readonly patientConsentId: Scalars['Uuid'];
  readonly patientId: Scalars['Uuid'];
  readonly startDate: Scalars['DateTime'];
};

export type PatientContact = {
  readonly __typename?: 'PatientContact';
  readonly caregiverStatmtRead?: Maybe<Scalars['Boolean']>;
  readonly contact?: Maybe<Contact>;
  readonly contactId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly legallyAuthRep?: Maybe<Scalars['Boolean']>;
  readonly patient?: Maybe<Patient>;
  readonly patientContactsId: Scalars['Int'];
  readonly patientContactType?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly phiauthBy?: Maybe<Scalars['String']>;
  readonly phiauthDate?: Maybe<Scalars['DateTime']>;
  readonly phiauthRep?: Maybe<Scalars['Boolean']>;
  readonly phiauthSource?: Maybe<Scalars['String']>;
  readonly phiinteractionId?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly relationship: Scalars['String'];
};

export type PatientContacts = {
  readonly __typename?: 'PatientContacts';
  readonly contact?: Maybe<Contacts>;
  readonly contactId: Scalars['Uuid'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly isLegallyAuthorized: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly patient?: Maybe<Patients>;
  readonly patientContactId: Scalars['Uuid'];
  readonly patientId: Scalars['Uuid'];
  readonly relationShipType?: Maybe<Scalars['String']>;
};

export type PatientDetailDispenses = {
  readonly __typename?: 'PatientDetailDispenses';
  readonly dispenseType?: Maybe<Scalars['String']>;
  readonly fillNumber?: Maybe<Scalars['String']>;
  readonly itemName?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly pharmacyName?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianId: Scalars['Int'];
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly productName?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['String']>;
  readonly quantityValue: Scalars['Decimal'];
  readonly refillRemaining: Scalars['Decimal'];
  readonly shippedDate: Scalars['DateTime'];
  readonly shippingCarrier?: Maybe<Scalars['String']>;
  readonly trackingNumber?: Maybe<Scalars['String']>;
  readonly unitOfMeasure?: Maybe<Scalars['String']>;
};

export type PatientDetailViewModel = {
  readonly __typename?: 'PatientDetailViewModel';
  readonly organizationAddress?: Maybe<OrganizationAddress>;
  readonly physician?: Maybe<Physician>;
};

export type PatientDiagnosisViewModel = {
  readonly __typename?: 'PatientDiagnosisViewModel';
  readonly diagnosisListId: Scalars['Int'];
  readonly icdcode?: Maybe<Scalars['String']>;
  readonly icddescription?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type PatientInsurance = {
  readonly __typename?: 'PatientInsurance';
  readonly cardholderName?: Maybe<Scalars['String']>;
  readonly employerName?: Maybe<Scalars['String']>;
  readonly insuranceId?: Maybe<Scalars['Int']>;
  readonly insuranceName?: Maybe<Scalars['String']>;
  readonly insuranceType?: Maybe<Scalars['String']>;
  readonly isMedicalSupplementPlan?: Maybe<Scalars['Boolean']>;
  readonly isPatientPrimaryCardholder: Scalars['Boolean'];
  readonly medePayerId?: Maybe<Scalars['String']>;
  readonly medicalCardHolderId?: Maybe<Scalars['String']>;
  readonly medicalGroup?: Maybe<Scalars['String']>;
  readonly medicalPhoneNumber?: Maybe<Scalars['String']>;
  readonly medicalPlanName?: Maybe<Scalars['String']>;
  readonly medicalSupplementPlanLetter?: Maybe<Scalars['String']>;
  readonly medPAPayerId?: Maybe<Scalars['String']>;
  readonly parentPayerId?: Maybe<Scalars['Int']>;
  readonly payerId?: Maybe<Scalars['Int']>;
  readonly payerName?: Maybe<Scalars['String']>;
  readonly payerType?: Maybe<Scalars['String']>;
  readonly pharmacyBINNumber?: Maybe<Scalars['String']>;
  readonly pharmacyCardholderID?: Maybe<Scalars['String']>;
  readonly pharmacyGroup?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceId?: Maybe<Scalars['Int']>;
  readonly pharmacyPCN?: Maybe<Scalars['String']>;
  readonly pharmacyPhoneNumber?: Maybe<Scalars['String']>;
  readonly pharmacyPlanName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly planType?: Maybe<Scalars['String']>;
  readonly relationship?: Maybe<Scalars['String']>;
  readonly subscriberDOB?: Maybe<Scalars['DateTime']>;
  readonly subscriberFirstName?: Maybe<Scalars['String']>;
  readonly subscriberGender?: Maybe<Scalars['String']>;
  readonly subscriberLastName?: Maybe<Scalars['String']>;
};

export type PatientIntake = {
  readonly __typename?: 'PatientIntake';
  readonly caseTransactionId: Scalars['Int'];
  readonly caseTransactionStatus?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly enrollmentId: Scalars['Int'];
  readonly patientEnrollmentGuid?: Maybe<Scalars['Uuid']>;
};

export type PatientPhoneNumber = {
  readonly __typename?: 'PatientPhoneNumber';
  readonly created: Scalars['DateTime'];
  readonly lastUpdate?: Maybe<Scalars['DateTime']>;
  readonly oktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientPhoneNumberId?: Maybe<Scalars['Int']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type PatientPhoneNumbers = {
  readonly __typename?: 'PatientPhoneNumbers';
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly oktoLeaveMessage: Scalars['Boolean'];
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly patient?: Maybe<Patients>;
  readonly patientId: Scalars['Uuid'];
  readonly patientPhoneNumberId: Scalars['Uuid'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneType?: Maybe<Scalars['String']>;
};

export type PatientRequestViewModel = {
  readonly __typename?: 'PatientRequestViewModel';
  readonly actionAlertCount: Scalars['Int'];
  readonly authorizationReceived: Scalars['Boolean'];
  readonly dOB?: Maybe<Scalars['DateTime']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly genderOther?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly missingInfoFields?: Maybe<Scalars['String']>;
  readonly nextStep?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianId?: Maybe<Scalars['Int']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly rxAdminAlert?: Maybe<Scalars['Boolean']>;
  readonly rxAdminDate?: Maybe<Scalars['DateTime']>;
  readonly rxAdminDose?: Maybe<Scalars['Int']>;
  readonly rxAdminStatus?: Maybe<Scalars['String']>;
  readonly statusText?: Maybe<Scalars['String']>;
  readonly updateAlertCount: Scalars['Int'];
};

export type PatientSearchViewModel = {
  readonly __typename?: 'PatientSearchViewModel';
  readonly dOB?: Maybe<Scalars['DateTime']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly patientId: Scalars['Int'];
};

export type PatientStatusDescription = {
  readonly __typename?: 'PatientStatusDescription';
  readonly programId: Scalars['Int'];
  readonly statusDescription?: Maybe<Scalars['String']>;
};

export type PatientUploadedConsentDtoInput = {
  readonly consentDate: Scalars['DateTime'];
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly optedOut: Scalars['Boolean'];
  readonly optedOutDate?: Maybe<Scalars['DateTime']>;
  readonly organizationId: Scalars['Uuid'];
  readonly patientConsentId: Scalars['Uuid'];
  readonly patientId: Scalars['Uuid'];
  readonly pdfPath?: Maybe<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export type Patients = {
  readonly __typename?: 'Patients';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly eHipaainvites?: Maybe<ReadonlyArray<Maybe<EHipaainvites>>>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedByEnrollment?: Maybe<Enrollment>;
  readonly lastUpdatedByEnrollmentId?: Maybe<Scalars['Uuid']>;
  readonly middleName?: Maybe<Scalars['String']>;
  readonly organization?: Maybe<Organizations>;
  readonly organizationId: Scalars['Uuid'];
  readonly patientAddresses?: Maybe<ReadonlyArray<Maybe<PatientAddresses>>>;
  readonly patientConsents?: Maybe<ReadonlyArray<Maybe<PatientConsents>>>;
  readonly patientContacts?: Maybe<ReadonlyArray<Maybe<PatientContacts>>>;
  readonly patientId: Scalars['Uuid'];
  readonly patientPhoneNumbers?: Maybe<ReadonlyArray<Maybe<PatientPhoneNumbers>>>;
  readonly preferredLanguage?: Maybe<Scalars['String']>;
  readonly preferredLanguageOther?: Maybe<Scalars['String']>;
};

export type Payer = {
  readonly __typename?: 'Payer';
  readonly externalId?: Maybe<Scalars['String']>;
  readonly medePayerId?: Maybe<Scalars['String']>;
  readonly medPAPayerId?: Maybe<Scalars['String']>;
  readonly parentPayerId?: Maybe<Scalars['Int']>;
  readonly payerCategories?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly payerId: Scalars['Int'];
  readonly payerName?: Maybe<Scalars['String']>;
  readonly payerURL?: Maybe<Scalars['String']>;
};

export type PerformanceMetrics = {
  readonly __typename?: 'PerformanceMetrics';
  readonly callsMade: Scalars['Int'];
  readonly callsReceived: Scalars['Int'];
  readonly messagesSent: Scalars['Int'];
  readonly tasksCompleted: Scalars['Int'];
};

export type PhoneNumberDetails = {
  readonly __typename?: 'PhoneNumberDetails';
  readonly number?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly recordType?: Maybe<Scalars['String']>;
};

export type Physician = {
  readonly __typename?: 'Physician';
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly mDMProgramID: Scalars['Int'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly physicianId?: Maybe<Scalars['String']>;
  readonly physiciansDetail?: Maybe<PhysicianDetail>;
  readonly physicianSpecialties?: Maybe<ReadonlyArray<Maybe<PhysicianSpecialties>>>;
  readonly physicianStateLicenses?: Maybe<PhysicianStateLicense>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly providerType?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type PhysicianDetail = {
  readonly __typename?: 'PhysicianDetail';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly dEA?: Maybe<Scalars['String']>;
  readonly hIN?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly medicaidID?: Maybe<Scalars['String']>;
  readonly medicareID?: Maybe<Scalars['String']>;
  readonly physiciansDetailId?: Maybe<Scalars['ID']>;
  readonly pTAN?: Maybe<Scalars['String']>;
  readonly taxId?: Maybe<Scalars['String']>;
};

export type PhysicianSearchViewModel = {
  readonly __typename?: 'PhysicianSearchViewModel';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly addressType?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly extension1?: Maybe<Scalars['String']>;
  readonly extension2?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly inProgram: Scalars['Int'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly mDMProgramID: Scalars['Int'];
  readonly middleName?: Maybe<Scalars['String']>;
  readonly nPI?: Maybe<Scalars['String']>;
  readonly organizationAddressID: Scalars['Int'];
  readonly organizationID: Scalars['Int'];
  readonly phone1?: Maybe<Scalars['String']>;
  readonly phone2?: Maybe<Scalars['String']>;
  readonly physicianAddressID: Scalars['Int'];
  readonly physicianID: Scalars['Int'];
  readonly practiceName?: Maybe<Scalars['String']>;
  readonly prefix?: Maybe<Scalars['String']>;
  readonly programID?: Maybe<Scalars['Int']>;
  readonly programName?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly rowNumber?: Maybe<Scalars['Long']>;
  readonly sharedRecordProgramID?: Maybe<Scalars['Int']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly suffix?: Maybe<Scalars['String']>;
  readonly zIP?: Maybe<Scalars['String']>;
};

export type PhysicianSpecialties = {
  readonly __typename?: 'PhysicianSpecialties';
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lookupDataListId: Scalars['Int'];
  readonly lookupDataListValue?: Maybe<Scalars['String']>;
  readonly physician?: Maybe<Physician>;
  readonly physicianId: Scalars['Int'];
  readonly physicianSpecialtiesId: Scalars['Int'];
  readonly programId: Scalars['Int'];
  readonly rank?: Maybe<Scalars['Int']>;
};

export type PhysicianStateLicense = {
  readonly __typename?: 'PhysicianStateLicense';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly licenseState?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseId?: Maybe<Scalars['String']>;
  readonly stateLicenseNumber?: Maybe<Scalars['String']>;
};

export type PhysicianViewModel = {
  readonly __typename?: 'PhysicianViewModel';
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly physicianId: Scalars['Int'];
};

export type PortalClient = {
  readonly __typename?: 'PortalClient';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly applicationName?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly clientLogo?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly cssOverrideFile?: Maybe<Scalars['String']>;
  readonly domainURL?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly headerLogo?: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly portalClientID: Scalars['Int'];
  readonly portalTypeID: Scalars['Int'];
  readonly programID: Scalars['Int'];
  readonly state?: Maybe<Scalars['String']>;
  readonly zip: Scalars['Int'];
};

export type PortalFeaturesData = {
  readonly __typename?: 'PortalFeaturesData';
  readonly data?: Maybe<Scalars['String']>;
  readonly drugId?: Maybe<Scalars['Int']>;
  readonly isCustom: Scalars['Int'];
  readonly key?: Maybe<Scalars['String']>;
  readonly portalFeatureCommonKeys?: Maybe<Scalars['String']>;
  readonly portalFeatureID: Scalars['Int'];
};

export type PortalTerritory = {
  readonly __typename?: 'PortalTerritory';
  readonly territoryId: Scalars['Int'];
  readonly territoryName: Scalars['String'];
};

export type PrescriptionIntake = {
  readonly __typename?: 'PrescriptionIntake';
  readonly caseRecordId: Scalars['Int'];
  readonly caseRecordTransactionId: Scalars['Int'];
  readonly caseTransactionId: Scalars['Int'];
  readonly daysSupply?: Maybe<Scalars['String']>;
  readonly doseType?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly ndc?: Maybe<Scalars['String']>;
  readonly prescriptionId: Scalars['Int'];
  readonly prescriptionReceivedDate?: Maybe<Scalars['DateTime']>;
  readonly quantity?: Maybe<Scalars['String']>;
};

export type PriorAuthorization = {
  readonly __typename?: 'PriorAuthorization';
  readonly additionalComments?: Maybe<Scalars['String']>;
  readonly authorizationNumber?: Maybe<Scalars['String']>;
  readonly benefitInvestigationId: Scalars['Int'];
  readonly daysToCompletePAReview?: Maybe<Scalars['String']>;
  readonly denialReason?: Maybe<Scalars['String']>;
  readonly ePAStatus?: Maybe<Scalars['String']>;
  readonly ePATransactionID?: Maybe<Scalars['String']>;
  readonly insurancePlanName?: Maybe<Scalars['String']>;
  readonly insurancePlanType?: Maybe<Scalars['String']>;
  readonly otherDenialReason?: Maybe<Scalars['String']>;
  readonly otherPARequirements?: Maybe<Scalars['String']>;
  readonly pAApprovalDate?: Maybe<Scalars['DateTime']>;
  readonly pACreatedDate?: Maybe<Scalars['DateTime']>;
  readonly pADenialDate?: Maybe<Scalars['DateTime']>;
  readonly pAExpirationDate?: Maybe<Scalars['DateTime']>;
  readonly pAExpiresOn?: Maybe<Scalars['DateTime']>;
  readonly pAOnFile?: Maybe<Scalars['String']>;
  readonly pARequirements?: Maybe<Scalars['String']>;
  readonly pAStatus?: Maybe<Scalars['String']>;
  readonly pASubmissionDate?: Maybe<Scalars['DateTime']>;
  readonly pASubmissionType?: Maybe<Scalars['String']>;
  readonly pAType?: Maybe<Scalars['String']>;
  readonly providerPAWebsite?: Maybe<Scalars['String']>;
  readonly referenceKey?: Maybe<Scalars['String']>;
  readonly stepTherapyRequirements?: Maybe<Scalars['String']>;
  readonly therapyOrDrug?: Maybe<Scalars['String']>;
};

export type ProviderStateLicense = {
  readonly __typename?: 'ProviderStateLicense';
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licenseNumberIndex: Scalars['Int'];
  readonly stateCode?: Maybe<Scalars['String']>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly allCareCyclesByCriteria?: Maybe<PaginationResultOfCCareCycles>;
  readonly allConsentTypes?: Maybe<ReadonlyArray<Maybe<ConsentTypes>>>;
  readonly allContactsByCriteria?: Maybe<PaginationResultOfCContacts>;
  readonly allOrganizationsByCriteria?: Maybe<PaginationResultOfCOrganizations>;
  readonly applicationByAuthClientID?: Maybe<Applications>;
  readonly applicationDrugs?: Maybe<ReadonlyArray<Maybe<Drugs>>>;
  readonly careCycleById?: Maybe<CCareCycles>;
  readonly careCycleServiceRequestIds?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly caseDetails?: Maybe<CaseDetail>;
  readonly caseMangerContact?: Maybe<ReadonlyArray<Maybe<CaseMangerContact>>>;
  readonly caseRecordDocuments?: Maybe<ReadonlyArray<Maybe<CaseRecordDocuments>>>;
  readonly caseRecords?: Maybe<ReadonlyArray<Maybe<CaseRecord>>>;
  readonly completedEhippaInvite?: Maybe<EHipaainvites>;
  readonly configurationItemValues?: Maybe<ReadonlyArray<Maybe<ConfigurationItemValue>>>;
  readonly consentTypesByDrugServiceType?: Maybe<ReadonlyArray<Maybe<ConsentTypes>>>;
  readonly contactById?: Maybe<CContacts>;
  readonly diagnosisListsByJcode?: Maybe<ReadonlyArray<Maybe<VwDrugDiagnosisList>>>;
  readonly dispensesByPatient?: Maybe<ReadonlyArray<Maybe<PatientDetailDispenses>>>;
  readonly documentById?: Maybe<Documents>;
  readonly documentByIdAndType?: Maybe<ReadonlyArray<Maybe<Documents>>>;
  readonly documentByListIdAndType?: Maybe<ReadonlyArray<Maybe<Documents>>>;
  readonly documentsNew?: Maybe<ReadonlyArray<Maybe<Documents>>>;
  readonly drugConfigurationAttributes?: Maybe<DrugConfigurationAttributes>;
  readonly drugCptCodeByProgramInfo?: Maybe<ReadonlyArray<Maybe<DrugCptCode>>>;
  readonly drugCptCodeInfo?: Maybe<ReadonlyArray<Maybe<DrugCptCode>>>;
  readonly drugDetailsByClientId?: Maybe<ReadonlyArray<Maybe<DrugInfo>>>;
  readonly eHipaaApiPrograms?: Maybe<EHipaaApiPrograms>;
  readonly ehippaInvitesByInviteId?: Maybe<EHipaainvites>;
  readonly ehippaInvitesByPafId?: Maybe<EHipaainvites>;
  readonly enrollment?: Maybe<Enrollment>;
  readonly enrollmentPatientById?: Maybe<Patients>;
  readonly enrollmentPatientConsents?: Maybe<ReadonlyArray<Maybe<EnrollPatientConsent>>>;
  readonly enrollmentTemplateByDrugNameAndServiceType?: Maybe<ReadonlyArray<Maybe<Scalars['Byte']>>>;
  readonly faxDocuments?: Maybe<DocumentInboundFaxConnection>;
  readonly fieldPortalOrganization?: Maybe<ReadonlyArray<Maybe<FieldPortalOrgnizationResponse>>>;
  readonly fieldPortalOrganizationLocations?: Maybe<ReadonlyArray<Maybe<FieldPortalOrgnizationLocationsResponse>>>;
  readonly fieldPortalOrganizationPatients?: Maybe<ReadonlyArray<Maybe<FieldPortalOrgnizationPatientsResponse>>>;
  readonly fieldPortalOrganizationProviders?: Maybe<ReadonlyArray<Maybe<FieldPortalOrganizationProvidersResponse>>>;
  readonly fieldPortalPatientBIDetails?: Maybe<ReadonlyArray<Maybe<FieldPortalPatientBiHistory>>>;
  readonly fieldPortalPatientDetails?: Maybe<ReadonlyArray<Maybe<FieldPortalPatientDetails>>>;
  readonly fieldPortalPatients?: Maybe<ReadonlyArray<Maybe<FieldPortalSearchResponse>>>;
  readonly fieldPortalPatientSRHistory?: Maybe<ReadonlyArray<Maybe<FieldPortalPatientSrHistory>>>;
  readonly fieldPortalPhysicianDetails?: Maybe<ReadonlyArray<Maybe<FieldPortalPhysicianDetails>>>;
  readonly fieldPortalPhysicians?: Maybe<ReadonlyArray<Maybe<FieldPortalSearchResponse>>>;
  readonly fieldPortalPhysicianSRHistory?: Maybe<ReadonlyArray<Maybe<FieldPortalPhysicianSrHistory>>>;
  readonly fieldPortalSRActivityHistory?: Maybe<ReadonlyArray<Maybe<FieldPortalSrActivities>>>;
  readonly fieldPortalSRDetails?: Maybe<ReadonlyArray<Maybe<FieldPortalSrDetails>>>;
  readonly fieldPortalSRs?: Maybe<ReadonlyArray<Maybe<FieldPortalSearchResponse>>>;
  readonly fieldPortalSRTasks?: Maybe<ReadonlyArray<Maybe<FieldPortalSrTasks>>>;
  readonly fieldPortalSrTypes?: Maybe<ReadonlyArray<Maybe<FieldPortalSrTypes>>>;
  readonly fieldReimbursementManagerContact?: Maybe<ReadonlyArray<Maybe<FieldReimbursementManagerContact>>>;
  readonly medPATransactions?: Maybe<ReadonlyArray<Maybe<MedPaSearchViewModel>>>;
  readonly nPIDataForOrganization?: Maybe<ReadonlyArray<Maybe<NpiOrganization>>>;
  readonly nPIDataForProvider?: Maybe<ReadonlyArray<Maybe<NpiProvider>>>;
  readonly offRampDetails?: Maybe<ReadonlyArray<Maybe<MbiDetails>>>;
  readonly organizationAddressesInfo?: Maybe<ReadonlyArray<Maybe<OrganizationAddress>>>;
  readonly organizationById?: Maybe<COrganizations>;
  readonly organizationDetails?: Maybe<Organization>;
  readonly organizationUsers?: Maybe<ReadonlyArray<Maybe<UserViewModel>>>;
  readonly patientById?: Maybe<CPatients>;
  readonly patientConsents?: Maybe<ReadonlyArray<Maybe<PatientConsentPerDrug>>>;
  readonly patientDetailByPatientID?: Maybe<PatientDetailViewModel>;
  readonly patientDiagnosisByProgramInfo?: Maybe<ReadonlyArray<Maybe<DiagnosisList>>>;
  readonly patientDiagnosisInfo?: Maybe<ReadonlyArray<Maybe<DiagnosisList>>>;
  readonly patientInfo?: Maybe<Patient>;
  readonly patientInsurancesInfo?: Maybe<ReadonlyArray<Maybe<PatientInsurance>>>;
  readonly patientRequestInfo?: Maybe<ReadonlyArray<Maybe<PatientRequestViewModel>>>;
  readonly patientSearchInfo?: Maybe<ReadonlyArray<Maybe<PatientSearchViewModel>>>;
  readonly patientServiceRequestIds?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly patientStatuses?: Maybe<ReadonlyArray<Maybe<PatientStatusDescription>>>;
  readonly payers?: Maybe<ReadonlyArray<Maybe<Payer>>>;
  readonly phoneNumberInfo?: Maybe<PhoneNumberDetails>;
  readonly physicianInfo?: Maybe<Physician>;
  readonly physiciansInfo?: Maybe<ReadonlyArray<Maybe<Physician>>>;
  readonly portalClientByDomainUrl?: Maybe<PortalClient>;
  readonly portalClientByEnrollmentId?: Maybe<PortalClient>;
  readonly portalFeaturesByClientId?: Maybe<ReadonlyArray<Maybe<PortalFeaturesData>>>;
  readonly prescriptionById?: Maybe<CPrescription>;
  readonly programIdFromHeader?: Maybe<ReadonlyArray<Maybe<PatientInsurance>>>;
  readonly providerById?: Maybe<CPhysicians>;
  readonly requiredProgramConsents?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly searchPhysicians?: Maybe<ReadonlyArray<Maybe<PhysicianSearchViewModel>>>;
  readonly serviceRequestById?: Maybe<CServiceRequests>;
  readonly serviceRequestInfo?: Maybe<ReadonlyArray<Maybe<ServiceRequestViewModel>>>;
  readonly serviceRequestWithDocumentsInfo?: Maybe<ReadonlyArray<Maybe<ServiceRequestViewModel>>>;
  readonly specialtyPharmacyContact?: Maybe<ReadonlyArray<Maybe<SpecialtyPharmacyContact>>>;
  readonly srHubStatuses?: Maybe<ReadonlyArray<Maybe<SrHubStatusDescription>>>;
  readonly territoryDetails?: Maybe<PortalTerritory>;
  readonly userInfo?: Maybe<UserInfo>;
  readonly userPerformace?: Maybe<UserPerformance>;
  readonly userPrograms?: Maybe<ReadonlyArray<Maybe<UserProgramViewModel>>>;
  readonly userTasks?: Maybe<TaskViewModelConnection>;
  readonly userTaskSummary?: Maybe<TaskCountViewModel>;
};

export type QueryAllCareCyclesByCriteriaArgs = {
  searchOptions: SearchOptionsInput;
};

export type QueryAllContactsByCriteriaArgs = {
  searchOptions: SearchOptionsInput;
};

export type QueryAllOrganizationsByCriteriaArgs = {
  searchOptions: SearchOptionsInput;
};

export type QueryApplicationByAuthClientIdArgs = {
  authClientId: Scalars['String'];
};

export type QueryApplicationDrugsArgs = {
  applicationId: Scalars['Uuid'];
};

export type QueryCareCycleByIdArgs = {
  careCycleId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryCareCycleServiceRequestIdsArgs = {
  careCycleId: Scalars['ID'];
};

export type QueryCaseDetailsArgs = {
  caseRecordId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryCaseMangerContactArgs = {
  programId: Scalars['Int'];
  zip: Scalars['String'];
};

export type QueryCaseRecordDocumentsArgs = {
  caseRecordId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryCaseRecordsArgs = {
  onlyOpenCases?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  programId?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
};

export type QueryCompletedEhippaInviteArgs = {
  drugName: Scalars['String'];
  patientId: Scalars['Uuid'];
};

export type QueryConfigurationItemValuesArgs = {
  appId: Scalars['String'];
  configCode: Scalars['String'];
  drugName: Scalars['String'];
  serviceCode: Scalars['String'];
};

export type QueryConsentTypesByDrugServiceTypeArgs = {
  drugName?: Maybe<Scalars['String']>;
  serviceType: Scalars['String'];
};

export type QueryContactByIdArgs = {
  contactId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryDiagnosisListsByJcodeArgs = {
  jCode?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
};

export type QueryDispensesByPatientArgs = {
  patientId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryDocumentByIdArgs = {
  documentId: Scalars['ID'];
};

export type QueryDocumentByIdAndTypeArgs = {
  entityId: Scalars['ID'];
  entityType: Scalars['String'];
};

export type QueryDocumentByListIdAndTypeArgs = {
  entityId: ReadonlyArray<Maybe<Scalars['ID']>>;
  entityType: Scalars['String'];
};

export type QueryDocumentsNewArgs = {
  where?: Maybe<DocumentsFilter>;
};

export type QueryDrugConfigurationAttributesArgs = {
  drugName?: Maybe<Scalars['String']>;
};

export type QueryDrugCptCodeByProgramInfoArgs = {
  drugId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryDrugDetailsByClientIdArgs = {
  clientId: Scalars['ID'];
};

export type QueryEHipaaApiProgramsArgs = {
  jCode?: Maybe<Scalars['String']>;
};

export type QueryEhippaInvitesByInviteIdArgs = {
  inviteId: Scalars['Long'];
};

export type QueryEhippaInvitesByPafIdArgs = {
  pafId: Scalars['Long'];
};

export type QueryEnrollmentArgs = {
  enrollmentId: Scalars['Uuid'];
  externalPracticeId: Scalars['String'];
};

export type QueryEnrollmentPatientByIdArgs = {
  patientId: Scalars['Uuid'];
};

export type QueryEnrollmentPatientConsentsArgs = {
  applicationId: Scalars['String'];
  drugName?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  externalPracticeId: Scalars['String'];
};

export type QueryEnrollmentTemplateByDrugNameAndServiceTypeArgs = {
  drugName: Scalars['String'];
  serviceType: Scalars['String'];
};

export type QueryFaxDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['PaginationAmount']>;
  last?: Maybe<Scalars['PaginationAmount']>;
  order_by?: Maybe<DocumentInboundFaxSort>;
  programId: Scalars['Int'];
  userName: Scalars['String'];
  where?: Maybe<DocumentInboundFaxFilter>;
};

export type QueryFieldPortalOrganizationArgs = {
  city?: Maybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  organizationName?: Maybe<Scalars['String']>;
  portalRoasterId: Scalars['Int'];
  programId: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type QueryFieldPortalOrganizationLocationsArgs = {
  organizationId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalOrganizationPatientsArgs = {
  organizationId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalOrganizationProvidersArgs = {
  organizationId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalPatientBiDetailsArgs = {
  patientId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalPatientDetailsArgs = {
  patientId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalPatientsArgs = {
  endDate: Scalars['DateTime'];
  patientId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type QueryFieldPortalPatientSrHistoryArgs = {
  patientId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalPhysicianDetailsArgs = {
  physicianId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalPhysiciansArgs = {
  city: Scalars['String'];
  organizationName: Scalars['String'];
  physicianId: Scalars['Int'];
  physicianName: Scalars['String'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type QueryFieldPortalPhysicianSrHistoryArgs = {
  physicianId: Scalars['Int'];
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId: Scalars['Int'];
};

export type QueryFieldPortalSrActivityHistoryArgs = {
  programId?: Maybe<Scalars['Int']>;
  serviceRequestId?: Maybe<Scalars['Int']>;
};

export type QueryFieldPortalSrDetailsArgs = {
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Int']>;
  serviceRequestId?: Maybe<Scalars['Int']>;
};

export type QueryFieldPortalSRsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  frmName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  physicianId?: Maybe<Scalars['Int']>;
  physicianName?: Maybe<Scalars['String']>;
  portalRoasterId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Int']>;
  srStatus?: Maybe<Scalars['String']>;
  srTypeShortDesc?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type QueryFieldPortalSrTasksArgs = {
  programId?: Maybe<Scalars['Int']>;
  serviceRequestId?: Maybe<Scalars['Int']>;
};

export type QueryFieldPortalSrTypesArgs = {
  programId: Scalars['Int'];
};

export type QueryFieldReimbursementManagerContactArgs = {
  programId: Scalars['Int'];
  zip: Scalars['String'];
};

export type QueryMedPaTransactionsArgs = {
  clientId: Scalars['ID'];
  lastUpdated: Scalars['Date'];
  programId: Scalars['ID'];
  status: Scalars['String'];
};

export type QueryNPiDataForOrganizationArgs = {
  npi: Scalars['String'];
};

export type QueryNPiDataForProviderArgs = {
  npi: Scalars['String'];
};

export type QueryOffRampDetailsArgs = {
  clientName?: Maybe<Scalars['String']>;
  isSeasonalClient?: Maybe<Scalars['Boolean']>;
  medeBVResponseIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type QueryOrganizationAddressesInfoArgs = {
  organizationId: Scalars['ID'];
};

export type QueryOrganizationByIdArgs = {
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryOrganizationDetailsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryOrganizationUsersArgs = {
  organizationId: Scalars['ID'];
};

export type QueryPatientByIdArgs = {
  patientId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryPatientConsentsArgs = {
  serviceRequestId: Scalars['Int'];
};

export type QueryPatientDetailByPatientIdArgs = {
  organizationId: Scalars['Int'];
  patientId: Scalars['Int'];
};

export type QueryPatientDiagnosisByProgramInfoArgs = {
  drugId: Scalars['Int'];
  iCDCode: Scalars['String'];
  programId: Scalars['Int'];
};

export type QueryPatientDiagnosisInfoArgs = {
  iCDCode: Scalars['ID'];
};

export type QueryPatientInfoArgs = {
  patientId: Scalars['ID'];
};

export type QueryPatientInsurancesInfoArgs = {
  patientId: Scalars['ID'];
};

export type QueryPatientRequestInfoArgs = {
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
};
export type QueryPatientSearchInfoArgs = {
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryPatientServiceRequestIdsArgs = {
  patientId: Scalars['ID'];
};

export type QueryPatientStatusesArgs = {
  programId: Scalars['Int'];
};

export type QueryPayersArgs = {
  categories?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['Int']>;
  payerName?: Maybe<Scalars['String']>;
};

export type QueryPhoneNumberInfoArgs = {
  recordId: Scalars['ID'];
  recordType: Scalars['String'];
};

export type QueryPhysicianInfoArgs = {
  npi: Scalars['String'];
  programId?: Maybe<Scalars['Int']>;
};

export type QueryPhysiciansInfoArgs = {
  organizationId: Scalars['ID'];
};

export type QueryPortalClientByDomainUrlArgs = {
  domainUrl: Scalars['String'];
};

export type QueryPortalClientByEnrollmentIdArgs = {
  enrollmentId: Scalars['Uuid'];
};

export type QueryPortalFeaturesByClientIdArgs = {
  portalClientID?: Maybe<Scalars['Int']>;
  portalFeatureCommonKeys?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type QueryPrescriptionByIdArgs = {
  prescriptionId: Scalars['ID'];
};

export type QueryProviderByIdArgs = {
  programId: Scalars['ID'];
  providerId: Scalars['ID'];
};

export type QueryRequiredProgramConsentsArgs = {
  programId: Scalars['Int'];
};

export type QueryServiceRequestByIdArgs = {
  programId: Scalars['ID'];
  serviceRequestId: Scalars['ID'];
};

export type QueryServiceRequestInfoArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['Int']>;
  programId: Scalars['ID'];
};

export type QueryServiceRequestWithDocumentsInfoArgs = {
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['Int']>;
  programId: Scalars['ID'];
  serviceRequestId?: Maybe<Scalars['Int']>;
};

export type QuerySpecialtyPharmacyContactArgs = {
  brandName: Scalars['String'];
  programId: Scalars['Int'];
};

export type QuerySrHubStatusesArgs = {
  organizationId: Scalars['Int'];
  programId: Scalars['Int'];
};

export type QueryTerritoryDetailsArgs = {
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
};

export type QueryUserInfoArgs = {
  recordId: Scalars['ID'];
  recordType: Scalars['String'];
};

export type QueryUserPerformaceArgs = {
  programId: Scalars['Int'];
  timePeriod: Scalars['String'];
  userName: Scalars['String'];
};

export type QueryUserProgramsArgs = {
  userName: Scalars['String'];
  where?: Maybe<UserProgramViewModelFilter>;
};

export type QueryUserTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['PaginationAmount']>;
  last?: Maybe<Scalars['PaginationAmount']>;
  order_by?: Maybe<TaskViewModelSort>;
  userName: Scalars['String'];
  where?: Maybe<TaskViewModelFilter>;
};

export type QueryUserTaskSummaryArgs = {
  programId: Scalars['Int'];
  taskStatus?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type SearchCriteriaInput = {
  readonly applicationId: Scalars['Uuid'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly enrollmentCreatedDateFrom?: Maybe<Scalars['DateTime']>;
  readonly enrollmentCreatedDateTo?: Maybe<Scalars['DateTime']>;
  readonly enrollmentStatus?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly serviceTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type SearchOptionsInput = {
  readonly createdOnOrAfter?: Maybe<Scalars['DateTime']>;
  readonly createdOnOrBefore?: Maybe<Scalars['DateTime']>;
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly programIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly updatedOnOrAfter?: Maybe<Scalars['DateTime']>;
  readonly updatedOnOrBefore?: Maybe<Scalars['DateTime']>;
};

export type ServiceRequestViewModel = {
  readonly __typename?: 'ServiceRequestViewModel';
  readonly actionAlert?: Maybe<Scalars['Boolean']>;
  readonly authorizationReceived?: Maybe<Scalars['Boolean']>;
  readonly caseRecordTransaction?: Maybe<ReadonlyArray<Maybe<CaseRecordTransaction>>>;
  readonly copayCard?: Maybe<CopayClaimSubmissionViewModel>;
  readonly copayCardId?: Maybe<Scalars['String']>;
  readonly displayOnGrid?: Maybe<Scalars['Int']>;
  readonly documents?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly isEserviceTransaction: Scalars['Boolean'];
  readonly missingInfoFields?: Maybe<Scalars['String']>;
  readonly nextStep?: Maybe<Scalars['String']>;
  readonly patientDOB?: Maybe<Scalars['Date']>;
  readonly patientEnrollmentGuid?: Maybe<Scalars['Uuid']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientId?: Maybe<Scalars['ID']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianNPI?: Maybe<Scalars['String']>;
  readonly requestID?: Maybe<Scalars['String']>;
  readonly serviceRequestId?: Maybe<Scalars['ID']>;
  readonly shippedDate?: Maybe<Scalars['Date']>;
  readonly shippedStatus?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly srCopayPaymentEob?: Maybe<ReadonlyArray<Maybe<SrCopayPaymentEob>>>;
  readonly sRDrugName?: Maybe<Scalars['String']>;
  readonly sRStartDate?: Maybe<Scalars['DateTime']>;
  readonly sRStatus?: Maybe<Scalars['String']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly statusText?: Maybe<Scalars['String']>;
  readonly updateAlert?: Maybe<Scalars['Boolean']>;
  readonly updatedAlertDate?: Maybe<Scalars['DateTime']>;
};

export type ServiceTypes = {
  readonly __typename?: 'ServiceTypes';
  readonly applicationDrugServices?: Maybe<ReadonlyArray<Maybe<ApplicationDrugServices>>>;
  readonly code: Scalars['String'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly description: Scalars['String'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly serviceTypeId: Scalars['Uuid'];
};

export enum SortOperationKind {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SpecialtyPharmacyContact = {
  readonly __typename?: 'SpecialtyPharmacyContact';
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly specialtyPharmacyName?: Maybe<Scalars['String']>;
  readonly specialtyPharmacyType?: Maybe<Scalars['String']>;
  readonly webSite?: Maybe<Scalars['String']>;
};

export type SrCopayPaymentEob = {
  readonly __typename?: 'SrCopayPaymentEob';
  readonly billingCode: Scalars['String'];
  readonly copayCardId: Scalars['String'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['String'];
  readonly dateOfService: Scalars['DateTime'];
  readonly deleted: Scalars['Boolean'];
  readonly drugName: Scalars['String'];
  readonly efOverrideBisrid?: Maybe<Scalars['Int']>;
  readonly efOverrideDate?: Maybe<Scalars['DateTime']>;
  readonly efOverrideExpirationDate?: Maybe<Scalars['DateTime']>;
  readonly efOverrideNotes?: Maybe<Scalars['String']>;
  readonly efOverrideReason?: Maybe<Scalars['String']>;
  readonly eoblineStatus?: Maybe<Scalars['String']>;
  readonly eobreceivedDate: Scalars['DateTime'];
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly originalBilledAmount?: Maybe<Scalars['Decimal']>;
  readonly patientCopayAmount: Scalars['Decimal'];
  readonly patientDueAmount: Scalars['Decimal'];
  readonly patientId: Scalars['Int'];
  readonly payeeId: Scalars['Int'];
  readonly payeeType: Scalars['String'];
  readonly primaryPlanPaidAmount?: Maybe<Scalars['Decimal']>;
  readonly programId: Scalars['Int'];
  readonly quantity?: Maybe<Scalars['Decimal']>;
  readonly serviceRequestId: Scalars['Int'];
  readonly srCopayPaymentEobid: Scalars['Int'];
  readonly status?: Maybe<Scalars['String']>;
  readonly statusUpdatedBy?: Maybe<Scalars['String']>;
  readonly statusUpdatedDate?: Maybe<Scalars['DateTime']>;
  readonly totalApprovedAmount: Scalars['Decimal'];
  readonly totalCopayRequestedAmount: Scalars['Decimal'];
  readonly totalDueAmount: Scalars['Decimal'];
};

export type SrHubStatusDescription = {
  readonly __typename?: 'SrHubStatusDescription';
  readonly statusDescription?: Maybe<Scalars['String']>;
};

export type TaskCountViewModel = {
  readonly __typename?: 'TaskCountViewModel';
  readonly currentProgramTaskCount?: Maybe<Scalars['Int']>;
  readonly enrollmentCount: Scalars['Int'];
  readonly faxCount: Scalars['Int'];
  readonly myAssignedTaskCount?: Maybe<Scalars['Int']>;
  readonly otherProgramTaskCount?: Maybe<Scalars['Int']>;
};

export type TaskViewModel = {
  readonly __typename?: 'TaskViewModel';
  readonly created: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly dateSnoozed?: Maybe<Scalars['DateTime']>;
  readonly deleted: Scalars['Boolean'];
  readonly drugName?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianId?: Maybe<Scalars['Int']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly serviceRequestId: Scalars['Int'];
  readonly snoozedBy?: Maybe<Scalars['String']>;
  readonly snoozedDays?: Maybe<Scalars['Int']>;
  readonly sRActivityListId: Scalars['Int'];
  readonly sRType?: Maybe<Scalars['String']>;
  readonly taskAgeInDays: Scalars['Float'];
  readonly taskAssignment?: Maybe<Scalars['String']>;
  readonly taskComments?: Maybe<Scalars['String']>;
  readonly taskDesc?: Maybe<Scalars['String']>;
  readonly taskDueDate?: Maybe<Scalars['DateTime']>;
  readonly taskId: Scalars['Int'];
  readonly taskStatus?: Maybe<Scalars['String']>;
  readonly taskStatusDate?: Maybe<Scalars['DateTime']>;
  readonly userGroupId?: Maybe<Scalars['Int']>;
  readonly userGroupName?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type TaskViewModelConnection = {
  readonly __typename?: 'TaskViewModelConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<TaskViewModelEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Maybe<TaskViewModel>>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TaskViewModelEdge = {
  readonly __typename?: 'TaskViewModelEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
  /** The item at the end of the edge. */
  readonly node?: Maybe<TaskViewModel>;
};

export type TaskViewModelFilter = {
  readonly AND?: Maybe<ReadonlyArray<TaskViewModelFilter>>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdBy_contains?: Maybe<Scalars['String']>;
  readonly createdBy_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly createdBy_not?: Maybe<Scalars['String']>;
  readonly createdBy_not_contains?: Maybe<Scalars['String']>;
  readonly createdBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly createdBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly createdBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly createdBy_starts_with?: Maybe<Scalars['String']>;
  readonly created_gt?: Maybe<Scalars['DateTime']>;
  readonly created_gte?: Maybe<Scalars['DateTime']>;
  readonly created_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly created_lt?: Maybe<Scalars['DateTime']>;
  readonly created_lte?: Maybe<Scalars['DateTime']>;
  readonly created_not?: Maybe<Scalars['DateTime']>;
  readonly created_not_gt?: Maybe<Scalars['DateTime']>;
  readonly created_not_gte?: Maybe<Scalars['DateTime']>;
  readonly created_not_in?: Maybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly created_not_lt?: Maybe<Scalars['DateTime']>;
  readonly created_not_lte?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_gt?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_gte?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly dateSnoozed_lt?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_lte?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_not?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_not_gt?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_not_gte?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly dateSnoozed_not_lt?: Maybe<Scalars['DateTime']>;
  readonly dateSnoozed_not_lte?: Maybe<Scalars['DateTime']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly deleted_not?: Maybe<Scalars['Boolean']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugName_contains?: Maybe<Scalars['String']>;
  readonly drugName_ends_with?: Maybe<Scalars['String']>;
  readonly drugName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugName_not?: Maybe<Scalars['String']>;
  readonly drugName_not_contains?: Maybe<Scalars['String']>;
  readonly drugName_not_ends_with?: Maybe<Scalars['String']>;
  readonly drugName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly drugName_not_starts_with?: Maybe<Scalars['String']>;
  readonly drugName_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly lastUpdatedBy?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_contains?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastUpdatedBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdatedBy_starts_with?: Maybe<Scalars['String']>;
  readonly lastUpdated_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_lte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_gte?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly lastUpdated_not_lt?: Maybe<Scalars['DateTime']>;
  readonly lastUpdated_not_lte?: Maybe<Scalars['DateTime']>;
  readonly OR?: Maybe<ReadonlyArray<TaskViewModelFilter>>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientFirstName_contains?: Maybe<Scalars['String']>;
  readonly patientFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientFirstName_not?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly patientId?: Maybe<Scalars['Int']>;
  readonly patientId_gt?: Maybe<Scalars['Int']>;
  readonly patientId_gte?: Maybe<Scalars['Int']>;
  readonly patientId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly patientId_lt?: Maybe<Scalars['Int']>;
  readonly patientId_lte?: Maybe<Scalars['Int']>;
  readonly patientId_not?: Maybe<Scalars['Int']>;
  readonly patientId_not_gt?: Maybe<Scalars['Int']>;
  readonly patientId_not_gte?: Maybe<Scalars['Int']>;
  readonly patientId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly patientId_not_lt?: Maybe<Scalars['Int']>;
  readonly patientId_not_lte?: Maybe<Scalars['Int']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientLastName_contains?: Maybe<Scalars['String']>;
  readonly patientLastName_ends_with?: Maybe<Scalars['String']>;
  readonly patientLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientLastName_not?: Maybe<Scalars['String']>;
  readonly patientLastName_not_contains?: Maybe<Scalars['String']>;
  readonly patientLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly patientLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly patientLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly patientLastName_starts_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianFirstName_contains?: Maybe<Scalars['String']>;
  readonly physicianFirstName_ends_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianFirstName_not?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_contains?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianFirstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianFirstName_starts_with?: Maybe<Scalars['String']>;
  readonly physicianId?: Maybe<Scalars['Int']>;
  readonly physicianId_gt?: Maybe<Scalars['Int']>;
  readonly physicianId_gte?: Maybe<Scalars['Int']>;
  readonly physicianId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly physicianId_lt?: Maybe<Scalars['Int']>;
  readonly physicianId_lte?: Maybe<Scalars['Int']>;
  readonly physicianId_not?: Maybe<Scalars['Int']>;
  readonly physicianId_not_gt?: Maybe<Scalars['Int']>;
  readonly physicianId_not_gte?: Maybe<Scalars['Int']>;
  readonly physicianId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly physicianId_not_lt?: Maybe<Scalars['Int']>;
  readonly physicianId_not_lte?: Maybe<Scalars['Int']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLastName_contains?: Maybe<Scalars['String']>;
  readonly physicianLastName_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLastName_not?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_contains?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly physicianLastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly physicianLastName_starts_with?: Maybe<Scalars['String']>;
  readonly priority?: Maybe<Scalars['String']>;
  readonly priority_contains?: Maybe<Scalars['String']>;
  readonly priority_ends_with?: Maybe<Scalars['String']>;
  readonly priority_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priority_not?: Maybe<Scalars['String']>;
  readonly priority_not_contains?: Maybe<Scalars['String']>;
  readonly priority_not_ends_with?: Maybe<Scalars['String']>;
  readonly priority_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly priority_not_starts_with?: Maybe<Scalars['String']>;
  readonly priority_starts_with?: Maybe<Scalars['String']>;
  readonly programId?: Maybe<Scalars['Int']>;
  readonly programId_gt?: Maybe<Scalars['Int']>;
  readonly programId_gte?: Maybe<Scalars['Int']>;
  readonly programId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_lt?: Maybe<Scalars['Int']>;
  readonly programId_lte?: Maybe<Scalars['Int']>;
  readonly programId_not?: Maybe<Scalars['Int']>;
  readonly programId_not_gt?: Maybe<Scalars['Int']>;
  readonly programId_not_gte?: Maybe<Scalars['Int']>;
  readonly programId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_not_lt?: Maybe<Scalars['Int']>;
  readonly programId_not_lte?: Maybe<Scalars['Int']>;
  readonly serviceRequestId?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_gt?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_gte?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly serviceRequestId_lt?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_lte?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_not?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_not_gt?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_not_gte?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly serviceRequestId_not_lt?: Maybe<Scalars['Int']>;
  readonly serviceRequestId_not_lte?: Maybe<Scalars['Int']>;
  readonly snoozedBy?: Maybe<Scalars['String']>;
  readonly snoozedBy_contains?: Maybe<Scalars['String']>;
  readonly snoozedBy_ends_with?: Maybe<Scalars['String']>;
  readonly snoozedBy_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly snoozedBy_not?: Maybe<Scalars['String']>;
  readonly snoozedBy_not_contains?: Maybe<Scalars['String']>;
  readonly snoozedBy_not_ends_with?: Maybe<Scalars['String']>;
  readonly snoozedBy_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly snoozedBy_not_starts_with?: Maybe<Scalars['String']>;
  readonly snoozedBy_starts_with?: Maybe<Scalars['String']>;
  readonly snoozedDays?: Maybe<Scalars['Int']>;
  readonly snoozedDays_gt?: Maybe<Scalars['Int']>;
  readonly snoozedDays_gte?: Maybe<Scalars['Int']>;
  readonly snoozedDays_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly snoozedDays_lt?: Maybe<Scalars['Int']>;
  readonly snoozedDays_lte?: Maybe<Scalars['Int']>;
  readonly snoozedDays_not?: Maybe<Scalars['Int']>;
  readonly snoozedDays_not_gt?: Maybe<Scalars['Int']>;
  readonly snoozedDays_not_gte?: Maybe<Scalars['Int']>;
  readonly snoozedDays_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly snoozedDays_not_lt?: Maybe<Scalars['Int']>;
  readonly snoozedDays_not_lte?: Maybe<Scalars['Int']>;
  readonly sRActivityListId?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_gt?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_gte?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly sRActivityListId_lt?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_lte?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_not?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_not_gt?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_not_gte?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly sRActivityListId_not_lt?: Maybe<Scalars['Int']>;
  readonly sRActivityListId_not_lte?: Maybe<Scalars['Int']>;
  readonly sRType?: Maybe<Scalars['String']>;
  readonly sRType_contains?: Maybe<Scalars['String']>;
  readonly sRType_ends_with?: Maybe<Scalars['String']>;
  readonly sRType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly sRType_not?: Maybe<Scalars['String']>;
  readonly sRType_not_contains?: Maybe<Scalars['String']>;
  readonly sRType_not_ends_with?: Maybe<Scalars['String']>;
  readonly sRType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly sRType_not_starts_with?: Maybe<Scalars['String']>;
  readonly sRType_starts_with?: Maybe<Scalars['String']>;
  readonly taskAgeInDays?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_gt?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_gte?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_in?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly taskAgeInDays_lt?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_lte?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_not?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_not_gt?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_not_gte?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_not_in?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly taskAgeInDays_not_lt?: Maybe<Scalars['Float']>;
  readonly taskAgeInDays_not_lte?: Maybe<Scalars['Float']>;
  readonly taskAssignment?: Maybe<Scalars['String']>;
  readonly taskAssignment_contains?: Maybe<Scalars['String']>;
  readonly taskAssignment_ends_with?: Maybe<Scalars['String']>;
  readonly taskAssignment_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskAssignment_not?: Maybe<Scalars['String']>;
  readonly taskAssignment_not_contains?: Maybe<Scalars['String']>;
  readonly taskAssignment_not_ends_with?: Maybe<Scalars['String']>;
  readonly taskAssignment_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskAssignment_not_starts_with?: Maybe<Scalars['String']>;
  readonly taskAssignment_starts_with?: Maybe<Scalars['String']>;
  readonly taskComments?: Maybe<Scalars['String']>;
  readonly taskComments_contains?: Maybe<Scalars['String']>;
  readonly taskComments_ends_with?: Maybe<Scalars['String']>;
  readonly taskComments_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskComments_not?: Maybe<Scalars['String']>;
  readonly taskComments_not_contains?: Maybe<Scalars['String']>;
  readonly taskComments_not_ends_with?: Maybe<Scalars['String']>;
  readonly taskComments_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskComments_not_starts_with?: Maybe<Scalars['String']>;
  readonly taskComments_starts_with?: Maybe<Scalars['String']>;
  readonly taskDesc?: Maybe<Scalars['String']>;
  readonly taskDesc_contains?: Maybe<Scalars['String']>;
  readonly taskDesc_ends_with?: Maybe<Scalars['String']>;
  readonly taskDesc_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskDesc_not?: Maybe<Scalars['String']>;
  readonly taskDesc_not_contains?: Maybe<Scalars['String']>;
  readonly taskDesc_not_ends_with?: Maybe<Scalars['String']>;
  readonly taskDesc_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskDesc_not_starts_with?: Maybe<Scalars['String']>;
  readonly taskDesc_starts_with?: Maybe<Scalars['String']>;
  readonly taskDueDate?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_gt?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_gte?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly taskDueDate_lt?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_lte?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_not?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly taskDueDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly taskDueDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly taskId?: Maybe<Scalars['Int']>;
  readonly taskId_gt?: Maybe<Scalars['Int']>;
  readonly taskId_gte?: Maybe<Scalars['Int']>;
  readonly taskId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly taskId_lt?: Maybe<Scalars['Int']>;
  readonly taskId_lte?: Maybe<Scalars['Int']>;
  readonly taskId_not?: Maybe<Scalars['Int']>;
  readonly taskId_not_gt?: Maybe<Scalars['Int']>;
  readonly taskId_not_gte?: Maybe<Scalars['Int']>;
  readonly taskId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly taskId_not_lt?: Maybe<Scalars['Int']>;
  readonly taskId_not_lte?: Maybe<Scalars['Int']>;
  readonly taskStatus?: Maybe<Scalars['String']>;
  readonly taskStatusDate?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_gt?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_gte?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly taskStatusDate_lt?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_lte?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_not?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_not_gt?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_not_gte?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']>>>;
  readonly taskStatusDate_not_lt?: Maybe<Scalars['DateTime']>;
  readonly taskStatusDate_not_lte?: Maybe<Scalars['DateTime']>;
  readonly taskStatus_contains?: Maybe<Scalars['String']>;
  readonly taskStatus_ends_with?: Maybe<Scalars['String']>;
  readonly taskStatus_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskStatus_not?: Maybe<Scalars['String']>;
  readonly taskStatus_not_contains?: Maybe<Scalars['String']>;
  readonly taskStatus_not_ends_with?: Maybe<Scalars['String']>;
  readonly taskStatus_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly taskStatus_not_starts_with?: Maybe<Scalars['String']>;
  readonly taskStatus_starts_with?: Maybe<Scalars['String']>;
  readonly userGroupId?: Maybe<Scalars['Int']>;
  readonly userGroupId_gt?: Maybe<Scalars['Int']>;
  readonly userGroupId_gte?: Maybe<Scalars['Int']>;
  readonly userGroupId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly userGroupId_lt?: Maybe<Scalars['Int']>;
  readonly userGroupId_lte?: Maybe<Scalars['Int']>;
  readonly userGroupId_not?: Maybe<Scalars['Int']>;
  readonly userGroupId_not_gt?: Maybe<Scalars['Int']>;
  readonly userGroupId_not_gte?: Maybe<Scalars['Int']>;
  readonly userGroupId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly userGroupId_not_lt?: Maybe<Scalars['Int']>;
  readonly userGroupId_not_lte?: Maybe<Scalars['Int']>;
  readonly userGroupName?: Maybe<Scalars['String']>;
  readonly userGroupName_contains?: Maybe<Scalars['String']>;
  readonly userGroupName_ends_with?: Maybe<Scalars['String']>;
  readonly userGroupName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userGroupName_not?: Maybe<Scalars['String']>;
  readonly userGroupName_not_contains?: Maybe<Scalars['String']>;
  readonly userGroupName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userGroupName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userGroupName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userGroupName_starts_with?: Maybe<Scalars['String']>;
};

export type TaskViewModelSort = {
  readonly created?: Maybe<SortOperationKind>;
  readonly createdBy?: Maybe<SortOperationKind>;
  readonly dateSnoozed?: Maybe<SortOperationKind>;
  readonly deleted?: Maybe<SortOperationKind>;
  readonly drugName?: Maybe<SortOperationKind>;
  readonly lastUpdated?: Maybe<SortOperationKind>;
  readonly lastUpdatedBy?: Maybe<SortOperationKind>;
  readonly patientFirstName?: Maybe<SortOperationKind>;
  readonly patientId?: Maybe<SortOperationKind>;
  readonly patientLastName?: Maybe<SortOperationKind>;
  readonly physicianFirstName?: Maybe<SortOperationKind>;
  readonly physicianId?: Maybe<SortOperationKind>;
  readonly physicianLastName?: Maybe<SortOperationKind>;
  readonly priority?: Maybe<SortOperationKind>;
  readonly programId?: Maybe<SortOperationKind>;
  readonly serviceRequestId?: Maybe<SortOperationKind>;
  readonly snoozedBy?: Maybe<SortOperationKind>;
  readonly snoozedDays?: Maybe<SortOperationKind>;
  readonly sRActivityListId?: Maybe<SortOperationKind>;
  readonly sRType?: Maybe<SortOperationKind>;
  readonly taskAgeInDays?: Maybe<SortOperationKind>;
  readonly taskAssignment?: Maybe<SortOperationKind>;
  readonly taskComments?: Maybe<SortOperationKind>;
  readonly taskDesc?: Maybe<SortOperationKind>;
  readonly taskDueDate?: Maybe<SortOperationKind>;
  readonly taskId?: Maybe<SortOperationKind>;
  readonly taskStatus?: Maybe<SortOperationKind>;
  readonly taskStatusDate?: Maybe<SortOperationKind>;
  readonly userGroupId?: Maybe<SortOperationKind>;
  readonly userGroupName?: Maybe<SortOperationKind>;
};

export type TblFiles = {
  readonly __typename?: 'TblFiles';
  readonly contentType?: Maybe<Scalars['String']>;
  readonly createdTime: Scalars['DateTime'];
  readonly entityId?: Maybe<Scalars['Int']>;
  readonly entityType?: Maybe<Scalars['String']>;
  readonly fileData?: Maybe<ReadonlyArray<Scalars['Byte']>>;
  readonly fileDataBase64String?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileSize?: Maybe<Scalars['Long']>;
  readonly iFileId: Scalars['Int'];
  readonly serviceRequestId?: Maybe<Scalars['Int']>;
};

export type TriagePrescription = {
  readonly __typename?: 'TriagePrescription';
  readonly dateSentToPharmacy?: Maybe<Scalars['DateTime']>;
  readonly sentToPharmacy?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type UpdateEnrollmentInput = {
  readonly additionalPhysicians?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress1?: Maybe<Scalars['String']>;
  readonly administeringInformationAddress2?: Maybe<Scalars['String']>;
  readonly administeringInformationCity?: Maybe<Scalars['String']>;
  readonly administeringInformationContactEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationContactFirstName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactLastName?: Maybe<Scalars['String']>;
  readonly administeringInformationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly administeringInformationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationContactPhoneType?: Maybe<Scalars['String']>;
  readonly administeringInformationDeaNumber?: Maybe<Scalars['String']>;
  readonly administeringInformationEmail?: Maybe<Scalars['String']>;
  readonly administeringInformationInsuranceProvider?: Maybe<Scalars['String']>;
  readonly administeringInformationName?: Maybe<Scalars['String']>;
  readonly administeringInformationNpi?: Maybe<Scalars['String']>;
  readonly administeringInformationPhoneNumbers?: Maybe<Scalars['String']>;
  readonly administeringInformationState?: Maybe<Scalars['String']>;
  readonly administeringInformationTaxId?: Maybe<Scalars['String']>;
  readonly administeringInformationType?: Maybe<Scalars['String']>;
  readonly administeringInformationZip?: Maybe<Scalars['String']>;
  readonly applicationId: Scalars['Uuid'];
  readonly cancelUrl?: Maybe<Scalars['String']>;
  readonly cardHolderData?: Maybe<Scalars['String']>;
  readonly caregiverEmail?: Maybe<Scalars['String']>;
  readonly caregiverFirstName?: Maybe<Scalars['String']>;
  readonly caregiverGender?: Maybe<Scalars['String']>;
  readonly caregiverHomePhone?: Maybe<Scalars['String']>;
  readonly caregiverLastName?: Maybe<Scalars['String']>;
  readonly caregiverMiddleName?: Maybe<Scalars['String']>;
  readonly caregiverMobilePhone?: Maybe<Scalars['String']>;
  readonly caregiverPreferredCommunication?: Maybe<Scalars['String']>;
  readonly caregiverRelationship?: Maybe<Scalars['String']>;
  readonly checkPharmacyInsurance?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode1Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode2?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode2Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalCptadminCode3?: Maybe<Scalars['String']>;
  readonly clinicalCptadminCode3Quantity?: Maybe<Scalars['Int']>;
  readonly clinicalData?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificDate1?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificDate2?: Maybe<Scalars['DateTime']>;
  readonly clinicalDrugSpecificField1?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField2?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField3?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField4?: Maybe<Scalars['String']>;
  readonly clinicalDrugSpecificField5?: Maybe<Scalars['String']>;
  readonly clinicalPrimaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalSecondaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTertiaryIcd10diagnosisCode?: Maybe<Scalars['String']>;
  readonly clinicalTreatmentStartDate?: Maybe<Scalars['DateTime']>;
  readonly commercialOrprivate?: Maybe<Scalars['Boolean']>;
  readonly confirmationNumber?: Maybe<Scalars['String']>;
  readonly copayAnnualMax?: Maybe<Scalars['Decimal']>;
  readonly copayCardBalance?: Maybe<Scalars['Decimal']>;
  readonly copayCardNumber?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly customFields?: Maybe<Scalars['String']>;
  readonly documents?: Maybe<Scalars['String']>;
  readonly drugDaysSupply?: Maybe<Scalars['Int']>;
  readonly drugJcode?: Maybe<Scalars['String']>;
  readonly drugName?: Maybe<Scalars['String']>;
  readonly drugNdc?: Maybe<Scalars['String']>;
  readonly drugQuantity?: Maybe<Scalars['Int']>;
  readonly enrollmentId?: Maybe<Scalars['Uuid']>;
  readonly enrollmentRequest?: Maybe<Scalars['String']>;
  readonly enrollmentStatus?: Maybe<Scalars['String']>;
  readonly finishUrl?: Maybe<Scalars['String']>;
  readonly goBackUrl?: Maybe<Scalars['String']>;
  readonly hasCaregiver?: Maybe<Scalars['Boolean']>;
  readonly hubEndDate?: Maybe<Scalars['DateTime']>;
  readonly hubPatientID?: Maybe<Scalars['String']>;
  readonly hubStartDate?: Maybe<Scalars['DateTime']>;
  readonly hubStatus?: Maybe<Scalars['String']>;
  readonly isAgreeToReceiveTextMessages?: Maybe<Scalars['Boolean']>;
  readonly isCaregiverLegallyAuthorizedRepresentative?: Maybe<Scalars['Boolean']>;
  readonly isOtherInsurance?: Maybe<Scalars['Boolean']>;
  readonly medicaid?: Maybe<Scalars['Boolean']>;
  readonly medicareAdvantage?: Maybe<Scalars['Boolean']>;
  readonly medicareId?: Maybe<Scalars['String']>;
  readonly medicarePartD?: Maybe<Scalars['Boolean']>;
  readonly organizationAddress1?: Maybe<Scalars['String']>;
  readonly organizationAddress2?: Maybe<Scalars['String']>;
  readonly organizationAddressType?: Maybe<Scalars['String']>;
  readonly organizationCity?: Maybe<Scalars['String']>;
  readonly organizationContactEmail?: Maybe<Scalars['String']>;
  readonly organizationContactFaxNumber?: Maybe<Scalars['String']>;
  readonly organizationContactFirstName?: Maybe<Scalars['String']>;
  readonly organizationContactGender?: Maybe<Scalars['String']>;
  readonly organizationContactLastName?: Maybe<Scalars['String']>;
  readonly organizationContactOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly organizationContactPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationContactPhoneType?: Maybe<Scalars['String']>;
  readonly organizationDeaNumber?: Maybe<Scalars['String']>;
  readonly organizationEmail?: Maybe<Scalars['String']>;
  readonly organizationExternalId?: Maybe<Scalars['String']>;
  readonly organizationFax?: Maybe<Scalars['String']>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly organizationNpi?: Maybe<Scalars['String']>;
  readonly organizationPhoneNumber?: Maybe<Scalars['String']>;
  readonly organizationState?: Maybe<Scalars['String']>;
  readonly organizationTaxId?: Maybe<Scalars['String']>;
  readonly organizationZip?: Maybe<Scalars['String']>;
  readonly otherInsurance?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly otherInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly otherInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly otherInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly otherInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly otherInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly otherInsurancePayerID?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly otherInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanName?: Maybe<Scalars['String']>;
  readonly otherInsurancePlanType?: Maybe<Scalars['String']>;
  readonly papEligibilityData?: Maybe<Scalars['String']>;
  readonly papFinancialData?: Maybe<Scalars['String']>;
  readonly patientAddress1?: Maybe<Scalars['String']>;
  readonly patientAddress2?: Maybe<Scalars['String']>;
  readonly patientAddressType?: Maybe<Scalars['String']>;
  readonly patientAttestationText?: Maybe<Scalars['String']>;
  readonly patientBestTimeToCall?: Maybe<Scalars['String']>;
  readonly patientCity?: Maybe<Scalars['String']>;
  readonly patientConsentEmailAddress?: Maybe<Scalars['String']>;
  readonly patientConsentPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientConsentType?: Maybe<Scalars['String']>;
  readonly patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly patientEmail?: Maybe<Scalars['String']>;
  readonly patientExternalId?: Maybe<Scalars['String']>;
  readonly patientFirstName?: Maybe<Scalars['String']>;
  readonly patientGender?: Maybe<Scalars['String']>;
  readonly patientHasInsurance?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientHomePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientHomePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientLastName?: Maybe<Scalars['String']>;
  readonly patientMiddleName?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumber?: Maybe<Scalars['String']>;
  readonly patientMobilePhoneNumberOktoLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientMobilePhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientPreferredCommunication?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguage?: Maybe<Scalars['String']>;
  readonly patientPreferredLanguageOther?: Maybe<Scalars['String']>;
  readonly patientState?: Maybe<Scalars['String']>;
  readonly patientUSResidence?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumber?: Maybe<Scalars['String']>;
  readonly patientWorkPhoneNumberOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly patientWorkPhoneNumberPreferred?: Maybe<Scalars['Boolean']>;
  readonly patientZip?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceBin?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceIsMedicarePartD?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsuranceName?: Maybe<Scalars['String']>;
  readonly pharmacyInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly pharmacyInsurancePayerId?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly pharmacyInsurancePlanName?: Maybe<Scalars['String']>;
  readonly pharmacyInsurnacePcn?: Maybe<Scalars['String']>;
  readonly physicianAttestationCanContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationReceived?: Maybe<Scalars['Boolean']>;
  readonly physicianAttestationTerms?: Maybe<Scalars['String']>;
  readonly physicianAttestationText?: Maybe<Scalars['String']>;
  readonly physicianBusinessAssociateAggrement?: Maybe<Scalars['Boolean']>;
  readonly physicianConsentCertified?: Maybe<Scalars['Boolean']>;
  readonly physicianDeanumber?: Maybe<Scalars['String']>;
  readonly physicianDoNotContactPatient?: Maybe<Scalars['Boolean']>;
  readonly physicianFirstName?: Maybe<Scalars['String']>;
  readonly physicianGender?: Maybe<Scalars['String']>;
  readonly physicianHin?: Maybe<Scalars['String']>;
  readonly physicianLastName?: Maybe<Scalars['String']>;
  readonly physicianLicensingState?: Maybe<Scalars['String']>;
  readonly physicianMedicaidMedicareProviderNumber?: Maybe<Scalars['String']>;
  readonly physicianMiddleName?: Maybe<Scalars['String']>;
  readonly physicianNpi?: Maybe<Scalars['String']>;
  readonly physicianPrescriberInstructions?: Maybe<Scalars['String']>;
  readonly physicianPtannumber?: Maybe<Scalars['String']>;
  readonly physicianReleasePatientInformation?: Maybe<Scalars['Boolean']>;
  readonly physicianRequestForwardPrescription?: Maybe<Scalars['Boolean']>;
  readonly physicianSpecialty?: Maybe<Scalars['String']>;
  readonly physicianStateLicenseNumber?: Maybe<Scalars['String']>;
  readonly physicianTaxId?: Maybe<Scalars['String']>;
  readonly physicianType?: Maybe<Scalars['String']>;
  readonly preferredLanguages?: Maybe<Scalars['String']>;
  readonly prescribingRole?: Maybe<Scalars['String']>;
  readonly prescribingType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly primaryMedicalInsurancePayerId?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly primaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly priorMedications?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderId?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPlanLetter?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly secondaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly secondaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly serviceSpecificData?: Maybe<Scalars['String']>;
  readonly serviceType?: Maybe<Scalars['String']>;
  readonly signatures?: Maybe<Scalars['String']>;
  readonly submitStatus?: Maybe<Scalars['String']>;
  readonly submittedDate?: Maybe<Scalars['DateTime']>;
  readonly tertiaryMedicalInsuranceCardholderDOB?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceCardHolderRelationship?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceEmployer?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceGroupNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceMedigapPolicy?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsuranceOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly tertiaryMedicalInsurancePayerID?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneNumber?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePhoneType?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanName?: Maybe<Scalars['String']>;
  readonly tertiaryMedicalInsurancePlanType?: Maybe<Scalars['String']>;
  readonly treatingRole?: Maybe<Scalars['String']>;
  readonly treatingType?: Maybe<Scalars['String']>;
  readonly userFirstName?: Maybe<Scalars['String']>;
  readonly userGender?: Maybe<Scalars['String']>;
  readonly userLastName?: Maybe<Scalars['String']>;
  readonly userMiddleName?: Maybe<Scalars['String']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userOKToLeaveMsg?: Maybe<Scalars['Boolean']>;
  readonly userPhoneNumber?: Maybe<Scalars['String']>;
  readonly userPhoneType?: Maybe<Scalars['String']>;
  readonly validationMessage?: Maybe<Scalars['String']>;
  readonly vaormilitary?: Maybe<Scalars['Boolean']>;
};

export type UserAccessFunctionViewModel = {
  readonly __typename?: 'UserAccessFunctionViewModel';
  readonly access?: Maybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly category?: Maybe<Scalars['String']>;
  readonly functionName?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  readonly __typename?: 'UserInfo';
  readonly email?: Maybe<Scalars['String']>;
  readonly fax?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly rank: Scalars['Int'];
  readonly recordType?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

export type UserPerformance = {
  readonly __typename?: 'UserPerformance';
  readonly message?: Maybe<Scalars['String']>;
  readonly teamMetrics?: Maybe<PerformanceMetrics>;
  readonly timePeriod?: Maybe<Scalars['String']>;
  readonly userMetrics?: Maybe<PerformanceMetrics>;
};

export type UserProgramViewModel = {
  readonly __typename?: 'UserProgramViewModel';
  readonly accountType?: Maybe<Scalars['String']>;
  readonly accountTypeLookupDataListID?: Maybe<Scalars['Int']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly isApproved: Scalars['Boolean'];
  readonly isLockedOut: Scalars['Boolean'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly programId: Scalars['Int'];
  readonly programName?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly rptExtract: Scalars['Boolean'];
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly userAccessFunctions?: Maybe<ReadonlyArray<Maybe<UserAccessFunctionViewModel>>>;
  readonly userInformationId?: Maybe<Scalars['Int']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userprogramId: Scalars['Int'];
};

export type UserProgramViewModelFilter = {
  readonly accountType?: Maybe<Scalars['String']>;
  readonly accountTypeLookupDataListID?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_gt?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_gte?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly accountTypeLookupDataListID_lt?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_lte?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_not?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_not_gt?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_not_gte?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly accountTypeLookupDataListID_not_lt?: Maybe<Scalars['Int']>;
  readonly accountTypeLookupDataListID_not_lte?: Maybe<Scalars['Int']>;
  readonly accountType_contains?: Maybe<Scalars['String']>;
  readonly accountType_ends_with?: Maybe<Scalars['String']>;
  readonly accountType_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly accountType_not?: Maybe<Scalars['String']>;
  readonly accountType_not_contains?: Maybe<Scalars['String']>;
  readonly accountType_not_ends_with?: Maybe<Scalars['String']>;
  readonly accountType_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly accountType_not_starts_with?: Maybe<Scalars['String']>;
  readonly accountType_starts_with?: Maybe<Scalars['String']>;
  readonly AND?: Maybe<ReadonlyArray<UserProgramViewModelFilter>>;
  readonly email?: Maybe<Scalars['String']>;
  readonly email_contains?: Maybe<Scalars['String']>;
  readonly email_ends_with?: Maybe<Scalars['String']>;
  readonly email_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly email_not?: Maybe<Scalars['String']>;
  readonly email_not_contains?: Maybe<Scalars['String']>;
  readonly email_not_ends_with?: Maybe<Scalars['String']>;
  readonly email_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly email_not_starts_with?: Maybe<Scalars['String']>;
  readonly email_starts_with?: Maybe<Scalars['String']>;
  readonly externalId?: Maybe<Scalars['String']>;
  readonly externalId_contains?: Maybe<Scalars['String']>;
  readonly externalId_ends_with?: Maybe<Scalars['String']>;
  readonly externalId_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly externalId_not?: Maybe<Scalars['String']>;
  readonly externalId_not_contains?: Maybe<Scalars['String']>;
  readonly externalId_not_ends_with?: Maybe<Scalars['String']>;
  readonly externalId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly externalId_not_starts_with?: Maybe<Scalars['String']>;
  readonly externalId_starts_with?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly firstName_contains?: Maybe<Scalars['String']>;
  readonly firstName_ends_with?: Maybe<Scalars['String']>;
  readonly firstName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly firstName_not?: Maybe<Scalars['String']>;
  readonly firstName_not_contains?: Maybe<Scalars['String']>;
  readonly firstName_not_ends_with?: Maybe<Scalars['String']>;
  readonly firstName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly firstName_not_starts_with?: Maybe<Scalars['String']>;
  readonly firstName_starts_with?: Maybe<Scalars['String']>;
  readonly isApproved?: Maybe<Scalars['Boolean']>;
  readonly isApproved_not?: Maybe<Scalars['Boolean']>;
  readonly isLockedOut?: Maybe<Scalars['Boolean']>;
  readonly isLockedOut_not?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly lastName_contains?: Maybe<Scalars['String']>;
  readonly lastName_ends_with?: Maybe<Scalars['String']>;
  readonly lastName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastName_not?: Maybe<Scalars['String']>;
  readonly lastName_not_contains?: Maybe<Scalars['String']>;
  readonly lastName_not_ends_with?: Maybe<Scalars['String']>;
  readonly lastName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly lastName_not_starts_with?: Maybe<Scalars['String']>;
  readonly lastName_starts_with?: Maybe<Scalars['String']>;
  readonly OR?: Maybe<ReadonlyArray<UserProgramViewModelFilter>>;
  readonly programId?: Maybe<Scalars['Int']>;
  readonly programId_gt?: Maybe<Scalars['Int']>;
  readonly programId_gte?: Maybe<Scalars['Int']>;
  readonly programId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_lt?: Maybe<Scalars['Int']>;
  readonly programId_lte?: Maybe<Scalars['Int']>;
  readonly programId_not?: Maybe<Scalars['Int']>;
  readonly programId_not_gt?: Maybe<Scalars['Int']>;
  readonly programId_not_gte?: Maybe<Scalars['Int']>;
  readonly programId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly programId_not_lt?: Maybe<Scalars['Int']>;
  readonly programId_not_lte?: Maybe<Scalars['Int']>;
  readonly programName?: Maybe<Scalars['String']>;
  readonly programName_contains?: Maybe<Scalars['String']>;
  readonly programName_ends_with?: Maybe<Scalars['String']>;
  readonly programName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly programName_not?: Maybe<Scalars['String']>;
  readonly programName_not_contains?: Maybe<Scalars['String']>;
  readonly programName_not_ends_with?: Maybe<Scalars['String']>;
  readonly programName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly programName_not_starts_with?: Maybe<Scalars['String']>;
  readonly programName_starts_with?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly role_contains?: Maybe<Scalars['String']>;
  readonly role_ends_with?: Maybe<Scalars['String']>;
  readonly role_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly role_not?: Maybe<Scalars['String']>;
  readonly role_not_contains?: Maybe<Scalars['String']>;
  readonly role_not_ends_with?: Maybe<Scalars['String']>;
  readonly role_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly role_not_starts_with?: Maybe<Scalars['String']>;
  readonly role_starts_with?: Maybe<Scalars['String']>;
  readonly rptExtract?: Maybe<Scalars['Boolean']>;
  readonly rptExtract_not?: Maybe<Scalars['Boolean']>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly timeZone_contains?: Maybe<Scalars['String']>;
  readonly timeZone_ends_with?: Maybe<Scalars['String']>;
  readonly timeZone_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly timeZone_not?: Maybe<Scalars['String']>;
  readonly timeZone_not_contains?: Maybe<Scalars['String']>;
  readonly timeZone_not_ends_with?: Maybe<Scalars['String']>;
  readonly timeZone_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly timeZone_not_starts_with?: Maybe<Scalars['String']>;
  readonly timeZone_starts_with?: Maybe<Scalars['String']>;
  readonly userInformationId?: Maybe<Scalars['Int']>;
  readonly userInformationId_gt?: Maybe<Scalars['Int']>;
  readonly userInformationId_gte?: Maybe<Scalars['Int']>;
  readonly userInformationId_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly userInformationId_lt?: Maybe<Scalars['Int']>;
  readonly userInformationId_lte?: Maybe<Scalars['Int']>;
  readonly userInformationId_not?: Maybe<Scalars['Int']>;
  readonly userInformationId_not_gt?: Maybe<Scalars['Int']>;
  readonly userInformationId_not_gte?: Maybe<Scalars['Int']>;
  readonly userInformationId_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  readonly userInformationId_not_lt?: Maybe<Scalars['Int']>;
  readonly userInformationId_not_lte?: Maybe<Scalars['Int']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userName_contains?: Maybe<Scalars['String']>;
  readonly userName_ends_with?: Maybe<Scalars['String']>;
  readonly userName_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userName_not?: Maybe<Scalars['String']>;
  readonly userName_not_contains?: Maybe<Scalars['String']>;
  readonly userName_not_ends_with?: Maybe<Scalars['String']>;
  readonly userName_not_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userName_not_starts_with?: Maybe<Scalars['String']>;
  readonly userName_starts_with?: Maybe<Scalars['String']>;
  readonly userprogramId?: Maybe<Scalars['Int']>;
  readonly userprogramId_gt?: Maybe<Scalars['Int']>;
  readonly userprogramId_gte?: Maybe<Scalars['Int']>;
  readonly userprogramId_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userprogramId_lt?: Maybe<Scalars['Int']>;
  readonly userprogramId_lte?: Maybe<Scalars['Int']>;
  readonly userprogramId_not?: Maybe<Scalars['Int']>;
  readonly userprogramId_not_gt?: Maybe<Scalars['Int']>;
  readonly userprogramId_not_gte?: Maybe<Scalars['Int']>;
  readonly userprogramId_not_in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userprogramId_not_lt?: Maybe<Scalars['Int']>;
  readonly userprogramId_not_lte?: Maybe<Scalars['Int']>;
};

export type UserViewModel = {
  readonly __typename?: 'UserViewModel';
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly isAdmin?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly passwordStatus?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Boolean']>;
  readonly userName?: Maybe<Scalars['String']>;
  readonly userType?: Maybe<Scalars['String']>;
};

export type VwDrugDiagnosisList = {
  readonly __typename?: 'VwDrugDiagnosisList';
  readonly diagnosisListId: Scalars['Int'];
  readonly drugDiagnosisListID: Scalars['Int'];
  readonly icdcode?: Maybe<Scalars['String']>;
  readonly icdcodeType?: Maybe<Scalars['String']>;
  readonly icddescription?: Maybe<Scalars['String']>;
  readonly jcode?: Maybe<Scalars['String']>;
  readonly ndc?: Maybe<Scalars['String']>;
  readonly primaryIndication?: Maybe<Scalars['String']>;
};

export type EHipaaApiPrograms = {
  readonly __typename?: 'eHipaaApiPrograms';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly apiClientId?: Maybe<Scalars['String']>;
  readonly apiClientKey?: Maybe<Scalars['String']>;
  readonly consentDrug?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly eHipaaID?: Maybe<Scalars['ID']>;
  readonly eHipaaProgramId?: Maybe<Scalars['ID']>;
  readonly programID?: Maybe<Scalars['Int']>;
  readonly templateId?: Maybe<Scalars['Int']>;
};

export type PriorAuthorizationFragmentFragment = { readonly __typename?: 'PriorAuthorization' } & Pick<
  PriorAuthorization,
  | 'benefitInvestigationId'
  | 'ePAStatus'
  | 'ePATransactionID'
  | 'insurancePlanName'
  | 'insurancePlanType'
  | 'pAExpiresOn'
  | 'pAStatus'
  | 'pAType'
>;

export type PhoneNumberDetailsFragment = { readonly __typename?: 'PhoneNumberDetails' } & Pick<
  PhoneNumberDetails,
  'number' | 'rank' | 'recordType'
>;

export type ProviderNpiFragment = { readonly __typename?: 'NPIProvider' } & Pick<
  NpiProvider,
  'firstName' | 'lastName' | 'middleName' | 'taxId'
> & { npi: NpiProvider['nPI'] } & {
    readonly stateLicenses?: Maybe<
      ReadonlyArray<
        Maybe<
          { readonly __typename?: 'ProviderStateLicense' } & Pick<
            ProviderStateLicense,
            'licenseNumber' | 'licenseNumberIndex' | 'stateCode'
          >
        >
      >
    >;
  };

export type OrganizationNpiFragment = { readonly __typename?: 'NPIOrganization' } & Pick<
  NpiOrganization,
  'address1' | 'address2' | 'city' | 'faxNumber' | 'organizationName' | 'phoneNumber' | 'state' | 'taxId' | 'zipCode'
> & { npi: NpiOrganization['nPI'] };

export type OrganizationAddressFragment = { readonly __typename?: 'OrganizationAddress' } & Pick<
  OrganizationAddress,
  | 'address1'
  | 'address2'
  | 'address3'
  | 'city'
  | 'fax'
  | 'organizationId'
  | 'phone1'
  | 'phone1Type'
  | 'phone2'
  | 'phone2Type'
  | 'state'
  | 'zip'
  | 'rank'
> & { id: OrganizationAddress['organizationAddressId']; name: OrganizationAddress['addressName'] };

export type OrganizationFragment = { readonly __typename?: 'Organization' } & Pick<
  Organization,
  'organizationId' | 'organizationName' | 'taxId' | 'email'
> & { npi: Organization['nPI'] } & {
    readonly organizationAddresses?: Maybe<
      ReadonlyArray<Maybe<{ readonly __typename?: 'OrganizationAddress' } & OrganizationAddressFragment>>
    >;
  };

export type CopayCardFragment = { readonly __typename?: 'CopayCard' } & Pick<
  CopayCard,
  | 'created'
  | 'createdBy'
  | 'deleted'
  | 'drugName'
  | 'endDate'
  | 'groupID'
  | 'isVendorCopayCard'
  | 'lastUpdated'
  | 'lastUpdatedBy'
  | 'patientId'
  | 'serviceRequestId'
  | 'startDate'
> & {
    bin: CopayCard['bIN'];
    id: CopayCard['copayCardID'];
    pcn: CopayCard['pCN'];
    serviceRequestCopayCardId: CopayCard['sRCopayCardId'];
  };

export type PatientRequestViewModelFragment = { readonly __typename?: 'PatientRequestViewModel' } & Pick<
  PatientRequestViewModel,
  | 'actionAlertCount'
  | 'authorizationReceived'
  | 'firstName'
  | 'gender'
  | 'genderOther'
  | 'lastName'
  | 'location'
  | 'middleName'
  | 'missingInfoFields'
  | 'nextStep'
  | 'patientId'
  | 'physicianFirstName'
  | 'physicianLastName'
  | 'physicianId'
  | 'updateAlertCount'
  | 'rxAdminDate'
> & { dob: PatientRequestViewModel['dOB'] };

export type PatientSearchViewModelFragment = { readonly __typename?: 'PatientSearchViewModel' } & Pick<
  PatientSearchViewModel,
  'firstName' | 'lastName' | 'middleName' | 'patientId' | 'middleName' | 'patientId'
> & { dob: PatientSearchViewModel['dOB'] };

export type PatientPhoneNumberFragment = { readonly __typename?: 'PatientPhoneNumber' } & Pick<
  PatientPhoneNumber,
  'created' | 'lastUpdate' | 'patientPhoneNumberId' | 'phoneNumber' | 'phoneType'
> & { okToLeaveMsg: PatientPhoneNumber['oktoLeaveMsg'] };

export type PatientAddressFragment = { readonly __typename?: 'PatientAddress' } & Pick<
  PatientAddress,
  'address1' | 'address2' | 'city' | 'created' | 'lastUpdated' | 'patientAddressId' | 'state' | 'zip'
>;

export type DiagnosisListFragment = { readonly __typename?: 'PatientDiagnosisViewModel' } & Pick<
  PatientDiagnosisViewModel,
  'diagnosisListId' | 'icdcode' | 'icddescription' | 'rank'
>;

export type PatientContactFragment = { readonly __typename?: 'PatientContact' } & Pick<
  PatientContact,
  'patientId' | 'contactId' | 'relationship' | 'legallyAuthRep' | 'patientContactType'
> & {
    readonly contact?: Maybe<
      { readonly __typename?: 'Contact' } & Pick<Contact, 'firstName' | 'middleName' | 'lastName' | 'email'> & {
          readonly contactPhoneNumbers?: Maybe<
            ReadonlyArray<
              Maybe<
                { readonly __typename?: 'ContactPhoneNumber' } & Pick<ContactPhoneNumber, 'phoneNumber' | 'phoneType'>
              >
            >
          >;
        }
    >;
  };

export type PatientFragment = { readonly __typename?: 'Patient' } & Pick<
  Patient,
  | 'authorizationReceived'
  | 'created'
  | 'dob'
  | 'email'
  | 'firstName'
  | 'gender'
  | 'genderOther'
  | 'lastName'
  | 'lastUpdated'
  | 'middleName'
  | 'preferredLanguage'
  | 'preferredLanguageOther'
  | 'externalId'
> & { id: Patient['patientId'] } & {
    readonly address?: Maybe<{ readonly __typename?: 'PatientAddress' } & PatientAddressFragment>;
    readonly copayCard?: Maybe<{ readonly __typename?: 'CopayCard' } & CopayCardFragment>;
    readonly phoneNumbers?: Maybe<
      ReadonlyArray<Maybe<{ readonly __typename?: 'PatientPhoneNumber' } & PatientPhoneNumberFragment>>
    >;
    readonly patientContact?: Maybe<
      ReadonlyArray<Maybe<{ readonly __typename?: 'PatientContact' } & PatientContactFragment>>
    >;
    readonly patientDiagnosisDetail?: Maybe<
      ReadonlyArray<Maybe<{ readonly __typename?: 'PatientDiagnosisViewModel' } & DiagnosisListFragment>>
    >;
  };

export type PatientInsuranceFragment = { readonly __typename?: 'PatientInsurance' } & Pick<
  PatientInsurance,
  | 'cardholderName'
  | 'insuranceType'
  | 'isPatientPrimaryCardholder'
  | 'medicalCardHolderId'
  | 'medicalGroup'
  | 'medicalPhoneNumber'
  | 'medicalPlanName'
  | 'payerName'
  | 'pharmacyBINNumber'
  | 'pharmacyCardholderID'
  | 'pharmacyGroup'
  | 'pharmacyPCN'
  | 'pharmacyPhoneNumber'
  | 'pharmacyPlanName'
  | 'subscriberDOB'
  | 'subscriberFirstName'
  | 'subscriberGender'
  | 'subscriberLastName'
>;

export type PatientDiagnosisInfoFragment = { readonly __typename?: 'DiagnosisList' } & Pick<
  DiagnosisList,
  'created' | 'icdcode' | 'icdcodeType' | 'icddescription' | 'lastUpdated'
> & { id: DiagnosisList['diagnosisListId'] };

export type PayerFragment = { readonly __typename?: 'Payer' } & Pick<
  Payer,
  'payerId' | 'medePayerId' | 'medPAPayerId' | 'payerName' | 'payerCategories'
>;

export type PhysicianFragment = { readonly __typename?: 'Physician' } & Pick<
  Physician,
  'email' | 'firstName' | 'lastName' | 'middleName' | 'prefix' | 'suffix' | 'providerType'
> & { id: Physician['physicianId']; npi: Physician['nPI'] } & {
    readonly physicianSpecialties?: Maybe<
      ReadonlyArray<
        Maybe<
          { readonly __typename?: 'PhysicianSpecialties' } & Pick<
            PhysicianSpecialties,
            'physicianId' | 'lookupDataListValue' | 'lookupDataListId' | 'physicianSpecialtiesId' | 'rank'
          >
        >
      >
    >;
    readonly details?: Maybe<
      { readonly __typename?: 'PhysicianDetail' } & Pick<
        PhysicianDetail,
        'created' | 'lastUpdated' | 'taxId' | 'pTAN' | 'dEA' | 'medicareID' | 'medicaidID' | 'hIN'
      > & { id: PhysicianDetail['physiciansDetailId'] }
    >;
    readonly stateLicenses?: Maybe<
      { readonly __typename?: 'PhysicianStateLicense' } & Pick<
        PhysicianStateLicense,
        'created' | 'expirationDate' | 'lastUpdated' | 'stateLicenseNumber'
      > & { id: PhysicianStateLicense['physicianStateLicenseId']; stateIssuer: PhysicianStateLicense['licenseState'] }
    >;
  };

export type PortalTerritoryFragment = { readonly __typename?: 'PortalTerritory' } & Pick<
  PortalTerritory,
  'territoryName'
> & { id: PortalTerritory['territoryId'] };

export type SpecialtyPharmacyContactFragmentFragment = { readonly __typename?: 'SpecialtyPharmacyContact' } & Pick<
  SpecialtyPharmacyContact,
  'specialtyPharmacyName' | 'webSite' | 'faxNumber' | 'phoneNumber' | 'specialtyPharmacyType'
>;

export type DocumentFragmentFragment = { readonly __typename?: 'Document' } & Pick<
  Document,
  | 'created'
  | 'entityId'
  | 'entityName'
  | 'fileName'
  | 'fileStorageId'
  | 'formComplete'
  | 'lastUpdated'
  | 'programId'
  | 'signatureRequired'
  | 'source'
  | 'status'
  | 'totalPages'
> & { id: Document['documentId'] };

export type ServiceRequestViewFragment = { readonly __typename?: 'ServiceRequestViewModel' } & Pick<
  ServiceRequestViewModel,
  | 'actionAlert'
  | 'authorizationReceived'
  | 'copayCardId'
  | 'displayOnGrid'
  | 'missingInfoFields'
  | 'nextStep'
  | 'patientDOB'
  | 'patientFirstName'
  | 'patientId'
  | 'patientLastName'
  | 'physicianFirstName'
  | 'physicianLastName'
  | 'shippedDate'
  | 'shippedStatus'
  | 'sRDrugName'
  | 'statusText'
  | 'updateAlert'
  | 'requestID'
  | 'updatedAlertDate'
> & {
    id: ServiceRequestViewModel['serviceRequestId'];
    requestStartDate: ServiceRequestViewModel['sRStartDate'];
    requestStatus: ServiceRequestViewModel['sRStatus'];
    requestType: ServiceRequestViewModel['sRType'];
  } & {
    readonly documents?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'Document' } & DocumentFragmentFragment>>>;
  };

export type OrganizationUserFragment = { readonly __typename?: 'UserViewModel' } & Pick<
  UserViewModel,
  'email' | 'firstName' | 'isAdmin' | 'lastName' | 'passwordStatus' | 'status' | 'userName' | 'userType'
>;

export type UserInfoFragment = { readonly __typename?: 'UserInfo' } & Pick<
  UserInfo,
  'email' | 'fax' | 'phone' | 'rank' | 'recordType' | 'zip'
>;

export type CaseDetailsQueryVariables = Exact<{
  caseRecordId: Scalars['ID'];
}>;

export type CaseDetailsQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'CaseDetail' } & {
      readonly mileStones?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'CaseMileStone' } & Pick<
              CaseMileStone,
              'mileStoneCode' | 'mileStoneId' | 'mileStoneName' | 'mileStoneType' | 'sortOrder'
            >
          >
        >
      >;
      readonly transactions?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'CaseTransaction' } & Pick<
              CaseTransaction,
              | 'caseClosed'
              | 'caseDrugName'
              | 'caseFlowStep'
              | 'caseIntakeId'
              | 'caseMileStoneCode'
              | 'caseRecordId'
              | 'caseRecordTransactionId'
              | 'caseSource'
              | 'caseStartTrigger'
              | 'caseTransactionId'
              | 'caseTransactionState'
              | 'caseTransactionStatus'
              | 'caseTransactionType'
              | 'mileStoneCreatedDate'
              | 'caseDrugDescription'
            >
          >
        >
      >;
      readonly prescriptionIntakes?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'PrescriptionIntake' } & Pick<
              PrescriptionIntake,
              | 'prescriptionId'
              | 'prescriptionReceivedDate'
              | 'drugName'
              | 'ndc'
              | 'doseType'
              | 'quantity'
              | 'daysSupply'
            >
          >
        >
      >;
      readonly benefitInvestigations?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'BenefitInvestigation' } & Pick<
              BenefitInvestigation,
              | 'insurancePlanName'
              | 'insurancePlanType'
              | 'bIType'
              | 'bICreatedDate'
              | 'productCovered'
              | 'eBVTransactionId'
              | 'priorAuthRequired'
              | 'therapyOrDrug'
              | 'insuranceRepName'
              | 'dateYearPlan'
              | 'planEffectiveDate'
              | 'planEndDate'
              | 'coInsurancePercent'
              | 'deductible'
              | 'deductibleAmountMet'
              | 'outOfPocketMax'
              | 'oopAmountMet'
              | 'additionalComments'
            >
          >
        >
      >;
      readonly priorAuthorizations?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'PriorAuthorization' } & Pick<
              PriorAuthorization,
              | 'insurancePlanName'
              | 'insurancePlanType'
              | 'pAType'
              | 'pACreatedDate'
              | 'pAStatus'
              | 'pAExpiresOn'
              | 'ePATransactionID'
              | 'ePAStatus'
              | 'authorizationNumber'
              | 'pASubmissionType'
              | 'referenceKey'
              | 'providerPAWebsite'
              | 'pASubmissionDate'
              | 'pAApprovalDate'
              | 'pAExpirationDate'
              | 'pADenialDate'
              | 'denialReason'
              | 'otherDenialReason'
              | 'pAOnFile'
              | 'daysToCompletePAReview'
              | 'stepTherapyRequirements'
              | 'pARequirements'
              | 'otherPARequirements'
              | 'additionalComments'
              | 'therapyOrDrug'
            >
          >
        >
      >;
      readonly triagePrescriptions?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'TriagePrescription' } & Pick<
              TriagePrescription,
              'sentToPharmacy' | 'dateSentToPharmacy' | 'status'
            >
          >
        >
      >;
      readonly patientEnrollments?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'PatientIntake' } & Pick<
              PatientIntake,
              'caseTransactionId' | 'caseTransactionStatus' | 'created' | 'enrollmentId' | 'patientEnrollmentGuid'
            >
          >
        >
      >;
      readonly appeals?: Maybe<
        ReadonlyArray<
          Maybe<
            { readonly __typename?: 'Appeal' } & Pick<
              Appeal,
              | 'insurancePlanName'
              | 'insurancePlanType'
              | 'appealType'
              | 'therapyOrDrug'
              | 'appealRequirements'
              | 'appealInitiated'
              | 'appealCompletedDate'
              | 'appealSubmitters'
              | 'appealMethods'
              | 'appealContactName'
              | 'appealContactAddress'
              | 'appealContactPhoneNumber'
              | 'appealContactFaxNumber'
              | 'appealOnlineSubmissionUrl'
              | 'appealResponseTurnaroundTime'
              | 'appealStatus'
              | 'appealDenialReasons'
              | 'appealDenialReasonOther'
              | 'appealDenialDate'
              | 'appealCreatedDate'
              | 'comments'
            >
          >
        >
      >;
    }
  >;
};

export type CaseRecordDocumentsQueryVariables = Exact<{
  caseRecordId: Scalars['ID'];
}>;

export type CaseRecordDocumentsQuery = { readonly __typename?: 'Query' } & {
  readonly caseRecordDocuments?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'CaseRecordDocuments' } & Pick<
          CaseRecordDocuments,
          | 'caseRecordId'
          | 'caseTransactionId'
          | 'created'
          | 'deleted'
          | 'documentId'
          | 'entityId'
          | 'entityName'
          | 'fileName'
          | 'fileStorageId'
          | 'formComplete'
          | 'lastUpdated'
          | 'signatureRequired'
          | 'source'
          | 'documentType'
          | 'drugName'
        >
      >
    >
  >;
};

export type CaseRecordsQueryVariables = Exact<{
  programId: Scalars['ID'];
  organizationId: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  userName: Scalars['String'];
}>;

export type CaseRecordsQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'CaseRecord' } & Pick<
          CaseRecord,
          | 'caseRecordId'
          | 'caseStatus'
          | 'caseSubStatus'
          | 'caseClosed'
          | 'caseDrugName'
          | 'caseMileStone'
          | 'caseReceivedDate'
          | 'hasUnReadMessage'
          | 'isUpdatedRecently'
          | 'needAction'
          | 'patientId'
          | 'patientFirstName'
          | 'patientLastName'
          | 'providerFirstName'
          | 'providerLastName'
          | 'providerNpi'
          | 'lastUpdatedDate'
          | 'caseDrugDescription'
        >
      >
    >
  >;
};

export type PhoneNumberQueryVariables = Exact<{
  recordId: Scalars['ID'];
  recordType: Scalars['String'];
}>;

export type PhoneNumberQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'PhoneNumberDetails' } & Pick<PhoneNumberDetails, 'number' | 'rank' | 'recordType'>
  >;
};

export type GetPatientDispensesQueryQueryVariables = Exact<{
  programId: Scalars['ID'];
  patientId: Scalars['ID'];
}>;

export type GetPatientDispensesQueryQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'PatientDetailDispenses' } & Pick<
          PatientDetailDispenses,
          | 'shippedDate'
          | 'pharmacyName'
          | 'productName'
          | 'dispenseType'
          | 'quantity'
          | 'fillNumber'
          | 'refillRemaining'
          | 'shippingCarrier'
          | 'trackingNumber'
          | 'physicianFirstName'
          | 'physicianLastName'
          | 'physicianNpi'
        >
      >
    >
  >;
};

export type DrugDetailsByClientIdQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;

export type DrugDetailsByClientIdQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'DrugInfo' } & Pick<
          DrugInfo,
          'drugBrandName' | 'drugId' | 'drugGenericName' | 'drugLabelName' | 'jCode1' | 'jCode2'
        > & {
            readonly drugDetails?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'DrugDetail' } & Pick<
                    DrugDetail,
                    'drugNdc' | 'drugDescription' | 'daysSupply' | 'quantity'
                  >
                >
              >
            >;
            readonly drugCptCode?: Maybe<
              ReadonlyArray<Maybe<{ readonly __typename?: 'DrugCPTCodeDetail' } & Pick<DrugCptCodeDetail, 'cptCode'>>>
            >;
          }
      >
    >
  >;
};

export type OrganizationNpiQueryVariables = Exact<{
  npi: Scalars['String'];
}>;

export type OrganizationNpiQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'NPIOrganization' } & Pick<
          NpiOrganization,
          | 'address1'
          | 'address2'
          | 'city'
          | 'faxNumber'
          | 'organizationName'
          | 'phoneNumber'
          | 'state'
          | 'taxId'
          | 'zipCode'
        > & { npi: NpiOrganization['nPI'] }
      >
    >
  >;
};

export type ProviderNpiQueryVariables = Exact<{
  npi: Scalars['String'];
}>;

export type ProviderNpiQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'NPIProvider' } & Pick<
          NpiProvider,
          'firstName' | 'lastName' | 'middleName' | 'taxId'
        > & { npi: NpiProvider['nPI'] } & {
            readonly stateLicenses?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'ProviderStateLicense' } & Pick<
                    ProviderStateLicense,
                    'licenseNumber' | 'licenseNumberIndex' | 'stateCode'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type OrganizationAddressInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type OrganizationAddressInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'OrganizationAddress' } & Pick<
          OrganizationAddress,
          | 'address1'
          | 'address2'
          | 'address3'
          | 'city'
          | 'fax'
          | 'organizationId'
          | 'phone1'
          | 'phone1Type'
          | 'phone2'
          | 'phone2Type'
          | 'state'
          | 'zip'
        > & { id: OrganizationAddress['organizationAddressId']; name: OrganizationAddress['addressName'] }
      >
    >
  >;
};

export type OrganizationUsersQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type OrganizationUsersQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'UserViewModel' } & Pick<
          UserViewModel,
          'email' | 'firstName' | 'isAdmin' | 'lastName' | 'passwordStatus' | 'status' | 'userName' | 'userType'
        >
      >
    >
  >;
};

export type UserInfoQueryVariables = Exact<{
  recordId: Scalars['ID'];
  recordType: Scalars['String'];
}>;

export type UserInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'UserInfo' } & Pick<UserInfo, 'email' | 'fax' | 'phone' | 'rank' | 'recordType' | 'zip'>
  >;
};

export type OrganizationDetailsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type OrganizationDetailsQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'Organization' } & Pick<
      Organization,
      'organizationId' | 'organizationName' | 'taxId' | 'email'
    > & { npi: Organization['nPI'] } & {
        readonly organizationAddresses?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'OrganizationAddress' } & Pick<
                OrganizationAddress,
                | 'address1'
                | 'address2'
                | 'address3'
                | 'city'
                | 'fax'
                | 'organizationId'
                | 'phone1'
                | 'phone1Type'
                | 'phone2'
                | 'phone2Type'
                | 'state'
                | 'zip'
                | 'rank'
              > & { id: OrganizationAddress['organizationAddressId']; name: OrganizationAddress['addressName'] }
            >
          >
        >;
      }
  >;
};

export type GetPatientDetailByIdQueryVariables = Exact<{
  organization: Scalars['Int'];
  patientId: Scalars['Int'];
}>;

export type GetPatientDetailByIdQuery = { readonly __typename?: 'Query' } & {
  readonly patientDetailByPatientID?: Maybe<
    { readonly __typename?: 'PatientDetailViewModel' } & {
      readonly organizationAddress?: Maybe<
        { readonly __typename?: 'OrganizationAddress' } & Pick<
          OrganizationAddress,
          | 'organizationAddressId'
          | 'organizationId'
          | 'addressName'
          | 'address1'
          | 'address2'
          | 'city'
          | 'state'
          | 'phone1'
          | 'phone1Type'
          | 'phone2'
          | 'phone2Type'
          | 'fax'
        >
      >;
      readonly physician?: Maybe<
        { readonly __typename?: 'Physician' } & Pick<
          Physician,
          'physicianId' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'prefix' | 'suffix'
        > & { npi: Physician['nPI'] } & {
            readonly physicianSpecialties?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'PhysicianSpecialties' } & Pick<
                    PhysicianSpecialties,
                    'physicianId' | 'lookupDataListValue' | 'lookupDataListId' | 'physicianSpecialtiesId' | 'rank'
                  >
                >
              >
            >;
            readonly details?: Maybe<
              { readonly __typename?: 'PhysicianDetail' } & Pick<
                PhysicianDetail,
                'created' | 'lastUpdated' | 'taxId' | 'pTAN' | 'dEA' | 'medicareID' | 'medicaidID'
              > & { id: PhysicianDetail['physiciansDetailId'] }
            >;
            readonly stateLicenses?: Maybe<
              { readonly __typename?: 'PhysicianStateLicense' } & Pick<
                PhysicianStateLicense,
                'created' | 'expirationDate' | 'lastUpdated' | 'stateLicenseNumber'
              > & {
                  id: PhysicianStateLicense['physicianStateLicenseId'];
                  stateIssuer: PhysicianStateLicense['licenseState'];
                }
            >;
          }
      >;
    }
  >;
};

export type PatientInfoQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;

export type PatientInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'Patient' } & Pick<
      Patient,
      | 'authorizationReceived'
      | 'created'
      | 'dob'
      | 'email'
      | 'firstName'
      | 'gender'
      | 'genderOther'
      | 'lastName'
      | 'lastUpdated'
      | 'middleName'
      | 'preferredLanguage'
      | 'preferredLanguageOther'
      | 'externalId'
    > & { id: Patient['patientId'] } & {
        readonly physicians?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'PhysicianViewModel' } & Pick<
                PhysicianViewModel,
                'physicianId' | 'firstName' | 'lastName'
              >
            >
          >
        >;
        readonly address?: Maybe<
          { readonly __typename?: 'PatientAddress' } & Pick<
            PatientAddress,
            'address1' | 'address2' | 'city' | 'created' | 'lastUpdated' | 'patientAddressId' | 'state' | 'zip'
          >
        >;
        readonly copayCard?: Maybe<
          { readonly __typename?: 'CopayCard' } & Pick<
            CopayCard,
            | 'created'
            | 'createdBy'
            | 'deleted'
            | 'drugName'
            | 'endDate'
            | 'groupID'
            | 'isVendorCopayCard'
            | 'lastUpdated'
            | 'lastUpdatedBy'
            | 'patientId'
            | 'serviceRequestId'
            | 'startDate'
          > & {
              bin: CopayCard['bIN'];
              id: CopayCard['copayCardID'];
              pcn: CopayCard['pCN'];
              serviceRequestCopayCardId: CopayCard['sRCopayCardId'];
            }
        >;
        readonly phoneNumbers?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'PatientPhoneNumber' } & Pick<
                PatientPhoneNumber,
                'created' | 'lastUpdate' | 'patientPhoneNumberId' | 'phoneNumber' | 'phoneType'
              > & { okToLeaveMsg: PatientPhoneNumber['oktoLeaveMsg'] }
            >
          >
        >;
        readonly patientContact?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'PatientContact' } & Pick<
                PatientContact,
                'patientId' | 'contactId' | 'relationship' | 'legallyAuthRep' | 'patientContactType'
              > & {
                  readonly contact?: Maybe<
                    { readonly __typename?: 'Contact' } & Pick<
                      Contact,
                      'firstName' | 'middleName' | 'lastName' | 'email'
                    >
                  >;
                }
            >
          >
        >;
        readonly patientDiagnosisDetail?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'PatientDiagnosisViewModel' } & Pick<
                PatientDiagnosisViewModel,
                'diagnosisListId' | 'icdcode' | 'icddescription' | 'rank'
              >
            >
          >
        >;
      }
  >;
};

export type PatientRequestInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type PatientRequestInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'PatientRequestViewModel' } & Pick<
          PatientRequestViewModel,
          | 'actionAlertCount'
          | 'authorizationReceived'
          | 'firstName'
          | 'gender'
          | 'genderOther'
          | 'lastName'
          | 'location'
          | 'middleName'
          | 'nextStep'
          | 'patientId'
          | 'physicianFirstName'
          | 'physicianLastName'
          | 'physicianId'
          | 'updateAlertCount'
          | 'statusText'
          | 'rxAdminDate'
          | 'rxAdminDose'
          | 'rxAdminStatus'
          | 'rxAdminAlert'
        > & { dob: PatientRequestViewModel['dOB'] }
      >
    >
  >;
};

export type PatientSearchInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type PatientSearchInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'PatientSearchViewModel' } & Pick<
          PatientSearchViewModel,
          'firstName' | 'lastName' | 'middleName' | 'patientId'
        > & { dob: PatientSearchViewModel['dOB'] }
      >
    >
  >;
};

export type PatientInsuranceQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;

export type PatientInsuranceQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'PatientInsurance' } & Pick<
          PatientInsurance,
          | 'cardholderName'
          | 'insuranceType'
          | 'isPatientPrimaryCardholder'
          | 'medicalCardHolderId'
          | 'medicalGroup'
          | 'medicalPhoneNumber'
          | 'medicalPlanName'
          | 'payerName'
          | 'pharmacyBINNumber'
          | 'pharmacyCardholderID'
          | 'pharmacyGroup'
          | 'pharmacyPCN'
          | 'pharmacyPhoneNumber'
          | 'pharmacyPlanName'
          | 'subscriberDOB'
          | 'subscriberFirstName'
          | 'subscriberGender'
          | 'subscriberLastName'
          | 'payerId'
          | 'medePayerId'
          | 'medPAPayerId'
          | 'parentPayerId'
          | 'payerType'
          | 'insuranceName'
          | 'planType'
          | 'phoneNumber'
          | 'employerName'
          | 'relationship'
          | 'insuranceId'
        >
      >
    >
  >;
};

export type PatientDiagnosisQueryVariables = Exact<{
  iCDCode: Scalars['ID'];
}>;

export type PatientDiagnosisQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'DiagnosisList' } & Pick<
          DiagnosisList,
          'created' | 'icdcode' | 'icdcodeType' | 'icddescription' | 'lastUpdated'
        > & { id: DiagnosisList['diagnosisListId'] }
      >
    >
  >;
};

export type PatientDiagnosisByProgramQueryVariables = Exact<{
  iCDCode: Scalars['String'];
  programId: Scalars['Int'];
  drugId: Scalars['Int'];
}>;

export type PatientDiagnosisByProgramQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'DiagnosisList' } & Pick<
          DiagnosisList,
          'icdcode' | 'icdcodeType' | 'icddescription'
        > & { id: DiagnosisList['diagnosisListId'] }
      >
    >
  >;
};

export type PatientStatusesQueryQueryVariables = Exact<{
  programId: Scalars['Int'];
}>;

export type PatientStatusesQueryQuery = { readonly __typename?: 'Query' } & {
  readonly patientStatuses?: Maybe<
    ReadonlyArray<
      Maybe<{ readonly __typename?: 'PatientStatusDescription' } & Pick<PatientStatusDescription, 'statusDescription'>>
    >
  >;
};

export type DrugCptCodeByProgramQueryVariables = Exact<{
  programId: Scalars['Int'];
  drugId?: Maybe<Scalars['Int']>;
}>;

export type DrugCptCodeByProgramQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'DrugCptCode' } & Pick<DrugCptCode, 'drugCptCodeId' | 'cptCode' | 'cptCodeDescription'>
      >
    >
  >;
};

export type PayersQueryVariables = Exact<{
  categories: Scalars['String'];
  payerName: Scalars['String'];
}>;

export type PayersQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'Payer' } & Pick<
          Payer,
          'payerId' | 'medePayerId' | 'medPAPayerId' | 'payerName' | 'payerCategories'
        >
      >
    >
  >;
};

export type PhysiciansInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type PhysiciansInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'Physician' } & Pick<
          Physician,
          'email' | 'firstName' | 'lastName' | 'middleName' | 'prefix' | 'suffix'
        > & { id: Physician['physicianId']; npi: Physician['nPI'] } & {
            readonly physicianSpecialties?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'PhysicianSpecialties' } & Pick<
                    PhysicianSpecialties,
                    'physicianId' | 'lookupDataListValue' | 'lookupDataListId' | 'physicianSpecialtiesId' | 'rank'
                  >
                >
              >
            >;
            readonly details?: Maybe<
              { readonly __typename?: 'PhysicianDetail' } & Pick<
                PhysicianDetail,
                'created' | 'lastUpdated' | 'taxId' | 'pTAN' | 'dEA' | 'medicareID' | 'medicaidID'
              > & { id: PhysicianDetail['physiciansDetailId'] }
            >;
            readonly stateLicenses?: Maybe<
              { readonly __typename?: 'PhysicianStateLicense' } & Pick<
                PhysicianStateLicense,
                'created' | 'expirationDate' | 'lastUpdated' | 'stateLicenseNumber'
              > & {
                  id: PhysicianStateLicense['physicianStateLicenseId'];
                  stateIssuer: PhysicianStateLicense['licenseState'];
                }
            >;
          }
      >
    >
  >;
};

export type PhysicianInfoQueryVariables = Exact<{
  npi: Scalars['String'];
  programId?: Maybe<Scalars['Int']>;
}>;

export type PhysicianInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'Physician' } & Pick<
      Physician,
      'email' | 'firstName' | 'lastName' | 'middleName' | 'prefix' | 'suffix' | 'providerType'
    > & { id: Physician['physicianId']; npi: Physician['nPI'] } & {
        readonly physicianSpecialties?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'PhysicianSpecialties' } & Pick<
                PhysicianSpecialties,
                'physicianId' | 'lookupDataListValue' | 'lookupDataListId' | 'physicianSpecialtiesId' | 'rank'
              >
            >
          >
        >;
        readonly details?: Maybe<
          { readonly __typename?: 'PhysicianDetail' } & Pick<
            PhysicianDetail,
            'created' | 'lastUpdated' | 'taxId' | 'pTAN' | 'dEA' | 'medicareID' | 'medicaidID' | 'hIN'
          > & { id: PhysicianDetail['physiciansDetailId'] }
        >;
        readonly stateLicenses?: Maybe<
          { readonly __typename?: 'PhysicianStateLicense' } & Pick<
            PhysicianStateLicense,
            'created' | 'expirationDate' | 'lastUpdated' | 'stateLicenseNumber'
          > & {
              id: PhysicianStateLicense['physicianStateLicenseId'];
              stateIssuer: PhysicianStateLicense['licenseState'];
            }
        >;
      }
  >;
};

export type TerritoryDetailsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type TerritoryDetailsQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    { readonly __typename?: 'PortalTerritory' } & Pick<PortalTerritory, 'territoryName'> & {
        id: PortalTerritory['territoryId'];
      }
  >;
};

export type PrescriptionByIdQueryVariables = Exact<{
  prescriptionId: Scalars['ID'];
}>;

export type PrescriptionByIdQuery = { readonly __typename?: 'Query' } & {
  readonly prescriptionById?: Maybe<
    { readonly __typename?: 'C_Prescription' } & Pick<
      CPrescription,
      | 'prescriptionId'
      | 'externalPrescriptionId'
      | 'patientFirstName'
      | 'patientLastName'
      | 'patientMiddleName'
      | 'patientSuffix'
      | 'patientDob'
      | 'patientGender'
      | 'patientAddressLine1'
      | 'patientAddressLine2'
      | 'patientCity'
      | 'patientState'
      | 'patientZipCode'
      | 'patientPhoneNumber1'
      | 'patientPhoneNumber2'
      | 'payerName'
      | 'bin'
      | 'pcn'
      | 'groupId'
      | 'cardholderId'
      | 'personCode'
      | 'helpDeskPhone'
      | 'medicareFlag'
      | 'benefitPlanId'
      | 'effectiveDate'
      | 'terminationDate'
      | 'licslevel'
      | 'formularyId'
      | 'futureEffectiveDate'
      | 'futureTerminationDate'
      | 'licseffectDate'
      | 'licstermDate'
      | 'licsplan'
      | 'createdOn'
      | 'createdBy'
      | 'carrierName'
      | 'dateWritten'
      | 'drugName'
      | 'sig'
      | 'ndc'
      | 'quantity'
      | 'unitofMeasure'
      | 'refillQualifier'
      | 'refills'
      | 'daw'
      | 'daysSupply'
      | 'lastFillDate'
      | 'rxEffectiveDate'
      | 'diagnoses'
      | 'note'
      | 'priorAuthStatus'
      | 'priorAuthQual'
      | 'priorAuth'
      | 'deleted'
      | 'isCreatedByFax'
      | 'lastUpdated'
      | 'lastUpdatedBy'
      | 'drugBasis'
      | 'doseType'
      | 'isPrescriptionClosed'
      | 'prescriptionSource'
      | 'applicationSource'
      | 'rxReferenceId'
      | 'refillsRemaining'
      | 'substitutionPermitted'
      | 'prescriberOrderNumber'
    > & {
        readonly prescriptionPharmacy?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'C_PrescriptionPharmacy' } & Pick<
                CPrescriptionPharmacy,
                | 'prescriptionPharmacyId'
                | 'prescriptionId'
                | 'storeName'
                | 'ncpdp'
                | 'npi'
                | 'addressline1'
                | 'addressline2'
                | 'city'
                | 'state'
                | 'zip'
                | 'phone'
                | 'fax'
                | 'createdOn'
                | 'createdBy'
                | 'lastUpdated'
                | 'lastUpdatedBy'
              >
            >
          >
        >;
        readonly prescriptionProviders?: Maybe<
          ReadonlyArray<
            Maybe<
              { readonly __typename?: 'C_PrescriptionProviders' } & Pick<
                CPrescriptionProviders,
                | 'prescriptionProviderId'
                | 'prescriptionId'
                | 'firstName'
                | 'lastName'
                | 'middleName'
                | 'suffix'
                | 'clinicName'
                | 'addressLine1'
                | 'addressLine2'
                | 'city'
                | 'state'
                | 'zip'
                | 'phone1'
                | 'phone2'
                | 'fax'
                | 'email'
                | 'dea'
                | 'npi'
                | 'isSupervisingPrescriberinfo'
                | 'createdOn'
                | 'createdBy'
                | 'lastUpdated'
                | 'lastUpdatedBy'
                | 'medicareID'
                | 'medicaidID'
                | 'stateLicenseNumber'
              >
            >
          >
        >;
      }
  >;
};

export type CaseMangerContactQueryVariables = Exact<{
  programId: Scalars['Int'];
  zip: Scalars['String'];
}>;

export type CaseMangerContactQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<{ readonly __typename?: 'CaseMangerContact' } & Pick<CaseMangerContact, 'name' | 'phoneNumber' | 'email'>>
    >
  >;
};

export type FieldReimbursementManagerContactQueryVariables = Exact<{
  programId: Scalars['Int'];
  zip: Scalars['String'];
}>;

export type FieldReimbursementManagerContactQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'FieldReimbursementManagerContact' } & Pick<
          FieldReimbursementManagerContact,
          'email' | 'name' | 'phoneNumber' | 'territoryName'
        >
      >
    >
  >;
};

export type SpecialtyPharmacyContactQueryVariables = Exact<{
  programId: Scalars['Int'];
  brandName: Scalars['String'];
}>;

export type SpecialtyPharmacyContactQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'SpecialtyPharmacyContact' } & Pick<
          SpecialtyPharmacyContact,
          'specialtyPharmacyName' | 'webSite' | 'faxNumber' | 'phoneNumber' | 'specialtyPharmacyType'
        >
      >
    >
  >;
};

export type ServiceRequestInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
  patientId?: Maybe<Scalars['Int']>;
}>;

export type ServiceRequestInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'ServiceRequestViewModel' } & Pick<
          ServiceRequestViewModel,
          | 'actionAlert'
          | 'authorizationReceived'
          | 'copayCardId'
          | 'displayOnGrid'
          | 'missingInfoFields'
          | 'nextStep'
          | 'patientDOB'
          | 'patientFirstName'
          | 'patientId'
          | 'patientLastName'
          | 'physicianFirstName'
          | 'physicianLastName'
          | 'physicianNPI'
          | 'shippedDate'
          | 'shippedStatus'
          | 'sRDrugName'
          | 'statusText'
          | 'updateAlert'
          | 'requestID'
          | 'updatedAlertDate'
          | 'patientEnrollmentGuid'
          | 'isEserviceTransaction'
        > & {
            id: ServiceRequestViewModel['serviceRequestId'];
            requestStartDate: ServiceRequestViewModel['sRStartDate'];
            requestStatus: ServiceRequestViewModel['sRStatus'];
            requestType: ServiceRequestViewModel['sRType'];
          } & {
            readonly documents?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'Document' } & Pick<
                    Document,
                    | 'created'
                    | 'entityId'
                    | 'entityName'
                    | 'fileName'
                    | 'fileStorageId'
                    | 'formComplete'
                    | 'lastUpdated'
                    | 'programId'
                    | 'signatureRequired'
                    | 'source'
                    | 'status'
                    | 'totalPages'
                  > & { id: Document['documentId'] }
                >
              >
            >;
          }
      >
    >
  >;
};

export type ServiceRequestWithDocumentsInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  programId: Scalars['ID'];
  patientId?: Maybe<Scalars['Int']>;
  serviceRequestId?: Maybe<Scalars['Int']>;
}>;

export type ServiceRequestWithDocumentsInfoQuery = { readonly __typename?: 'Query' } & {
  readonly result?: Maybe<
    ReadonlyArray<
      Maybe<
        { readonly __typename?: 'ServiceRequestViewModel' } & Pick<
          ServiceRequestViewModel,
          | 'actionAlert'
          | 'authorizationReceived'
          | 'copayCardId'
          | 'displayOnGrid'
          | 'missingInfoFields'
          | 'nextStep'
          | 'patientDOB'
          | 'patientFirstName'
          | 'patientId'
          | 'patientLastName'
          | 'physicianFirstName'
          | 'physicianLastName'
          | 'shippedDate'
          | 'shippedStatus'
          | 'sRDrugName'
          | 'statusText'
          | 'updateAlert'
          | 'updatedAlertDate'
          | 'requestID'
        > & {
            id: ServiceRequestViewModel['serviceRequestId'];
            requestStartDate: ServiceRequestViewModel['sRStartDate'];
            requestStatus: ServiceRequestViewModel['sRStatus'];
            requestType: ServiceRequestViewModel['sRType'];
          } & {
            readonly documents?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'Document' } & Pick<
                    Document,
                    | 'created'
                    | 'entityId'
                    | 'entityName'
                    | 'fileName'
                    | 'fileStorageId'
                    | 'formComplete'
                    | 'lastUpdated'
                    | 'programId'
                    | 'documentType'
                    | 'signatureRequired'
                    | 'source'
                    | 'status'
                    | 'totalPages'
                    | 'product'
                  > & { id: Document['documentId'] }
                >
              >
            >;
            readonly caseRecordTransaction?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'CaseRecordTransaction' } & Pick<
                    CaseRecordTransaction,
                    | 'caseFlowStep'
                    | 'caseMileStone'
                    | 'caseRecordId'
                    | 'caseTransactionId'
                    | 'caseTransactionState'
                    | 'caseTransactionStatus'
                    | 'caseTransactionType'
                  > & { id: CaseRecordTransaction['caseRecordTransactionId'] }
                >
              >
            >;
            readonly copayCard?: Maybe<
              { readonly __typename?: 'CopayClaimSubmissionViewModel' } & Pick<
                CopayClaimSubmissionViewModel,
                | 'copayCardID'
                | 'payeeName'
                | 'payeeAddress'
                | 'taxID'
                | 'totalApprovedAmount'
                | 'totalCopayRequestedAmount'
                | 'totalDueAmount'
                | 'payeeType'
                | 'copayCardEffectiveStartDate'
                | 'copayCardEffectiveEndDate'
                | 'eOBReceivedDate'
              >
            >;
            readonly srCopayPaymentEob?: Maybe<
              ReadonlyArray<
                Maybe<
                  { readonly __typename?: 'SrCopayPaymentEob' } & Pick<
                    SrCopayPaymentEob,
                    | 'copayCardId'
                    | 'billingCode'
                    | 'dateOfService'
                    | 'quantity'
                    | 'originalBilledAmount'
                    | 'patientCopayAmount'
                    | 'patientDueAmount'
                    | 'status'
                    | 'statusUpdatedDate'
                    | 'statusUpdatedBy'
                    | 'primaryPlanPaidAmount'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetSrHubStatusesQueryVariables = Exact<{
  programId: Scalars['Int'];
  organizationId: Scalars['Int'];
}>;

export type GetSrHubStatusesQuery = { readonly __typename?: 'Query' } & {
  readonly srHubStatuses?: Maybe<
    ReadonlyArray<
      Maybe<{ readonly __typename?: 'SrHubStatusDescription' } & Pick<SrHubStatusDescription, 'statusDescription'>>
    >
  >;
};

export const PriorAuthorizationFragmentFragmentDoc = gql`
  fragment priorAuthorizationFragment on PriorAuthorization {
    benefitInvestigationId
    ePAStatus
    ePATransactionID
    insurancePlanName
    insurancePlanType
    pAExpiresOn
    pAStatus
    pAType
  }
`;
export const PhoneNumberDetailsFragmentDoc = gql`
  fragment phoneNumberDetails on PhoneNumberDetails {
    number
    rank
    recordType
  }
`;
export const ProviderNpiFragmentDoc = gql`
  fragment providerNpi on NPIProvider {
    npi: nPI
    firstName
    lastName
    middleName
    taxId
    stateLicenses {
      licenseNumber
      licenseNumberIndex
      stateCode
    }
  }
`;
export const OrganizationNpiFragmentDoc = gql`
  fragment organizationNpi on NPIOrganization {
    npi: nPI
    address1
    address2
    city
    faxNumber
    organizationName
    phoneNumber
    state
    taxId
    zipCode
  }
`;
export const OrganizationAddressFragmentDoc = gql`
  fragment organizationAddress on OrganizationAddress {
    id: organizationAddressId
    name: addressName
    address1
    address2
    address3
    city
    fax
    organizationId
    phone1
    phone1Type
    phone2
    phone2Type
    state
    zip
    rank
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment organization on Organization {
    npi: nPI
    organizationAddresses {
      ...organizationAddress
    }
    organizationId
    organizationName
    taxId
    email
  }
  ${OrganizationAddressFragmentDoc}
`;
export const PatientRequestViewModelFragmentDoc = gql`
  fragment patientRequestViewModel on PatientRequestViewModel {
    actionAlertCount
    authorizationReceived
    dob: dOB
    firstName
    gender
    genderOther
    lastName
    location
    middleName
    missingInfoFields
    nextStep
    patientId
    physicianFirstName
    physicianLastName
    physicianId
    updateAlertCount
  }
`;
export const PatientSearchViewModelFragmentDoc = gql`
  fragment patientSearchViewModel on PatientSearchViewModel {
    dob: dOB
    firstName
    lastName
    middleName
    patientId
  }
`;
export const PatientAddressFragmentDoc = gql`
  fragment patientAddress on PatientAddress {
    address1
    address2
    city
    created
    lastUpdated
    patientAddressId
    state
    zip
  }
`;
export const CopayCardFragmentDoc = gql`
  fragment copayCard on CopayCard {
    bin: bIN
    id: copayCardID
    pcn: pCN
    serviceRequestCopayCardId: sRCopayCardId
    created
    createdBy
    deleted
    drugName
    endDate
    groupID
    isVendorCopayCard
    lastUpdated
    lastUpdatedBy
    patientId
    serviceRequestId
    startDate
  }
`;
export const PatientPhoneNumberFragmentDoc = gql`
  fragment patientPhoneNumber on PatientPhoneNumber {
    created
    lastUpdate
    okToLeaveMsg: oktoLeaveMsg
    patientPhoneNumberId
    phoneNumber
    phoneType
  }
`;
export const PatientContactFragmentDoc = gql`
  fragment patientContact on PatientContact {
    patientId
    contactId
    relationship
    legallyAuthRep
    patientContactType
    contact {
      firstName
      middleName
      lastName
      email
    }
  }
`;
export const DiagnosisListFragmentDoc = gql`
  fragment diagnosisList on PatientDiagnosisViewModel {
    diagnosisListId
    icdcode
    icddescription
    rank
  }
`;
export const PatientFragmentDoc = gql`
  fragment patient on Patient {
    id: patientId
    authorizationReceived
    created
    dob
    email
    firstName
    gender
    genderOther
    lastName
    lastUpdated
    middleName
    preferredLanguage
    preferredLanguageOther
    externalId
    address: patientAddresses {
      ...patientAddress
    }
    copayCard {
      ...copayCard
    }
    phoneNumbers: patientPhoneNumbers {
      ...patientPhoneNumber
    }
    patientContact: patientContact {
      ...patientContact
    }
    patientDiagnosisDetail: diagnosisList {
      ...diagnosisList
    }
  }
  ${PatientAddressFragmentDoc}
  ${CopayCardFragmentDoc}
  ${PatientPhoneNumberFragmentDoc}
  ${PatientContactFragmentDoc}
  ${DiagnosisListFragmentDoc}
`;
export const PatientInsuranceFragmentDoc = gql`
  fragment patientInsurance on PatientInsurance {
    cardholderName
    insuranceType
    isPatientPrimaryCardholder
    medicalCardHolderId
    medicalGroup
    medicalPhoneNumber
    medicalPlanName
    payerName
    pharmacyBINNumber
    pharmacyCardholderID
    pharmacyGroup
    pharmacyPCN
    pharmacyPhoneNumber
    pharmacyPlanName
    subscriberDOB
    subscriberFirstName
    subscriberGender
    subscriberLastName
  }
`;
export const PatientDiagnosisInfoFragmentDoc = gql`
  fragment patientDiagnosisInfo on DiagnosisList {
    id: diagnosisListId
    created
    icdcode
    icdcodeType
    icddescription
    lastUpdated
  }
`;
export const PayerFragmentDoc = gql`
  fragment payer on Payer {
    payerId
    medePayerId
    medPAPayerId
    payerName
    payerCategories
  }
`;
export const PhysicianFragmentDoc = gql`
  fragment physician on Physician {
    id: physicianId
    npi: nPI
    email
    firstName
    lastName
    middleName
    prefix
    providerType
    suffix
    physicianSpecialties {
      physicianId
      lookupDataListValue
      lookupDataListId
      physicianSpecialtiesId
      rank
    }
    details: physiciansDetail {
      id: physiciansDetailId
      created
      lastUpdated
      taxId
      pTAN
      dEA
      hIN
      medicareID
      medicaidID
    }
    stateLicenses: physicianStateLicenses {
      id: physicianStateLicenseId
      stateIssuer: licenseState
      created
      expirationDate
      lastUpdated
      stateLicenseNumber
    }
  }
`;
export const PortalTerritoryFragmentDoc = gql`
  fragment portalTerritory on PortalTerritory {
    id: territoryId
    territoryName
  }
`;
export const SpecialtyPharmacyContactFragmentFragmentDoc = gql`
  fragment specialtyPharmacyContactFragment on SpecialtyPharmacyContact {
    specialtyPharmacyName
    webSite
    faxNumber
    phoneNumber
    specialtyPharmacyType
  }
`;
export const DocumentFragmentFragmentDoc = gql`
  fragment documentFragment on Document {
    id: documentId
    created
    entityId
    entityName
    fileName
    fileStorageId
    formComplete
    lastUpdated
    programId
    signatureRequired
    source
    status
    status
    totalPages
  }
`;
export const ServiceRequestViewFragmentDoc = gql`
  fragment serviceRequestView on ServiceRequestViewModel {
    id: serviceRequestId
    requestStartDate: sRStartDate
    requestStatus: sRStatus
    requestType: sRType
    actionAlert
    authorizationReceived
    copayCardId
    displayOnGrid
    missingInfoFields
    nextStep
    patientDOB
    patientFirstName
    patientId
    patientLastName
    physicianFirstName
    physicianLastName
    shippedDate
    shippedStatus
    sRDrugName
    statusText
    updateAlert
    requestID
    updatedAlertDate
    documents {
      ...documentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
`;
export const OrganizationUserFragmentDoc = gql`
  fragment organizationUser on UserViewModel {
    email
    firstName
    isAdmin
    lastName
    passwordStatus
    status
    userName
    userType
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment userInfo on UserInfo {
    email
    fax
    phone
    rank
    recordType
    zip
  }
`;
export const CaseDetailsDocument = gql`
  query caseDetails($caseRecordId: ID!) {
    result: caseDetails(caseRecordId: $caseRecordId, programId: 290) {
      mileStones {
        mileStoneCode
        mileStoneId
        mileStoneName
        mileStoneType
        sortOrder
      }
      transactions {
        caseClosed
        caseDrugName
        caseFlowStep
        caseIntakeId
        caseMileStoneCode
        caseRecordId
        caseRecordTransactionId
        caseSource
        caseStartTrigger
        caseTransactionId
        caseTransactionState
        caseTransactionStatus
        caseTransactionType
        mileStoneCreatedDate
        caseDrugDescription
      }
      prescriptionIntakes {
        prescriptionId
        prescriptionReceivedDate
        drugName
        ndc
        doseType
        quantity
        daysSupply
      }
      benefitInvestigations {
        insurancePlanName
        insurancePlanType
        bIType
        bICreatedDate
        productCovered
        eBVTransactionId
        priorAuthRequired
        therapyOrDrug
        insuranceRepName
        dateYearPlan
        planEffectiveDate
        planEndDate
        coInsurancePercent
        deductible
        deductibleAmountMet
        outOfPocketMax
        oopAmountMet
        additionalComments
      }
      priorAuthorizations {
        insurancePlanName
        insurancePlanType
        pAType
        pACreatedDate
        pAStatus
        pAExpiresOn
        ePATransactionID
        ePAStatus
        authorizationNumber
        pASubmissionType
        referenceKey
        providerPAWebsite
        pASubmissionDate
        pAApprovalDate
        pAExpirationDate
        pADenialDate
        denialReason
        otherDenialReason
        pAOnFile
        daysToCompletePAReview
        stepTherapyRequirements
        pARequirements
        otherPARequirements
        additionalComments
        therapyOrDrug
      }
      triagePrescriptions {
        sentToPharmacy
        dateSentToPharmacy
        status
      }
      patientEnrollments {
        caseTransactionId
        caseTransactionStatus
        created
        enrollmentId
        patientEnrollmentGuid
      }
      appeals {
        insurancePlanName
        insurancePlanType
        appealType
        therapyOrDrug
        appealRequirements
        appealInitiated
        appealCompletedDate
        appealSubmitters
        appealMethods
        appealContactName
        appealContactAddress
        appealContactPhoneNumber
        appealContactFaxNumber
        appealOnlineSubmissionUrl
        appealResponseTurnaroundTime
        appealStatus
        appealDenialReasons
        appealDenialReasonOther
        appealDenialDate
        appealCreatedDate
        comments
      }
    }
  }
`;

/**
 * __useCaseDetailsQuery__
 *
 * To run a query within a React component, call `useCaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseDetailsQuery({
 *   variables: {
 *      caseRecordId: // value for 'caseRecordId'
 *   },
 * });
 */
export function useCaseDetailsQuery(baseOptions: Apollo.QueryHookOptions<CaseDetailsQuery, CaseDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseDetailsQuery, CaseDetailsQueryVariables>(CaseDetailsDocument, options);
}
export function useCaseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseDetailsQuery, CaseDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseDetailsQuery, CaseDetailsQueryVariables>(CaseDetailsDocument, options);
}
export type CaseDetailsQueryHookResult = ReturnType<typeof useCaseDetailsQuery>;
export type CaseDetailsLazyQueryHookResult = ReturnType<typeof useCaseDetailsLazyQuery>;
export type CaseDetailsQueryResult = Apollo.QueryResult<CaseDetailsQuery, CaseDetailsQueryVariables>;
export const CaseRecordDocumentsDocument = gql`
  query caseRecordDocuments($caseRecordId: ID!) {
    caseRecordDocuments(programId: 290, caseRecordId: $caseRecordId) {
      caseRecordId
      caseTransactionId
      created
      deleted
      documentId
      entityId
      entityName
      fileName
      fileStorageId
      formComplete
      formComplete
      lastUpdated
      signatureRequired
      source
      documentType
      drugName
    }
  }
`;

/**
 * __useCaseRecordDocumentsQuery__
 *
 * To run a query within a React component, call `useCaseRecordDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseRecordDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseRecordDocumentsQuery({
 *   variables: {
 *      caseRecordId: // value for 'caseRecordId'
 *   },
 * });
 */
export function useCaseRecordDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<CaseRecordDocumentsQuery, CaseRecordDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseRecordDocumentsQuery, CaseRecordDocumentsQueryVariables>(
    CaseRecordDocumentsDocument,
    options
  );
}
export function useCaseRecordDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseRecordDocumentsQuery, CaseRecordDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseRecordDocumentsQuery, CaseRecordDocumentsQueryVariables>(
    CaseRecordDocumentsDocument,
    options
  );
}
export type CaseRecordDocumentsQueryHookResult = ReturnType<typeof useCaseRecordDocumentsQuery>;
export type CaseRecordDocumentsLazyQueryHookResult = ReturnType<typeof useCaseRecordDocumentsLazyQuery>;
export type CaseRecordDocumentsQueryResult = Apollo.QueryResult<
  CaseRecordDocumentsQuery,
  CaseRecordDocumentsQueryVariables
>;
export const CaseRecordsDocument = gql`
  query caseRecords($programId: ID!, $organizationId: ID!, $patientId: ID, $userName: String!) {
    result: caseRecords(
      programId: $programId
      organizationId: $organizationId
      patientId: $patientId
      userName: $userName
      onlyOpenCases: false
    ) {
      caseRecordId
      caseStatus
      caseSubStatus
      caseClosed
      caseDrugName
      caseMileStone
      caseReceivedDate
      hasUnReadMessage
      isUpdatedRecently
      needAction
      patientId
      patientFirstName
      patientLastName
      providerFirstName
      providerLastName
      providerNpi
      lastUpdatedDate
      caseDrugDescription
    }
  }
`;

/**
 * __useCaseRecordsQuery__
 *
 * To run a query within a React component, call `useCaseRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseRecordsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      organizationId: // value for 'organizationId'
 *      patientId: // value for 'patientId'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useCaseRecordsQuery(baseOptions: Apollo.QueryHookOptions<CaseRecordsQuery, CaseRecordsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseRecordsQuery, CaseRecordsQueryVariables>(CaseRecordsDocument, options);
}
export function useCaseRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseRecordsQuery, CaseRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseRecordsQuery, CaseRecordsQueryVariables>(CaseRecordsDocument, options);
}
export type CaseRecordsQueryHookResult = ReturnType<typeof useCaseRecordsQuery>;
export type CaseRecordsLazyQueryHookResult = ReturnType<typeof useCaseRecordsLazyQuery>;
export type CaseRecordsQueryResult = Apollo.QueryResult<CaseRecordsQuery, CaseRecordsQueryVariables>;
export const PhoneNumberDocument = gql`
  query phoneNumber($recordId: ID!, $recordType: String!) {
    result: phoneNumberInfo(recordId: $recordId, recordType: $recordType) {
      number
      rank
      recordType
    }
  }
`;

/**
 * __usePhoneNumberQuery__
 *
 * To run a query within a React component, call `usePhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberQuery({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      recordType: // value for 'recordType'
 *   },
 * });
 */
export function usePhoneNumberQuery(baseOptions: Apollo.QueryHookOptions<PhoneNumberQuery, PhoneNumberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhoneNumberQuery, PhoneNumberQueryVariables>(PhoneNumberDocument, options);
}
export function usePhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberQuery, PhoneNumberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhoneNumberQuery, PhoneNumberQueryVariables>(PhoneNumberDocument, options);
}
export type PhoneNumberQueryHookResult = ReturnType<typeof usePhoneNumberQuery>;
export type PhoneNumberLazyQueryHookResult = ReturnType<typeof usePhoneNumberLazyQuery>;
export type PhoneNumberQueryResult = Apollo.QueryResult<PhoneNumberQuery, PhoneNumberQueryVariables>;
export const GetPatientDispensesQueryDocument = gql`
  query getPatientDispensesQuery($programId: ID!, $patientId: ID!) {
    result: dispensesByPatient(programId: $programId, patientId: $patientId) {
      shippedDate
      pharmacyName
      productName
      dispenseType
      quantity
      dispenseType
      fillNumber
      refillRemaining
      shippingCarrier
      trackingNumber
      physicianFirstName
      physicianLastName
      physicianNpi
    }
  }
`;

/**
 * __useGetPatientDispensesQueryQuery__
 *
 * To run a query within a React component, call `useGetPatientDispensesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDispensesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDispensesQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDispensesQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientDispensesQueryQuery, GetPatientDispensesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientDispensesQueryQuery, GetPatientDispensesQueryQueryVariables>(
    GetPatientDispensesQueryDocument,
    options
  );
}
export function useGetPatientDispensesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDispensesQueryQuery, GetPatientDispensesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientDispensesQueryQuery, GetPatientDispensesQueryQueryVariables>(
    GetPatientDispensesQueryDocument,
    options
  );
}
export type GetPatientDispensesQueryQueryHookResult = ReturnType<typeof useGetPatientDispensesQueryQuery>;
export type GetPatientDispensesQueryLazyQueryHookResult = ReturnType<typeof useGetPatientDispensesQueryLazyQuery>;
export type GetPatientDispensesQueryQueryResult = Apollo.QueryResult<
  GetPatientDispensesQueryQuery,
  GetPatientDispensesQueryQueryVariables
>;
export const DrugDetailsByClientIdDocument = gql`
  query DrugDetailsByClientId($clientId: ID!) {
    result: drugDetailsByClientId(clientId: $clientId) {
      drugBrandName
      drugId
      drugGenericName
      drugLabelName
      jCode1
      jCode2
      drugDetails {
        drugNdc
        drugDescription
        daysSupply
        quantity
      }
      drugCptCode {
        cptCode
      }
    }
  }
`;

/**
 * __useDrugDetailsByClientIdQuery__
 *
 * To run a query within a React component, call `useDrugDetailsByClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrugDetailsByClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrugDetailsByClientIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useDrugDetailsByClientIdQuery(
  baseOptions: Apollo.QueryHookOptions<DrugDetailsByClientIdQuery, DrugDetailsByClientIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DrugDetailsByClientIdQuery, DrugDetailsByClientIdQueryVariables>(
    DrugDetailsByClientIdDocument,
    options
  );
}
export function useDrugDetailsByClientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DrugDetailsByClientIdQuery, DrugDetailsByClientIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DrugDetailsByClientIdQuery, DrugDetailsByClientIdQueryVariables>(
    DrugDetailsByClientIdDocument,
    options
  );
}
export type DrugDetailsByClientIdQueryHookResult = ReturnType<typeof useDrugDetailsByClientIdQuery>;
export type DrugDetailsByClientIdLazyQueryHookResult = ReturnType<typeof useDrugDetailsByClientIdLazyQuery>;
export type DrugDetailsByClientIdQueryResult = Apollo.QueryResult<
  DrugDetailsByClientIdQuery,
  DrugDetailsByClientIdQueryVariables
>;
export const OrganizationNpiDocument = gql`
  query organizationNpi($npi: String!) {
    result: nPIDataForOrganization(npi: $npi) {
      npi: nPI
      address1
      address2
      city
      faxNumber
      organizationName
      phoneNumber
      state
      taxId
      zipCode
    }
  }
`;

/**
 * __useOrganizationNpiQuery__
 *
 * To run a query within a React component, call `useOrganizationNpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNpiQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useOrganizationNpiQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationNpiQuery, OrganizationNpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationNpiQuery, OrganizationNpiQueryVariables>(OrganizationNpiDocument, options);
}
export function useOrganizationNpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationNpiQuery, OrganizationNpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationNpiQuery, OrganizationNpiQueryVariables>(OrganizationNpiDocument, options);
}
export type OrganizationNpiQueryHookResult = ReturnType<typeof useOrganizationNpiQuery>;
export type OrganizationNpiLazyQueryHookResult = ReturnType<typeof useOrganizationNpiLazyQuery>;
export type OrganizationNpiQueryResult = Apollo.QueryResult<OrganizationNpiQuery, OrganizationNpiQueryVariables>;
export const ProviderNpiDocument = gql`
  query providerNpi($npi: String!) {
    result: nPIDataForProvider(npi: $npi) {
      npi: nPI
      firstName
      lastName
      middleName
      taxId
      stateLicenses {
        licenseNumber
        licenseNumberIndex
        stateCode
      }
    }
  }
`;

/**
 * __useProviderNpiQuery__
 *
 * To run a query within a React component, call `useProviderNpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderNpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderNpiQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useProviderNpiQuery(baseOptions: Apollo.QueryHookOptions<ProviderNpiQuery, ProviderNpiQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderNpiQuery, ProviderNpiQueryVariables>(ProviderNpiDocument, options);
}
export function useProviderNpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderNpiQuery, ProviderNpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderNpiQuery, ProviderNpiQueryVariables>(ProviderNpiDocument, options);
}
export type ProviderNpiQueryHookResult = ReturnType<typeof useProviderNpiQuery>;
export type ProviderNpiLazyQueryHookResult = ReturnType<typeof useProviderNpiLazyQuery>;
export type ProviderNpiQueryResult = Apollo.QueryResult<ProviderNpiQuery, ProviderNpiQueryVariables>;
export const OrganizationAddressInfoDocument = gql`
  query organizationAddressInfo($organizationId: ID!) {
    result: organizationAddressesInfo(organizationId: $organizationId) {
      id: organizationAddressId
      name: addressName
      address1
      address2
      address3
      city
      fax
      organizationId
      phone1
      phone1Type
      phone2
      phone2Type
      state
      zip
    }
  }
`;

/**
 * __useOrganizationAddressInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationAddressInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAddressInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAddressInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationAddressInfoQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationAddressInfoQuery, OrganizationAddressInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationAddressInfoQuery, OrganizationAddressInfoQueryVariables>(
    OrganizationAddressInfoDocument,
    options
  );
}
export function useOrganizationAddressInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAddressInfoQuery, OrganizationAddressInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationAddressInfoQuery, OrganizationAddressInfoQueryVariables>(
    OrganizationAddressInfoDocument,
    options
  );
}
export type OrganizationAddressInfoQueryHookResult = ReturnType<typeof useOrganizationAddressInfoQuery>;
export type OrganizationAddressInfoLazyQueryHookResult = ReturnType<typeof useOrganizationAddressInfoLazyQuery>;
export type OrganizationAddressInfoQueryResult = Apollo.QueryResult<
  OrganizationAddressInfoQuery,
  OrganizationAddressInfoQueryVariables
>;
export const OrganizationUsersDocument = gql`
  query organizationUsers($organizationId: ID!) {
    result: organizationUsers(organizationId: $organizationId) {
      email
      firstName
      isAdmin
      lastName
      passwordStatus
      status
      userName
      userType
    }
  }
`;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationUsersQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
}
export function useOrganizationUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(
    OrganizationUsersDocument,
    options
  );
}
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = Apollo.QueryResult<OrganizationUsersQuery, OrganizationUsersQueryVariables>;
export const UserInfoDocument = gql`
  query userInfo($recordId: ID!, $recordType: String!) {
    result: userInfo(recordId: $recordId, recordType: $recordType) {
      email
      fax
      phone
      rank
      recordType
      zip
    }
  }
`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      recordType: // value for 'recordType'
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
}
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const OrganizationDetailsDocument = gql`
  query organizationDetails($organizationId: ID!) {
    result: organizationDetails(organizationId: $organizationId) {
      npi: nPI
      organizationAddresses {
        id: organizationAddressId
        name: addressName
        address1
        address2
        address3
        city
        fax
        organizationId
        phone1
        phone1Type
        phone2
        phone2Type
        state
        zip
        rank
      }
      organizationId
      organizationName
      taxId
      email
    }
  }
`;

/**
 * __useOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationDetailsQuery, OrganizationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationDetailsQuery, OrganizationDetailsQueryVariables>(
    OrganizationDetailsDocument,
    options
  );
}
export function useOrganizationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationDetailsQuery, OrganizationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationDetailsQuery, OrganizationDetailsQueryVariables>(
    OrganizationDetailsDocument,
    options
  );
}
export type OrganizationDetailsQueryHookResult = ReturnType<typeof useOrganizationDetailsQuery>;
export type OrganizationDetailsLazyQueryHookResult = ReturnType<typeof useOrganizationDetailsLazyQuery>;
export type OrganizationDetailsQueryResult = Apollo.QueryResult<
  OrganizationDetailsQuery,
  OrganizationDetailsQueryVariables
>;
export const GetPatientDetailByIdDocument = gql`
  query getPatientDetailById($organization: Int!, $patientId: Int!) {
    patientDetailByPatientID(organizationId: $organization, patientId: $patientId) {
      organizationAddress {
        organizationAddressId
        organizationId
        addressName
        address1
        address2
        city
        state
        phone1
        phone1Type
        phone2
        phone2Type
        fax
      }
      physician {
        physicianId
        npi: nPI
        email
        firstName
        lastName
        middleName
        prefix
        suffix
        physicianSpecialties {
          physicianId
          lookupDataListValue
          lookupDataListId
          physicianSpecialtiesId
          rank
        }
        details: physiciansDetail {
          id: physiciansDetailId
          created
          lastUpdated
          taxId
          pTAN
          dEA
          medicareID
          medicaidID
        }
        stateLicenses: physicianStateLicenses {
          id: physicianStateLicenseId
          stateIssuer: licenseState
          created
          expirationDate
          lastUpdated
          stateLicenseNumber
        }
      }
    }
  }
`;

/**
 * __useGetPatientDetailByIdQuery__
 *
 * To run a query within a React component, call `useGetPatientDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDetailByIdQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDetailByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientDetailByIdQuery, GetPatientDetailByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientDetailByIdQuery, GetPatientDetailByIdQueryVariables>(
    GetPatientDetailByIdDocument,
    options
  );
}
export function useGetPatientDetailByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDetailByIdQuery, GetPatientDetailByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientDetailByIdQuery, GetPatientDetailByIdQueryVariables>(
    GetPatientDetailByIdDocument,
    options
  );
}
export type GetPatientDetailByIdQueryHookResult = ReturnType<typeof useGetPatientDetailByIdQuery>;
export type GetPatientDetailByIdLazyQueryHookResult = ReturnType<typeof useGetPatientDetailByIdLazyQuery>;
export type GetPatientDetailByIdQueryResult = Apollo.QueryResult<
  GetPatientDetailByIdQuery,
  GetPatientDetailByIdQueryVariables
>;
export const PatientInfoDocument = gql`
  query patientInfo($patientId: ID!) {
    result: patientInfo(patientId: $patientId) {
      id: patientId
      authorizationReceived
      created
      dob
      email
      firstName
      gender
      genderOther
      lastName
      lastUpdated
      middleName
      preferredLanguage
      preferredLanguageOther
      externalId
      physicians {
        physicianId
        firstName
        lastName
      }
      address: patientAddresses {
        address1
        address2
        city
        created
        lastUpdated
        patientAddressId
        state
        zip
      }
      copayCard {
        bin: bIN
        id: copayCardID
        pcn: pCN
        serviceRequestCopayCardId: sRCopayCardId
        created
        createdBy
        deleted
        drugName
        endDate
        groupID
        isVendorCopayCard
        lastUpdated
        lastUpdatedBy
        patientId
        serviceRequestId
        startDate
      }
      phoneNumbers: patientPhoneNumbers {
        created
        lastUpdate
        okToLeaveMsg: oktoLeaveMsg
        patientPhoneNumberId
        phoneNumber
        phoneType
      }
      patientContact {
        patientId
        contactId
        relationship
        legallyAuthRep
        patientContactType
        contact {
          firstName
          middleName
          lastName
          email
          contactPhoneNumbers {
            phoneNumber
            phoneType
          }
        }
      }
      patientDiagnosisDetail: diagnosisList {
        diagnosisListId
        icdcode
        icddescription
        rank
      }
    }
  }
`;

/**
 * __usePatientInfoQuery__
 *
 * To run a query within a React component, call `usePatientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInfoQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientInfoQuery(baseOptions: Apollo.QueryHookOptions<PatientInfoQuery, PatientInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientInfoQuery, PatientInfoQueryVariables>(PatientInfoDocument, options);
}
export function usePatientInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientInfoQuery, PatientInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientInfoQuery, PatientInfoQueryVariables>(PatientInfoDocument, options);
}
export type PatientInfoQueryHookResult = ReturnType<typeof usePatientInfoQuery>;
export type PatientInfoLazyQueryHookResult = ReturnType<typeof usePatientInfoLazyQuery>;
export type PatientInfoQueryResult = Apollo.QueryResult<PatientInfoQuery, PatientInfoQueryVariables>;
export const PatientRequestInfoDocument = gql`
  query patientRequestInfo($organizationId: ID!, $programId: ID!) {
    result: patientRequestInfo(organizationId: $organizationId, programId: $programId) {
      actionAlertCount
      authorizationReceived
      dob: dOB
      firstName
      gender
      genderOther
      lastName
      location
      middleName
      nextStep
      patientId
      physicianFirstName
      physicianLastName
      physicianId
      updateAlertCount
      statusText
      rxAdminDate
      rxAdminDose
      rxAdminStatus
      rxAdminAlert
    }
  }
`;

/**
 * __usePatientRequestInfoQuery__
 *
 * To run a query within a React component, call `usePatientRequestInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRequestInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRequestInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function usePatientRequestInfoQuery(
  baseOptions: Apollo.QueryHookOptions<PatientRequestInfoQuery, PatientRequestInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientRequestInfoQuery, PatientRequestInfoQueryVariables>(
    PatientRequestInfoDocument,
    options
  );
}
export function usePatientRequestInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientRequestInfoQuery, PatientRequestInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientRequestInfoQuery, PatientRequestInfoQueryVariables>(
    PatientRequestInfoDocument,
    options
  );
}

export type PatientRequestInfoQueryHookResult = ReturnType<typeof usePatientRequestInfoQuery>;
export type PatientRequestInfoLazyQueryHookResult = ReturnType<typeof usePatientRequestInfoLazyQuery>;
export type PatientRequestInfoQueryResult = Apollo.QueryResult<
  PatientRequestInfoQuery,
  PatientRequestInfoQueryVariables
>;

/**
 * __usePatientSearchInfoQuery__
 *
 * To run a query within a React component, call `usePatientSearchInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSearchInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSearchInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export const PatientSearchInfoDocument = gql`
  query patientSearchInfo($organizationId: ID!, $programId: ID!) {
    result: patientSearchInfo(organizationId: $organizationId, programId: $programId) {
      dob: dOB
      firstName
      lastName
      middleName
      patientId
    }
  }
`;
export function usePatientSearchInfoQuery(
  baseOptions: Apollo.QueryHookOptions<PatientSearchInfoQuery, PatientSearchInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientSearchInfoQuery, PatientSearchInfoQueryVariables>(PatientSearchInfoDocument, options);
}
export function usePatientSearchInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientSearchInfoQuery, PatientSearchInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientSearchInfoQuery, PatientSearchInfoQueryVariables>(
    PatientSearchInfoDocument,
    options
  );
}

export type PatientSearchInfoQueryHookResult = ReturnType<typeof usePatientSearchInfoQuery>;
export type PatientSearchInfoLazyQueryHookResult = ReturnType<typeof usePatientSearchInfoLazyQuery>;
export type PatientSearchInfoQueryResult = Apollo.QueryResult<PatientSearchInfoQuery, PatientSearchInfoQueryVariables>;

export const PatientInsuranceDocument = gql`
  query patientInsurance($patientId: ID!) {
    result: patientInsurancesInfo(patientId: $patientId) {
      cardholderName
      insuranceType
      isPatientPrimaryCardholder
      medicalCardHolderId
      medicalGroup
      medicalPhoneNumber
      medicalPlanName
      payerName
      pharmacyBINNumber
      pharmacyCardholderID
      pharmacyGroup
      pharmacyPCN
      pharmacyPhoneNumber
      pharmacyPlanName
      subscriberDOB
      subscriberFirstName
      subscriberGender
      subscriberLastName
      payerId
      medePayerId
      medPAPayerId
      parentPayerId
      payerType
      insuranceName
      planType
      phoneNumber
      employerName
      relationship
      insuranceId
    }
  }
`;

/**
 * __usePatientInsuranceQuery__
 *
 * To run a query within a React component, call `usePatientInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInsuranceQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<PatientInsuranceQuery, PatientInsuranceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientInsuranceQuery, PatientInsuranceQueryVariables>(PatientInsuranceDocument, options);
}
export function usePatientInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientInsuranceQuery, PatientInsuranceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientInsuranceQuery, PatientInsuranceQueryVariables>(PatientInsuranceDocument, options);
}
export type PatientInsuranceQueryHookResult = ReturnType<typeof usePatientInsuranceQuery>;
export type PatientInsuranceLazyQueryHookResult = ReturnType<typeof usePatientInsuranceLazyQuery>;
export type PatientInsuranceQueryResult = Apollo.QueryResult<PatientInsuranceQuery, PatientInsuranceQueryVariables>;
export const PatientDiagnosisDocument = gql`
  query patientDiagnosis($iCDCode: ID!) {
    result: patientDiagnosisInfo(iCDCode: $iCDCode) {
      id: diagnosisListId
      created
      icdcode
      icdcodeType
      icddescription
      lastUpdated
    }
  }
`;

/**
 * __usePatientDiagnosisQuery__
 *
 * To run a query within a React component, call `usePatientDiagnosisQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDiagnosisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDiagnosisQuery({
 *   variables: {
 *      iCDCode: // value for 'iCDCode'
 *   },
 * });
 */
export function usePatientDiagnosisQuery(
  baseOptions: Apollo.QueryHookOptions<PatientDiagnosisQuery, PatientDiagnosisQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientDiagnosisQuery, PatientDiagnosisQueryVariables>(PatientDiagnosisDocument, options);
}
export function usePatientDiagnosisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientDiagnosisQuery, PatientDiagnosisQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientDiagnosisQuery, PatientDiagnosisQueryVariables>(PatientDiagnosisDocument, options);
}
export type PatientDiagnosisQueryHookResult = ReturnType<typeof usePatientDiagnosisQuery>;
export type PatientDiagnosisLazyQueryHookResult = ReturnType<typeof usePatientDiagnosisLazyQuery>;
export type PatientDiagnosisQueryResult = Apollo.QueryResult<PatientDiagnosisQuery, PatientDiagnosisQueryVariables>;
export const PatientDiagnosisByProgramDocument = gql`
  query patientDiagnosisByProgram($iCDCode: String!, $programId: Int!, $drugId: Int!) {
    result: patientDiagnosisByProgramInfo(iCDCode: $iCDCode, programId: $programId, drugId: $drugId) {
      id: diagnosisListId
      icdcode
      icdcodeType
      icddescription
    }
  }
`;

/**
 * __usePatientDiagnosisByProgramQuery__
 *
 * To run a query within a React component, call `usePatientDiagnosisByProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDiagnosisByProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDiagnosisByProgramQuery({
 *   variables: {
 *      iCDCode: // value for 'iCDCode'
 *      programId: // value for 'programId'
 *      drugId: // value for 'drugId'
 *   },
 * });
 */
export function usePatientDiagnosisByProgramQuery(
  baseOptions: Apollo.QueryHookOptions<PatientDiagnosisByProgramQuery, PatientDiagnosisByProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientDiagnosisByProgramQuery, PatientDiagnosisByProgramQueryVariables>(
    PatientDiagnosisByProgramDocument,
    options
  );
}
export function usePatientDiagnosisByProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientDiagnosisByProgramQuery, PatientDiagnosisByProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientDiagnosisByProgramQuery, PatientDiagnosisByProgramQueryVariables>(
    PatientDiagnosisByProgramDocument,
    options
  );
}
export type PatientDiagnosisByProgramQueryHookResult = ReturnType<typeof usePatientDiagnosisByProgramQuery>;
export type PatientDiagnosisByProgramLazyQueryHookResult = ReturnType<typeof usePatientDiagnosisByProgramLazyQuery>;
export type PatientDiagnosisByProgramQueryResult = Apollo.QueryResult<
  PatientDiagnosisByProgramQuery,
  PatientDiagnosisByProgramQueryVariables
>;
export const PatientStatusesQueryDocument = gql`
  query PatientStatusesQuery($programId: Int!) {
    patientStatuses(programId: $programId) {
      statusDescription
    }
  }
`;

/**
 * __usePatientStatusesQueryQuery__
 *
 * To run a query within a React component, call `usePatientStatusesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientStatusesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientStatusesQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function usePatientStatusesQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PatientStatusesQueryQuery, PatientStatusesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientStatusesQueryQuery, PatientStatusesQueryQueryVariables>(
    PatientStatusesQueryDocument,
    options
  );
}
export function usePatientStatusesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientStatusesQueryQuery, PatientStatusesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientStatusesQueryQuery, PatientStatusesQueryQueryVariables>(
    PatientStatusesQueryDocument,
    options
  );
}
export type PatientStatusesQueryQueryHookResult = ReturnType<typeof usePatientStatusesQueryQuery>;
export type PatientStatusesQueryLazyQueryHookResult = ReturnType<typeof usePatientStatusesQueryLazyQuery>;
export type PatientStatusesQueryQueryResult = Apollo.QueryResult<
  PatientStatusesQueryQuery,
  PatientStatusesQueryQueryVariables
>;
export const DrugCptCodeByProgramDocument = gql`
  query drugCPTCodeByProgram($programId: Int!, $drugId: Int) {
    result: drugCptCodeByProgramInfo(programId: $programId, drugId: $drugId) {
      drugCptCodeId
      cptCode
      cptCodeDescription
    }
  }
`;

/**
 * __useDrugCptCodeByProgramQuery__
 *
 * To run a query within a React component, call `useDrugCptCodeByProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrugCptCodeByProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrugCptCodeByProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      drugId: // value for 'drugId'
 *   },
 * });
 */
export function useDrugCptCodeByProgramQuery(
  baseOptions: Apollo.QueryHookOptions<DrugCptCodeByProgramQuery, DrugCptCodeByProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DrugCptCodeByProgramQuery, DrugCptCodeByProgramQueryVariables>(
    DrugCptCodeByProgramDocument,
    options
  );
}
export function useDrugCptCodeByProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DrugCptCodeByProgramQuery, DrugCptCodeByProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DrugCptCodeByProgramQuery, DrugCptCodeByProgramQueryVariables>(
    DrugCptCodeByProgramDocument,
    options
  );
}
export type DrugCptCodeByProgramQueryHookResult = ReturnType<typeof useDrugCptCodeByProgramQuery>;
export type DrugCptCodeByProgramLazyQueryHookResult = ReturnType<typeof useDrugCptCodeByProgramLazyQuery>;
export type DrugCptCodeByProgramQueryResult = Apollo.QueryResult<
  DrugCptCodeByProgramQuery,
  DrugCptCodeByProgramQueryVariables
>;
export const PayersDocument = gql`
  query payers($categories: String!, $payerName: String!) {
    result: payers(categories: $categories, payerName: $payerName) {
      payerId
      medePayerId
      medPAPayerId
      payerName
      payerCategories
    }
  }
`;

/**
 * __usePayersQuery__
 *
 * To run a query within a React component, call `usePayersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayersQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      payerName: // value for 'payerName'
 *   },
 * });
 */
export function usePayersQuery(baseOptions: Apollo.QueryHookOptions<PayersQuery, PayersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
}
export function usePayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayersQuery, PayersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
}
export type PayersQueryHookResult = ReturnType<typeof usePayersQuery>;
export type PayersLazyQueryHookResult = ReturnType<typeof usePayersLazyQuery>;
export type PayersQueryResult = Apollo.QueryResult<PayersQuery, PayersQueryVariables>;
export const PhysiciansInfoDocument = gql`
  query physiciansInfo($organizationId: ID!) {
    result: physiciansInfo(organizationId: $organizationId) {
      id: physicianId
      npi: nPI
      email
      firstName
      lastName
      middleName
      prefix
      suffix
      physicianSpecialties {
        physicianId
        lookupDataListValue
        lookupDataListId
        physicianSpecialtiesId
        rank
      }
      details: physiciansDetail {
        id: physiciansDetailId
        created
        lastUpdated
        taxId
        pTAN
        dEA
        medicareID
        medicaidID
      }
      stateLicenses: physicianStateLicenses {
        id: physicianStateLicenseId
        stateIssuer: licenseState
        created
        expirationDate
        lastUpdated
        stateLicenseNumber
      }
    }
  }
`;

/**
 * __usePhysiciansInfoQuery__
 *
 * To run a query within a React component, call `usePhysiciansInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhysiciansInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhysiciansInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function usePhysiciansInfoQuery(
  baseOptions: Apollo.QueryHookOptions<PhysiciansInfoQuery, PhysiciansInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhysiciansInfoQuery, PhysiciansInfoQueryVariables>(PhysiciansInfoDocument, options);
}
export function usePhysiciansInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhysiciansInfoQuery, PhysiciansInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhysiciansInfoQuery, PhysiciansInfoQueryVariables>(PhysiciansInfoDocument, options);
}
export type PhysiciansInfoQueryHookResult = ReturnType<typeof usePhysiciansInfoQuery>;
export type PhysiciansInfoLazyQueryHookResult = ReturnType<typeof usePhysiciansInfoLazyQuery>;
export type PhysiciansInfoQueryResult = Apollo.QueryResult<PhysiciansInfoQuery, PhysiciansInfoQueryVariables>;
export const PhysicianInfoDocument = gql`
  query physicianInfo($npi: String!, $programId: Int!) {
    result: physicianInfo(npi: $npi, programId: $programId) {
      id: physicianId
      npi: nPI
      email
      firstName
      lastName
      middleName
      prefix
      providerType
      suffix
      physicianSpecialties {
        physicianId
        lookupDataListValue
        lookupDataListId
        physicianSpecialtiesId
        rank
      }
      details: physiciansDetail {
        id: physiciansDetailId
        created
        lastUpdated
        taxId
        pTAN
        dEA
        hIN
        medicareID
        medicaidID
      }
      stateLicenses: physicianStateLicenses {
        id: physicianStateLicenseId
        stateIssuer: licenseState
        created
        expirationDate
        lastUpdated
        stateLicenseNumber
      }
    }
  }
`;

/**
 * __usePhysicianInfoQuery__
 *
 * To run a query within a React component, call `usePhysicianInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhysicianInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhysicianInfoQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function usePhysicianInfoQuery(
  baseOptions: Apollo.QueryHookOptions<PhysicianInfoQuery, PhysicianInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhysicianInfoQuery, PhysicianInfoQueryVariables>(PhysicianInfoDocument, options);
}
export function usePhysicianInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhysicianInfoQuery, PhysicianInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhysicianInfoQuery, PhysicianInfoQueryVariables>(PhysicianInfoDocument, options);
}
export type PhysicianInfoQueryHookResult = ReturnType<typeof usePhysicianInfoQuery>;
export type PhysicianInfoLazyQueryHookResult = ReturnType<typeof usePhysicianInfoLazyQuery>;
export type PhysicianInfoQueryResult = Apollo.QueryResult<PhysicianInfoQuery, PhysicianInfoQueryVariables>;
export const TerritoryDetailsDocument = gql`
  query territoryDetails($organizationId: ID!, $programId: ID!) {
    result: territoryDetails(organizationId: $organizationId, programId: $programId) {
      id: territoryId
      territoryName
    }
  }
`;

/**
 * __useTerritoryDetailsQuery__
 *
 * To run a query within a React component, call `useTerritoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerritoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerritoryDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useTerritoryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<TerritoryDetailsQuery, TerritoryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TerritoryDetailsQuery, TerritoryDetailsQueryVariables>(TerritoryDetailsDocument, options);
}
export function useTerritoryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TerritoryDetailsQuery, TerritoryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TerritoryDetailsQuery, TerritoryDetailsQueryVariables>(TerritoryDetailsDocument, options);
}
export type TerritoryDetailsQueryHookResult = ReturnType<typeof useTerritoryDetailsQuery>;
export type TerritoryDetailsLazyQueryHookResult = ReturnType<typeof useTerritoryDetailsLazyQuery>;
export type TerritoryDetailsQueryResult = Apollo.QueryResult<TerritoryDetailsQuery, TerritoryDetailsQueryVariables>;
export const PrescriptionByIdDocument = gql`
  query prescriptionById($prescriptionId: ID!) {
    prescriptionById(prescriptionId: $prescriptionId) {
      prescriptionId
      externalPrescriptionId
      patientFirstName
      patientLastName
      patientMiddleName
      patientSuffix
      patientDob
      patientGender
      patientAddressLine1
      patientAddressLine2
      patientCity
      patientState
      patientZipCode
      patientPhoneNumber1
      patientPhoneNumber2
      payerName
      bin
      pcn
      groupId
      cardholderId
      personCode
      helpDeskPhone
      medicareFlag
      benefitPlanId
      effectiveDate
      terminationDate
      licslevel
      formularyId
      futureEffectiveDate
      futureTerminationDate
      licseffectDate
      licstermDate
      licsplan
      createdOn
      createdBy
      carrierName
      dateWritten
      drugName
      sig
      ndc
      quantity
      unitofMeasure
      refillQualifier
      refills
      daw
      daysSupply
      lastFillDate
      rxEffectiveDate
      diagnoses
      note
      priorAuthStatus
      priorAuthQual
      priorAuth
      deleted
      isCreatedByFax
      lastUpdated
      lastUpdatedBy
      drugBasis
      doseType
      isPrescriptionClosed
      prescriptionSource
      applicationSource
      rxReferenceId
      refillsRemaining
      substitutionPermitted
      prescriberOrderNumber
      prescriptionPharmacy {
        prescriptionPharmacyId
        prescriptionId
        storeName
        ncpdp
        npi
        addressline1
        addressline2
        city
        state
        zip
        phone
        fax
        createdOn
        createdBy
        lastUpdated
        lastUpdatedBy
      }
      prescriptionProviders {
        prescriptionProviderId
        prescriptionId
        firstName
        lastName
        middleName
        suffix
        clinicName
        addressLine1
        addressLine2
        city
        state
        zip
        phone1
        phone2
        fax
        email
        dea
        npi
        isSupervisingPrescriberinfo
        createdOn
        createdBy
        lastUpdated
        lastUpdatedBy
        medicareID
        medicaidID
        stateLicenseNumber
      }
    }
  }
`;

/**
 * __usePrescriptionByIdQuery__
 *
 * To run a query within a React component, call `usePrescriptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionByIdQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function usePrescriptionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<PrescriptionByIdQuery, PrescriptionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrescriptionByIdQuery, PrescriptionByIdQueryVariables>(PrescriptionByIdDocument, options);
}
export function usePrescriptionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionByIdQuery, PrescriptionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrescriptionByIdQuery, PrescriptionByIdQueryVariables>(PrescriptionByIdDocument, options);
}
export type PrescriptionByIdQueryHookResult = ReturnType<typeof usePrescriptionByIdQuery>;
export type PrescriptionByIdLazyQueryHookResult = ReturnType<typeof usePrescriptionByIdLazyQuery>;
export type PrescriptionByIdQueryResult = Apollo.QueryResult<PrescriptionByIdQuery, PrescriptionByIdQueryVariables>;
export const CaseMangerContactDocument = gql`
  query caseMangerContact($programId: Int!, $zip: String!) {
    result: caseMangerContact(programId: $programId, zip: $zip) {
      name
      phoneNumber
      email
    }
  }
`;

/**
 * __useCaseMangerContactQuery__
 *
 * To run a query within a React component, call `useCaseMangerContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseMangerContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseMangerContactQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useCaseMangerContactQuery(
  baseOptions: Apollo.QueryHookOptions<CaseMangerContactQuery, CaseMangerContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseMangerContactQuery, CaseMangerContactQueryVariables>(CaseMangerContactDocument, options);
}
export function useCaseMangerContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseMangerContactQuery, CaseMangerContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseMangerContactQuery, CaseMangerContactQueryVariables>(
    CaseMangerContactDocument,
    options
  );
}
export type CaseMangerContactQueryHookResult = ReturnType<typeof useCaseMangerContactQuery>;
export type CaseMangerContactLazyQueryHookResult = ReturnType<typeof useCaseMangerContactLazyQuery>;
export type CaseMangerContactQueryResult = Apollo.QueryResult<CaseMangerContactQuery, CaseMangerContactQueryVariables>;
export const FieldReimbursementManagerContactDocument = gql`
  query fieldReimbursementManagerContact($programId: Int!, $zip: String!) {
    result: fieldReimbursementManagerContact(programId: $programId, zip: $zip) {
      email
      name
      phoneNumber
      territoryName
    }
  }
`;

/**
 * __useFieldReimbursementManagerContactQuery__
 *
 * To run a query within a React component, call `useFieldReimbursementManagerContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldReimbursementManagerContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldReimbursementManagerContactQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useFieldReimbursementManagerContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    FieldReimbursementManagerContactQuery,
    FieldReimbursementManagerContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldReimbursementManagerContactQuery, FieldReimbursementManagerContactQueryVariables>(
    FieldReimbursementManagerContactDocument,
    options
  );
}
export function useFieldReimbursementManagerContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldReimbursementManagerContactQuery,
    FieldReimbursementManagerContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldReimbursementManagerContactQuery, FieldReimbursementManagerContactQueryVariables>(
    FieldReimbursementManagerContactDocument,
    options
  );
}
export type FieldReimbursementManagerContactQueryHookResult = ReturnType<
  typeof useFieldReimbursementManagerContactQuery
>;
export type FieldReimbursementManagerContactLazyQueryHookResult = ReturnType<
  typeof useFieldReimbursementManagerContactLazyQuery
>;
export type FieldReimbursementManagerContactQueryResult = Apollo.QueryResult<
  FieldReimbursementManagerContactQuery,
  FieldReimbursementManagerContactQueryVariables
>;
export const SpecialtyPharmacyContactDocument = gql`
  query specialtyPharmacyContact($programId: Int!, $brandName: String!) {
    result: specialtyPharmacyContact(programId: $programId, brandName: $brandName) {
      specialtyPharmacyName
      webSite
      faxNumber
      phoneNumber
      specialtyPharmacyType
    }
  }
`;

/**
 * __useSpecialtyPharmacyContactQuery__
 *
 * To run a query within a React component, call `useSpecialtyPharmacyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialtyPharmacyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialtyPharmacyContactQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      brandName: // value for 'brandName'
 *   },
 * });
 */
export function useSpecialtyPharmacyContactQuery(
  baseOptions: Apollo.QueryHookOptions<SpecialtyPharmacyContactQuery, SpecialtyPharmacyContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpecialtyPharmacyContactQuery, SpecialtyPharmacyContactQueryVariables>(
    SpecialtyPharmacyContactDocument,
    options
  );
}
export function useSpecialtyPharmacyContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpecialtyPharmacyContactQuery, SpecialtyPharmacyContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpecialtyPharmacyContactQuery, SpecialtyPharmacyContactQueryVariables>(
    SpecialtyPharmacyContactDocument,
    options
  );
}
export type SpecialtyPharmacyContactQueryHookResult = ReturnType<typeof useSpecialtyPharmacyContactQuery>;
export type SpecialtyPharmacyContactLazyQueryHookResult = ReturnType<typeof useSpecialtyPharmacyContactLazyQuery>;
export type SpecialtyPharmacyContactQueryResult = Apollo.QueryResult<
  SpecialtyPharmacyContactQuery,
  SpecialtyPharmacyContactQueryVariables
>;
export const ServiceRequestInfoDocument = gql`
  query serviceRequestInfo($organizationId: ID!, $programId: ID!, $patientId: Int) {
    result: serviceRequestInfo(organizationId: $organizationId, programId: $programId, patientId: $patientId) {
      id: serviceRequestId
      requestStartDate: sRStartDate
      requestStatus: sRStatus
      requestType: sRType
      actionAlert
      authorizationReceived
      copayCardId
      displayOnGrid
      missingInfoFields
      nextStep
      patientDOB
      patientFirstName
      patientId
      patientLastName
      physicianFirstName
      physicianLastName
      physicianNPI
      shippedDate
      shippedStatus
      sRDrugName
      statusText
      updateAlert
      requestID
      updatedAlertDate
      patientEnrollmentGuid
      isEserviceTransaction
      documents {
        id: documentId
        created
        entityId
        entityName
        fileName
        fileStorageId
        formComplete
        lastUpdated
        programId
        signatureRequired
        source
        status
        status
        totalPages
      }
    }
  }
`;

/**
 * __useServiceRequestInfoQuery__
 *
 * To run a query within a React component, call `useServiceRequestInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceRequestInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceRequestInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      programId: // value for 'programId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useServiceRequestInfoQuery(
  baseOptions: Apollo.QueryHookOptions<ServiceRequestInfoQuery, ServiceRequestInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceRequestInfoQuery, ServiceRequestInfoQueryVariables>(
    ServiceRequestInfoDocument,
    options
  );
}
export function useServiceRequestInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServiceRequestInfoQuery, ServiceRequestInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceRequestInfoQuery, ServiceRequestInfoQueryVariables>(
    ServiceRequestInfoDocument,
    options
  );
}
export type ServiceRequestInfoQueryHookResult = ReturnType<typeof useServiceRequestInfoQuery>;
export type ServiceRequestInfoLazyQueryHookResult = ReturnType<typeof useServiceRequestInfoLazyQuery>;
export type ServiceRequestInfoQueryResult = Apollo.QueryResult<
  ServiceRequestInfoQuery,
  ServiceRequestInfoQueryVariables
>;
export const ServiceRequestWithDocumentsInfoDocument = gql`
  query serviceRequestWithDocumentsInfo(
    $organizationId: ID!
    $programId: ID!
    $patientId: Int
    $serviceRequestId: Int
  ) {
    result: serviceRequestWithDocumentsInfo(
      organizationId: $organizationId
      programId: $programId
      patientId: $patientId
      serviceRequestId: $serviceRequestId
    ) {
      id: serviceRequestId
      requestStartDate: sRStartDate
      requestStatus: sRStatus
      requestType: sRType
      actionAlert
      authorizationReceived
      copayCardId
      displayOnGrid
      missingInfoFields
      nextStep
      patientDOB
      patientFirstName
      patientId
      patientLastName
      physicianFirstName
      physicianLastName
      shippedDate
      shippedStatus
      sRDrugName
      statusText
      updateAlert
      updatedAlertDate
      requestID
      documents {
        id: documentId
        created
        entityId
        entityName
        fileName
        fileStorageId
        formComplete
        lastUpdated
        programId
        documentType
        signatureRequired
        source
        status
        status
        totalPages
        product
      }
      caseRecordTransaction {
        id: caseRecordTransactionId
        caseFlowStep
        caseMileStone
        caseRecordId
        caseTransactionId
        caseTransactionState
        caseTransactionStatus
        caseTransactionType
      }
      copayCard {
        copayCardID
        payeeName
        payeeAddress
        taxID
        totalApprovedAmount
        totalCopayRequestedAmount
        totalDueAmount
        payeeType
        copayCardEffectiveStartDate
        copayCardEffectiveEndDate
        eOBReceivedDate
      }
      srCopayPaymentEob {
        copayCardId
        billingCode
        dateOfService
        quantity
        originalBilledAmount
        patientCopayAmount
        patientDueAmount
        status
        statusUpdatedDate
        statusUpdatedBy
        primaryPlanPaidAmount
      }
    }
  }
`;

/**
 * __useServiceRequestWithDocumentsInfoQuery__
 *
 * To run a query within a React component, call `useServiceRequestWithDocumentsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceRequestWithDocumentsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceRequestWithDocumentsInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      programId: // value for 'programId'
 *      patientId: // value for 'patientId'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useServiceRequestWithDocumentsInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceRequestWithDocumentsInfoQuery,
    ServiceRequestWithDocumentsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceRequestWithDocumentsInfoQuery, ServiceRequestWithDocumentsInfoQueryVariables>(
    ServiceRequestWithDocumentsInfoDocument,
    options
  );
}
export function useServiceRequestWithDocumentsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceRequestWithDocumentsInfoQuery,
    ServiceRequestWithDocumentsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceRequestWithDocumentsInfoQuery, ServiceRequestWithDocumentsInfoQueryVariables>(
    ServiceRequestWithDocumentsInfoDocument,
    options
  );
}
export type ServiceRequestWithDocumentsInfoQueryHookResult = ReturnType<typeof useServiceRequestWithDocumentsInfoQuery>;
export type ServiceRequestWithDocumentsInfoLazyQueryHookResult = ReturnType<
  typeof useServiceRequestWithDocumentsInfoLazyQuery
>;
export type ServiceRequestWithDocumentsInfoQueryResult = Apollo.QueryResult<
  ServiceRequestWithDocumentsInfoQuery,
  ServiceRequestWithDocumentsInfoQueryVariables
>;
export const GetSrHubStatusesDocument = gql`
  query getSrHubStatuses($programId: Int!, $organizationId: Int!) {
    srHubStatuses(programId: $programId, organizationId: $organizationId) {
      statusDescription
    }
  }
`;

/**
 * __useGetSrHubStatusesQuery__
 *
 * To run a query within a React component, call `useGetSrHubStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrHubStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrHubStatusesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetSrHubStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<GetSrHubStatusesQuery, GetSrHubStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSrHubStatusesQuery, GetSrHubStatusesQueryVariables>(GetSrHubStatusesDocument, options);
}
export function useGetSrHubStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSrHubStatusesQuery, GetSrHubStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSrHubStatusesQuery, GetSrHubStatusesQueryVariables>(GetSrHubStatusesDocument, options);
}
export type GetSrHubStatusesQueryHookResult = ReturnType<typeof useGetSrHubStatusesQuery>;
export type GetSrHubStatusesLazyQueryHookResult = ReturnType<typeof useGetSrHubStatusesLazyQuery>;
export type GetSrHubStatusesQueryResult = Apollo.QueryResult<GetSrHubStatusesQuery, GetSrHubStatusesQueryVariables>;
