import { noop } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, FieldRenderProps } from 'react-final-form';
import { ProviderNpiFragment, useProviderNpiLazyQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { NpiSearch, NpiSearchProps } from './NpiSearch';

const renderLabel = (option: ProviderNpiFragment): string => {
  const name = [option.firstName, option.middleName, option.lastName]
    .filter((n) => n && n?.trim()?.length >= 0)
    .join(' ');

  return `${option.npi} | ${name}`;
};

interface NpiSearchProviderFieldProps extends Partial<NpiSearchProps> {
  fieldName?: string;
  label?: string;
}

export const NpiSearchProviderField = ({
  fieldName = 'prescriber_npi',
  onNpiSelect = noop,
  ...props
}: NpiSearchProviderFieldProps) => {
  return (
    <Field name={fieldName}>
      {(fieldRenderProps: FieldRenderProps<AnyType>) => (
        <>
          {props.label && <Form.Label className='text-uppercase'>{props.label}</Form.Label>}
          <NpiSearch
            {...(props as NpiSearchProps)}
            asyncTypeaheadProps={{
              ...props.asyncTypeaheadProps,
              allowNew: props.asyncTypeaheadProps?.allowNew ?? true,
              defaultInputValue: String(fieldRenderProps.input.value),
              inputProps: {
                ...props.asyncTypeaheadProps?.inputProps,
                'data-testid': fieldRenderProps.input.name
              }
            }}
            fieldRenderProps={fieldRenderProps}
            labelRenderer={renderLabel}
            onNpiSelect={onNpiSelect}
            queryHook={useProviderNpiLazyQuery}
          />
        </>
      )}
    </Field>
  );
};
