import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AnyType } from '../interfaces';
import { AppRootState, tenancyFeatureSelector } from '../redux';
import { LoadingIndicatorRow } from '.';
import { getEnrollmentPayload, htmlProps } from '../utils';
import { useGeneratePDF } from '../api';
import { useBinaryObjectUrl } from '../hooks';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';

const ReviewTab = () => {
  const { service } = useParams<{ service: string }>();
  const { getState } = useFormApi();
  const { values, submitError } = getState();
  const { patientId } = useParams<{ service: string; patientId: string }>();
  const user = useSelector((state: AppRootState) => state.auth.session?.user);
  const { mutate, loading, error } = useGeneratePDF({});
  const [pdfData, setPdfData] = useState<AnyType>(undefined);
  const pdfUrl = useBinaryObjectUrl(pdfData, 'application/pdf');
  const formSchema: AnyType = useSelector<AnyType>(tenancyFeatureSelector(`form.service-request-${service}` ?? ''));
  const portalName: AnyType = useSelector<AnyType>((state: AppRootState) => state.app?.config?.applicationName);
  const { search: queryString } = useLocation();
  const drugList = useSelector((state: AppRootState) => state.app?.entities?.drugList) ?? null;
  const queryParams = new URLSearchParams(queryString);
  const drugId = queryParams.get('drug') || drugList?.[0]?.DrugId?.toString() || '';
  const copay_claim_review_tab_text = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.features as AnyType) ?? {})['form.service-request-copay-claim-review-tab-text'] as AnyType
  );
  if (portalName === 'TaihoProviderPortal') {
    values['primary_is_cardholder'] = 'N';
    values['secondary_is_cardholder'] = 'N';
    values['tertiary_is_cardholder'] = 'N';
  }
  const payload = useCallback(
    () => getEnrollmentPayload(values, patientId, service, portalName, user, formSchema?.fields, drugId),
    [values, patientId, user]
  );

  useEffect(() => {
    const generatePdf = async () => {
      try {
        const response = await mutate(payload() as AnyType);
        setPdfData(response);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('ERR GENERATING PDF', e);
      }
    };
    generatePdf();
    return () => {
      if (portalName === 'TaihoProviderPortal') {
        values['primary_is_cardholder'] = undefined;
        values['secondary_is_cardholder'] = undefined;
      }
    };
  }, [payload]);

  if (loading) return <LoadingIndicatorRow />;
  if (error) return <p>{error.message}</p>;

  return (
    <>
      {pdfUrl && (
        <object
          aria-label='Review PDF Download for Service Request'
          style={{ width: '100%', minHeight: '500px' }}
          type='application/pdf'
          data={pdfUrl}
        />
      )}

      <span
        {...htmlProps(
          copay_claim_review_tab_text?.data?.review_and_submit_tab_text != undefined &&
            copay_claim_review_tab_text?.data?.review_and_submit_tab_text != null
            ? copay_claim_review_tab_text?.data?.review_and_submit_tab_text
            : ''
        )}></span>

      {submitError && <p className='text-danger pt-2'>{submitError}</p>}
    </>
  );
};

export default ReviewTab;
