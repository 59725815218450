import { ColDef, RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import React, { forwardRef, PropsWithRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { PersonFill, ReplyFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { HcpGrid, HcpRequestStateUI, MessagesFilterTypes } from '.';
import { API_URL, useThreads } from '../api';
import { AnyType, Message } from '../interfaces';
import { AppRootState } from '../redux';
import {
  ActionColumnActions,
  actionsColumnDefinition,
  clientApplicationNameSelector,
  messageDateColumnDefinition,
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  messageColumnDefinition,
  messageDirectionColumnDefinition,
  ROUTE_PATHS
} from '../utils';

export interface MessagesTableComponent {
  loadData(): void;
}

interface MessagesTableProps extends PropsWithRef<AnyType> {
  filteredStatus: string;
  searchText?: string;
  messages?: Message[];
}

const columnSelector = featureGridColumnsSelector('messages.table');

export const MessagesTable = forwardRef<AnyType, MessagesTableProps>((props: MessagesTableProps, ref) => {
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);

  const { data: messages, ...result } = useThreads({
    base: API_URL,
    lazy: true
  });

  const applicationName = useSelector(clientApplicationNameSelector);
  const loadData = useCallback(() => {
    if (!currentUser) return;

    result.refetch({
      queryParams: {
        applicationName: applicationName
      }
    });
  }, [currentUser]);

  useEffect(loadData, [loadData]);

  useImperativeHandle(
    ref,
    () => ({
      loadData
    }),
    [loadData]
  );

  const columns = useSelector(columnSelector);

  if (!columns) return null;

  const filterGridComparator = (
    node: RowNode,
    { filterState: value, searchText }: { filterState: string | undefined; searchText: string | undefined },
    columns?: ColDef[]
  ) => {
    const hasSearch =
      !searchText ||
      (searchText &&
        !!columns?.find(
          (col: AnyType) => col.field && node.data[col.field]?.toLowerCase()?.includes(searchText?.toLowerCase())
        ));

    switch (value) {
      case MessagesFilterTypes.unread:
        return !node.data.isRead && hasSearch;
      case MessagesFilterTypes.read:
        return node.data.isRead && hasSearch;
      default:
        return hasSearch;
    }
  };

  return (
    <HcpRequestStateUI
      loadingUIEnabled
      isLoading={result.loading || !messages}
      errorUIEnabled
      errorText={messages?.errors?.map((e) => e.description).join(' ')}
      errorTitle='Messages Error'
      isError={result.error || (messages?.errors?.length ?? 0) > 0}
      reloadingEnabled
      reload={loadData}>
      <HcpGrid
        isFilterActive={props.filteredStatus !== 'all' || !!props.searchText}
        filterComparator={filterGridComparator}
        filterValue={{ filterState: props.filteredStatus, searchText: props.searchText }}
        gridOptions={{
          rowClassRules: {
            'font-weight-bold': (params) => params.data.isRead !== true
          }
        }}
        rowData={messages?.messageThreads ?? []}
        rowSelection='single'
        className='grid-wrapper'
        pagination
        suppressCellSelection={true}>
        <AgGridColumn {...messageDirectionColumnDefinition()} />
        <AgGridColumn
          {...messageDateColumnDefinition('lastMessageDate')}
          {...getFeatureGridColumnProps(columns.date)}
        />
        <AgGridColumn field='patientName' {...getFeatureGridColumnProps(columns.patientName)} />
        <AgGridColumn field='providerName' {...getFeatureGridColumnProps(columns.prescriberName)} />
        <AgGridColumn field='subject' {...getFeatureGridColumnProps(columns.subject)} />
        <AgGridColumn {...messageColumnDefinition()} {...getFeatureGridColumnProps(columns.messageDetail)} />
        <AgGridColumn field='sender' {...getFeatureGridColumnProps(columns.from)} />
        <AgGridColumn field='assignedTo' {...getFeatureGridColumnProps(columns.to)} />
        <AgGridColumn
          {...actionsColumnDefinition((node: RowNode): ActionColumnActions[] => [
            {
              href: `${ROUTE_PATHS.messages}/${node.data.threadID}`,
              label: 'View',
              icon: PersonFill
            },
            {
              href: `${ROUTE_PATHS.messages}/${node.data.threadID}#reply`,
              label: 'Reply',
              icon: ReplyFill
            }
          ])}
        />
      </HcpGrid>
    </HcpRequestStateUI>
  );
});
