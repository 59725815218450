import React from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { AppNavigation, MessageNotification, ConnectedPatientSearch, UserMenu } from '.';
import { ResourceImage } from '.';
import { PatientRequestViewModelFragment } from '../graphql';
import logo from '../images/logo_CareMetx.png';
import { AnyType } from '../interfaces';
import { navigateToHome, navigateToPatient } from '../redux';
import { FeatureGuard } from './multi-tenant/FeatureGuard';
import { CustomThemes } from '../utils/custom-themes';

interface MainToolbarProps {
  navigateToHome: () => void;
  navigateToPatient: (id: string | number) => void;
}

export const MainToolbar = (props: MainToolbarProps) => {
  CustomThemes();
  return (
    <Navbar collapseOnSelect expand='xl' className='fs-2 px-2 text-uppercase cmx__hcp-nav align-items-center py-0'>
      <Navbar.Brand onClick={props.navigateToHome} className='pointer'>
        <ResourceImage contentKey='header.logo' className='cmx__hcp-nav__logo-toolbar-image' />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls='responsive-navbar-nav' className='border-0' />
      <Navbar.Collapse className=''>
        <Nav className='w-20 justify-content-xl-end align-items-lg-left align-items-xl-center'>
          <AppNavigation />
        </Nav>
        <Nav className='justify-content-end align-items-lg-left align-items-xs-left py-0'>
          <FeatureGuard contentKey='messages.manage'>
            <MessageNotification />
          </FeatureGuard>
          {renderPatientSearch(props)}
          <span className='vertical-border-header d-none d-md-block mx-3' />
          <UserMenu />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const renderPatientSearch = ({ navigateToPatient }: MainToolbarProps) => {
  const patientSearchRef = React.createRef();

  const onPatientSelect = (patient: PatientRequestViewModelFragment) => {
    navigateToPatient(patient.patientId);
    (patientSearchRef.current as AnyType).clear();
  };

  return (
    <Form
      // Do nothing since the patient search handles state.
      // Form is needed since search is a final form field (used in other forms).
      onSubmit={() => undefined}
      render={() => (
        <div className='w-auto align-items-start'>
          <div className='cmx__patient-search'>
            <div className='cmx__patient-search__search-outlined-input'>
              <ConnectedPatientSearch
                asyncTypeaheadProps={{ ref: patientSearchRef }}
                placeholder='Patient Last Name or DOB'
                onPatientSelect={onPatientSelect}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  navigateToHome,
  navigateToPatient
};

export const ConnectedMainToolbar = connect(mapStateToProps, mapDispatchToProps)(MainToolbar);
