import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldTextSection, fieldLoginInput } from '..';

export const getForgotPasswordFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'forgot password form',
  fields: [
    fieldLoginInput('username', schema?.username, {
      sizes: { xs: 12 },
      validate: [
        {
          type: 'username'
        }
      ]
    })
  ].filter(Boolean)
});

// -----===[test schema]===-----
export const forgotPasswordFormSchema: DBFormSchema = {
  username: { label: 'username', required: true }
};
