import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { number, object, string } from 'yup';

import { AdminInfoForm, OrganizationInfoForm, ResourceContent, ResourcePublicLayout } from '../components';
import { API_URL } from '../api';
import { AnyType, Breadcrumb } from '../interfaces';
import { addressValidation, DBFieldSchema, ROUTE_PATHS, NOT_REQUIRED, validations } from '../utils';
import { useSelector } from 'react-redux';
import { tenancyFeatureSelector } from '../redux/selectors';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { getNpiValidationMessage } from '../redux/helpers';

export const getOrganizationOnNextSchema = (applicationName: string | undefined) =>
  object({
    administrator_email: string().test('unique-email', 'Email is not unique', async (value: AnyType) => {
      if (!value) return false;

      const queryParams = `emailAddress=${encodeURIComponent(value)}`;
      const url = `${API_URL}/api/User/IsUniqueEmail`;
      const response = await fetch(
        `${url}?${queryParams}&applicationName=${encodeURIComponent(applicationName as string)}`
      );
      const json = await response.json();

      return !!json.isUniqueEmail;
    }),
    administrator_username: string().test('unique-username', 'Username is not unique', async (value: AnyType) => {
      if (!value) return false;

      const queryParams = `userName=${encodeURIComponent(value)}`;
      const url = `${API_URL}/api/User/IsUniqueUserName`;
      const response = await fetch(
        `${url}?${queryParams}&applicationName=${encodeURIComponent(applicationName as string)}`
      );
      const json = await response.json();

      return !!json.isUniqueUserName;
    })
  });

export const organizationSchema = ({ programId }: { programId: number | undefined }) => {
  return object({
    // OrganizationInfo
    organization_npi: validations.npiValidation().nullable().required(),
    organization_name: validations.orgNameValidation(),
    address_1: validations.addressValidation(),
    address_2: addressValidation(false),
    city: validations.cityValidation(),
    state: validations.stateValidation(),
    zip: validations.zipValidation(),
    tax_id: validations.taxIdValidation(NOT_REQUIRED),
    organization_phone: validations.phoneValidation(),
    organization_fax: validations.phoneValidation(),
    // AdminInfo
    administrator_is_prescriber: string().default('no').required(),
    administrator_prescriber_npi: validations.conditionalValidation(
      'administrator_is_prescriber',
      validations
        .npiValidation()
        .nullable()
        .required()
        .test('administrator_is_prescriber', getNpiValidationMessage(), async function (value) {
          const { organization_npi, administrator_prescriber_npi } = this.parent ?? {};
          if (
            (administrator_prescriber_npi === null || administrator_prescriber_npi === undefined) &&
            (organization_npi === null || organization_npi === undefined)
          )
            return true;

          const url = `${API_URL}/api/register/IsNpiExists`;
          const queryParams = `orgNpi=${organization_npi}&prescriberNpi=${value}&programId=${programId}`;
          if (organization_npi?.toString()?.length == 10 && administrator_prescriber_npi?.toString()?.length == 10) {
            const response = await window.fetch(`${url}?${queryParams}`, {
              method: 'GET'
            });
            const json = await response.json();
            return json?.isSuccess;
          }

          return true;
        }),
      (v: string) => {
        return v === 'yes';
      },
      number()
    ),
    administrator_first_name: validations.nameValidation().label('First Name'),
    administrator_last_name: validations.nameValidation().label('Last Name'),
    administrator_suffix: validations.suffixValidation().label('Suffix'),
    administrator_phone: validations.phoneValidation().label('Phone'),
    administrator_fax: validations.phoneValidation().label('Fax'),
    administrator_email: validations.emailValidation().required(),
    administrator_username: validations.usernameValidation().required()
  });
};

const breadcrumbs: Breadcrumb[] = [
  {
    href: ROUTE_PATHS.registrationWelcome,
    label: '<< BACK TO WELCOME',
    testId: 'welcome'
  }
];

export const OrganizationView = () => {
  const [organizationFormFields, setOrganizationFormFields]: [DBFieldSchema, AnyType] = useState({});
  const [administratorFormFields, setAdministratorFormFields]: [DBFieldSchema, AnyType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getOrganizationFormSchema = async () => {
    const organizationFormSchema: AnyType = await useSelector<AnyType>(
      tenancyFeatureSelector('form.organization-info')
    );
    if (organizationFormSchema) {
      const { fields } = await organizationFormSchema;
      setOrganizationFormFields(fields);
    }
  };
  getOrganizationFormSchema();
  const getAdministratorFormSchema = async () => {
    const administratorFormSchema: AnyType = await useSelector<AnyType>(
      tenancyFeatureSelector('form.administrator-info')
    );
    if (administratorFormSchema) {
      const { fields } = await administratorFormSchema;
      setAdministratorFormFields(fields);
    }
  };
  getAdministratorFormSchema();
  useEffect(() => {
    if (!isEmpty(organizationFormFields) && !isEmpty(administratorFormFields)) {
      setIsLoading(false);
    }
  }, [organizationFormFields, administratorFormFields]);

  return (
    <ResourcePublicLayout breadcrumbs={breadcrumbs} className='view-container' pageTitle='Organization Information'>
      <div className='d-flex pt-2'>
        <Col>
          <p className='required-text text-sm-right'>
            <ResourceContent contentKey='general.required-fields-note' />
          </p>
        </Col>
      </div>

      <div className='d-flex flex-column flex-lg-row'>
        <Col lg={6}>
          <div className='px-md-3'>
            <OrganizationInfoForm isLoading={isLoading} formFields={organizationFormFields} />
          </div>
        </Col>

        <div className='vertical-border d-none d-lg-block' />

        <Col lg={6}>
          <div className='px-md-3'>
            <AdminInfoForm isLoading={isLoading} formFields={administratorFormFields} />
          </div>
        </Col>
      </div>
    </ResourcePublicLayout>
  );
};

const isEmpty = (obj: AnyObject) => Object.keys(obj).length === 0;
