import { FormOptions } from '@data-driven-forms/react-form-renderer';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import suirComponentMapper from '@data-driven-forms/suir-component-mapper/dist/cjs/component-mapper';
import { AnyObject } from 'final-form';
import React, { createRef, useEffect, useState, useCallback } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { Typeahead, TypeaheadProps } from 'react-bootstrap-typeahead';
import { X } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import {
  AppRootState,
  getAllTenancyFeatureSelector,
  tenancyDrugServicesSelector,
  tenancyFeatureSelector,
  useTenancyDrugListSelector
} from '../../redux';
import {
  displayErrorText,
  LoadingIndicator,
  NpiSearch,
  NpiSearchProps,
  SearchInputICDWithGql,
  SearchInputWithGql,
  SearchInputWithGqlDisplay,
  SearchInputWithGqlIconLocation
} from '..';
import {
  GRAPHQL_DEFAULT_CACHE,
  OrganizationAddressFragment,
  OrganizationNpiFragment,
  PatientDiagnosisInfoFragment,
  PatientInsuranceQuery,
  PhysicianFragment,
  ProviderNpiFragment,
  useGetPatientDetailByIdLazyQuery,
  useOrganizationAddressInfoLazyQuery,
  useOrganizationNpiLazyQuery,
  usePatientDiagnosisByProgramLazyQuery,
  usePatientDiagnosisByProgramQuery,
  usePatientDiagnosisLazyQuery,
  usePatientInsuranceLazyQuery,
  usePhysiciansInfoLazyQuery,
  useProviderNpiLazyQuery
} from '../../graphql';
import { AnyType } from '../../interfaces';
import { dateFormatter, FIELD_NAMES, formatContactNumber } from '../../utils';
import { DEFAULT_INSURANCE_FIELD_EXCLUSIONS } from '../../utils/service-request-exclusions';
import { DisplayErrorOrHelperTextFeedback, validationProps } from './Common';
import { noop } from 'rxjs';
import { Drug } from '../../api/portal-config.generated';
import { useCacheTenancyFeatures } from '../../hooks';

const { facilityFields, insuranceFields, patientFields, prescriberFields, rxAdminDoseFields } = FIELD_NAMES;

export const HcpNpiProviderSearch = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);

  const onNpiSelect = (npi: ProviderNpiFragment) => {
    let stateLicense;

    if (npi?.stateLicenses && npi?.stateLicenses?.length > 0) {
      stateLicense = npi.stateLicenses[0];
    }

    form.change(prescriberFields.npi, Number(npi?.npi) || null);
    npi?.firstName && form.change(prescriberFields.firstName, npi.firstName);
    npi?.middleName && form.change(prescriberFields.middleName, npi.middleName);
    npi?.lastName && form.change(prescriberFields.lastName, npi.lastName);
    stateLicense?.licenseNumber && form.change(prescriberFields.stateLicenseNumber, stateLicense.licenseNumber);
    stateLicense?.stateCode && form.change(prescriberFields.licensingState, stateLicense.stateCode);
    npi?.taxId && form.change(prescriberFields.taxId, npi.taxId);
  };

  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <NpiSearch
        {...(fieldProps.rest as NpiSearchProps)}
        asyncTypeaheadProps={{
          ...props.asyncTypeaheadProps,
          allowNew: props.asyncTypeaheadProps?.allowNew ?? true,
          defaultInputValue: String(fieldProps.input.value ?? ''),
          inputProps: {
            ...props.asyncTypeaheadProps?.inputProps,
            'data-testid': fieldProps.input.name
          }
        }}
        fieldRenderProps={fieldProps}
        labelRenderer={renderProviderSearchLabel}
        onNpiSelect={onNpiSelect}
        queryHook={useProviderNpiLazyQuery}
      />
    </Form.Group>
  );
};

const renderProviderSearchLabel = (option: ProviderNpiFragment): string => {
  const name = [option.firstName, option.middleName, option.lastName]
    .filter((n) => n && n?.trim()?.length >= 0)
    .join(' ');

  return `${option.npi} | ${name}`;
};

export const HcpNpiOrganizationSearch = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);
  const onNpiSelect = (npi: OrganizationNpiFragment) => {
    if (props?.name === 'organization_npi') {
      form.change('organization_npi', Number(npi?.npi) || null);
      npi?.organizationName && form.change('organization_name', npi?.organizationName ?? '');
      npi?.address1 && form.change('address_1', npi?.address1 ?? '');
      npi?.address2 && form.change('address_2', npi?.address2 ?? '');
      npi?.city && form.change('city', npi?.city ?? '');
      npi?.state && form.change('state', npi?.state ?? '');
      npi?.zipCode && form.change('zip', (npi?.zipCode ?? '').substring(0, 5));
      npi?.taxId && form.change('tax_id', npi?.taxId ?? '');
      npi?.phoneNumber && form.change('organization_phone', npi?.phoneNumber ?? '');
      npi?.faxNumber && form.change('organization_fax', npi?.faxNumber ?? '');
    } else {
      form.change(facilityFields.npi, Number(npi?.npi) || null);
      npi.organizationName && form.change(facilityFields.name, npi.organizationName);
      npi.address1 && form.change(facilityFields.address1, npi.address1);
      npi.address2 && form.change(facilityFields.address2, npi.address2);
      npi.zipCode && form.change(facilityFields.zip, npi.zipCode);
      npi.city && form.change(facilityFields.city, npi.city);
      npi.state && form.change(facilityFields.state, npi.state);
      npi.taxId && form.change(facilityFields.taxId, npi.taxId);
    }
  };

  const renderLabel = (option: OrganizationNpiFragment): string => {
    return `${option.npi} | ${option.organizationName}`;
  };

  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <NpiSearch
        {...(fieldProps.rest as NpiSearchProps)}
        asyncTypeaheadProps={{
          ...props.asyncTypeaheadProps,
          allowNew: props.asyncTypeaheadProps?.allowNew ?? true,
          defaultInputValue: String(fieldProps.input.value ?? ''),
          inputProps: {
            ...props.asyncTypeaheadProps?.inputProps,
            'data-testid': fieldProps.input.name
          }
        }}
        fieldRenderProps={fieldProps}
        labelRenderer={renderLabel}
        onNpiSelect={onNpiSelect}
        queryHook={useOrganizationNpiLazyQuery}
      />
    </Form.Group>
  );
};

export const HcpSearchAndAddPrescriber = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);
  const ref = createRef<HTMLDivElement>();

  const toggleAddInitialValue: boolean =
    form.getState().values['adding_prescriber'] || form.getState().values[prescriberFields.id] ? true : false || false;
  const [toggleAdd, setToggleAdd] = useState<boolean>(toggleAddInitialValue);

  const toggleReadFields = () => {
    if (form.getState().values['adding_prescriber'] === false) {
      form.change(prescriberFields.id, '');
      form.change(prescriberFields.npi, '');
      form.change(prescriberFields.firstName, '');
      form.change(prescriberFields.middleName, '');
      form.change(prescriberFields.lastName, '');
      form.change(prescriberFields.taxId, '');
      form.change(prescriberFields.stateLicenseNumber, '');
      form.change(prescriberFields.licensingState, '');
      form.change(prescriberFields.ptan, '');
      form.change(prescriberFields.dea, '');
      form.change(prescriberFields.email, '');
    }
  };

  const onPrescriberSelect = (prescriber: PhysicianFragment | null) => {
    form.batch(() => {
      (ref?.current as AnyType)?.clear();
      form.change(prescriberFields.id, prescriber?.id);
      form.change(prescriberFields.firstName, prescriber?.firstName);
      form.change(prescriberFields.middleName, prescriber?.middleName);
      form.change(prescriberFields.lastName, prescriber?.lastName);
      form.change(
        prescriberFields.npi,
        // A server-sent blank string can cause a NaN-related error during validation conversion, so we default to null:
        prescriber?.npi || null
      );
      form.change(prescriberFields.taxId, prescriber?.details?.taxId);
      form.change(prescriberFields.stateLicenseNumber, prescriber?.stateLicenses?.stateLicenseNumber);
      form.change(prescriberFields.licensingState, prescriber?.stateLicenses?.stateIssuer);
      form.change(prescriberFields.ptan, prescriber?.details?.pTAN);
      form.change(prescriberFields.dea, prescriber?.details?.dEA);
      form.change(prescriberFields.email, prescriber?.email);
    });
    setToggleAdd(true);
  };
  const onToggle = () => {
    setToggleAdd((prevToggle: boolean) => {
      form.change('adding_prescriber', !prevToggle);
      toggleReadFields();
      return !prevToggle;
    });
  };
  const renderLabel = (option: PhysicianFragment): string => {
    return `${option.firstName} ${option.lastName} | ${option.npi}`;
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <div className='d-flex align-items-center'>
        <SearchInputWithGql
          asyncTypeaheadProps={{ ref }}
          display={SearchInputWithGqlDisplay.outlined}
          labelRenderer={renderLabel}
          queryHook={usePhysiciansInfoLazyQuery}
          onItemSelect={onPrescriberSelect}
        />
        <Button block variant='outline-secondary' className='ml-2 w-50 text-nowrap' onClick={onToggle}>
          {toggleAdd ? 'CLEAR' : 'ADD A PRESCRIBER'}
        </Button>
      </div>
      {displayErrorText(fieldProps)}
    </Form.Group>
  );
};

export const HcpSearchAndAddLocation = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);
  const ref = createRef<HTMLDivElement>();

  const toggleAddInitialValue: boolean =
    form.getState().values['adding_location'] || form.getState().values['prescriber_location_id']
      ? true
      : false || false;
  const [toggleAdd, setToggleAdd] = useState<boolean>(toggleAddInitialValue);

  const toggleReadFields = () => {
    if (form.getState().values['adding_location'] === false) {
      form.change(prescriberFields.locationId, '');
      form.change(prescriberFields.locationAddress1, '');
      form.change(prescriberFields.locationAddress2, '');
      form.change(prescriberFields.locationPhone2, '');
      form.change(prescriberFields.locationAddressZip, '');
      form.change(prescriberFields.locationAddressCity, '');
      form.change(prescriberFields.locationAddressState, '');
      form.change(prescriberFields.locationPhone1, '');
      form.change(prescriberFields.locationFaxNumber, '');
      form.change(prescriberFields.locationAddressFull, '');
    }
  };

  const onLocationSelect = (location: OrganizationAddressFragment | null) => {
    form.batch(() => {
      (ref?.current as AnyType)?.clear();
      form.change(prescriberFields.locationId, location?.id);
      form.change(prescriberFields.locationAddress1, location?.address1);
      form.change(prescriberFields.locationAddress2, location?.address2);
      form.change(prescriberFields.locationAddressZip, location?.zip);
      form.change(prescriberFields.locationAddressCity, location?.city);
      form.change(prescriberFields.locationAddressState, location?.state);
      form.change(prescriberFields.locationPhone1, formatContactNumber(location?.phone1 ?? location?.phone2));
      form.change(prescriberFields.locationFaxNumber, formatContactNumber(location?.fax));
      form.change(
        prescriberFields.locationAddressFull,
        `${location?.address1} ${location?.address2 ?? ''} | ${location?.city}${
          location?.state || location?.zip ? ', ' : ''
        } ${location?.state ?? ''} ${location?.zip ?? ''}`
      );
    });
    setToggleAdd(true);
  };
  const onToggle = () => {
    setToggleAdd((prevToggle: boolean) => {
      form.change('adding_location', !prevToggle);
      form.change(prescriberFields.isNewPrescriber, !prevToggle);
      toggleReadFields();
      return !prevToggle;
    });
  };
  const renderLabel = (option: OrganizationAddressFragment): string => `${option?.address1}`;

  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <div className='d-flex align-items-center'>
        <SearchInputWithGql
          asyncTypeaheadProps={{ ref }}
          display={SearchInputWithGqlDisplay.outlined}
          labelRenderer={renderLabel}
          queryHook={useOrganizationAddressInfoLazyQuery}
          onItemSelect={onLocationSelect}
        />
        <Button block variant='outline-secondary' className='ml-2 w-50 text-nowrap' onClick={onToggle}>
          {toggleAdd ? 'CLEAR' : 'ADD A LOCATION'}
        </Button>
      </div>
      {displayErrorText(fieldProps)}
    </Form.Group>
  );
};

export const HcpAdministrationInfo = (props: UseFieldApiConfig) => {
  return (
    <Form.Group as={Col} className='hcp-radio' {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} />
    </Form.Group>
  );
};

export const HcpAttestationType = (props: UseFieldApiConfig) => {
  const styles = {
    marginBottom: '-2rem'
  };
  return (
    <Form.Group as={Col} className='hcp-radio' {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} style={styles} />
    </Form.Group>
  );
};

export const HcpHasInsuranceToggler = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const { input } = useFieldApi(props);
  // State to set the previous value of input.value
  const [prevValue, setPrevValue] = useState('');
  const currentValue = input.value;
  const { patientId } = useParams<{ patientId: string }>();
  const [execQuery, { data, loading }] = usePatientInsuranceLazyQuery({});
  const mandatoryInsuranceFieldUpdate = () => {
    input.value = 'yes';
    form.change('patient_has_insurance', 'yes');
  };

  // This will call once and set previous value as input.value
  useEffect(() => {
    setPrevValue(input.value);
  }, []);

  props.insuranceMandatory ? mandatoryInsuranceFieldUpdate() : noop;
  useEffect(() => {
    if (input.value !== 'yes') return;
    execQuery({
      variables: {
        patientId
      }
    });
  }, [input.value, patientId]);

  useEffect(() => {
    if (input.value !== 'yes') return;
    if (prevValue !== currentValue) {
      patchInsuranceForm(form, data);
    }
  }, [data, input.value]);

  useEffect(() => {
    if (input.value !== 'no') return;
    DEFAULT_INSURANCE_FIELD_EXCLUSIONS.forEach((field: string) => {
      form.change(field);
    });
    form.change(patientFields.hasInsurance, 'no');
  }, [form.change, input.value]);

  return (
    <Form.Group as={Col} className='hcp-radio' {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} />
      {loading && <LoadingIndicator spinnerProps={{ size: 'sm' }} />}
    </Form.Group>
  );
};

export const ICDDiagnosisSearch = (props: UseFieldApiConfig) => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const urlDrugId = queryParams.get('drug') ?? '';
  const form = useFormApi();
  const programId = useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId);
  const fieldProps = useFieldApi(props);
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  let drugId: number | undefined = undefined;
  if (urlDrugId) {
    drugId = parseInt(urlDrugId);
  } else {
    drugId = newDrugList[0]?.DrugId;
  }
  const services =
    useSelector(tenancyDrugServicesSelector(drugId)) &&
    Object.keys(useSelector(tenancyDrugServicesSelector(drugId))).length !== 0
      ? useSelector(tenancyDrugServicesSelector(drugId))
      : useSelector((state: AppRootState) => state.app?.entities?.features as AnyType);
  const filtered = services?.['filteredList.ICD']?.data;
  const [icdDescription, setIcdDescription] = useState<string | undefined | null>(undefined);
  const onSelect = (selection: PatientDiagnosisInfoFragment | null) => {
    if (selection) {
      setIcdDescription(selection?.icddescription);
      const isOther =
        (selection as AnyObject)?.customOption && (selection as AnyObject)?.label?.toLowerCase() === 'other';
      form.change(fieldProps.input.name, isOther ? 'other' : selection?.icdcode + ' - ' + selection?.icddescription);
    } else {
      form.change(fieldProps.input.name, null);
    }
  };

  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <SearchInputICDWithGql
        {...fieldProps}
        organizationIdRequired={false}
        programIdRequired={false}
        asyncTypeaheadProps={{
          ...validationProps(fieldProps.meta),
          defaultInputValue: fieldProps.input.value,
          defaultSelected: [fieldProps?.input?.value].filter(Boolean)
        }}
        display={SearchInputWithGqlDisplay.outlined}
        labelRenderer='codeWithDescription'
        queryHook={
          props?.filter === false
            ? usePatientDiagnosisLazyQuery
            : filtered?.enabled
            ? usePatientDiagnosisByProgramLazyQuery
            : usePatientDiagnosisLazyQuery
        }
        queryOptionsOnSearch={(queryOptions, searchTerm) => ({
          ...queryOptions,
          variables: {
            ...queryOptions.variables,
            iCDCode: searchTerm,
            programId: programId ? parseInt(programId) : 0,
            drugId: drugId
          }
        })}
        onItemSelect={onSelect}
      />
      {displayErrorText(fieldProps)}
    </Form.Group>
  );
};

export const IsPrimaryCardholderToggler = (props: UseFieldApiConfig) => {
  return (
    <Form.Group as={Col} className='hcp-radio inline-radio' {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} />
    </Form.Group>
  );
};

export const PlanNameTextField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} {...props.sizes} className={props.className}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpSearchOnlyPrescriber = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);
  const [prescriberSearch, setPrescriberSearch] = useState<boolean>(false);
  const organizationId = useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId);
  const [execPatientDetailQuery, patientDetailResult] = useGetPatientDetailByIdLazyQuery({
    variables: {
      organization: parseInt(organizationId || ''),
      patientId: parseInt(form.getState().values[rxAdminDoseFields?.patientId]) || 0
    },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });
  const loadDefaultPhysician = useCallback(() => {
    execPatientDetailQuery();
  }, []);

  useEffect(loadDefaultPhysician, [loadDefaultPhysician]);
  useEffect(() => {
    const result = patientDetailResult?.data;
    if (!result) return;
  }, [organizationId]);

  const onPrescriberSelect = (prescriber: PhysicianFragment | null) => {
    setPrescriberSearch(true);
    form.batch(() => {
      form.change(prescriberFields.id, prescriber?.id);
      form.change(prescriberFields.firstName, prescriber?.firstName);
      form.change(prescriberFields.middleName, prescriber?.middleName);
      form.change(prescriberFields.lastName, prescriber?.lastName);
      form.change(
        prescriberFields.npi,
        // A server-sent blank string can cause a NaN-related error during validation conversion, so we default to null:
        prescriber?.npi || null
      );
      form.change(rxAdminDoseFields.prescriberName, prescriber?.firstName);
      form.change(prescriberFields.taxId, prescriber?.details?.taxId);
      form.change(prescriberFields.stateLicenseNumber, prescriber?.stateLicenses?.stateLicenseNumber);
      form.change(prescriberFields.licensingState, prescriber?.stateLicenses?.stateIssuer);
      form.change(prescriberFields.ptan, prescriber?.details?.pTAN);
      form.change(prescriberFields.dea, prescriber?.details?.dEA);
      form.change(prescriberFields.email, prescriber?.email);
    });
  };
  const renderLabel = (option: PhysicianFragment): string => {
    return `${option.firstName} ${option.lastName} | ${option.npi}`;
  };
  if (organizationId && form.getState().values[rxAdminDoseFields.patientId] && !patientDetailResult?.data) {
    return (
      <Form.Group as={Col} {...props.sizes}>
        <LoadingIndicator />
      </Form.Group>
    );
  }
  if (patientDetailResult?.data?.patientDetailByPatientID?.physician && !prescriberSearch) {
    const prescriber = patientDetailResult?.data?.patientDetailByPatientID?.physician;
    form.change(prescriberFields.id, prescriber?.physicianId);
    form.change(prescriberFields.firstName, prescriber?.firstName);
    form.change(prescriberFields.middleName, prescriber?.middleName);
    form.change(prescriberFields.lastName, prescriber?.lastName);
    form.change(rxAdminDoseFields.npi, prescriber?.npi);
    form.change(rxAdminDoseFields.prescriberName, prescriber?.firstName + ' ' + prescriber?.lastName);
    form.change(prescriberFields.taxId, prescriber?.details?.taxId);
    form.change(prescriberFields.stateLicenseNumber, prescriber?.stateLicenses?.stateLicenseNumber);
    form.change(prescriberFields.licensingState, prescriber?.stateLicenses?.stateIssuer);
    form.change(prescriberFields.ptan, prescriber?.details?.pTAN);
    form.change(prescriberFields.dea, prescriber?.details?.dEA);
    form.change(prescriberFields.email, prescriber?.email);
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {fieldProps.label && <Form.Label className='text-uppercase'>{fieldProps.label}</Form.Label>}
      <div className='d-flex align-items-center'>
        <SearchInputWithGql
          asyncTypeaheadProps={{
            ...(props.asyncTypeaheadProps ?? {}),
            defaultSelected: patientDetailResult?.data?.patientDetailByPatientID?.physician
              ? [patientDetailResult?.data?.patientDetailByPatientID?.physician]
              : props?.asyncTypeaheadProps?.defaultSelected ?? []
          }}
          searchIconLocation={SearchInputWithGqlIconLocation.none}
          display={SearchInputWithGqlDisplay.outlined}
          labelRenderer={renderLabel}
          queryHook={usePhysiciansInfoLazyQuery}
          onItemSelect={onPrescriberSelect}
          placeholder={props.placeholder}
          isDisabled={fieldProps?.readOnly}
          {...fieldProps}
        />
      </div>
      {displayErrorText(fieldProps)}
    </Form.Group>
  );
};

export const asyncComponentMapper = {
  'has-insurance-toggler': HcpHasInsuranceToggler,
  'attestation-types': HcpAttestationType,
  'administration-info': HcpAdministrationInfo,
  'icd-diagnosis-search': ICDDiagnosisSearch,
  'location-add-search': HcpSearchAndAddLocation,
  'npi-organization-search': HcpNpiOrganizationSearch,
  'npi-provider-search': HcpNpiProviderSearch,
  'prescriber-add-search': HcpSearchAndAddPrescriber,
  'is-primary-cardholder-toggler': IsPrimaryCardholderToggler,
  'plan-name-text-field': PlanNameTextField,
  'prescriber-search': HcpSearchOnlyPrescriber
};

function patchInsuranceForm(form: FormOptions, data: PatientInsuranceQuery | undefined) {
  const primaryInsurance = data?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'primary');
  const secondaryInsurance = data?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'secondary');
  const tertiaryInsurance = data?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'tertiary');
  const otherInsurance = data?.result?.find((x) => x?.insuranceType?.toLowerCase() === 'other');
  if (primaryInsurance || secondaryInsurance || otherInsurance) {
    form.batch(() => {
      form.change(insuranceFields.primaryPlanName, primaryInsurance?.medicalPlanName);
      form.change(insuranceFields.primaryPlanCardholderId, primaryInsurance?.medicalCardHolderId);
      form.change(insuranceFields.primaryPlanGroupNumber, primaryInsurance?.medicalGroup);
      form.change(insuranceFields.primaryPlanPhoneNumber, primaryInsurance?.medicalPhoneNumber);
      form.change(insuranceFields.primaryPlanPayerName, primaryInsurance?.payerName);
      form.change(insuranceFields.primaryIsCardholder, primaryInsurance?.isPatientPrimaryCardholder ? 'Y' : 'N');
      form.change(insuranceFields.primarySubscriberFirstName, primaryInsurance?.subscriberFirstName);
      form.change(insuranceFields.primarySubscriberLastName, primaryInsurance?.subscriberLastName);
      form.change(insuranceFields.primarySubscriberDob, dateFormatter(primaryInsurance?.subscriberDOB, 'yyyy-MM-DD'));
      form.change(insuranceFields.primarySubscriberGender, primaryInsurance?.subscriberGender);
      form.change(insuranceFields.primarySubscriberRelationship, primaryInsurance?.relationship);
      form.change(insuranceFields.secondaryPlanName, secondaryInsurance?.medicalPlanName);
      form.change(insuranceFields.secondaryPlanCardholderId, secondaryInsurance?.medicalCardHolderId);
      form.change(insuranceFields.secondaryPlanCardholderIdOpt, secondaryInsurance?.medicalCardHolderId);
      form.change(insuranceFields.secondaryPlanGroupNumber, secondaryInsurance?.medicalGroup);
      form.change(insuranceFields.secondaryPlanGroupNumberOpt, secondaryInsurance?.medicalGroup);
      form.change(insuranceFields.secondaryPlanPhoneNumber, secondaryInsurance?.medicalPhoneNumber);
      form.change(insuranceFields.secondaryPlanPayerName, secondaryInsurance?.payerName);
      form.change(insuranceFields.secondarySubscriberFirstName, secondaryInsurance?.subscriberFirstName);
      form.change(insuranceFields.secondarySubscriberLastName, secondaryInsurance?.subscriberLastName);
      form.change(
        insuranceFields.secondarySubscriberDob,
        dateFormatter(secondaryInsurance?.subscriberDOB, 'yyyy-MM-DD')
      );
      form.change(insuranceFields.secondarySubscriberGender, secondaryInsurance?.subscriberGender);
      form.change(insuranceFields.secondarySubscriberRelationship, secondaryInsurance?.relationship);
      form.change(insuranceFields.tertiaryPlanPayerName, tertiaryInsurance?.payerName);
      form.change(insuranceFields.tertiaryPlanCardholderId, tertiaryInsurance?.medicalCardHolderId);
      form.change(insuranceFields.tertiaryPlanCardholderIdOpt, tertiaryInsurance?.medicalCardHolderId);
      form.change(insuranceFields.tertiarySubscriberFirstName, tertiaryInsurance?.subscriberFirstName);
      form.change(insuranceFields.tertiarySubscriberLastName, tertiaryInsurance?.subscriberLastName);
      form.change(insuranceFields.tertiarySubscriberDob, dateFormatter(tertiaryInsurance?.subscriberDOB, 'yyyy-MM-DD'));
      form.change(insuranceFields.tertiarySubscriberGender, tertiaryInsurance?.subscriberGender);
      form.change(insuranceFields.pharmacyName, primaryInsurance?.pharmacyPlanName);
      form.change(insuranceFields.pharmacyCardholderId, primaryInsurance?.pharmacyCardholderID);
      form.change(insuranceFields.pharmacyRxGroupNumber, primaryInsurance?.pharmacyGroup);
      form.change(insuranceFields.pharmacyRxBinIin, primaryInsurance?.pharmacyBINNumber);
      form.change(insuranceFields.pharmacyRxPcn, primaryInsurance?.pharmacyPCN);
      form.change(insuranceFields.pharmacyPhoneNumber, primaryInsurance?.pharmacyPhoneNumber);
      form.change(insuranceFields.pharmacyCardholderIdOpt, primaryInsurance?.pharmacyCardholderID);
      form.change(insuranceFields.pharmacyRxGroupNumberOpt, primaryInsurance?.pharmacyGroup);
      form.change(insuranceFields.otherPlanName, otherInsurance?.medicalPlanName);
      form.change(insuranceFields.otherPlanCardholderId, otherInsurance?.medicalCardHolderId);
      form.change(insuranceFields.otherPlanGroupNumber, otherInsurance?.medicalGroup);
      form.change(insuranceFields.otherPlanPhoneNumber, otherInsurance?.medicalPhoneNumber);
    });
  }
}
