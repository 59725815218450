import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, fieldTextSection, headerSection, headerWithPasswordRequirement, spacerSection } from '..';

export const getMyAccountFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'My Account Form',
  fields: [
    headerWithPasswordRequirement(schema?.change_password_header?.label, schema?.change_password_header, {
      top: '-56px',
      left: '183px'
    }),
    fieldTextSection('current_password', schema?.current_password, {
      type: 'password',
      sizes: { sm: 12, md: 6, lg: 4 },
      validate: [
        {
          type: 'strong-password'
        }
      ]
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldTextSection('new_password', schema?.new_password, {
      type: 'password',
      sizes: { sm: 12, md: 6, lg: 4 },
      validate: [
        {
          type: 'same-as',
          strict: true,
          compareToField: 'confirm_password'
        },
        {
          type: 'strong-password'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    fieldTextSection('confirm_password', schema?.confirm_password, {
      type: 'password',
      sizes: { sm: 12, md: 6, lg: 4 },
      validate: [
        {
          type: 'same-as',
          strict: true,
          compareToField: 'new_password'
        },
        {
          type: 'strong-password'
        }
      ]
    }),
    spacerSection(3, { sizes: { sm: 12 } })
  ].filter(Boolean)
});

// -----===[test schema]===-----
export const myAccountFormSchema: DBFormSchema = {
  change_password_header: { label: 'change password?' },
  current_password: { label: 'current password', required: true },
  new_password: { label: 'new password', required: true },
  confirm_password: { label: 'confirm password', required: true }
};
