import React, { useState, useContext } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { boolean, object, string } from 'yup';
import { useSelector } from 'react-redux';
import {
  CheckboxField,
  ContentForRequiredScroll,
  PublicLayout,
  TextField,
  ResourceContent,
  FormWizardPageChangeContext
} from '../components';
import { AnyType, Breadcrumb } from '../interfaces';
import {
  rawSafeHtmlProps,
  htmlProps,
  clientApplicationNameSelector,
  localDateFormat,
  FORM_WIZARD_VALUES,
  ROUTE_PATHS
} from '../utils';
import { AppRootState } from '../redux';

export const baaSchema = object({
  acknowledge_baa: boolean().oneOf([true], 'Must Accept BAA').default(undefined).required(),
  acknowledge_language: boolean().oneOf([true], 'Must Accept Agreement').default(undefined).required(),
  signature: string().required().default(''),
  signature_date: string()
    .required()
    .default(localDateFormat(new Date()) as string)
});

const REGISTRATION_FORM_PAGE_NAMES = FORM_WIZARD_VALUES?.pages?.registration?.names;

export const BAAView = () => {
  const [isBottom, setIsBottom] = useState(false);
  const applicationName = useSelector(clientApplicationNameSelector);

  const onScrollToBottom = () => {
    if (!isBottom) setIsBottom(true);
  };

  const changeFormWizardPage = useContext(FormWizardPageChangeContext);
  const baaLegal = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.resources as AnyType) ?? {})['registration.baa.legal' ?? ''] as AnyType)?.data
  );

  const breadcrumbs: Breadcrumb[] = [
    {
      href: ROUTE_PATHS.registrationWelcome,
      label: 'WELCOME',
      testId: 'welcome'
    },
    {
      onClick: () => changeFormWizardPage(REGISTRATION_FORM_PAGE_NAMES.organization),
      label: 'ORGANIZATION INFORMATION',
      testId: 'organization'
    },
    {
      onClick: () => changeFormWizardPage(REGISTRATION_FORM_PAGE_NAMES.prescriber),
      label: 'PRESCRIBER',
      testId: 'prescriber'
    },
    {
      onClick: () => changeFormWizardPage(REGISTRATION_FORM_PAGE_NAMES.termsAndConditions),
      label: 'TERMS AND CONDITIONS',
      testId: 'terms'
    }
  ];

  return (
    <PublicLayout className='view-container' pageTitle='Business Associate Agreement' breadcrumbs={breadcrumbs}>
      <Row className='justify-content-between'>
        <Col>
          <p>Read the following terms and conditions carefully.</p>
        </Col>
        {applicationName === 'PhyzProviderPortal' ? (
          <Col className='text-indigo text-right'>
            <a
              className='text-indigo'
              href={process.env.PUBLIC_URL + '/documents/BUSINESS ASSOCIATE AGREEMENT-phyz.pdf'}
              target='_blank'
              rel='noreferrer'>
              Download Business Associate Agreement
            </a>
          </Col>
        ) : (
          <Col className='text-indigo text-right'>
            <a
              className='text-indigo'
              href={process.env.PUBLIC_URL + '/documents/BUSINESS ASSOCIATE AGREEMENT.pdf'}
              target='_blank'
              rel='noreferrer'>
              Download Business Associate Agreement
            </a>
          </Col>
        )}
      </Row>

      <ContentForRequiredScroll
        onScrollToBottom={onScrollToBottom}
        className='border border-gray rounded py-3 pl-3 pr-5 fs-2'
        style={{ maxWidth: '1500px', maxHeight: '300px' }}
        data-testid='cmx__baa-content'>
        <p className='mb-0'>
          <span {...htmlProps(baaLegal)} />
        </p>
      </ContentForRequiredScroll>

      <p className='required-text mt-2'>
        You must scroll and read the entirety of the Terms and Conditions to proceed.
      </p>

      <Form.Row className='align-items-center ml-1 py-2'>
        <Form.Group controlId='acknowledge_baa'>
          <CheckboxField
            inline='true'
            name='acknowledge_baa'
            type='checkbox'
            disabled={!isBottom}
            allowNull={false}
            label='By checking this box and typing my signature below, I am acknowledging that I have read, understood, and agree
            to the terms of this Business Associate Agreement. I further represent that I have the authority to enter into this
            Business Associate Agreement on behalf of the provider and to bind the provider to this Agreement.'
            data-testid='cmx__baa-checkbox-agree'
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className='align-items-center ml-1 py-2'>
        <Form.Group controlId='acknowledge_language'>
          <CheckboxField
            inline='true'
            type='checkbox'
            name='acknowledge_language'
            disabled={!isBottom}
            allowNull={false}
            label='The parties agree that this agreement may be electronically signed. The parties agree that the electronic signatures
            appearing on this agreement are the same as handwritten signatures for the purposes of validity, enforceability and admissibility.'
            data-testid='cmx__baa-language-checkbox'
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs={5} controlId='signature'>
          <TextField name='signature' label='Signature' data-testid='cmx__baa-signature' />
        </Form.Group>

        <Form.Group as={Col} xs={3} controlId='signature_date'>
          <TextField plaintext name='signature_date' label='Date of Signature' className='text-justify-center' />
        </Form.Group>
      </Form.Row>
    </PublicLayout>
  );
};
