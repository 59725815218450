import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';

export const LuckyOrange = () => {
  const luckyOrangeKey = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['luckyorange.button'] as AnyType)?.data?.luckyOrangeKey
  );

  useScript(luckyOrangeKey);

  return <></>;
};
const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
