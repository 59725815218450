import React, { PropsWithRef, useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeaheadProps } from 'react-bootstrap-typeahead';
import { Field, FieldRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';
import { displayError, LoadingIndicator } from '.';
import {
  ServiceRequestViewFragment,
  useServiceRequestWithDocumentsInfoLazyQuery,
  useServiceRequestInfoLazyQuery
} from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { SearchInputWithGqlRequestWithDocumentInfo, SearchInputWithGqlDisplay } from './SearchInputWithGql';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { result } from 'lodash';

interface RequestsTypeaheadProps {
  asyncTypeaheadProps?: Partial<AsyncTypeaheadProps<AnyType> & PropsWithRef<AnyType>>;
  display?: SearchInputWithGqlDisplay;
  defaultRequestId?: string;
  onRequestSelect: (request?: ServiceRequestViewFragment) => void;
  placeholder?: string;
  patientId?: number;
  requestIdOptions?: any;
  setRequestIdOptions?: any;
  setIsGISrNeeded?: any;
  isGISrNeeded?: any;
  setEServicesOptions?: any;
}

const renderLabel = (option: ServiceRequestViewFragment): string => {
  if ((option?.sRDrugName === 'inclisiran' && option?.requestStatus === 'O') || option?.requestStatus === 'C') {
    return `${option.id} | ${option.requestType}`;
  } else if (option?.requestStatus === 'O') {
    return `${option.id} | ${option.requestType}`;
  } else return '';
};
export const RequestsTypeahead = ({
  display = SearchInputWithGqlDisplay.underlined,
  defaultRequestId: serviceRequestId = '',
  patientId,
  ...props
}: RequestsTypeaheadProps) => {
  const typeaheadRef = useRef<AnyType>();

  const queryOptions = {
    variables: {
      patientId
    }
  };

  const [defaultRequest, setDefaultRequest] = useState<any>();
  const [isRequestDone, setIsRequestDone] = useState(false);
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? ''));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));

  const [execServiceRequestQuery, serviceRequestResult] = useServiceRequestInfoLazyQuery({});

  // Clear the typehead if the patient changed
  useEffect(() => {
    if (!patientId) {
      props?.setIsGISrNeeded(false);
      typeaheadRef.current?.clear();
    }
  }, [patientId]);

  // A callback used to load the default service request
  const loadDefaultServiceReqest = useCallback(() => {
    if (!patientId || !serviceRequestId || !organizationId || !programId) return;
    if (serviceRequestResult.called) return;

    execServiceRequestQuery({
      variables: {
        organizationId,
        patientId: Number(patientId),
        programId
      }
    });
  }, [patientId, serviceRequestId, organizationId, programId, serviceRequestResult]);

  // Load a default service request when the callback changes
  useEffect(loadDefaultServiceReqest, [loadDefaultServiceReqest]);

  // Set a defaultRequest if a service request was loaded
  useEffect(() => {
    const results = serviceRequestResult?.data?.result;
    if (results) {
      if (results.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setDefaultRequest(
          results.filter((request: any) => {
            return request?.id === serviceRequestId;
          })?.[0]
        );
      }
      setIsRequestDone(true);
      props.onRequestSelect(defaultRequest);
    }
  }, [patientId, serviceRequestId, organizationId, programId, serviceRequestResult]);

  // Trigger the select handler if there is a defaultRequest set
  useEffect(() => {
    defaultRequest && props.onRequestSelect(defaultRequest);
  }, [defaultRequest]);

  // Show the loading indicator if we're supposed to load the defaultRequest
  if (patientId && serviceRequestId && !defaultRequest && !isRequestDone) return <LoadingIndicator />;

  return (
    <SearchInputWithGqlRequestWithDocumentInfo
      asyncTypeaheadProps={{
        ...props.asyncTypeaheadProps,
        defaultSelected: defaultRequest ? [defaultRequest] : props.asyncTypeaheadProps?.defaultSelected || '',
        disabled: !patientId,
        filterBy: (option: ServiceRequestViewFragment, p: AnyType) => {
          const key = p.labelKey && p.labelKey(option);
          return key?.toLowerCase()?.includes(p.text) && option.requestStatus?.toLowerCase() === 'o';
        },
        ref: typeaheadRef
      }}
      display={display}
      labelRenderer={renderLabel}
      queryHook={useServiceRequestInfoLazyQuery}
      queryOptions={queryOptions}
      onItemSelect={props.onRequestSelect}
      placeholder={props.placeholder}
      minLength={0}
      requestIdOptions={props?.requestIdOptions}
      setRequestIdOptions={props?.setRequestIdOptions}
      setIsGISrNeeded={props?.setIsGISrNeeded}
      isGISrNeeded={props?.isGISrNeeded}
      setEServicesOptions={props?.setEServicesOptions}
    />
  );
};

interface RequestsTypeaheadFieldProps extends RequestsTypeaheadProps {
  fieldName?: string;
  label?: string;
  requestIdOptions?: any;
  setRequestIdOptions?: any;
  setIsGISrNeeded?: any;
  isGISrNeeded?: any;
  setIsRequestComplete?: any;
  setEServicesOptions?: any;
}

export const RequestsTypeaheadField = ({ fieldName, label, ...props }: RequestsTypeaheadFieldProps) => {
  return (
    <Field name={fieldName ?? 'patient'}>
      {(fieldRenderProps: FieldRenderProps<AnyType>) => (
        <>
          {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
          <RequestsTypeahead
            {...props}
            asyncTypeaheadProps={
              props?.isGISrNeeded
                ? { ...props.asyncTypeaheadProps, isInvalid: false, isValid: true }
                : {
                    ...props.asyncTypeaheadProps,
                    isInvalid: fieldRenderProps.meta.touched && fieldRenderProps.meta.invalid,
                    isValid: fieldRenderProps.meta.touched && fieldRenderProps.meta.valid,
                    onBlur: () => fieldRenderProps?.input?.onBlur()
                  }
            }
          />
          {displayError(fieldRenderProps)}
        </>
      )}
    </Field>
  );
};
