import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import { AnyObject } from 'final-form';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react';
import { List } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { HcpGrid, HcpModal, HcpUpload } from '.';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  productNameFormatter,
  caseMileStoneValueFormatter,
  dateColumnDefinition,
  featureGridColumnsSelector,
  getFeatureGridColumnProps,
  ROUTE_PATHS,
  viewActionColumnDefinition,
  productNameFormatterForPatientCase,
  OUTBOUND_EMAIL
} from '../utils';
import { ICellRendererParams } from 'ag-grid-community';
import { DocumentUpload } from './DocumentUpload';
import { CaseMileStoneSelectionTable } from './CaseMileStoneSelectionTable';
import { Button } from 'react-bootstrap';
import {
  CaseTransaction,
  CaseMileStone,
  useCaseDetailsQuery,
  useCaseRecordDocumentsQuery,
  useServiceRequestWithDocumentsInfoLazyQuery,
  useServiceRequestWithDocumentsInfoQuery
} from '../graphql';
import InfoIcon from './InfoIcon';

interface DocumentsTableProps {
  documents?: AnyObject[];
  gridColumnFeatureKey?: string;
  serviceRequest?: AnyObject;
  caseDetail?: CaseTransaction[];
  caseMileStones?: CaseMileStone[];
}

interface CaseDocumentsTableProps {
  caseDocuments?: AnyObject[];
  gridColumnFeatureKey?: string;
  patientId?: number;
}
const columnSelector = featureGridColumnsSelector('documents.table');

export const DocumentsTable = ({
  documents,
  gridColumnFeatureKey,
  serviceRequest,
  caseDetail,
  caseMileStones
}: DocumentsTableProps) => {
  const [modalShow, setModalShow] = useState(false);
  // Filter documents array to not show the document which have source as OUTBOUND EMAIL
  documents = documents?.filter((document: AnyObject) => document?.source !== OUTBOUND_EMAIL);
  const caseQueryVars = { caseRecordId: String(caseDetail?.[0]?.caseRecordId) };
  const caseResult = useCaseDetailsQuery({ variables: caseQueryVars });
  const caseRequestDocuments = useCaseRecordDocumentsQuery({ variables: caseQueryVars });
  useEffect(() => {
    if (caseDetail?.[0]?.caseRecordId && !modalShow) {
      caseResult.refetch(caseQueryVars);
      caseRequestDocuments.refetch(caseQueryVars);
    }
  }, [modalShow]);

  let columns = useSelector(columnSelector);
  if (gridColumnFeatureKey) {
    const pageSpecificColumnSelector = featureGridColumnsSelector(gridColumnFeatureKey);
    const pageSpecificColumns = useSelector(pageSpecificColumnSelector);
    columns = pageSpecificColumns ? { ...columns, ...pageSpecificColumns } : columns;
  }
  const informationIcon = (
    <p className='document-upload-content'>Only PDF documents under 10 MB in size are supported</p>
  );
  if (!columns) return null;

  return (
    <>
      <p>
        <i>
          To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
          criteria.
        </i>
        {serviceRequest?.[0]?.requestStatus === 'O' && serviceRequest && (
          <div>
            <DocumentUpload buttonAlign='right' serviceRequest={serviceRequest} buttonName='Upload Document' />
          </div>
        )}
        {/* check if case is open and the open Milestone is not closed */}
        {caseDetail?.[0]?.caseTransactionState === 'False' && caseDetail?.[0]?.caseClosed === false && (
          <div className={`d-flex align-items-left flex-row-reverse`}>
            <div style={{ textAlign: 'center' }}>
              <div>
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={() => {
                    setModalShow(true);
                  }}>
                  Upload Document
                </Button>
              </div>
              <div>
                <HcpModal
                  enableCancel
                  modalProps={{
                    show: modalShow,
                    onHide: () => setModalShow(false)
                  }}
                  header={' Open Case Milestone'}
                  body={
                    <>
                      <CaseMileStoneSelectionTable caseDetails={caseDetail} caseMileStones={caseMileStones} />
                    </>
                  }
                />
              </div>
            </div>
            <div>&nbsp;</div>
            <div style={{ marginTop: '5px' }}>
              <InfoIcon
                arrow='arrow-right'
                bodyData={informationIcon}
                top='-4.9px'
                right='226px'
                width='500px'
                height='60px'
              />
              <br />
            </div>
          </div>
        )}
      </p>
      <HcpGrid rowData={documents ?? []} pagination>
        <AgGridColumn
          field='fileName'
          {...getFeatureGridColumnProps(columns.documentName)}
          comparator={fileNameComparator}
        />
        <AgGridColumn field='documentType' {...getFeatureGridColumnProps(columns.documentType)} />
        <AgGridColumn valueFormatter={productNameFormatter} {...getFeatureGridColumnProps(columns.product)} />
        <AgGridColumn {...dateColumnDefinition('created')} {...getFeatureGridColumnProps(columns.createdDate)} />
        <AgGridColumn {...viewActionColumnDefinition()} />
      </HcpGrid>
    </>
  );
};

export const PatientCaseDocumentsTable = ({
  caseDocuments,
  gridColumnFeatureKey,
  patientId
}: CaseDocumentsTableProps) => {
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const queryVars = {
    patientId: patientId ?? 0,
    organizationId: String(currentUser?.OrganizationId),
    programId: String(currentUser?.ProgramId)
  };
  const resultSR = useServiceRequestWithDocumentsInfoQuery({ variables: queryVars });
  useEffect(() => {
    if (!patientId) return;
    if (resultSR && patientId) {
      resultSR.refetch(queryVars);
    }
  }, [patientId]);
  let columns = useSelector(columnSelector);
  const cases = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['case-journey.manage' ?? ''] as AnyType)?.data?.enabled
  );
  const history = useHistory();
  const cellClick = (params: ICellRendererParams) => {
    history.push(`${ROUTE_PATHS.patients}/${params.data.patientId}/${params.data.caseRecordId}#caseDetail`);
  };
  const linkColor = () => {
    return { color: '#789a3e' };
  };

  if (gridColumnFeatureKey) {
    const pageSpecificColumnSelector = featureGridColumnsSelector(gridColumnFeatureKey);
    const pageSpecificColumns = useSelector(pageSpecificColumnSelector);
    columns = pageSpecificColumns ? { ...columns, ...pageSpecificColumns } : columns;
  }
  if (!columns) return null;

  return (
    <>
      <p>
        <i>
          To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
          criteria.
        </i>
      </p>
      <HcpGrid rowData={caseDocuments ?? []} pagination>
        <AgGridColumn
          field='caseRecordId'
          {...getFeatureGridColumnProps(columns.caseRecordId)}
          onCellClicked={cellClick}
          cellStyle={linkColor}
          hide={!cases}
        />
        <AgGridColumn
          field='caseMileStone'
          {...getFeatureGridColumnProps(columns.caseMileStone)}
          valueFormatter={caseMileStoneValueFormatter}
          hide={!cases}
        />
        <AgGridColumn
          field='fileName'
          {...getFeatureGridColumnProps(columns.documentName)}
          comparator={fileNameComparator}
        />
        <AgGridColumn field='documentType' {...getFeatureGridColumnProps(columns.documentType)} />
        <AgGridColumn field='caseDrug' {...getFeatureGridColumnProps(columns.caseDrug)} hide={!cases} />
        <AgGridColumn
          valueFormatter={productNameFormatterForPatientCase}
          {...getFeatureGridColumnProps(columns.product)}
          hide={cases}
        />
        <AgGridColumn {...dateColumnDefinition('created')} {...getFeatureGridColumnProps(columns.createdDate)} />
        <AgGridColumn {...viewActionColumnDefinition()} />
      </HcpGrid>
    </>
  );
};

const fileNameComparator = (valueA: any, valueB: any) => {
  if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
  return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
};
