import React, { useCallback, useEffect, useState } from 'react';
import { Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Check, ThreeDots, ArrowClockwise } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { API_URL, useCaseThreads, useThreadDetails } from '../api';
import { CaseMileStone, CaseTransaction, useCaseDetailsQuery, useCaseRecordDocumentsQuery } from '../graphql';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import {
  clientApplicationNameSelector,
  extractCaseUrl,
  REFRESH,
  INCOMPLETE_ACTIVE_JOURNEY_STEP,
  ACTIVE_JOURNEY_STEP
} from '../utils';
import './CaseDetailMainSectionJourney.scss';
import { getToken } from './enrollment-module';

interface CaseDetailMainSectionJourneyParams {
  caseId: string;
}

interface CaseDetailMainSectionJourneyInterface {
  reloadCallback: () => void;
  hideEnrollmentInJourney: boolean;
}

type NormalizedTransactions = { [key: string]: CaseTransaction };

export const CaseDetailMainSectionJourney = (props: CaseDetailMainSectionJourneyInterface) => {
  const { caseId } = useParams<CaseDetailMainSectionJourneyParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transactions, setTransactions] = useState<NormalizedTransactions>({});
  const queryVars = { caseRecordId: caseId };
  const caseResult = useCaseDetailsQuery({ variables: queryVars });
  const applicationName = useSelector(clientApplicationNameSelector);
  const caseRecordDocumentsResult = useCaseRecordDocumentsQuery({ variables: queryVars });
  const messageQueryVars = {
    base: API_URL,
    lazy: true
  };
  const caseSecureMessageThreads = useCaseThreads(messageQueryVars);
  const caseSecureMessage = useThreadDetails(messageQueryVars);
  const location = useLocation();
  const hashValueItem = location?.hash;
  const caseData = caseResult.data?.result;
  const casemileStones = caseData?.mileStones ?? [];
  const transactionsData = caseData?.transactions ?? [];
  const milestone: any = {};
  caseData?.mileStones?.forEach((item) => {
    if (item && item['mileStoneCode']) {
      milestone[item['mileStoneCode']] = item;
    }
  });
  const transactionMileStones = transactionsData.map((item: any, i) => {
    if (milestone[item?.caseMileStoneCode]) {
      return milestone[item?.caseMileStoneCode];
    } else return null;
  });
  const nonTransactionMileStones = casemileStones.map((item, i) =>
    item?.mileStoneCode !== transactionsData?.[i]?.caseMileStoneCode ? caseData?.mileStones?.[i] : null
  );

  const dupMileStones = nonTransactionMileStones
    ? [...transactionMileStones, ...nonTransactionMileStones]
    : transactionMileStones;

  const mileStones = dupMileStones.filter((v, i, a) => a.indexOf(v) === i);

  useEffect(() => {
    const t = caseData?.transactions?.reduce((accumulator: NormalizedTransactions, transaction) => {
      if (transaction?.caseMileStoneCode) accumulator[transaction.caseMileStoneCode as string] = transaction;

      return accumulator;
    }, {});

    if (t) setTransactions(t);
  }, [caseData?.transactions]);
  const [spin, setSpin] = React.useState(false);
  const reload = () => {
    setSpin(true);
    setTimeout(() => {
      setSpin(false);
    }, 1000);
    refreshCaseSummary(caseResult, caseId);
    refreshCaseDocument(caseRecordDocumentsResult, caseId);
    props.reloadCallback();
  };

  return (
    <Card className='m-4 cmx__case-detail-main-section-journey'>
      <Card.Body>
        <div style={{ float: 'right' }}>
          <OverlayTrigger placement='top' overlay={<Tooltip id='refresh'>{REFRESH}</Tooltip>}>
            <ArrowClockwise size={20} onClick={reload} className={spin ? 'spin' : ''} />
          </OverlayTrigger>
        </div>
        <Card.Title className='h4 cmx__case-detail-main-section-journey__title'>JOURNEY</Card.Title>
        <Card.Text className='d-flex' style={{ overflow: 'auto', height: '7rem' }}>
          {mileStones?.map((milestone, i) =>
            milestone?.mileStoneCode === 'ENR' && props?.hideEnrollmentInJourney ? null : (
              <Milestone
                key={i}
                milestone={milestone}
                transaction={
                  milestone ? (milestone?.mileStoneCode ? transactions[milestone?.mileStoneCode] : null) : null
                }
              />
            )
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export const refreshCaseSummary = (caseResult: AnyType, caseId: string) => {
  const queryVars = { caseRecordId: caseId };
  caseResult.refetch(queryVars);
};
export const refreshCaseDocument = (caseRecordDocumentsResult: AnyType, caseId: string) => {
  const queryVars = { caseRecordId: caseId };
  caseRecordDocumentsResult.refetch(queryVars);
};
interface MilestoneProps {
  milestone?: CaseMileStone | null | void;
  transaction?: CaseTransaction | null | void;
}

const Milestone = ({ milestone, transaction }: MilestoneProps) => {
  if (!milestone) return null;

  const isActive = !!transaction;
  const isComplete = transaction ? isActive && transaction?.caseTransactionState === 'True' : false;

  // The empty space is intentional to make sure the status always has some text.
  const substatus = transaction
    ? isActive
      ? transaction?.caseTransactionStatus
        ? transaction.caseTransactionStatus
        : 'In Progress'
      : ' '
    : ' ';

  const styles = {
    borderColor: isComplete ? '#48A532' : isActive ? '#007EA5' : '#8f8f8f',
    '--block-border-var': isComplete ? '#48A532' : isActive ? '#007EA5' : '#8f8f8f',
    backgroundColor: !isActive ? '#DADADA' : 'initial',
    '--block-background-var': !isActive ? '#DADADA' : '#ffffff',
    color: !isActive ? '#8f8f8f' : 'initial'
  };

  return (
    <div style={styles} className='d-flex align-items-center cmx__case-detail-main-section-journey__block'>
      <div className='flex-shrink-0 d-flex justify-content-center cmx__case-detail-main-section-journey__block__icon'>
        {isActive && !isComplete && (
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='incomplete_active_journey'>{INCOMPLETE_ACTIVE_JOURNEY_STEP}</Tooltip>}>
            <ThreeDots className='cmx__case-detail-main-section-journey__three-dots' size={20} />
          </OverlayTrigger>
        )}
        {isActive && isComplete && (
          <OverlayTrigger placement='top' overlay={<Tooltip id='active_journey_step'>{ACTIVE_JOURNEY_STEP}</Tooltip>}>
            <Check className='cmx__case-detail-main-section-journey__check' size={20} />
          </OverlayTrigger>
        )}
      </div>
      <div className='fs-9 flex-grow-1 d-flex flex-column m-1'>
        <div className='d-flex fs-4 text-uppercase'>{milestone?.mileStoneName}</div>
        <div className='d-flex cmx__case-detail-main-section-journey__sub-status'>{substatus}</div>
      </div>
    </div>
  );
};
