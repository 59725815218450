import axios from 'axios';
import { AnyType } from '../../interfaces';
import { CHECK_STATUS_API, HCP_EHIPAA_GENDER_MAP, SEND_INVITE_API } from '../../utils';
import { ConsentType, PatientContactInfo } from './types';

export const getInvitePayload = (
  patientInfo: AnyType,
  patientContactInfo: Record<string, PatientContactInfo>,
  channelValue: string,
  channel: string,
  allDrugs: string,
  brand: string
) => {
  const {
    firstName,
    email,
    middleName,
    lastName,
    address: { address1, address2, city, state, zip },
    gender,
    otherGender,
    dob
  } = patientInfo;
  const isOnsiteVerification = channel === ConsentType.ONSITE;
  const payload = {
    patientFirstName: firstName,
    patientMiddleInitial: middleName,
    patientLastName: lastName,
    patientGender: HCP_EHIPAA_GENDER_MAP[gender],
    patientDateOfBirth: dob,
    patientAddress1: address1,
    patientAddress2: address2,
    patientCity: city,
    patientState: state,
    patientZipCode: zip,
    patientEmail: email,
    patientCellNumber: patientContactInfo?.Sms.patient,
    channel,
    channelValue: isOnsiteVerification ? undefined : channelValue,
    consentRequestedForBrand: allDrugs ?? brand,
    prescriberName: '',
    otherGender: otherGender
  };
  return payload;
};

export const getCheckStatusPayload = (patientInfo: AnyType, drug: string, allDrugs?: string) => {
  const {
    firstName,
    email,
    lastName,
    address: { address1, address2, city, state, zip },
    gender,
    dob
  } = patientInfo;
  const payload = {
    patientFirstName: firstName,
    patientLastName: lastName,
    patientGender: gender,
    dob,
    patientAddress1: address1,
    patientAddress2: address2,
    patientCity: city,
    patientState: state,
    patientZipCode: zip,
    patientEmail: email,
    drug: allDrugs ?? drug
  };
  return payload;
};

export const hasConsentOption = (consentType: ConsentType, programId: string) => {
  switch (consentType) {
    case ConsentType.EMAIL:
    case ConsentType.SMS: {
      return true;
    }
    case ConsentType.UPLOAD:
      return false;
    case ConsentType.ONSITE:
      return programId === '127';
    default:
      return false;
  }
};

export const checkConsentStatusByDrug = async (patientInfo: AnyType, drugName: string, allDrugs?: string) => {
  const accessToken = JSON.parse(sessionStorage.getItem('okta-token-storage') as string)?.accessToken?.value ?? '';
  const payload = getCheckStatusPayload(patientInfo, drugName, allDrugs);
  const checkStatusResponse = (
    await axios.post(CHECK_STATUS_API, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  ).data;
  return checkStatusResponse;
};

export const inviteEhipaa = async (
  patientInfo: AnyType,
  consentType: ConsentType,
  patientContactInfo: Record<string, PatientContactInfo>,
  channelValue: string,
  drugName: string,
  allDrugs: string,
  currentChannel?: ConsentType
) => {
  const payload = getInvitePayload(
    patientInfo,
    patientContactInfo,
    channelValue,
    currentChannel ?? consentType,
    allDrugs,
    drugName
  );
  const accessToken = JSON.parse(sessionStorage.getItem('okta-token-storage') as string)?.accessToken?.value ?? '';
  const inviteQueryParam = consentType === ConsentType.ONSITE ? '?getVerificationCode=true' : '';
  const inviteUrl = `${SEND_INVITE_API}${inviteQueryParam}`;

  const inviteResponse = (
    await axios.post(inviteUrl, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  ).data;
  return inviteResponse;
};
