import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { AnyObject, FORM_ERROR } from 'final-form';
import { AnyType } from '../interfaces';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import { API_URL, UploadDocumentModel, useUploadDocument } from '../api';
import { any } from 'prop-types';
import { useCaseDetailsQuery, useCaseRecordDocumentsQuery, useServiceRequestWithDocumentsInfoQuery } from '../graphql';
import { getAcceptFiles } from './HcpUpload';
import InfoIcon from './InfoIcon';

export interface DocumentUploadProps {
  buttonAlign?: 'left' | 'right';
  serviceRequest?: AnyObject;
  buttonName?: string;
  caseDetail?: AnyObject;
}
export const DocumentUpload = ({ buttonAlign, serviceRequest, buttonName, caseDetail }: DocumentUploadProps) => {
  const [fileUploaded, setFileUploaded] = useState<File | any>();
  const hiddenFileInput = useRef<HTMLDivElement | any>(null);
  const programId = useSelector((state: AppRootState) => state.app.config?.programId);
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const srTypes = useSelector(
    (state: AppRootState) => ((state.app?.entities?.features as AnyType) ?? {})['patient.intake.sr.creation'] as AnyType
  );
  const { mutate: uploadDocument } = useUploadDocument({ base: API_URL });
  const [uploadError, setUploadError] = useState<string | any>();
  const queryVars = {
    serviceRequestId: Number(serviceRequest?.[0]?.id ?? 0),
    organizationId: String(organizationId),
    programId: String(programId)
  };
  const resultSR = useServiceRequestWithDocumentsInfoQuery({ variables: queryVars });
  const [refreshServiceDoc, setRefreshServiceDoc] = useState(false);
  useEffect(() => {
    if (!refreshServiceDoc) return;
    if (resultSR && refreshServiceDoc && !caseDetail?.caseRecordId) {
      resultSR.refetch(queryVars);
      setRefreshServiceDoc(false);
    }
  }, [refreshServiceDoc]);

  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const saveFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileUploaded(null);
    //in case you wan to print the file selected
    const target = e?.target as HTMLInputElement;
    const file: File = (target?.files as FileList)[0];
    setUploadError(null);

    if (file) {
      setFileUploaded(file);
      const fileResult = await importFile(file);
      target.value = '';
    }
  };

  const importFile = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const fileResult = reader.result ?? '';
      const fileValidate = getDataType(file, 'application/pdf');
      if (fileValidate && getFileLength(file)) {
        try {
          const payload: UploadDocumentModel = {
            serviceRequestId: caseDetail?.caseRecordId
              ? Number(caseDetail?.caseTransactionId)
              : Number(serviceRequest?.[0]?.id ?? 0),
            entityName: caseDetail?.caseRecordId
              ? caseDetail?.caseTransactionType.replace(/([A-Z])/g, ' $1').trim()
              : srTypes?.data?.SrEntityName,
            documentSource: srTypes?.data?.DocumentSource,
            createdBy: srTypes?.data?.SrCreatedBy,
            documentType: null,
            lookupDataListFunctionName: null,
            document: {
              fileData: fileResult?.toString().replace('data:application/pdf;base64,', '') ?? '',
              fileName: file?.name ?? ''
            }
          };
          const result = await uploadDocument(payload);
          if (result) {
            setRefreshServiceDoc(true);
          }
          if (result.errors && result.errors.length > 0) {
            setRefreshServiceDoc(false);
            return { [FORM_ERROR]: result.errors.map((e) => e.description).join(' ') };
          }
        } catch (ex) {
          setUploadError(ex);
        }
      }
      return reader.result ?? '';
    };
    reader.onerror = function (error) {
      setUploadError(error);
      return null;
    };
  };

  const errors = fileUploaded && (
    <ul className='text-danger fs-2'>
      {(!getDataType(fileUploaded, 'application/pdf') || !getFileLength(fileUploaded)) && (
        <p>Only {getAcceptFiles('.pdf')?.toUpperCase()} documents under 10 MB in size are supported</p>
      )}
      {uploadError && <li> An Error has occurred while uploading the Document</li>}
    </ul>
  );

  const success = fileUploaded &&
    getDataType(fileUploaded, 'application/pdf') &&
    getFileLength(fileUploaded) &&
    !uploadError && (
      <ul className='text-success align-items-center' style={{ marginRight: '1em' }}>
        <p>Successfully Uploaded </p>
      </ul>
    );

  const informationIcon = (
    <p className='document-upload-content'>Only PDF documents under 10 MB in size are supported</p>
  );
  return (
    <>
      <div className={`d-flex align-items-left flex-row-reverse`} style={{ position: 'relative' }}>
        <div style={{ textAlign: 'center' }}>
          <Button className='mb-3 btn btn-secondary btn-sm' onClick={handleClick}>
            {' '}
            {buttonName ?? ''}
          </Button>
          <input
            type='file'
            accept='.pdf'
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            onChange={saveFileSelected}></input>
        </div>
        <div>&nbsp;</div>
        <div style={{ marginTop: '5px', marginRight: '2px' }}>
          {!caseDetail?.caseRecordId && (
            <InfoIcon
              arrow='arrow-right'
              bodyData={informationIcon}
              top='-53px'
              right='205px'
              position='absolute'
              width='fit-content'
              height='60px'
              padding='10px'
            />
          )}
        </div>
      </div>
      <div className={`d-flex align-items-left flex-row-reverse`} style={{ position: 'relative' }}>
        {success}
        {errors}
      </div>
    </>
  );
};

const getDataType = (file: File, fileType: string) => {
  if (file?.type === fileType) {
    return true;
  }
  return false;
};

const getFileLength = (file: File) => {
  if (file?.size > 10e6) {
    return false;
  }
  return true;
};
