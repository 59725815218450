/* Generated by restful-react */

import React from 'react';
import { Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = '1.0';
export interface Addresses {
  AddressType?: string;
  AddressName?: string;
  Rank?: number;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  City?: string;
  State?: string;
  ZIP?: string;
  Active?: string;
  ExternalID?: string;
  Country?: string;
  Comments?: string;
}

export interface Phones {
  PhoneNumber?: string;
  Extension?: string;
  Type?: string;
  Comments?: string;
  Active?: string;
  Rank?: number;
  OKtoLeaveMsg?: string;
  PreferredTime?: string;
  Country?: string;
  CountryCallingCode?: string;
  PreferredCallingRate?: string;
  OKtoSendTextMsg?: string;
}

export interface Consent {
  DrugName?: string;
  ReceivedDate?: string;
  ReceivedSource?: string;
  OptedOut?: string;
  OptedOutDate?: string;
}

export interface Contacts {
  LastName?: string;
  FirstName?: string;
  MiddleName?: string;
  Suffix?: string;
  Prefix?: string;
  Title?: string;
  ContactType?: string;
  Email?: string;
  MasterContactID?: number;
  ExternalID?: string;
  Relationship?: string;
  LegallyAuthRep?: boolean;
  PatientContactType?: string;
}

export interface PatientRequest {
  LastName?: string;
  FirstName?: string;
  MiddleName?: string;
  DOB?: string;
  RefusedToProvideDOB?: string;
  Gender?: string;
  SSN?: string;
  Email?: string;
  ExternalID?: string;
  ConsentReceivedManufacturer?: string;
  ConsentDateManufacturer?: string;
  ConsentExpDateManufacturer?: string;
  ConsentSource?: string;
  ConsentReceivedBy?: string;
  PreferredMethodOfContact?: string;
  PreferredLanguage?: string;
  PreferredLanguageOther?: string;
  GenderOther?: string;
  Addresses?: Addresses[];
  Phones?: Phones[];
  Consent?: Consent[];
  Contacts?: Contacts[];
}

export interface Patient {
  PatientID?: number;
}

export interface Errors {
  code?: number;
  description?: string;
}

export interface PatientResponse {
  Patient?: Patient;
  errors?: Errors[];
}

export type CreatePatientProps = Omit<
  MutateProps<PatientResponse, unknown, void, PatientRequest, void>,
  'path' | 'verb'
>;

export const CreatePatient = (props: CreatePatientProps) => (
  <Mutate<PatientResponse, unknown, void, PatientRequest, void> verb='POST' path={`/patient`} {...props} />
);

export type UseCreatePatientProps = Omit<
  UseMutateProps<PatientResponse, unknown, void, PatientRequest, void>,
  'path' | 'verb'
>;

export const useCreatePatient = (props: UseCreatePatientProps) =>
  useMutate<PatientResponse, unknown, void, PatientRequest, void>('POST', `/patient`, props);
