import FormTemplateRenderProps from '@data-driven-forms/react-form-renderer/dist/cjs/form-template-render-props';
import { FormOptions } from '@data-driven-forms/react-form-renderer/dist/cjs/renderer-context';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useUpdatePassword, useVerifyResetToken, VerifyResetTokenResponse } from '../api';
import {
  AuthLayout,
  HcpFormRenderer,
  HcpFormTemplate,
  HcpRequestStateUI,
  PublicLayout,
  ResourceAuthLayoutHeader,
  ResourceContent
} from '../components';
import image from '../images/img_Hero.jpg';
import { AnyType, GeneralObject } from '../interfaces';
import { AppRootState, navigateToLoginPostReset } from '../redux';
import { clientApplicationNameSelector, getResetPasswordFormSchema, ROUTE_PATHS } from '../utils';

export const ResetPasswordView = () => {
  const { token } = useParams<{ token: string }>();
  const { mutate: verifyResetToken, loading } = useVerifyResetToken({});
  const [verifyTokenResult, setVerifyTokenResult] = useState<VerifyResetTokenResponse>();
  const didCallValidation = verifyTokenResult !== null;
  const isValidToken =
    verifyTokenResult?.tokenStatus?.toLowerCase() === 'recovery' ||
    verifyTokenResult?.tokenStatus?.toLowerCase() === 'password_reset';
  const applicationName = useSelector(clientApplicationNameSelector);
  const isLoginImage = useSelector(
    (state: AppRootState) => ((state.app?.entities?.resources as AnyType) ?? {})['login.image'] as AnyType
  );
  const verifyToken = useCallback(async () => {
    const result = await verifyResetToken({ applicationName: applicationName, resetToken: token });
    setVerifyTokenResult(result);
  }, [token]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const renderErrorMessage = useCallback(() => {
    // NOTE: using a span here since the wrapper uses `p` to wrap the error.
    return (
      <>
        <span className='d-block'>
          <ResourceContent contentKey='reset-password.expired' />
        </span>
        <span className='d-block pt-2'>
          Click <Link to={ROUTE_PATHS.forgotPassword}>HERE</Link> to get a new link and reset your password.
        </span>
      </>
    );
  }, []);

  return (
    <PublicLayout fluid className='authlayout_custom_image'>
      <AuthLayout image={isLoginImage?.data?.url}>
        {isValidToken && (
          <ResourceAuthLayoutHeader contentKey='reset-password.title'>
            <ResourceContent contentKey='reset-password.body' />
          </ResourceAuthLayoutHeader>
        )}

        <HcpRequestStateUI
          isError={didCallValidation && isValidToken === false}
          errorUIEnabled
          errorTitle='RESET PASSWORD LINK EXPIRED'
          errorText={renderErrorMessage()}
          isLoading={loading || !didCallValidation}
          loadingUIEnabled
          loadingText='Verifying token'>
          <ResetPasswordForm token={token} />
        </HcpRequestStateUI>
      </AuthLayout>
    </PublicLayout>
  );
};

interface ResetPasswordFormProps {
  token: string;
}

const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const dispatch = useDispatch();
  const { mutate: updatePassword } = useUpdatePassword({});

  const applicationName = useSelector(clientApplicationNameSelector);

  const onSubmit = async (values: GeneralObject) => {
    const result = await updatePassword({
      applicationName: applicationName,
      resetToken: token,
      newPassword: String(values.new_password)
    });

    if ((result?.errors?.length ?? 0) > 0) return { [FORM_ERROR]: result.errors?.map((e) => e.description).join(' ') };

    dispatch(navigateToLoginPostReset());
  };

  return (
    <HcpFormRenderer
      contentKey='form.reset-password'
      FormTemplate={ResetPasswordTemplate}
      noErrorMessage
      onSubmit={onSubmit}
      schemaMethod={getResetPasswordFormSchema}
    />
  );
};

const ResetPasswordTemplate = (props: FormTemplateRenderProps) => {
  return (
    <HcpFormTemplate
      {...props}
      renderControls={(formProps: FormOptions) => {
        const formState = formProps.getState();
        const { submitError, submitting, hasValidationErrors } = formState;
        return (
          <Row>
            {submitError && <p className='text-danger'>{submitError}</p>}
            <Col xs={12} className='text-center'>
              <Button type='submit' variant='secondary' disabled={submitting || hasValidationErrors}>
                {submitting ? 'SUBMITTING...' : 'SET MY NEW PASSWORD'}
              </Button>
            </Col>
          </Row>
        );
      }}
    />
  );
};
