import React, { useEffect } from 'react';

import { Badge, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Envelope, InfoCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomThemes } from '../utils/custom-themes';
import { AppRootState, getUnreadMessageCount, navigateToMessages } from '../redux';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { AnyType } from '../interfaces';
import { RECENTLY_UPDATED, UNREAD_MESSAGE } from '../utils';

export const MessageNotification = () => {
  const dispatch = useDispatch();
  const { unreadMessagesCount } = useSelector((state: AppRootState) => state.messages);
  const secureMessageEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['secure-message.manage'] as AnyType)?.data?.enabled
  );

  CustomThemes();
  useEffect(() => {
    // NOTE: !0 === true
    if (secureMessageEnabled && unreadMessagesCount === null) {
      dispatch(getUnreadMessageCount());
    }
  }, [unreadMessagesCount, dispatch]);

  return (
    <FeatureGuard contentKey='secure-message.manage'>
      <div className='cmx__nav-msg-btn'>
        <Nav.Item onClick={() => dispatch(navigateToMessages())}>
          <Nav.Link className='cmx__nav-msg-btn_link'>
            <OverlayTrigger placement='left' overlay={<Tooltip id='recently-updated'>{UNREAD_MESSAGE}</Tooltip>}>
              <span>
                <Envelope size={20} />
                <Badge pill variant='warning' className='cmx__nav-msg-btn__count'>
                  {unreadMessagesCount}
                </Badge>
              </span>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
      </div>
    </FeatureGuard>
  );
};
