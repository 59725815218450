import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppRootState, navigateToRequestSubmitted } from '../redux';
import { AnyType } from '../interfaces';
import { getToken } from '../components/enrollment-module';
import { useEnrollment, ENROLLMENT_MODULE_TRIGGER_RULE } from '../api';

import { AnyObject } from 'final-form';
import { callModuleEnrollmentApi, prepareEnollmentPayload } from '.';
import { LoadingIndicator } from '../components';
import { Drug } from '../api/portal-config.generated';
import { parseJsonSourceFileConfigFileContent } from 'typescript';

export const AddEnrollmentServiceRecord = () => {
  const history = useHistory();
  const isUsingEnrollmentModule = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['isEnrollmentUsingModule'] as AnyType)?.data?.enabled
  );
  const careMetxRecordType = String(
    useSelector((state: AppRootState) => state.auth.session?.user?.CareMetxRecordType ?? '')
  );
  const drugList: Drug[] = JSON.parse(sessionStorage.getItem('drugList') ?? '');

  const dispatch = useDispatch();

  const goToPreviousPath = () => {
    sessionStorage.setItem('enrollmentModuleEnrollmentId', '');
    sessionStorage.setItem('enrollmentModulePracticeId', '');
    sessionStorage.setItem('caseId', '');
    sessionStorage.setItem('drugList', '');
    sessionStorage.setItem('locationId', '');
    sessionStorage.setItem('caseIdEnrollment', '');
    if (sessionStorage.getItem('patientIdEnrollment')) {
      dispatch(navigateToRequestSubmitted());
    } else {
      history.goBack();
    }
  };
  try {
    const { mutate: enroll } = useEnrollment({});
    const application = useSelector((state: AppRootState) => state.app?.config?.domainURL);
    const callEnrollmentAPI = async (payload: AnyObject) => {
      try {
        await enroll(payload);
        goToPreviousPath();
      } catch (e) {
        goToPreviousPath();
        throw e?.message;
      }
    };
    const loadAccessToken = useCallback(async () => {
      const token = await getToken(ENROLLMENT_MODULE_TRIGGER_RULE);
      const result = await token.text();
      // eslint-disable-next-line no-console
      console.log('DS result :: ', result);
      try {
        const json = JSON.parse(result);
        loadPayloadData(json.authToken);
        // eslint-disable-next-line no-console
        console.log('DS authToken :: ', JSON.stringify(json));
      } catch (e) {
        throw e?.message;
      }
    }, []);

    const loadPayloadData = useCallback(async (accessToken: string) => {
      if (!accessToken) return;

      const response = await callModuleEnrollmentApi(
        accessToken,
        sessionStorage.getItem('enrollmentModuleEnrollmentId'),
        sessionStorage.getItem('enrollmentModulePracticeId')
      );
      const result = await response.text();

      try {
        const json = JSON.parse(result);
        const drugId = drugList.find((element) => element?.JCode1 === json?.drug?.jCode ?? 0)?.DrugId;
        const caseId = sessionStorage.getItem('caseId') || sessionStorage.getItem('caseIdEnrollment');
        callEnrollmentAPI(
          prepareEnollmentPayload(
            json,
            Number(caseId ?? '0'),
            careMetxRecordType,
            Number(drugId) ?? 0,
            json?.enrollmentDocument,
            isUsingEnrollmentModule
          )
        );
      } catch (e) {
        goToPreviousPath();
        throw e?.message;
      }
    }, []);
    useEffect(() => {
      if (
        sessionStorage.getItem('enrollmentModuleEnrollmentId') &&
        sessionStorage.getItem('enrollmentModulePracticeId')
      ) {
        loadAccessToken();
      }
    }, [loadAccessToken]);
  } catch (e) {
    sessionStorage.setItem('enrollmentModuleEnrollmentId', '');
    sessionStorage.setItem('enrollmentModulePracticeId', '');
    sessionStorage.setItem('caseId', '');
    sessionStorage.setItem('drugList', '');
    sessionStorage.setItem('locationId', '');
    sessionStorage.setItem('patientIdEnrollment', '');
    sessionStorage.setItem('caseIdEnrollment', '');
    history.goBack();
  }
  return (
    <div className='text-center'>
      <LoadingIndicator />
      <p>Please wait as we process your Enrollment</p>
    </div>
  );
};
