import React, { Props } from 'react';

import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { useField, useForm } from 'react-final-form';

import { NpiSearchProviderField, PhoneField, RadioField, SuffixSelect, TextField } from '.';
import { ProviderNpiFragment } from '../graphql';
import { AnyType } from '../interfaces';
import { dataDrivenFormFieldSection } from '../utils/dynamic-forms';
import { RegistrationFormProps } from '../views';

export const AdminInfoForm = (props: RegistrationFormProps) => {
  const { input } = useField('administrator_is_prescriber');
  const form = useForm();
  const npiSearchRef = React.createRef();

  const clearNpiField = () => {
    form.change('administrator_prescriber_npi', null);
    form.change('administrator_is_prescriber', 'no');
    form.change('prescriber_npi', null);
    form.change('prescriber_first_name', '');
    form.change('prescriber_middle_name', '');
    form.change('prescriber_last_name', '');
    form.change('prescriber_state_license', '');
    form.change('prescriber_licensing_state', '');
    form.change('prescriber_tax_id', '');
    (npiSearchRef.current as AnyType)?.clear();
  };

  const onYesClick = () => {
    const values = form.getState().values;
    form.change('administrator_is_prescriber', 'yes');
    form.change('prescriber_first_name', values.administrator_first_name);
    form.change('prescriber_last_name', values.administrator_last_name);
    form.change('prescriber_suffix', values.administrator_suffix);
  };

  const onNpiSelect = (npi: ProviderNpiFragment) => {
    form.change('administrator_prescriber_npi', Number(npi?.npi) || null);
    npi?.firstName && form.change('administrator_first_name', npi?.firstName ?? '');
    npi?.lastName && form.change('administrator_last_name', npi?.lastName ?? '');

    let stateLicense;

    if (npi?.stateLicenses && npi?.stateLicenses?.length > 0) {
      stateLicense = npi.stateLicenses[0];
    }

    form.change('prescriber_npi', Number(npi?.npi) || null);
    npi?.firstName && form.change('prescriber_first_name', npi?.firstName ?? '');
    npi?.middleName && form.change('prescriber_middle_name', npi?.middleName ?? '');
    npi?.lastName && form.change('prescriber_last_name', npi?.lastName ?? '');
    stateLicense?.licenseNumber && form.change('prescriber_state_license', stateLicense?.licenseNumber ?? '');
    stateLicense?.stateCode && form.change('prescriber_licensing_state', stateLicense?.stateCode ?? '');
    npi?.taxId && form.change('prescriber_tax_id', npi?.taxId ?? '');
  };

  const fields = props.formFields;

  if (props.isLoading) {
    return <> </>;
  }

  return (
    <div>
      <Form.Row>
        <p className='section-text'>{fields?.administrator_info_header?.label}</p>
        <span>
          <InfoCircleFill className='ml-4 text-black-50' />
        </span>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={5}
          controlId='administrator_is_prescriber'
          className={'hideField' in fields?.administrator_is_prescriber ? 'd-none' : ''}>
          <Form.Label>{fields?.administrator_is_prescriber?.label}</Form.Label>
          <div>
            <RadioField
              id='yes_prescriber'
              inline='true'
              name='administrator_is_prescriber'
              value='yes'
              checktype='radio'
              label='Yes'
              onChange={onYesClick}
              data-testid='cmx__yes-prescriber'
            />
            <RadioField
              id='no_prescriber'
              inline='true'
              name='administrator_is_prescriber'
              value='no'
              checktype='radio'
              label='No'
              onChange={clearNpiField}
              data-testid='cmx__no-prescriber'
            />
          </div>
        </Form.Group>

        <Form.Group
          as={Col}
          xs={12}
          sm={7}
          className={'hideField' in fields?.administrator_prescriber_npi ? 'd-none' : ''}>
          <NpiSearchProviderField
            asyncTypeaheadProps={{ disabled: input.value === 'no', ref: npiSearchRef }}
            onNpiSelect={onNpiSelect}
            fieldName='administrator_prescriber_npi'
            {...dataDrivenFormFieldSection('administrator_prescriber_npi', fields?.administrator_prescriber_npi)}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          sm={5}
          controlId='administrator_first_name'
          className={'hideField' in fields?.administrator_first_name ? 'd-none' : ''}>
          <TextField
            maxLength={fields?.administrator_first_name?.max}
            {...dataDrivenFormFieldSection('administrator_first_name', fields?.administrator_first_name)}
          />
        </Form.Group>

        <Form.Group
          as={Col}
          sm={5}
          controlId='administrator_last_name'
          className={'hideField' in fields?.administrator_last_name ? 'd-none' : ''}>
          <TextField {...dataDrivenFormFieldSection('administrator_last_name', fields?.administrator_last_name)} />
        </Form.Group>

        <Form.Group
          as={Col}
          sm={2}
          controlId='administrator_suffix'
          className={'hideField' in fields?.administrator_suffix ? 'd-none' : ''}>
          <SuffixSelect {...dataDrivenFormFieldSection('administrator_suffix', fields?.administrator_suffix)} />
        </Form.Group>
      </Form.Row>

      <Form.Row className='form-row-height'>
        <Form.Group
          as={Col}
          sm={5}
          controlId='administrator_phone'
          className={'hideField' in fields?.administrator_phone ? 'd-none' : ''}>
          <PhoneField {...dataDrivenFormFieldSection('administrator_phone', fields?.administrator_phone)} />
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id='administrator_phone_verbiage'>{fields?.administrator_phone_verbiage?.label}</Tooltip>
            }>
            <InfoCircleFill className='ml-4 text-black-50 verbiage-text' />
          </OverlayTrigger>
        </Form.Group>

        <Form.Group
          as={Col}
          sm={5}
          controlId='administrator_fax'
          className={'hideField' in fields?.administrator_fax ? 'd-none' : ''}>
          <PhoneField {...dataDrivenFormFieldSection('administrator_fax', fields?.administrator_fax)} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          sm={6}
          controlId='administrator_email'
          className={'hideField' in fields?.administrator_email ? 'd-none' : ''}>
          <TextField
            maxLength={fields?.administrator_email?.max}
            {...dataDrivenFormFieldSection('administrator_email', fields?.administrator_email)}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          sm={6}
          controlId='administrator_username'
          className={'hideField' in fields?.administrator_username ? 'd-none' : ''}>
          <TextField
            maxLength={fields?.administrator_username?.max}
            {...dataDrivenFormFieldSection('administrator_username', fields?.administrator_username)}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
