import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API_URL, ComposeMessageRequest, useThreadDetails } from '../api';
import { HcpRequestStateUI, MessageDetail, MessageStep, PrivateLayout, ResourceContent } from '../components';
import { AppRootState } from '../redux';
import { clientApplicationNameSelector, ROUTE_PATHS } from '../utils';

export const MessageView = () => {
  const { id: threadId } = useParams<{ id: string }>();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const programId = String(useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId ?? ''));
  const organizationId = String(useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId ?? ''));
  const userName = String(useSelector((state: AppRootState) => state.auth.session?.user?.Login ?? ''));

  const { data: message, ...result } = useThreadDetails({
    base: API_URL,
    lazy: true
  });

  const applicationName = useSelector(clientApplicationNameSelector);

  const loadData = useCallback(() => {
    if (!currentUser || !threadId) return;

    result.refetch({
      queryParams: {
        applicationName: applicationName,
        threadId: Number(threadId)
      }
    });
  }, [currentUser, threadId]);

  const onReplySent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (payload: ComposeMessageRequest) => {
      loadData();
    },
    [loadData]
  );

  useEffect(loadData, [loadData]);

  const isError = result.error || (message?.errors?.length ?? 0) > 0;
  const lastThread =
    message?.messageThread?.messages && message?.messageThread?.messages[message?.messageThread?.messages?.length - 1];
  const patientName =
    (message?.messageThread && message?.messageThread.patientName) ?? (isError ? 'Message Error' : '...');

  const isServiceRequestOpen = message?.messageThread?.status?.toLowerCase() !== 'closed';

  const getPageTitle = (prefix: string) =>
    isError || result.loading || !patientName || !threadId ? '' : `${prefix} ${patientName}`;

  return (
    <ResourceContent
      contentKey='message.title'
      render={({ content: titlePrefix }: { content: string }) => (
        <PrivateLayout
          breadcrumbs={[{ label: '<< back to messages', href: ROUTE_PATHS.messages }]}
          pageTitle={getPageTitle(titlePrefix)}>
          <HcpRequestStateUI
            loadingUIEnabled
            isLoading={result.loading || !message}
            errorUIEnabled
            errorText={message?.errors?.map((e) => e.description).join(' ')}
            errorTitle=''
            isError={isError}
            reloadingEnabled
            reload={loadData}>
            <MessageDetail thread={message?.messageThread ?? null} />
            <hr />
            {message?.messageThread?.messages?.map((t, i) => (
              <MessageStep
                key={i}
                canReply={t === lastThread && isServiceRequestOpen}
                isMessageOpen={t === lastThread}
                replyProps={{
                  onSent: onReplySent
                }}
                thread={message?.messageThread ?? null}
                threadMessage={t}
              />
            ))}
          </HcpRequestStateUI>
        </PrivateLayout>
      )}
    />
  );
};
