import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';

import { FIELD_NAMES, getAddPrescriberSchema, StepNames } from '../..';
import {
  DBFormSchema,
  fieldLocationAddSearchSection,
  fieldPlainTextSection,
  fieldPrescriberAddSearchSection,
  headerSection,
  separatorSection,
  fieldTextSection,
  spacerSection,
  titleSection
} from '../field-helpers';
import { getAddLocationFormSchema } from '../fields';

const { organizationFields, prescriberFields } = FIELD_NAMES;

export const prescriberTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const prescriberTab = schema?.prescriber_tab;

  if (!prescriberTab || prescriberTab?.hideTab) return null;

  return {
    title: prescriberTab?.label ?? 'Prescriber',
    name: StepNames.PRESCRIBER,
    nextStep: prescriberTab?.nextStep ?? nextStep,
    fields: [
      fieldPrescriberAddSearchSection(prescriberFields.id, schema?.[prescriberFields.id], {
        sizes: { md: 8 }
      }),
      spacerSection('prescriber_1', {
        sizes: { sm: 12 }
      }),
      headerSection(schema?.prescribers_information_header?.label, schema?.prescribers_information_header),
      ...addPrescriberFields(schema),
      ...readOnlyPrescriberFields(schema),
      separatorSection('prescriber_1', schema.separator_prescriber_1),
      headerSection(
        schema?.prescribers_practice_information_header?.label,
        schema?.prescribers_practice_information_header
      ),
      fieldPlainTextSection(organizationFields.name, schema?.[organizationFields.name], {
        sizes: { md: 4 }
      }),
      fieldPlainTextSection(organizationFields.npi, schema?.[organizationFields.npi], {
        sizes: { md: 4 }
      }),
      fieldTextSection(organizationFields.taxId, schema?.[organizationFields.taxId], {
        type: 'tax',
        sizes: { md: 4 },
        validate: [
          {
            type: 'tax-id'
          }
        ]
      }),
      spacerSection('prescriber_2', { sizes: { sm: 12 } }),
      fieldLocationAddSearchSection(prescriberFields.locationId, schema?.[prescriberFields.locationId], {
        sizes: { md: 8 }
      }),
      spacerSection('prescriber_3', { sizes: { sm: 12 } }),
      titleSection(schema?.location_header?.label, schema?.location_header, { underlined: true }),
      spacerSection('prescriber_4', { sizes: { sm: 12 } }),
      ...addLocationFields(schema),
      ...readOnlyLocationFields(schema),
      spacerSection('prescriber_5', { sizes: { sm: 12 } }),

      // Office contact Section
      titleSection(schema?.office_contact_header?.label, schema?.office_contact_header, { underlined: true }),
      spacerSection('prescriber_6', { sizes: { sm: 12 } }),
      fieldPlainTextSection(
        organizationFields.officeContactFirstName,
        schema?.[organizationFields.officeContactFirstName],
        {
          sizes: { md: 4 }
        }
      ),
      fieldPlainTextSection(
        organizationFields.officeContactLastName,
        schema?.[organizationFields.officeContactLastName],
        {
          sizes: { md: 4 }
        }
      ),
      fieldPlainTextSection(organizationFields.officeContactPhone, schema?.[organizationFields.officeContactPhone], {
        sizes: { md: 4 }
      }),
      fieldTextSection(organizationFields.officeContactFax, schema?.[organizationFields.officeContactFax], {
        sizes: { md: 4 },
        type: 'tel',
        validate: [
          {
            type: 'phone-conditional',
            compareToField: organizationFields.officeContactPhone
          }
        ]
      }),
      fieldTextSection(organizationFields.officeContactEmail, schema?.[organizationFields.officeContactEmail], {
        sizes: { md: 4 }
      }),
      spacerSection('prescriber_6', { sizes: { sm: 12 } })
    ].filter(Boolean)
  };
};

// -----===[add prescriber]===-----
const addPrescriberFields = (schema: DBFormSchema) =>
  getAddPrescriberSchema(schema).fields.map((field) => {
    const stateConditional =
      field.name === 'prescriber_licensing_state'
        ? { when: 'prescriber_state_license_number', is: (x: string) => !!x }
        : null;
    if (field.name === 'prescribers_information_section' || field.name === 'prescriber_s_information_section') return;
    return {
      ...field,
      condition: [
        {
          when: 'adding_prescriber',
          is: (x: string) => !!x
        },
        {
          when: prescriberFields.id,
          is: (x: string) => !x
        },
        stateConditional
      ].filter(Boolean)
    };
  });

const readOnlyPrescriberFields = (schema: DBFormSchema) => [
  fieldPlainTextSection(prescriberFields.firstName, schema?.[prescriberFields.firstName], {
    sizes: { md: 4 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.lastName, schema?.[prescriberFields.lastName], {
    sizes: { md: 4 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.npi, schema?.[prescriberFields.npi], {
    sizes: { md: 4 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.dashes, schema?.[prescriberFields.dashes], {
    sizes: { md: 1 },
    condition: {
      not: [
        {
          when: prescriberFields.lastName,
          is: (x: string) => !!x
        }
      ]
    }
  }),
  fieldPlainTextSection(prescriberFields.taxId, schema?.[prescriberFields.taxId], {
    type: 'tax',
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.stateLicenseNumber, schema?.[prescriberFields.stateLicenseNumber], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.licensingState, schema?.[prescriberFields.licensingState], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  spacerSection('prescriber_7', { sizes: { sm: 12 } }),
  fieldPlainTextSection(prescriberFields.ptan, schema?.[prescriberFields.ptan], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.dea, schema?.[prescriberFields.dea], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.email, schema?.[prescriberFields.email], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.id,
      is: (x: string) => !!x
    }
  })
];

// -----===[add location]===-----
const addLocationFields = (schema: DBFormSchema) =>
  getAddLocationFormSchema(schema).fields.map((field) => {
    if (field.name === 'locations_information_section') return;
    return {
      ...field,
      condition: [
        {
          when: 'adding_location',
          is: (x: string) => !!x
        },
        {
          when: prescriberFields.locationId,
          is: (x: string) => !x
        }
      ]
    };
  });

const readOnlyLocationFields = (schema: DBFormSchema) => [
  fieldPlainTextSection(prescriberFields.locationAddressFull, schema?.[prescriberFields.locationAddressFull], {
    sizes: { md: 12 },
    required: false,
    condition: {
      when: prescriberFields.locationId,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.locationDashes, schema?.[prescriberFields.locationDashes], {
    sizes: { md: 1 },
    condition: {
      not: [
        {
          when: prescriberFields.locationAddress1,
          is: (x: string) => !!x
        }
      ]
    }
  }),
  fieldPlainTextSection(prescriberFields.locationPhone1, schema?.[prescriberFields.locationPhone1], {
    sizes: { md: 2 },
    required: false,
    condition: {
      when: prescriberFields.locationId,
      is: (x: string) => !!x
    }
  }),
  fieldPlainTextSection(prescriberFields.locationFaxNumber, schema?.[prescriberFields.locationFaxNumber], {
    sizes: { md: 10 },
    required: false,
    condition: {
      when: prescriberFields.locationId,
      is: (x: string) => !!x
    }
  })
];
