import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GettingStarted, PrivateLayout, ResourceContent } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { AppRootState, navigateToServiceRequest } from '../redux';

export const HomeView = () => {
  const dispatch = useDispatch();

  const renderButton = () => {
    const isStartNewRequestTextChangeEnabled = useSelector(
      (state: AppRootState) =>
        (state.app.entities.features?.['StartNewRequestTextEnhancement']?.data as any)?.enabled ?? false
    );

    const buttonNewText = useSelector(
      (state: AppRootState) =>
        (state.app.entities.resources?.['home.getting-started.widget1.action']?.data as any)?.content?.label
    );

    return (
      <>
        <FeatureGuard contentKey='service-request.manage'>
          <Button
            className='text-nowrap'
            variant='secondary'
            size='sm'
            onClick={() => dispatch(navigateToServiceRequest())}
            data-testid='cmx__new-request-button'>
            {isStartNewRequestTextChangeEnabled ? buttonNewText : 'START A NEW REQUEST'}
          </Button>
        </FeatureGuard>
      </>
    );
  };

  return (
    <>
      <FeatureGuard contentKey='home.layout'>
        <PrivateLayout subtoolbarChildren={renderButton()}>
          <h3 className='text-primary'>
            <ResourceContent contentKey='home.title' />
          </h3>
          <GettingStarted />
        </PrivateLayout>
      </FeatureGuard>
      <FeatureGuard contentKey='home-phyz.layout'>
        <PrivateLayout subtoolbarChildren={renderButton()} pageTitle='FRONT DESK'>
          <GettingStarted />
        </PrivateLayout>
      </FeatureGuard>
    </>
  );
};
