import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import SignaturePad from 'react-signature-pad-wrapper';

import { AnyType } from '../../interfaces';
import { getSignatureType, IMAGE_TYPE, SignatureOptions } from '../../utils/signature-utils';
import { DisplayErrorOrHelperTextFeedback } from './Common';
import { HcpInputField, HcpPlainTextField } from './HcpFormFields';

const FIELD_HEIGHT = 60;

const defaultInputStyles = {
  height: 'calc(1.5em + 0.75rem + 2px)',
  lineHeight: 1.5,
  padding: '0.375rem 0'
};

export const HCPSignatureField = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const { label, ...fieldProps } = useFieldApi(props);
  const [selectedOption, setSelectionOption] = useState<SignatureOptions>(SignatureOptions.TEXT);
  const signatureRef = useRef<AnyType>();

  useEffect(() => {
    if (fieldProps.input.value) {
      form.change(fieldProps.input.name, null);
    }
  }, []);

  const clearWriteSignature = () => {
    signatureRef?.current?.clear();
  };

  const onSelection = useCallback((value: SignatureOptions) => {
    form.change(fieldProps.input.name, null);
    setSelectionOption(value);
  }, []);

  const onSignatureChange = () => {
    form.change(fieldProps.input.name, signatureRef?.current?.toDataURL(IMAGE_TYPE));
  };

  return (
    <Col {...props.sizes}>
      <Form.Row>
        <Form.Group as={Col}>{label && <Form.Label className='text-uppercase mr-4'>{label}</Form.Label>}</Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <ToggleButtonGroup name='signature' type='radio' value={selectedOption} onChange={onSelection}>
            <ToggleButton
              className={`text-uppercase btn btn-primary ${
                selectedOption.includes(SignatureOptions.TEXT) ? 'active' : ''
              }`}
              value={SignatureOptions.TEXT}>
              Type Your Signature
            </ToggleButton>
            <ToggleButton
              className={`text-uppercase btn btn-primary ${
                selectedOption.includes(SignatureOptions.IMAGE) ? 'active' : ''
              }`}
              value={SignatureOptions.IMAGE}>
              Write Your Signature
            </ToggleButton>
          </ToggleButtonGroup>
        </Form.Group>
      </Form.Row>

      {selectedOption.includes(SignatureOptions.IMAGE) && (
        <Form.Row>
          <Form.Group as={Col}>
            <SignaturePad
              ref={signatureRef}
              options={{
                minWidth: 1,
                maxWidth: 2,
                backgroundColor: 'rgba(211, 211, 211, 1)',
                onEnd: onSignatureChange
              }}
              height={FIELD_HEIGHT}
            />
            <Button size='sm' className='px-2 py-1' onClick={clearWriteSignature} variant='light'>
              Clear
            </Button>
          </Form.Group>
        </Form.Row>
      )}

      {selectedOption.includes(SignatureOptions.TEXT) && (
        <Form.Row>
          <HcpInputField
            {...(fieldProps.input as AnyType)}
            style={{ height: FIELD_HEIGHT, fontFamily: 'cursive', fontWeight: 'bold', fontSize: 20 }}
          />
        </Form.Row>
      )}
    </Col>
  );
};

export const HCPPlainSignatureDateField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, isreadonly, ...fieldProps } = useFieldApi(props);
  const styles = { ...defaultInputStyles };
  const componentStyles = !isreadonly ? { marginTop: '6rem' } : {};

  return (
    <Form.Group as={Col} {...props.sizes} style={componentStyles}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {input.value}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HCPPlainSignatureField = (props: UseFieldApiConfig) => {
  const { label, input, ...fieldProps } = useFieldApi(props);
  const signatureType = getSignatureType(input.value);
  const styles = { ...defaultInputStyles, marginBottom: '2.5rem' };

  return (
    <>
      {SignatureOptions.IMAGE === signatureType ? (
        <Form.Group as={Col} {...props.sizes}>
          {label && <Form.Label className='text-uppercase mr-4'>{label}</Form.Label>}
          <div {...fieldProps} className='fs-4' style={styles}>
            <img src={input.value} alt='Signature' style={{ height: FIELD_HEIGHT }} />
          </div>
        </Form.Group>
      ) : (
        <HcpPlainTextField {...(props as AnyType)} />
      )}
    </>
  );
};
