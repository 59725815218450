import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTerritoryDetailsLazyQuery } from '../graphql';
import { useScript } from '../hooks/use-script';
import { AnyType } from '../interfaces';
import { AppRootState } from '../redux';
import { CustomThemes } from '../utils/custom-themes';

export const ClickToChat = () => {
  CustomThemes();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const five9Profile = useSelector(
    (state: AppRootState) => ((state.app?.entities?.features as AnyType) ?? {})['five9.profile'] as AnyType
  );
  const [execQuery, { loading, data }] = useTerritoryDetailsLazyQuery({});
  const chatTheme = useSelector(
    (state: AppRootState) => ((state.app?.entities?.features as AnyType) ?? {})['five9.theme'] as AnyType
  );
  const onLoad = useCallback(() => {
    if (five9Profile?.data?.five9_profile_key) {
      (window as AnyType).Five9SocialWidget?.addWidget({
        allowCustomerToControlSoundPlay: false,
        allowUsabilityMenu: false,
        autostart: false,
        fields: {
          name: { value: currentUser?.name, show: false },
          email: { value: currentUser?.email, show: false },
          'Chat.Territory': { value: data?.result?.territoryName, label: 'Territory', show: false }
        },
        hideDuringAfterHours: false,
        playSoundOnMessage: true,
        profiles: five9Profile?.data.five9_profile_key,
        rootUrl: 'https://app.five9.com/consoles/',
        showEmailButton: false,
        showPrintButton: false,
        showProfiles: false,
        surveyOptions: { showComment: false, requireComment: false },
        tenant: 'Caremetx Hub',
        theme: chatTheme?.data.theme,
        title: '',
        type: 'chat',
        useBusinessHours: true
      });
    }
    chatIconCss();
  }, [currentUser, data, loading, five9Profile]);

  const loadScript = useScript({
    id: 'five9chat',
    src: 'https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js',
    callback: onLoad
  });

  useEffect(() => {
    if (!currentUser) return;

    execQuery({
      variables: {
        organizationId: String(currentUser.OrganizationId),
        programId: String(currentUser.ProgramId)
      }
    });
    chatIconCss();
  }, [currentUser, execQuery]);

  useEffect(() => {
    if (loading) return;
    if (!currentUser || !data?.result?.territoryName) return;

    loadScript();
    chatIconCss();
  }, [currentUser, loadScript, data, loading, five9Profile]);

  return <></>;
};

export const chatIconCss = () => {
  const items: any = document.getElementsByClassName('five9-chat-button');
  if (items.length > 0) {
    items[0].className = 'five9-chat-button1';
  }

  const items3: any = document.getElementsByClassName('five9-chat-button');
  if (items3.length > 0) {
    items3[0].className = 'five9-chat-button1';
  }
};
