import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CaseDetailMainSectionJourney, CaseDetailMainSectionTabs, HcpModal } from '../components';
import {
  GRAPHQL_DEFAULT_CACHE,
  PatientInsurance,
  useCaseDetailsQuery,
  useCaseRecordsQuery,
  usePatientInsuranceQuery,
  usePrescriptionByIdQuery
} from '../graphql';
import { Button } from 'react-bootstrap';
import { EnrollmentModuleView } from '../views';
import { Drug } from '../api/portal-config.generated';
import { useTenancyDrugListSelector } from '../redux/selectors';
import { AppRootState } from '../redux';
import { AnyType } from '../interfaces';
import { AnyObject } from 'final-form';

interface CaseDetailMainSectionParams {
  caseId: string;
  id: string;
}

export const CaseDetailMainSection = () => {
  const [modalShow, setModalShow] = useState(false);
  const { caseId, id } = useParams<CaseDetailMainSectionParams>();
  sessionStorage.setItem('caseId', caseId);
  sessionStorage.setItem('patientIdEnrollment', id);
  sessionStorage.setItem('caseIdEnrollment', caseId);
  const caseResult = useCaseDetailsQuery({ variables: { caseRecordId: caseId } });
  const caseData = caseResult.data?.result;
  const caseTransactions = caseData?.transactions ?? [];
  const checkPatientEnrollment = caseTransactions?.find((element) => element?.caseMileStoneCode === 'ENR')
    ? true
    : false;
  const drugName = caseTransactions[0]?.caseDrugName ?? '';
  const drugDescription = caseTransactions[0]?.caseDrugDescription ?? '';
  const newDrugList: Drug[] = useTenancyDrugListSelector();
  sessionStorage.setItem('drugList', JSON.stringify(newDrugList));
  const getRedirectURL = (tab: string) => {
    const path = window?.location?.pathname;
    return path.split('#')[0].concat(tab);
  };
  const getRedirectEnrollmentURL = (tab: string) => {
    const path = window?.location?.pathname;
    return path.split('/')[0].concat(tab);
  };
  const enrollmentEnabledDrugs =
    useSelector(
      (state: AppRootState) =>
        (((state.app?.entities?.features as AnyType) ?? {})['drugbased-enrollmentModule.button' ?? ''] as AnyType)?.data
    ) ?? [];
  const isEnrollmentDrugEnabled = enrollmentEnabledDrugs?.enable?.find(
    (element: AnyObject) => element?.toLowerCase() === drugName?.toLowerCase()
  );
  const orgDetail = useSelector((state: AppRootState) => state.auth?.session?.orgDetails);
  const [updateContent, setUpdateContent] = useState(0);
  const prescriptionIntakes = (caseData?.prescriptionIntakes ?? [])[0];
  const queryVariables = {
    organizationId: useSelector((state: AppRootState) => state.auth.session?.user?.OrganizationId) || '',
    programId: useSelector((state: AppRootState) => state.auth.session?.user?.ProgramId) || '',
    patientId: id,
    userName: useSelector((state: AppRootState) => state.auth.session?.user?.Login) || ''
  };

  const { data: patientInsuranceResult, error: patientInsuranceFetchError } = usePatientInsuranceQuery({
    variables: { patientId: id },
    fetchPolicy: GRAPHQL_DEFAULT_CACHE
  });

  const prescriptionDetailsResult = usePrescriptionByIdQuery({
    variables: { prescriptionId: prescriptionIntakes?.prescriptionId?.toString() || '' }
  });
  const prescriptionDetails = prescriptionDetailsResult?.data?.prescriptionById;

  const getPatientInsuranceDetails = (): Array<PatientInsurance> | undefined => {
    if (patientInsuranceFetchError || !patientInsuranceResult?.result) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return patientInsuranceResult?.result;
  };

  const result = useCaseRecordsQuery({ variables: { ...queryVariables }, fetchPolicy: GRAPHQL_DEFAULT_CACHE });
  const data = result?.data?.result;
  const patientCaseRecord = data?.find((d) => d?.caseRecordId.toString() === caseId);
  const getLocation = () => {
    if (orgDetail && orgDetail.organizationAddresses && orgDetail.organizationAddresses.length > 0) {
      return orgDetail.organizationAddresses[0];
    } else {
      return undefined;
    }
  };
  const state = {
    patientId: id,
    drug: newDrugList?.find((d) => d.DrugBrandName?.toLowerCase() === drugName.toLowerCase()),
    prescriber: {
      id: null,
      npi: patientCaseRecord?.providerNpi,
      firstName: patientCaseRecord?.providerFirstName,
      lastName: patientCaseRecord?.providerLastName,
      details: {
        id: null,
        taxId: null
      },
      stateLicenses: {
        id: null,
        stateLicenseNumber: '',
        stateIssuer: null
      }
    },
    insurance: getPatientInsuranceDetails(),
    location: getLocation() || undefined,
    prescription: prescriptionDetails,
    redirectUrl: {
      cancelUrl: getRedirectURL(''),
      finishUrl: getRedirectEnrollmentURL('/enrollmentSR'),
      goBackUrl: getRedirectURL('')
    }
  };
  const reloaded = () => {
    setUpdateContent(updateContent + 1);
    refreshCaseRecords(result, queryVariables);
    refreshCaseDetails(caseResult, caseId);
  };
  return (
    <div className='flex-grow-1 flex-column overflow-auto'>
      <div className='m-4 d-flex justify-content-between'>
        <div className='h5'>
          CASE #: {caseId} - {drugDescription}
        </div>
        {patientCaseRecord && !checkPatientEnrollment && !patientCaseRecord?.caseClosed && isEnrollmentDrugEnabled && (
          <Button
            variant='secondary'
            size='sm'
            onClick={() => {
              setModalShow(true);
            }}>
            Start an Enrollment Request
          </Button>
        )}
      </div>
      <CaseDetailMainSectionJourney
        reloadCallback={reloaded}
        hideEnrollmentInJourney={!checkPatientEnrollment || false}
      />
      <CaseDetailMainSectionTabs updateContent={updateContent} />
      <div>
        <HcpModal
          enableCancel
          modalProps={{ show: modalShow, onHide: () => setModalShow(false), className: 'enrollmentModuleContainer' }}
          body={<EnrollmentModuleView {...state} />}
        />
      </div>
    </div>
  );
};

export const refreshCaseDetails = (caseResult: AnyType, caseId: string) => {
  const queryVars = { caseRecordId: caseId };
  caseResult.refetch(queryVars);
};

export const refreshCaseRecords = (caseResult: AnyType, variables: any) => {
  const queryVars = { ...variables };
  caseResult.refetch(queryVars);
};
