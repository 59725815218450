/* Generated by restful-react */

import React from 'react';
import { Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = '1.0';
export interface Registration {
  PracticeInfo?: {
    ProgramId?: number;
    PracticeName?: string;
    Speciality?: string;
    TaxId?: string;
    NPI?: string;
    AddressLine1?: string;
    AddressLine2?: string;
    City?: string;
    State?: string;
    Zip?: string;
    PhoneNumber?: string;
    FaxNumber?: string;
  };
  AdministratorInfo?: {
    IsPhysician?: number;
    NPI?: string;
    FirstName?: string;
    LastName?: string;
    Suffix?: string;
    PhoneNumber?: string;
    FaxNumber?: string;
    EMail?: string;
    UserName?: string;
  };
  PhysicianInfo?: {
    NPI?: string;
    FirstName?: string;
    LastName?: string;
    MiddleName?: string;
    TaxID?: string;
    StateLicenseNumber?: string;
    LicensingState?: string;
    PTAN?: string;
    DEA?: string;
    EMAIL?: string;
    HIN?: string;
  };
  TermsAndConditionsAcknowledgement?: number;
  BAAAcknowledgement?: number;
  BAALanguageAcknowledgement?: number;
  BAASignatureText?: string;
  BAASignatureDate?: Date;
}

export interface RegistrationResponse {
  errors?: Error[] | null;
  RegistationRecordID?: number | null;
}

export interface NewRegistrationResponse {
  errors?: Error[] | null;
  isSuccess?: boolean | null;
}

export interface Error {
  code?: string | null;
  description?: string | null;
}

export type RegistrationProps = Omit<
  MutateProps<RegistrationResponse, void, void, Registration, void>,
  'path' | 'verb'
>;

export const Registration = (props: RegistrationProps) => (
  <Mutate<RegistrationResponse, void, void, Registration, void>
    verb='POST'
    path={`/registrations/registrations`}
    {...props}
  />
);

export type UseRegistrationProps = Omit<
  UseMutateProps<RegistrationResponse, void, void, Registration, void>,
  'path' | 'verb'
>;

export const useRegistration = (props: UseRegistrationProps) =>
  useMutate<RegistrationResponse, void, void, Registration, void>('POST', `/registrations/registrations`, props);

export type NewRegistrationProps = Omit<
  MutateProps<NewRegistrationResponse, void, void, Registration, void>,
  'path' | 'verb'
>;

export const NewRegistration = (props: NewRegistrationProps) => (
  <Mutate<NewRegistrationResponse, void, void, Registration, void> verb='POST' path={`/api/register`} {...props} />
);

export type UseNewRegistrationProps = Omit<
  UseMutateProps<NewRegistrationResponse, void, void, Registration, void>,
  'path' | 'verb'
>;

export const useNewRegistration = (props: UseNewRegistrationProps) =>
  useMutate<NewRegistrationResponse, void, void, Registration, void>('POST', `/api/register`, props);
