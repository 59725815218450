import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { FIELD_NAMES } from '../../field-names';
import { DBFormSchema, fieldTextSection, headerSection, spacerSection } from '..';
import { fieldSelectStatesSection } from '../field-helpers';

const { prescriberFields } = FIELD_NAMES;

export const getAddLocationFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'Add Location Form',
  fields: [
    headerSection(schema?.location_information_header?.label, schema?.location_information_header),
    fieldTextSection(prescriberFields.locationAddress1, schema?.[prescriberFields.locationAddress1], {
      sizes: { xs: 12, md: 7 },
      validate: [
        {
          type: 'address',
          label: 'Address 1'
        }
      ]
    }),
    spacerSection(1, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.locationAddress2, schema?.[prescriberFields.locationAddress2], {
      sizes: { xs: 12, md: 7 },
      validate: [
        {
          type: 'address',
          label: 'Address 2'
        }
      ]
    }),
    spacerSection(2, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.locationAddressZip, schema?.[prescriberFields.locationAddressZip], {
      sizes: { xs: 12, md: 2 },
      type: 'zip',
      validate: [
        {
          type: 'zip'
        }
      ]
    }),
    fieldTextSection(prescriberFields.locationAddressCity, schema?.[prescriberFields.locationAddressCity], {
      sizes: { xs: 12, md: 3 },
      validate: [
        {
          type: 'city'
        }
      ]
    }),
    fieldSelectStatesSection(prescriberFields.locationAddressState, schema?.[prescriberFields.locationAddressState], {
      sizes: { xs: 12, md: 2 }
    }),
    spacerSection(3, { sizes: { sm: 12 } }),
    fieldTextSection(prescriberFields.locationPhone1, schema?.[prescriberFields.locationPhone1], {
      sizes: { xs: 12, md: 2 },
      type: 'tel',
      validate: [
        {
          type: 'phone'
        }
      ]
    }),
    fieldTextSection(prescriberFields.locationFaxNumber, schema?.[prescriberFields.locationFaxNumber], {
      sizes: { xs: 12, md: 2 },
      type: 'tel',
      validate: [
        {
          type: 'phone'
        }
      ]
    }),
    spacerSection(4, { sizes: { sm: 12 } })
  ].filter(Boolean)
});

// -----===[test schema]===-----

export const addLocationFormSchema: DBFormSchema = {
  location_information_header: { label: 'location information' },
  prescriber_location_address_1: { label: 'address 1', required: true },
  prescriber_location_address_2: { label: 'address 2' },
  prescriber_location_zip: { label: 'zip code', required: true },
  prescriber_location_city: { label: 'city', required: true },
  prescriber_location_state: { label: 'state', required: true },
  prescriber_location_phone_1: { label: 'phone number', required: true },
  prescriber_location_fax_number: { label: 'fax number', required: true }
};
