import React, { useCallback } from 'react';
import { Alert, Form, Button, Row, Col } from 'react-bootstrap';
import { FormRenderProps } from 'react-final-form';
import { FormWizardPageChangeContext } from './form-wizard-context';
import { confirmWithUserAndPrepForBackNavigation } from './form-wizard-utils';

interface FormWizardContentProps extends FormRenderProps {
  actionsClassName?: string;
  getPageNumberByName: (pageName: string | undefined) => number;
  isLastPage: boolean;
  isBackVisible: boolean;
  onBackClick: () => unknown;
  onCancelClick?: () => unknown;
  onPageChange: (index: number) => void;
  submitText?: string;
}

export const FormWizardContent = ({ handleSubmit, ...props }: FormWizardContentProps) => {
  const navigateToPreviousPage = useCallback(
    (pageName: string) => {
      const pageNumber = props.getPageNumberByName(pageName);

      const navigationConfirmed = confirmWithUserAndPrepForBackNavigation(props.form);
      if (!navigationConfirmed) return;

      props.onPageChange(pageNumber);
    },
    [props.onPageChange, props.form]
  );

  return (
    <Form onSubmit={async (e) => await handleSubmit(e)} noValidate>
      <FormWizardPageChangeContext.Provider value={navigateToPreviousPage}>
        {props.children}
      </FormWizardPageChangeContext.Provider>

      <div className={`mb-5 ${props.actionsClassName}`.trim()}>
        {props.submitError && (
          <Row>
            <Col>
              <Alert variant='danger'>
                <Alert.Heading>Please address the following form errors:</Alert.Heading>
                <span>{props.submitError}</span>
              </Alert>
            </Col>
          </Row>
        )}

        <Row xs={1} md={3}>
          <Col className='pb-1' xs={{ order: 2 }} md={{ order: 1 }}>
            {props.isBackVisible && (
              <Button
                type='button'
                variant='outline-secondary'
                className='w-100 w-lg-auto'
                disabled={props.submitting}
                onClick={props.onBackClick}
                data-testid='cmx__back-button'>
                BACK
              </Button>
            )}
          </Col>

          <Col className='text-lg-center pb-1' xs={{ order: 'last' }} md={{ order: 2 }}>
            {props.onCancelClick && (
              <Button
                type='reset'
                variant='transparent'
                className='text-danger w-100 w-lg-auto'
                disabled={props.submitting}
                onClick={props.onCancelClick}
                data-testid='cmx__cancel-button'>
                CANCEL
              </Button>
            )}
          </Col>

          <Col className='text-lg-right pb-1' xs={{ order: 1 }} md={{ order: 3 }}>
            {props.isLastPage && (
              <Button
                type='submit'
                className='w-100 w-lg-auto'
                variant='secondary'
                disabled={props.submitting || props.hasValidationErrors}
                data-testid='cmx__submit-button'>
                {props.submitText ?? 'SUBMIT'}
              </Button>
            )}

            {!props.isLastPage && (
              <Button
                type='submit'
                className='w-100 w-lg-auto'
                variant='secondary'
                disabled={props.submitting}
                data-testid='cmx__next-button'>
                NEXT
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};
