import { AnyObject } from 'final-form';
import flagsmith from 'flagsmith';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { getFilePayload, getFilesPayload } from '../';
import {
  PortalDiagnosis,
  PortalDrug,
  PortalEnrollmentRequest,
  PortalInsurance,
  TreatingSite
} from '../../api/interface-portal';
import {
  Caregiver,
  CPTCode,
  Facility,
  Other,
  Patient,
  PatientAttestation,
  Physician,
  PrescriberAttestation,
  Prescription,
  Provider,
  Signature
} from '../../api/portal.generated';
import { FilePreview, SelectOption } from '../../interfaces';
import { AuthUser } from '../../redux';
import { GetTimeTracker } from '../../redux/helpers';
import { buildPlaceOfService, compareAndGetNonHiddenField, DBFormSchema } from '../dynamic-forms';
import { FIELD_NAMES } from '../field-names';
import { getSignatureType, SignatureEntityType, SignatureOptions, SignaturePurpose } from '../signature-utils';

const {
  clinicalFields,
  copayFields,
  facilityFields,
  insuranceFields,
  organizationFields,
  patientAttestationFields,
  patientFields,
  prescriberAttestationFields,
  prescriberFields,
  prescriptionFields,
  caregiverFields,
  pharmacyFields,
  treatmentSiteFields
} = FIELD_NAMES;

export const getEnrollmentPayload = (
  values: AnyObject,
  patientId: string,
  enrollmentType: string,
  portalName: string,
  user?: AuthUser,
  schema?: DBFormSchema,
  drug?: string
): PortalEnrollmentRequest => {
  return {
    userName: String(user?.Login),
    enrollmentType: getEnrollmentType(enrollmentType, portalName),
    enrollmentSubType: getEnrollmentSubType(enrollmentType),
    patient: buildPatient(values, +patientId),
    insurance: buildInsurance(values, schema),
    physicians: buildPhysician(values, user?.ProgramId, user?.OrganizationId),
    diagnosis: buildDiagnosis(values),
    administrationInformation: values[facilityFields.administrationInfo],
    treatingSite: buildTreatingSite(values),
    other: buildOther(values, portalName),
    facility: buildFacility(values),
    patientAttestation: buildPatientAttestation(values),
    prescriberAttestation: buildPrescriberAttestation(values),
    cpt: buildCPTCodes(values),
    drugInfo: buildDrugInfo(values),
    drugId: Number(drug ?? 0),
    pos: Number(values[clinicalFields.placeOfService]?.split('-')?.[0]),
    placeOfService: buildPlaceOfService(values[clinicalFields.placeOfService], portalName),
    nextScheduledDate: values[clinicalFields.nextTreatmentDate],
    prescription: buildPrescriptionData(values),
    enrollmentSignatures: buildSignatures(values),
    provider: buildProviderData(values),
    isTamTracker: GetTimeTracker()
  };
};
// function GetTimeTracker() {
//   const tamTracker: AnyType = useSelector((state: AppRootState) => state?.app?.entities?.features?.tamTracker?.data);
//   {
//     return tamTracker;
//   }
// }

function buildTreatingSite(values: AnyObject): TreatingSite {
  return {
    referMyPatient: values[treatmentSiteFields.referMyPatient]?.[0],
    sendUpdateCommunications: values[treatmentSiteFields.sendUpdatesAndCommnunication],
    name: values[treatmentSiteFields.siteName],
    phone: values[treatmentSiteFields.phone],
    fax: values[treatmentSiteFields.fax],
    email: values[treatmentSiteFields.email],
    npi: values[treatmentSiteFields.npiNumber],
    taxId: values[treatmentSiteFields.taxId],
    contactName: values[treatmentSiteFields.contactName]
  };
}

function buildOther(values: AnyObject, portalName: string): Other {
  return {
    patientCopayId: values[copayFields.cardId],
    groupNumber: values[copayFields.groupNumber],
    preferredMethodOfPayment:
      portalName == 'novartisproviderportal' ? 'CHECK' : values[copayFields.preferredPaymentMethod],
    additionalDocuments: values[copayFields.additionalDocumentsUpload]?.map((file: FilePreview) =>
      getFilePayload(file)
    ),
    organizationEmail: values[copayFields.emailId],
    checkPayableTo: values[copayFields.checkPayableTo],
    copayProviderPaymentRemittanceAddress: values[copayFields.copayProviderPaymentRemittanceAddress],
    copayCity: values[copayFields.copayReimbursementCity] ?? values[copayFields.copayCity],
    copayState: values[copayFields.copayReimbursementState] ?? values[copayFields.copayState],
    copayZipCode: values[copayFields.copayReimbursementZip] ?? values[copayFields.copayZipCode],
    copayFaxNumber: values[copayFields.copayFaxNumber],
    copayNpiNumber: values[copayFields.copayNpiNumber],
    copayTaxIdNumber: values[copayFields.copayTaxIdNumber],
    copayMedicationName: values[copayFields.copayMedicationName],
    copayRegistrationRepayment: values[copayFields.copayRegistrationRepayment],
    copayPaymentReimbursementAddress: values[copayFields.copayPaymentReimbursementAddress],
    copaySuite: values[copayFields.copaySuite],
    copayPrimaryPayerName: values[copayFields.copayPrimaryPayerName],
    copayDateOfService: values[copayFields.copayDateOfService],
    copayPatientCostShareEOD: values[copayFields.copayPatientCostShareEOD],
    copayReimbursementAmountRequested: values[copayFields.copayReimbursementAmountRequested],
    copayRequestorFirstname: values[copayFields.copayReqFirstname],
    copayRequestorLastName: values[copayFields.copayRequestorLastName],
    copayRequestorPhoneNumber: values[copayFields.copayRequestorPhoneNumber],
    copayRequestorEmailAddress: values[copayFields.copayRequestorEmail]
  };
}

function buildPatient(values: AnyObject, patientId: number): Patient {
  return {
    patientId,
    firstName: values[patientFields.firstName],
    middleName: values[patientFields.middleName],
    lastName: values[patientFields.lastName],
    last4SSN: values[patientFields.last4SSN],
    preferredPhone: values[patientFields.preferredPhone],
    dob: moment.utc(values[patientFields.dob]).format(),
    gender: values[patientFields.gender] === 'O' ? values[patientFields?.otherGender] : values[patientFields.gender],
    preferredLanguage: values[patientFields.preferredLanguage],
    //preferredPhone: values[patientFields.preferredPhone],
    otherLanguage: values[patientFields.otherLanguage],
    email: values[patientFields.editableEmail] || values[patientFields.email],
    patientPhoneNumbers: [
      values[patientFields.homeNumber] && {
        patientPhoneNumberId: Number(values[patientFields.homeNumberId]),
        phoneType: patientFields.homeNumberType,
        phoneNumber: values[patientFields.homeNumber].replace(/[^\d]/g, ''),
        okToLeaveMessage: !!values[patientFields.messageOption]?.find((option: string) => option === 'home')
      },
      values[patientFields.mobileNumber] && {
        patientPhoneNumberId: Number(values[patientFields.mobileNumberId]),
        phoneType: patientFields.mobileNumberType,
        phoneNumber: values[patientFields.mobileNumber].replace(/[^\d]/g, ''),
        okToLeaveMessage: !!values[patientFields.messageOption]?.find((option: string) => option === 'mobile')
      }
    ].filter((x) => !!x),
    patientAddressId: values[patientFields.addressId],
    address1: values[patientFields.address1],
    address2: values[patientFields.address2],
    zip: values[patientFields.zip],
    city: values[patientFields.city],
    state: values[patientFields.state],
    hasCaregiver: values[patientFields.hasCaregiver]?.[0] === 'yes',
    caregiver: buildCaregiver(values)
  };
}

function buildInsurance(values: AnyObject, schema?: DBFormSchema): PortalInsurance {
  const seconaryPayerOrPlanName = compareAndGetNonHiddenField(
    insuranceFields.secondaryPlanPayerName,
    insuranceFields.secondaryPlanName,
    schema
  );

  const insuranceInfoBillingPreferenceValue = flagsmith.hasFeature('insurance_billing_preference_v2')
    ? values[insuranceFields.insuranceInfoBillingPreference]
    : null;

  return {
    hasPatientInsurance: values[patientFields.hasInsurance] === 'yes',
    insuranceInfoBillingPreference: insuranceInfoBillingPreferenceValue,
    medicarePartB:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'medicare_part_b') ??
      false,
    medicarePartD:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'medicare_part_d') ??
      false,
    medicareAdvantage:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'medicare_advantage') ??
      false,
    medicaid:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'medicaid') ?? false,
    military:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'military') ?? false,
    privateInsurance:
      !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'private') ?? false,
    other: !!values[patientFields.insurances]?.find((insurance: SelectOption) => insurance.value === 'other') ?? false,
    otherInsurance: values[patientFields.otherInsurance],
    medicareBeneficiaryId: values[insuranceFields.medicareBeneficiaryIDNumber],
    last4SSN: values[insuranceFields.last4SSN],
    medicaidPending: values[insuranceFields.medicaidPending],
    patientInsurances: [
      {
        patientInsuranceId: null,
        insuranceType: 'primary',
        medicalCardHolderId: values[insuranceFields.primaryPlanCardholderId],
        medicalGroup: values[insuranceFields.primaryPlanGroupNumber],
        medicalPhoneNumber: values[insuranceFields.primaryPlanPhoneNumber],
        medicalPlanName:
          values[insuranceFields.primaryPlanName] ?? values[insuranceFields?.primaryPlanPayerName]?.[0]?.label,
        pharmacyCardholderId: values[insuranceFields.pharmacyCardholderId],
        pharmacyPlanName: values[insuranceFields.pharmacyName],
        pharmacyPhoneNumber: values[insuranceFields.pharmacyPhoneNumber],
        pharmacyBINNumber: values[insuranceFields.pharmacyRxBinIin],
        policyHolderName: values[insuranceFields.primaryPolicyHolderName],
        pharmacyGroup: values[insuranceFields.pharmacyRxGroupNumber],
        pharmacyPCN: values[insuranceFields.pharmacyRxPcn],
        payerId:
          getPayerID(values, insuranceFields.primaryPlanPayerName)?.[0] || values[insuranceFields.primaryPlanPayerId],
        payerIds: getPayerID(values, insuranceFields.primaryPlanPayerName) || [
          values[insuranceFields.primaryPlanPayerId]
        ],
        medePayerId: values[insuranceFields.primaryPlanMedePayerId],
        medPAPayerId: values[insuranceFields.primaryPlanMedPAPayerId],
        isPatientPrimaryCardholder: values[insuranceFields.primaryIsCardholder] === 'Y',
        subscriberFirstName: values[insuranceFields.primarySubscriberFirstName],
        subscriberLastName: values[insuranceFields.primarySubscriberLastName],
        subscriberDateBirth: values[insuranceFields.primarySubscriberDob],
        subscriberGender: values[insuranceFields.primarySubscriberGender],

        employer: values[insuranceFields.primaryEmployer],
        subscriberRelationshipToCardholder: values[insuranceFields.primarySubscriberRelationship]
      },
      {
        patientInsuranceId: null,
        insuranceType: 'secondary',
        medicalCardHolderId: seconaryPayerOrPlanName
          ? values[
              !isEmpty(values[seconaryPayerOrPlanName])
                ? insuranceFields.secondaryPlanCardholderId
                : insuranceFields.secondaryPlanCardholderIdOpt
            ]
          : null,
        medicalGroup:
          values[
            insuranceFields.secondaryPlanName
              ? insuranceFields.secondaryPlanGroupNumber
              : insuranceFields.secondaryPlanGroupNumberOpt
          ],
        medicalPhoneNumber: values[insuranceFields.secondaryPlanPhoneNumber],
        medicalPlanName:
          values[insuranceFields.secondaryPlanName] ?? values[insuranceFields?.secondaryPlanPayerName]?.[0]?.label,
        pharmacyCardholderId: values[insuranceFields.pharmacyCardholderId],
        pharmacyPlanName: values[insuranceFields.pharmacyName],
        pharmacyPhoneNumber: values[insuranceFields.pharmacyPhoneNumber],
        pharmacyBINNumber: values[insuranceFields.pharmacyRxBinIin],
        policyHolderName: values[insuranceFields.secondaryPolicyHolderName],
        pharmacyGroup: values[insuranceFields.pharmacyRxGroupNumber],
        pharmacyPCN: values[insuranceFields.pharmacyRxPcn],
        payerId:
          getPayerID(values, insuranceFields.secondaryPlanPayerName)?.[0] ||
          values[insuranceFields.secondaryPlanPayerId],
        payerIds: getPayerID(values, insuranceFields.secondaryPlanPayerName) || [
          values[insuranceFields.secondaryPlanPayerId]
        ],
        medePayerId: values[insuranceFields.secondaryPlanMedePayerId],
        medPAPayerId: values[insuranceFields.secondaryPlanMedPAPayerId],
        isPatientPrimaryCardholder: values[insuranceFields.secondaryIsCardholder] === 'Y',
        subscriberFirstName: values[insuranceFields.secondarySubscriberFirstName],
        subscriberLastName: values[insuranceFields.secondarySubscriberLastName],
        subscriberDateBirth: values[insuranceFields.secondarySubscriberDob],
        subscriberGender: values[insuranceFields.secondarySubscriberGender],
        employer: values[insuranceFields.secondaryEmployer],
        subscriberRelationshipToCardholder: values[insuranceFields.secondarySubscriberRelationship],
        isMedicalSupplementPlan: values[insuranceFields.secondaryIsMedicalSupplementPlan] === 'Y',
        medicalSupplementPlanLetter: values[insuranceFields.secondaryMedicalSupplementPlanLetter]
      },
      {
        patientInsuranceId: null,
        insuranceType: 'tertiary',
        medicalCardHolderId:
          values[
            !isEmpty(values[insuranceFields.tertiaryPlanPayerName])
              ? insuranceFields.tertiaryPlanCardholderId
              : insuranceFields.tertiaryPlanCardholderIdOpt
          ],
        payerId:
          getPayerID(values, insuranceFields.tertiaryPlanPayerName)?.[0] || values[insuranceFields.tertiaryPlanPayerId],
        payerIds: getPayerID(values, insuranceFields.tertiaryPlanPayerName) || [
          values[insuranceFields.tertiaryPlanPayerId]
        ],
        medePayerId: values[insuranceFields.tertiaryPlanMedePayerId],
        medPAPayerId: values[insuranceFields.tertiaryPlanMedPAPayerId],
        isPatientPrimaryCardholder: values[insuranceFields.tertiaryIsCardholder] === 'Y',
        subscriberFirstName: values[insuranceFields.tertiarySubscriberFirstName],
        subscriberLastName: values[insuranceFields.tertiarySubscriberLastName],
        subscriberDateBirth: values[insuranceFields.tertiarySubscriberDob],
        subscriberGender: values[insuranceFields.tertiarySubscriberGender],
        medicalPlanName:
          values[insuranceFields.tertiaryPlanName] ?? values[insuranceFields?.tertiaryPlanPayerName]?.[0]?.label
      },
      {
        patientInsuranceId: null,
        insuranceType: 'other',
        medicalCardHolderId: values[insuranceFields.otherPlanCardholderId],
        medicalGroup: values[insuranceFields.otherPlanGroupNumber],
        medicalPhoneNumber: values[insuranceFields.otherPlanPhoneNumber],
        medicalPlanName: values[insuranceFields.otherPlanName],
        policyHolderName: values[insuranceFields.otherPolicyHolderName],
        pharmacyCardholderId: values[insuranceFields.pharmacyCardholderId],
        pharmacyPlanName: values[insuranceFields.pharmacyName],
        pharmacyPhoneNumber: values[insuranceFields.pharmacyPhoneNumber],
        pharmacyBINNumber: values[insuranceFields.pharmacyRxBinIin],
        pharmacyGroup: values[insuranceFields.pharmacyRxGroupNumber],
        pharmacyPCN: values[insuranceFields.pharmacyRxPcn]
      }
    ].filter(Boolean),
    insuranceCard: getFilesPayload(values[insuranceFields.cardUpload])
  };
}
function convertToPhoneNumber(phoneNumber: string) {
  // Remove all non-digit characters using a regular expression
  if (phoneNumber?.length < 10) {
    return '';
  }
  return phoneNumber?.replace(/\D/g, '');
}

function buildPhysician(values: AnyObject, programId?: string, organizationId?: string): Physician {
  return {
    physicianId: Number(values[prescriberFields.id]),
    lastName: values[prescriberFields.lastName],
    firstName: values[prescriberFields.firstName],
    middleName: values[prescriberFields.middleName],
    npi: values[prescriberFields.npi]?.toString().trim(),
    taxId: values[prescriberFields.taxId],
    stateLicenseNumber: values[prescriberFields.stateLicenseNumber],
    licenseState: values[prescriberFields.licensingState],
    ptan: values[prescriberFields.ptan],
    dea: values[prescriberFields.dea],
    email: values[prescriberFields.email],
    organization: {
      organizationId: Number(organizationId),
      name: values[organizationFields.name],
      npi: values[organizationFields.npi]?.toString().trim(),
      taxId: values[organizationFields.taxId],
      organizationAddress: {
        organizationAddressId: Number(values[prescriberFields.locationId]),
        address1: values[prescriberFields.locationAddress1],
        address2: values[prescriberFields.locationAddress2],
        zip: values[prescriberFields.locationAddressZip],
        city: values[prescriberFields.locationAddressCity],
        state: values[prescriberFields.locationAddressState],
        phoneNumber: convertToPhoneNumber(values[prescriberFields.locationPhone1]),
        faxNumber: convertToPhoneNumber(values[prescriberFields.locationFaxNumber])
      }
    },
    officeContact: {
      lastName: values[organizationFields.officeContactLastName],
      firstName: values[organizationFields.officeContactFirstName],
      phoneNumber: values[organizationFields.officeContactPhone],
      fax: values[organizationFields.officeContactFax],
      eMail: values[organizationFields.officeContactEmail]
    }
  };
}

// For checking whether the checkbox on IU selected or not
function isCheckBoxSelected(value: string[] | boolean, fieldName: string) {
  if (Array.isArray(value)) {
    return value?.[0]?.toLocaleLowerCase() === fieldName;
  }
  return false;
}

function buildDiagnosis(values: AnyObject): PortalDiagnosis {
  const diagnosisCodeArray = values[clinicalFields?.primaryDiagnosed]?.split('-');
  const diagnosisCode = diagnosisCodeArray?.[0];
  let diagnosisCodeDescription = '';
  // Looping through the array and concating the diagnosis description
  for (let i = 1; i < diagnosisCodeArray?.length; i++) {
    if (i === diagnosisCodeArray.length - 1) diagnosisCodeDescription += diagnosisCodeArray[i];
    else diagnosisCodeDescription += diagnosisCodeArray[i] + '-';
  }
  return {
    treatmentStartDate: values[clinicalFields.treatmentStartDate],
    diagnosisCode:
      values[clinicalFields?.diagnosisCode] ??
      (values[clinicalFields?.primaryDiagnosedSelect] || values[clinicalFields?.primaryDiagnosedSelectAnother]) ??
      diagnosisCode,
    diagnosisDescription:
      values[clinicalFields?.diagnosisCode] ??
      values[clinicalFields?.primaryDiagnosedSelect] ??
      diagnosisCodeDescription,
    otherDiagnosisCode:
      values[clinicalFields.primaryDiagnosedSelectAnother]?.toLowerCase() === 'other'
        ? values[clinicalFields.otherDiagnosisCode]
        : null,
    patientPreviouslyEnrolled: values[clinicalFields.previouslyEnrolled]?.[0],
    hasASCVSorHeFH: values[clinicalFields.diagnosed] === 'yes' ? true : false,
    primarydiagnosisCode: values[clinicalFields?.primaryDiagnosedSelect] ?? diagnosisCode,
    diagnosisCodesSecondary: values[clinicalFields?.secondaryDiagnosed]?.split('-')?.[0],
    diagnosisCodesTertiary: values[clinicalFields?.tertiaryDiagnosed]?.split('-')?.[0],
    previousTreatments: [
      {
        treatmentType: 'primary',
        startDate: values[clinicalFields.previousStartDate],
        endDate: values[clinicalFields.previousEndDate],
        dose: values[clinicalFields.previousDose],
        treatment: values[clinicalFields.previousTreatment],
        frequency: values[clinicalFields.previousFrequency]
      },
      {
        treatmentType: 'secondary',
        startDate: values[clinicalFields.previousStartDate1],
        endDate: values[clinicalFields.previousEndDate1],
        dose: values[clinicalFields.previousDose1],
        treatment: values[clinicalFields.previousTreatment1],
        frequency: values[clinicalFields.previousFrequency1]
      },
      {
        treatmentType: 'tertiary',
        startDate: values[clinicalFields.previousStartDate2],
        endDate: values[clinicalFields.previousEndDate2],
        dose: values[clinicalFields.previousDose2],
        treatment: values[clinicalFields.previousTreatment2],
        frequency: values[clinicalFields.previousFrequency2]
      }
    ],
    optIn: values[clinicalFields.optIn],
    cptCode: buildCPTCodes(values),
    billingPreference: values[clinicalFields.treatmentInfoBillingPreference],
    isSampleProductAdministered: values[clinicalFields.treatmentInfoSampleProductAdministered] === 'yes' ? true : false,
    dateProductNeeded: moment.utc(values[clinicalFields.treatmentInfoDateProductNeeded]).format(),
    anyKnownDrugAllergies: values[clinicalFields.treatmentInfoAnyKnownDrugAllergies] === 'yes' ? true : false,
    allergies: values[clinicalFields.treatmentInfoAllergies],
    contraindications: values[clinicalFields.treatmentInfoContraindications],
    hasStartedTreatment: values[clinicalFields.treatmentInfoPatientStartedTreatment] === 'yes' ? true : false,
    visualAcuityRightEye: values[clinicalFields.visualAccuityRight],
    visualAcuityLeftEye: values[clinicalFields.visualAccuityLeft],
    treatmentLastDate: values[clinicalFields.injectionDate],
    clinicalPatientMeets: values[clinicalFields?.patientMeetingCriteria],
    hasASCVD: values[clinicalFields?.patientMeetingCriteriaOther]?.includes('hasASCVD'),
    hasHeFH: values[clinicalFields?.patientMeetingCriteriaOther]?.includes('hasHeFH'),
    hasClinicalOther: values[clinicalFields?.patientMeetingCriteriaOther]?.includes('other'),
    clinicalASCVDText:
      values[clinicalFields.primaryDiagnosedSelect]?.toLowerCase() === 'other'
        ? values[clinicalFields?.otherDiagnosisCode]
        : null,
    clinicalHeFHText: buildHefhTextValues(values),
    clinicalOtherText: values[clinicalFields?.clinicalOtherSelectedHeader],
    isClinicalAscvd: isCheckBoxSelected(values[clinicalFields?.clinicalAscvd], 'clinicalasvcd'),
    isClinical12Selected: isCheckBoxSelected(values[clinicalFields?.clinical12], 'i2'),
    clinical12TextOne: values[clinicalFields?.clinical12TextOne],
    clinical12TextTwo: values[clinicalFields?.clinical12TextTwo],
    isClinical16CerebrovascularDiagnosisSelected: isCheckBoxSelected(
      values[clinicalFields?.clinical16CerebrovascularDiagnosis],
      'i6'
    ),
    clinical16CerebrovascularTextOne: values[clinicalFields?.clinical16CerebrovascularTextOne],
    clinical16CerebrovascularTexttwo: values[clinicalFields?.clinical16CerebrovascularTexttwo],
    isClinicalAtherosClerosisI70DiagnosisSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalAtherosClerosisI70Diagnosis],
      'i70'
    ),
    clinicalAtherosClerosisText: values[clinicalFields?.clinicalAtherosClerosisText] ?? null,
    isClinicalOtherPeripheralVasculari73DiagnosisSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalOtherPeripheralVasculari73Diagnosis],
      'i73'
    ),
    clinicalPeripheral1Vasculari173: values[clinicalFields?.clinicalPeripheral1Vasculari173],
    isClinicalOtherspecifyICDSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalOtherspecifyICDSelection],
      'other icd'
    ),
    clinicalOthersSpecifyICDText: values[clinicalFields?.clinicalOthersSpecifyICDText] ?? null,
    isE7801Selected: values[clinicalFields?.E78Diagnosed] === 'e7801' ? true : null,
    isClinicalZ8342Selected: isCheckBoxSelected(values[clinicalFields?.clinicalZ8342], 'z83.42'),
    isOtherICD10CMSelected: values[clinicalFields?.E78Diagnosed] === 'other' ? true : null,
    isClinicalE755Selected: values[clinicalFields?.clinicalE755]?.[0] === 'E75.5' ? true : null,
    isClinicalOtherRiskFactorsSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalOtherRiskFactors],
      'otherriskfactors'
    ),
    isClinicalDiabetes11DiagnosisSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalDiabetes11Diagnosis],
      'e11'
    ),
    clinicalDiabetesMellitus: values[clinicalFields?.clinicalDiabetesMellitus],
    isClinicalHyperTensionI10DiagnosisSelected: isCheckBoxSelected(
      values[clinicalFields?.clinicalHyperTensionI10Diagnosis],
      'i10'
    ),
    clinicalHyperTension: values[clinicalFields?.clinicalHyperTension],
    isClinicalSpecifyICD10cmDiagnosis: isCheckBoxSelected(
      values[clinicalFields?.clinicalSpecifyICD10cmDiagnosis],
      'other icd-10'
    ),
    clinicalOtherspecifyICD: values[clinicalFields?.clinicalOtherspecifyICD],
    clienicalLdlText: values[clinicalFields?.clienicalLdlText] ?? null,
    clinicalDateTaken: values[clinicalFields?.clinicalDateTaken] ?? null,
    clinicalLDLTratment: values[clinicalFields?.clinicalLDLTratment] ?? null,
    hasPatientPreviouslyEnrolled: values[clinicalFields?.previouslyEnrolled]?.[0],
    injectionDate: values[clinicalFields?.injectionDate] ?? null,
    isE78Selected: values[clinicalFields?.E78Diagnosed] === 'e78' ? true : null,
    E78Diagnosed: values[clinicalFields?.E78Select] ?? null,
    clinicalOther: values[clinicalFields?.clinicalOther] ?? null,
    isClinicalOtherICDSelected: isCheckBoxSelected(values[clinicalFields?.clinicalOtherICD], 'other'),
    clinicalSpecifyOuterText: values[clinicalFields?.clinicalSpecifyOuterText] ?? null
  };
}

function buildHefhTextValues(values: AnyObject): any {
  const hefhOptions = values[clinicalFields?.hefhSelectedOptions] || [];
  let modifiedHefhOptions = [];
  const indexOfOther = hefhOptions?.indexOf('other');
  if (indexOfOther >= 0) {
    modifiedHefhOptions = hefhOptions?.filter((item: string) => item !== 'other');
    modifiedHefhOptions?.push(values[clinicalFields?.clinicalHefhOtherSelected]);
    return modifiedHefhOptions;
  }
  return hefhOptions;
}

function buildFacility(values: AnyObject): Facility {
  return {
    facilityId: values[facilityFields.facilityId],
    npi: values[facilityFields.npi]?.toString().trim(),
    name: values[facilityFields.name],
    address1: values[facilityFields.address1],
    address2: values[facilityFields.address2],
    state: values[facilityFields.state],
    city: values[facilityFields.city],
    zip: values[facilityFields.zip],
    taxId: values[facilityFields.taxId],
    officeContactFirstName: values[facilityFields.officeContactFirstName],
    officeContactLastName: values[facilityFields.officeContactLastName],
    officeContactPhone: values[facilityFields.officeContactPhone]
  };
}

function buildPatientAttestation(values: AnyObject): PatientAttestation {
  return {
    patientAttestationId: 0, // need data
    attestationType: values[patientAttestationFields.attestationType],
    manualAttestationDocument:
      values[patientAttestationFields.attestationDocument] &&
      getFilePayload(values[patientAttestationFields.attestationDocument][0]),
    electronicAttestation: {
      patientEmail:
        values[patientAttestationFields.patientEmailEditableAttestation] ||
        values[patientAttestationFields.patientEmailAttestation] ||
        values[patientFields.email],
      patientMobileNumber: values[patientAttestationFields.patientmobileNumber]
    }
  };
}

function buildPrescriberAttestation(values: AnyObject): PrescriberAttestation {
  const signatureType = getSignatureType(values[prescriberAttestationFields.signature]);
  return {
    isCertified: !!values[prescriberAttestationFields.isCertified],
    isCertified2: values[prescriberAttestationFields.isCertified2],
    isCertified3: values[prescriberAttestationFields.isCertified3],
    typedSignature: signatureType === SignatureOptions.TEXT ? values[prescriberAttestationFields.signature] : null,
    signatureDate: moment(values[prescriberAttestationFields.signatureDate]).format('YYYY-MM-DD'),
    contactPatientForEnrollmentIssues: !!values[prescriberAttestationFields.prescriberSigningAttestation]
  };
}
function buildCPTCodes(values: AnyObject): CPTCode {
  return {
    code1: values[clinicalFields.cptCodes]?.length >= 1 ? values[clinicalFields.cptCodes][0].value : null,
    code2: values[clinicalFields.cptCodes]?.length >= 2 ? values[clinicalFields.cptCodes][1].value : null,
    code3: values[clinicalFields.cptCodes]?.length >= 3 ? values[clinicalFields.cptCodes][2].value : null,
    code4: values[clinicalFields.cptCodes]?.length >= 4 ? values[clinicalFields.cptCodes][3].value : null,
    code5: values[clinicalFields.cptCodes]?.length >= 5 ? values[clinicalFields.cptCodes][4].value : null
  };
}

function buildDrugInfo(values: AnyObject): PortalDrug {
  return {
    daysSupply: values[clinicalFields.daysSupply],
    quantity: values[clinicalFields.qty],
    ndc: values[clinicalFields.ndc]
  };
}

function buildPrescriptionData(values: AnyObject): Prescription {
  const signatureType = getSignatureType(values[prescriptionFields.signature]);
  return {
    drugDescription: values[prescriptionFields.ndc],
    quantity: Number(values[prescriptionFields.qty]),
    height: values[prescriptionFields.height],
    weight: values[prescriptionFields.weight],
    bsa: values[prescriptionFields.bsa],
    mg15: values[prescriptionFields.milligrams15] || values[prescriptionFields.mgCycle15],
    mg20: values[prescriptionFields.milligrams20] || values[prescriptionFields.mg20],
    fixedDoseTablet: values[prescriptionFields.fixedDoseTablet],
    tabletsPerCycle: values[prescriptionFields.tabletsPerCycle] || values[prescriptionFields.tabletsPerCycleAnother],
    sig: values[prescriptionFields.sig] || values[prescriptionFields.sigAlternateLonsurf],
    sigAlternate: values[prescriptionFields.sigAlternate],
    quantityUnitOfMeasure: values[prescriptionFields.ndc],
    daysSupply: Number(values[prescriptionFields.daysSupply]),
    numberOfRefills:
      Number(values[prescriptionFields.numberOfRefills]) || Number(values[prescriptionFields.refillLonsurf]),
    numberOfRefillsAnother: Number(values[prescriptionFields.numberOfRefillsAnother]),
    effectiveDate: values[prescriptionFields.effectiveDate],
    allowSubstitutions: values[prescriptionFields.allowSubstitutions] === 'y',
    numberOfPeriodontalSites: Number(values[prescriptionFields.numberOfPeriodontalSites]),
    directionForUse: values[prescriptionFields.directionForUse],
    additionalNotes: values[prescriptionFields.additionalNotes],
    typedSignature: SignatureOptions.TEXT === signatureType ? values[prescriptionFields.signature] : null,
    typedSignatureDate: moment.utc(values[prescriptionFields.signatureDate]).format(),
    initialDose: values[prescriptionFields.initalDose],
    secondDose: values[prescriptionFields.secondDose],
    physicianSignatureType: values[prescriptionFields.physicianSignatureType],
    signingAttestation: values[prescriptionFields.signingAttestation]
  };
}

function buildCaregiver(values: AnyObject): Caregiver {
  return {
    firstName: values[caregiverFields.firstName],
    middleName: values[caregiverFields.middleName],
    lastName: values[caregiverFields.lastName],
    email: values[caregiverFields.email],
    homePhoneNumber: values[caregiverFields.homePhone],
    mobilePhoneNumber: values[caregiverFields.mobilePhone],
    relationship: values[caregiverFields.patientRelationship],
    isLegallyAuthorizedRepresentative: values[caregiverFields.isAuthorizedRepForPatient]?.[0] === 'yes'
  };
}

function buildProviderData(values: AnyObject): Provider {
  return {
    pharmacyName: values[pharmacyFields.pharmacyName],
    otherValue: values[pharmacyFields.otherPharmacyName]
  };
}

function buildSignatures(values: AnyObject): Signature[] {
  const signatures: Signature[] = [];
  if (values[prescriptionFields.signature]) {
    signatures.push({
      entityType: SignatureEntityType.PRESCRIPTION,
      signaturePurpose: SignaturePurpose.PRESCRIPTION_WRITTEN,
      signatureType: getSignatureType(values[prescriptionFields.signature]),
      signatureDateTime: moment.utc(values[prescriptionFields.signatureDate]).format(),
      signatureImage: values[prescriptionFields.signature]
    });
  }
  if (values[prescriberAttestationFields.signature]) {
    signatures.push({
      entityType: SignatureEntityType.PROVIDER,
      signaturePurpose: SignaturePurpose.PRESCRIPTION_ALLOWED,
      signatureType: getSignatureType(values[prescriberAttestationFields.signature]),
      signatureDateTime: moment.utc(values[prescriberAttestationFields.signatureDate]).format(),
      signatureImage: values[prescriberAttestationFields.signature]
    });
  }
  return signatures;
}

function getPayerID(values: AnyObject, fieldName: string): number[] | null {
  const playerIds = values[fieldName]?.length > 0 ? values[fieldName][0].value : null;
  if (Array.isArray(playerIds)) {
    return playerIds.filter((id) => !!id && id.length > 0).map((id) => parseInt(id));
  }
  return playerIds;
}

const PatientEnrollmentClients = ['VerricaProviderPortal'];

export function getEnrollmentSubType(type: string) {
  switch (type) {
    case 'all':
      return 'Full Service Support';
    case 'field-reimbursement-manager':
      return 'Field Reimbursement Manager Support Only';
    default:
      return '';
  }
}

export function getEnrollmentType(type: string, portalName: string) {
  switch (type) {
    case 'all':
      return 'All Services';
    case 'insurance-review':
      return PatientEnrollmentClients.includes(portalName)
        ? 'Patient Enrollment'
        : 'Insurance Determination And Coverage Review';
    case 'copay-enrollment':
      return 'Copay Enrollment';
    case 'copay-claim':
      return 'Copay Claim Submission';
    case 'patient-resources':
      return 'Patient Education and Support Resources';
    default:
      return 'All Services';
  }
}
