import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import {
  clinicalTabFields,
  insuranceTabFields,
  otherTabFields,
  patientAttestationTabFields,
  patientTabFields,
  prescriberAttestationTabFields,
  prescriberTabFields,
  reviewTabFields,
  StepNames,
  prescriptionTabFields
} from '.';
import { DBFormSchema } from '..';
import { ServiceOptionType } from '../../../interfaces';
import { pharmacyTabFields } from './pharmacy-tab';

export const getServiceRequestAllFormSchema = (schema: DBFormSchema): Schema => {
  return {
    title: 'Service Request ALL Form',
    fields: [
      {
        component: componentTypes.WIZARD,
        name: `service-request-${ServiceOptionType.All}`,
        fields: [
          patientTabFields(schema, StepNames.INSURANCE),
          insuranceTabFields(schema, StepNames.PRESCRIBER),
          prescriberTabFields(schema, StepNames.CLINICAL),
          clinicalTabFields(schema, StepNames.PRESCRIPTION),
          prescriptionTabFields(schema, StepNames.PHARMACY),
          pharmacyTabFields(schema, StepNames.OTHER),
          otherTabFields(schema, StepNames.PATIENT_ATTESTATION),
          patientAttestationTabFields(schema, StepNames.PRESCRIBER_ATTESTATION),
          prescriberAttestationTabFields(schema, StepNames.REVIEW),
          reviewTabFields(schema)
        ].filter(Boolean)
      }
    ]
  };
};

export const serviceRequestAllFormSchema: DBFormSchema = {
  // !PATIENT TAB
  patient_tab: { label: 'patient' },
  personal_information_header: { label: 'personal information' },
  patient_first_name: { label: 'first name' },
  patient_middle_name: { label: 'middle name' },
  patient_last_name: { label: 'last name' },
  patient_dob: { label: 'date of birth' },
  patient_gender: { label: 'gender' },
  separator_patient_1: {},
  patient_preferred_language: { label: 'preferred language' },
  patient_other_language: { label: 'specify "other" language', required: true },
  contact_information_header: { label: 'contact and address information' },
  patient_email: { label: 'email', placeholder: 'optional' },
  patient_home_number: { label: 'home phone number', placeholder: 'optional' },
  patient_mobile_number: { label: 'mobile number', placeholder: 'optional' },
  patient_message_option: { label: 'okay to leave message on' },
  patient_address_1: { label: 'address 1', required: true },
  patient_address_2: { label: 'address 2', placeholder: 'optional' },
  patient_zip: { label: 'zip code', required: true },
  patient_city: { label: 'city', required: true },
  patient_state: { label: 'state', required: true },
  // !INSURANCE TAB
  insurance_tab: { label: 'insurance' },
  insurance_information_header: { label: 'insurance information' },
  patient_has_insurance: { label: 'does the patient have insurance?', required: true },
  patient_insurances: {
    label: 'who does the patient have insurance through? (check all that apply)',
    required: true
  },
  patient_other_insurance: { label: 'please specify "other" insurance', required: true },
  medical_insurance_header: { label: 'medical insurance' },
  medical_insurance_primary_header: { label: 'primary' },
  primary_plan_name: { label: 'plan name', required: true },
  primary_plan_cardholder_id: { label: 'cardholder id', required: true },
  primary_plan_group_number: { label: 'group #', required: true },
  primary_plan_phone_number: { label: 'phone number', required: true },
  medical_insurance_secondary_header: { label: 'secondary' },
  secondary_plan_name: { label: 'plan name' },
  secondary_plan_cardholder_id: { label: 'cardholder id' },
  secondary_plan_group_number: { label: 'group #' },
  secondary_plan_phone_number: { label: 'phone number' },
  pharmacy_insurance_header: { label: 'pharmacy or prescription insurance' },
  pharmacy_plan_name: { label: 'plan name' },
  pharmacy_cardholder_id: { label: 'cardholder id' },
  pharmacy_rx_group_number: { label: 'group #' },
  pharmacy_rx_bin_iin: { label: 'rx bin or rx iin' },
  pharmacy_rx_pcn: { label: 'rx pcn' },
  pharmacy_phone_number: { label: 'phone number' },
  other_insurance_header: { label: 'other insurance' },
  other_plan_name: { label: 'plan name' },
  other_plan_cardholder_id: { label: 'cardholder id' },
  other_plan_group_number: { label: 'group # / bin / pcn' },
  other_plan_phone_number: { label: 'phone number' },
  upload_insurance_cards_header: { label: 'upload copy of insurance card(s)' },
  insurance_card_upload: {},
  medicscan_upload: {},
  // !PRESCRIBER TAB
  prescriber_tab: { label: 'prescriber' },
  prescriber_id: { label: 'select or add a prescriber' },
  prescribers_information_header: { label: "prescriber's information" },
  prescriber_first_name: { label: 'first name', required: true },
  prescriber_middle_name: { label: 'middle name' },
  prescriber_last_name: { label: 'last name', required: true },
  prescriber_npi: { label: 'prescriber npi', required: true },
  prescriber_tax_id: { label: 'tax id # (tin)' },
  prescriber_state_license_number: { label: 'state license #' },
  prescriber_licensing_state: { label: 'licensing state', required: true },
  separator_prescriber_1: {},
  prescribers_practice_information_header: { label: "prescriber's practice information" },
  organization_name: { label: 'organization name' },
  organization_npi: { label: 'organization npi' },
  organization_tax_id: { label: 'organization tax id' },
  prescriber_location_id: { label: 'select or add a location' },
  location_header: { label: 'location' },
  prescriber_location_address_full: { label: 'address' },
  prescriber_location_address_1: { label: 'address 1', required: true },
  prescriber_location_address_2: { label: 'address 2' },
  prescriber_location_zip: { label: 'zip code', required: true },
  prescriber_location_city: { label: 'city', required: true },
  prescriber_location_state: { label: 'state', required: true },
  prescriber_location_phone_1: { label: 'phone number', required: true },
  prescriber_location_fax_number: { label: 'fax number', required: true },
  separator_prescriber_2: {},
  office_contact_header: { label: 'office contact' },
  organization_office_contact_first_name: { label: 'first name' },
  organization_office_contact_last_name: { label: 'last name' },
  organization_office_contact_phone: { label: 'phone' },
  dashes: { label: '---', required: true },
  location_dashes: { label: '---', required: true },
  // !CLINICAL TAB
  clinical_tab: { label: 'clinical' },
  clinical_information_header: { label: 'clinical information' },
  clinical_treatment_start_date: { label: 'treatment start date' },
  spacer_clinical_1: {},
  clinical_patient_previously_enrolled: {},
  spacer_clinical_2: {},
  clinical_ndc_drug_name: { hideField: true },
  spacer_clinical_3: { hideField: true },
  clinical_qty: { hideField: true },
  spacer_clinical_4: { hideField: true },
  clinical_days_supply: { hideField: true },
  spacer_clinical_5: { hideField: true },
  clinical_patient_primary_diagnosed_select: { label: 'ICD-10 Primary Diagnosis Code', required: true },
  clinical_patient_primary_diagnosed: { hideField: true },
  spacer_clinical_6: {},
  clinical_patient_secondary_diagnosed: { hideField: true },
  spacer_clinical_7: { hideField: true },
  clinical_patient_tertiary_diagnosed: { hideField: true },
  spacer_clinical_8: { hideField: true },
  clinical_treatment_other: { label: 'please specify "other" diagnosis', required: true },
  spacer_clinical_9: { hideField: true },
  clinical_patient_diagnosed: { label: 'has patient been diagnosed with ascvd and/or hefh?', required: true },
  spacer_clinical_10: {},
  // !OTHER TAB
  other_tab: { label: 'other' },
  administration_information_header: { label: 'administration information' },
  administration_info: { label: 'how will the product be acquired & administered?', required: true },
  alternate_facility_header: { label: 'alternate administering facility information' },
  facility_npi: { label: 'administering practice/facility npi' },
  facility_name: { label: 'administering practice/facility name', required: true },
  facility_address_1: { label: 'address 1', required: true },
  facility_address_2: { label: 'address 2' },
  facility_zip: { label: 'zip code', required: true },
  facility_city: { label: 'city', required: true },
  facility_state: { label: 'state', required: true },
  facility_tax_id: { label: 'administering practice/facility tax id:' },
  facility_office_contact_first_name: { label: 'office contact first name', required: true },
  facility_office_contact_last_name: { label: 'office contact last name', required: true },
  facility_office_contact_phone: { label: 'office contact phone', required: true },
  triage_services: { hideField: true },
  copay_claim_information_header: { hideField: true },
  copay_preferred_payment_method: { hideField: true },
  copay_email_id: { hideField: true },
  required_documentation_header: { hideField: true },
  upload_information_text: { hideField: true },
  upload_forms_header: { hideField: true },
  copay_additional_documents_upload: { hideField: true },
  // !PATIENT ATTESTATION
  patient_attestation_tab: { label: 'patient attestation & signature' },
  patient_attestation_type: {},
  patient_attestation_document: {},
  // !PRESCRIBER ATTESTATION
  prescriber_attestation_tab: { label: 'prescriber attestation & signature' },
  pa_patient_information_header: { label: 'patient information' },
  pa_personal_information_header: { label: 'personal information' },
  pa_contact_information_header: { label: 'contact and address information' },
  pa_prescriber_information_header: { label: 'prescriber information' },
  pa_practice_information_header: { label: 'practice information' },
  pa_office_contact_header: { label: 'office contact' },
  pa_treatment_information_header: { label: 'treatment information' },
  pa_administration_information_header: { label: 'administration information' },
  pa_attestation_acknowledgement_header: { label: 'please read the following attestation and acknowledge to proceed' },
  prescriber_attestation_certified_text: {
    content:
      '<p>Novartis Patient Assistance Foundation (NPAF) Authorization FOR PHYSICIAN I certify that this therapy is medically necessary and that this information is accurate to the best of my knowledge.</p><p>I certify that the above therapy is medically necessary, and that the information provided is accurate to the best of my knowledge. I certify that I am the physician who has prescribed [Product] to the previously identifed patient, and that I provided the patient with a description of [Program Name]. I agree to the NPAF Authorization above. I also agree to receive communications, including faxes, related to my patient’s enrollment or participation in [Program Name].</p><p>I understand that NPAF may revise, change, or terminate programs at any time.</p>',
    helperText: 'Please read entire section.'
  },
  prescriber_attestation_is_certified: {},
  prescriber_attestation_is_certified_text: {},
  can_contact_patient: { label: 'Can we contact the patient if they have issues with enrollment?' },
  prescriber_attestation_signature: { label: 'signature', required: true },
  prescriber_attestation_signature_date: {
    label: 'date of certification'
  },
  // !REVIEW
  review_tab: { label: 'review & submit' }
};
