import React, { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useState } from 'react';

interface ContentForRequiredScrollProps extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  // A callback to be notified when the user scrolled to the bottom of the component.
  //
  // Warning: using an anonymous function will cause problems. Use a callback or a class function to avoid loops.
  onScrollToBottom: () => void;
}
/**
 * A component to allow arbitrary content with `overflow-auto` applied by default to enable scroll tracking.
 *
 * @param {ContentForRequiredScrollProps} { onScrollToBottom, ...props }
 * @returns {ReactElement}
 */
export const ContentForRequiredScroll = ({ onScrollToBottom, ...props }: ContentForRequiredScrollProps) => {
  const [didScrollToBottom, setDidScrollToBottom] = useState(false);

  const onScroll = useCallback(
    (event) => {
      // The scroll buffer allows N pixels close to the bottom to trigger the callback
      const scrollBuffer = 5;
      // Calculate the bottom including the buffer value
      const isAtBottom = event.target.scrollHeight - event.target.scrollTop - scrollBuffer <= event.target.clientHeight;

      // NOTE: we're only tracking this with state so we trigger the callback once for consumers
      if (isAtBottom) setDidScrollToBottom(true);
    },
    [setDidScrollToBottom]
  );

  useEffect(() => {
    // Notify the client, but only if they did scroll to the bottom
    didScrollToBottom && onScrollToBottom();
  }, [didScrollToBottom, onScrollToBottom]);

  // Set the default className and append any the consumer sends
  const className = `overflow-auto border rounded ${props.className}`.trim();

  // Force a default maxHeight, but allow the consumer to override
  const style = { maxHeight: 150, minHeight: 100, ...props.style };

  return (
    <div {...props} className={className} onScroll={onScroll} style={style}>
      {props.children}
    </div>
  );
};
