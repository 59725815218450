import React from 'react';
import { Form } from 'react-bootstrap';

import { AnyType } from '../../interfaces';

export type SPSearchOptionType = {
  label: string;
  value: string;
};

interface SepeciltyPharmacySearchProps {
  options: SPSearchOptionType[];
  defaultValue?: SPSearchOptionType;
  onSearch?: (value: string) => void;
}

export const SepeciltyPharmacySearch = (props: SepeciltyPharmacySearchProps) => {
  const options: SPSearchOptionType[] = props?.options || [];
  const defaultOption: SPSearchOptionType = props?.defaultValue || options?.[0] || {};

  const onSearch = (e: AnyType) => {
    e.preventDefault();
    const value = e.target.value;
    props.onSearch && props.onSearch(value);
  };

  return (
    <>
      <Form.Group controlId='drugSelect'>
        {/* <Form.Label>Example select</Form.Label> */}
        <Form.Control
          as='select'
          onChange={onSearch}
          defaultValue={defaultOption?.value}
          disabled={options?.length === 1}>
          {options?.length > 0 &&
            options.map((option: SPSearchOptionType) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
    </>
  );
};
