import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';
import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';

// TODO: flesh this out in the next iteration

export const registrationFormSchema: Schema = {
  fields: [
    {
      component: componentTypes.WIZARD,
      name: 'wizzard',
      fields: [
        {
          title: 'Organization Information',
          name: 'organization-information',
          fields: [
            {
              component: componentTypes.TEXT_FIELD,
              name: 'test_1',
              label: 'test 1'
            }
          ]
        },
        {
          title: 'prescriber',
          name: 'prescriber',
          fields: [
            {
              component: componentTypes.TEXT_FIELD,
              name: 'test_2',
              label: 'test 2'
            }
          ]
        }
      ]
    }
  ]
};
