import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { InfoCircleFill, ExclamationCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';

import { CustomThemes } from '../utils/custom-themes';

export enum AlertTypeEnum {
  INFO = 'info',
  WARN = 'warning',
  DANGER = 'danger'
}

interface CustomAlertProps {
  title: string;
  message: string;
  type: AlertTypeEnum;
}

const iconMap = {
  info: InfoCircleFill,
  warning: ExclamationCircleFill,
  danger: XCircleFill
};

export const CustomAlert = ({ title, message, type = AlertTypeEnum.INFO }: CustomAlertProps) => {
  CustomThemes();
  const [isExpanded, setExpanded] = useState(false);
  const Icon = iconMap[type];

  return (
    <Alert
      className='cmx__alert bg-transparent pointer'
      data-testid='customAlertContainer'
      variant={type}
      onClick={() => setExpanded(!isExpanded)}>
      <div className='d-flex flex-nowrap align-items-center'>
        <Icon data-testid='customAlertIcon' className={`mr-2 text=${type.toLowerCase()}`.trim()} />
        {isExpanded ? <p className='message'>{message}</p> : <h5 className='title'>{title}</h5>}
      </div>
    </Alert>
  );
};

const mapStateToProps = () => ({
  title: 'You have 1 Announcement',
  message:
    'Aenean vestibulum diam mauris, eu suscipit nisi congue quis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse vehicula convallis elit in dignissim. Donec fermentum volutpat nunc, nec porta velit sodales ac.',
  type: AlertTypeEnum.INFO
});

const mapDispatchToProps = {};

export const ConnectedCustomAlert = connect(mapStateToProps, mapDispatchToProps)(CustomAlert);
