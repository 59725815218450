import { partial } from 'lodash';
import { MIN_BIRTHDATE } from '.';
import { Insurance, Patient, PatientInsurance, PatientPhoneNumbers } from '../api';

import { PortalPatientInsurance, PortalInsurance, PortalDrug } from '../api/interface-portal';
import { PatientPhoneNumberFragment } from '../graphql';
import { dateFormatter, YEAR_FIRST_FORMAT, humanize } from './formatters';
import { AnyObject } from '@data-driven-forms/react-form-renderer';

export const scrollTo = (x: number, y: number) => window.scrollTo({ top: x, left: y, behavior: 'smooth' });

export const scrollToTop = () => scrollTo(0, 0);

// https://stackoverflow.com/a/12900504
export const getFileExtension = (fileName: string) => fileName?.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

export const getPatientPhoneMessageOption = (
  homePhone?: PatientPhoneNumberFragment | null,
  mobilePhone?: PatientPhoneNumberFragment | null
) => {
  return [homePhone?.okToLeaveMsg ? 'home' : null, mobilePhone?.okToLeaveMsg ? 'mobile' : null]
    .filter((x) => !!x)
    .map(humanize)
    .join(', ');
};

export const getPatientPhoneNumber = (type: 'home' | 'mobile', patient: Patient) =>
  patient.patientPhoneNumbers?.find?.((phoneNumber: PatientPhoneNumbers) => phoneNumber?.phoneType === type)
    ?.phoneNumber;
export const getPatientHomePhoneNumber = partial(getPatientPhoneNumber, 'home');
export const getPatientMobilePhoneNumber = partial(getPatientPhoneNumber, 'mobile');

export const findInsurancePolicy = (
  policyType: 'primary' | 'secondary' | 'tertiary' | 'other',
  insurance: PortalInsurance
) =>
  insurance?.patientInsurances?.find(
    (insurance: PortalPatientInsurance) => insurance?.insuranceType?.toLowerCase() === policyType
  ) ?? null;
export const findInsurancePrimaryPolicy = partial(findInsurancePolicy, 'primary');
export const findInsuranceSecondaryPolicy = partial(findInsurancePolicy, 'secondary');
export const findInsuranceOtherPolicy = partial(findInsurancePolicy, 'other');

export const getValidBirthdayRangeProps = () => ({
  min: MIN_BIRTHDATE,
  max: dateFormatter(new Date(), YEAR_FIRST_FORMAT)
});

export const isEmptyObject = (obj: AnyObject) => Object.keys(obj).length === 0;

export const formatPhoneNumber = (value: any) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const emailValidationHelper = (value: any) => {
  /* eslint-disable no-useless-escape */
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  return !regex.test(value);
};
