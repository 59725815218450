import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { List } from 'react-bootstrap-icons';
import { CasesTable, ResourceContent } from '.';
import { CasesFilterTypes } from '../utils';
import { ActionCard } from './ActionCard';
import { FeatureGuard } from './multi-tenant/FeatureGuard';
import { Feedback } from './Feedback';

export const GettingStarted = () => {
  return (
    <>
      <div className='d-flex'>
        <p className='font-weight-bold text-primary text-nowrap'>
          <strong>
            <ResourceContent contentKey='home.getting-started.title' />
          </strong>
        </p>
      </div>

      <FeatureGuard contentKey='home.layout'>
        <Row>
          <Col className='text-black-50 mb-3'>
            <ResourceContent contentKey='home-quickguide-link' />
          </Col>
        </Row>
      </FeatureGuard>

      <FeatureGuard contentKey='home-phyz.layout'>
        <Row>
          <Col className='text-black-50 mb-3'>
            <ResourceContent contentKey='home.getting-started.body.phyz' />
          </Col>
        </Row>
      </FeatureGuard>

      <Row className='justify-content-between mb-4'>
        <FeatureGuard contentKey='service-request.manage'>
          <Col className='mb-md-2 mb-sm-3 xs={12} md mb-2'>
            <DashboardGridCell name='widget1' testId='cmx__start-new-request' />
          </Col>
        </FeatureGuard>

        <FeatureGuard contentKey='home-phyz.layout'>
          <Col className='mb-md-2 mb-sm-3 xs={12} md mb-2'>
            <DashboardGridCell name='widget5' testId='cmx__view-cases' />
          </Col>
        </FeatureGuard>

        <Col className='mb-md-2 mb-sm-3 xs={12} md mb-2'>
          <DashboardGridCell name='widget2' testId='cmx__resource-download' />
        </Col>

        <FeatureGuard contentKey='phyz.content'>
          <Col className='mb-md-2 mb-sm-3 xs={12} md mb-2'>
            <DashboardGridCell name='widget6' testId='cmx__view-message-center' />
          </Col>
        </FeatureGuard>
      </Row>

      <hr className='border-top border-dark mb-4' />

      <FeatureGuard contentKey='home.layout'>
        <div className='d-flex font-weight-bold text-primary text-nowrap'>
          <strong>
            <ResourceContent contentKey='home.getting-started.secondary.body' />
          </strong>
        </div>
      </FeatureGuard>

      <FeatureGuard contentKey='home-phyz.layout'>
        <div className='d-flex font-weight-bold text-primary text-nowrap'>
          <strong>CASES WITH ACTIONS AND UPDATES</strong>
        </div>
      </FeatureGuard>

      <FeatureGuard contentKey='home-phyz.layout'>
        <Row>
          <Col className='text-black-50 mb-3'>
            <ResourceContent contentKey='home.getting-started.secondary.body' />
          </Col>
        </Row>
      </FeatureGuard>

      <FeatureGuard contentKey='home.layout'>
        <Row>
          <Col className='mb-xs-3 mb-2'>
            <DashboardGridCell name='widget3' testId='cmx__requests-with-action' />
          </Col>

          <Col className='mb-sm-2'>
            <DashboardGridCell name='widget4' testId='cmx__requests-with-recent-updates' />
          </Col>
        </Row>
      </FeatureGuard>

      <FeatureGuard contentKey='home-phyz.layout'>
        <p>
          <i>
            To sort by column, click column name. To filter by column, hover over and click the <List /> icon to enter
            criteria.
          </i>
        </p>
        <CasesTable
          defaultFilters={[CasesFilterTypes.needAction, CasesFilterTypes.updated, CasesFilterTypes.messageReceived]}
        />
      </FeatureGuard>

      <FeatureGuard contentKey='feedback.button'>
        <Feedback />
      </FeatureGuard>
    </>
  );
};

function DashboardGridCell({ name, testId }: { name: string; testId: string }) {
  return (
    <ResourceContent
      contentKey={[
        `home.getting-started.${name}.action`,
        `home.getting-started.${name}.body`,
        `home.getting-started.${name}.subtitle`,
        `home.getting-started.${name}.title`
      ]}
      defaultContent=''
      sourceDataKey='content'
      render={([action, body, subtitle, title]) => {
        return <ActionCard title={title} subtitle={subtitle} description={body} action={action} data-testid={testId} />;
      }}
    />
  );
}
