import React, { PropsWithChildren } from 'react';

import { Button, ButtonProps } from 'react-bootstrap';
import { LoadingIndicator } from './LoadingIndicator';

interface HcpButtonProps extends ButtonProps, PropsWithChildren<unknown> {
  loading?: boolean;
}

export const HcpButton = (props: HcpButtonProps) => {
  return (
    <Button className='text-uppercase' {...props}>
      <>
        {props.children}
        {props.loading && <LoadingIndicator spinnerProps={{ size: 'sm', className: 'ml-2' }} />}
      </>
    </Button>
  );
};
