import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import Schema from '@data-driven-forms/react-form-renderer/dist/cjs/schema';

import { DBFormSchema, reviewTabFields } from '..';
import {
  clinicalTabFields,
  patientTabFields,
  prescriptionTabFields,
  prescriberAttestationTabFields,
  prescriberTabFields,
  StepNames
} from '.';
import { ServiceOptionType } from '../../../interfaces';
import { insuranceTabFields } from './insurance-tab';

export const getServiceRequestRxEBVFormSchema = (schema: DBFormSchema): Schema => ({
  title: 'Service Request PHARMACY ELECTRONIC BENEFIT VERIFICATION Form',
  fields: [
    {
      component: componentTypes.WIZARD,
      name: `service-request-${ServiceOptionType.PharmacyElectronicBenefitVerification}`,
      fields: [
        patientTabFields(schema, StepNames.INSURANCE),
        insuranceTabFields(schema, StepNames.PRESCRIBER),
        prescriberTabFields(schema, StepNames.CLINICAL),
        clinicalTabFields(schema, StepNames.PRESCRIBER_ATTESTATION),
        prescriptionTabFields(schema, StepNames.OTHER),
        prescriberAttestationTabFields(schema, StepNames.REVIEW),
        reviewTabFields(schema)
      ].filter(Boolean)
    }
  ]
});

export const serviceRequestRxEBVFormSchema: DBFormSchema = {
  // !PATIENT TAB
  patient_tab: { label: 'patient' },
  personal_information_header: { label: 'personal information' },
  patient_first_name: { label: 'first name' },
  patient_middle_name: { label: 'middle name' },
  patient_last_name: { label: 'last name' },
  spacer_patient_1: { hideField: true },
  patient_dob: { label: 'date of birth' },
  patient_gender: { label: 'gender' },
  spacer_patient_2: { hideField: true },
  separator_patient_1: { hideField: true },
  patient_preferred_language: { hideField: true },
  patient_other_language: { hideField: true },
  spacer_patient_3: { hideField: true },
  contact_information_header: { hideField: true },
  patient_email: { hideField: true },
  patient_home_number: { hideField: true },
  patient_mobile_number: { hideField: true },
  patient_message_option: { hideField: true },
  spacer_patient_4: { hideField: true },
  patient_address_1: { hideField: true },
  patient_address_2: { hideField: true },
  spacer_patient_5: { hideField: true },
  patient_zip: { hideField: true },
  patient_city: { hideField: true },
  patient_state: { hideField: true },
  spacer_patient_6: { hideField: true },
  // !PRESCRIBER TAB
  prescriber_tab: { label: 'prescriber' },
  prescriber_id: { label: 'select or add a prescriber' },
  spacer_prescriber_1: {},
  prescribers_information_header: { label: "prescriber's information" },
  prescriber_first_name: { label: 'first name', required: true },
  prescriber_middle_name: { label: 'middle name' },
  prescriber_last_name: { label: 'last name', required: true },
  prescriber_npi: { label: 'prescriber npi', required: true },
  prescriber_tax_id: { label: 'tax id # (tin)' },
  prescriber_state_license_number: { label: 'state license #' },
  prescriber_licensing_state: { label: 'licensing state', required: true },
  separator_prescriber_1: {},
  prescribers_practice_information_header: { label: "prescriber's practice information" },
  organization_name: { label: 'organization name' },
  organization_npi: { label: 'organization npi' },
  organization_tax_id: { label: 'organization tax id' },
  spacer_prescriber_2: { hideField: true },
  prescriber_location_id: { label: 'select or add a location' },
  spacer_prescriber_3: {},
  location_header: { label: 'location' },
  spacer_prescriber_4: {},
  prescriber_location_address_full: { label: 'address' },
  prescriber_location_address_1: { label: 'address 1', required: true },
  prescriber_location_address_2: { label: 'address 2' },
  prescriber_location_zip: { label: 'zip code', required: true },
  prescriber_location_city: { label: 'city', required: true },
  prescriber_location_state: { label: 'state', required: true },
  prescriber_location_phone_1: { label: 'phone number', required: true },
  prescriber_location_fax_number: { label: 'fax number', required: true },
  separator_prescriber_2: { hideField: true },
  office_contact_header: { hideField: true },
  spacer_prescriber_5: { hideField: true },
  organization_office_contact_first_name: { hideField: true },
  organization_office_contact_last_name: { hideField: true },
  organization_office_contact_phone: { hideField: true },
  dashes: { label: '---', required: true },
  location_dashes: { label: '---', required: true },
  spacer_prescriber_6: { hideField: true },
  // !CLINICAL TAB
  clinical_tab: { label: 'clinical' },
  clinical_information_header: { label: 'clinical information' },
  clinical_treatment_start_date: { label: 'treatment start date' },
  spacer_clinical_1: {},
  clinical_patient_previously_enrolled: {},
  spacer_clinical_2: {},
  clinical_ndc_drug_name: { hideField: true },
  spacer_clinical_3: { hideField: true },
  clinical_qty: { hideField: true },
  spacer_clinical_4: { hideField: true },
  clinical_days_supply: { hideField: true },
  spacer_clinical_5: { hideField: true },
  clinical_patient_primary_diagnosed_select: { label: 'ICD-10 Primary Diagnosis Code', required: true },
  clinical_patient_primary_diagnosed: { hideField: true },
  spacer_clinical_6: {},
  clinical_patient_secondary_diagnosed: { hideField: true },
  spacer_clinical_7: { hideField: true },
  clinical_patient_tertiary_diagnosed: { hideField: true },
  spacer_clinical_8: { hideField: true },
  clinical_treatment_other: { label: 'please specify "other" diagnosis', required: true },
  spacer_clinical_9: { hideField: true },
  clinical_patient_diagnosed: { label: 'has patient been diagnosed with ascvd and/or hefh?', required: true },
  spacer_clinical_10: {},
  // !PRESCRIBER ATTESTATION
  prescriber_attestation_tab: { label: 'prescriber attestation & signature' },
  pa_patient_information_header: { label: 'patient information' },
  pa_personal_information_header: { label: 'personal information' },
  spacer_prescriber_attestation_1: { hideField: true },
  spacer_prescriber_attestation_2: {},
  pa_contact_information_header: { label: 'contact and address information' },
  spacer_prescriber_attestation_3: { hideField: true },
  spacer_prescriber_attestation_4: { hideField: true },
  spacer_prescriber_attestation_5: {},
  pa_prescriber_information_header: { label: 'prescriber information' },
  spacer_prescriber_attestation_6: { hideField: true },
  pa_practice_information_header: { label: 'practice information' },
  spacer_prescriber_attestation_7: { hideField: true },
  spacer_prescriber_attestation_8: { hideField: true },
  spacer_prescriber_attestation_9: {},
  pa_office_contact_header: { label: 'office contact' },
  spacer_prescriber_attestation_10: { hideField: true },
  pa_treatment_information_header: { label: 'treatment information' },
  spacer_prescriber_attestation_11: {},
  pa_administration_information_header: { label: 'administration information' },
  spacer_prescriber_attestation_12: {},
  pa_attestation_acknowledgement_header: { label: 'please read the following attestation and acknowledge to proceed' },
  prescriber_attestation_certified_text: {
    content:
      '<p>Novartis Patient Assistance Foundation (NPAF) Authorization FOR PHYSICIAN I certify that this therapy is medically necessary and that this information is accurate to the best of my knowledge.</p><p>I certify that the above therapy is medically necessary, and that the information provided is accurate to the best of my knowledge. I certify that I am the physician who has prescribed [Product] to the previously identifed patient, and that I provided the patient with a description of [Program Name]. I agree to the NPAF Authorization above. I also agree to receive communications, including faxes, related to my patient’s enrollment or participation in [Program Name].</p><p>I understand that NPAF may revise, change, or terminate programs at any time.</p>',
    helperText: 'Please read entire section.'
  },
  spacer_prescriber_attestation_13: { hideField: true },
  prescriber_attestation_is_certified: {},
  spacer_prescriber_attestation_14: { hideField: true },
  can_contact_patient: { label: 'Can we contact the patient if they have issues with enrollment?' },
  spacer_prescriber_attestation_15: { hideField: true },
  prescriber_attestation_signature: { label: 'signature', required: true },
  prescriber_attestation_signature_date: {
    label: 'date of certification'
  },
  spacer_prescriber_attestation_16: { hideField: true }
};
